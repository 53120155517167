var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-room-video",
      class: { speaking: _vm.userSpeaking },
      style: { height: _vm.height, background: _vm.bgColor }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isVideoMute || _vm.isSharing,
              expression: "!isVideoMute || isSharing"
            }
          ],
          staticClass: "w-100 h-100 position-relative overflow-hidden"
        },
        [
          _c("video", {
            ref: "videoElement",
            class: { sharing: _vm.isSharing },
            style: _vm.videoSize,
            attrs: { id: _vm.user ? "video-" + _vm.user.id : null }
          })
        ]
      ),
      _vm._v(" "),
      _vm.camMsg >= _vm.typePermission.denied
        ? _c("div", { staticClass: "w-100 h-100 noVideo cam-error" }, [
            _vm.camMsg == _vm.typePermission.errorSystem
              ? _c("p", { staticClass: "noCamera mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("room.camera.alerts.errorSystem.issue")))
                ])
              : _vm.camMsg == _vm.typePermission.denied
              ? _c("p", { staticClass: "noCamera mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("room.camera.alerts.denied.issue")))
                ])
              : _vm.camMsg == _vm.typePermission.deviceEmpty
              ? _c("p", { staticClass: "noCamera mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("room.camera.alerts.empty.issue")))
                ])
              : _vm.camMsg == _vm.typePermission.errorDevice
              ? _c("p", { staticClass: "noCamera mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("room.camera.alerts.fail")))
                ])
              : _vm.camMsg == _vm.typePermission.mute
              ? _c("p", { staticClass: "noCamera mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("room.camera.alerts.mute")))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user &&
      ((_vm.typeLesson == "Grupal" && _vm.isVideoMute && !_vm.isSharing) ||
        (_vm.typeLesson == "Individual" && _vm.isVideoMute))
        ? _c("div", { staticClass: "w-100 h-100 noVideo" }, [
            _c(
              "div",
              {
                staticClass: "content-noVideo-img",
                style: { height: _vm.getPhotoSize, width: _vm.getPhotoSize }
              },
              [
                _c("div", {
                  staticClass: "h-100 w-100 noVideo-img",
                  style: {
                    background: `url(${_vm.user.avatar}) center center / cover no-repeat`
                  }
                }),
                _vm._v(" "),
                !_vm.onlyRead
                  ? _c("div", {
                      staticClass:
                        "microphone d-flex align-items-center justify-content-center",
                      style: { transform: `scale(${_vm.volume * 0.007 + 1})` }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.onlyRead
                  ? _c("div", {
                      staticClass: "bound-vol",
                      class: { show: _vm.statusBound }
                    })
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.roster && !_vm.onlyRead
        ? _c(
            "div",
            {
              staticClass:
                "expand-and-mic d-flex align-items-center justify-content-center",
              class: { hide: _vm.hideActions },
              style: { top: _vm.expandAndMicTop }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn-expand action-video",
                  class: {
                    active: _vm.activePin,
                    "s-eam": _vm.typeUser == "student",
                    "t-eam": _vm.typeUser == "tutor"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("handleExpandUser")
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "icon-eam",
                    attrs: { name: "pin" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "msg-hover" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activePin
                          ? _vm.$t("room.header.pin.nf")
                          : _vm.$t("room.header.pin.f")
                      )
                    )
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.user || _vm.onlyRead) &&
      (_vm.typeLesson == "Grupal" ||
        (_vm.typeLesson == "Individual" && !_vm.isSharing))
        ? _c(
            "div",
            {
              staticClass: "v-user d-flex align-items-center",
              style: { bottom: _vm.bottomName }
            },
            [
              !_vm.isAudioMute
                ? _c(
                    "div",
                    { staticClass: "vol-bars d-flex align-items-center" },
                    [
                      _c("div", { staticClass: "content-vol-bar" }, [
                        _c("div", {
                          staticClass: "vol-bar",
                          style: {
                            width: `${_vm.volume <= 25 ? _vm.volume * 4 : 100}%`
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-vol-bar" }, [
                        _c("div", {
                          staticClass: "vol-bar",
                          style: {
                            width: `${
                              _vm.volume <= 70 && _vm.volume > 30
                                ? _vm.volume * 1.33333
                                : _vm.volume <= 30
                                ? 0
                                : 100
                            }%`
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-vol-bar" }, [
                        _c("div", {
                          staticClass: "vol-bar",
                          style: {
                            width: `${_vm.volume > 75 ? _vm.volume : 0}%`
                          }
                        })
                      ])
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "mute-vol" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-mic",
                        attrs: { name: "no-micro", original: "" }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.user
                ? _c("span", { staticClass: "v-user-name mr-2" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.onlyRead && !_vm.isLocal
                          ? _vm.user.name
                          : _vm.$t("room.camera.you")
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.user.type == "tutor" && !_vm.onlyRead
                ? _c("div", { staticClass: "tag-tutor" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("room.header.tutor")))])
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }