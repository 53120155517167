import menuFormats from './menu-formats.vue';
import itemMenuFormat from './item-menu-format.vue';
import formatIdentificationFile from './format-identification-file.vue';
import formatStudySkills from './format-study-skills.vue';
import formatLearningInventory from './format-learning-inventory.vue';
import formatSelfEsteem from './format-self-esteem.vue';
import formatAssertiveness from './format-assertiveness.vue';
import studentSeeker from './student-seeker.vue';

import contentQuestionsYesNo from './items/content-questions-yes-no.vue';
import questionYesNo from './items/question-yes-no.vue';

import contentQuestionsScale from './items/content-questions-scale.vue';
import questionScale from './items/question-scale.vue';
import questionsResult from './items/questions-result.vue';
import questionsRadio from './items/question-radio.vue';

import contentQuestionsRadio from './items/content-questions-radio.vue';

import identificationPdf from './pdfs/identification.vue';
import studySkillsPdf from './pdfs/study-skills.vue';
import learningStylesPdf from './pdfs/learning-styles.vue';
import selfEsteemPdf from './pdfs/self-esteem.vue';
import assertivenessPdf from './pdfs/assertiveness.vue';

const components = (Vue) => {
    Vue.component('v-menu-formats', menuFormats);
    Vue.component('v-item-menu-format', itemMenuFormat);
    Vue.component('v-format-identification-file', formatIdentificationFile);
    Vue.component('v-format-study-skills', formatStudySkills);
    Vue.component('v-format-learning-inventory', formatLearningInventory);
    Vue.component('v-format-self-esteem', formatSelfEsteem);
    Vue.component('v-format-assertiveness', formatAssertiveness);
    Vue.component('v-student-seeker', studentSeeker);

    Vue.component('v-content-questions-yes-no', contentQuestionsYesNo);
    Vue.component('v-questions-yes-no', questionYesNo);

    Vue.component('v-content-questions-scale', contentQuestionsScale);
    Vue.component('v-question-scale', questionScale);
    Vue.component('v-questions-result', questionsResult);

    Vue.component('v-questions-radio', contentQuestionsRadio);
    Vue.component('v-question-radio', questionsRadio);

    Vue.component('v-identification-pdf', identificationPdf);
    Vue.component('v-study-skills-pdf', studySkillsPdf);
    Vue.component('v-learning-styles-pdf', learningStylesPdf);
    Vue.component('v-self-esteem-pdf', selfEsteemPdf);
    Vue.component('v-assertiveness-pdf', assertivenessPdf);
}

export default components;