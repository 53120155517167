var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-students-t" }, [
    _c(
      "h5",
      { staticClass: "v-sch-t-title d-flex align-items-center mb-3" },
      [
        _vm._v(
          "\n           " +
            _vm._s(_vm.$t("scheduleT.students.t")) +
            "\n           "
        ),
        _c("v-more-info", {
          staticClass: "ml-2",
          attrs: { txtCloud: _vm.$t("scheduleT.students.tooltip") }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: { disabled: _vm.disabled },
        style: { border: "1px solid #9E9E9E", borderRadius: "20px" },
        attrs: { id: "search-autocomplete" }
      },
      [
        _c(
          "div",
          {
            staticClass: "position-relative search",
            on: {
              click: function($event) {
                return _vm.toggleAction()
              }
            }
          },
          [
            _c("svgicon", {
              staticClass: "icon-search",
              attrs: { name: "magnifier" }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val"
                }
              ],
              ref: "input",
              attrs: {
                type: "text",
                disabled: _vm.disabled,
                placeholder: _vm.$t("scheduleT.students.placeholder")
              },
              domProps: { value: _vm.val },
              on: {
                keyup: function($event) {
                  return _vm.keyFuction($event.key)
                },
                focus: function($event) {
                  _vm.statusDropdown =
                    _vm.val.length >= 3
                      ? _vm.stepsDropdown.focus
                      : _vm.stepsDropdown.blur
                },
                blur: function($event) {
                  return _vm.blurAction()
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.val = $event.target.value
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        (_vm.statusDropdown == _vm.stepsDropdown.focus ||
          _vm.statusDropdown == _vm.stepsDropdown.hover) &&
        !_vm.disabled
          ? _c(
              "perfect-scrollbar",
              {
                ref: "scroll",
                staticClass: "dropdown",
                attrs: { tag: "div" },
                on: {
                  mouseover: function($event) {
                    return _vm.hoverAction()
                  },
                  mouseleave: function($event) {
                    return _vm.leaveAction()
                  }
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "bound", mode: "out-in" } },
                  [
                    _vm.statusResults === _vm.stepsResults.spinner
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            _c("v-spinner", {
                              attrs: { height: "40px", width: "40px" }
                            })
                          ],
                          1
                        )
                      : _vm.statusResults === _vm.stepsResults.message
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            !_vm.isEmail
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "msg-search mt-2 mb-2 ml-auto mr-auto text-center"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "scheduleT.students.alertStudentsNotFound"
                                        )
                                      )
                                    )
                                  ]
                                )
                              : !_vm.emailIsValid
                              ? _c("p", {
                                  staticClass:
                                    "msg-search mt-2 mb-2 ml-auto mr-auto text-center",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.invalidExt)
                                  }
                                })
                              : _c(
                                  "form",
                                  {
                                    staticClass: "row ml-2 mr-2",
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.handleNotFoundStudent.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 pl-2 pr-2 mb-2" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "msg-search mt-2 mb-2 ml-auto mr-auto text-center mb-0"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduleT.students.alertStudentIsntExist"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 pl-2 pr-2" },
                                      [
                                        _c("v-textField", {
                                          ref: "name",
                                          staticClass: "mb-3 ml-auto",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "scheduleT.students.form.name"
                                            ),
                                            required: true
                                          },
                                          model: {
                                            value: _vm.formInviteStudent.name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formInviteStudent,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "formInviteStudent.name"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 pl-2 pr-2" },
                                      [
                                        _c("v-textField", {
                                          ref: "name",
                                          staticClass: "mb-3 mr-auto",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "scheduleT.students.form.lastName"
                                            ),
                                            required: true
                                          },
                                          model: {
                                            value:
                                              _vm.formInviteStudent.lastName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formInviteStudent,
                                                "lastName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formInviteStudent.lastName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 pl-2 pr-2 mb-3" },
                                      [
                                        _c("v-btn", {
                                          staticClass: "w-100 ml-auto mr-auto",
                                          attrs: {
                                            max: "450px",
                                            disabled:
                                              _vm.formInviteStudent.name ==
                                                "" ||
                                              _vm.formInviteStudent.lastName ==
                                                "",
                                            txt: _vm.$t(
                                              "scheduleT.students.form.btn"
                                            ),
                                            type: "submit",
                                            height: "30px",
                                            name: "signUp"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                          ]
                        )
                      : _vm.statusResults === _vm.stepsResults.results
                      ? _c(
                          "transition-group",
                          {
                            key: "results",
                            staticClass: "pl-0 mb-0",
                            attrs: { name: "bound", tag: "ul" }
                          },
                          _vm._l(_vm.results, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: `subject-'${item.id}`,
                                ref: "dropdownItem",
                                refInFor: true,
                                staticClass: "dropdown-item",
                                class: {
                                  hover: _vm.resultHover == `di-${item.id}`,
                                  disabled: item.disabled
                                },
                                attrs: { id: `di-${item.id}` },
                                on: {
                                  click: function($event) {
                                    return _vm.handleStudent(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex ml-2 mr-2 align-items-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-auto pl-0 pr-0" },
                                      [
                                        _c("v-lesson-photo", {
                                          staticClass: "ml-auto mr-auto",
                                          attrs: {
                                            options: {
                                              photo: item.photo,
                                              size: "40px",
                                              showPhoto: true
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col pl-3 pr-2",
                                        style: { width: "calc( 100% - 50px )" }
                                      },
                                      [
                                        _c("v-hover-txt", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            fontWeight: "500",
                                            txt: item.fullName,
                                            positionCloud:
                                              index == 0 ? "top" : "bottom"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-hover-txt", {
                                          attrs: {
                                            fontSize: "14px",
                                            txt: item.email,
                                            positionCloud:
                                              index == 0 &&
                                              _vm.results.length > 1
                                                ? "top"
                                                : "bottom"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.arrStudents.length > 0
      ? _c(
          "div",
          { staticClass: "v-students-list-t pt-3" },
          [
            _c(
              "transition-group",
              {
                staticClass: "row ml-n1 mr-n1",
                attrs: { name: "bound", tag: "div" }
              },
              _vm._l(_vm.arrStudents, function(student) {
                return _c(
                  "div",
                  {
                    key: student.email,
                    staticClass: "col-sm-6 pl-1 pr-1 mb-2"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "v-student-card pl-3 pr-3 pt-1 pb-1 d-flex align-items-center position-relative"
                      },
                      [
                        !student.id
                          ? _c(
                              "div",
                              { staticClass: "v-student-card-unconfirmed" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("scheduleT.students.unconfirmed")
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "v-student-card-content w-100 d-flex align-items-center position-relative"
                          },
                          [
                            _c("v-lesson-photo", {
                              attrs: {
                                options: {
                                  photo: student.photo,
                                  size: "45px",
                                  showPhoto: true
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "v-student-card-txt w-100" },
                              [
                                _c("v-hover-txt", {
                                  staticClass: "w-100 mb-1",
                                  attrs: {
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    txt: student.fullName
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-hover-txt", {
                                  staticClass: "w-100",
                                  attrs: {
                                    fontSize: "14px",
                                    txt: student.email
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-student-card-btn-delete",
                            on: {
                              click: function($event) {
                                return _vm.removeStudent(student)
                              }
                            }
                          },
                          [
                            _c("svgicon", {
                              staticClass: "v-student-card-btn-icon",
                              attrs: { name: "cross-circle" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }