/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ellipsis': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M2.5 13C1.122 13 0 11.878 0 10.5S1.122 8 2.5 8 5 9.122 5 10.5 3.878 13 2.5 13zm0-4C1.673 9 1 9.673 1 10.5S1.673 12 2.5 12 4 11.327 4 10.5 3.327 9 2.5 9zM9.5 13C8.122 13 7 11.878 7 10.5S8.122 8 9.5 8 12 9.122 12 10.5 10.878 13 9.5 13zm0-4C8.673 9 8 9.673 8 10.5S8.673 12 9.5 12s1.5-.673 1.5-1.5S10.327 9 9.5 9zM16.5 13c-1.378 0-2.5-1.122-2.5-2.5S15.122 8 16.5 8 19 9.122 19 10.5 17.878 13 16.5 13zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5S17.327 9 16.5 9z"/>'
  }
})
