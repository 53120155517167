/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.9 12.199a10.586 10.586 0 00-3.63-3.055A10.39 10.39 0 009.5 8 10.49 10.49 0 002 11.152V8.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5h4a.5.5 0 000-1H2.572A9.49 9.49 0 019.5 9a9.43 9.43 0 017.6 3.799.498.498 0 00.7.1.5.5 0 00.099-.7z"/>'
  }
})
