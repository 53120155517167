var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pt-5 pb-5 d-flex flex-column", attrs: { id: "formats" } },
    [
      _c("transition", { attrs: { name: "bound" } }, [
        _vm.statusTooltip &&
        _vm.currentStep == _vm.step.menu &&
        !_vm.isTutor &&
        _vm.userType == "student"
          ? _c(
              "div",
              {
                staticClass:
                  "f-tooltip pl-xl-5 pt-xl-4 pr-xl-5 pb-xl-4 p-sm-4 p-3 mb-3 position-relative"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "row ml-n2 mr-n2 flex-nowrap align-items-center"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "f-close",
                        on: {
                          click: function($event) {
                            return _vm.closeTooltipformats()
                          }
                        }
                      },
                      [
                        _c("svgicon", {
                          staticClass: "f-close-icon",
                          attrs: { name: "cross" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-auto pl-2 pr-2 d-none d-sm-block" },
                      [
                        _c("svgicon", {
                          staticClass: "f-icon-tooltip",
                          attrs: { color: "#000", name: "clipboard-user" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col pl-2 pr-2" }, [
                      _c("h3", { staticClass: "f-title mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("formats.tooltip.t")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "f-txt mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("formats.tooltip.txt")))
                      ])
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.currentStep != _vm.step.menu
        ? _c(
            "div",
            {
              staticClass: "row align-items-center justify-content-between mb-3"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "f-btn-prev d-flex align-items-center",
                    style: { "--hover-color": _vm.color[_vm.userType].main },
                    on: {
                      click: function($event) {
                        _vm.currentStep = _vm.step.menu
                      }
                    }
                  },
                  [
                    _c("svgicon", {
                      staticClass: "f-btn-icon mr-2",
                      attrs: { name: "arrow-left" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "f-btn-prev-txt" }, [
                      _vm._v("Ir atrás")
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.statusPrint
                ? _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c("v-btn", {
                        attrs: {
                          txt: "Imprimir",
                          min: "120px",
                          fontSize: "16px",
                          height: "40px"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.print.apply(null, arguments)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-paper h-100" },
        [
          _c(
            "transition",
            { attrs: { name: "bound", mode: "out-in" } },
            [
              _vm.currentStep == _vm.step.menu
                ? _c("v-menu-formats", {
                    key: "menu",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4 h-100",
                    attrs: {
                      studentFormats: _vm.studentFormats,
                      spinner: _vm.spinner,
                      currentStudent: _vm.currentStudent
                    },
                    on: {
                      handleStudent: _vm.handleStudent,
                      handleFormat: _vm.handleStep
                    }
                  })
                : _vm.currentStep == _vm.step.identificationFile
                ? _c("v-format-identification-file", {
                    key: "identificationFile",
                    ref: "format",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4",
                    attrs: { studentFormats: _vm.studentFormats },
                    on: { openMenu: _vm.openMenu }
                  })
                : _vm.currentStep == _vm.step.studySkills
                ? _c("v-format-study-skills", {
                    key: "studySkills",
                    ref: "format",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4",
                    attrs: { studentFormats: _vm.studentFormats },
                    on: { openMenu: _vm.openMenu }
                  })
                : _vm.currentStep == _vm.step.learningInventory
                ? _c("v-format-learning-inventory", {
                    key: "learningInventory",
                    ref: "format",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4",
                    attrs: { studentFormats: _vm.studentFormats },
                    on: { openMenu: _vm.openMenu }
                  })
                : _vm.currentStep == _vm.step.selfEsteem
                ? _c("v-format-self-esteem", {
                    key: "learningInventory",
                    ref: "format",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4",
                    attrs: { studentFormats: _vm.studentFormats },
                    on: { openMenu: _vm.openMenu }
                  })
                : _vm.currentStep == _vm.step.assertiveness
                ? _c("v-format-assertiveness", {
                    key: "assertiveness",
                    ref: "format",
                    staticClass: "p-xl-5 p-sm-4 pl-3 pt-4 pr-3 pb-4",
                    attrs: { studentFormats: _vm.studentFormats },
                    on: { openMenu: _vm.openMenu }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }