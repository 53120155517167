const handleUsePriorityBasedDownlinkPolicy = (state, band) => {
    state.usePriorityBasedDownlinkPolicy = band;
}

const handleEnableWebAudio = (state, band) => {
    state.enableWebAudio = band;
}

/* Chime autenticate */
const handleMeetingSession = (state, session: object | null) =>{
    state.meetingSession = session;
}

const handleMeetingEventPOSTLogger = (state, session: object | null) =>{
    state.meetingEventPOSTLogger = session;
}

const handleMeetingLogger = (state, meetingLogger: object | null) => {
    state.meetingLogger = meetingLogger;
}

const handleDefaultBrowserBehaviour = (state, defaultBrowserBehaviour: object | null) =>{
    state.defaultBrowserBehaviour = defaultBrowserBehaviour;
}

const handlePriorityBasedDownlinkPolicy = (state, priorityBasedDownlinkPolicy: object | null) =>{
    state.priorityBasedDownlinkPolicy = priorityBasedDownlinkPolicy;
}

/* Voice focus */
const handleVoiceFocus = (state, status: boolean) => {
    state.enableVoiceFocus = status;
}

const handleSupportsVoiceFocus = (state, status: boolean) => {
    state.supportsVoiceFocus = status;
}

const handleVoiceFocusDevice = (state, payload: any) => {
    state.voiceFocusDevice = payload;
}

const handleVoiceFocusTransformer = (state, payload: any) => {
    state.voiceFocusTransformer = payload;
}

/* Browser permissions */
const handlePermissions = (state, permissions: any) => {
    if (permissions.mic != undefined)
        state.permissions.mic = permissions.mic;
    if (permissions.cam != undefined)
        state.permissions.cam = permissions.cam;
}

const handleAskingForPermissions = (state, status: boolean) => {
    state.askingForPermissions = status;
}

/* Devices and permissions */
const handleDevices = (state, devices: any) => {
    if (devices.microphones)
        state.devices.microphones = devices.microphones;
    if (devices.cameras)
        state.devices.cameras = devices.cameras;
    if (devices.speakers)
        state.devices.speakers = devices.speakers;
}

const handleDevice = (state, device: any) => {
	if (device.type == 'cam')
		state.currentCam = device.device;
	else if(device.type == 'mic')
		state.currentMic = device.device;
    else if(device.type == 'VQ')
        state.currentVideoQuality = device.deviceId;
    else 
        state.currentSpk = device.deviceId;
}

const handleCamTracks = (state, status: boolean) => {
    state.camTracksChange = status;
}

const handleMicTracks = (state, status: boolean) => {
    state.micTracksChange = status;
}

const handleAudioInputDevice = (state, payload: any) => {
    state.currentAudioInputDevice = payload;
}

/* Clear room variables */
const cleanRoomDatas = (state) => {
    /* dinamic chime variables */
    state.meetingSession = null;
    state.meetingLogger = undefined;
    state.meetingEventPOSTLogger = null,
    state.defaultBrowserBehaviour = null;
    state.priorityBasedDownlinkPolicy = null,
    state.supportsVoiceFocus = false;
    state.enableVoiceFocus = false;
    state.voiceFocusTransformer = undefined;
    state.voiceFocusDevice = undefined;
    /* General */
    state.permissions = { mic: state.typePermission.prompt, cam: state.typePermission.prompt };
    state.askingForPermissions = false;
    state.devices = { microphones: [], cameras: [], speakers: [], videoQuality: state.devices.videoQuality };
    state.camTracksChange = false;
    state.micTracksChange = false;
    state.currentMic = null;
    state.currentCam = null;
    state.currentSpk = null;
    state.currentVideoQuality = null;
    state.currentAudioInputDevice = undefined;
}


export default{
    handleUsePriorityBasedDownlinkPolicy,
    handleEnableWebAudio,
    handleMeetingSession,
    handleMeetingEventPOSTLogger,
    handleMeetingLogger,
    handleDefaultBrowserBehaviour,
    handlePriorityBasedDownlinkPolicy,
    handleVoiceFocus,
    handleSupportsVoiceFocus,
    handleAudioInputDevice,
    handleVoiceFocusTransformer,
    handlePermissions,
    handleAskingForPermissions,
    handleDevices,
    handleDevice,
    handleCamTracks,
    handleMicTracks,
    handleVoiceFocusDevice,
    cleanRoomDatas 
}