<template>
	<div id="calendar" class="pb-4">
		<!-- ================ Calendar ================== -->
		<section class="calendar-lessons p-4 p-md-5 d-flex justify-content-center position-relative">
			<div class="content">
				<h1 class="title mb-3"> <svgicon class="icon" name="calendar-full"></svgicon> {{ $t('calendar.title') }} </h1>
				<div class="pl-sm-3 pr-sm-3 pl-md-5 pr-md-5">
					<p class="text b-line pb-4 mb-4" v-html="$t('calendar.descripHtml')"></p>
					<h3 class="title-url mb-3">{{ $t('calendar.titleUrl') }}</h3>
					<div class="content-url pl-2 pr-2 pl-sm-3 pr-sm-3 pl-md-5 pr-md-5 mb-5">
						<p class="text mb-3" v-html="$t('calendar.descripUrlHtml')"></p>
						<div class="link-calendar position-relative mb-2" :class="{ disabled : auth.user.disabled_calendar }">
							<input id="input-clipboard" type="link" v-model="getLink" readonly="readonly">
							<button class="btn-copy" v-on:click="copyURL">{{ $t('calendar.btnCopy') }}</button>
						</div>
						<div class="row">
							<div class="col-md-8 text-center text-md-right">
								<span class="text-enabled" v-html="!auth.user.disabled_calendar?$t('calendar.enabledTxt'):$t('calendar.disabledTxt')"></span>
							</div>
							<div class="col-md-4 text-center text-md-left" v-if="auth.user.disabled_calendar">
								<button class="btn-disable" @click="changeStatusCalendar({ disabled_calendar : 0 })">{{ $t('calendar.btnEnabled') }}</button>
							</div>
							<div class="col-md-4 text-center text-md-left" v-else>
								<button class="btn-enabled-s color-student-main" @click="changeStatusCalendar({ disabled_calendar : 1 })">{{ $t('calendar.btnDisable') }}</button>
							</div>
						</div>
					</div>
					<h3 class="title-calendars mb-3">{{ $t('calendar.instructions') }}</h3>
					<div class="content-calendars pl-3 pr-3 pl-md-5 pr-md-5 row" :class="{ disabled : auth.user.disabled_calendar }">
						<a class="btn-calendar pt-3 pb-3 col-12 text-center" :href="$t('calendar.icloud')" target="_blank">
							<img src="/media/images/calendar/icloud.png" :alt="$t('image.icloud')">
						</a>
						<a class="btn-calendar pt-3 pb-3 col-12 text-center" :href="$t('calendar.google')" target="_blank">
							<img src="/media/images/calendar/google.png" :alt="$t('image.google')">
						</a>
						<a class="btn-calendar pt-3 pb-3 col-6 text-right" :href="$t('calendar.outlook')" target="_blank">
							<img src="/media/images/calendar/outlook.png" :alt="$t('image.outlook')">
						</a>
						<a class="btn-calendar pt-3 pb-3 col-6" :href="$t('calendar.outlook')" target="_blank">
							<img src="/media/images/calendar/exchange.png" :alt="$t('image.exchange')">
						</a>
					</div>
				</div>
			</div>
			<svgicon class="trello-left d-none d-sm-block" name="trello-3" original/>
			<svgicon class="trello-right d-none d-sm-block" name="trello-1" original/>
		</section>
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	export default{
		data() {
			return {
				link: "http://linc.io/calendar/2tSEOkKK6kU9Lmfrt3"
			}
		},
		computed:{
            ...mapState(['auth']),
            getLink(){
            	if (!this.auth.user.disabled_calendar)
            	    return `https://calendar.linc.io/key/${this.auth.user.calendar_token}`
            	else
            		return '';
            }
		},
		methods: {
			copyURL : () => {
				const input = document.getElementById('input-clipboard');
                input.select();
                document.execCommand("copy");
			}
		},
		methods: {
			...mapActions(['changeStatusCalendar']),
			copyURL : () => {
				const input = document.getElementById('input-clipboard');
                input.select();
                document.execCommand("copy");
			}
		}
	}
</script>