const modal = {
    data(){
    	return{
    		show: false, // Show modal (bound animation)
    		eventListener: true, // Init event listener
    		notKeyPress: false
    	}
	},
	mounted(){
		this.onMounted();
	},
	methods:{
		onKeyPress(e){ // Close modal on key esc press
			if (!this.notKeyPress)
				if ("key" in e) {
					if (e.key === "Escape" || e.key === "Esc") {
						this.close();
					}
				}else if(e.keyCode === 27){
					this.close();
				}
		},
		async close(){
			await this.beforeClose();
			this.show = false;
			if (this.eventListener)
			    document.removeEventListener('keydown', this.onKeyPress);
			setTimeout(() => {
				this.afterClose();
			}, 400);
		},
		onMounted(){
			this.$nextTick( () => {
				if (!this.show){
					this.show = true;
					if (this.eventListener)
						document.addEventListener('keydown', this.onKeyPress);
				}
			});
		},
		afterClose(){},
		async beforeClose(){}
	}
}

export{
	modal
}