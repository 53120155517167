/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smile-keyboard': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g transform="translate(0 -.007)" _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#8D949E" cx="9.5" cy="9.5" r="9.5"/><g transform="translate(4 6)" _fill="#FFF"><circle pid="1" cx="1.5" cy="1.5" r="1.5"/><circle pid="2" cx="9.5" cy="1.5" r="1.5"/></g><path pid="3" d="M5 12c1.124 1.012 2.595 1.517 4.414 1.517 1.818 0 3.33-.505 4.535-1.517" _stroke="#FFF" stroke-linecap="round"/></g>'
  }
})
