<template>
	<div class="content-img" :style="{ background : `url(${img})center center / cover no-repeat`, width: size, height: size }"></div>
</template>
<script>
	export default{
		props: {
			img: { type: String, default: '', required: true },
			size: { type: String, default: '60px', required: false } // in px
		}
	}
</script>