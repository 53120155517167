/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M7.5 0a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM9.06 11.623c-.386.153-.693.268-.924.348-.23.08-.497.12-.801.12-.467 0-.831-.114-1.09-.342a1.104 1.104 0 01-.388-.868c0-.136.01-.276.028-.418.02-.142.051-.302.094-.482l.483-1.707c.042-.164.08-.32.108-.464.03-.146.044-.28.044-.402 0-.217-.045-.37-.135-.455-.09-.086-.262-.128-.516-.128-.125 0-.253.019-.384.058-.13.04-.243.076-.336.111l.127-.525c.317-.13.62-.24.908-.331.29-.093.562-.139.82-.139.464 0 .822.113 1.074.337.25.224.377.515.377.874 0 .074-.009.205-.026.391-.017.188-.05.358-.097.515l-.48 1.702c-.04.136-.075.292-.106.467a2.471 2.471 0 00-.047.398c0 .226.05.38.152.462.1.082.276.123.525.123.118 0 .249-.021.398-.062a2.26 2.26 0 00.32-.108l-.128.525zm-.085-6.906a1.147 1.147 0 01-.81.312 1.16 1.16 0 01-.812-.312.997.997 0 01-.339-.758c0-.295.115-.549.339-.759a1.15 1.15 0 01.812-.316c.316 0 .586.105.81.316.224.21.336.464.336.76s-.112.549-.336.757z" _fill="#9B9B9B" fill-rule="nonzero"/>'
  }
})
