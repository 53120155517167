/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trophy': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.423 11.563C17.571 10.516 19 9.214 19 7a2.002 2.002 0 00-3.038-1.709c.03-.746.038-1.366.038-1.791 0-.538-.337-1.301-1.94-1.884C12.968 1.219 11.526 1 10 1s-2.968.219-4.06.616C4.337 2.199 4 2.962 4 3.5c0 .425.007 1.045.038 1.791A2.002 2.002 0 001 7c0 2.214 1.429 3.517 2.577 4.563C4.34 12.259 5 12.86 5 13.5a.5.5 0 01-1 0 .5.5 0 00-1 0 1.501 1.501 0 002.688.915c.281.655.594 1.222.939 1.697.911 1.253 2.046 1.889 3.373 1.889s2.462-.635 3.373-1.889a8.736 8.736 0 00.939-1.697A1.498 1.498 0 0017 13.5a.5.5 0 00-1 0 .5.5 0 01-1 0c0-.64.659-1.241 1.423-1.937zM6.282 2.556C7.267 2.198 8.588 2 10 2s2.733.197 3.718.556c.885.322 1.282.706 1.282.944s-.396.622-1.282.944C12.733 4.802 11.412 5 10 5s-2.733-.197-3.718-.556C5.397 4.122 5 3.738 5 3.5s.396-.622 1.282-.944zM2 7c0-.551.449-1 1-1s1 .449 1 1c0 .445-.293.896-.667 1.029a.5.5 0 00.333.943 1.84 1.84 0 00.629-.383c.105.878.245 1.788.434 2.678-.155-.148-.316-.294-.479-.443C3.143 9.815 1.999 8.772 1.999 7zm10.564 8.523C11.842 16.517 11.003 17 10 17s-1.842-.483-2.564-1.477c-.644-.886-1.174-2.163-1.575-3.797-.58-2.362-.774-4.994-.835-6.767.246.148.547.291.914.425C7.032 5.781 8.474 6 10 6s2.968-.219 4.06-.616a5.26 5.26 0 00.914-.425c-.061 1.773-.254 4.406-.835 6.767-.402 1.633-.931 2.911-1.575 3.797zm2.706-4.256c.189-.89.33-1.801.434-2.678.184.168.397.301.629.383a.5.5 0 10.333-.943c-.374-.132-.667-.584-.667-1.029 0-.551.449-1 1-1s1 .449 1 1c0 1.772-1.144 2.816-2.251 3.824-.163.148-.324.295-.479.443z"/><path pid="1" d="M10 20c-1.001 0-1.95-.17-2.672-.48C6.172 19.025 6 18.355 6 18c0-.287.108-.57.314-.818a.5.5 0 01.772.636c-.056.068-.085.13-.085.182 0 .353 1.139 1 3 1s3-.647 3-1c0-.052-.029-.114-.085-.182a.5.5 0 11.772-.636c.205.249.314.532.314.818 0 .355-.173 1.025-1.328 1.52-.722.309-1.671.48-2.672.48z"/>'
  }
})
