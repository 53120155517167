/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'doc': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M14.5 19h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM1.5 1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z"/><path pid="1" d="M6.5 4h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6.5 6h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6.5 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6.5 10h-3a.5.5 0 010-1h3a.5.5 0 010 1zM12.5 12h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 14h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 16h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 10h-4a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v6a.5.5 0 01-.5.5zM9 9h3V4H9v5z"/></g>'
  }
})
