<template>
	<div class="v-places row justify-content-center w-100">
		<div class="col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3">
        	<div class="btn-prev-step order-0 col-auto col-md">
				<v-prev-link class="col-12 pt-3 pb-3 pl-0 pr-0" :txt="$t('scheduleLesson.place.prev')" type="step" :click="() => prevAction()" />
			</div>
			<h3 class="title-step order-2 order-md-1 col-12 col-md-auto mb-0 text-center">{{ $t('scheduleLesson.place.t') }}</h3>
		    <div class="btn-close-step order-1 order-md-2 col-auto col-md text-right">
			    <button @click="closeAction()" v-if="closeAction"><svgicon name="cross2"></svgicon></button>
		    </div>
	    </div>
	    <div class="v-places-content col-12">
	    	<perfect-scrollbar class="v-places-list w-100 pl-0 mb-4" tag="ul">
		        <li class="radio-place d-flex justify-content-between align-items-center pt-2 pb-2 pl-2 pl-sm-3 pr-md-4 pr-3 w-100" :color="(index+1)%2==0?'clear':'dark'" v-for="(item,index) in places" @click="handleVal(item)" :class="{ active : value.id == item.id }">
			        <div class="d-flex align-items-center mr-2">
				        <div class="icon-place mr-2" :style="'background: #92117F'">
					        <svgicon class="icon" :name="item.icon.icon_name" />
				        </div>
				        <span class="name-place">{{ item.name }}</span>
			        </div>
			        <div class="val-place"></div>
		        </li>
	        </perfect-scrollbar>
            <v-btn class="float-right" :txt="$t('scheduleLesson.btnNext')" fontSize="20px" type="Next" min="200px" @click.native="nextAction()" :disabled="!value.id" />
	    </div>
	    
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	export default{
		props: {
            closeAction: { type: Function, required: true },
			prevAction: { type: Function, required: true },
            nextAction: { type: Function, required: true },
            lesson: { type: Object, required: true },
            idTutor: { required: true },
            value: { required: false }
		},
        data(){
        	return{
        	    places: [],
                nColor: 0,
                color: [ '#92117F', '#019DE0', '#FF9C00', '#009036'  ]
        	}
        },
        computed:{
            ...mapState(['auth'])
        },
        methods: {
            ...mapActions(['axiosRequest']),
            getPlaces(){
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/tutor/${this.idTutor}/subject/${this.lesson.subject_id}/places`,
                    params: { subject_id: this.lesson.subject_id },
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data)
                .then( data => {
                    this.places = data;
                }).catch( error => {
                    console.log(error)
                })
            },
            handleVal(val){
            	this.$emit('input',val);
            },
            setColor(){
                if (this.nColor == 4)
                    this.nColor = 0
                this.nColor += 1;
                return this.color[this.nColor];
            }
        },
        created(){
        	this.getPlaces();
        }
	}
</script>