
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class inputField extends Vue{
        @Prop({ type: String, default: '', required: false }) readonly placeholder!: String;
		@Prop({ default: undefined, required: false }) readonly maxlength!: any;
		@Prop({ type: Boolean, default: false, required: false }) readonly required!: Boolean;
		@Prop({ type: String, default: '', required: false }) readonly name!: String;
		@Prop({ type: String, default: 'text', required: false }) readonly type!: String;
		@Prop({ type: String, default: '', required: false }) readonly label!: String;
		@Prop({ type: Boolean, default: false, required: false }) readonly error!: Boolean;
		@Prop({ default: false }) readonly onlyRead!: boolean;
		@Prop({ type: Boolean, default: false, required: false }) readonly iconEye!: Boolean;
		@Prop({ type: Boolean, default: false, required: false }) readonly disabled!: String;
		@Prop({ type: Object, default: () => { return {} }, required: false }) readonly options!: any;
		@Prop({ default: '' }) readonly value!: any;

		@State( state => state.auth.permission ) userType;
		@Getter('userColor') userColor;

		focus: Boolean = false;
		statusEye: Boolean = false;

		get isFocus(): Boolean{
            return this.focus && /*!this.error &&*/ !this.disabled && !this.onlyRead;
		}

		get isActive():Boolean{
			return this.value != '';
		}

		get focusColor(): String{
            if(this.options?.styles?.colorFocus)
				return this.options.styles.colorFocus;
			else
				return this.userColor.main;
		}

		get activeLabel(): Boolean{
            return !!this.options.activeLabel || this.isFocus || this.placeholder != '' || this.isActive;
		}

		get labelStyles(): Object{
            let styles: object = { 
				            color: (this.focus || this.value != '' || this.placeholder != '')?this.focusColor:'#9b9b9b', 
					        fontSize: '1rem',
							lineHeight: '1.2em', 
							fontWeight: '400',
							'--transform': 'translateY(25px)',
							'--transform-active': 'translateY(0px) scale(0.7)'
					    }

				if(this.options?.styles?.label)
				    styles = { ...styles, ...this.options.styles.label };
				
				return styles;
		}

		handleInput(value): void{
			(this as any).$emit('input', value);
		}
	}
