var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: { width: _vm.size, height: _vm.size },
      attrs: { id: "circles" + _vm.vCircle }
    },
    _vm._l(4, function(n) {
      return _c("div", { staticClass: "content-circle" }, [
        _c("div", {
          staticClass: "circle",
          class:
            _vm.bgColor === "main"
              ? `bgColor-${
                  _vm.auth.permission == "" ? "student" : _vm.auth.permission
                }-${_vm.circles[_vm.vCircle - 1].color[n - 1]}`
              : `bgColor-yellow-[vCircle-1].color[n-1]`
        })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }