<template>
	<div class="v-tutoring-place" :class="{ active : show }" v-if="modalVal.modal=='v-modal-changePlace'">
		<div class="blurColor" @click="close()"></div>
		<div class="v-content">
			<h4 class="title pt-4 pb-3 pl-4 pr-5 mb-0" :class="`bgColor-${auth.permission}-main`">
				{{ $t('modals.place.j') }} <span class="d-block w-100">{{ $t('modals.place.t') }}</span>
				<svgicon class="btn-close" name="cross2" @click="close()" />
			</h4>
			<div class="pt-5 pb-5 pl-4 pr-4">
				<p class="descrip">{{ $t('modals.place.descrip') }}</p>
				<input class="field-place w-100" v-model="place" :placeholder="$t('modals.place.placeholder')" maxlength="100" @keyup.enter="joinedPlace()" type="text">
                <v-btn class="w-100" :txt="$t('modals.place.btn')" btn="warning" height="44px" fontSize="20px" @click.native="joinedPlace()" :disabled="$v.place.$invalid || sending"/>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	import { required, maxLength, sameAs, not } from 'vuelidate/lib/validators';
	export default{
		mixins: [ modal ],
		data(){
			return{
				place: '',
				oldPlace: '',
				sending: false
			}
		},
		computed:{
			...mapState(['auth' ,'currentLesson']),
			...mapGetters(['modalVal'])
		},
		created(){
            this.place = this.currentLesson.lesson.place;
            this.oldPlace = this.currentLesson.lesson.place;
		},
		methods:{
			...mapMutations(['setLessonChanges','setNotification', 'setModal', 'setActiveLessons']),
			...mapActions(['axiosRequest']),
			joinedPlace(){
				if (!this.$v.place.$invalid && !this.sending){
					this.sending = true;
					this.$Progress.start();
					if (this.oldPlace != this.place) {
					    this.axiosRequest({ config : {
						    method: 'PUT',
						    url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/place`,
						    params: { place: this.place },
						    headers: { authorization : this.auth.token }
					    }}).then( resp => resp.data )
					    .then( data => {
						    this.sending = false;
						    this.$Progress.finish();
						    this.currentLesson.lesson.place = this.place;
						    this.setLessonChanges({ data: 'place', lesson: { id: this.currentLesson.lesson.id, val: this.place } });
						    let indexActivelesson = this.auth.activeLessons.findIndex( item => item.id == this.currentLesson.lesson.id );
						    if (indexActivelesson >= 0){
							    let lessons =  this.auth.activeLessons;
							        lessons[indexActivelesson].place = this.place;
						        this.setActiveLessons(lessons);
						    }
						    this.close();
						    this.setNotification({ type: data.type, msg: data.message });
					    })
					    .catch( error => {
						    this.sending = false;
						    this.$Progress.fail();
					    } );
				    }
				    else{
				    	this.sending = false;
				    	this.$Progress.finish();
				    }
				}
			},
			afterClose(){
				this.setModal('');
			}
		},
		validations: {
            place: {
            	required,
            	maxLength: maxLength(100),
            	not: not(sameAs('oldPlace'))
            }
        }
	}
</script>