var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "get-app" } },
    [
      _c("v-search", {
        ref: "search",
        staticClass: "mb-4",
        attrs: {
          placeholder: "Search subject or tutor…",
          data: [],
          fields: ["name"]
        }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "container-fluid pb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "pt-4 pb-4 pt-sm-5 pb-sm-5 pl-3 pr-3 pl-sm-4 pr-sm-4 paper row justify-content-center"
          },
          [
            _c("div", { staticClass: "info col-lg mb-4" }, [
              _c("h2", { staticClass: "title mb-4" }, [
                _vm._v(_vm._s(_vm.$t("lincAppS.title")))
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("lincAppS.subTitle")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://play.google.com/store/apps/details?id=mx.linc.institutions&referrer=utm_source%3Dwebsite",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-app",
                    attrs: {
                      src: _vm.$t("googlePlay"),
                      alt: _vm.$t("image.googlePlay")
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://itunes.apple.com/app/apple-store/id1424076127?mt=8",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-app pl-2",
                    attrs: {
                      src: _vm.$t("appStore"),
                      alt: _vm.$t("image.appStore")
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "list-benefits mt-4" }, [
                _c("dt", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b1.t")))
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "mb-3 mb-sm-4" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b1.d1")))
                ]),
                _vm._v(" "),
                _c("dt", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b2.t")))
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "mb-sm-4" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b2.d1")))
                ]),
                _vm._v(" "),
                _c("dt", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b3.t")))
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "mb-3 mb-sm-4" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b3.d1")))
                ]),
                _vm._v(" "),
                _c("dt", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("lincAppS.benefits.b4.t")))
                ]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.$t("lincAppS.benefits.b4.d1")))])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "img col-lg d-flex flex-wrap justify-content-center justify-content-sm-start",
                style: { maxWidth: "500px" }
              },
              [
                _c("img", {
                  style: { maxWidth: "100%" },
                  attrs: {
                    src: _vm.$t("lincAppS.img"),
                    alt: _vm.$t("image.getTheAppS")
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "qr-code text-center" },
                  [
                    _c("svgicon", {
                      staticClass: "qr mb-2",
                      attrs: { name: _vm.$t("lincAppS.qr"), original: "" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "txt-qr" },
                      [
                        _c("svgicon", {
                          staticClass: "arrow",
                          attrs: { name: "save-arrow" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "txt-top d-block" }, [
                          _vm._v(_vm._s(_vm.$t("lincAppS.qrtxt1")))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "txt-down d-block" }, [
                          _vm._v(_vm._s(_vm.$t("lincAppS.qrtxt2")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }