/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone-bubble': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12.738 19c-1.415 0-2.918-.4-4.468-1.189-1.425-.726-2.832-1.764-4.07-3.003s-2.275-2.648-2.999-4.074C.413 9.183.014 7.68.014 6.265c0-.914.778-1.766 1.241-2.193.562-.517 1.385-1.07 2.011-1.07.329 0 .689.197 1.166.639.34.315.722.741 1.103 1.232.326.419 1.388 1.842 1.388 2.577 0 .649-.691 1.074-1.423 1.523-.249.153-.507.311-.701.46a1.24 1.24 0 00-.261.247c.752 1.821 2.963 4.033 4.785 4.785.039-.026.12-.094.247-.261.148-.194.307-.452.46-.701.45-.732.875-1.423 1.524-1.423.735 0 2.158 1.062 2.577 1.388.491.382.917.763 1.232 1.103.442.477.639.837.639 1.166 0 .626-.552 1.452-1.069 2.015-.427.466-1.28 1.248-2.194 1.248zM3.249 4.002c-.207.015-.762.294-1.316.805-.558.514-.919 1.086-.919 1.458 0 2.466 1.456 5.396 3.894 7.837s5.366 3.899 7.831 3.899c.371 0 .943-.362 1.457-.923.511-.558.79-1.114.805-1.322-.052-.162-.481-.727-1.543-1.543-.968-.744-1.679-1.091-1.883-1.129-.173.099-.509.645-.692.943-.458.745-.891 1.449-1.556 1.449a.875.875 0 01-.326-.063c-2.094-.838-4.572-3.315-5.41-5.41-.112-.28-.133-.715.401-1.199.268-.242.632-.466.985-.683.298-.183.845-.519.943-.692-.038-.205-.385-.915-1.129-1.883-.816-1.062-1.381-1.491-1.543-1.543z"/><path pid="1" d="M9.5 10a.5.5 0 01-.354-.854c.817-.817 1.077-1.318 1.159-1.55-.844-.72-1.306-1.633-1.306-2.597 0-1.103.596-2.127 1.678-2.884 1.028-.719 2.385-1.116 3.822-1.116s2.795.396 3.822 1.116c1.082.757 1.678 1.782 1.678 2.884s-.596 2.127-1.678 2.884c-1.028.719-2.385 1.116-3.822 1.116a7.41 7.41 0 01-1.753-.206c-.513.349-1.906 1.206-3.247 1.206zm5-8c-1.234 0-2.388.332-3.249.935C10.444 3.5 10 4.233 10 5c0 .727.405 1.432 1.139 1.984.09.068.155.164.183.273.05.19.063.642-.478 1.434a7.26 7.26 0 001.501-.838.502.502 0 01.437-.083c.545.152 1.123.23 1.718.23 1.234 0 2.388-.332 3.249-.935C18.556 6.5 19 5.767 19 5s-.444-1.5-1.251-2.065C16.888 2.332 15.734 2 14.5 2z"/>'
  }
})
