/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10.682 12.998a3.671 3.671 0 01-2.604-1.077.5.5 0 01.707-.707 2.685 2.685 0 003.793 0l3.636-3.636c1.046-1.046 1.046-2.747 0-3.793s-2.747-1.046-3.793 0L9.353 6.853a.5.5 0 01-.707-.707l3.068-3.068c1.436-1.436 3.772-1.436 5.207 0s1.436 3.772 0 5.207l-3.636 3.636a3.671 3.671 0 01-2.604 1.077z"/><path pid="1" d="M4.682 18.998a3.671 3.671 0 01-2.604-1.077 3.685 3.685 0 010-5.207l3.636-3.636a3.685 3.685 0 015.207 0 .5.5 0 01-.707.707 2.685 2.685 0 00-3.793 0l-3.636 3.636c-1.046 1.046-1.046 2.747 0 3.793s2.747 1.046 3.793 0l3.068-3.068a.5.5 0 01.707.707l-3.068 3.068a3.671 3.671 0 01-2.604 1.077z"/>'
  }
})
