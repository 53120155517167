/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fan': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 19c-2.538 0-4.923-.988-6.718-2.782S0 12.038 0 9.5c0-2.538.988-4.923 2.782-6.717S6.962.001 9.5.001c2.538 0 4.923.988 6.718 2.782S19 6.963 19 9.5s-.988 4.923-2.782 6.718A9.438 9.438 0 019.5 19zm0-18C4.813 1 1 4.813 1 9.5S4.813 18 9.5 18 18 14.187 18 9.5 14.187 1 9.5 1z"/><path pid="1" d="M15.914 8.439a6.46 6.46 0 00-.783-2.19.499.499 0 10-.865.501c.232.402.411.826.534 1.268A2.446 2.446 0 0014.502 8h-3.001a2.893 2.893 0 00-.132-.161c1.071-.821 1.633-1.964 1.633-3.339 0-1.378-1.122-2.5-2.5-2.5-.854 0-1.609.431-2.061 1.086a6.46 6.46 0 00-2.19.783.499.499 0 10.501.866 5.462 5.462 0 011.268-.534 2.446 2.446 0 00-.018.298V7.5a2.893 2.893 0 00-.161.132C7.02 6.561 5.877 5.999 4.502 5.999a2.503 2.503 0 00-2.5 2.5c0 .854.431 1.609 1.086 2.061a6.46 6.46 0 00.783 2.19.501.501 0 00.683.183.499.499 0 00.182-.683 5.462 5.462 0 01-.534-1.268c.098.012.197.018.298.018h3.001c.042.055.086.109.132.161C6.562 11.982 6 13.124 6 14.5 6 15.878 7.122 17 8.5 17c.854 0 1.609-.431 2.061-1.086a6.46 6.46 0 002.19-.783.499.499 0 10-.501-.865 5.462 5.462 0 01-1.268.534c.012-.098.018-.197.018-.298v-3.001c.055-.042.109-.086.161-.132.821 1.071 1.963 1.633 3.339 1.633 1.378 0 2.5-1.122 2.5-2.5 0-.854-.431-1.609-1.086-2.061zM9.5 11C8.673 11 8 10.327 8 9.5S8.673 8 9.5 8s1.5.673 1.5 1.5-.673 1.5-1.5 1.5zM9 4.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5c0 1.158-.499 2.071-1.483 2.717A2.486 2.486 0 009 7.05V4.5zM4.5 10C3.673 10 3 9.327 3 8.5S3.673 7 4.5 7c1.158 0 2.071.499 2.717 1.483A2.486 2.486 0 007.05 10H4.5zm5.5 4.5c0 .827-.673 1.5-1.5 1.5S7 15.327 7 14.5c0-1.158.499-2.071 1.483-2.717A2.486 2.486 0 0010 11.95v2.55zm4.5-2.5c-1.158 0-2.071-.499-2.717-1.483A2.486 2.486 0 0011.95 9h2.55c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"/>'
  }
})
