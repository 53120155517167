
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Action } from 'vuex-class';

	@Component
	export default class lessonTopic extends Vue{
		// Required { lessonId }, opcional { topic, hideBtn }
        @Prop({ default: () => { return {} } }) readonly options!: any;

        @State( state => state.auth.permission ) userType;

        topicSize: string = 'auto';

        @Action('beforeShowModal') beforeShowModal;

        get refs(): any{
        	return (this as any).$refs;
        }

        mounted(){
        	this.topicSize = this.refs.tag.offsetWidth;
        }
	}
