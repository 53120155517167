<template>
	<div class="content-img">
		<div class="img" v-bind:style="{ background: 'url('+img+')center center / cover no-repeat' }" v-if="type!=='Group'">
			<div class="content-icon one-on-one" :style="{ background : color.primary.main }">
				<svgicon class="icon" name="user"></svgicon>
			</div>
		</div>
		<div class="icon" v-else>
			<svgicon class="icon" name="users2"></svgicon>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props: {
			img: { type: String, default: {}, required: true },
			type: { type: String, default: '', required: true }
		},
		computed: {
			...mapState(['color'])
		}
	}
</script>