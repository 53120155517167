/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit-right2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M1 18.5v-16C1 1.673 1.673 1 2.5 1h1C4.327 1 5 1.673 5 2.5v6a.5.5 0 01-1 0v-6a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6a.5.5 0 011 0v6c0 .827-.673 1.5-1.5 1.5h-1c-.827 0-1.5-.673-1.5-1.5z"/><path pid="1" d="M13.646 15.854a.5.5 0 010-.707l4.146-4.146H3.499a.5.5 0 010-1h14.293l-4.146-4.146a.5.5 0 01.707-.707l5 5a.498.498 0 010 .708l-5 5a.5.5 0 01-.707 0z"/>'
  }
})
