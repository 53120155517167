<template>
	<div id="main-menu" class="d-flex flex-wrap" :class="{ 'compact' : menu.compact, show: menu.showMenuResponsive, 'hide-text' : menu.hideText }">
        <div class="w-100">
			<!-- ================ Btn Schedule ================ -->
			<div class="position-relative w-100 schedule" :style="{ '--bg-color': color[auth.permission].main }">
				<router-link class="btn-schedule" :to="{ name: `schedule-${auth.permission}` }" tag="button" @click.native="setStatusMenuResponsive(false)" :style="{ background: color[auth.permission].main }">
					<svgicon class="icon" name="clock" v-if="menu.compact && (grid == 'lg' || grid == 'xl')"></svgicon>
					<span class="text" v-else>{{ $t('mainMenu.schedule') }}</span>
				</router-link>
			</div>

		    <!-- ================ Menu ================ -->
		    <perfect-scrollbar class="menu navbar w-100 p-0 align-items-start" :key="`menu-${auth.permission}`" :activeColor="auth.permission==='student'?'blue':'green'" tag="nav" :options="{ suppressScrollX: true }">
		    	<ul class="navbar-nav w-100">
		    		<li class="w-100 content-btn" @mouseover="btnActive = menu.compact && activeHover?btn.id:btnActive" @mouseleave="btnActive = menu.compact && activeHover?'':btnActive" :key="`btnMenu-${i}`" v-for="(btn,i) in setMenu">
		    			<a class="btn-menu nav-link" href="#" :class="{ 'active-sub': btnActive==btn.id }" @click.prevent="btnActive = btnActive==btn.id?'':btn.id" v-if="btn.sub">
		    				<svgicon class="icon" :name="btn.icon"></svgicon>
		    				<span class="text">{{ btn.name }}</span>
		    				<svgicon class="icon-sub" name="chevron-down"></svgicon>
		    			</a>
		    			<router-link class="btn-menu nav-link" :to="btn.route" @click.native="setStatusMenuResponsive(false)" v-else>
		    				<svgicon class="icon" :name="btn.icon"></svgicon>
		    				<span class="text">{{ btn.name }}</span>
		    			</router-link>

		    			<!-- ================ Submenu ================ -->
                        <v-slide-animation :key="btn.id" :boundAnimation="false" :show="btn.id == btnActive || (menu.compact && activeHover)" :class="{ 'd-none': hideSub }">
		    			    <ul class="navbar-nav w-100 sub">
		    				    <li class="content-btn d-none d-lg-block" v-if="menu.compact">
		    					    <a class="btn-sub nav-link nav-name" href="#" v-if="btn.sub">{{ btn.name }}</a>
		    					    <router-link class="btn-sub nav-link nav-name" :to="btn.route" v-else><span class="text">{{ btn.name }}</span></router-link>
		    				    </li>
		    				    <li class="content-btn" :key="`btnSubmenu-${j}`" v-for="(btnSub,j) in btn.sub || []">
		    					    <router-link class="btn-sub nav-link" :to="btnSub.route" @click.native="setStatusMenuResponsive(false)">
		    						    <svgicon class="icon" :name="btnSub.icon"></svgicon>
		    						    <span class="text">{{ btnSub.name }}</span>
		    					    </router-link>
		    				    </li>
		    			    </ul>
                        </v-slide-animation>
		    		</li>
		    	</ul>
		    </perfect-scrollbar>
		</div>	

		<!-- ================ Btn Linc App ================ -->
		<div class="position-relative w-100 align-self-end linc-app" :activeColor="auth.permission==='student'?'blue':'green'">
			<router-link class="btn-linc-app" :to="{ name: `app-${auth.permission}` }" tag="button" v-html="$t('mainMenu.appHtml')" @click.native="setStatusMenuResponsive(false)"></router-link>
		</div>

    </div>
</template>
<script>
	import { mapState, mapGetters, mapMutations } from 'vuex';
	export default{
		data(){
            return{
            	timer: '',
                btnActive: '',
                activeHover: true,
                hideSub: false
            }
		},
		computed:{
			...mapState(['menu','auth', 'grid', 'color']),
            ...mapGetters(['permissionFormats']),
            setMenu(){
                const formats = this.permissionFormats?[{
                                    id: 'formats',
                                    name: this.auth.permission == 'student'?this.$t('mainMenu.formatsS'):this.$t('mainMenu.formatsT'),
                                    icon: 'list3',
                                    route: { name: `formats-${this.auth.permission}` }
                                }]:[];

                const tutor = this.auth.permission === 'tutor'?{
                        profile: [{
                            id: 'profile',
                            name: this.$t('mainMenu.profile'),
                            icon: 'user',
                            route: { name: 'profile-tutor' },
                        }],
                        preferences: [{
                            id: 'preferences',
                            name: this.$t('mainMenu.preferences.preferences'),
                            icon: 'cog2',
                            route: '#',
                            sub: [
                                { 
                                    name: this.$t('mainMenu.preferences.subjects'), 
                                    icon: 'hammer-wrench',
                                    route: { name: 'subjects-tutor' }
                                },
                                { 
                                    name: this.$t('mainMenu.preferences.availability'), 
                                    icon: 'clock',
                                    route: { name: 'availability-tutor' } 
                                },
                                { 
                                    name: this.$t('mainMenu.preferences.biography'), 
                                    icon: 'register',
                                    route: { name: 'bio-tutor' } 
                                },
                            ]
                        }]
                    }:{ 
                        profile: [],
                        preferences: []
                    }

                const menu = [
                    {
                        id: 'dashboard',
                        name: this.$t('mainMenu.dashboard'),
                        icon: 'icons',
                        route: { name: `dashboard-${this.auth.permission}` },
                    },
                    ...tutor.profile,
                    {
                        id: 'lessons',
                        name: this.$t('mainMenu.lessons.lessons'),
                        icon: 'pencil-ruler',
                        route: '#',
                        sub: [
                            { 
                                name: this.$t('mainMenu.lessons.manage'), 
                                icon: 'checkmark-circle',
                                route: { name: `lessons-${this.auth.permission}` } 
                            },
                            { 
                                name: this.$t('mainMenu.lessons.history'), 
                                icon: 'bookmark',
                                route: { name: `history-${this.auth.permission}` } 
                            },
                            { 
                                name: this.$t('mainMenu.lessons.calendar'), 
                                icon: 'calendar-full',
                                route: { name: `calendar-${this.auth.permission}` } 
                            }
                        ]
                    },
                    ...tutor.preferences,
                    {
                        id: 'settings',
                        name: this.$t('mainMenu.settings.settings'),
                        icon: 'user',
                        route: '#',
                        sub:[
                            { 
                                name: this.$t('mainMenu.settings.acount'), 
                                icon: 'hammer-wrench',
                                route: { name: `info-${this.auth.permission}` } 
                            },
                            { 
                                name: this.$t('mainMenu.settings.changePassword'), 
                                icon: 'lock',
                                route: { name: `password-${this.auth.permission}` }
                            },
                            { 
                                name: this.$t('mainMenu.settings.notifications'), 
                                icon: 'paper-plane',
                                route: { name: `preferences-${this.auth.permission}` } 
                            }
                        ]
                    },
                    {
                        id: 'chat',
                        name: this.$t('mainMenu.chat'),
                        icon: 'bubble',
                        route: { name: `chat-${this.auth.permission}` } 
                    },
                    ...formats 
                ];

                return menu;
            }
		},
		methods: {
			...mapMutations(['changeTitle','setCompact','setStatusMenuResponsive','setHideText']),
            getWindowWidth(){
                if (window.innerWidth <= 991 && this.menu.compact)
                    this.activeHover = false;
                else
                    this.activeHover = true;
            }
		},
        mounted(){
            this.getWindowWidth();
            window.addEventListener('resize', this.getWindowWidth);
        },
        beforeDestroy(){
            window.removeEventListener('resize', this.getWindowWidth);
        },
        watch: {
            'menu.compact': function(newVal){
                var that = this;
                this.btnActive = '';
                if (!newVal) {
                    this.hideSub = true;
                    this.timer = setTimeout(function(){
                        that.setHideText(false);
                        that.hideSub = false;
                    },500);
                }else{
                    clearTimeout(this.timer);
                    that.setHideText(true);
                }
            }
        }
	}
</script>