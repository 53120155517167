import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { AudioInputDevice,
        MeetingSessionPOSTLogger, 
        DefaultBrowserBehavior, 
        Logger, 
        LogLevel, 
        VideoPriorityBasedPolicy, 
        VideoPriorityBasedPolicyConfig, 
        VoiceFocusSpec,
        VoiceFocusDeviceTransformer,
        VoiceFocusTransformDevice,
        VoiceFocusModelComplexity } from 'amazon-chime-sdk-js';

enum typePermission {
    prompt,
    granted,
    denied,
    deviceEmpty,
    errorSystem,
    errorDevice,
    mute
}

type Devices = {
    microphones: Array<object>,
    cameras: Array<object>,
    speakers: Array<object>,
    videoQuality: Array<object>
}

type Permissions = {
    mic: typePermission,
    cam: typePermission
}

type State = {
    /* Static chime variables */
    logLevel: LogLevel,
    enableUnifiedPlanForChromiumBasedBrowsers: boolean,
    usePriorityBasedDownlinkPolicy: boolean,
    videoPriorityBasedPolicyConfig: VideoPriorityBasedPolicyConfig,
    enableWebAudio: boolean,
    enableSimulcast: boolean,
    spec: VoiceFocusSpec,
    timeoutMs: number,
    MAX_VOICE_FOCUS_COMPLEXITY: VoiceFocusModelComplexity | undefined,
    /* dinamic chime variables */
    meetingSession: any,  
    meetingLogger: Logger | undefined;
    meetingEventPOSTLogger: MeetingSessionPOSTLogger | null,
    defaultBrowserBehaviour: DefaultBrowserBehavior | null,
    priorityBasedDownlinkPolicy: VideoPriorityBasedPolicy | null,
    supportsVoiceFocus: boolean,
    enableVoiceFocus: boolean,
    voiceFocusTransformer: VoiceFocusDeviceTransformer | undefined,
    voiceFocusDevice: VoiceFocusTransformDevice | undefined,
    /* General */
    typePermission: object,
    permissions: Permissions,
    askingForPermissions: boolean,
    devices: Devices,
    camTracksChange: boolean,
    micTracksChange: boolean,
    currentMic: object | null,
    currentCam: object | null,
    currentSpk: string | null,
    currentVideoQuality: string | null,
    currentAudioInputDevice: AudioInputDevice | undefined, 
}

const videoQuality = [ 
    { deviceId: "360p", label: "360p" },
    { deviceId: "480p", label: "480p" },
    { deviceId: "720p", label: "720p" }
]

const state: State = {
    /* Static chime variables */
    logLevel: LogLevel.OFF,
    enableUnifiedPlanForChromiumBasedBrowsers: false,
    usePriorityBasedDownlinkPolicy: true,
    videoPriorityBasedPolicyConfig: VideoPriorityBasedPolicyConfig.Default,
    enableWebAudio: true,
    enableSimulcast: false,
    spec:  { /*name: 'default',
             variant: 'c50', 
             executionPreference: 'auto', 
             simd: 'force' */
           },
    timeoutMs: 5000,
    MAX_VOICE_FOCUS_COMPLEXITY: undefined,
    /* dinamic chime variables */
    meetingSession: null,
    meetingLogger: undefined,
    meetingEventPOSTLogger: null,
    defaultBrowserBehaviour: null,
    priorityBasedDownlinkPolicy: null,
    supportsVoiceFocus: false,
    enableVoiceFocus: false,
    voiceFocusTransformer: undefined,
    voiceFocusDevice: undefined,
    /* General */
    typePermission: typePermission,
    permissions: { mic: typePermission.prompt, cam: typePermission.prompt },
    askingForPermissions: false,
    devices: { microphones: [], cameras: [], speakers: [], videoQuality: videoQuality },
    camTracksChange: false,
    micTracksChange: false,
    currentMic: null,
    currentCam: null,
    currentSpk: null,
    currentVideoQuality: null,
    currentAudioInputDevice: undefined, 
}

export default{
	state,
	getters,
	mutations,
	actions
}