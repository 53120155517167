/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shoe': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.244 4.712a9.037 9.037 0 00-.484-.456c-.074-.542-.376-1.08-.906-1.61C14.253 1.046 13.125 1 13.001 1h-.006a.503.503 0 00-.414.227c-1.379 2.115-3.44 5.227-4.585 6.861.036-.308.039-.54-.016-.728-.339-1.156-1.857-1.348-1.921-1.356a.498.498 0 00-.513.288c-.208.455-.396 1.651-.711 3.725l-.171 1.12c-.494 3.197-.554 3.259-2.092 4.84-.191.196-.407.419-.647.671a3.442 3.442 0 00-.84 1.494c-.141.555-.091 1.04.135 1.332.365.469.79.52.871.526a.504.504 0 00.192-.025c.101-.034 2.497-.86 5.447-3.807 2.778-2.774 3.373-3.944 3.767-4.718.137-.269.227-.446.379-.622.257-.296.822-.911 1.476-1.622l.125-.136.635.777a.5.5 0 00.363.183l.024.001a.5.5 0 00.355-.148c.008-.008.766-.774 1.514-1.592 1.508-1.649 1.631-2.013 1.631-2.29 0-.357-.233-.754-.756-1.288zm-6.122 5.461c-.232.268-.363.525-.515.823-.365.717-.917 1.802-3.583 4.464-2.356 2.353-4.35 3.279-4.906 3.507a.547.547 0 01-.098-.094c-.058-.165.017-.894.628-1.536.236-.248.451-.469.64-.663.798-.821 1.279-1.316 1.61-2.052.312-.696.478-1.552.753-3.333l.171-1.123c.181-1.197.368-2.426.517-3.071.277.096.595.268.678.54.025.139-.056.632-.099.898-.115.707-.178 1.096.054 1.37a.56.56 0 00.426.197c.239 0 .406-.149.461-.197.119-.106.398-.354 3.029-4.298a535.993 535.993 0 002.346-3.553c.34.103 1.017.408 1.91 1.301.882.881.725 1.482.219 2.114-.451.562-1.725 1.948-2.748 3.061-.659.717-1.229 1.337-1.495 1.644zm4.408-2.448c-.37.402-.735.784-.997 1.055l-.372-.455c.835-.912 1.627-1.787 1.984-2.232.174-.217.312-.434.414-.651.206.215.365.411.423.53-.083.147-.385.594-1.452 1.752z"/>'
  }
})
