var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "form-email" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "v-paper w-100 mb-4" },
          [
            _c("div", { staticClass: "v-if-head pl-4 pr-4 pt-3 pb-3" }, [
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("intitutionalEmail.t")))
              ])
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "bound", mode: "out-in" } }, [
              _vm.content == "form"
                ? _c(
                    "div",
                    {
                      key: "form",
                      staticClass: "p-4 d-flex flex-column align-items-center"
                    },
                    [
                      _c("img", {
                        staticClass: "v-institution-logo mb-4",
                        attrs: {
                          src: _vm.institution.icon,
                          alt: _vm.institution.name
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          key: "form-login",
                          staticClass: "w-100",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.send()
                            }
                          }
                        },
                        [
                          _c("v-textField", {
                            ref: "email",
                            staticClass: "pb-2",
                            attrs: {
                              options: _vm.opcInput,
                              name: "institutional-email",
                              label: _vm.$t("intitutionalEmail.label"),
                              type: "email",
                              required: true
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-btn", {
                            staticClass: "mb-4 mt-4 w-100",
                            attrs: {
                              fontSize: "1rem",
                              fontWeight: "400",
                              txt: _vm.$t("intitutionalEmail.btn"),
                              name: "login",
                              type: "submit",
                              height: "40px",
                              spinner: _vm.sending,
                              disabled: _vm.$v.validationGroup.$invalid
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm.content == "plan"
                ? _c(
                    "div",
                    {
                      key: "plan",
                      staticClass: "p-4 d-flex flex-column align-items-center"
                    },
                    [
                      _c("img", {
                        staticClass: "v-institution-logo mb-4",
                        attrs: {
                          src: _vm.institution.icon,
                          alt: _vm.institution.name
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "v-if-txt mb-4 text-center pl-3 pr-3" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("intitutionalEmail.planBasic.d"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "v-if-txt text-center pl-3 pr-3",
                        attrs: { weight: "bold" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("intitutionalEmail.planBasic.iHtml")
                          )
                        }
                      })
                    ]
                  )
                : _vm.content == "alert"
                ? _c(
                    "div",
                    {
                      key: "msg",
                      staticClass: "p-4 d-flex flex-column align-items-center"
                    },
                    [
                      _c("div", { staticClass: "v-if-txt-opps mb-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("intitutionalEmail.invalidEmail.error"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "v-if-txt pb-2 m-0 pl-3 pr-3 text-center",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("intitutionalEmail.invalidEmail.msgHtml")
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("v-btn", {
                        staticClass: "mb-4 mt-4 w-100",
                        attrs: {
                          fontSize: "1rem",
                          fontWeight: "400",
                          txt: _vm.$t("intitutionalEmail.invalidEmail.btn"),
                          name: "prevForm",
                          type: "submit",
                          height: "40px"
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.content = "form"
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }