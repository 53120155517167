var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalVal.modal == "v-modal-attendance"
    ? _c(
        "div",
        { staticClass: "v-joined-students", class: { active: _vm.show } },
        [
          _c("div", {
            staticClass: "blurColor",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "v-joined-students-modal" }, [
            _c(
              "h4",
              { staticClass: "title pt-4 pb-3 pl-5 pr-5 mb-0" },
              [
                _c("span", { staticClass: "d-block w-100" }, [
                  _vm._v(_vm._s(_vm.$t("modals.attendance.t")))
                ]),
                _vm._v(" "),
                _c("svgicon", {
                  staticClass: "btn-close",
                  attrs: { name: "cross2" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pt-3 pl-5 pr-4 mb-3" },
              [
                _c(
                  "perfect-scrollbar",
                  {
                    staticClass: "list-students pl-0 pr-4",
                    attrs: { tag: "ul" }
                  },
                  _vm._l(_vm.currentLesson.students, function(student) {
                    return _c(
                      "li",
                      {
                        staticClass:
                          "student v-check d-flex align-items-center pt-2 pb-2 pr-4",
                        class: { active: student.assistance },
                        on: {
                          click: function($event) {
                            student.assistance = student.assistance ? 0 : 1
                          }
                        }
                      },
                      [
                        _c("v-img-profile", {
                          staticClass: "mr-1",
                          attrs: { img: student.photo, size: "40px" }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col pl-0" }, [
                          _c("span", { staticClass: "name d-block w-100" }, [
                            _vm._v(_vm._s(student.name))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "subject d-block w-100" }, [
                            _vm._v(_vm._s(student.subject))
                          ])
                        ])
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pl-4 pr-4 pb-4" },
              [
                _c("v-btn", {
                  staticClass: "w-100",
                  attrs: {
                    txt: _vm.$t("modals.attendance.btn"),
                    btn: "warning",
                    height: "44px",
                    fontSize: "20px",
                    disabled: _vm.sending
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.send()
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }