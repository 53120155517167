var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-format-question-yes-no" }, [
    _c(
      "div",
      { staticClass: "v-table-questions pl-3 pr-3 mb-4" },
      [
        _c("div", { staticClass: "v-fq-title row pt-sm-4 pt-3 pb-sm-4 pb-3" }, [
          _c("div", { staticClass: "col pl-sm-4 pl-3" }, [
            _c("div", { staticClass: "v-fq-title-txt" }, [
              _vm._v(_vm._s(_vm.$t("formats.fm2.tableT")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto d-none d-sm-block" }, [
            _c("div", { staticClass: "row text-center" }, [
              _c("div", { staticClass: "v-answer col-auto pl-2 pr-2" }, [
                _c("div", { staticClass: "v-fq-title-txt" }, [
                  _vm._v(_vm._s(_vm.$t("formats.fm2.tableAYes")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "v-answer col-auto pl-2 pr-2" }, [
                _c("div", { staticClass: "v-fq-title-txt" }, [
                  _vm._v(_vm._s(_vm.$t("formats.fm2.tableANo")))
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.questions, function(q, i) {
          return _c("v-questions-yes-no", {
            key: `question-${q.id}`,
            class: { "bg-dark": (i + 1) % 2 == 0 },
            attrs: {
              statusFormat: _vm.statusFormat,
              question: q,
              error: _vm.error
            },
            on: { handleAnswer: _vm.handleAnswer }
          })
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm.statusFormat
      ? _c("div", { staticClass: "d-flex justify-content-end" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap justify-content-center" },
            [
              _c("span", { staticClass: "quality-txt mr-2" }, [
                _vm._v(_vm._s(_vm.$t("formats.fm2.quality")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "quality-result pl-2 pr-2 text-center" },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.score) + "\n            "
                  )
                ]
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }