var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalVal.modal == "v-modal-joinedStudents"
    ? _c(
        "div",
        { staticClass: "v-joined-students", class: { active: _vm.show } },
        [
          _c("div", {
            staticClass: "blurColor",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "v-joined-students-modal" }, [
            _c(
              "h4",
              { staticClass: "title pt-4 pb-3 pl-5 pr-5 mb-0" },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.$t("modals.join.t")) + "\n\t\t\t\t"
                ),
                _c("svgicon", {
                  staticClass: "btn-close",
                  attrs: { name: "cross2" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pt-3 pb-4 pl-5 pr-4" },
              [
                _c(
                  "perfect-scrollbar",
                  {
                    staticClass: "list-students pl-0 pr-4",
                    attrs: { tag: "ul" }
                  },
                  _vm._l(_vm.currentLesson.students, function(student) {
                    return _c(
                      "li",
                      {
                        key: student.id,
                        staticClass:
                          "student d-flex align-items-center pt-2 pb-2 pr-4",
                        class: student.status ? "join" : ""
                      },
                      [
                        _c("v-img-profile", {
                          staticClass: "mr-1",
                          attrs: { img: student.photo, size: "40px" }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col pl-0" }, [
                          _c("span", { staticClass: "name d-block w-100" }, [
                            _vm._v(_vm._s(student.name))
                          ]),
                          _vm._v(" "),
                          !student.guest
                            ? _c(
                                "span",
                                { staticClass: "subject d-block w-100" },
                                [_vm._v(_vm._s(student.career))]
                              )
                            : _c(
                                "span",
                                { staticClass: "subject d-block w-100" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      student.guest
                                        ? _vm.$t(
                                            "scheduleT.students.unconfirmed"
                                          )
                                        : ""
                                    )
                                  )
                                ]
                              )
                        ])
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }