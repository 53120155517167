<template>
	<div class="v-emojis">
		<div class="btn-emoji" @click="!isMovile?toggleEmojis():$emit('showEmojis')">
			<div class="btn-emoji-hover" v-if="btnEmoji == 'focus' || btnEmoji == 'hover'"></div>
			<input class="input-hide" type="text" ref="btn-emojis" @focus="btnEmoji = 'focus'" @blur="blurEmojis" readonly>
			<svgicon class="emoji-icon" name="smile-keyboard" original/>
			<div class="hover-txt" v-if="btnEmoji == 'blur'">{{ $t('chat.emoji') }}</div>
		</div>
		<VEmojiPicker :emojisByRow="7" :emojiSize="24" :i18n="i18n" :exceptEmojis="exceptEmojis" :emojiWithBorder="false" @select="e => $emit('insertEmoji', e)" v-if="(btnEmoji == 'focus' || btnEmoji == 'hover') && !disabled" @mouseover.native="hoverEmojis()" @mouseleave.native="leaveEmojis()"/>
	</div>
</template>

<script>
    import { mapState } from 'vuex';
	export default{
		props: {
            disabled: { type: Boolean, default: false, required: false }
		},
		data(){
			return{
				btnEmoji: 'blur', // blur, focus, hover
				exceptEmojis: [
                    {
                        data: '☹️',
                        category: 'Peoples',
                        aliases: [ 'frowning_face' ]
                    }
                ],
			}
		},
		computed:{
            ...mapState(['isMovile']),
			i18n(){
                return{
                    search: this.$t('chat.emojiPicker.search'),
                    categories: {
                        Activity: this.$t('chat.emojiPicker.activity'),
                        Flags: this.$t('chat.emojiPicker.flags'),
                        Foods: this.$t('chat.emojiPicker.foods'),
                        Frequently: this.$t('chat.emojiPicker.frequently'),
                        Objects: this.$t('chat.emojiPicker.objects'),
                        Nature: this.$t('chat.emojiPicker.nature'),
                        Peoples: this.$t('chat.emojiPicker.peoples'),
                        Symbols: this.$t('chat.emojiPicker.symbols'),
                        Places: this.$t('chat.emojiPicker.places')
                    }
                }
            }
		},
		methods: {
			toggleEmojis(){
                if (this.btnEmoji == 'blur')
                    this.focusEmojis();
            },
            focusEmojis(){
                if (this.btnEmoji == 'blur' || this.btnEmoji == 'focus'){
                	this.btnEmoji = 'focus';
                    this.$refs['btn-emojis'].focus();
                }
            },
            blurEmojis(){
                if (this.btnEmoji != 'hover')
                    this.btnEmoji = 'blur';
            },
            hoverEmojis(){
                if (this.btnEmoji == 'focus')
                    this.btnEmoji = 'hover';
            },
            leaveEmojis(){
                if (this.btnEmoji == 'hover') {
                    this.btnEmoji = 'focus'; 
                    this.focusEmojis();
                }
            }
		}
	}
</script>