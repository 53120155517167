var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pb-5", attrs: { id: "account-details" } },
    [
      _c("v-search", { ref: "search", staticClass: "mb-4" }),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c(
          "div",
          { staticClass: "details col-md" },
          [
            _c(
              "form",
              {
                staticClass:
                  "d-flex paper justify-content-center flex-wrap p-4 mb-3",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.changeDatas()
                  }
                }
              },
              [
                _c("div", { staticClass: "editImg mb-3 position-relative" }, [
                  _c(
                    "div",
                    {
                      staticClass: "content-img h-100 w-100",
                      style: {
                        background:
                          "url(" +
                          _vm.bgIMG +
                          ")center center / cover no-repeat"
                      },
                      on: { click: _vm.clickInputFile }
                    },
                    [
                      _c("input", {
                        staticClass: "fileinput",
                        attrs: {
                          id: "input-file",
                          type: "file",
                          accept: "image/png,image/jpg,image/jpeg"
                        },
                        on: { change: _vm.upload }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: { slot: "upload-label", for: "fileInput" },
                          slot: "upload-label"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content-icon p-right bgColor-student-main"
                            },
                            [
                              _c("svgicon", {
                                staticClass: "icon",
                                attrs: { name: "camera2" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "img-edit bgColor-student-light" },
                            [
                              _c("svgicon", {
                                staticClass: "icon",
                                attrs: { name: "pencil3" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("v-textField", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.name"),
                    required: true
                  },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                }),
                _vm._v(" "),
                _c("v-textField", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.lastName"),
                    required: true
                  },
                  model: {
                    value: _vm.form.last_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "last_name", $$v)
                    },
                    expression: "form.last_name"
                  }
                }),
                _vm._v(" "),
                _c("v-textField", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.email"),
                    disabled: true
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                }),
                _vm._v(" "),
                _c("v-textField", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.campus"),
                    disabled: true
                  },
                  model: {
                    value: _vm.campus,
                    callback: function($$v) {
                      _vm.campus = $$v
                    },
                    expression: "campus"
                  }
                }),
                _vm._v(" "),
                _c("v-textField", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.phone"),
                    type: "number"
                  },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                }),
                _vm._v(" "),
                _c("v-datePicker", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.birthday"),
                    required: true
                  },
                  model: {
                    value: _vm.form.birthday,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "birthday", $$v)
                    },
                    expression: "form.birthday"
                  }
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.gender"),
                    defaultOpc: _vm.$t("signUp.form.gender.placeholder"),
                    opc: _vm.gender,
                    fieldVal: "val",
                    fieldTxt: "txt",
                    required: true
                  },
                  model: {
                    value: _vm.form.gender,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "gender", $$v)
                    },
                    expression: "form.gender"
                  }
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.major"),
                    defaultOpc: _vm.$t("signUp.form.career.placeholder"),
                    opc: _vm.careers,
                    fieldVal: "id",
                    fieldTxt: "name",
                    required: true
                  },
                  model: {
                    value: _vm.form.career_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "career_id", $$v)
                    },
                    expression: "form.career_id"
                  }
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    label: _vm.$t("accountDetails.form.semester"),
                    defaultOpc: _vm.$t("signUp.form.period.placeholder"),
                    opc: _vm.semesters,
                    fieldVal: "id",
                    fieldTxt: "period",
                    required: true
                  },
                  model: {
                    value: _vm.form.period_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "period_id", $$v)
                    },
                    expression: "form.period_id"
                  }
                }),
                _vm._v(" "),
                _c("v-btn", {
                  staticClass: "w-100 mt-3",
                  attrs: {
                    txt: _vm.$t("accountDetails.form.btn"),
                    type: "submit",
                    disabled: _vm.$v.form.$invalid || _vm.sending
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("v-circles", { attrs: { vCircle: 3, size: "300px" } }),
            _vm._v(" "),
            _c("v-circles", { attrs: { vCircle: 4, size: "200px" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-crop-image", {
        attrs: {
          bgIMG: _vm.imgFile,
          show: _vm.statusCropImage,
          statusBtn: _vm.statusBtn
        },
        model: {
          value: _vm.imgCrob,
          callback: function($$v) {
            _vm.imgCrob = $$v
          },
          expression: "imgCrob"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }