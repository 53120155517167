
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { State, Mutation, Action } from 'vuex-class';

    enum Step{
        menu,
        identificationFile,
        studySkills,
        learningInventory,
        selfEsteem,
        assertiveness
    }
    @Component
    export default class Format extends Vue{
        @State(state => state.auth.user.is_tutor) isTutor;
        @State(state => state.auth.permission) userType;
        @State(state => state.auth.formats) formats;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.color ) color;
        
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;

        private step = Step;
        statusTooltip: boolean = true;
        currentStep: Step = Step.menu;
        statusAlert: boolean = true;
        studentFormats: any = null;
        spinner: boolean = false;
        currentStudent: any = null;

        get emit(): any{
            return (this as any).$emit;
        }

        get refs(): any{
            return (this as any).$refs;
        }

        get statusPrint(): boolean{
            const st = this.studentFormats?.forms;
            
            if(this.currentStep == this.step.identificationFile && (st?st.identity:this.formats.identity))
                return true;
            else if(this.currentStep == this.step.studySkills && (st?st.studySkills:this.formats.studySkills))
                return true;
            else if(this.currentStep == this.step.learningInventory && (st?st.learningStyles:this.formats.learningStyles))
                return true;
            else if(this.currentStep == this.step.selfEsteem && (st?st.selfEsteem:this.formats.selfEsteem))
                return true;
            else if(this.currentStep == this.step.assertiveness && (st?st.assertiveness:this.formats.assertiveness))
                return true;

            return false;
        }

        get localStorage(): any{
            return (this as any).$localStorage;
        }

        created(): void{
            const status = this.localStorage.get('statusTooltipFormats');
            this.statusTooltip = status == 'false'?false:true;
        }

        beforeDestroy() {
            if(this.userType == 'tutor')
                this.notification({ type: "warning", noClose:'close', msg: '' });
        }

        closeTooltipformats(): void{
            this.localStorage.set('statusTooltipFormats', false);
            this.statusTooltip = false;
        }

        handleStep(step: Step): void{
            this.currentStep = step;
        }

        print(): void{
            (this as any).$refs.format?.print();
        }

        handleStudent(student): void{
            this.currentStudent = student;
        }

        openMenu(): void{
            if(this.userType == 'student' && this.formats.identity && this.formats.studySkills && this.formats.learningStyles && this.formats.selfEsteem && this.formats.assertiveness)
                this.$router.push({ name : `dashboard-student` }); 
            else
                this.currentStep = this.step.menu;
        }

        @Watch('currentStudent')
        getStudentFormat(): void{
            this.spinner = true;
            this.studentFormats = null;
            this.notification({ type: "warning", noClose:'close', msg: '' });
               
            this.axios({ config: {
                url: `${this.api}/tecnm/forms/status`,
                method: 'GET',
                params: { studentId: this.currentStudent.id },
                headers: { Authorization: this.token }
            }}).then( resp => resp.data )
               .then( data => {
                   this.studentFormats = { studentId: this.currentStudent.id, forms: data.forms };
                   this.spinner = false;

                   const { assertiveness, identity, learningStyles, selfEsteem, studySkills } = data.forms;
                   if(!assertiveness || !identity || !learningStyles || !selfEsteem || !studySkills)
                      this.notification({ type: "warning", noClose: true, msg: 'Este usuario no ha llenado ningún formato de estudiante'});
            }).catch( err => {
                this.spinner = false;
                console.log(err);
            } );
        }
    }
