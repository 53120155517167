/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock2': {
    width: 81,
    height: 83,
    viewBox: '0 0 81 83',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M60.3 34.806h-2.68v-10.04C57.62 11.11 46.5 0 32.83 0S8.04 11.11 8.04 24.766v10.04H6.7C3.006 34.806 0 37.81 0 41.5v34.806C0 79.997 3.006 83 6.7 83h53.6c3.694 0 6.7-3.003 6.7-6.694V41.5c0-3.69-3.006-6.694-6.7-6.694zm-49.58-10.04c0-12.18 9.919-22.089 22.11-22.089 12.191 0 22.11 9.91 22.11 22.09v10.04h-5.36v-10.04c0-9.229-7.513-16.735-16.75-16.735s-16.75 7.506-16.75 16.734v10.04h-5.36v-10.04zm36.18 10.04H18.76v-10.04c0-7.751 6.311-14.056 14.07-14.056S46.9 17.015 46.9 24.766v10.04zm17.42 41.5a4.023 4.023 0 01-4.02 4.017H6.7a4.023 4.023 0 01-4.02-4.017V41.5a4.023 4.023 0 014.02-4.016h53.6a4.023 4.023 0 014.02 4.016v34.806zM33.5 48.194a5.363 5.363 0 00-5.36 5.354v10.71a5.363 5.363 0 005.36 5.355 5.363 5.363 0 005.36-5.355v-10.71a5.363 5.363 0 00-5.36-5.354zm2.68 16.064a2.681 2.681 0 01-2.68 2.677c-1.478 0-2.68-1.2-2.68-2.677v-10.71a2.681 2.681 0 012.68-2.677c1.478 0 2.68 1.2 2.68 2.677v10.71zM68 31.543c0 .852.69 1.543 1.543 1.543h9.038a1.543 1.543 0 100-3.086h-9.038c-.852 0-1.543.69-1.543 1.543zM65.577 25.505c.413.003.81-.16 1.102-.452l6.393-6.392a1.559 1.559 0 00-2.205-2.204l-6.392 6.392a1.543 1.543 0 001.102 2.656z"/></g>'
  }
})
