/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<path pid="0" d="M13 0v2.803c0 .11.09.2.2.2h1.528L13 1.277V0l3 3.003V17.5c0 .827-.673 1.5-1.5 1.5h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0H13zm-1 1H1.5a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V4h-2a1 1 0 01-1-1V1zM8.55 11a.5.5 0 110 1h-5a.5.5 0 110-1h5zm3.95-2a.5.5 0 010 1h-9a.5.5 0 010-1h9zm0-2a.5.5 0 010 1h-9a.5.5 0 010-1h9z" _fill="#000" fill-rule="nonzero"/>'
  }
})
