/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'road': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M.501 20a.5.5 0 01-.489-.603l4-19a.5.5 0 01.978.206l-4 19A.5.5 0 01.501 20zM18.499 20a.502.502 0 01-.489-.397l-4-19a.5.5 0 01.978-.206l4 19a.5.5 0 01-.489.603zM9.5 3a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM9.5 10a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v4a.5.5 0 01-.5.5zM9.5 20a.5.5 0 01-.5-.5v-6a.5.5 0 011 0v6a.5.5 0 01-.5.5z"/>'
  }
})
