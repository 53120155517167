/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.854 19.354l6-6a.5.5 0 00-.707-.707l-5.146 5.146V1.5a.5.5 0 00-1 0v16.293l-5.146-5.146a.5.5 0 00-.707.708l6 6a.5.5 0 00.707 0z"/>'
  }
})
