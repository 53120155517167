var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "notification-preferences" } }, [
    _c(
      "div",
      {
        staticClass:
          "paper row ml-md-0 mr-md-0 p-sm-4 pt-4 pb-4 justify-content-center"
      },
      [
        _c(
          "div",
          { staticClass: "emails order-1 order-md-0 col-12 col-md-6 mb-5" },
          [
            _c("h3", { staticClass: "title mb-3 mt-3" }, [
              _vm._v(_vm._s(_vm.$t("notificationsPreferences.titleEmail")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.send.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "paper p-4 mb-4" }, [
                  _c("div", { staticClass: "general" }, [
                    _c("h4", { staticClass: "title color-tutor-main mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("notificationsPreferences.general")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkBox mb-4 pb-4" }, [
                      _c("h5", { staticClass: "title mb-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.newsletter.t"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "descrip mb-0",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.newsletter.dHtml"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "v-checkbox v-green",
                          class: { active: _vm.form.newsletter }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.newsletter,
                                expression: "form.newsletter"
                              }
                            ],
                            attrs: { type: "checkbox", value: "newsletter" },
                            domProps: {
                              checked: Array.isArray(_vm.form.newsletter)
                                ? _vm._i(_vm.form.newsletter, "newsletter") > -1
                                : _vm.form.newsletter
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.newsletter,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "newsletter",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "newsletter",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "newsletter",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "newsletter", $$c)
                                }
                              }
                            }
                          })
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "lessons" }, [
                    _c("h4", { staticClass: "title color-tutor-main mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("notificationsPreferences.lessons")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkBox mb-4" }, [
                      _c("h5", { staticClass: "title mb-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_request.t"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "descrip" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_request.d"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "v-checkbox v-green",
                          class: { active: _vm.form.lesson_request_t }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.lesson_request_t,
                                expression: "form.lesson_request_t"
                              }
                            ],
                            attrs: { type: "checkbox", value: "request" },
                            domProps: {
                              checked: Array.isArray(_vm.form.lesson_request_t)
                                ? _vm._i(_vm.form.lesson_request_t, "request") >
                                  -1
                                : _vm.form.lesson_request_t
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.lesson_request_t,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "request",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_request_t",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_request_t",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "lesson_request_t", $$c)
                                }
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkBox mb-4" }, [
                      _c("h5", { staticClass: "title mb-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_cancelled.t"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "descrip" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_cancelled.d"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "v-checkbox v-green",
                          class: { active: _vm.form.lesson_cancelled_t }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.lesson_cancelled_t,
                                expression: "form.lesson_cancelled_t"
                              }
                            ],
                            attrs: { type: "checkbox", value: "cancelled" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.lesson_cancelled_t
                              )
                                ? _vm._i(
                                    _vm.form.lesson_cancelled_t,
                                    "cancelled"
                                  ) > -1
                                : _vm.form.lesson_cancelled_t
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.lesson_cancelled_t,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "cancelled",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_cancelled_t",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_cancelled_t",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "lesson_cancelled_t", $$c)
                                }
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkBox mb-3" }, [
                      _c("h5", { staticClass: "title mb-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_summary.t"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "descrip" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.lesson_summary.d"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "v-checkbox v-green",
                          class: { active: _vm.form.lesson_summary_t }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.lesson_summary_t,
                                expression: "form.lesson_summary_t"
                              }
                            ],
                            attrs: { type: "checkbox", value: "simmary" },
                            domProps: {
                              checked: Array.isArray(_vm.form.lesson_summary_t)
                                ? _vm._i(_vm.form.lesson_summary_t, "simmary") >
                                  -1
                                : _vm.form.lesson_summary_t
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.lesson_summary_t,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "simmary",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_summary_t",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "lesson_summary_t",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "lesson_summary_t", $$c)
                                }
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkBox mb-3" }, [
                      _c("h5", { staticClass: "title mb-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.upcoming_lessons.t"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "descrip",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "notificationsPreferences.checkboxes.upcoming_lessons.dHtml"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "v-checkbox v-green",
                          class: { active: _vm.form.upcoming_lessons_t }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.upcoming_lessons_t,
                                expression: "form.upcoming_lessons_t"
                              }
                            ],
                            attrs: { type: "checkbox", value: "reminder" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.upcoming_lessons_t
                              )
                                ? _vm._i(
                                    _vm.form.upcoming_lessons_t,
                                    "reminder"
                                  ) > -1
                                : _vm.form.upcoming_lessons_t
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.upcoming_lessons_t,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "reminder",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "upcoming_lessons_t",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "upcoming_lessons_t",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "upcoming_lessons_t", $$c)
                                }
                              }
                            }
                          })
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("v-btn", {
                  staticClass: "w-100 mt-3",
                  attrs: {
                    txt: _vm.$t("notificationsPreferences.btn"),
                    type: "submit",
                    disabled: _vm.sending
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("v-circles", { attrs: { vCircle: 3, size: "300px" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "notifications order-0 order-md-1 col-12 col-md-6 mb-4"
          },
          [
            _c("h3", { staticClass: "title mb-3 mt-3" }, [
              _vm._v(_vm._s(_vm.$t("notificationsPreferences.titlePush")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paper" }, [
              _c("p", { staticClass: "descrip mb-4" }, [
                _vm._v(_vm._s(_vm.$t("notificationsPreferences.descrip")))
              ]),
              _vm._v(" "),
              _c("h4", { staticClass: "title-app mb-3" }, [
                _vm._v(_vm._s(_vm.$t("notificationsPreferences.app")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://play.google.com/store/apps/details?id=mx.linc.institutions&referrer=utm_source%3Dwebsite",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-app",
                    attrs: {
                      src: _vm.$t("googlePlay"),
                      alt: _vm.$t("image.googlePlay")
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://itunes.apple.com/app/apple-store/id1424076127?mt=8",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-app pl-2",
                    attrs: {
                      src: _vm.$t("appStore"),
                      alt: _vm.$t("image.appStore")
                    }
                  })
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }