/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-divert': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.5 4h-6a.5.5 0 000 1h4.793L8.5 14.793.854 7.147a.5.5 0 00-.707.707l8 8a.498.498 0 00.708 0L19.001 5.708v4.793a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"/>'
  }
})
