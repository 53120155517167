var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "reset-password" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c("div", { staticClass: "v-paper w-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3"
            },
            [
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("resetPassword.t")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4 d-flex flex-column align-items-center" },
            [
              _c(
                "div",
                { staticClass: "bg-icon mb-4" },
                [
                  _c("svgicon", {
                    staticClass: "inset-icon lock2",
                    attrs: { name: "lock2", color: "#fff" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "w-100",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.send()
                    }
                  }
                },
                [
                  _c("v-textField", {
                    ref: "password",
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("resetPassword.form.password"),
                      type: "password",
                      maxlength: "50",
                      required: true,
                      name: "password",
                      options: _vm.opcInput,
                      error: !_vm.$v.form.password.minLength,
                      iconEye: true
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textField", {
                    ref: "password",
                    staticClass: "mb-2",
                    attrs: {
                      label: _vm.$t("resetPassword.form.samePassword"),
                      type: "password",
                      maxlength: "50",
                      required: true,
                      name: "password2",
                      options: _vm.opcInput,
                      error:
                        !_vm.$v.form.password2.sameAs ||
                        !this.$v.form.password2.minLength,
                      iconEye: true
                    },
                    model: {
                      value: _vm.form.password2,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password2", $$v)
                      },
                      expression: "form.password2"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.form.password2.sameAs &&
                  _vm.form.password.length >= 6 &&
                    _vm.form.password2.length >= 6
                    ? _c("p", { staticClass: "v-if-alert text-left mb-1" }, [
                        _vm._v(_vm._s(_vm.$t("resetPassword.step1")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.form.password.minLength ||
                  !this.$v.form.password2.minLength
                    ? _c("p", { staticClass: "v-if-alert text-left" }, [
                        _vm._v(_vm._s(_vm.$t("resetPassword.step2")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-btn", {
                    staticClass: "mt-4 w-100",
                    attrs: {
                      fontSize: "1rem",
                      fontWeight: "400",
                      txt: _vm.$t("resetPassword.btn"),
                      height: "40px",
                      type: "submit",
                      name: "resetPassword",
                      spinner: _vm.sending,
                      disabled: _vm.$v.form.$invalid
                    }
                  })
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }