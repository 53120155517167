/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#FFF" cx="16.8" cy="16.8" r="16.8"/><path pid="1" d="M20.611 9.593c-.465-.465-1.25-.448-1.735.038l-.296.295c-.486.486-.503 1.27-.038 1.736l.363.362-4.342 2.308c-.456-.271-.878-.42-1.32-.36a.421.421 0 00-.104.025l-1.459.61c-.256.103-.345.49-.155.676l2.569 2.57-4.265 6.493a.419.419 0 00.035.522.419.419 0 00.522.035l6.493-4.266 2.569 2.57c.187.19.573.1.676-.156l.61-1.458a.421.421 0 00.025-.105c.061-.441-.088-.863-.36-1.32l2.308-4.342.363.363c.465.465 1.249.448 1.735-.038l.296-.295c.486-.486.503-1.27.037-1.736l-4.527-4.527zm-.591.591l4.527 4.527c.162.162.156.41-.012.579l-.296.295c-.169.17-.417.175-.578.013l-4.528-4.527c-.161-.162-.156-.41.013-.579l.295-.295c.17-.17.417-.174.579-.013zm-.518 2.437l2.608 2.609-2.227 4.19c-.534-.696-1.279-1.49-2.18-2.392-.901-.9-1.696-1.645-2.392-2.18l4.19-2.227zm-2.39 4.998c.936.936 1.694 1.748 2.188 2.41.481.645.66 1.142.644 1.355l-.334.802-7.065-7.065.802-.333c.213-.017.71.162 1.355.643.662.494 1.474 1.253 2.41 2.188zm-2.434.817l1.618 1.618-4.72 3.102 3.102-4.72z" _fill="#3A506B" fill-rule="nonzero"/></g>'
  }
})
