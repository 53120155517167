
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';
	import { modal } from '@/mixins/index.js'

	@Component({
		mixins: [ modal ]
	})
	export default class MeetingEnded extends Vue{
		@Prop({ type: Boolean, default: false }) readonly showModal!: boolean;

		eventListener: boolean = false;

		@State( state => state.auth.permission ) permission;

		get emit(){
        	return(this as any).$emit;
        }

		@Watch('showModal')
        handleModal(newVal: boolean){
        	if (newVal)
        		(this as any).show = true;
        }
	}
