<template>
	<div class="v-range-time" :class="{ disabled: active }">
		<div class="row align-items-center">
			<div class="content-t col col-sm-6 pr-2 pr-sm-3">
				<div class="v-select2">
					<div class="v-select2-selected-opc d-flex align-items-center">
						<input class="v-select2-selected w-100" type="text" readonly @focus="statusFrom=true" @blur="statusFrom=false" :value="$moment(value.from, 'HH:mm').format('LT')" :disabled="active" />
						<svgicon class="v-select2-icon" name="chevron-down" />
					</div>
                    <div class="v-select2-options" v-if="statusFrom || statusFromHover" @mouseover="statusFromHover=true" @mouseleave="statusFromHover = false">
                        <perfect-scrollbar class="w-100 h-100 pl-0 mb-0" tag="ul"  :options="opc" >
                            <li class="v-select2-opc" v-for="(opc, i) in times" @click="handleTime('from',opc.time)" :class="{  disabled : active || validationFrom(opc), selected : selectRange(opc) }">{{ opc.format }}</li>
                        </perfect-scrollbar>
                    </div>
				</div>
			</div>
			<div class="content-t col col-sm-6 pl-2 pl-sm-3">
				<div class="v-select2">
					<div class="v-select2-selected-opc d-flex align-items-center">
						<input class="v-select2-selected w-100" type="text" readonly @focus="statusTo=true" @blur="statusTo=false" :value="$moment(value.to, 'HH:mm').format('LT')" :disabled="active" />
						<svgicon class="v-select2-icon" name="chevron-down" />
					</div>
                    <div class="v-select2-options" v-if="statusTo || statusToHover" @mouseover="statusToHover=true" @mouseleave="statusToHover = false">
                        <perfect-scrollbar class="w-100 h-100 pl-0 mb-0" tag="ul"  :options="opc" >
                            <li class="v-select2-opc" v-for="opc in times" @click="handleTime('to',opc.time)" :class="{ disabled : active || validationTo(opc), selected : selectRange(opc) }">{{ opc.format }}</li>
                        </perfect-scrollbar>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			options : { type: Array, default: [], required: true },
            initTime: { type: String, default: '06:00', required: false },
            endTime: { type: String, default: '21:00', required: false },
            minRangetime: { type: Number, default: 30, required: false },
			prev: '', // Prev schedule time
			next: '', // Next schedule time
			activeTimes: '',
            active: false,
			value: '',
		},
		data(){
			return{
				time: { from: '', to: '' },
				times: [],
				pv: '', // Last prev schedule time
				nt: '', // Last next schedule time
				statusTo : false,
				statusFrom: false,
				statusFromHover: false,
				statusToHover: false,
                opc: {
                    suppressScrollX: true
                },
				toWait: false
			}
		},
		methods: {
			handleTime(rg,time){
                if (!this.active) {
                    this.pv = { from: this.$moment(this.prev.from, 'HH:mm'), to: this.$moment(this.prev.to, 'HH:mm') };
                    this.nt = { from: this.$moment(this.next.from, 'HH:mm'), to: this.$moment(this.next.to, 'HH:mm') };
                    if (rg === 'from') 
                       this.statusFromHover = false;
                   else
                       this.statusToHover = false;
                   this.time[rg] = time;
                }
			},
			setFull(newVal,oldVal,time){
                if (time=='to' && oldVal != '') { // If second time change
                	const toN = this.$moment(newVal, 'HH:mm'), // New second time
                          toO = this.$moment(oldVal, 'HH:mm'), // Old second time
                          from = this.$moment(this.value.from, 'HH:mm'), // New fisrt time
                          next = this.$moment(this.next.from, 'HH:mm');
                    if (toN.isSameOrBefore(toO)) // IF new second time (to) is lester than old second time (to). Remove hours to the schedule
                        for (var i = 0; i < this.times.length; i++) {
                        	const time = this.$moment(this.times[i].time, 'HH:mm');
                        	if (time.isAfter(toO)) break;
                    	    else if (time.isBetween(from,toO, null, '[]')) 
                                if (time.isAfter(toN) && !time.isSame(next))
                                    this.times[i] = { time: this.times[i].time, format: this.times[i].format };
                        }
                    else// IF new second time (to) is greater than old second time (to). Add missing hours to the schedule
                        for (var i = 0; i < this.times.length; i++) {
                        	const time = this.$moment(this.times[i].time, 'HH:mm');
                        	if (time.isAfter(next)) break;
                            else if(time.isBetween(from,toN, null, '[]'))
                            	if (this.times[i].full) continue;
                                else if(time.isSameOrAfter(toO))
                                    this.times[i].full = true; 
                        }           
                }else if (time=='from' && oldVal != ''){ // If fisrt time change
                    const fromN = this.$moment(newVal, 'HH:mm'), // New fisrt time
                          fromO = this.$moment(oldVal, 'HH:mm'), // Old fisrt time
                          to = this.$moment(this.value.to, 'HH:mm'), // Old second time
                          prev = this.$moment(this.prev.from, 'HH:mm');
                    if (fromN.isSameOrAfter(to)){// IF new fist time (from) is greater than old fisrt time (from). Remove old schedule
                    	this.toWait = true;
                    	for (var i = 0; i < this.times.length; i++){
                    		const time = this.$moment(this.times[i].time, 'HH:mm');
                    		if (time.isBetween(fromO,to, null, '[]') && !time.isSame(fromN) && !time.isSame(this.nt.from) && (!time.isSame(this.pv.to) || !this.pv.to.isValid()))
                    			this.times[i] = { time: this.times[i].time, format: this.times[i].format };
                    		else if (time.isSame(fromN)){
                    			this.times[i].full = true;
                    			this.times[i+1].full = true;
                    			this.handleTime('to',this.times[i+1].time);
                    			break;
                    		}
                        }
                    }
                    else if (fromN.isBefore(this.pv.from)){// IF new fist time (from) is laster than prev schedule. Remove old schedule
                    	this.toWait = true;
                    	let toVal = {};
                    	for (var i = 0; i < this.times.length; i++){
                    		const time = this.$moment(this.times[i].time, 'HH:mm');
                            if (time.isSame(fromN)){
                    			this.times[i].full = true;
                    			this.times[i+1].full = true;
                    			toVal = this.times[i+1].time;
                    		}
                            else if (time.isBetween(fromO,to, null, '[]') && !time.isSame(this.pv.to) && (!time.isSame(this.nt.from) || !this.nt.from.isValid()))
                    			this.times[i] = { time: this.times[i].time, format: this.times[i].format };
                    		else if(time.isAfter(to)) break;
                        }
                        this.handleTime('to', toVal);
                    }
                    else if (fromN.isSameOrAfter(fromO))
                        for (var i = 0; i < this.times.length; i++) {
                        	const time = this.$moment(this.times[i].time, 'HH:mm');
                        	if (time.isSameOrAfter(fromN)) break;
                        	else if(time.isBefore(fromO)) continue;
                    	    else this.times[i] = { time: this.times[i].time, format: this.times[i].format };         
                        }
                    else if (fromN.isSameOrBefore(fromO))
                        for (var i = 0; i < this.times.length; i++) {
                        	const time = this.$moment(this.times[i].time, 'HH:mm');
                        	if (time.isSameOrAfter(fromO)) break;
                        	else if(time.isBefore(fromN)) continue;
                    	    else this.times[i].full = true;         
                        }    
                }
                this.$emit('input',this.time);
                this.$emit('updateTimes', this.times);
			},
			validationFrom(opc){ // Disabled times (from)
				const opcTime = this.$moment(opc.time, 'HH:mm');

				return !(((!opc.full && opc.time !== this.endTime) || 
                         this.activeTimes.has(opc.time) || 
                         (((this.prev!==''?opcTime.isSameOrAfter(this.$moment(this.prev.to, 'HH:mm')):true) && (this.next!==''?opcTime.isBefore(this.$moment(this.next.from, 'HH:mm')):opc.time !== this.endTime)))) && ( (this.$moment.duration(opc.time, 'HH:mm').asMinutes() + this.minRangetime) <= this.$moment.duration(this.endTime, 'HH:mm').asMinutes() ));
            },
            validationTo(opc){ // Disabled times (to)
            	const opcTime = this.$moment(opc.time, 'HH:mm');
            	return !(opcTime.isAfter(this.$moment(this.value.from, 'HH:mm')) && (this.next!==''?opcTime.isSameOrBefore(this.$moment(this.next.from, 'HH:mm')):true));
            },
            selectRange(opc){ // Color chedule
            	return (this.$moment(opc.time, 'HH:mm').isBetween(this.$moment(this.value.from, 'HH:mm'),this.$moment(this.value.to, 'HH:mm'), null, '[]'));
            }
		},
		created(){
            this.time = this.value;
            this.times = [...this.options];
		},
		watch: {
			'time.from':  function(newVal,oldVal){
				if (oldVal !== '')
				    this.setFull(newVal,oldVal,'from');
			},
			'time.to':  function(newVal,oldVal){
				if (oldVal !== '')
					if (this.toWait)
						this.toWait = false
					else
						this.setFull(newVal,oldVal,'to');
			},
			options: function(newVal){
				this.times = [...this.options];
			}
      	}
    }
</script>