
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { State, Getter, Mutation, Action } from 'vuex-class';

	@Component
	export default class ongoingLesson extends Vue{
		@State('statusOngoingLesson') statusOngoingLesson;
		@State(state => state.auth.activeLessons) activeLessons;
		@State(state => state.auth.currentActiveLesson) currentActiveLesson;
		@State(state => state.auth.permission) userType;
		@State('currentLesson') currentLesson;

		@Getter('events') events;

		@Mutation('setStatusOngoingLesson') setStatusOngoingLesson
		@Mutation('setActiveLessons') setActiveLessons

		show: boolean = false;
		statusModal: boolean = false;
		lesson: any = {};
		resetTime: number = 0;

		get t(): any{
			return (this as any).$t;
		}

		get lessonPhotoOpc(): object{
            return { size: '90px', photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type };
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get name(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

		created() {
			if (this.activeLessons.length > 0 && !this.statusOngoingLesson) {
				this.setStatusOngoingLesson(true);
				this.lesson = this.currentActiveLesson || {};
				setTimeout(() => {
					this.show = true;
				}, 400);
			}else if(this.activeLessons.length > 0 && this.statusOngoingLesson){
				this.lesson = this.currentActiveLesson || {};
				this.show = true;
			}
		}

		beforeDestroy() {
			this.setStatusOngoingLesson(false);
		}

		startEventListener(): void{
			this.statusModal = true;
			document.addEventListener("keydown", this.onKeyPress);
		}

		onKeyPress(e): void{
			if ("key" in e) {
				if (e.key === "Escape" || e.key === "Esc") {
					this.endEventListener();
				}
			} else if (e.keyCode === 27) {
				this.endEventListener();
			}
		}

		endEventListener(): void{
		 	this.statusModal = false;
		 	document.removeEventListener("keydown", this.onKeyPress);
		}

        @Watch('currentActiveLesson')
        handleModal(newVal){
        	if (this.lesson.timer) clearInterval(this.lesson.timer);
        	if (newVal && this.statusOngoingLesson){
        		this.lesson = newVal;
        		this.resetTime += 1;
				if(!this.show)
				    setTimeout(() => {
        			    this.show = true;
        		    }, 400);
        	}
        	else if(newVal && !this.statusOngoingLesson){
        		this.setStatusOngoingLesson(true);
        		this.lesson = newVal;
        		setTimeout(() => {
        			this.show = true;
        			this.resetTime += 1;
        		}, 400);
        	}
        	else if (!newVal && this.statusOngoingLesson) {
        		this.show = false;
        		setTimeout(() => {
        			this.setStatusOngoingLesson(false);
        			this.lesson = [];
        		}, 400);
        	}
        }

        @Watch('$route')
        endListener(){
        	this.endEventListener();
        }
        
        @Watch('events')
        handleActiveLessons(val: any){
            const lesson = JSON.parse((this as any).$localStorage.get("lesson"));
            if (val && lesson){
            	const lesson = val.split("_");
            	if ( lesson[1] === "pendingFeedback" || lesson[1] === "completed") {
            		const newArray = this.activeLessons.filter( i => i.id + "" !== lesson[0] );
            	    this.setActiveLessons(newArray);
                }
            }
        }

	}
