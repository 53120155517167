/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-fill': {
    width: 102,
    height: 123,
    viewBox: '0 0 102 123',
    data: '<path pid="0" d="M41.438 14.686C24.94 19.152 12.75 34.906 12.75 53.67v26.834c0 2.473-1.909 4.477-4.246 4.477C3.808 84.982 0 88.997 0 93.927c0 4.94 3.812 8.946 8.521 8.946h84.958c4.706 0 8.521-4.015 8.521-8.946 0-4.94-3.825-8.945-8.504-8.945-2.345 0-4.246-1.989-4.246-4.477V53.671c0-18.757-12.187-34.517-28.688-38.985v-4.62C60.563 4.493 56.282 0 51 0c-5.27 0-9.563 4.506-9.563 10.066v4.62zm-5.313 92.66h29.75C65.875 115.99 59.215 123 51 123s-14.875-7.009-14.875-15.655z" _fill="#F0F0F0" fill-rule="nonzero"/>'
  }
})
