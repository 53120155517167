/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mouse-both': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 4A5.506 5.506 0 004 9.5v5C4 17.533 6.467 20 9.5 20s5.5-2.467 5.5-5.5v-5C15 6.467 12.533 4 9.5 4zm4.472 5H10V5.028A4.507 4.507 0 0113.972 9zM9 5.028V9H5.028A4.507 4.507 0 019 5.028zM9.5 19A4.505 4.505 0 015 14.5V10h9v4.5c0 2.481-2.019 4.5-4.5 4.5zM5.5 3a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM2.5 6h-2a.5.5 0 010-1h2a.5.5 0 010 1zM3.5 4a.502.502 0 01-.354-.146l-2-2a.5.5 0 01.707-.707l2 2A.5.5 0 013.499 4zM13.5 3a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM18.5 6h-2a.5.5 0 010-1h2a.5.5 0 010 1zM15.5 4a.5.5 0 01-.354-.853l2-2a.5.5 0 01.707.707l-2 2a.498.498 0 01-.354.146z"/>'
  }
})
