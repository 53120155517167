/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note3': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.82 1.116a.499.499 0 00-.41-.108l-11 2a.5.5 0 00-.411.492v11.399a3.965 3.965 0 00-.448-.26C6.727 14.227 5.644 14 4.499 14s-2.228.227-3.052.639c-.934.467-1.448 1.128-1.448 1.861s.514 1.395 1.448 1.861c.824.412 1.907.639 3.052.639s2.228-.227 3.052-.639c.934-.467 1.448-1.128 1.448-1.861V6.416l10-1.847v8.33a3.965 3.965 0 00-.448-.26c-.824-.412-1.907-.639-3.052-.639s-2.228.227-3.052.639c-.934.467-1.448 1.128-1.448 1.861s.514 1.395 1.448 1.861c.824.412 1.907.639 3.052.639s2.228-.227 3.052-.639c.934-.467 1.448-1.128 1.448-1.861v-13a.5.5 0 00-.18-.384zM4.5 18C2.497 18 1 17.208 1 16.5S2.497 15 4.5 15s3.5.792 3.5 1.5S6.503 18 4.5 18zM9 5.399V3.917l10-1.818v1.453L9 5.399zM15.5 16c-2.003 0-3.5-.792-3.5-1.5s1.497-1.5 3.5-1.5 3.5.792 3.5 1.5-1.497 1.5-3.5 1.5z"/>'
  }
})
