<template>
    <div class="v-schedule row w-100" >
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3">
        	<div class="btn-prev-step order-0 col-auto col-md">
				<v-prev-link class="col-12 pt-3 pb-3 pl-0 pr-0" :txt="$t('scheduleLesson.subjects.t')" type="step" :click="() => { time = ''; date = null; handleDate(); prevAction();}" />
			</div>
			<h3 class="title-step order-2 order-md-1 col-12 col-md-auto mb-0 text-center">{{ $t('scheduleLesson.date.t') }}</h3>
		    <div class="btn-close-step order-1 order-md-2 col-auto col-md text-right">
			    <button @click="closeAction()" v-if="closeAction"><svgicon name="cross2"></svgicon></button>
		    </div>
	    </div>

	    <div class="col-md-6 mb-3 calendar pl-0 pr-0 pl-sm-3 pr-sm-3">
		    <v-date-picker class="v-calendar" :is-inline="true"
				                              is-expanded
				                              :min-date='$moment().toDate()'
                                              :max-date='$moment($moment().format("MMMM"),"MMMM").add(4, "M").subtract(1,"days").toDate()'
				                              :value="date"
				                              :themeStyles="calendar.themeStyles"
				                              v-model="date"
                                              :key="'calendar'+i18n.locale"
				                              tintColor="#2188FC"
				                              :formats="{ weekdays: 'WWW' }" 
				                              :disabled-dates='[ { weekdays: calendar.disabledDays.days }, ...calendar.disabledDays.dates ]'
                                              :available-dates="calendar.availableDates"
				                              @input="handleDate()"
				                              ref="calendar" >                       
		    </v-date-picker>
	    </div>
	    <div class="col-md-6 d-flex flex-wrap justify-content-end mb-3 select-hour">
		    <div class="align-self-end w-100 mb-3">
			    <div class="lesson mb-4">
			    	<div class="d-flex align-items-center mb-3">
			    	    <svgicon class="lesson-icon mr-2" :name="lesson.icon" :color="lesson.color" />
				        <span class="subject">{{ lesson.name }}</span>
			        </div>
			        <div class="row ml-n2 mr-n2 justify-content-start align-items-center">
			        	<div class="col-auto pl-2 pr-2 border-r mt-1 mb-1" v-if="time.places || lesson.type == 'Individual'">
				            <v-lesson-tag :type="lesson.type" :options="{ lessonQuota: time.occupied, lessonLimit: time.places }" />
			            </div>
			            <div class="col-auto pl-2 pr-2 mt-1 mb-1">
                            <v-lesson-tag :type="lesson.modality" :options="{ minWidth: '80px' }" />
			            </div>
			        </div> 
			          
			    </div>
			    <div class="schedule-time mb-3" :class="{ disabled: statusBtn || wait  }" v-if="date && times.length > 0">
			    	<select class="time" :disabled="statusBtn || wait" v-model="time" @change="handleDate()">
			    		<option value="">{{ $t('scheduleLesson.date.hour') }}</option>
			    		<option :value="time" v-for="time in times">{{ getTime(time.start) }} - {{ getTime(time.end) }}</option>
			    	</select>
			    	<div class="content-icon">
			    		<svgicon class="icon" name="chevron-down"></svgicon>
			    	</div>    	
			    </div>
			    <p class="lesson-date mb-0" v-if="date && times.length > 0">- {{ getDate }}</p>
			    <p class="lesson-date" v-if="time!== '' && date">- {{ `${getTime(time.start)} - ${getTime(time.end)}` }}</p>
                <p class="lesson-date" v-else-if="!date">{{ $t('schedule.selectDay') }}</p> 
                <p class="lesson-date" v-else-if="date && times.length === 0">{{ $t('schedule.noTimes') }}</p> 
			    <v-btn class="float-right" :txt="$t('scheduleLesson.btnNext')" fontSize="20px" type="Next" min="200px" @click.native="nextAction()" :disabled="statusBtn || wait || time == ''" />
		    </div>
		    <slot name="btn" />
	    </div> 
    </div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
    import { setupCalendar } from "v-calendar";
	export default{
		props: {
			lesson: { type: Object, required: true },
			idTutor: { required: true },
            closeAction: { type: Function, required: true },
            nextAction: { type: Function, required: true },
            prevAction: { type: Function, required: true },
            value: { type: Object }
		},
		data(){
			return{
                date: null,
                time: '',
                days: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                schedule: {},
                times: [],
                availability: [],
                wait: false,
                calendar: {
					themeStyles: {
						wrapper: {
							backgroundColor: '#fff'
						}
					},
					attributes: [
                        {
                            key: 'today',
                            highlight: true,
                            dates: this.$moment().toDate()
                        }
                    ],
                    availableDates: [],
                    disabledDays: {
                        days: [],
                        dates: []
                    }
				}
			}
		},
		computed:{
			...mapState(['auth','i18n']),
            getDate(){
                const date = this.$moment(this.date).locale(this.i18n.locale).format(this.$t('formatDateC'));
                return date.charAt(0).toUpperCase()+date.slice(1);
            },
            statusBtn(){
            	let band = false;
            	 
            	if (this.availability[0]) 
            		if (this.availability[0].in_session!=this.availability[0].cupo)
            			band = false
            		else
            			band = true
            	if(!this.date)
            	    band = true
            	else
            	    band = false	
            	return band;
            }
		},
		methods:{
			...mapActions(['axiosRequest']),
            getSchedule(){
                this.wait = true;
            	this.axiosRequest({ config : {
            		method: 'GET',
                    url: `${this.auth.api}/tutor/${this.idTutor}/schedules/`,
                    headers: { authorization : this.auth.token, 'Content-Type' : 'application/json' },
                    params: { subject: this.lesson.subject_id }
            	}}).then( resp => resp.data.data)
                .then( data => {
                	this.$emit('input', { date: this.date, time: this.time })
                	this.days.map( (day, index) => {
                        if (data.schedules[day]){
                            if (data.schedules[day].active)
                        	    this.schedule = { ...this.schedule, [day] : data.schedules[day].ranges };
                            else
                                this.calendar.disabledDays.days.push(index+1)
                        }
                        else
                        	this.calendar.disabledDays.days.push(index+1)
                	});
                    const keys = Object.keys(data.custom_schedules);
                    Object.values(data.custom_schedules).map( (date, index) => {
                        if (!date.active)
                            this.calendar.disabledDays.dates.push(this.$moment(keys[index], 'YYYY-MM-DD').toDate());
                        else
                            this.calendar.availableDates.push(this.$moment(keys[index], 'YYYY-MM-DD').toDate());
                    });
                    if (!this.value.date)
                        this.selectAvailabilityDay(data.custom_schedules, this.$moment());
                    this.wait = false;
                }).catch( error => {
                    console.log(error)
                    this.wait = false;
                })
            },
            getTime(time){
                return this.$moment(time, 'HH:mm:ss').format('LT');
            },
            getTimes(){ 
                this.wait = true;
                this.$Progress.start();
            	this.axiosRequest({ config : {
            		method: 'GET',
                    url: `${this.auth.api}/tutor/${this.idTutor}/availability/subject/${this.lesson.subject_id}`,
                    params: { date: this.$moment(this.date).format('YYYY-MM-DD') },
                    headers: { authorization : this.auth.token }
            	}}).then( resp => resp.data)
                .then( data => {
                    this.times = data;
                    this.time = data[0] || '';
                    this.handleDate();
                    this.wait = false;
                    this.$Progress.finish();
                }).catch( error => {
                	if (error.response.status == 404){
                		this.times = [];
                		this.time = '';
                	}  
                    this.wait = false;
                    this.$Progress.fail();
                })
            },
            handleDate(){
            	this.$emit('input', { date: this.date, time: this.time, availability: this.availability })
            },
            selectAvailabilityDay(custom_schedules, date, cont = 0, max = 1){
                let day = parseInt(date.format('E')),
                    dateF = date.format('YYYY-MM-DD');
                    day = day===7?1:++day;

                if (max <= 20 ) {
                    const generalSch = !this.calendar.disabledDays.days.find( item => item === day),
                          customSch = custom_schedules[dateF]?custom_schedules[dateF].active:generalSch;

                    if ((generalSch && customSch) || customSch){
                        this.date = date.toDate();
                    }
                    else    
                        this.selectAvailabilityDay(custom_schedules, date.add(1, 'days') , (cont==6?0:++cont), ++max);
                }
            }
		},
		created(){
            setupCalendar({ locale: this.i18n.locale });
            this.getSchedule();
            if (this.value.date)
                this.date = this.value.date;
            if (this.value.time)
            	this.time = this.value.time;
		},
		watch:{
			lesson: function(){
                this.getTimes();
			},
			date: function(newVal){
                if (newVal)
                    this.getTimes();
			},
            'i18n.locale': function(newVal){
                setupCalendar({ locale: newVal });
            }
		}
	}
</script>