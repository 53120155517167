var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pb-5", attrs: { id: "biografhy" } },
    [
      _c(
        "div",
        {
          staticClass:
            "paper row ml-sm-0 mr-sm-0 pl-sm-4 pr-sm-4 pt-sm-5 pb-sm-5 pt-4 pb-4 justify-content-center"
        },
        [
          _c(
            "form",
            {
              staticClass: "content-biografhy col-md",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.changeDescription()
                }
              }
            },
            [
              _c(
                "h2",
                { staticClass: "title-biografhy mb-3" },
                [
                  _c("svgicon", {
                    staticClass: "icon",
                    attrs: { name: "bookmark" }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("bio.t")) + " ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "descrip-biografhy mb-4" },
                [
                  _vm._v(_vm._s(_vm.$t("bio.descrip1")) + " "),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "profile-tutor" }, tag: "strong" } },
                    [_vm._v(_vm._s(_vm.$t("bio.descrip2")))]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("bio.descrip3")))
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "paper d-flex flex-wrap p-4 mb-4" },
                [
                  _c("v-img-profile", {
                    staticClass: "col pl-0 pr-0 mr-1",
                    attrs: { img: _vm.auth.user.photo }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col d-flex align-items-center d-block d-sm-none"
                    },
                    [
                      _c("h3", { staticClass: "name pt-2 mb-2" }, [
                        _vm._v(_vm._s(_vm.fullName))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm mt-2 mt-sm-0" },
                    [
                      _c(
                        "h3",
                        { staticClass: "name pt-2 mb-2 d-none d-sm-block" },
                        [_vm._v(_vm._s(_vm.fullName))]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.description,
                            expression: "description"
                          }
                        ],
                        staticClass: "biografhy w-100 mt-1 mb-3",
                        attrs: {
                          cols: "30",
                          rows: "10",
                          placeholder: _vm.$t("bio.placeholder")
                        },
                        domProps: { value: _vm.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.description = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("v-btn", {
                        staticClass: "w-100",
                        attrs: {
                          txt: _vm.$t("bio.btnSave"),
                          type: "submit",
                          disabled: _vm.sending
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-circles", { attrs: { vCircle: 3, size: "250px" } })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }