var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "confirm-modal room-settings", class: { active: _vm.show } },
    [
      _c("div", {
        staticClass: "blurColor",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-content" },
        [
          _c(
            "h4",
            {
              ref: "title",
              staticClass:
                "title pt-3 pt-sm-4 pb-3 pl-4 pl-md-5 pr-4 pr-md-5 mb-0",
              class: `bgColor-${_vm.permission}-main`
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("room.modalSettings.t")) +
                  "\n\t\t\t\t"
              ),
              _c("span", { staticClass: "d-block" }, [
                _vm._v(_vm._s(_vm.$t("room.modalSettings.subT")))
              ]),
              _vm._v(" "),
              _c("svgicon", {
                staticClass: "btn-close",
                attrs: { name: "cross2" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              style: { height: _vm.contentH },
              attrs: { options: { suppressScrollX: true } }
            },
            [
              _c(
                "div",
                {
                  ref: "content",
                  staticClass:
                    "row ml-sm-0 mr-sm-0 pt-4 pt-sm-5 pr-4 pb-4 pb-sm-5 pl-4"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-sm-7 mb-3 order-1 order-sm-0" },
                    [
                      _c("v-select", {
                        staticClass: "w-100 mb-3",
                        attrs: {
                          defaultOpc: _vm.defaultOpcVideo,
                          label: _vm.$t("room.camera.select.label"),
                          value:
                            _vm.defaultOpcVideo == "" ? _vm.currentCam : "",
                          opc: _vm.cameras,
                          fieldVal: _vm.defaultOpcVideo == "" ? "deviceId" : "",
                          fieldTxt: "label",
                          colorLabel: true,
                          disabled: _vm.defaultOpcVideo != ""
                        },
                        on: { input: newVal => _vm.changeDevice("cam", newVal) }
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "w-100",
                        attrs: {
                          label: _vm.$t("room.videoQuality.select.label"),
                          value: _vm.currentVideoQuality,
                          opc: _vm.videoQuality,
                          fieldVal: "deviceId",
                          fieldTxt: "label",
                          colorLabel: true,
                          disabled: _vm.defaultOpcVideo != ""
                        },
                        on: { input: newVal => _vm.changeDevice("VQ", newVal) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-5 mb-3 pl-sm-0 order-0 order-sm-1",
                      style: { zIndex: 10 }
                    },
                    [_vm._t("video")],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "col-sm-7 mb-3 order-3 order-sm-2",
                    attrs: {
                      label: _vm.$t("room.microphone.select.label"),
                      value:
                        _vm.defaultOpcAudioInput == "" ? _vm.currentMic : "",
                      defaultOpc: _vm.defaultOpcAudioInput,
                      opc: _vm.microphones,
                      fieldVal:
                        _vm.defaultOpcAudioInput == "" ? "deviceId" : "",
                      fieldTxt: "label",
                      colorLabel: true,
                      disabled: _vm.defaultOpcAudioInput != ""
                    },
                    on: { input: newVal => _vm.changeDevice("mic", newVal) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-5 mb-3 pl-sm-0 d-flex align-content-center order-2 order-sm-3"
                    },
                    [
                      _vm.audioMute ||
                      _vm.permissionMic != _vm.typePermission.granted
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center w-100 w-sm-auto"
                            },
                            [
                              _vm.permissionMic ==
                              _vm.typePermission.errorSystem
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "noMic w-100 p-2 mb-0 text-center"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "room.microphone.alerts.errorSystem.issue"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm.permissionMic == _vm.typePermission.denied
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "noMic w-100 p-2 mb-0 text-center"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "room.microphone.alerts.denied.issue"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm.permissionMic ==
                                  _vm.typePermission.deviceEmpty
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "noMic w-100 p-2 mb-0 text-center"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("room.microphone.alerts.empty")
                                        )
                                      )
                                    ]
                                  )
                                : _vm.permissionMic ==
                                  _vm.typePermission.errorDevice
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "noMic w-100 p-2 mb-0 text-center"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("room.microphone.alerts.fail")
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "p",
                                    {
                                      staticClass:
                                        "noMic w-100 p-2 mb-0 text-center"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("room.microphone.alerts.mute")
                                        )
                                      )
                                    ]
                                  )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("svgicon", {
                                staticClass: "mic-icon mr-2",
                                attrs: { name: "mic" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vol-bars d-flex align-items-center position-relative"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-vol-bar" },
                                    [
                                      _c("div", {
                                        staticClass: "vol-bar",
                                        style: {
                                          width: `${
                                            _vm.volume <= 25
                                              ? _vm.volume * 4
                                              : 100
                                          }%`
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "content-vol-bar" },
                                    [
                                      _c("div", {
                                        staticClass: "vol-bar",
                                        style: {
                                          width: `${
                                            _vm.volume <= 70 && _vm.volume > 30
                                              ? _vm.volume * 1.33333
                                              : _vm.volume <= 30
                                              ? 0
                                              : 100
                                          }%`
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "content-vol-bar" },
                                    [
                                      _c("div", {
                                        staticClass: "vol-bar",
                                        style: {
                                          width: `${
                                            _vm.volume > 75 ? _vm.volume : 0
                                          }%`
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "col-sm-7 mb-3 order-5 order-sm-4",
                    attrs: {
                      label: _vm.$t("room.audioOuput.select.label"),
                      value:
                        _vm.defaultOpcAudioOutput == "" ? _vm.currentSpk : "",
                      defaultOpc: _vm.defaultOpcAudioOutput,
                      opc: _vm.speakers,
                      fieldVal:
                        _vm.defaultOpcAudioOutput == "" ? "deviceId" : "",
                      fieldTxt: "label",
                      colorLabel: true,
                      disabled: _vm.defaultOpcAudioOutput != ""
                    },
                    on: { input: newVal => _vm.changeDevice("spk", newVal) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-5 mb-3 pl-sm-0 d-flex align-items-center order-4 order-sm-5"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-sound pl-1 text-left",
                          class: { active: _vm.statusTestSound },
                          on: {
                            click: function($event) {
                              return _vm.$emit("playTestSound")
                            }
                          }
                        },
                        [
                          _c("svgicon", {
                            staticClass: "sound-icon mr-2",
                            attrs: { name: "volume-medium" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "sound-txt" }, [
                            _vm._v(
                              _vm._s(
                                _vm.statusTestSound
                                  ? _vm.$t("room.audioOuput.playingTestSound")
                                  : _vm.$t("room.audioOuput.btnTest")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-7 mb-3 order-7 order-sm-6" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "vf-title",
                          class: `color-${_vm.permission}-main`
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("room.modalSettings.voiceFocus.t"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "vf-descript mb-0" }, [
                        _vm._v(
                          _vm._s(_vm.$t("room.modalSettings.voiceFocus.d"))
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-5 mb-3 pl-sm-0 d-flex align-items-center order-6 order-sm-7"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vf-switch cloud-hover mt-1 mb-1 pb-1",
                          class: {
                            active: _vm.enableVoiceFocus,
                            disabled: !_vm.enebledVF
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "vf-onOff",
                            on: {
                              click: function($event) {
                                return _vm.changeDevice(
                                  "AVF",
                                  !_vm.enableVoiceFocus
                                )
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }