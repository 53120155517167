<template>
	<div class="as-select d-flex justify-content-between pl-3 pr-3 pt-2 pb-2 align-items-center">
		<p class="mb-0 content-as-txt d-flex flex-wrap">
			<span class="as-txt mr-1">{{ txt }}</span> <span class="as-txt" :txtColor="txtSelect.color" v-if="value!='All'">{{ txtSelect.txt }}</span>
		</p>
		
		<input type="text" readonly ref="input" @focus="statusFocus = true" @blur="showSubmenu = false"/>
		<svgicon class="icon ml-1" name="chevron-down" />
		<nav class="opc-select" :class="{ active: showSubmenu }" @mouseover="statusFocus = true;" v-if="statusFocus ">
			<ul class="mb-0 pl-0 w-100">
				<li class="item-select d-flex align-items-center justify-content-between" :pColor="item.color" @click="$emit('input', item.val)" v-for=" item in options ">
					<span class="i-txt">{{ item.txt }}</span>
					<div class="as-checkbox mr-2" :class="{ active : value===item.val }">
						<svgicon class="icon-check" name="check" />
					</div>
				</li>
			</ul>
		</nav>
	</div>
</template>
<script>
	export default{
		props: {
			options: { type: Array, default: () => [], required: true },
			txt: { type: String, default: '', required: true },
			value: ''
		},
		data(){
			return{
                statusFocus: false,
                showSubmenu: false
			}
		},
		computed:{
            txtSelect(){
            	if (this.value=='All')
            		return { color: null, txt: '' }
            	else{
            		const opc = this.options.find( i => i.val == this.value)
            		return { color: opc.color, txt: `(${opc.txt})` };
            	}
            }
		},
		watch:{
			statusFocus: function(newVal){
				this.$nextTick( () => {
					if (newVal)
					    this.showSubmenu = true;
				} );
			},
			showSubmenu: function(newVal){
				this.$nextTick( () => {
                    if (!newVal)
                    	setTimeout( () => {
                    		this.statusFocus = false;
                    	},400);
				});
			}
		}
	}
</script>