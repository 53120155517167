
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';

    @Component
    export default class questionYesNo extends Vue{
        @Prop() readonly question!: any;
        @Prop({ type: Boolean, default: false }) readonly error!: boolean;
        @Prop({ default: false }) readonly statusFormat!: boolean;


        val: number | null = null;

        get emit(): any{
            return (this as any).$emit;
        }

        created(): void{
            if(this.statusFormat)
                this.val = this.question.answer;
        }
        
        handleAnswer(val: number){
            if(!this.statusFormat){
                this.val = val;
                this.$emit('handleAnswer', { id: this.question.id, answer: val });
            }
        }
    }
