/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tired': {
    width: 13,
    height: 21,
    viewBox: '0 0 13 21',
    data: '<g _fill="none" fill-rule="evenodd"><g _fill="#000" fill-rule="nonzero"><path pid="0" d="M8 4c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1zM4.314 19.964a.501.501 0 01-.279-.65l1.878-4.694-2.767-2.767a.5.5 0 01-.094-.577L5.69 6c.207-.667.476-1 .809-1a.5.5 0 01.447.723l-2.839 5.678 2.745 2.745a.501.501 0 01.111.539l-2 5a.5.5 0 01-.649.279z"/><path pid="1" d="M12.5 9h-4a.5.5 0 01-.447-.276l-1-2a.5.5 0 01.895-.447l.862 1.724h3.691a.5.5 0 010 1L12.5 9z"/></g><path pid="2" d="M2.5 12.5c.56 1.54.726 2.706.5 3.5-.227.794-1.06 1.96-2.5 3.5" _stroke="#000" stroke-linecap="round"/><path pid="3" d="M10.5 11c.333-.667.667-1 1-1 .333 0 .667.167 1 .5v10" _stroke="#000" stroke-width=".8"/></g>'
  }
})
