var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-btn-prev col-12" },
    [
      _vm.type === "link"
        ? _c(
            "router-link",
            {
              staticClass: "pb-0 text-left",
              attrs: { to: _vm.to, tag: "button" }
            },
            [
              _c("svgicon", {
                staticClass: "icon",
                attrs: { name: "arrow-left" }
              }),
              _vm._v(_vm._s(_vm.txt))
            ],
            1
          )
        : _c(
            "button",
            { staticClass: "pb-0 text-left", on: { click: _vm.click } },
            [
              _c("svgicon", {
                staticClass: "icon",
                attrs: { name: "arrow-left" }
              }),
              _vm._v(_vm._s(_vm.txt))
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }