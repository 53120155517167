/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 16h-16C.673 16 0 15.327 0 14.5v-8C0 5.673.673 5 1.5 5h16c.827 0 1.5.673 1.5 1.5v8c0 .827-.673 1.5-1.5 1.5zM1.5 6a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-16z"/><path pid="1" d="M3.5 8h-1a.5.5 0 010-1h1a.5.5 0 010 1zM16.5 8h-1a.5.5 0 010-1h1a.5.5 0 010 1zM4.5 10h-2a.5.5 0 010-1h2a.5.5 0 010 1zM3.5 14h-1a.5.5 0 010-1h1a.5.5 0 010 1zM11.5 14h-6a.5.5 0 010-1h6a.5.5 0 010 1zM14.5 14h-1a.5.5 0 010-1h1a.5.5 0 010 1zM16.5 12h-3a.5.5 0 010-1H16V9.5a.5.5 0 011 0v2a.5.5 0 01-.5.5zM5.5 12h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6 7.5a.5.5 0 11-1 0 .5.5 0 011 0zM8 7.5a.5.5 0 11-1 0 .5.5 0 011 0zM10 7.5a.5.5 0 11-1 0 .5.5 0 011 0zM12 7.5a.5.5 0 11-1 0 .5.5 0 011 0zM14 7.5a.5.5 0 11-1 0 .5.5 0 011 0zM7 9.5a.5.5 0 11-1 0 .5.5 0 011 0zM9 9.5a.5.5 0 11-1 0 .5.5 0 011 0zM11 9.5a.5.5 0 11-1 0 .5.5 0 011 0zM13 9.5a.5.5 0 11-1 0 .5.5 0 011 0z"/><path pid="2" d="M15 9.5a.5.5 0 11-1 0 .5.5 0 011 0zM8 11.5a.5.5 0 11-1 0 .5.5 0 011 0zM10 11.5a.5.5 0 11-1 0 .5.5 0 011 0zM12 11.5a.5.5 0 11-1 0 .5.5 0 011 0z"/>'
  }
})
