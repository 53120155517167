/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flash-memory': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.5 20h-13c-.827 0-1.5-.673-1.5-1.5V6c0-.688.367-1.574.854-2.061L5.94.853c.487-.487 1.373-.854 2.061-.854h8.5c.827 0 1.5.673 1.5 1.5v17c0 .827-.673 1.5-1.5 1.5zM8 1c-.415 0-1.06.267-1.354.561L3.56 4.647c-.293.293-.561.939-.561 1.354v12.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-17a.5.5 0 00-.5-.5h-8.5z"/><path pid="1" d="M15.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM11.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM9.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM7.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM13.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5z"/>'
  }
})
