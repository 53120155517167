/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'first-aid': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.5 16h-3a.5.5 0 01-.5-.5V14H6.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H8V8.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V10h1.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H12v1.5a.5.5 0 01-.5.5zM9 15h2v-1.5a.5.5 0 01.5-.5H13v-2h-1.5a.5.5 0 01-.5-.5V9H9v1.5a.5.5 0 01-.5.5H7v2h1.5a.5.5 0 01.5.5V15z"/><path pid="1" d="M18.5 5H14V3.5c0-.827-.673-1.5-1.5-1.5h-5C6.673 2 6 2.673 6 3.5V5H1.5C.673 5 0 5.673 0 6.5v11c0 .827.673 1.5 1.5 1.5h17c.827 0 1.5-.673 1.5-1.5v-11c0-.827-.673-1.5-1.5-1.5zM7 3.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V5H7V3.5zm12 14a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v11z"/>'
  }
})
