import mutations from './mutations.js'
import actions from './actions.js'
import en_us from './languages/en/us.js';
import es_mx from './languages/es/mx.js';

const state = {
	langs: {
		en: [
			'en_US'
		],
		es: [
			'es_MX'
		]
	},
	languages: [
	    {
	    	short: 'en_US',
	    	long: 'English'
	    },
	    {
	    	short: 'es_MX',
	    	long: 'Español'
	    }
	]
}

export default {
	state,
	mutations,
	actions,
	en_us,
	es_mx
}