var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "search-autocomplete" } },
    [
      _c(
        "div",
        { staticClass: "position-relative search" },
        [
          _c("svgicon", {
            staticClass: "icon-search",
            attrs: { name: "magnifier" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textInput,
                expression: "textInput"
              }
            ],
            attrs: { type: "text", placeholder: _vm.$t("search.placeholder") },
            domProps: { value: _vm.textInput },
            on: {
              focus: function($event) {
                return _vm.focus($event.target.value)
              },
              keyup: function($event) {
                return _vm.keyFuction($event.key)
              },
              input: function($event) {
                if ($event.target.composing) return
                _vm.textInput = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("v-more-info", {
            staticClass: "position-absolute",
            attrs: {
              cloudPositionY: "bottom",
              sizePx: "20px",
              txtCloud: _vm.$t("search.cloud"),
              iconColor: "#2188FC"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.inputFocus
        ? _c(
            "perfect-scrollbar",
            { ref: "scroll", staticClass: "dropdown", attrs: { tag: "div" } },
            [
              _c(
                "transition",
                { attrs: { name: "bound", mode: "out-in" } },
                [
                  _vm.show === "spinner"
                    ? _c(
                        "div",
                        {
                          key: "spinner",
                          staticClass: "w-100 p-3 text-center"
                        },
                        [
                          _c("v-spinner", {
                            attrs: { height: "40px", width: "40px" }
                          })
                        ],
                        1
                      )
                    : _vm.show === "message"
                    ? _c(
                        "div",
                        {
                          key: "spinner",
                          staticClass: "w-100 p-3 text-center"
                        },
                        [
                          _c("p", { staticClass: "msg-search mt-2 mb-2" }, [
                            _vm._v(_vm._s(_vm.$t("msgEmpty.inputsearch")))
                          ])
                        ]
                      )
                    : _vm.show === "results"
                    ? _c(
                        "transition-group",
                        {
                          key: "results",
                          staticClass: "pl-0 mb-0",
                          attrs: { name: "bound", tag: "ul" }
                        },
                        _vm._l(_vm.browser.searchResults, function(
                          item,
                          index
                        ) {
                          return _c(
                            "li",
                            {
                              key: "dropdown-item" + index,
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.select(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex ml-0 mr-0 align-items-center"
                                },
                                [
                                  item.icon_name
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "content-icon",
                                          style: { background: item.color }
                                        },
                                        [
                                          _c("svgicon", {
                                            staticClass: "icon",
                                            attrs: { name: item.icon_name }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("div", {
                                        staticClass: "content-img",
                                        style: {
                                          background:
                                            "url(" +
                                            item.photo +
                                            ")center center / cover no-repeat"
                                        }
                                      }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-sm d-flex flex-wrap align-items-center subject-tutor"
                                    },
                                    [
                                      _c("p", {
                                        staticClass: "mb-0 w-100 name",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.textBold(item.name)
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }