/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tornado': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 5c-2.571 0-4.993-.212-6.821-.597-.914-.192-1.639-.42-2.156-.677C.344 3.388-.001 2.975-.001 2.5s.344-.888 1.024-1.226c.516-.257 1.242-.485 2.156-.677C5.006.212 7.428 0 10 0c1.223 0 2.414.048 3.542.142a.5.5 0 01-.083.997 41.712 41.712 0 00-3.458-.138c-2.383 0-4.65.188-6.384.528-2.139.42-2.547.881-2.609.972.061.091.47.552 2.609.972 1.733.341 4.001.528 6.384.528s4.65-.188 6.384-.528c2.266-.445 2.59-.936 2.616-.984a.501.501 0 011 .012c0 .475-.344.888-1.024 1.226-.516.257-1.242.485-2.156.677-1.827.385-4.249.597-6.821.597zM9 17c-1.27 0-2.473-.223-3.385-.629C4.574 15.908 4 15.244 4 14.5a.5.5 0 011 0c0 .311.391.677 1.021.957.787.35 1.845.543 2.979.543s2.192-.193 2.979-.543c.63-.28 1.021-.647 1.021-.957a.5.5 0 011 0c0 .744-.574 1.408-1.615 1.871C11.473 16.777 10.27 17 9 17z"/><path pid="1" d="M10 20c-2.243 0-4-1.098-4-2.5a.5.5 0 011 0c0 .709 1.232 1.5 3 1.5s3-.791 3-1.5a.5.5 0 011 0c0 1.402-1.757 2.5-4 2.5zM9 11c-2.046 0-3.975-.214-5.432-.603C1.864 9.943 1 9.304 1 8.5a.5.5 0 011 0c0 .094.31.527 1.826.931C5.202 9.798 7.039 10 9 10s3.799-.202 5.174-.569C15.69 9.027 16 8.594 16 8.5a.5.5 0 011 0c0 .805-.864 1.443-2.568 1.897C12.975 10.786 11.045 11 9 11z"/><path pid="2" d="M8.5 14c-1.655 0-3.218-.217-4.401-.611C2.364 12.811 2 12.043 2 11.5a.5.5 0 011 0c0 .162.296.567 1.415.94 1.084.361 2.534.56 4.085.56s3.001-.199 4.085-.56c1.12-.373 1.415-.778 1.415-.94a.5.5 0 011 0c0 .542-.364 1.31-2.099 1.889-1.183.394-2.746.611-4.401.611zM9.5 8c-2.439 0-4.738-.212-6.472-.598C.991 6.949 0 6.327 0 5.5a.5.5 0 011-.006c.043.132.573.583 2.43.972C5.073 6.81 7.229 7 9.5 7s4.427-.19 6.07-.534c1.857-.389 2.387-.84 2.43-.972a.5.5 0 011 .006c0 .827-.99 1.45-3.028 1.902C14.238 7.787 11.939 8 9.5 8z"/>'
  }
})
