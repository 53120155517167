var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-tutor-sch-session pt-5 pb-5 d-flex flex-column" },
    [
      _c("div", { staticClass: "t-sch-content ml-auto mr-auto w-100" }, [
        _c("div", { staticClass: "t-sch-head mb-4 position-relative" }, [
          _c(
            "div",
            {
              staticClass:
                "row ml-n2 mr-n2 flex-nowrap align-items-center justify-content-between"
            },
            [
              _c("div", { staticClass: "col-auto pl-2 pr-2" }, [
                _c("h2", { staticClass: "t-sch-title mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("scheduleT.t")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto pl-2 pr-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "t-sch-label mb-0",
                    attrs: {
                      href: _vm.$t("scheduleT.HowItWorksHref"),
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("scheduleT.HowItWorks")))]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "v-paper p-4" },
          [
            _c("v-subjects-t", {
              staticClass: "mb-4 mt-3 position-relative pb-3",
              model: {
                value: _vm.subject,
                callback: function($$v) {
                  _vm.subject = $$v
                },
                expression: "subject"
              }
            }),
            _vm._v(" "),
            _c("v-date-time-t", {
              staticClass: "mb-4",
              attrs: {
                duration: _vm.durationTime,
                disabled: !_vm.subject || _vm.sending
              },
              model: {
                value: _vm.dateTime,
                callback: function($$v) {
                  _vm.dateTime = $$v
                },
                expression: "dateTime"
              }
            }),
            _vm._v(" "),
            _c("v-students-t", {
              staticClass: "mb-4 pb-3 position-relative",
              attrs: {
                quote: _vm.quote,
                disabled: !_vm.subject || _vm.sending
              },
              model: {
                value: _vm.students,
                callback: function($$v) {
                  _vm.students = $$v
                },
                expression: "students"
              }
            }),
            _vm._v(" "),
            _vm.statusPlace
              ? _c("v-places-t", {
                  staticClass: "mb-4 pb-2 position-relative",
                  attrs: {
                    type: _vm.type,
                    idSubject: _vm.subject.id,
                    disabled: _vm.sending
                  },
                  model: {
                    value: _vm.place,
                    callback: function($$v) {
                      _vm.place = $$v
                    },
                    expression: "place"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.subject
              ? _c("div", { staticClass: "v-sch-summary-t pt-4 mb-4 pb-3" }, [
                  _c("h5", { staticClass: "v-sch-t-title mb-4 pt-2" }, [
                    _vm._v(_vm._s(_vm.$t("scheduleT.summary.t")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "v-sch-summary-content p-4 d-flex flex-column"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mb-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "v-sch-summary-icon mr-2 position-relative"
                            },
                            [
                              _c("svgicon", {
                                attrs: { name: _vm.subject.icon }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "v-sch-summary-txt bold" }, [
                            _vm._v(_vm._s(_vm.subject.name))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row ml-n2 mr-n2 mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-auto pl-2 pr-2" },
                          [
                            _c("v-lesson-tag", {
                              attrs: {
                                type: _vm.subject.type,
                                options: { lessonLimit: _vm.subject.limit }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-auto pl-2 pr-2" },
                          [
                            _c("v-lesson-tag", {
                              attrs: { type: _vm.subject.modality }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.fullDate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center mb-3 position-relative"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "v-sch-summary-icon mr-2 position-relative"
                                },
                                [
                                  _c("svgicon", { attrs: { name: "calendar" } })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "v-sch-summary-txt" }, [
                                _vm._v(_vm._s(_vm.fullDate))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.place
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-center mb-3" },
                            [
                              _c("v-lesson-place", {
                                attrs: {
                                  options: {
                                    icon: _vm.place.iconName,
                                    place: _vm.place.name,
                                    modality: _vm.subject.modality,
                                    type: _vm.subject.type,
                                    edit: false
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "v-sch-summary-icon mr-2 position-relative"
                          },
                          [_c("svgicon", { attrs: { name: "group-work" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "v-sch-summary-txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("scheduleT.summary.enrolled", {
                                count: _vm.enrolledStudents
                              })
                            )
                          )
                        ])
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("v-btn", {
              staticClass: "w-100 mb-3",
              attrs: {
                txt: _vm.$t("scheduleT.btnBookSession"),
                height: "40px",
                name: "signUp",
                disabled: !_vm.statusForm,
                spinner: _vm.sending
              },
              nativeOn: {
                click: function($event) {
                  return _vm.submit.apply(null, arguments)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }