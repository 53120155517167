/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16 3.5V3c0-.392-.241-.733-.717-1.014-.344-.203-.821-.382-1.419-.531-1.172-.293-2.722-.454-4.364-.454s-3.192.161-4.364.454c-.598.149-1.075.328-1.419.531C3.241 2.267 3 2.608 3 3v.5a6.513 6.513 0 004 6.002v1.997a6.513 6.513 0 00-4 6.002v.5c0 .392.241.733.717 1.014.344.203.821.382 1.419.531C6.308 19.839 7.858 20 9.5 20s3.192-.161 4.364-.454c.598-.149 1.075-.328 1.419-.531.476-.281.717-.623.717-1.014v-.5a6.513 6.513 0 00-4-6.002V9.502A6.513 6.513 0 0016 3.5zM5.612 2.369C6.678 2.131 8.059 2 9.5 2s2.823.131 3.888.369c1.15.257 1.498.534 1.589.631-.091.097-.439.374-1.589.631C12.322 3.869 10.941 4 9.5 4s-2.823-.131-3.888-.369C4.462 3.374 4.114 3.097 4.023 3c.091-.097.439-.374 1.589-.631zm5.721 9.944A5.51 5.51 0 0115 17.5v.473c-.042.057-.324.37-1.612.657-1.066.238-2.447.369-3.888.369s-2.823-.131-3.888-.369c-1.287-.288-1.57-.6-1.612-.657V17.5a5.509 5.509 0 013.667-5.187.5.5 0 00.333-.471V9.159a.5.5 0 00-.333-.471 5.513 5.513 0 01-3.624-4.506c.3.135.666.257 1.093.364C6.308 4.839 7.858 5 9.5 5s3.192-.161 4.364-.454a6.548 6.548 0 001.093-.364 5.511 5.511 0 01-3.624 4.506.5.5 0 00-.333.471v2.683a.5.5 0 00.333.471z"/><path pid="1" d="M13.667 16.529C11.405 15.73 10 13.803 10 11.5V8.475a4.955 4.955 0 001.723-.495.5.5 0 00-.445-.895c-.555.276-1.153.416-1.778.416s-1.223-.14-1.778-.416a.5.5 0 00-.445.895A4.928 4.928 0 009 8.475V11.5c0 2.302-1.405 4.229-3.667 5.029a.499.499 0 00.058.959c.982.22 2.477.512 4.109.512s3.127-.292 4.109-.512a.5.5 0 00.058-.959zM9.5 17c-.877 0-1.701-.087-2.437-.205a6.43 6.43 0 001.711-1.575c.293-.391.536-.812.726-1.254.19.442.433.863.726 1.254a6.432 6.432 0 001.711 1.575c-.736.117-1.56.205-2.437.205z"/>'
  }
})
