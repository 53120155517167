/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" d="M9.067 8.571c1.594 0 3.147.452 4.373 1.273 1.38.924 2.258 2.241 2.54 3.809a1.19 1.19 0 01-.24.974c-.199.24-.495.377-.807.373H3.2a1.03 1.03 0 01-.806-.373 1.188 1.188 0 01-.24-.975c.281-1.567 1.16-2.884 2.54-3.808 1.225-.82 2.778-1.273 4.373-1.273zM2.4 4.286c.295 0 .533.24.533.535v1.34h1.334a.535.535 0 010 1.071H2.933v1.34a.535.535 0 11-1.066 0v-1.34H.533A.535.535 0 010 6.696c0-.295.239-.535.533-.535h1.334V4.82c0-.295.238-.535.533-.535zM9.067 0c1 0 1.91.387 2.562 1.09.659.713.98 1.672.904 2.7-.152 2.046-1.707 3.71-3.466 3.71C7.304 7.5 5.75 5.835 5.6 3.789c-.073-1.037.246-1.998.9-2.705C7.147.385 8.059 0 9.067 0z" _fill="#000" fill-rule="nonzero"/>'
  }
})
