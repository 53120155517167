/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-align-justify': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 5h-15a.5.5 0 010-1h15a.5.5 0 010 1zM17.5 8h-15a.5.5 0 010-1h15a.5.5 0 010 1zM17.5 11h-15a.5.5 0 010-1h15a.5.5 0 010 1zM17.5 14h-15a.5.5 0 010-1h15a.5.5 0 010 1zM17.5 17h-15a.5.5 0 010-1h15a.5.5 0 010 1z"/>'
  }
})
