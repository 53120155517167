
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class StudentLesson extends Vue{
		@Prop({ type: Object, required: true }) readonly lesson!: any;
        @Prop({ default: undefined }) readonly w!: any;

        @State(state => state.menu.compact) statusMenu;
        @State(state => state.auth.permission) userType;
        @State(state => state.grid) bootstrapGrid;

        photoSize: number = 0;
        gridSize: object = {};
        padding: string = '10px';
        btnLarge: boolean = false;
        keyTime: number = 0;
        colOnlineAuto: boolean = true;
        colAutoTime: boolean = true;


        maxSectionsSize: any = {
        	typeModality: 276,
            topic: 171,
            schedule: 171,
            btnsOnline: 201,
            place: 201
        }

        get t(): any{
        	return (this as any).$t;
        }

        get refs(): any{
        	return (this as any).$refs;
        }

        get statusActions1(){
            if ((this.userType == 'tutor' && (this.lesson.type == 'Grupal' || this.lesson.modality == 'Online')) || (this.userType == 'student' && this.lesson.modality == 'Online'))
                return true;
            else
                return false;
        }

        get cardName(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type };
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get statusParting(): any{
            if (this.bootstrapGrid == 'xl'){
                return { typeModality: true, topic: true, schedule: this.lesson.modality == 'Presencial' };
            }
            else if (this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md')
                return { typeModality: true, topic: true, schedule: false };
            else if (this.bootstrapGrid == 'sm')
                return { typeModality: true, topic: true, schedule: false };
            else
                return { typeModality: false, topic: false, schedule: false }
        }

        created(){
            if (this.bootstrapGrid != 'xl' || this.statusMenu)
                this.btnLarge = true;
            else
                this.btnLarge = false;
        }

        mounted(){
        	this.photoSize = this.refs.photo.offsetWidth;
            (this as any).$nextTick(() => {
                if (this.userType == 'tutor')
                    this.maxSectionsSize = {
                        typeModality: 276,
                        topic: 161,
                        schedule: 171,
                        btnsOnline: 201,
                        place: 201
                    }
                this.getGrid();
            });
        }

        getGrid():void {
            let typeModality: number = this.maxSectionsSize.typeModality,
                schedule: number = this.maxSectionsSize.schedule,
                topic: number = this.maxSectionsSize.topic,
                place: number = this.maxSectionsSize.place,
                btnsOnline: number = this.refs.actions?this.refs.actions.offsetWidth + 1:0,
                actions: number = this.refs.actions2.offsetWidth + 1,
                time: number = this.refs.time.offsetWidth + 1;

            const realW = this.w - 2;

            let w = realW;

            let rest = 0,
                p = 10,
                calcPadding = 0;

            if (this.bootstrapGrid == 'xl') { // > 1200px

                if ((this.userType == 'student') || (this.userType == 'tutor' && this.lesson.type == 'Individual')){
                    w  = w - actions - time;

                    typeModality = (w / 4) <= this.maxSectionsSize.typeModality?typeModality:(w / 4);
                    w  -= typeModality;

                    if (this.lesson.modality == 'Presencial'){
                        rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic + this.maxSectionsSize.place + actions + time));
                        p = 12;  
                        calcPadding = rest / p;   

                        w  -= (w / 3) <= this.maxSectionsSize.place && this.statusMenu?place:(w / 3);
                        place = 2;
                    }
                    else{
                        rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic + actions + time));
                        p = 10;  
                        calcPadding = rest / p;

                        if (this.statusMenu) {
                            btnsOnline = (w / 3) <= this.maxSectionsSize.btnsOnline?this.maxSectionsSize.btnsOnline:(w / 3);
                        }
                        else{
                            btnsOnline = btnsOnline;
                        }

                        w  -= btnsOnline; 
                    }

                    topic = (w / 2) <= this.maxSectionsSize.topic && this.statusMenu?topic:(w/2)
                    w -= topic;

                    schedule = this.lesson.modality == 'Presencial'?w:2;
                }
                else{
                    w  = w - actions - time;

                    typeModality = (w / 4) <= this.maxSectionsSize.typeModality?typeModality:(w / 4);
                    w  -= typeModality;

                    

                    if (this.lesson.modality == 'Presencial') {
                        rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic + actions + btnsOnline + time));
                        p = 14;  
                        calcPadding = rest / p; 

                        w  -= btnsOnline;

                        schedule = (w / 3) <= this.maxSectionsSize.schedule && this.statusMenu?schedule:(w / 3);
                        w -= schedule;

                        topic = (w / 2) <= this.maxSectionsSize.topic && this.statusMenu?topic:(w / 2);
                        w -= topic;

                        place = 2;
                    }
                    else{
                        rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic + this.maxSectionsSize.place + actions + btnsOnline + time));
                        p = 12;  
                        calcPadding = rest / p;

                        w  -= btnsOnline;

                        topic = (w / 2);
                        w -= topic;

                        schedule = 2;                        
                    }
                }
            }
            else if(this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md'){
                rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic));
                        p = 6;  
                        calcPadding = rest / p;

                typeModality = (w / 3) <= this.maxSectionsSize.typeModality?typeModality:(w / 3);
                w  -= typeModality;
                topic =  (w / 2);
                w -= topic;
                schedule = 2;
                

                btnsOnline = realW - time - actions;
                place = realW - time - actions;
                if (this.lesson.type == 'Grupal' && this.lesson.modality == 'Presencial' && this.userType == 'tutor') {
                    btnsOnline = (realW - time - actions) / 2;
                    place = (realW - time - actions) / 2;
                }else{
                    btnsOnline = realW - time - actions;
                    place = realW - time - actions;
                }
                this.colOnlineAuto = false;
            }
            else if(this.bootstrapGrid == 'sm'){
                rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule));
                        p = 4;  
                        calcPadding = rest / p;

                typeModality = (w / 2);
                topic = typeModality;
                    w  -= typeModality;

                time = w;
                schedule = w;
                
                place = this.lesson.type == 'Grupal'?(realW - btnsOnline - actions):realW - actions;
                if (this.lesson.modality == 'Online'){
                    this.colOnlineAuto = false;
                    btnsOnline = realW - actions;
                }

                this.colAutoTime = false;
            }
            else if(this.bootstrapGrid == 'xs'){
                rest = realW;
                p = 2;
                calcPadding = 15;
                this.colOnlineAuto = false;
                typeModality = 0;
                schedule = 0;
                topic = 0;
                place = 0;
                btnsOnline = 0;
                time = 0;
            }

            if (rest >= (p * 10))
                this.padding = calcPadding >= 15?'15px':`${calcPadding}px`;
            else
                this.padding = '10px';

            this.gridSize =  { typeModality: { minWidth: typeModality?`${Math.trunc(typeModality - 1)}px`:null },
                               schedule: { minWidth: schedule?`${Math.trunc(schedule - 1)}px`:null },
                               topic: { minWidth: topic?`${Math.trunc(topic - 1)}px`:null },
                               place: { minWidth: place?`${Math.trunc(place - 1)}px`:null },
                               btnsOnline: { minWidth: !this.colOnlineAuto && btnsOnline?`${Math.trunc(btnsOnline - 1)}px`:null },
                               time: { minWidth: time && !this.colAutoTime?`${Math.trunc(time - 1)}px`:null } };
        }

        @Watch('w')
        handleGrid(){
            if (this.bootstrapGrid != 'xl' && this.statusMenu)
                this.btnLarge = true;
            else if(!this.statusMenu)
                this.btnLarge = false;

            this.colOnlineAuto = true;
            this.colAutoTime = true;
            this.getGrid();
        }

        @Watch('statusMenu')
        handleGrid2(val: any){
            this.colOnlineAuto = true;
            this.colAutoTime = true;
            if (this.bootstrapGrid != 'xl' || val)
                this.btnLarge = true;
            else
                this.btnLarge = false;
            setTimeout(()=> {
                this.getGrid();
            }, 500)
        }
	}
