var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-subjects-t" }, [
    _c(
      "h5",
      { staticClass: "v-sch-t-title d-flex align-items-center mb-3" },
      [
        _vm._v(
          "\n           " +
            _vm._s(_vm.$t("scheduleT.subjects.t")) +
            "\n           "
        ),
        _c("v-more-info", {
          staticClass: "ml-2",
          attrs: { txtCloud: _vm.$t("scheduleT.subjects.tooltip") }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        style: { border: "1px solid #9E9E9E", borderRadius: "20px" },
        attrs: { id: "search-autocomplete" }
      },
      [
        _c(
          "div",
          {
            staticClass: "position-relative search",
            on: {
              click: function($event) {
                return _vm.toggleAction()
              }
            }
          },
          [
            _c("svgicon", {
              staticClass: "icon-arrow-down",
              attrs: { name: "chevron-down" }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val"
                }
              ],
              ref: "input",
              attrs: {
                type: "text",
                placeholder: _vm.$t("scheduleT.subjects.placeholder")
              },
              domProps: { value: _vm.val },
              on: {
                keyup: function($event) {
                  return _vm.keyFuction($event.key)
                },
                focus: function($event) {
                  _vm.statusDropdown =
                    _vm.results.length > 0
                      ? _vm.stepsDropdown.focus
                      : _vm.stepsDropdown.blur
                },
                blur: function($event) {
                  return _vm.blurAction()
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.val = $event.target.value
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.statusDropdown == _vm.stepsDropdown.focus ||
        _vm.statusDropdown == _vm.stepsDropdown.hover
          ? _c(
              "perfect-scrollbar",
              {
                ref: "scroll",
                staticClass: "dropdown",
                attrs: { tag: "div" },
                on: {
                  mouseover: function($event) {
                    return _vm.hoverAction()
                  },
                  mouseleave: function($event) {
                    return _vm.leaveAction()
                  }
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "bound", mode: "out-in" } },
                  [
                    _vm.statusResults === _vm.stepsResults.spinner
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            _c("v-spinner", {
                              attrs: { height: "40px", width: "40px" }
                            })
                          ],
                          1
                        )
                      : _vm.statusResults === _vm.stepsResults.message
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "msg-search mt-2 mb-2 ml-auto mr-auto text-center",
                                style: { maxWidth: "550px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("scheduleT.subjects.alertNotFound")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      : _vm.statusResults === _vm.stepsResults.results
                      ? _c(
                          "transition-group",
                          {
                            key: "results",
                            staticClass: "pl-0 mb-0",
                            attrs: { name: "bound", tag: "ul" }
                          },
                          _vm._l(_vm.filterResults, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: `subject-'${item.id}`,
                                ref: "dropdownItem",
                                refInFor: true,
                                staticClass: "dropdown-item",
                                class: {
                                  hover: _vm.resultHover == `di-${item.id}`
                                },
                                attrs: { id: `di-${item.id}` },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSubject(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row ml-n2 mr-n2 align-items-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-sm col-12 pl-2 pr-2 mb-2 mb-sm-0",
                                        style: {
                                          width:
                                            _vm.grid != "xs"
                                              ? "calc(100% - 210px)"
                                              : null
                                        }
                                      },
                                      [
                                        _c("v-hover-txt", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            colorTxt: "#4A4A4A",
                                            positionCloud:
                                              index == 0 ? "top" : "bottom",
                                            txt: item.name
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-hover-txt", {
                                          attrs: {
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            colorTxt: "#A4A4A4",
                                            positionCloud:
                                              index == 0 ? "top" : "bottom",
                                            txt: item.category
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-sm-auto col-12 pl-2 pr-2",
                                        style: {
                                          width:
                                            _vm.grid != "xs" ? "210px" : null
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row ml-n1 mr-n1 justify-content-sm-end justify-content-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-auto pl-1 pr-1"
                                              },
                                              [
                                                _c("v-lesson-tag", {
                                                  attrs: {
                                                    type: item.type,
                                                    options: {
                                                      lessonLimit: item.limit
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-auto pl-1 pr-1"
                                              },
                                              [
                                                _c("v-lesson-tag", {
                                                  attrs: { type: item.modality }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }