/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<defs><filter id="svgicon_send_a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/></filter></defs><g transform="translate(-3 -6)" filter="url(#svgicon_send_a)" _fill="none" fill-rule="evenodd"><path pid="0" d="M31.396 16.39l-1.523-.236-20.035-3.11 6.295 6.988 5.803-.82-5.23 2.695-1.311 9.313z" _fill="#FFF" fill-rule="nonzero"/></g>'
  }
})
