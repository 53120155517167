
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State, Mutation, Action } from 'vuex-class';

    @Component
    export default class formatLearningInventory extends Vue {
        @Prop({ default: null, required: false }) readonly studentFormats: any;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.formats.learningStyles ) statusLearningStyles;
        
        @Mutation('handleFormat') handleFormat;
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;
 
        sending: boolean = false;
        qst: Array<object> = [];
        qstVal: any = null;
        error: boolean = false;
        imReady: boolean = false;
        createdDate: any = null;
        user: any = null;
        results: any = { visual: { total: 0, questions: [] },
                         hearing: { total: 0, questions: [] },
                         kinesthetic: { total: 0, questions: [] } };
        pdfData: any = {};

        get moment(): any{
            return (this as any).$moment;
        }

        get statusFormat(): boolean{
            const st = this.studentFormats?.forms;

            return st?st.learningStyles:this.statusLearningStyles;
        }

        get answers(): Array<object>{
            return [
                { val: 1, t: this.$t('formats.fm3.qstT.q1') },
                { val: 2, t: this.$t('formats.fm3.qstT.q2') },
                { val: 3, t: this.$t('formats.fm3.qstT.q3') },
                { val: 4, t: this.$t('formats.fm3.qstT.q4') },
                { val: 5, t: this.$t('formats.fm3.qstT.q5') },
            ]
        }

        created(): void{
            this.getFormat();
        }

        getFormat(): void{
            const params = this.studentFormats?.studentId?{ params: { studentId: this.studentFormats?.studentId } }:{};

            this.axios({ config: {
                method: 'GET',
                url: `${this.api}/tecnm/forms/learning-styles`,
                headers: { Authorization: this.token },
                ...params
            } }).then( resp => resp.data )
                .then( data => {
                    this.qst = data.learningQuestions;

                    if(this.statusFormat && data.created){
                        const formatDate = this.moment(data.created).locale(this.lang).format(this.$t('formatDateC'));
                        this.createdDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
                    }

                    if(this.statusFormat && data.user)
                        this.user = data.user;

                    if(this.statusFormat && data.score){
                        this.results.visual.total = data.score.visual;
                        this.results.kinesthetic.total = data.score.kinesthetic;
                        this.results.hearing.total = data.score.hearing;
                        this.getResults();
                    }

                    if(this.statusFormat){
                        this.pdfData = { 
                            createdDate: this.createdDate, 
                            ...this.user, 
                            answers: this.answers, 
                            q: this.qst, 
                            results: this.results 
                        }
                    }

                    this.imReady = true;
            }).catch( err => {
                this.imReady = true;
                console.log(err);
            })
        }

        getScore(): Promise<Array<object>>{
            return this.qstVal.questions.reduce( (arr, q, i) =>{
                const index = i+1;

                if(q.answer != null){
                    if(index == 1 || index == 3 || index == 6 || index == 9 || index == 10 || index == 11 || index == 14)
                       arr.visual += q.answer;
                    else if(index == 2 || index == 5 || index == 12 || index == 15 || index == 17 || index == 21 || index == 23)
                       arr.hearing += q.answer;
                    else if(index == 4 || index == 7 || index == 8 || index == 13 || index == 19 || index == 22 || index == 24)
                       arr.kinesthetic += q.answer;
                }
                
                return arr;
            }, { visual: 0, hearing: 0, kinesthetic: 0 })
        }

        getResults(): any{
            for(let i: number = 0; i < 24; i++){
                let a: number = 0;
                const q: any = this.qst[i],
                      index = i+1;
                
                if(q)
                    a = q.answer;

                if(index == 1 || index == 3 || index == 6 || index == 9 || index == 10 || index == 11 || index == 14)
                    this.results.visual.questions = [ ...this.results.visual.questions, { question: index, answers: a } ];
                else if(index == 2 || index == 5 || index == 12 || index == 15 || index == 17 || index == 21 || index == 23)
                    this.results.hearing.questions = [ ...this.results.hearing.questions, { question: index, answers: a } ];
                else if(index == 4 || index == 7 || index == 8 || index == 13 || index == 19 || index == 22 || index == 24)
                    this.results.kinesthetic.questions = [ ...this.results.kinesthetic.questions, { question: index, answers: a } ];
            }
        }

        sendFormat(): void{
            if(!this.qstVal?.status){
                this.error = true;
                this.notification({ type: 'warning', msg: 'Lorem ipsum dolor sit amet consectetur adipiscing elit congue natoque justo nec ac.' });
                return;
            }

            if(!this.sending){
                this.error = false;
                this.sending = true;

                this.axios({ config: {
                    url: `${this.api}/tecnm/forms/learning-styles`,
                    method: 'POST',
                    headers: { Authorization: this.token },
                    data: {
                        learningQuestions: this.qstVal.questions,
                        score: this.getScore()
                    }
                } }).then( resp => resp.data )
                    .then( data => {
                    this.handleFormat({ format: 'learningStyles', status: 1 });
                    this.sending = false;
                    this.notification({ type: 'success', msg: data.msg });
                    this.$emit('openMenu');
                }).catch( err => {
                    this.sending = false;
                    console.log(err);
                } )
            }
        }

        print(){
            (this as any).$refs.pdf.print();
        }
    }
