var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-notifications" }, [
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c(
          "transition-group",
          {
            staticClass: "row",
            style: {
              "--hover-color":
                this.userType == "student" ? "#2188FC" : "#005E58"
            },
            attrs: { name: "bound", tag: "div" }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statusList,
                    expression: "statusList"
                  }
                ],
                key: "list",
                staticClass: "col col-xl-4",
                style: { maxWidth: _vm.grid == "xl" ? "400px" : null }
              },
              [_c("v-notification-list")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statusLessonInfo,
                    expression: "statusLessonInfo"
                  }
                ],
                key: "lesson",
                staticClass: "col-xl",
                class: {
                  "col-lg-7": _vm.grid == "lg" && _vm.statusMenu,
                  "d-flex flex-column": _vm.statusLessonInfo
                }
              },
              [
                _vm.grid == "xs" ||
                _vm.grid == "sm" ||
                _vm.grid == "md" ||
                (_vm.grid == "lg" && !_vm.statusMenu)
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "vn-btn-prev d-flex align-items-center mb-3",
                        on: {
                          click: function($event) {
                            _vm.lesson = null
                          }
                        }
                      },
                      [
                        _c("svgicon", {
                          staticClass: "vn-btn-icon mr-2",
                          attrs: { name: "arrow-left" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "vn-btn-txt" }, [
                          _vm._v(_vm._s(_vm.$t("notifications.btnBack")))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "v-nf-paper h-100 pl-4 pl-xl-5 pt-4 pr-4 pr-xl-5 pb-4"
                  },
                  [
                    _c(
                      "transition",
                      {
                        attrs: { name: "bound", mode: "out-in" },
                        on: { enter: _vm.enter }
                      },
                      [
                        !_vm.lesson
                          ? _c(
                              "div",
                              {
                                key: "alert",
                                staticClass:
                                  "h-100 d-flex flex-column align-items-center justify-content-center pl-4 pr-4"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "v-n-alert-icon mb-4" },
                                  [
                                    _c("svgicon", {
                                      staticClass: "icon-bell",
                                      attrs: {
                                        name: "bell-fill",
                                        color: "#F0F0F0"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "v-n-alert-txt text-center" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("notifications.alerts.select")
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm.loading
                          ? _c(
                              "div",
                              {
                                key: "spinner",
                                staticClass:
                                  "h-100 d-flex justify-content-center align-items-center"
                              },
                              [_c("v-spinner")],
                              1
                            )
                          : _c("div", { key: "lesson" }, [
                              _c("h2", { staticClass: "v-nf-title mb-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `notifications.type.${_vm.lesson.lessonStatus}.t`
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "v-nf-date mb-5" }, [
                                _vm._v(_vm._s(_vm.dateTime))
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                ref: "info",
                                staticClass: "v-nf-typeMsg mb-4",
                                domProps: { innerHTML: _vm._s(_vm.lesson.body) }
                              }),
                              _vm._v(" "),
                              _vm.lesson.status != "Cancelada" &&
                              _vm.lesson.status != "Finalizada"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row ml-1 mr-1 justify-content-end mb-4"
                                    },
                                    [
                                      _vm.btnJoin
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-2 pr-2 mb-2"
                                            },
                                            [
                                              _c("v-lesson-btn", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  options: {
                                                    type: "online",
                                                    idLesson: _vm.lesson.id
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.btnStop
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-1 pr-1 mb-2"
                                            },
                                            [
                                              _c("v-lesson-btn", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  options: {
                                                    type: "stop",
                                                    idLesson: _vm.lesson.id,
                                                    minWidth: "130px"
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.btnCancel
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-1 pr-1 mb-2"
                                            },
                                            [
                                              _c("v-lesson-btn", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  options: {
                                                    type: "cancel",
                                                    idLesson: _vm.lesson.id,
                                                    minWidth: "130px"
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.btnConfirm
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-1 pr-1 mb-2"
                                            },
                                            [
                                              _c("v-lesson-btn", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  options: {
                                                    type: "confirm",
                                                    idLesson: _vm.lesson.id,
                                                    minWidth: "130px"
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.btnStart
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-1 pr-1 mb-2"
                                            },
                                            [
                                              _c("v-lesson-btn", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  options: {
                                                    type: "start",
                                                    idLesson: _vm.lesson.id,
                                                    minWidth: "130px"
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "v-bf-info p-3 p-sm-4 w-100 mb-3",
                                  style: {
                                    border: `2px solid ${
                                      _vm.userType == "tutor"
                                        ? "#005E58"
                                        : "#0091FF"
                                    }`
                                  }
                                },
                                [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center"
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "v-nf-color-status",
                                          class: _vm.statusLesson.color
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "v-nf-status pl-1 pr-1 mb-0"
                                          },
                                          [_vm._v(_vm._s(_vm.statusLesson.t))]
                                        ),
                                        _vm._v(" "),
                                        _c("v-more-info", {
                                          attrs: {
                                            txtCloud: _vm.statusLesson.tooltip
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row ml-n2 mr-n2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-sm-auto pl-2 pr-2 mb-3"
                                        },
                                        [
                                          _c("v-lesson-photo", {
                                            staticClass: "ml-auto mr-auto",
                                            attrs: {
                                              options: _vm.lessonPhotoOpc
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col pl-2 pr-2 pt-3" },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-lg mb-3",
                                                style: {
                                                  minWidth: "240px",
                                                  maxWidth:
                                                    _vm.grid == "xl"
                                                      ? `${(100 / 12) * 5}%`
                                                      : null
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "v-nf-tutor col-12 mb-1"
                                                      },
                                                      [_vm._v(_vm._s(_vm.name))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row ml-n1 mr-n1"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-auto pl-1 pr-1 pb-1 pt-1"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-lesson-tag",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1",
                                                                    attrs: {
                                                                      type:
                                                                        _vm
                                                                          .lesson
                                                                          .type,
                                                                      options: {
                                                                        ..._vm.tagTypeOpc,
                                                                        minWidth:
                                                                          "80px"
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-auto pl-1 pr-1 pb-1 pt-1"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-lesson-tag",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1",
                                                                    attrs: {
                                                                      type:
                                                                        _vm
                                                                          .lesson
                                                                          .modality,
                                                                      options: {
                                                                        minWidth:
                                                                          "80px"
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-lg mb-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "v-nf-subject mb-0 position-relative"
                                                          },
                                                          [
                                                            _c("svgicon", {
                                                              staticClass:
                                                                "v-nf-icon mr-2",
                                                              attrs: {
                                                                name:
                                                                  _vm.lesson
                                                                    .icon,
                                                                color: "#000"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              "\n\t    \t\t\t\t    \t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.lesson
                                                                    .subject
                                                                ) +
                                                                "\n\t    \t\t\t\t    \t\t\t\t\t\t\t"
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.statusTopic
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 col-md mt-2"
                                                          },
                                                          [
                                                            _c(
                                                              "v-lesson-topic",
                                                              {
                                                                attrs: {
                                                                  options: {
                                                                    lessonId:
                                                                      _vm.lesson
                                                                        .id,
                                                                    topic:
                                                                      _vm.lesson
                                                                        .topic,
                                                                    hideBtn: !(
                                                                      _vm.lesson
                                                                        .status ==
                                                                        "Pendiente" ||
                                                                      _vm.lesson
                                                                        .status ==
                                                                        "Confirmada"
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "v-nf-fulldate mb-3 position-relative"
                                            },
                                            [
                                              _c("svgicon", {
                                                staticClass: "v-nf-icon mr-2",
                                                attrs: {
                                                  name: "calendar",
                                                  color: "#000"
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t    \t\t\t\t    \t\t\t\t" +
                                                  _vm._s(_vm.fullDate) +
                                                  "\n\t    \t\t\t\t    \t\t\t"
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-lesson-place", {
                                            staticClass: "mb-3",
                                            attrs: {
                                              options: {
                                                icon: _vm.lesson.placeIcon,
                                                place: _vm.lesson.place,
                                                modality: _vm.lesson.modality,
                                                type: _vm.lesson.type,
                                                lessonId: _vm.lesson.id,
                                                edit:
                                                  _vm.lesson.status ==
                                                    "Pendiente" ||
                                                  _vm.lesson.status ==
                                                    "Confirmada"
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-lesson-btn", {
                                            attrs: {
                                              options: {
                                                type: "chat",
                                                idLesson: _vm.lesson.id,
                                                minWidth: "150px"
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "v-nf-lessons position-relative",
                                      attrs: {
                                        tag: "button",
                                        to: { name: `lessons-${_vm.userType}` }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "scheduleLesson.msgSuccess.btnPrev"
                                          )
                                        ) + " "
                                      ),
                                      _c("svgicon", {
                                        staticClass: "bl-icon",
                                        attrs: { name: "arrow-right" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }