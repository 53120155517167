
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class identificationPdf extends Vue{
        @Prop({ default: () => { return {} } }) readonly data: any;

        print(): void{
            (this as any).$refs.html2Pdf.generatePdf(); 
        }
    }
