/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shape': {
    width: 30,
    height: 28,
    viewBox: '0 0 30 28',
    data: '<path pid="0" d="M29.99 13.74a1.183 1.183 0 00-.686-1.023L1.612.097A1.134 1.134 0 00.316.37c-.333.36-.411.897-.195 1.342l5.902 12.09L.12 25.891c-.216.444-.138.98.195 1.34.333.362.85.47 1.296.274l27.692-12.62c.439-.2.711-.655.685-1.146zm-6.6-1.122H8.019L3.62 3.61l19.772 9.01zm0 2.366l-19.77 9.01 4.398-9.01h15.373z" _fill="#1F7CDD" fill-rule="nonzero"/>'
  }
})
