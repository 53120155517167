/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no-micro': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#E82719" cx="21" cy="21" r="21"/><path pid="1" _stroke="#FFF" stroke-linecap="square" d="M10.5 32.5l22-22"/><g _fill="#FFF" fill-rule="nonzero"><path pid="2" d="M21 25a4.312 4.312 0 01-4.308-4.308v-7.384A4.312 4.312 0 0121 9a4.312 4.312 0 014.308 4.308v7.384A4.312 4.312 0 0121 25z"/><path pid="3" d="M29 20.692a.616.616 0 00-1.23 0 6.776 6.776 0 01-6.77 6.77 6.776 6.776 0 01-6.77-6.77.616.616 0 00-1.23 0c0 4.205 3.26 7.662 7.385 7.977v2.485h-1.847a.616.616 0 000 1.23h4.924a.616.616 0 000-1.23h-1.847v-2.485C25.74 28.354 29 24.897 29 20.692z"/></g></g>'
  }
})
