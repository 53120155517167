/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'launch': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.5 0h-5a.5.5 0 000 1h3.833L7.147 12.146a.5.5 0 10.706.708L19 1.747V5.5a.5.5 0 001 0v-5a.5.5 0 00-.5-.5z"/><path pid="1" d="M16.5 20h-15C.673 20 0 19.327 0 18.5v-15C0 2.673.673 2 1.5 2h10a.5.5 0 010 1h-10a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-10a.5.5 0 011 0v10c0 .827-.673 1.5-1.5 1.5z"/>'
  }
})
