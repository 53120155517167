/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-right': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 2h-8a.5.5 0 000 1h6.793L2.147 17.146a.5.5 0 00.708.707L17.001 3.707V10.5a.5.5 0 001 0v-8a.5.5 0 00-.5-.5z"/>'
  }
})
