<template>
	<div id="notifications" :style="{ pointerEvents: (notifications.length + notificationsE.length)>0?'initial':'none' }">
        <transition-group name="notification" tag="div">
		    <div class="notification mt-2" :type="auth.permission==='tutor' && item.type.toLowerCase() === 'success'?'successt':item.type.toLowerCase()" :key="`msg-${item.id}`" v-for="(item,i) in [...notifications, ...notificationsE]">
		    	<svgicon class="n-icon" :name="item.icon?item.icon:'checkmark-circle'" v-if="item.type.toLowerCase() === 'success'"/>
		    	<svgicon class="n-icon" :name="item.icon?item.icon:'wondering'" v-else-if="item.type.toLowerCase() === 'warning'"/>
		    	<svgicon class="n-icon" :name="item.icon?item.icon:'shocked'" v-else/>
		    	<h6 class="title mb-0" v-if="item.title">{{ item.title }}</h6>
			    <p class="msg mb-0" v-html="handleMsg(item.msg)"></p>
			    <svgicon class="btn-close" name="cross-circle" @click="close(item)" />
		    </div>
		</transition-group>
	</div>
</template>
<script>
	import {  mapState, mapMutations } from 'vuex';
	export default{
		props: {
			max: { type: Number, default: 5, required: false },
			timeToClose: { type: Number, default: 5000, required: false }
		},
		computed:{
            ...mapState(['auth', 'notification'])
		},
		data(){
			return{
				id: 0,
				idE: 0,
				time: '',
				notifications: [],
                notificationsE: []
			}
		},
		methods:{
			...mapMutations(['setNotification']),
            close(item){
            	if (item.noClose)
            		this.notificationsE = this.notificationsE.filter( n => n.id !== item.id );
                else{
                	this.notifications = this.notifications.filter( n => n.id !== item.id );
            	    clearTimeout(this.time);
            	    if(this.notifications.length > 0){
            	    	this.initTime();
            	    }else{
            	    	this.id = 0;
            	    }
                }
            },
            initTime(){
            	const that = this;
            	const tn = this.notifications.length; 
            	if (tn > 0)
            		this.time = setTimeout( () => {
            			that.notifications.pop();
            			that.initTime();  
            		}, this.timeToClose);
            	else{
            		this.id = 0;
            		clearTimeout(this.time);
            	}
            },
			handleMsg(txt){
                const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
				let newMsg = txt.split(' ');
				newMsg = newMsg.reduce( (str, item) => {
					let hasUrl = false;

                    let st = item.replace(urlRegex, (url) => {
						hasUrl = true;
					    return `<span class="hyphens"> ${url} </span>`
                    });

					if(!hasUrl && st.length > 24)
					    st = `<span class="hyphens"> ${st} </span>`

					str = `${str} ${st}`;

					return str;
				});

				return newMsg;
			}
		},
		updated(){
			if (this.id == 1) {
                this.initTime();
			}
		},
		watch:{
			notification: function(newVal){
				if (newVal!='' && !newVal.noClose){
					if ( this.notifications.length  >= (5 - this.notificationsE.length) ){
						this.notifications.pop();
            	        this.initTime();
					}else if(this.notifications.length === 0){
                        this.id = 0;
					}
					if (newVal.closeNE)
						this.notificationsE.pop();
					this.notifications.unshift({ ...newVal, id: this.id });
				    this.id++;
					this.setNotification('');
				}else if(newVal!='' && newVal.noClose){
                    if (this.notificationsE.length >= 1)
						this.notificationsE.pop();
					if(newVal.noClose !== 'close'){ 
						this.notificationsE.unshift({ ...newVal, id: 'e-'+this.idE });
						this.idE++;
						this.setNotification('');
				    }
				}
			}
		}
	}
</script>