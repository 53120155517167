<template>
	<div id="biografhy" class="container-fluid pb-5">
		<div class="paper row  ml-sm-0 mr-sm-0 pl-sm-4 pr-sm-4 pt-sm-5 pb-sm-5 pt-4 pb-4 justify-content-center">
			<form class="content-biografhy col-md" @submit.prevent="changeDescription()">
				<h2 class="title-biografhy mb-3"> <svgicon class="icon" name="bookmark"></svgicon> {{ $t('bio.t') }} </h2>
				<p class="descrip-biografhy mb-4">{{ $t('bio.descrip1') }} <router-link :to="{ name: 'profile-tutor' }" tag="strong">{{ $t('bio.descrip2') }}</router-link> {{ $t('bio.descrip3') }}</p>
				<div class="paper d-flex flex-wrap p-4 mb-4">
					<v-img-profile class="col pl-0 pr-0 mr-1" :img="auth.user.photo" />
					<div class="col d-flex align-items-center d-block d-sm-none">
						<h3 class="name pt-2 mb-2">{{ fullName }}</h3>
					</div>
					<div class="col-sm mt-2 mt-sm-0">
						<h3 class="name pt-2 mb-2 d-none d-sm-block">{{ fullName }}</h3>
						<textarea class="biografhy w-100 mt-1 mb-3" cols="30" rows="10" :placeholder="$t('bio.placeholder')" v-model="description"></textarea>
						<v-btn class="w-100" :txt="$t('bio.btnSave')" type="submit"  :disabled="sending"/>
					</div>
				</div>
				<v-circles :vCircle="3" size="250px"/>
			</form>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		data(){
			return{
				sending: false,
				description: ''
			}
		},
		computed:{
			...mapState(['auth']),
			fullName(){
				return `${this.auth.user.name} ${this.auth.user.last_name}`
			}
		},
		methods:{
			...mapMutations(['setDescription','setNotification']),
			...mapActions(['axiosRequest']),
			changeDescription(){
				if (!this.sending){
					this.sending = true;
					this.$Progress.start();
					this.axiosRequest({ config : {
						method: 'PUT',
						url: `${this.auth.api}/tutor/bio`,
						data: { description : this.description },
						headers: { authorization : this.auth.token }
					}}).then( resp => resp.data )
					.then( data => {
						this.sending = false;
						this.setDescription(data.data.description);
						this.$Progress.finish();
						this.setNotification({ type: data.type, msg: data.message });
					}).catch( error => {
						this.sending = false;
						this.$Progress.fail();
					});
                }
			}
		},
		created(){
			this.description = this.auth.user.description;
		}
	}
</script>