/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ghost-hipster': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 20c-1.645 0-2.506-.413-3.134-.715-.344-.165-.592-.285-.866-.285-.349 0-.638.193-.973.416-.411.274-.876.584-1.527.584s-1.117-.31-1.527-.584C10.138 19.193 9.849 19 9.5 19s-.638.193-.973.416C8.116 19.69 7.651 20 7 20c-.662 0-1.158-.319-1.595-.601C5.073 19.185 4.786 19 4.5 19c-.22 0-.455.112-.779.266C3.07 19.576 2.179 20 .5 20a.5.5 0 01-.5-.5v-9c0-2.538.988-4.923 2.783-6.717S6.963 1 9.5 1c2.538 0 4.923.988 6.718 2.783S19 7.963 19 10.5v9a.5.5 0 01-.5.5zm-9-2c.651 0 1.117.31 1.527.584.335.223.624.416.973.416s.638-.193.973-.416c.411-.274.876-.584 1.527-.584.501 0 .889.186 1.299.383.5.24 1.108.532 2.201.601v-8.485c0-4.687-3.813-8.5-8.5-8.5S1 5.812 1 10.499v8.485c1.131-.07 1.767-.373 2.291-.622.393-.187.763-.363 1.209-.363.579 0 1.04.296 1.446.558.368.237.687.442 1.054.442.349 0 .638-.193.973-.416.411-.274.876-.584 1.527-.584z"/><path pid="1" d="M6.5 10C5.673 10 5 9.327 5 8.5S5.673 7 6.5 7 8 7.673 8 8.5 7.327 10 6.5 10zm0-2a.5.5 0 100 1 .5.5 0 000-1zM12.5 10c-.827 0-1.5-.673-1.5-1.5S11.673 7 12.5 7s1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 100 1 .5.5 0 000-1zM15.923 14.233a.502.502 0 00-.521-.224 2.784 2.784 0 01-.536.056c-.872 0-1.402-.503-1.962-1.036-.533-.506-1.084-1.03-1.903-1.03-.597 0-1.133.263-1.5.679a1.996 1.996 0 00-1.5-.679c-.819 0-1.37.524-1.903 1.03-.561.533-1.09 1.036-1.962 1.036-.169 0-.349-.019-.536-.056a.502.502 0 00-.498.791c1.157 1.543 2.642 1.867 3.685 1.867.621 0 1.115-.117 1.355-.187.531-.154 1.018-.542 1.36-1.028.343.486.83.873 1.36 1.028.24.07.734.187 1.355.187 1.042 0 2.528-.324 3.685-1.867a.5.5 0 00.023-.567zM7.86 15.52a3.927 3.927 0 01-1.076.147 3.48 3.48 0 01-2.069-.654c.935-.179 1.556-.768 2.07-1.258.492-.468.815-.755 1.214-.755.551 0 1 .449 1 1 0 .611-.554 1.349-1.14 1.52zm4.355.147c-.497 0-.887-.092-1.076-.147-.586-.17-1.14-.909-1.14-1.52 0-.551.449-1 1-1 .4 0 .722.287 1.214.755.515.489 1.135 1.079 2.07 1.257a3.485 3.485 0 01-2.069.654z"/>'
  }
})
