<template>
    <div id="reset-password" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">
        <div class="v-init-form-content d-flex flex-column">
            <div class="v-paper w-100 mb-4">
                <div class="v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3">
                    <h2 class="v-if-title mb-0">{{ $t('resetPassword.t') }}</h2>
                </div>

                <!-- ========== Form ========== -->
                <div class="p-4 d-flex flex-column align-items-center">
                    <div class="bg-icon mb-4">
					    <svgicon class="inset-icon lock2" name="lock2" color="#fff" />
				    </div>
                    <form class="w-100" @submit.prevent="send()" >
                        <v-textField class="mb-3" :label="$t('resetPassword.form.password')" type="password" maxlength="50" v-model="form.password" :required="true" name="password" :options="opcInput" ref="password" :error="!$v.form.password.minLength" :iconEye="true"/>
                        <v-textField class="mb-2" :label="$t('resetPassword.form.samePassword')" type="password" maxlength="50" v-model="form.password2" :required="true" name="password2" :options="opcInput" ref="password" :error="!$v.form.password2.sameAs || !this.$v.form.password2.minLength" :iconEye="true"/>
                        <p class="v-if-alert text-left mb-1" v-if="!$v.form.password2.sameAs && (form.password.length >= 6 && form.password2.length >= 6)">{{ $t('resetPassword.step1') }}</p>
					    <p class="v-if-alert text-left" v-if="!$v.form.password.minLength || !this.$v.form.password2.minLength">{{ $t('resetPassword.step2') }}</p>
                        <v-btn class="mt-4 w-100" fontSize="1rem" fontWeight="400" :txt="$t('resetPassword.btn')" height="40px" type="submit" name="resetPassword" :spinner="sending" :disabled="$v.form.$invalid" />
                    </form>
                </div>
            </div>

            <!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
        </div>

        <!-- ========== Select language ========== -->
		<v-select-lang />
    </div>
</template>

<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';

	export default{
		data(){
			return{
				sending: false,
				form: {
					password: '',
				    password2: ''
				},
				opcInput: {
					styles: {
						label: {
							color: '#5E5E5E',
							fontWeight: 500,
							'--transform-active': 'translateY(0)'
						}
					}
				}
			}
		},
		computed:{
			...mapState({ api: state => state.auth.api })
		},
		methods:{
			...mapMutations(['setUser','setPath','setNotification']),
		    ...mapActions(['axiosRequest']),
            send(){
                if (!this.$v.form.$invalid && !this.sending){
                	this.sending = true;
                	this.axiosRequest({ config : {
            			method: 'PATCH',
                        data: { password : this.form.password },
                        url: `${this.api}/user/password/reset/${this.$route.params.token}`,
            		}, error401: false }).then( resp => resp.data.data)
                    .then( data => {
                    	this.sending = false;
                        this.setUser(data);
                        this.setPath('Login');
                    	this.$router.push({ name : 'confirm-token-password' }); 
                    } )
                    .catch( error => {
                    	this.sending = false;
                    	this.setNotification({ type: 'error', msg: error.response.data.error.message });
                    });
                }
            }
		},
		validations: {
			form:{
				password: {
            	    required,
            	    minLength: minLength(8),
            	    maxLength: maxLength(50),
                },
                password2:{
            	    required,
            	    minLength: minLength(8),
            	    maxLength: maxLength(50),
            	    sameAs: sameAs('password')
                }
			} 
        }
	}
</script>