/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.707 10.5l2.646-2.646a.5.5 0 00-.707-.707L13 9.793l-2.646-2.646a.5.5 0 00-.707.707l2.646 2.646-2.646 2.646a.5.5 0 00.708.707l2.646-2.646 2.646 2.646a.498.498 0 00.708 0 .5.5 0 000-.707L13.709 10.5z"/><path pid="1" d="M18.5 17h-11c-.704 0-1.574-.408-2.024-.948l-3.72-4.464c-.509-.61-.509-1.566 0-2.177l3.72-4.464c.45-.541 1.321-.948 2.024-.948h11c.827 0 1.5.673 1.5 1.5v10c0 .827-.673 1.5-1.5 1.5zM7.5 5c-.401 0-.999.28-1.256.588l-3.72 4.464a.747.747 0 000 .896l3.72 4.464c.257.308.855.588 1.256.588h11a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5h-11z"/>'
  }
})
