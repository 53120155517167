/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat2': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M1.553 12.465l.95-2.27C1.56 10.004.81 9.572.397 8.74c-.206-.413-.357-.899-.37-1.357C-.018 5.868.005 4.348.012 2.83A2.842 2.842 0 012.835.01c2.263-.012 4.526-.012 6.79 0 1.363.006 2.645 1.114 2.714 2.484.085 1.696.096 3.402.013 5.1-.077 1.56-1.343 2.612-2.935 2.614-1.298.002-2.597-.01-3.895.008-.23.004-.454.069-.65.188-1.09.693-2.166 1.408-3.247 2.116l-.072-.054z"/><path pid="1" d="M13.051 4.081c1.338-.149 2.266.281 2.715 1.264.127.278.197.577.206.882.025 1.088-.03 2.18.023 3.266.063 1.23-.52 2.265-1.966 2.62l.79 1.887c-.909-.594-1.732-1.148-2.575-1.67a1.482 1.482 0 00-.713-.217c-.976-.023-1.952-.01-2.929-.004-.93.005-1.625-.383-2.145-1.238.539 0 1.014.004 1.49 0 .641-.006 1.29.035 1.924-.042 1.923-.234 3.185-1.695 3.18-3.614V4.081z"/></g>'
  }
})
