<template>
	<div class="v-hover position-relative" ref="contentTxt" @mouseover="hover" @mouseleave="removeHover">
		<transition name="bound">
		      <div class="txt-cloud" v-if="statusHover" key="cloud" :style="{ [positionCloud]: positionCloudVal, width: widthCloud, minWidth: minWidthCloud }"><p class="mb-0">{{ txt }}</p></div>
	    </transition>
		<span class="txt-hover" ref="txt" :style="ellipsisStyles">{{ txt }}</span>
	</div>
</template>
<script>
	export default{
		props:{
            txt: { default: '', required: true },
            fontSize: { type: String, default: '14px', required: false },
            fontWeight: { default: 300, required: false },
            lineHeight: { type: String, default: '120%', required: false },
            colorTxt: { type: String, default: '', required: false },
            lines: { type: Number, default: 1, required: false  },
            positionCloud: { type: String, default: 'bottom', required: false },
            positionCloudVal: { type: String, default: '100%', required: false },
            widthCloud: { type: String, default: '100%', required: false },
            minWidthCloud: { type: String, default: '140px', required: false },
            showCloud: { type: Boolean, default: true, required: false },
		},
		data(){
            return{
            	statusHover: false
            }
		},
		computed:{
            ellipsisStyles(){
            	const styles = { fontSize: this.fontSize, fontWeight: this.fontWeight, color: this.colorTxt, lineHeight: this.lineHeight };
                if (this.lines <= 1)
                	return { ...styles, display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
                else
                	return { ...styles, display: '-webkit-box', '-webkit-line-clamp': this.lines, '-webkit-box-orient': 'vertical' }
            }
		},
		methods:{
			hover(){
				const el = this.$refs.txt,
				that = this;

				if (el && this.showCloud) {
					if (this.lines <= 1){
						if (el.clientWidth < el.scrollWidth)
							that.statusHover = true;
						else
							that.statusHover = false;
				    }else{
				    	if (el.clientHeight < el.scrollHeight)
							that.statusHover = true;
						else
							that.statusHover = false;
				    }
			    }
			},
			removeHover(){
				this.statusHover = false;
			} 
		}
	}
</script>