var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "login" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c("div", { staticClass: "v-paper w-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3"
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "v-btn-prev mr-2",
                  attrs: {
                    to: { name: "email-validatation" },
                    name: "institutionEmail",
                    type: "button",
                    tag: "button"
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "v-btn-prev-icon",
                    attrs: { name: "arrow-left", color: "#fff" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("login.t")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4 d-flex flex-column align-items-center" },
            [
              _c("img", {
                staticClass: "v-institution-logo mb-4",
                attrs: { src: _vm.institution.icon, alt: _vm.institution.name }
              }),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "w-100",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.send()
                    }
                  }
                },
                [
                  _c("v-textField", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("login.form.email"),
                      type: "email",
                      options: _vm.opcInput,
                      required: true,
                      disabled: true
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textField", {
                    ref: "password",
                    staticClass: "pb-2",
                    attrs: {
                      label: _vm.$t("login.form.password.label"),
                      placeholder: _vm.$t("login.form.password.placeholder"),
                      type: "password",
                      maxlength: "50",
                      options: _vm.opcInput,
                      required: true
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-btn", {
                    staticClass: "mb-2 mt-4 w-100",
                    attrs: {
                      fontSize: "1rem",
                      fontWeight: "400",
                      txt: _vm.$t("login.btn"),
                      height: "40px",
                      type: "submit",
                      name: "login",
                      spinner: _vm.sending,
                      disabled: _vm.$v.validationGroup.$invalid
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "v-btn-linc",
                          attrs: {
                            to: { name: "recovery-password" },
                            name: "forgotPassword",
                            type: "button",
                            tag: "button"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.forgot")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }