/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diamond4': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 18a.499.499 0 01-.372-.166l-9-10A.498.498 0 01.1 7.2l3-4a.5.5 0 01.4-.2h6a.5.5 0 010 1H3.75L1.147 7.471 9.5 16.752l8.353-9.281L15.25 4H13.5a.5.5 0 010-1h2a.5.5 0 01.4.2l3 4a.5.5 0 01-.028.634l-9 10A.502.502 0 019.5 18z"/><path pid="1" d="M11.5 10a.5.5 0 01-.5-.5C11 8.122 9.878 7 8.5 7a.5.5 0 010-1C9.878 6 11 4.878 11 3.5a.5.5 0 011 0C12 4.878 13.122 6 14.5 6a.5.5 0 010 1A2.503 2.503 0 0012 9.5a.5.5 0 01-.5.5zm-1.199-3.5c.49.296.903.708 1.199 1.199.296-.49.708-.903 1.199-1.199A3.536 3.536 0 0111.5 5.301c-.296.49-.708.903-1.199 1.199z"/>'
  }
})
