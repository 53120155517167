<template>
	<div class="v-joined-students" :class="{ active : show }" v-if="modalVal.modal=='v-modal-attendance'">
		<div class="blurColor" @click="close()"></div>
		<div class="v-joined-students-modal">
			<h4 class="title pt-4 pb-3 pl-5 pr-5 mb-0">
				<span class="d-block w-100">{{ $t('modals.attendance.t') }}</span>
				<svgicon class="btn-close" name="cross2" @click="close()" />
			</h4>
			<div class="pt-3 pl-5 pr-4 mb-3">
				<perfect-scrollbar class="list-students pl-0 pr-4" tag="ul">
                    <li class="student v-check d-flex align-items-center pt-2 pb-2 pr-4" v-for="student in currentLesson.students" :class="{ active: student.assistance }" @click="student.assistance = student.assistance?0:1"> 
                    	<v-img-profile class="mr-1" :img="student.photo" size="40px" />
                    	<div class="col pl-0">
                    		<span class="name d-block w-100">{{ student.name }}</span>
                    		<span class="subject d-block w-100">{{ student.subject }}</span>
                    	</div>
                    </li>
				</perfect-scrollbar>
			</div>
			<div class="pl-4 pr-4 pb-4">
				<v-btn class="w-100" :txt="$t('modals.attendance.btn')" btn="warning" height="44px" fontSize="20px" @click.native="send()" :disabled="sending"/>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	export default{
		mixins: [ modal ],
		data(){
            return{
                sending: false
            }
        },
		computed:{
			...mapState(['auth','currentLesson']),
			...mapGetters(['modalVal'])
		},
		methods:{
			...mapMutations(['setNotification', 'setModal']),
			...mapActions(['axiosRequest']),
			send(){
				if (!this.sending){
					this.sending = true;
                    this.$Progress.start();
					this.axiosRequest({ config : {
						method: 'PUT',
						url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/attendances`,
						data: { users : this.currentLesson.students },
						headers: { authorization : this.auth.token }
					}}).then( resp => resp.data )
					.then( data => {
						this.sending = false;
                        this.$Progress.finish();
						this.close();
						this.setNotification({ type: data.type, msg: data.message });
					})
					.catch( error => {
						this.sending = false;
                        this.$Progress.fail();
					} );
                }
			},
			afterClose(){
                this.setModal('');
            }
		}
	}
</script>