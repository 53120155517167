/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user2': {
    width: 14,
    height: 15,
    viewBox: '0 0 14 15',
    data: '<path pid="0" d="M7 8.571c1.61 0 3.178.452 4.415 1.273 1.393.924 2.28 2.24 2.564 3.808.065.36-.023.715-.242.975-.2.24-.5.377-.814.373H1.077a1.045 1.045 0 01-.814-.373 1.178 1.178 0 01-.242-.974c.284-1.568 1.17-2.885 2.564-3.81C3.822 9.024 5.39 8.572 7 8.572zM7 0c1.01 0 1.924.387 2.58 1.09.662.713.985 1.672.909 2.7C10.337 5.835 8.775 7.5 7 7.5S3.66 5.835 3.51 3.789c-.075-1.037.247-1.998.905-2.705C5.067.385 5.985 0 7 0z" _fill="#000" fill-rule="nonzero"/>'
  }
})
