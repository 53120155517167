var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalNotifications
    ? _c("div", { staticClass: "confirm-modal", class: { active: _vm.show } }, [
        _c("div", { staticClass: "blurColor" }),
        _vm._v(" "),
        _c("div", { staticClass: "v-content" }, [
          _c("h4", {
            staticClass: "title pt-4 pb-3 pl-5 pr-5 mb-0",
            class: `bgColor-${_vm.auth.permission}-main`,
            domProps: { innerHTML: _vm._s(_vm.$t("modals.notification.tHtml")) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center pt-3 pb-4 pl-4 pr-4" },
            [
              _c("svgicon", {
                staticClass: "icon mb-4 mt-3",
                attrs: { name: "altavoz-ondas", color: "#000" }
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "alert-legend w-100 mb-4",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("modals.notification.msgHtml"))
                }
              }),
              _vm._v(" "),
              _c("v-btn", {
                staticClass: "btn-accept mb-3 w-100",
                attrs: {
                  txt: _vm.$t("modals.notification.btn"),
                  btn: "main",
                  height: "44px"
                },
                nativeOn: {
                  click: function($event) {
                    _vm.getPermissions()
                    _vm.close()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-cancel",
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("modals.notification.btn2")))]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }