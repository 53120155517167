<template>
	<div class="v-file-prevew" :class="{ active : show }">
		<div class="blurColor" @click="close()"></div>

		<div class="v-preview-content" :style="{ maxWidth: paperSize.maxWidth, maxHeight: paperSize.maxHeight }">
			<div class="v-preview-header pl-4 pr-4">
				<div class="d-flex align-items-center justify-content-between h-100" ref="contentInfo">
					<div class="v-preview-info col" :style="{ maxWidth: infoW }">
						<svgicon class="v-info-icon" :name="options.icon" />
						<v-hover-txt fontSize="18px" fontWeight="500" :txt="options.name" />
						<span class="v-file-size d-block">{{ options.size }}</span>
					</div>
					<div class="v-preview-actions d-flex align-items-center" ref="actions">
						<div class="content-other-action pl-3 pr-3">
							<button class="btn-other" :style="{ '--hover-color': permission == 'tutor'?'#005E58':'#2188FC' }" @click="$emit('download', options.file)" v-if="!chatFile">
								<svgicon class="i-other" name="download3" />
								<div class="hover-txt">{{ $t('chat.download') }}</div>
							</button>
							<button class="btn-other cancel" :style="{ '--hover-color': permission == 'tutor'?'#005E58':'#2188FC' }" v-else>
								<div class="cp-spinner position-relative">
									<v-spinner height="100%" width="100%" />
									<svgicon class="i-other" name="download3" />
								</div>
								<!--<div class="hover-txt">{{ $t('chat.cancelDownload') }}</div>-->
							</button>
						</div>
						<div class="content-btn-close text-center pt-1 pb-1 pl-3">
							<button class="btn-close" @click="close()">
								<svgicon class="i-cross" name="cross2" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="v-preview d-flex justify-content-center align-items-center position-relative" :class="{ 'p-4': options.icon == 'file-video' || options.icon == 'file-audio' || options.icon == 'image' }" >
				<!-- ========= Spinner ======== -->
                <div class="content-spinner d-flex justify-content-center align-items-center" v-if="statusPreview == 'loading'">
                	<v-spinner />
                </div>

				<!-- ========= Google docs ======== -->
				<iframe ref="iframe" :src="`https://docs.google.com/viewer?url=${urlFile}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`"  style="border: none; width: 100%; height: 100%;" @load="load" frameborder="0" :key="refresh" v-if="options.icon == 'pdf' || options.icon == 'doc' || options.icon == 'slide' || options.icon == 'txt' || options.icon == 'sheet'"></iframe>
                
                <!-- ========= Video ======== -->
				<video controls v-else-if="options.icon == 'file-video' && statusPreview != 'error'" controlsList="nodownload">
					<source :src="urlFile" @load="load" @error="unload">
			    </video>
                
                <!-- ========= Audio ======== -->
			    <audio controls v-else-if="options.icon == 'file-audio' && statusPreview != 'error'" controlsList="nodownload" >
			    	<source :src="urlFile" @load="load" @error="unload">
			    </audio>
                
                <!-- ========= Image ======== -->
                <img :src="urlFile" :key="refresh" v-else-if="options.icon == 'image' && statusPreview != 'error'" @load="load" @error="unload">

                <!-- ========= Msg file not found ======== -->
                <p class="fileNotFound" v-else-if="statusPreview == 'error'">{{ $t('chat.fileNotFound') }}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapActions } from 'vuex';
	export default{
		mixins: [ modal ],
		props: {
			options: { type: Object, default: () => {}, required: true }
		},
		data(){
            return {
            	statusPreview: 'loading', // loading, load, error
            	refresh: 0,
            	infoW: 'initial'
            }
		},
		created(){
			setTimeout( () => {
                this.show = true;
			}, 400);
		},
		mounted(){
            this.infoW = `${this.$refs.contentInfo.offsetWidth - this.$refs.actions.offsetWidth}px`;
		},
		computed:{
            ...mapState({ 
        		    permission(state){ return state.auth.permission },
        	        universityId(state){ return state.auth.user.university_id },
        	        media(state){ return state.auth.media }, 
        		    chatFile(state){
        		    const file = state.chatFiles.inProgress[this.options.file];
        		        if (file)
        			        if (file.progress != 100)
        				        return file;
        			        else
        				        return undefined
        	        }
        	}),
            urlFile(){
                return `${this.media}/${this.universityId}/${this.options.idLesson}/${this.options.file}`
            },
            paperSize(){
            	const { icon } = this.options;
            	if (icon == 'pdf' || icon == 'doc' || icon == 'slide' || icon == 'txt' || icon == 'sheet')
            		return { maxWidth: '1200px', maxHeight: '900px' };
            	else if(icon == 'file-video')
            		return { maxWidth: '1000px', maxHeight: '700px' };
            	else if(icon == 'file-audio')
            		return { maxWidth: '500px', maxHeight: '200px' };
            	else if(icon == 'image')
            		return { maxWidth: '500px', maxHeight: '500px' };
            }
		}, 
		methods:{
			...mapActions(['axiosRequest']),
			afterClose(){
				this.$emit('close', this.options);
			},
			load(e){
				this.statusPreview = 'load';
            },
            unload(e){
            	this.statusPreview = 'error';
            }
		}
	}
</script>