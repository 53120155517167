var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "content", attrs: { id: "chat" } }, [
    _c("div", { staticClass: "row ml-n2 mr-n2 justify-content-between" }, [
      _c(
        "div",
        {
          staticClass: "col-12 pl-2 pr-2",
          style: {
            "--hover-color":
              _vm.auth.permission == "student" ? "#2188FC" : "#005E58"
          }
        },
        [
          _vm.statusBtnPrev
            ? _c(
                "button",
                {
                  ref: "btnBack",
                  staticClass: "vn-btn-prev d-flex align-items-center mb-3",
                  on: {
                    click: function($event) {
                      _vm.step = "chats"
                      _vm.$router.push({ name: `chat-${_vm.auth.permission}` })
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "vn-btn-icon mr-2",
                    attrs: { name: "arrow-left" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "vn-btn-txt" }, [
                    _vm._v(_vm._s(_vm.$t("notifications.btnBack")))
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.statusChats &&
      ((_vm.statusLesson !== "Finalizada" &&
        _vm.statusLesson !== "Cancelada") ||
        !_vm.$route.params.idlesson)
        ? _c(
            "div",
            {
              staticClass: "col-xl-3 col-12 order-md-0 contacts pl-2 pr-2",
              class: { "col-lg-4": _vm.menu.compact },
              style: { height: _vm.listChats }
            },
            [
              _c("v-list-chats", {
                staticClass: "h-100",
                attrs: { chats: _vm.lessons }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.statusDetail
        ? _c(
            "div",
            { staticClass: "chat col-lg order-xl-1 order-md-2 pl-2 pr-2" },
            [
              _vm.statusLessonInfoTop
                ? _c("v-chat-lesson2", {
                    key: `info-top-${_vm.currentLessonChat.id}`,
                    ref: "lessonInfo",
                    staticClass: "mb-2",
                    attrs: { lesson: _vm.currentLessonChat }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("v-chat", {
                key: _vm.userFirebase ? _vm.$route.params.idlesson : "",
                style: {
                  height: _vm.chatH,
                  maxHeight: _vm.statusH ? "700px" : null
                },
                attrs: {
                  idLesson: _vm.$route.params.idlesson || null,
                  disabled:
                    typeof _vm.$route.params.idlesson === "undefined" ||
                    _vm.statusLesson === "Finalizada" ||
                    _vm.statusLesson === "Cancelada",
                  users: _vm.chatUsers
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.statusLessonInfo
        ? _c(
            "div",
            {
              staticClass:
                "pending-request col-xl-4 order-xl-2 order-md-1 mb-3 pl-2 pr-2 d-none d-xl-block"
            },
            [
              _c("v-chat-lesson", {
                key: `info-right-${_vm.currentLessonChat.id}`,
                attrs: { lesson: _vm.currentLessonChat, users: _vm.chatUsers }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }