var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "confirm-modal", class: { active: _vm.show } },
    [
      _c("div", { staticClass: "blurColor" }),
      _vm._v(" "),
      _c("div", { staticClass: "v-content" }, [
        _c(
          "h4",
          {
            staticClass: "title pt-4 pb-3 pl-5 pr-5 mb-0",
            class: `bgColor-${_vm.permission}-main`
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("room.alertsOnboarding.meetingEnded.t")) +
                "\n\t    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center pt-3 pb-4 pl-4 pr-4" },
          [
            _c("svgicon", {
              staticClass: "icon mb-3",
              attrs: { name: "notification", color: "#F5A623" }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "alert-legend w-100 mb-4 mb-sm-5" }, [
              _vm._v(_vm._s(_vm.$t("room.alertsOnboarding.meetingEnded.d")))
            ]),
            _vm._v(" "),
            _c("v-btn", {
              staticClass: "btn-accept mb-3 w-100",
              attrs: {
                txt: _vm.$t("room.alertsOnboarding.meetingEnded.btnY"),
                btn: "main",
                height: "44px"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("resetMeeting")
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-cancel",
                on: {
                  click: function($event) {
                    return _vm.$emit("leave")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("room.alertsOnboarding.meetingEnded.btnN"))
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }