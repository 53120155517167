/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trello-3': {
    width: 273,
    height: 159,
    viewBox: '0 0 273 159',
    data: '<g _fill="none" fill-rule="evenodd"><g fill-rule="nonzero"><path pid="0" _fill="#F8F8F8" d="M50.835 7.658L64.02 0 0 3.08z"/><path pid="1" _fill="#ECECEC" d="M50.835 7.658l1.692 12.871-5.962-7.343L0 3.08z"/><path pid="2" _fill="#D3D3D3" d="M52.527 20.53l-8.575-2.633 2.613-4.71z"/><path pid="3" _fill="#F8F8F8" d="M46.565 13.186l-3.563 18.137L0 3.08z"/></g><g fill-rule="nonzero"><path pid="4" _fill="#F8F8F8" d="M172.977 40.708l6.395-6.642-36.723 12.463z"/><path pid="5" _fill="#ECECEC" d="M172.977 40.708l3.126 7.186-4.69-3.266-28.764 1.901z"/><path pid="6" _fill="#D3D3D3" d="M176.103 47.894l-5.426-.097.736-3.169z"/><path pid="7" _fill="#F8F8F8" d="M171.413 44.628l.946 11.118-29.71-9.217z"/></g><g fill-rule="nonzero"><path pid="8" _fill="#F8F8F8" d="M105.779 102.37l25.606-10.539L15.73 81.261z"/><path pid="9" _fill="#ECECEC" d="M105.54 102.346l-.254 23.8-8.804-14.835-80.818-30.075z"/><path pid="10" _fill="#D3D3D3" d="M104.993 125.741L89.797 118.9l5.988-7.58z"/><path pid="11" _fill="#F8F8F8" d="M96.57 111.927l-10.997 31.526-70.635-61.429z"/></g><path pid="12" d="M58.5 24c29.667 2 53.167 16 70.5 42 26 39 95.5 51.5 100 51.5" _stroke="#E6E6E6" stroke-width=".5" stroke-dasharray="3"/><path pid="13" d="M178.019 48.72c20.656 9.893 31.717 18.86 33.184 26.902 2.858 11.93-24.14 3.165-5.235-8.16 18.906-11.326 58.835 24.434 50.422 18.793" _stroke="#E6E6E6" stroke-width=".5" stroke-dasharray="1.2"/><path pid="14" d="M107 128c22.333 22.667 42.333 27.833 60 15.5 26.5-18.5 69-15 106 15" _stroke="#E6E6E6" stroke-width=".5" stroke-dasharray="3"/></g>'
  }
})
