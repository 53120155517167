var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    { staticClass: "v-list-chats" },
    [
      _vm.orderChats.length > 0
        ? _c(
            "transition-group",
            { staticClass: "w-100 pl-0", attrs: { name: "bound", tag: "ul" } },
            _vm._l(_vm.orderChats, function(lesson) {
              return _vm.msgs[lesson.id]
                ? _c(
                    "router-link",
                    {
                      key: `lesson-${lesson.id}`,
                      staticClass: "paper p-2",
                      class:
                        _vm.$route.params.idlesson == lesson.id
                          ? `bgColor-${_vm.userType}-main`
                          : null,
                      attrs: {
                        to: {
                          name: `chat-${_vm.userType}-id`,
                          params: { idlesson: lesson.id }
                        },
                        tag: "li"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "v-chat-info position-relative" },
                        [
                          _c("v-lesson-photo", {
                            staticClass: "ml-auto mr-auto position-absolute",
                            attrs: {
                              options: {
                                photo: lesson.photo,
                                lessonType: lesson.type,
                                size: "40px",
                                statusType:
                                  _vm.userType == "student" ||
                                  (_vm.userType == "tutor" &&
                                    lesson.type == "Individual"),
                                iconSize:
                                  _vm.userType == "student" ? "55%" : "60%"
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c("v-hover-txt", {
                                attrs: {
                                  colorTxt: "#000",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  txt: lesson.name
                                }
                              }),
                              _vm._v(" "),
                              _c("v-hover-txt", {
                                attrs: {
                                  colorTxt: "#252525",
                                  fontSize: "14px",
                                  fontWeight: "300",
                                  txt:
                                    _vm.userType == "tutor"
                                      ? lesson.student_name
                                      : lesson.tutor_name
                                }
                              }),
                              _vm._v(" "),
                              _c("v-hover-txt", {
                                attrs: {
                                  colorTxt: "#5C5C5C",
                                  fontSize: "12px",
                                  fontWeight: "300",
                                  txt: _vm.msgs[lesson.id].lastMessage
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "v-lc-r d-flex align-items-center" },
                            [
                              _c("div", { staticClass: "pr-1 text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "v-lc-time d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .$moment(_vm.msgs[lesson.id].date)
                                          .format("LT")
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                lesson.status == "Pendiente"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "v-lc-status color-lesson-pending d-block"
                                      },
                                      [_vm._v(_vm._s(_vm.$t("chat.pending")))]
                                    )
                                  : lesson.status == "Confirmada"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "v-lc-status color-lesson-upcoming d-block"
                                      },
                                      [_vm._v(_vm._s(_vm.$t("chat.upcoming")))]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "v-lc-status color-lesson-ongoing d-block d-fled align-items-center"
                                      },
                                      [
                                        _c("svgicon", {
                                          staticClass:
                                            "play-icon fill-lesson-ongoing",
                                          attrs: { name: "play2" }
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("chat.ongoing"))
                                        )
                                      ],
                                      1
                                    )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "v-last-msg",
                                  class: {
                                    hide: _vm.msgs[lesson.id].counter === 0
                                  }
                                },
                                [
                                  _c("span", { staticClass: "v-msg d-block" }, [
                                    _vm._v(_vm._s(_vm.msgs[lesson.id].counter))
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            }),
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "h-100 d-flex justify-content-center align-items-center"
            },
            [
              _c(
                "div",
                { staticClass: "list-empty w-100 pl-3 pr-3 text-center" },
                [
                  _c("p", { staticClass: "text-center mb-4" }, [
                    _vm._v(
                      _vm._s(
                        _vm.userType == "student"
                          ? _vm.$t("chat.listChatsEmpty.s")
                          : _vm.$t("chat.listChatsEmpty.t")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.userType == "student"
                    ? _c("v-btn", {
                        staticClass: "w-100",
                        attrs: {
                          height: "40px",
                          max: "190px",
                          fontWeight: "400",
                          txt: _vm.$t("mainMenu.schedule")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "schedule-student"
                            })
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }