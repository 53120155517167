var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "dashboard" } },
    [
      _c("v-search", {
        ref: "search",
        staticClass: "mb-4",
        attrs: { placeholder: "Search subject or tutor…" }
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "bound", mode: "out-in" } },
        [
          _vm.spinner && _vm.news.length === 0
            ? _c(
                "div",
                {
                  key: "spinner",
                  staticClass: "lesson-spinner w-100 p-4 text-center"
                },
                [_c("v-spinner")],
                1
              )
            : _c(
                "carousel",
                {
                  key: `news-${_vm.updateNews}`,
                  ref: "slickNews",
                  staticClass: "mb-4 dashboard-slick",
                  attrs: {
                    perPageCustom: [[0, 1]],
                    autoplay: true,
                    speed: 2000,
                    autoplayTimeout: 5000,
                    loop: true
                  }
                },
                _vm._l(_vm.news, function(item) {
                  return _c("slide", { key: item.id }, [
                    _c("a", { attrs: { href: item.link, target: "_blank" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "item d-flex align-items-center",
                          style: {
                            background: `url(${item.picture})center center / cover no-repeat`
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "bgColor",
                            style: {
                              background: `linear-gradient(to right, ${
                                item.color
                              }, ${_vm.$hexToRgba(
                                item.color,
                                0.9
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.7
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.5
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.3
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0,
                                1
                              )}, transparent)`
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column pt-4 pl-sm-5 pl-3 w-100 content"
                            },
                            [
                              _c("h3", { staticClass: "mb-0 w-100 title" }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mb-0 w-100 descrip" }, [
                                _vm._v(_vm._s(item.subtitle))
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                }),
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { ref: "lessons", staticClass: "s-lessons mb-4" },
        [
          _c(
            "div",
            {
              staticClass:
                "row ml-n2 mr-n2 align-items-center justify-content-between mr-0"
            },
            [
              _c("div", { staticClass: "col-auto pl-2 pr-2" }, [
                _c(
                  "h4",
                  { staticClass: "d-title mb-0 d-flex align-items-center" },
                  [
                    _c("div", { staticClass: "point bg-lesson-upcoming mr-1" }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("dashboard.upcoming")) +
                        "\n                        "
                    ),
                    _c("v-more-info", {
                      staticClass: "ml-2",
                      attrs: { txtCloud: _vm.$t("moreInfo.infoUpcomingS") }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col d-none d-sm-block pl-2 pr-4 text-right position-relative"
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn-lessons position-static color-student-main pr-0",
                      attrs: { to: { name: "lessons-student" } }
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("lessons.btnAll")) + " "),
                      _c("svgicon", {
                        staticClass: "icon fill-blue-main",
                        attrs: { name: "chevron-right" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bound", mode: "out-in" } },
            [
              _vm.spinner && _vm.upcomingLessons.length === 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : _vm.upcomingLessons.length === 0 && !_vm.spinner
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "img pl-3 pr-3",
                        attrs: { original: "", name: "dashboards1" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pl-3 pr-3 text-center text-sm-left mt-2",
                          style: { maxWidth: "300px" }
                        },
                        [
                          _c("p", {
                            staticClass: "txt mb-3",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msgEmpty.msgUpcoming"))
                            }
                          }),
                          _vm._v(" "),
                          _c("v-btn", {
                            attrs: {
                              txt: _vm.$t("msgEmpty.btn"),
                              min: "100%",
                              fontSize: "18px"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "schedule-student"
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "transition-group",
                    {
                      key: "lessons",
                      staticClass: "lessons",
                      attrs: { name: "bound", tag: "div" }
                    },
                    _vm._l(_vm.upcomingLessons, function(lesson, i) {
                      return _c("v-lesson", {
                        key: lesson.id,
                        staticClass: "col-12 mt-3",
                        attrs: { w: _vm.cardW, lesson: lesson }
                      })
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "latest-lessons mb-4" },
        [
          _c(
            "h4",
            { staticClass: "d-title mb-0 d-flex align-items-center" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("dashboard.latestLessons")) +
                  "\n                "
              ),
              _c("v-more-info", {
                staticClass: "ml-2",
                attrs: { txtCloud: _vm.$t("moreInfo.infoSLastesSubjects") }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bound", tag: "div", mode: "out-in" } },
            [
              _vm.spinner && _vm.subjects.length === 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : !_vm.spinner && _vm.subjects.length === 0
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "img pl-3 pr-3",
                        attrs: { original: "", name: "dashboards2" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pl-3 pr-3 text-center text-sm-left mt-2",
                          style: { maxWidth: "300px" }
                        },
                        [
                          _c("p", {
                            staticClass: "txt mb-3",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msgEmpty.msgLatest"))
                            }
                          }),
                          _vm._v(" "),
                          _c("v-btn", {
                            attrs: {
                              txt: _vm.$t("msgEmpty.btn"),
                              min: "100%",
                              fontSize: "18px"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "schedule-student"
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "carousel",
                    {
                      key: `lessons-${_vm.lessons.update}`,
                      ref: "slickSubjects",
                      staticClass: "mt-4 lessons",
                      attrs: {
                        perPageCustom: [
                          [0, 1],
                          [450, 2],
                          [700, 3],
                          [1200, 4]
                        ],
                        autoplay: true,
                        speed: 1000,
                        autoplayTimeout: 3500,
                        loop: true,
                        paginationEnabled: true,
                        paginationActiveColor: "#2188FC",
                        "mouse-drag": false
                      }
                    },
                    _vm._l(_vm.subjects, function(lesson) {
                      return _c(
                        "slide",
                        {
                          key: lesson.id,
                          staticClass: "item pl-3 pr-3",
                          nativeOn: {
                            click: function($event) {
                              _vm.setTxtBrowser(lesson.name)
                              _vm.$router.push({
                                name: "results-student2",
                                params: {
                                  subject: _vm.encodeParam(lesson.name)
                                }
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "lesson text-center p-4",
                              style: { background: lesson.color }
                            },
                            [
                              _c("div", { staticClass: "blurcolor" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "lesson-icon pt-3 pb-3 mb-3" },
                                [
                                  _c("svgicon", {
                                    staticClass: "icon",
                                    attrs: { name: lesson.icon }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-hover-txt", {
                                staticClass: "mb-2",
                                attrs: {
                                  fontSize: "22px",
                                  fontWeight: "200",
                                  colorTxt: "#fff",
                                  txt: lesson.name
                                }
                              }),
                              _vm._v(" "),
                              _c("v-hover-txt", {
                                attrs: {
                                  fontWeight: "500",
                                  colorTxt: "#fff",
                                  txt: lesson.tutor
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "latest-tutors mb-4" },
        [
          _c(
            "h4",
            { staticClass: "d-title mb-0 d-flex align-items-center" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("dashboard.latestTutors")) +
                  "\n                "
              ),
              _c("v-more-info", {
                staticClass: "ml-2",
                attrs: { txtCloud: _vm.$t("moreInfo.infoSLastesTutors") }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              staticClass: "row mr-0 ml-0",
              attrs: { name: "bound", tag: "div", mode: "out-in" }
            },
            [
              _vm.spinner && _vm.tutors.length === 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : !_vm.spinner && _vm.tutors.length === 0
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "basic-paper empty-lessons d-flex flex-column align-items-center pt-5 pb-4 pl-3 pr-3 mt-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "img mb-3 w-100",
                        attrs: { original: "", name: "tutors-empty1" }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "txt bold mb-1" }, [
                        _vm._v(_vm._s(_vm.$t("msgEmpty.msgTutor")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "txt2" }, [
                        _vm._v(_vm._s(_vm.$t("msgEmpty.msgTutor2")))
                      ])
                    ],
                    1
                  )
                : _c(
                    "carousel",
                    {
                      key: `tutors-${_vm.tutorsOpc.update}`,
                      ref: "slickTutors",
                      staticClass: "mt-4 tutors",
                      attrs: {
                        perPageCustom: [
                          [0, 1],
                          [450, 2],
                          [700, 3],
                          [1200, 4]
                        ],
                        autoplay: true,
                        speed: 1000,
                        autoplayTimeout: 3500,
                        loop: true,
                        paginationEnabled: true,
                        paginationActiveColor: "#2188FC",
                        "mouse-drag": false
                      }
                    },
                    _vm._l(_vm.tutors, function(tutor) {
                      return _c(
                        "slide",
                        {
                          key: tutor.id,
                          staticClass: "item pl-3 pr-3",
                          nativeOn: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "tutorProfile-student",
                                params: { tutor: tutor.id }
                              })
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "tutor" }, [
                            _c(
                              "div",
                              {
                                staticClass: "img position-relative",
                                style: {
                                  background:
                                    "url(" +
                                    tutor.photo +
                                    ")center center / cover no-repeat"
                                }
                              },
                              [_c("div", { staticClass: "blurcolor" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pl-3 pr-3 pt-2 pb-2 info" },
                              [
                                _c("v-hover-txt", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    txt: tutor.name
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-block w-100 matter mb-1" },
                                  [_vm._v(_vm._s(tutor.subject))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-block w-100 schedule" },
                                  [_vm._v(_vm._s(_vm.parceDate(tutor.date)))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              { staticClass: "btn-see" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dashboard.btnProfile")) + " "
                                ),
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "chevron-right" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }