import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import { vuexfireMutations } from 'vuexfire';

import auth from './auth';
import menu from './menu';
import general from './general';
import browser from './browser';
import lessons from './lessons';
import room from './room/index.ts';
import lang from './language';
import firebase from './firebase';
import availability from './availability';
import chat from './chat';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
        ...lang.state,
        ...auth.state,
        ...menu.state,
        ...browser.state,
        ...general.state,
        ...lessons.state,
        ...firebase.state,
        ...room.state,
        ...availability.state,
        ...chat.state
	},
	mutations: {
        ...lang.mutations,
        ...auth.mutations,
        ...menu.mutations,
        ...browser.mutations,
        ...general.mutations,
        ...lessons.mutations,
        ...room.mutations,
        ...firebase.mutations,
        ...vuexfireMutations,
        ...availability.mutations,
        ...chat.mutations
	},
	actions: {
        ...lang.actions,
        ...auth.actions,
        ...browser.actions,
        ...general.actions,
        ...lessons.actions,
        ...firebase.actions,
        ...room.actions,
        ...availability.actions,
        ...chat.actions
	},
    getters: {
        ...auth.getters,
        ...general.getters,
        ...firebase.getters,
        ...lessons.getters,
        ...room.getters
    }
});


Vue.use(vuexI18n.plugin, store);

Vue.i18n.add('en_US', lang.en_us);
Vue.i18n.add('es_MX', lang.es_mx);

export default store