import Vue from 'vue';

const beforeShowModal = ({ state, commit, dispatch }, payload) => {
    commit('setModal','');
    if (payload.action == 'v-modal-assistants'){
        dispatch('getAssistants',payload)
    }
    //else if(payload.action == 'v-join-onboarding'){
        /*if (state.isMovile){
            commit('setModal', 'v-modal-alert:movil' );
        }else if(!state.currentBrowser.OP_C){
            commit('setModal', 'v-modal-alert:browser' );
        }else{*/
            //commit('setCurrentLesson', payload);
            //commit('setModal', payload.action );
        //}
    //}
    else if(payload.action === 'v-modal-joinedStudents' || payload.action === 'v-modal-attendance'){
        dispatch('getJoinedStudents',payload)
    }
    else{ // v-modal-changePlace, v-modal-crud, v-modal-feedback
        commit('setCurrentLesson', payload);
        commit('setModal', payload.action );
    }
}

const getJoinedStudents = ({ commit, state, dispatch },payload) => {
    Vue.prototype.$Progress.start();
    dispatch('axiosRequest', { config : {
        method: 'GET',
        url: `${state.auth.api}/lessons/${payload.lesson.id}/participants`,
        headers: { authorization : state.auth.token }
    }}).then( resp => resp.data )
    .then( data => {
        commit('setCurrentLesson', { ...payload, students: data });
        commit('setModal', payload.action);
        Vue.prototype.$Progress.finish();
    })
    .catch( error => {
        Vue.prototype.$Progress.fail();
    } );
}

const getAssistants = ({ commit, state, dispatch },payload) => {
    Vue.prototype.$Progress.start();
    dispatch('axiosRequest', { config : {
        method: 'GET',
        url: `${state.auth.api}/lessons/${payload.lesson.id}/feedback`,
        headers: { authorization : state.auth.token }
    }}).then( resp => resp.data )
    .then( data => {
        commit('setCurrentLesson', { ...payload.lesson, students: data });
        commit('setModal', payload.action);
        Vue.prototype.$Progress.finish();
    })
    .catch( error => {
        Vue.prototype.$Progress.fail();
    } );
}

const getLessonById = ({ commit, state, dispatch }, payload) => {
    return new Promise( (resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'Get',
            url: `${state.auth.api}/lesson/${payload}`,
            headers: { authorization : state.auth.token }
        }}).then( resp => resp.data)
        .then( data => {
            resolve(data);
        }).catch( err => {
            reject(err);
        });
    })
}

const getLesson = ({ commit, state, dispatch }, payload) => {
    dispatch('axiosRequest', { config : {
        method: 'Get',
        url: `${state.auth.api}/lesson/${payload}`,
        headers: { authorization : state.auth.token }
    }}).then( resp => resp.data)
    .then( data => {
        dispatch('beforeShowModal', { lesson: data[0], action: 'v-modal-feedback' });
    }).catch( error => {

    });
}

export default{
    getJoinedStudents,
    getAssistants,
    getLessonById,
    getLesson,
    beforeShowModal
}