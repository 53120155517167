
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';

    @Component
    export default class identificationPdf extends Vue{
        @Prop({ default: () => { return {} } }) readonly data: any;

        @State( state => state.auth.awPhoto ) path;

        get photo(): string{
            const url = this.data.userPhoto.split('/');
            
            return `${(this.path)}${url[url.length-1]}`;
        }

        print(): void{
            (this as any).$refs.html2Pdf.generatePdf(); 
        }
    }
