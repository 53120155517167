/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unlock': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.5 1A4.505 4.505 0 009 5.5V8H1.5C.673 8 0 8.673 0 9.5v8c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5v-8c0-.827-.673-1.5-1.5-1.5H10V5.5C10 3.57 11.57 2 13.5 2S17 3.57 17 5.5v1a.5.5 0 001 0v-1C18 3.019 15.981 1 13.5 1zm-2 8a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h10z"/>'
  }
})
