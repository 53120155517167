/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.5 20h-13c-.827 0-1.5-.673-1.5-1.5v-16C2 1.673 2.673 1 3.5 1h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM3.5 2a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z"/><path pid="1" d="M12.5 5h-7a.5.5 0 010-1h7a.5.5 0 010 1zM14.5 7h-9a.5.5 0 010-1h9a.5.5 0 010 1zM14.5 9h-9a.5.5 0 010-1h9a.5.5 0 010 1zM10.5 11h-5a.5.5 0 010-1h5a.5.5 0 010 1zM14.5 15h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 17h-7a.5.5 0 010-1h7a.5.5 0 010 1z"/>'
  }
})
