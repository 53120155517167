<template>
	<div class="v-dropzone" :style="{ '--bg-color': auth.permission == 'tutor'?'#005E58':'#2188FC' }" v-show="show">
		<button class="v-dropzone-close d-flex align-items-center" @click="closeAction" :style="{ pointerEvents: statusCursor?'initial':'none' }">
			<svgicon class="icon-cross mr-2" name="cross2" :style="auth.permission == 'tutor'?'fill-green-main':'fill-blue-main'" />
			<span :style="`color-${auth.permission}-main`">{{ $t('chat.btnClose') }}</span>
		</button>
		<vue-dropzone class="h-100 w-100" 
		              ref="myVueDropzone" 
		              id="dropzone" 
		              :options="{ url: `${auth.api}/lesson/${idLesson}/chat/file`, 
		                          previewTemplate: templateHtml,
		                          headers: { Authorization : auth.token },
		                          ...opc }" 
		              :useCustomSlot="true"
		              @vdropzone-file-added="addFile"
		              @vdropzone-upload-progress="dropzoneUploadProgress" 
		              @vdropzone-success="fileComplete"
                      @vdropzone-error="fileCanceled"
		              @vdropzone-sending="fileSending"
		              @vdropzone-drag-over="statusCursor = false"
		              @vdropzone-drag-leave="statusCursor = true"
		              @vdropzone-drop="statusCursor = true"
		              @vdropzone-removed-file="fileRemove"
		              v-if="this.auth.api">
		    <div class="dropzone-custom-content">
		    	<div class="dropzone-custom-title">
		    		<h3 class="dz-title">{{ $t('chat.dragFiles') }}</h3>
		    	</div>
		    </div>
		</vue-dropzone>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default{	
        props:{
            idLesson: { default: null, required: true },
            show: { type: Boolean, default: false, required: false }
        },
		data(){
			return{
				statusCursor: true,
				dropzoneTotalFilesize: 0,
                dropzoneUploadedFilesize: 0,
                dropzoneCurrentUpload: 0,
                acceptedFiles : [
                    'pdf',       /* ===== PDF ===== */
                    'gif',       /* ===== Start Images ===== */
                    'heic',
                    'heif',
                    'jpeg',
                    'jpg',
                    'png',
                    'svg',
                    'tiff',      /* ===== End Images ===== */
                    'doc',       /* ===== Start Documents ===== */
                    'docm',
                    'docx',
                    'dot',
                    'dotm',
                    'dotx',
                    'odt',
                    'pages',     /* ===== End Documents ===== */
                    'key',   /* ===== Start Slides ===== */
                    'odp',
                    'pot',
                    'potm',
                    'potx',
                    'ppa',
                    'ppam',
                    'pps',
                    'ppsm',
                    'ppsx',
                    'ppt',
                    'pptm',
                    'pptx',      /* ===== End Slides ===== */
                    'csv',       /* ===== Start Sheets ===== */
                    'numbers',
                    'xla',
                    'xlam',
                    'xls',
                    'xlsb',
                    'xlsm',
                    'xlsx',
                    'xlt',
                    'xltm',
                    'xltx',      /* ===== End Sheets ===== */
                    '7z',        /* ===== Start Compressed ===== */
                    'zip',  
                    'bz',
                    'bz2',
                    'gz',
                    'gzip',
                    'rar',
                    'tar',
                    'tgz',
                    'zip',       /* ===== End Compressed ===== */
                    'rtf',       /* ===== Start Textfiles ===== */
                    'txt',       /* ===== End Textfiles ===== */
                    '3g2',       /* ===== Start Videos ===== */
                    '3gp',
                    'avi',
                    'flv',
                    'm3u8',
                    'mov',
                    'mp4',
                    'mpeg',
                    'ogv',
                    'ts',
                    'webm',
                    'wmv',       /* ===== End Videos ===== */   
                    'aac',       /* ===== Start Audios ===== */
                    'm4a',  
                    'mp3',
                    'ogg',
                    'opus'       /* ===== End Audios ===== */    
                ],
				opc: {
					method: 'post',
					paramName: 'file',
					clickable: true,
					//maxFiles: 8,
					maxFilesize: 10,
					parallelUploads: 1,
					uploadMultiple: false,
					addRemoveLinks: true,
					dictRemoveFile: '',
					dictCancelUpload: '',
					accept: (file, done) => {
						const ext = file.name.split('.').pop().toLowerCase();
						if( this.acceptedFiles.find( i => i == ext ) )
							done();
						else
							done(this.$refs.myVueDropzone.dropzone.options.dictInvalidFileType);
					}
				}
			}
		},
		computed: {
			...mapState(['auth', 'i18n']),
			templateHtml(){
				return `<div class="dz-preview dz-file-preview">
		                    <div class="dz-image">
                                <img data-dz-thumbnail />
                            </div>
                            <div class="dz-details bgColor-${this.auth.permission}-light">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                            </div>
                            <div class="dz-details dz-error-message bgColor-${this.auth.permission}-light">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                                <span class="dz-error" data-dz-errormessage></span>
                                <div class="dz-error-message"></div>
                            </div>
                            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                            <div class="dz-type"><span data-dz-type></span></div>
		                </div>`;
			}
		},
        mounted(){
            this.$refs.myVueDropzone.dropzone.options.dictFileTooBig = this.$t('chat.fileTooBig');
            this.$refs.myVueDropzone.dropzone.options.dictInvalidFileType = this.$t('chat.unsupportedFileType');
        },
		methods:{
			loadFile(){
				this.$refs.myVueDropzone.$el.click();
			},
			addFile(file){ // Active dropZone
				this.dropzoneTotalFilesize += file.size;
				this.$emit('handleDropzone', true);
			},
			dropzoneUploadProgress(file, totalBytes, totalBytesSent){
                this.dropzoneCurrentUpload = totalBytesSent; // write totalBytes to dropzoneCurrentUpload
                if(file.size <= totalBytesSent){
                    this.dropzoneCurrentUpload = 0; // reset current upload bytes counter
                    this.dropzoneUploadedFilesize += totalBytesSent; // add finished file to total upload
                }
            },  
			fileComplete(file, resp){
				if(resp.statusCode == 200){
				    this.$emit('saveFile', resp.files[0]);
				    this.dropzoneTotalFilesize -= file.size;
				    this.$refs.myVueDropzone.removeFile(file);
				}
			},
            fileCanceled(file, err){
                if (err.error.statusCode == 415)
                    file.previewTemplate.querySelector('.dz-error-message .dz-error').textContent = this.$t('chat.unsupportedFileType');
                else if (err.error.statusCode == 413)
                    file.previewTemplate.querySelector('.dz-error-message .dz-error').textContent = this.$t('chat.fileTooBig'); 
            },
			fileRemove(){
                if (this.$refs.myVueDropzone.getUploadingFiles() + this.$refs.myVueDropzone.getRejectedFiles() + this.$refs.myVueDropzone.getAcceptedFiles() == 0)
				    this.$emit('handleDropzone', false);
			},
			fileSending(file, xhr, formData){ // add extra datas to formData
				formData.append("text", file.name);
				formData.append("size", file.size);
			},
			closeAction(){
				this.$emit('handleDropzone', false);
				this.dropzoneTotalFilesize = 0;
                this.$refs.myVueDropzone.removeAllFiles();
			},
            onKeyPress(e){ // Close modal on key esc press
                if ("key" in e) {
                    if (e.key === "Escape" || e.key === "Esc") {
                        this.closeAction();
                    }
                }else if(e.keyCode === 27){
                    this.closeAction();
                }
            }
		},
        watch:{
            'i18n.locale': function(){
                const errorMsgs = this.$refs.myVueDropzone.dropzone.options;
                this.$refs.myVueDropzone.getRejectedFiles().forEach( (item) => {
                    const element = item.previewTemplate.querySelector('.dz-error-message .dz-error');
                    if (errorMsgs.dictFileTooBig == element.textContent)
                        element.textContent = this.$t('chat.fileTooBig');
                    else if(errorMsgs.dictInvalidFileType == element.textContent)
                        element.textContent = this.$t('chat.unsupportedFileType');
                } )
                this.$refs.myVueDropzone.dropzone.options.dictFileTooBig = this.$t('chat.fileTooBig');
                this.$refs.myVueDropzone.dropzone.options.dictInvalidFileType = this.$t('chat.unsupportedFileType');
            },
            show: function(newVal){
                if (newVal)
                    document.addEventListener('keydown', this.onKeyPress);
                else
                    document.removeEventListener('keydown', this.onKeyPress);
            }
        }
	}
</script>