<template>
	<div :id="'circles'+vCircle" :style="{ width: size, height: size }">
	    <div class="content-circle" v-for="n in 4">
			<div class="circle" :class="bgColor==='main'?`bgColor-${auth.permission==''?'student':auth.permission}-${circles[vCircle-1].color[n-1]}`:`bgColor-yellow-[vCircle-1].color[n-1]`"></div>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props: { 
			vCircle: { type: Number, default: 0,  required: true },
			size: { type: String, default: '100px', required: true },
			bgColor: { type: String, default: 'main', required: false }
		},
		data(){
			return{
				circles:[
				{ 
					n: 4,
					color: [ 'main', 'light', 'light', 'main' ]
				},
				{
					n: 3,
					color: [ 'main', 'light', 'light' ]
				},
				{
					n: 4,
					color: [ 'main', 'light', 'light', 'main' ]
				},
				{
					n: 3,
					color: [ 'main', 'light', 'light' ]
				}
				]
			}
		},
        computed:{
        	...mapState(['auth']),
        }
    }
</script>