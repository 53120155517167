  
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	enum ToolbarActions{
		blur,
        focus,
        hover,
        click
	}

	enum SubMenus{
		default,
        help
	}

	@Component
	export default class Toolbar extends Vue{
		/* ========== Props ========== */
        @Prop({ type: Boolean, default: false }) readonly compact!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusCam!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusMic!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusScreenShare!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusFullScreen!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusMicAlert!: boolean;
        @Prop({ type: Object }) readonly lesson!: object | any | null

        /* ============ Datas ============0 */
        statusSessionDetails: ToolbarActions = ToolbarActions.blur;
        statusMovilActions: boolean = false;
        btnShowMore: ToolbarActions = ToolbarActions.blur;
        submenu: SubMenus = SubMenus.default;
        private toolbarActions = ToolbarActions; // Enum
        private subMenus = SubMenus; // Enum

        /* ========= State (vuex) ========= */
        @State( state => state.auth.permission ) statePermission;
        @State( state => state.i18n.locale ) lang
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour

        /* ========== Computed ========= */
        get refs(){
        	return (this as any).$refs;
        }
        get t(){
        	return (this as any).$t;
        }
        get moment(){
        	return (this as any).$moment;
        }
        get type(): string | undefined{
        	return this.lesson.type == 'Grupal'?this.$t('room.sessionDetails.groupType'):this.$t('room.sessionDetails.individualType');
        }
        get date(): string{
        	return this.moment.utc(this.lesson.scheduled_date).locale(this.lang).format('LL');
        }
        get time(): string{
            return `${this.moment(this.lesson.scheduledTime,"HH:mm:ss").format('LT')} - ${this.moment(this.lesson.endScheduledTime,"HH:mm:ss").format('LT')}`;
        }
        get isntMovil(){
        	return this.defaultBrowserBehaviour?.requiresCheckForSdpConnectionAttributes() && !this.defaultBrowserBehaviour?.isAndroid() && !this.defaultBrowserBehaviour?.isSamsungInternet();
        }

        /* =========== Methods =========== */
        toggleAction(btn: string): void{
            if (this.btnShowMore == this.toolbarActions.blur || this.statusSessionDetails == this.toolbarActions.blur)
            	this.focusAction(btn);
        }
        focusAction(btn: string): void{
        	if ((btn == 'btn-more' && this.btnShowMore == this.toolbarActions.blur || this.btnShowMore == this.toolbarActions.focus)
        		|| (btn == 'session-details' && this.statusSessionDetails == this.toolbarActions.blur || this.statusSessionDetails == this.toolbarActions.focus))
        		this.refs[btn].focus();
        }
        blurAction(btn: string){
        	if (btn == 'btn-more' && this.btnShowMore != this.toolbarActions.hover){
        		this.btnShowMore = this.toolbarActions.blur;
        		this.submenu = this.subMenus.default;
        	}else if (btn == 'session-details' && this.statusSessionDetails != this.toolbarActions.hover) {
        		this.statusSessionDetails = this.toolbarActions.blur;
        	}
        }
        hoverAction(btn: string): void{
        	if (btn == 'btn-more' && this.btnShowMore == this.toolbarActions.focus)
        		this.btnShowMore = this.toolbarActions.hover;
        	else if (btn == 'btn-more' && this.statusSessionDetails == this.toolbarActions.focus)
        		this.statusSessionDetails = this.toolbarActions.hover;
        }
        leaveAction(btn: string): void{
        	if (btn == 'btn-more' && this.btnShowMore == this.toolbarActions.hover) {
        		this.btnShowMore = this.toolbarActions.focus; 
        		this.focusAction(btn);
        	}else if (btn == 'session-details' && this.statusSessionDetails == this.toolbarActions.hover) {
        		this.statusSessionDetails = this.toolbarActions.focus; 
        		this.focusAction(btn);
        	}
        }
        btnHelp(btn: string){
        	this.btnShowMore = this.toolbarActions.click; 
        	this.blurAction('btn-more');
            if (btn == 'docs')
            	window.open(this.t('header.helpSub.docs.link'),'_blank');
            else if (btn == 'support')
            	window.open(this.t('header.helpSub.online.link'),'_blank');
            else if (btn == 'email')
            	window.open(this.t('header.helpSub.email.link'),'_blank');
        }

        handleMic(): void{
        	if (this.refs.alertMic)
        		this.refs.alertMic.breakCloud();
        	this.$emit('handleMic');
        }
	}
