/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tape': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8 7c-1.001 0-1.95-.17-2.672-.48C4.172 6.025 4 5.355 4 5s.173-1.025 1.328-1.52C6.05 3.171 6.999 3 8 3s1.95.17 2.672.48C11.828 3.975 12 4.645 12 5s-.173 1.025-1.328 1.52C9.95 6.829 9.001 7 8 7zm0-3c-1.861 0-3 .647-3 1s1.139 1 3 1 3-.647 3-1-1.139-1-3-1z"/><path pid="1" d="M19.859 8.253c-.067-.07-1.59-1.625-3.933-2.561.049-.227.074-.457.074-.691 0-1.385-.866-2.667-2.439-3.611C12.068.494 10.093.001 8 .001S3.933.494 2.439 1.39C.866 2.334 0 3.616 0 5.001s.866 2.667 2.439 3.611c.18.108.367.21.561.306v2.167c-.847.421-1.557.952-2.064 1.548C.324 13.352 0 14.171 0 15.001c0 1.385.866 2.667 2.439 3.611 1.493.896 3.468 1.389 5.561 1.389 1.896 0 3.731-.415 5.168-1.169 1.199-.629 2.065-1.463 2.504-2.397.357.272.726.581 1.107.926 1.332 1.209 2.321 2.439 2.331 2.451a.501.501 0 00.556.16A.5.5 0 0020 19.5V8.6c0-.13-.05-.254-.141-.348zM1 5c0-1.019.694-1.997 1.954-2.753C4.294 1.443 6.086 1 8 1s3.706.443 5.046 1.247C14.306 3.003 15 3.981 15 5c0 .28-.053.557-.155.826l-.002.005-.001.003c-.271.71-.886 1.372-1.796 1.919a7.381 7.381 0 01-.735.384l-.027.012c-1.224.552-2.713.85-4.285.85-1.57 0-3.057-.298-4.281-.849l-.032-.015a7.364 7.364 0 01-.734-.384C1.692 6.995.998 6.017.998 4.998zm7 5c1.427 0 2.798-.229 4-.659V14.5c0 .311-.391.677-1.021.957-.787.35-1.845.543-2.979.543s-2.192-.193-2.979-.543C4.391 15.177 4 14.81 4 14.5V9.341c1.202.43 2.573.659 4 .659zm4.703 7.946C11.407 18.626 9.737 19 8 19c-1.914 0-3.706-.443-5.046-1.247C1.694 16.997 1 16.019 1 15c0-1.027.735-2.032 2-2.78v2.28c0 .744.574 1.408 1.615 1.871C5.527 16.777 6.73 17 8 17s2.473-.223 3.385-.629c.785-.349 1.304-.812 1.512-1.338.568.09 1.22.366 1.938.818-.311.795-1.055 1.529-2.132 2.095zm6.297.218a24.747 24.747 0 00-1.539-1.534c-1.692-1.539-3.19-2.408-4.461-2.592V8.917c.193-.096.381-.198.561-.306.931-.558 1.614-1.235 2.017-1.982 1.744.704 3.014 1.802 3.423 2.183v9.352z"/>'
  }
})
