
    import { Component, Vue, Prop } from 'vue-property-decorator';

    enum Char{
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K'
    }

    @Component
    export default class identificationPdf extends Vue{
        @Prop({ default: () => { return {} } }) readonly data: any;

        char: any = Char;

        print(): void{
            (this as any).$refs.html2Pdf.generatePdf(); 
        }
    }
