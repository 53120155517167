import Vue from 'vue';
import router from '../../router.js';
import { configureScope } from '@sentry/browser';

const login = ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'POST', 
            url: `${state.auth.api}/login`,
            data: { ...payload, institution_id: state.auth.institution.id },
            headers: { 'Content-Type' : 'application/json' }
        }, error401: false }).then( resp => resp.data)
        .then( async resp => {
            Vue.localStorage.set('tokenFirebase',resp.tokenFirebase);
            dispatch('getUser',resp.id).then( resp => {
                resolve(resp);
            } ).catch( error => {
                reject(error);
            } );
        } )
        .catch( error => {
            reject(error);
            commit('setNotification',{ type: 'error', msg: error.response.data.error.message });
        } )
    })
}

const logOut = ({ commit, state, dispatch }, payload) => {
    dispatch('axiosRequest', { config : {
            method: 'POST', 
            url: `${state.auth.api}/logout`,
            data: { uid: state.tokenBrowser, token: state.auth.token }
    } })
    .then(resp => {
        commit('setContToken', 5);
        Vue.localStorage.remove('tokenLinkWebApp');
        Vue.localStorage.remove('tokenBrowser');
        Vue.localStorage.remove('tokenPushNotification');
        Vue.localStorage.remove('statusModalNotifications');
        dispatch('endNotifications');
        commit('setTokenNotifications', null);
        commit('setTokenBrowser', null);
        dispatch('stopEvents');
        commit('logOut');
        dispatch('signOutFirebase');
        router.push({ name : 'email-validatation' }); 
    }).catch( error => { } )
}

const getUser = ({ commit, state, dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'GET',
            url: `${state.auth.api}/user`,
            headers: { Authorization : payload }
        }, error401: false}).then( resp => resp.data.data.data )
          .then( async dataUser => {
            let permission = Vue.localStorage.get('permissionLinkWebApp');

            let url = null;
            if (router.history.current.query.next)
                url = decodeURIComponent(router.history.current.query.next).split('/')[1];

            if (permission == 'tutor' && dataUser.user.is_tutor==0){
                Vue.localStorage.remove('permissionLinkWebApp');
                permission = 'student';
            }
            else if(!permission && !url)
                permission = dataUser.user.is_tutor==1?'tutor':'student';
            else if(!permission && url)
                permission = dataUser.user.is_tutor==1 && (url == 'student' || url == 'tutor')?url:(dataUser.user.is_tutor==1?'tutor':'student');
            else
                permission = dataUser.user.is_tutor==1 && url != permission && (url == 'student' || url == 'tutor')?url:permission;
                
            Vue.localStorage.set('permissionLinkWebApp', permission);

            commit('handleCampusType', dataUser.user.campustType);

            if (process.env.NODE_ENV === 'production'){
                configureScope( scope =>  {
                    scope.setUser({"email": dataUser.user.email});
                });
            }

            if (dataUser.user.confirmed === 'SI'){
                Vue.localStorage.set('tokenLinkWebApp', payload);
                commit('setToken',payload);
                console.log(getters.permissionFormats);
                commit('setPermission',permission);
                if(getters.permissionFormats)
                    await dispatch('getFormsStatus');
                commit('setUser',{ ...dataUser.user, preferences : dataUser.preferences });
                commit('setTokenBrowser', Vue.localStorage.get('tokenBrowser'));
                if (state.i18n.locale !== dataUser.preferences.web_lang){
                    dispatch('saveLang', state.i18n.locale);
                }
                if (Vue.localStorage.get('tokenFirebase')){
                    dispatch('loginFirebase').then( resp => {
                        if (getters.events) {
                            const lesson = getters.events.split('_');
                            if (lesson[1] === 'pendingAccept' && state.auth.permission === 'student' ) {
                                dispatch('beforeShowModal',  { lesson: { id: lesson[0] }, action: 'v-modal-crud:confirmLesson' });
                            }
                        }
                        if ('serviceWorker' in navigator && (Vue.prototype.$firebase.messaging?Notification.permission === 'default':false) && Vue.localStorage.get('statusModalNotifications')+'' !== '1'){ // Modal browser notifications
                            commit('setModalNotifications', true);
                        }
                    })
                }
                resolve(permission);      
            }else{
                commit('setPath','confirm-email');
                resolve('confirm-email')
            }
        } ).catch( error => {
            reject(error);
        } )
    })
}

const getFormsStatus = async ({ dispatch, commit, state }) => {
    const formats = await dispatch('axiosRequest', { config: {
        method: 'GET',
        url: `${state.auth.api}/tecnm/forms/status`,
        headers: { Authorization : state.auth.token }
    }});

    if(formats.data?.forms){
        commit('setStatusFormats', formats.data?.forms);
    }
}

const changePermission = ({ commit,state }, payload) => {
    Vue.localStorage.remove('permissionLinkWebApp');
    Vue.localStorage.set('permissionLinkWebApp', payload);
    commit('setPermission',payload);
}

const changeStatusCalendar = ({ commit,state, dispatch }, payload) => {
    Vue.prototype.$Progress.start();
    dispatch('axiosRequest', { config : {
        method: 'PATCH',
        url: `${state.auth.api}/calendar`,
        data: payload,
        headers: { Authorization : state.auth.token }
    }}).then( resp => resp.data )
        .then( data => {
        commit('setStatusCalendar',data.data.disabled);
        commit('setNotification',{ type: data.type, msg: data.message });
        Vue.prototype.$Progress.finish();
    } ).catch( error => {
        Vue.prototype.$Progress.fail();
    } )
}

const changePassword = ({ commit, state, dispatch }, payload) => {
    Vue.prototype.$Progress.start();
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'PATCH',
            url: `${state.auth.api}/user/changePassword`,
            data: payload,
            headers: { Authorization : state.auth.token }
        }}).then( resp => resp.data )
            .then( data => {
            Vue.localStorage.remove('tokenLinkWebApp');
            Vue.localStorage.set('tokenLinkWebApp', data.data.accessToken.id);
            commit('setToken',data.data.accessToken.id);
            commit('setNotification',{ type: data.type, msg: data.message });
            Vue.prototype.$Progress.finish();
            resolve(true);
        } ).catch( error => {
            Vue.prototype.$Progress.fail();
            commit('setNotification',{ type: 'error', msg: error.response.data.error.message });
            reject(false);
        } )
    })
}

const changeUserData = ({ commit,state, dispatch }, payload) => {
    Vue.prototype.$Progress.start();
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'PATCH',
            url: `${state.auth.api}/user/update`,
            data: payload.form,
            headers: { Authorization : state.auth.token }
        }}).then( resp => resp.data )
        .then( data => {
            commit('setUserData',payload.form);
            commit('setNotification',{ type: data.type, msg: data.message });
            resolve();
            Vue.prototype.$Progress.finish();
        } ).catch( error => {
            Vue.prototype.$Progress.fail();
            reject();
        } )
    })
}

const changeUserPhoto = ({ commit, state, dispatch }, payload) => {
    Vue.prototype.$Progress.start();
    console.log(payload);
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'POST',
            url: `${state.auth.api}/user/photo`,
            data: payload,
            headers: { Authorization : state.auth.token, 'Content-Type' : 'multipart/form-data; boundary=--------------------------396867289176364844103316' }
        }}).then( resp => resp.data )
        .then( data => {
            commit('setUserPhoto',data.data.photo);
            commit('setNotification',{ type: data.type, msg: data.message });
            resolve(true);
            Vue.prototype.$Progress.finish();
        } ).catch( error => {
            Vue.prototype.$Progress.fail();
            reject(false);
        } )
    })
}

const saveUser = ({ commit, state, dispatch },payload) => {
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'POST',
            url: `${state.auth.api}/user/save`,
            data: { ...payload, institution_id: state.auth.institution.id },
            headers: { 'Content-Type' : 'application/json' }
        }, error401: false }).then( resp => resp.data)
          .then( data => {
            commit('setPath','confirm-email');
            Vue.prototype.$firebase.analytics.logEvent('sign_up', { name: `${payload.name} ${payload.last_name}`, email: payload.email, university_id: payload.university_id, career_id: payload.career_id, period_id: payload.period_id });
            resolve('confirm-email');
        }).catch( error => {
            reject();
        })
    })
}

const confirmEmail = ({ commit, state, dispatch },payload) => {
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'GET',
            url: `${state.auth.api}/user/confirm/${payload}`,
            headers: { 'Content-Type' : 'application/json' }
        }, error401: false }).then( resp => resp.data)
          .then( data => {
            commit('setPath','Login');
            commit('setUser',data);
            resolve();
        }).catch( error => {
            reject();
        })
    })
}

const confirmPassword = ({ commit, state, dispatch },payload) => {
    return new Promise((resolve, reject) => {
        dispatch('axiosRequest', { config : {
            method: 'GET',
            url: `${state.auth.api}/validate/password/${payload}`,
            headers: { 'Content-Type' : 'application/json' }
        }, error401: false }).then( resp => resp.data)
          .then( data => {
            resolve(data);
        }).catch( error => {
            reject();
        })
    })
}

const lessonsFeedback = ({ state, commit, dispatch }) =>{
    dispatch('axiosRequest', { config : {
        method: 'GET',
        url: `${state.auth.api}/user/lessons/unrated/${state.auth.permission === 'tutor'?'t':'s'}`,
        headers: { Authorization : state.auth.token }
    }}).then( resp => resp.data)
    .then( data => {
        const feedback = [ ...data.group, ...data.individual ];
        commit('setpendingFeedback', feedback);
        commit('setcommentFeedback', data.required);
        commit('setpendingConfirmation', data.pending);
        commit('handleServerTime', data.serverTime);
        commit('setActiveLessons', data.active);
        if (data.pending.length > 0){
            dispatch('beforeShowModal',  { lesson: { id: data.pending[0].id }, action: 'v-modal-crud:confirmLesson' });
        }
        else if (feedback.length > 0)
            dispatch('getLesson', feedback[0].id);
    })          
}

const saveLang = ({state, commit, dispatch}, payload) => {
    dispatch('axiosRequest', { config : {
        method: 'PATCH',
        url: `${state.auth.api}/user/language/update`,
        headers: { Authorization : state.auth.token }
    }}).then( resp => resp.data)
    .then( data => {
        commit('changeUserLang', payload);
    })          
}

export default{
    login,
    logOut,
    getUser,
    getFormsStatus,
    changePermission,
    changeStatusCalendar,
    changePassword,
    changeUserData,
    changeUserPhoto,
    saveUser,
    confirmEmail,
    confirmPassword,
    lessonsFeedback,
    saveLang
}
