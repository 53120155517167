/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telephone2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.894 8.897c-1.041 0-2.928-.375-3.516-.963-.361-.361-.446-.813-.515-1.177-.085-.448-.136-.581-.332-.666-.902-.388-2.196-.61-3.551-.61-1.34 0-2.62.219-3.512.6-.194.083-.244.216-.327.663-.068.365-.152.819-.512 1.179-.328.328-1.015.554-1.533.685a8.43 8.43 0 01-1.963.267c-.664 0-1.113-.126-1.372-.386a2.383 2.383 0 01-.685-1.467C.039 6.566.127 5.89.756 5.26c1.022-1.022 2.396-1.819 4.086-2.368 1.554-.506 3.322-.773 5.114-.773 1.804 0 3.587.27 5.156.782 1.705.556 3.093 1.361 4.124 2.393 1.05 1.05.79 2.443.012 3.221-.257.257-.7.382-1.354.382zM9.98 4.481c1.507 0 2.908.246 3.946.691.713.306.833.938.92 1.398.052.275.097.513.24.656.252.252 1.706.671 2.809.671.481 0 .633-.082.652-.094.31-.314.698-1.086-.017-1.802-1.805-1.805-5.01-2.882-8.574-2.882-3.535 0-6.709 1.065-8.493 2.848-.288.288-.42.616-.391.974.025.302.17.614.39.836.019.012.173.098.67.098 1.098 0 2.541-.411 2.789-.659.141-.141.185-.379.236-.654.086-.462.203-1.095.917-1.4 1.026-.439 2.413-.68 3.905-.68z"/><path pid="1" d="M16.5 18h-13a2.373 2.373 0 01-2.388-2.525c.004-.077.118-1.901 1.27-3.739a7.5 7.5 0 012.686-2.577C6.429 8.39 8.088 8 10 8s3.571.39 4.932 1.159a7.483 7.483 0 012.686 2.577c1.152 1.839 1.266 3.663 1.27 3.739A2.376 2.376 0 0116.5 18zM10 9c-3.117 0-5.388 1.088-6.749 3.233-1.03 1.623-1.139 3.282-1.14 3.299-.022.392.111.761.373 1.038s.623.43 1.017.43h13c.393 0 .754-.153 1.017-.43s.395-.646.373-1.039c-.001-.016-.111-1.675-1.14-3.298C15.389 10.088 13.118 9 10.002 9z"/><path pid="2" d="M10 16c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>'
  }
})
