import lessonPhoto from './lesson-photo.vue';
import lessonTag from './lesson-tag.vue';
import lessonTopic from './lesson-topic.vue';
import lessonSchedule from './lesson-schedule.vue';
import lessonPlace from './lesson-place.vue';
import lessonBtn from './lesson-btn.vue';
import lesson from './card-lesson.vue';
import ongoingLesson from './card-ongoing-lesson.vue';
import lessonTime from './lesson-time.vue';
import lessonHistory from './lesson-history.vue';
import modalOngoingLesson from './ongoing-lesson.vue';

const components = (Vue) => {
	// General
	Vue.component('v-lesson-photo', lessonPhoto);
	Vue.component('v-lesson-tag', lessonTag);
	Vue.component('v-lesson-topic', lessonTopic);
	Vue.component('v-lesson-schedule', lessonSchedule);
	Vue.component('v-lesson-place', lessonPlace);
	Vue.component('v-lesson-btn', lessonBtn)
    Vue.component('v-lesson', lesson);
    Vue.component('v-ongoing-lesson', ongoingLesson);
    Vue.component('v-lesson-time', lessonTime);
    Vue.component('v-lesson-history', lessonHistory);
    Vue.component('v-modal-ongoing-lesson', modalOngoingLesson);
}

export default components