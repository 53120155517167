/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M5.5 20a.5.5 0 01-.457-.703L8.286 12H3.5a.5.5 0 01-.354-.854l10-10a.501.501 0 01.811.556l-3.243 7.297H15.5a.5.5 0 01.354.854l-10 10a.499.499 0 01-.354.146zm-.793-9h4.348a.5.5 0 01.457.704L7.05 17.243l7.242-7.242H9.944a.5.5 0 01-.457-.704l2.462-5.539L4.707 11z"/>'
  }
})
