const setLessonChanges = (state,payload) => {
	state.lessonChanges = payload;
}

const setCurrentLesson = (state,payload) => {
	state.currentLesson = payload;
}

const setModal = (state, payload) => {
    state.showModal = payload;
}

const setStatusOngoingLesson = (state,payload) => {
    state.statusOngoingLesson = payload;
}

const handleServerTime = (state,time) => {
    state.serverTime = time;
}

export default{
	setLessonChanges,
	setCurrentLesson,
	setModal,
	setStatusOngoingLesson,
	handleServerTime
}