var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "room" }, on: { mousemove: _vm.mousemove } },
    [
      _c(
        "fullscreen",
        {
          ref: "fullscreen",
          staticClass: "d-flex flex-wrap h-100",
          on: {
            change: function($event) {
              return _vm.fullscreenChange()
            }
          }
        },
        [
          _vm.roomStep == _vm.Step.spinner
            ? _c(
                "div",
                {
                  staticClass:
                    "col-12 d-flex justify-content-center align-items-center"
                },
                [_c("v-spinner")],
                1
              )
            : _vm.roomStep == _vm.Step.promptPermissions
            ? _c(
                "div",
                {
                  staticClass:
                    "msg-promptError col-12 d-flex justify-content-center align-items-center"
                },
                [
                  _c("div", { staticClass: "text-center" }, [
                    _c("h4", { staticClass: "title mb-3" }, [
                      _vm._v(_vm._s(_vm.$t("room.permissions.alert.t")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "msg mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("room.permissions.alert.msg")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          href: _vm.$t("room.permissions.alert.aLink"),
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("room.permissions.alert.aTxt")))]
                    )
                  ])
                ]
              )
            : _vm.roomStep == _vm.Step.startRoom
            ? _c("v-grid-room", {
                ref: "gridRoom",
                staticClass: "col pl-0 pr-0",
                attrs: {
                  statusChat: _vm.statusChat,
                  currentScreenShare: _vm.currentRosterScreenShare,
                  totalUsers: _vm.totalUsers,
                  lesson: _vm.lesson,
                  pin: _vm.pin,
                  showCurrentVideo: _vm.showCurrentVideo,
                  muteCam: _vm.muteCam,
                  hideElement: _vm.hideElement,
                  activeUserSpeaking: _vm.activeUserSpeaking,
                  roster: _vm.roster,
                  volume: _vm.volume,
                  localRoster: _vm.localRoster,
                  loadingRemoteUsers: _vm.loadingRemoteUsers,
                  remoteVideos: _vm.remoteVideos,
                  filterRosters: _vm.filterRosters,
                  metrics: _vm.metrics,
                  devMode: _vm.devMode
                },
                on: {
                  boundVideoElement: _vm.boundVideoElement,
                  startLocalVideoTile: _vm.startLocalVideoTile,
                  handlePin: p => (_vm.pin = p),
                  openVideoInputFromSelection: _vm.openVideoInputFromSelection,
                  handleTotalUsers: i => (_vm.totalUsers = i),
                  handleScreenShare: i => (_vm.currentRosterScreenShare = i)
                },
                nativeOn: {
                  dblclick: function($event) {
                    return _vm.toggleFullScreen()
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "slide-bottom" } },
            [
              _vm.roomStep == _vm.Step.startRoom
                ? _c("v-room-toolbar", {
                    key: "toolbar",
                    ref: "toolbar",
                    class: { hide: _vm.hideElement },
                    attrs: {
                      lesson: _vm.lesson,
                      statusCam: !_vm.muteCam,
                      statusMic: !_vm.muteMic,
                      statusScreenShare:
                        _vm.contentShareType ==
                        _vm.ContentShareType.ScreenCapture,
                      statusFullScreen: _vm.statusFullScreen,
                      statusMicAlert: _vm.volumeAlert,
                      compact:
                        _vm.statusChat && (_vm.grid == "xl" || _vm.grid == "lg")
                    },
                    on: {
                      handleMic: function($event) {
                        return _vm.$emit("handleMic", !_vm.muteMic)
                      },
                      handleCam: function($event) {
                        return _vm.$emit("handleCam", !_vm.muteCam)
                      },
                      handleScreenShare: function($event) {
                        return _vm.contentShareStart(
                          _vm.contentShareType ==
                            _vm.ContentShareType.ScreenCapture
                            ? _vm.ContentShareType.Nothing
                            : _vm.ContentShareType.ScreenCapture
                        )
                      },
                      toggleFullScreen: _vm.toggleFullScreen,
                      openSettings: function($event) {
                        _vm.statusModalSettings = true
                      },
                      leave: _vm.leave,
                      takeAssitant: function($event) {
                        return _vm.beforeShowModal({
                          lesson: _vm.lesson,
                          action: "v-modal-attendance"
                        })
                      },
                      handleVolumeAlert: function($event) {
                        return _vm.handleVolumeAlert()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bound" } },
            [
              _vm.roomStep == _vm.Step.startRoom
                ? _c("v-room-header", {
                    key: "header",
                    ref: "header",
                    staticClass: "col-auto pl-0 pr-0",
                    attrs: {
                      showChat: _vm.statusChat,
                      lesson: _vm.lesson,
                      duration: _vm.duration,
                      users: _vm.usersMap,
                      stream: _vm.localRoster
                        ? _vm.remoteVideos[_vm.localRoster.roster]
                        : undefined,
                      currentRoster: _vm.localRoster,
                      remoteRosters: _vm.filterRosters,
                      isSharing: !!_vm.currentRosterScreenShare,
                      currentUser: _vm.localRoster,
                      roster: _vm.roster,
                      muteVideo: _vm.muteCam,
                      deviceId: _vm.muteCam ? null : _vm.currentCam,
                      showCurrentVideo: _vm.showCurrentVideo,
                      windowGrid: _vm.grid,
                      pin: _vm.pin,
                      totalUsers: _vm.totalUsers,
                      resetLocalVideo: _vm.resetVideo
                    },
                    on: {
                      handleChat: function($event) {
                        _vm.statusChat = !_vm.statusChat
                        _vm.handleStatusChatVideoRoom(_vm.statusChat)
                        _vm.getHeight()
                      },
                      handlePin: val => {
                        _vm.pin = val
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.grid == "md" || _vm.grid == "sm"
            ? _c("div", {
                staticClass: "chat-blurcolor",
                class: { show: _vm.statusChat },
                on: {
                  click: function($event) {
                    _vm.statusChat = false
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.statusModalSettings
            ? _c(
                "v-room-settings",
                {
                  ref: "modal-settings",
                  attrs: {
                    showModal: _vm.statusModalSettings,
                    audioMute: _vm.muteMic,
                    volume: _vm.volume,
                    statusTestSound: _vm.statusTestSound
                  },
                  on: {
                    close: function($event) {
                      _vm.statusModalSettings = false
                    },
                    playTestSound: function($event) {
                      return _vm.$emit("playTestSound")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "video" },
                    [
                      _c("v-room-video", {
                        key: _vm.resetVideo,
                        ref: "video",
                        staticClass: "w-100 h-100 position-relative",
                        attrs: {
                          deviceId: _vm.currentCam,
                          camMsg:
                            _vm.muteCam &&
                            _vm.permissionCam == _vm.typePermission.granted
                              ? _vm.typePermission.mute
                              : _vm.permissionCam,
                          isLocal: true,
                          onlyRead: true,
                          bgColor: _vm.muteCam ? "transparent" : "#000",
                          stream: _vm.localRoster
                            ? _vm.remoteVideos[_vm.localRoster.roster]
                            : undefined
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.meetingEnded
            ? _c("v-meeting-ended", {
                attrs: { showModal: _vm.meetingEnded },
                on: { resetMeeting: _vm.resetMeeting, leave: _vm.leave }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }