/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ice-cream2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17 11.5c0-.581-.279-1.43-1.592-2.199C15.882 8.821 16 8.35 16 8c0-.909-.79-1.753-2.074-2.333A3.004 3.004 0 0011 2c-.07 0-.192-.008-.228-.063-.125-.191-.002-.804.186-1.235a.5.5 0 00-.579-.687C10.2.06 6 1.143 6 4.012c0 .246.031.491.09.729C4.773 5.396 4 6.405 4 7.5c0 .174.02.35.058.525C3.365 8.757 3 9.607 3 10.5c0 .388.069.767.202 1.131l.769 5.688c.033.244.178.537.352.711C4.403 18.11 6.335 20 10 20s5.596-1.889 5.677-1.97a1.4 1.4 0 00.356-.701l.772-4.999c.129-.267.196-.545.196-.83zM4.503 9.05c.134.203.294.401.478.588.614.624 1.463 1.102 2.392 1.345a.502.502 0 00.61-.357.5.5 0 00-.357-.61c-.757-.199-1.443-.582-1.932-1.079C5.246 8.482 5 7.971 5 7.5c0-.692.547-1.361 1.485-1.839a2.966 2.966 0 001.917 1.33.5.5 0 00.195-.981c-.911-.181-1.598-1.04-1.598-1.997 0-1.433 1.684-2.319 2.756-2.738-.062.406-.05.858.179 1.209.154.236.464.517 1.066.517a2.002 2.002 0 011.975 2.314 9.9 9.9 0 00-1.402-.309.501.501 0 00-.146.99C13.634 6.321 15 7.29 15 8.001c0 .331-.301.628-.573.828-.82-.325-1.791-.563-2.864-.7a.5.5 0 10-.126.992c2.602.332 4.563 1.355 4.563 2.38 0 .58-.637 1.186-1.747 1.662-1.261.541-2.949.838-4.753.838-1.505 0-2.913-.386-3.965-1.088C4.545 12.253 4 11.396 4 10.501c0-.503.172-.995.503-1.45zm10.466 8.274c-.03.029-.462.438-1.263.84-.76.382-2.02.837-3.707.837s-2.947-.455-3.707-.837c-.798-.401-1.229-.808-1.262-.839a.44.44 0 01-.069-.139l-.52-3.846c.166.141.345.277.538.405 1.214.81 2.82 1.256 4.52 1.256 1.936 0 3.764-.326 5.147-.919.356-.152.674-.32.952-.499l-.555 3.594a.45.45 0 01-.075.148z"/>'
  }
})
