
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Action } from 'vuex-class';

	@Component
	export default class lessonBtn extends Vue{
		// Required { idLesson, type, txt }, opcional { minWidth }
		// type: chat, cancel, start, stop, confirm, takeAssistant, online
		// btnType: small, large, large2
		@Prop({ type: Object, default: () => {} }) readonly options!: any;

		@State(state => state.auth.permission) userType;

		@Action('beforeShowModal') beforeShowModal;

		get t(): any{
			return (this as any).$t;
		}

		get router(): any{
			return (this as any).$router;
		}

		get btnType(): string{
			if (this.options.btnType)
				return this.options.btnType
			else
				return 'large';

		}

		get btnSize(): string{
            if (this.options.btnSize)
				return this.options.btnSize;
			else
				return '28px';
		}

		get minWidth(): string | null{
            if (this.options.minWidth)
				return this.options.minWidth;
			else
				return '100px';
		}

		get btnCss(): object{
			let styles: any = this.btnType == 'large'?{ '--border-radius': '4px', minWidth: this.minWidth, paddingLeft: '10px', paddingRight: '10px', '--hover': 'rgba(0,0,0, 0.15)', '--hover-txt': '#fff' }:{ '--border-radius': '15px', '--hover': 'rgba(0,0,0, 0.15)', '--hover-txt': '#fff' };

            if (this.options.type == 'chat' || this.options.type == 'cancel' || this.options.type == 'start' || this.options.type == 'stop' || this.options.type == 'confirm'){
            	return styles;
            }
            else if (this.options.type == 'takeAssistant'){
            	if (this.btnType == 'large2')
                    styles = { ...styles, '--hover': 'initial', '--bg-icon': '#F5A623', '--hover-txt': 'initial', paddingLeft: '0', paddingRight: '0' };
                return styles
            }
            else if (this.options.type == 'online'){
            	styles = { ...styles, '--hover': 'initial', '--bg-icon': '#72BD00', '--hover-txt': 'initial', '--border-radius': '15px', paddingLeft: '0', paddingRight: '0' };
				return styles;
			}
			else
				return {}
		}

		get iconCss(): object{
            if (this.options.type == 'chat' || this.options.type == 'start')
				return { height: '16px', width: '16px', fill: '#fff' };	
			else if(this.options.type == 'cancel'){
				if (this.btnType == 'small')
					return { height: '20px', width: '20px', fill: '#fff' }
			    else
				    return { height: '12px', width: '12px', fill: '#EA2E2E !important' };	
			}
			else if (this.options.type == 'confirm')
				return { height: '16px', width: '16px', fill: '#fff' };	
			else if (this.options.type == 'start')
				return { height: '16px', width: '16px', fill: '#fff' };	
			else if (this.options.type == 'stop')
				return { height: '14px', width: '14px', fill: '#fff' };
		    else if (this.options.type == 'takeAssistant')
				return { height: '20px', width: '20px', stroke: '#fff' };	
		    else if (this.options.type == 'online')
				return { height: '20px', width: '20px', fill: '#fff' };		
			else
				return {}
		}

		get txtCss(): object{
            if (this.options.type == 'chat' || this.options.type == 'cancel' || this.options.type == 'start' || this.options.type == 'confirm'  || this.options.type == 'stop')
				return { color: '#fff' };	
			else if(this.options.type == 'takeAssistant'){
				if (this.btnType == 'large2')
				    return { color: '#000', fontWeight: 500, lineHeight: '21px', marginLeft: '5px', borderBottom: '2px solid #F5A623' };
				else
					return { color: '#fff' };
			}
			else if(this.options.type == 'online')
				return { color: '#000', fontWeight: 500, lineHeight: '21px', marginLeft: '5px', borderBottom: '2px solid #72BD00' };
			else
				return {}
		}

		get bgColor(): string{
			if (this.options.type == 'chat')
				return `bg-lesson-1-on-1`;
			else if (this.options.type == 'cancel' || this.options.type == 'stop')
				return 'bgColor-red-light';
			else if (this.options.type == 'start' || this.options.type == 'confirm')
				return 'bgColor-tutor-light';
			else if(this.options.type == 'takeAssistant' && (this.btnType == 'small' || this.btnType == 'large'))
				return 'bgColor-yellow-main';
			else if (this.options.type == 'online')
				return 'bound';
			else
				return '';
		}

		get icon(): string{
            if (this.options.type == 'chat')
				return 'chat2';
			else if (this.options.type == 'cancel')
				return 'close-sharp';
			else if (this.options.type == 'confirm')
				return  this.btnType == 'small'?'check':'checkmark-circle-fill';
			else if (this.options.type == 'start')
				return 'play2';
			else if (this.options.type == 'stop')
				return 'stop';
			else if (this.options.type == 'takeAssistant')
				return 'list5';
			else if (this.options.type == 'online')
				return 'arrow-right2';
			else
				return '';
		}

		get txt(): string{
            if (this.options.type == 'chat')
				return this.t('lessons.btnChat');
			else if (this.options.type == 'cancel')
				return this.t('lessons.btnCancel');
			else if (this.options.type == 'confirm')
				return this.t('lessons.btnConfirm');
			else if (this.options.type == 'start')
				return this.t('lessons.btnStart');
			else if (this.options.type == 'stop')
				return this.t('lessons.btnStop');
			else if (this.options.type == 'takeAssistant')
				return this.t('lessons.btnAttendance');
			else if (this.options.type == 'online')
				return this.t('lessons.btnJoin');
			else
				return '';
		}

		lessonAction():void{
			if (this.options.type == 'chat')
				this.router.push({ name : `chat-${this.userType}-id`, params: { idlesson: this.options.idLesson } });
			else if (this.options.type == 'cancel')
				this.beforeShowModal({ lesson: { id: this.options.idLesson }, action: 'v-modal-crud:cancel' });
			else if (this.options.type == 'confirm')
				this.beforeShowModal({ lesson: { id: this.options.idLesson }, action: 'v-modal-crud:confirm' });
			else if (this.options.type == 'start')
				this.beforeShowModal({ lesson: { id: this.options.idLesson }, action: 'v-modal-crud:start' });
			else if (this.options.type == 'stop')
				this.beforeShowModal({ lesson: { id: this.options.idLesson }, action: 'v-modal-crud:finished' });
			else if (this.options.type == 'takeAssistant')
				this.beforeShowModal({ lesson: { id: this.options.idLesson }, action: 'v-modal-attendance' });
			else if (this.options.type == 'online')
				this.router.push({ name: `room-${this.userType}`, params: { roomId: this.options.idLesson } });
		}
        
	}
