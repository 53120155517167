<template>
    <div id="recover-password" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">

        <div class="v-init-form-content d-flex flex-column">
            <div class="v-paper w-100 mb-4">
                <div class="v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3">
                    <h2 class="v-if-title mb-0">{{ $t('recoveryPassword.t') }}</h2>
                </div>

                <!-- ========== Form ========== -->
                <div class="p-4 d-flex flex-column align-items-center">
                    <div class="bg-icon mb-4">
					    <svgicon class="inset-icon lock2" name="lock2" color="#fff" />
				    </div>
                    <form class="w-100" @submit.prevent="send()" >
                        <v-textField class="mb-4 pb-2" ref="email" :label="$t('recoveryPassword.label')" :placeholder="$t('recoveryPassword.placeholder')" type="email" v-model="form.email" :options="opcInput" :required="true"/>
                        <v-btn class="mb-2 w-100" fontSize="1rem" fontWeight="400" :txt="$t('recoveryPassword.btn')" height="40px" type="submit" name="login" :spinner="sending" :disabled="$v.validationGroup.$invalid" />
                        <div class="text-center">
                            <router-link class="v-btn-linc" :to="{ name: 'login' }" name="recoverPassword" type="button" tag="button">{{ $t('recoveryPassword.btnPrev') }}</router-link>
                        </div>
                    </form>
                </div>
            </div>

            <!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
        </div>

        <!-- ========== Select language ========== -->
		<v-select-lang />
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
	import { required, email } from 'vuelidate/lib/validators';

    export default{
		data(){
			return{
				sending: false,
				form: {
					email: ''
				},
                opcInput: {
					activeLabel: true,
					styles: {
						label: {
							color: '#5E5E5E',
							fontWeight: 500,
							marginBottom: '.5rem',
							'--transform': 'initial',
							'--transform-active': 'initial'
						}
					}
				}
			}
		},
        computed:{
            ...mapState({ api: state => state.auth.api,
                          userEmail: state => state.auth.user.email }),
            emailLowerCase(){
				return this.form.email.toLowerCase();
			}
		},
        created(){
            this.form.email = this.userEmail;
		}, 
		mounted(){
            this.$refs.email.$el.querySelector('input').focus();
		},
        methods: {
			...mapMutations(['setNotification','setUser']),
			...mapActions(['axiosRequest']),
            send(){
            	if (!this.$v.validationGroup.$invalid && !this.sending){
            		this.sending = true;
            		this.axiosRequest({ config : {
            			method: 'PATCH',
                        data: { email: this.emailLowerCase },
                        url: `${this.api}/user/password/recovery`
            		}, error401: false }).then( resp => {
            			this.sending = false;
                    	this.setUser(this.form);
                        this.$router.push({ name : 'confirm-password' }); 
                    }).catch( error => {
                    	this.sending = false;
                	    this.setNotification({ type: 'error', msg: error.response.data.error.message });
                    });
                }
            }
		},
        validations: {
            form: {
            	email: {
            		required
            	}
            },
            emailLowerCase: {
                email
            },
            validationGroup: [ 'form', 'emailLowerCase' ]
        }
    }
</script>