
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class schDateTimeT extends Vue {
        @Prop({ default: '' }) readonly value!: any;
        @Prop({ default: true }) readonly disabled!: boolean;
        @Prop({ default: 0, required: true }) readonly duration!: number;
        @Prop({ type: String, default: '06:00', required: false }) readonly startTime!: string;
        @Prop({ type: String, default: '23:00', required: false }) readonly endTime!: string;

        val: any = {
            date: '',
            time: ''
        }
        rangeTimes: Array<object> = [];
        reset: number = 0;

        times: Array<object> = [];

        get moment(): any{
            return (this as any).$moment;
        }

        mounted() {
            this.getTimes()
        }

        inputOpc: any = {
            styles: {
                colorFocus: 'initial'
            }
        }

        getTimes(): any {
            this.val.time = '';
            this.reset += 1;

            let arr: Array<object> = [];
            const initTime = this.moment(this.startTime, 'HH:mm'),
                  endTime = this.moment(this.endTime, 'HH:mm');
            
            let time = initTime;
            while(time.isSameOrBefore(endTime)){
                const t = time.clone().add(this.duration, 'minutes');
                arr = [ ...arr, { txt: `${time.format('hh:mm A')} - ${t.format('hh:mm A')}` } ];

                time = t;
            }
            
            this.rangeTimes = arr;
        }

        @Watch('value.time')
        resetTimeVal(newVal): void{
            this.val.time = newVal;
        }

        @Watch('val')
        input(newVal){
            (this as any).$emit('input', {
                date: newVal.date != ''?this.moment(newVal.date, 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD'):'',
                time: newVal.time
            });
        }

        @Watch('duration')
        resetTime(): void {
            this.getTimes()
        }
    }
