const changeTitle = (state,title) => {
	state.title = title;
}

const setLessonRequest = (state,lesson) => {
	state.lessonRequest.lesson = lesson;
}

const setRoute = (state,route) => {
	state.lessonRequest.route = route;
}

const setStatusLesson = (state,status) => {
	state.lessonRequest.status = status;
}

const setNotification = (state,notification) => {
	state.notification = notification;
}

const setstatusHour = (state,band) => {
	state.statusHour = band;
}

const setIsMovile = (state, status) => {
    state.isMovile = status;
}

const setBrowser = (state, browser) => {
    state.currentBrowser.OP_C = browser.OP_C;
    state.currentBrowser.name = browser.name;
    state.currentBrowser.version = browser.version;
    state.currentBrowser.model = browser.model;
}

const handleGrid = (state, grid) => {
    state.grid = grid;
}

const handleSize = (state, size) => {
    if(size.heightH)
    	state.contentSize.headerH = size.heightH;
    if(size.windowH)
    	state.contentSize.windowH = size.windowH;
}

export default{
	changeTitle,
	setLessonRequest,
	setRoute,
	setStatusLesson,
	setNotification,
	setstatusHour,
	setIsMovile,
	setBrowser,
	handleGrid,
	handleSize
}