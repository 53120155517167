
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class inputRadio extends Vue {
        @Prop({ type: Array, default: [], required: true }) readonly opc!: any;
        @Prop({ type: String, default: '' }) readonly label!: any;
        @Prop({ type: String, default: 'val', required: true }) readonly fieldVal!: any;
        @Prop({ type: String, default: 'txt', required: true }) readonly fieldTxt!: any;
        @Prop({ type: Boolean, default: true }) readonly error!: boolean;
        @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
        @Prop({ default: false }) readonly onlyRead!: boolean;
        @Prop({ default: '' }) readonly value!: any;

        handleVal(val): void{
            this.$emit('input', val);
        }
    }
