<template>
	<div id="notification-preferences">
		<!-- ================ Search ================== -->
		<v-search class="mb-4" ref="search"></v-search>

		<div class="row justify-content-center">
			<!-- ================= Emails ================ -->
			<div class="emails order-1 order-md-0 col-12 col-md-6 mb-5">
				<h3 class="title mb-3">{{ $t('notificationsPreferences.titleEmail') }}</h3>
				<form @submit.prevent="send">
					<div class="paper p-4 mb-4">
						<div class="general">
						    <h4 class="title mb-4">{{ $t('notificationsPreferences.general') }}</h4>
						    <div class="checkBox mb-4 pb-4">
						    	<h5 class="title mb-1">{{ $t('notificationsPreferences.checkboxes.newsletter.t') }}</h5>
						    	<p class="descrip mb-0" v-html="$t('notificationsPreferences.checkboxes.newsletter.dHtml')"></p>
						    	<div class="v-checkbox" :class="{ active : form.newsletter }">
						    	    <input type="checkbox" v-model="form.newsletter">
						        </div>
						    </div>
					    </div>
					    <div class="lessons">
						    <h4 class="title mb-4">{{ $t('notificationsPreferences.lessons') }}</h4>
						    <div class="checkBox mb-4">
						    	<h5 class="title mb-1">{{ $t('notificationsPreferences.checkboxes.lesson_status.t') }}</h5>
						    	<p class="descrip">{{ $t('notificationsPreferences.checkboxes.lesson_status.d') }}</p>
						    	<div class="v-checkbox" :class="{ active : form.lesson_status_s }">
						    	    <input type="checkbox" v-model="form.lesson_status_s">
						        </div>
						    </div>
						    <div class="checkBox mb-4">
						    	<h5 class="title mb-1">{{ $t('notificationsPreferences.checkboxes.lesson_summary.t') }}</h5>
						    	<p class="descrip">{{ $t('notificationsPreferences.checkboxes.lesson_summary.d') }}</p>
						    	<div class="v-checkbox" :class="{ active : form.lesson_summary_s }">
						    	    <input type="checkbox" v-model="form.lesson_summary_s">
						        </div>
						    </div>
						    <div class="checkBox mb-3">
						    	<h5 class="title mb-1">{{ $t('notificationsPreferences.checkboxes.upcoming_lessons.t') }}</h5>
						    	<p class="descrip" v-html="$t('notificationsPreferences.checkboxes.upcoming_lessons.dHtml')"></p>
						    	<div class="v-checkbox" :class="{ active : form.upcoming_lessons_s }">
						    	    <input type="checkbox" v-model="form.upcoming_lessons_s">
						        </div>
						    </div>
					    </div>
					</div>
					<v-btn class="w-100" :txt="$t('notificationsPreferences.btn')" type="submit" :disabled="sending" />
				</form>
			</div>
			<!-- ================= Push notifications ================ -->
			<div class="notifications order-0 order-md-1 col-12 col-md-6 mb-4">
				<h3 class="title mb-3">{{ $t('notificationsPreferences.titlePush') }}</h3>
				<div class="paper">
					<p class="descrip mb-4">{{ $t('notificationsPreferences.descrip') }}</p>
					<h4 class="title-app mb-3">{{ $t('notificationsPreferences.app') }}</h4>
					<a href="https://play.google.com/store/apps/details?id=mx.linc.institutions&referrer=utm_source%3Dwebsite" target="_blank">
						<img class="img-app" :src="$t('googlePlay')" :alt="$t('image.googlePlay')">
					</a>
					<a href="https://itunes.apple.com/app/apple-store/id1424076127?mt=8" target="_blank">
						<img class="img-app pl-2" :src="$t('appStore')" :alt="$t('image.appStore')">
					</a>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		data(){
			return{
				sending: false,
				form:{
					newsletter: false,
					lesson_status_s: false,
					lesson_summary_s: false,
					upcoming_lessons_s: false
				}
			}
		},
		computed:{
            ...mapState(['auth'])
		},
		created(){
            this.form.newsletter = !!this.auth.user.preferences.notif_newsletter;
            this.form.lesson_status_s = !!this.auth.user.preferences.notif_lesson_status_s;
            this.form.lesson_summary_s = !!this.auth.user.preferences.notif_lesson_summary_s;
            this.form.upcoming_lessons_s = !!this.auth.user.preferences.notif_upcoming_lessons_s;
		},
		methods: {
			...mapMutations(['setNotifications','setNotification']),
			...mapActions(['axiosRequest']),
			send(){
				if (!this.sending){
					this.sending = true;
					this.$Progress.start();
					this.axiosRequest({ config : {
						method: 'PUT',
						url: `${this.auth.api}/user/notifications`,
						data: this.form,
						headers: { authorization : this.auth.token, 'Content-Type' : 'application/json' }
					}}).then( resp => resp.data )
					.then( data => {
						this.sending = false;
						this.setNotifications(this.form);
						this.setNotification({ type: data.type, msg: data.message });
						this.$Progress.finish();
					}).catch( error => {
						this.sending = false;
						this.$Progress.fail();
					});
                }
			}
		}
	}
</script>