
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class Video extends Vue{
		@Prop({ type: String, default: '' }) readonly deviceId!: string;
		@Prop({ type: Number, default: null }) readonly camMsg!: boolean | null;
		@Prop({ type: Boolean, default: false }) readonly muteVideo!: boolean;
		@Prop({ type: Boolean, default: false }) readonly handleVideo!: boolean;
		@Prop({ type: Boolean, default: false }) readonly isLocal!: boolean;
		@Prop({ type: Boolean, default: true }) readonly startLocalVideoPreview!: boolean;
		@Prop({ type: Object, default: null }) readonly roster!: any;
		@Prop({ type: Object, default: null }) readonly stream!: any;
		@Prop({ type: Object, default: null }) readonly user!: any;
		@Prop({ type: String, default: null }) readonly typeLesson!: string | null;
		@Prop({ type: Boolean, default: false }) readonly onlyRead!: boolean;
		@Prop({ type: String, default: null }) readonly height!: string | null;
        @Prop({ type: String, default: null }) readonly width!: string | null;
		@Prop({ type: Number, default: 80 }) readonly minHeightPhoto!: number; // size in px
		@Prop({ type: String, default: '1.2rem' }) readonly bottomName!: string;
		@Prop({ type: Boolean, default: false }) readonly activePin!:boolean;
        @Prop({ type: Boolean, default: false }) readonly hideActions!:boolean;
        @Prop({ type: Boolean, default: false }) readonly userSpeaking!:boolean;
        @Prop({ type: Number, default: 0 }) readonly totalUsers!: number;
		@Prop({ type: Object, default: undefined }) readonly metric!: object;
		@Prop({ type: Boolean }) readonly devMode!:  boolean;
		@Prop({ type: String, default: '#000' }) readonly bgColor!: String;

		/*-- ========== Datas ========= */
		statusBound: boolean = false;
		timeOut: any = '';
		canvasTxt: any = null;

        @State( state => state.auth.permission ) typeUser;
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour;
        @State( state => state.typePermission ) typePermission // Enum
		@Getter('audioVideo') audioVideo

		/* ========Computed======= */
		get isVideoMute(){
			if (this.isLocal)
				return this.stream?!this.stream.active:true;
			else if (this.roster){
			    return this.stream && !this.roster?.bandwidthConstrained?false:true;
            }
		}
		get id(){
			if (this.stream)
				return this.stream.tileId;
			else
				return '';
		}
		get isAudioMute(){
			if (this.roster)
				return this.roster.muted;
			else
				return false;
		}
		get isSharing(){
			if (this.roster)
				return this.roster.sharing && !this.roster?.bandwidthConstrained;
			else
				return false;
		}
		get volume(){
			if (this.roster && !this.isAudioMute)
				return this.roster.volume;
			else
				return 0;
		}
		/*get videoMetric(): object{
			if(this.metric && this.devMode){
				const m: any = Object.values(this.metric)[0];
				if(this.isLocal)
				    return { bw: m.videoUpstreamBitrate / 1000, 
				             fps: m.videoUpstreamFramesEncodedPerSecond,
						     h: m.videoUpstreamGoogFrameHeight,
						     w: m.videoUpstreamGoogFrameWidth };
				else
				    return { bw: m.videoDownstreamBitrate / 1000, 
				             fps: m.videoDownstreamFramesDecodedPerSecond,
						     h: m.videoDownstreamGoogFrameHeight,
						     w: m.videoDownstreamGoogFrameWidth };
			}
			else{
				return { up: 0, fps: 0, h: null, w: null }
			}
		}*/
		/*get priority(): any{
			if(typeof this.roster?.quality === 'number' && this.devMode){
				const q = this.roster.quality == 0?'Low':(this.roster.quality == 1?'Medium':'Hight');
				return { p: this.roster?.priority, q: q };
			}
			else{
				return null;
			}
		}*/
		get refs(){
            return(this as any).$refs;
        }
        get emit(){
            return(this as any).$emit;
        }
        get getPhotoSize(){
            if(this.height == '100%' || !this.height)
            	return '160px';
            else{
            	let h = parseInt(this.height.replace('px', ''));
            	h = h/3;
            	if (h >= 160)
            		return '160px';
            	else if(h <= this.minHeightPhoto)
            	    return `${this.minHeightPhoto}px`;
            	else
            		return `${h}px`;
            }
        }
        get videoSize(){
            if (this.stream && this.totalUsers > 0 && this.height && this.width){
                const pc = this.stream.videoStreamContentHeight && this.stream.videoStreamContentWidth,
                      H = pc?this.stream.videoStreamContentHeight:this.stream.videoElementPhysicalHeightPixels,
                      W = pc?this.stream.videoStreamContentWidth:this.stream.videoElementPhysicalWidthPixels,
                      contentW = parseInt(this.width.replace('px','')),
                      contentH = parseInt(this.height.replace('px',''));
                if (this.totalUsers <= 2 || this.activePin || this.isSharing) {
                    return { width: '100%', height: '100%' };
                }
                else if(this.totalUsers > 2 && W > H){
                    return { width: `${(640/360)*contentH}px`, height: this.height, left: `${-((((640/360)*contentH)-contentW)/2)}px` };                        
                }
            }else{
                return { width: '100%', height: '100%' };
            }
        }
        get expandAndMicTop(){
            if (this.height && this.getPhotoSize){
            	const contentH = parseInt(this.height.replace('px', '')),
        	          photoH = parseInt(this.getPhotoSize.replace('px', ''));

                if(contentH < 370)
                	return '50%';
                else
            	    return `${(contentH/2) + (photoH/2) + 22 + 15}px`;
            }
            else
            	return '60%';
        }

		mounted(){
			if (!this.onlyRead && ((this.isLocal && this.handleVideo) || !this.isLocal)){
				if (this.handleVideo && (!this.muteVideo || this.isSharing) && this.isLocal)
				    this.emit('changeVideo', this.deviceId, band => {
						this.openVideoInputFromSelection(true);
					});
				else
				    this.openVideoInputFromSelection(true);
			}
			else if(this.refs.videoElement && this.stream?.boundVideoStream && !this.handleVideo && this.isLocal ){
				this.initCloneVideo();
			}
		}

        beforeDestroy(){
            this.endCloneVideo();
        }

		openVideoInputFromSelection(bindVideo: boolean = false): void{
			if (/*this.stream && */this.audioVideo){
				if (this.isLocal) {
					if (this.deviceId && !this.muteVideo){
					    this.emit('startLocalVideo');
					}
				}
			    /*else if(this.isSharing && !this.user && this.typeLesson == 'Individual' && bindVideo){
			    	this.emit('boundVideoElement', this.isLocal, this.stream, this.refs.videoElement as HTMLVideoElement );
			    }*/

			    if ((!this.muteVideo || this.isSharing) /*&& this.typeLesson == 'Grupal'*/ && this.stream?.tileId && bindVideo){ 
			    	this.emit('boundVideoElement', this.isLocal, this.stream, this.refs.videoElement as HTMLVideoElement );
			    }
			}
		}

		initCloneVideo(): void{
			console.log('clone video');
            const clonedStream = this.stream.boundVideoStream.clone();
            
			this.refs.videoElement.srcObject = clonedStream;
            this.refs.videoElement.play().catch(() => {});
		}

		async endCloneVideo(): Promise<void>{
			if(!this.handleVideo && this.isLocal && this.refs.videoElement){
				console.log('Stop clone video');
				let video = this.refs.videoElement.srcObject;
				if(video){
				    await this.refs.videoElement.srcObject.getTracks().forEach(track => {
					    track.stop();
				    });
				
				    video = null;
				}
			}
		}
        
        @Watch('deviceId')
        async camChange(newVal: string): Promise<void>{
        	if (this.handleVideo && !this.muteVideo && (this.typeLesson == 'Individual' || (this.typeLesson == 'Grupal' && !this.isSharing))) {
        		console.log('video input device is changed');
        		try {
					this.emit('changeVideo', newVal, band => {
						if(band)
						    this.openVideoInputFromSelection(false);
					});
        		} catch (err) {
        			console.log('no video input device selected');
        		}
        	}
        }

        @Watch('muteVideo')
        async statusVideo(newVal: string, oldVal: string): Promise<void>{
        	if (!newVal && this.handleVideo && (this.typeLesson == 'Individual' || (this.typeLesson == 'Grupal' && !this.isSharing))) {
        		try {
					this.emit('changeVideo', this.deviceId, band => {
						if(band)
						    this.openVideoInputFromSelection(false);
					});
        		} catch (err) {
        			console.log('no video input device selected');
        		}	
        	}
        }


        @Watch('roster.volume')
        showBound(newVal: number): void{
            if (newVal > 30 && !this.statusBound && !this.onlyRead){
            	clearTimeout(this.timeOut);
            	this.timeOut = setTimeout( () => {
            		this.statusBound = true;
            	}, 500)
            }
        }

        @Watch('statusBound')
        hideBound(newVal: boolean): void{
        	if (newVal && !this.isAudioMute)
        		setTimeout( () => {
        			this.statusBound = false;
        		}, 1500)
        }

        @Watch('stream.tileId')
        async handleTileId(newVal: any, oldVal: any): Promise<void>{
			if (newVal && !this.onlyRead && ((this.isLocal && this.handleVideo && (this.stream.localTile || this.stream.boundAttendeeId.includes('#content'))) || !this.isLocal)){
			 	this.openVideoInputFromSelection(/*!oldVal?*/true/*:false*/);
			}
			else if(this.refs.videoElement && this.stream?.boundVideoStream && !this.handleVideo && this.isLocal && this.stream.localTile){
				await this.endCloneVideo();
                this.initCloneVideo();
			}
        }
	}
