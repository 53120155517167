/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slide': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M14.5 19h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM1.5 1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z"/><path pid="1" d="M12.625 13h-8.75c-.345 0-.625-.224-.625-.5s.28-.5.625-.5h8.75c.345 0 .625.224.625.5s-.28.5-.625.5zM12.625 15h-8.75c-.345 0-.625-.224-.625-.5s.28-.5.625-.5h8.75c.345 0 .625.224.625.5s-.28.5-.625.5zM10 6a1.001 1.001 0 010-2 1.001 1.001 0 010 2zm0-1.5a.5.5 0 10.002 1.002A.5.5 0 0010 4.5z"/><path pid="2" d="M12.688 2H3.312A.31.31 0 003 2.308v7.384c0 .17.14.308.313.308h9.374A.31.31 0 0013 9.692V2.308A.31.31 0 0012.687 2zM3.624 7.963l2.006-2.222a.258.258 0 01.188-.09c.071 0 .138.03.191.086l3.458 3.648H3.625V7.963zm8.75 1.422h-2.052L6.467 5.317a.88.88 0 00-1.303.015L3.625 7.037V2.615h8.75v6.77z"/></g>'
  }
})
