import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

const state = {
	auth: {
        api : process.env.NODE_ENV==='production'?'https://web-api.linc.mx/api':'https://dev-web-api.linc.mx/api',
        media: process.env.NODE_ENV==='production'?'https://media.linc.mx/docs/chat':'https://media.linc.mx/docs/dev/chat',
        aw: process.env.NODE_ENV==='production'?'https://s3.amazonaws.com/media.linc.mx/docs/chat':'https://s3.amazonaws.com/media.linc.mx/docs/dev/chat',
        awPhoto: process.env.NODE_ENV==='production'?'https://s3.amazonaws.com/media.linc.mx/images/universities/app/profile/':'https://s3.amazonaws.com/media.linc.mx/images/dev/universities/app/profile/',
        path: '',
        permission: '',
        token: '',
        institution: {},
        user:{},
        formats: null,
        pendingFeedback: [],
        pendingConfirmation: [],
        activeLessons: [],
        commentRequired: false,
        currentActiveLesson: null,
        campusType: 'default'
	}
}

export default {
    state,
    getters,
    mutations,
    actions
}