<template>
	<div class="v-modal" :class="{ active : show }" v-if="modalVal.modal=='v-modal-assistants'">
		<!-- ========= Background color ======== -->
		<div class="v-blurColor" @click="close()"></div>
		<div class="v-modal-paper">
			<!-- ========= Modal title ======== -->
			<div class="v-modal-title" bgColor="green-main">
				<h4 class="v-t">{{ $t('modals.assistants.t') }}</h4>
				<svgicon class="v-btn-close" name="cross2" @click="close()" />
			</div>
			<!-- ========= List asistants ======== -->
			<div class="pb-4 pl-5 pr-4">
				<perfect-scrollbar class="list-students pl-0 pt-3 pr-4" tag="ul" :style="{ overflow: currentLesson.students.length > 3?'hidden !important':'initial !important' }">
                    <li class="student d-flex flex-wrap align-items-start pt-2 pb-2 pr-4" :class="{ active: student.assistance }" v-for="(student,i) in currentLesson.students">
                    	<v-img-profile class="mr-2" :img="student.photo" size="40px" />
                    	<div class="col student-info pl-0 pr-0">
                    		<div class="row mr-0 ml-0 align-items-center">
                    			<div class="col pl-0 pr-0 name-and-subject">
                                    <v-hover-txt class="mb-1" fontSize="12px" fontWeight="400" minWidthCloud="200px" :positionCloud="i==0 && currentLesson.students.length > 1?'top':'bottom'" :txt="student.user_name" />
                                    <v-hover-txt fontSize="11px" fontWeight="400" minWidthCloud="200px" colorTxt="#717171" :positionCloud="i==0 && currentLesson.students.length > 1?'top':'bottom'" :txt="student.career" />
                    			</div>
                    			<div class="col pl-0 pr-0 rating">
                    				<star-rating class="stars justify-content-end" :class="{ 'hide-num-rating': !student.rating }" :increment="0.1" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" :rating="student.rating?parseInt(student.rating):0" :star-size="10" :border-width="0.1" text-class="custom-text" inactive-color="#FCE5BF" active-color="#F5A623" read-only/>
                    			</div>
                    		</div>
                    	</div>
                    	<v-hover-txt class="comment w-100" fontWeight="400" fontSize="11px" colorTxt="#717171" :lines="3" :positionCloud="i==0 && currentLesson.students.length > 1?'top':'bottom'" lineHeight="14px" :txt='student.comments?`"${student.comments}"`:""' />
                    </li>
				</perfect-scrollbar>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapGetters } from 'vuex';
	export default{
		mixins: [ modal ],
		computed:{
			...mapState(['currentLesson']),
			...mapGetters(['modalVal'])
		},
		methods:{
			...mapMutations(['setModal']),
			afterClose(){
				this.setModal('');
			}
		}
	}
</script>