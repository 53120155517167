const statusFormats = state => {
    if(state.auth.formats && !state.auth.user.is_tutor && state.auth.campusType == 'tecnm'){
        const { assertiveness, identity, learningStyles, selfEsteem, studySkills } = state.auth.formats;
        if(!assertiveness || !identity || !learningStyles || !selfEsteem || !studySkills )
           return true;
    }

    return false;
}

const permissionFormats = state => {
    if(state.auth.campusType == 'tecnm'){
        if(state.auth.permission == 'tutor' || (state.auth.permission == 'student' && !state.auth.user.is_tutor))
            return true;
        else
            return false;
    }else 
        return false;
}

export default{
    permissionFormats,
    statusFormats
}