var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pb-5", attrs: { id: "subjects" } },
    [
      _c(
        "div",
        {
          staticClass:
            "paper row ml-sm-0 mr-sm-0 pl-md-4 pr-md-4 pt-sm-5 pb-sm-5 pt-4 pb-4 justify-content-center"
        },
        [
          _c(
            "div",
            { staticClass: "content-subjects col-md" },
            [
              _c(
                "h2",
                { staticClass: "title-subjects mb-3" },
                [
                  _c("svgicon", {
                    staticClass: "icon",
                    attrs: { name: "icons" }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("subjects.t")) + " ")
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                staticClass: "descrip-subjects mb-3",
                domProps: { innerHTML: _vm._s(_vm.$t("subjects.descripHtml")) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "paper p-4 mb-4" },
                [
                  _c("div", { staticClass: "d-none d-md-block" }, [
                    _c("div", { staticClass: "d-flex flex-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col offset-md-4 d-flex order-1 order-sm-0 text-center"
                        },
                        [
                          _c("span", { staticClass: "title-list-subject" }, [
                            _vm._v(_vm._s(_vm.$t("subjects.tType")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col d-flex order-0 order-sm-1 text-center"
                        },
                        [
                          _c("span", { staticClass: "title-list-subject" }, [
                            _vm._v(_vm._s(_vm.$t("subjects.tMode")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col d-flex order-2 text-center" },
                        [
                          _c("span", { staticClass: "title-list-subject" }, [
                            _vm._v(_vm._s(_vm.$t("subjects.duration")))
                          ])
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "perfect-scrollbar",
                    { ref: "scroll" },
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "subjects-list pl-3 pr-3",
                          attrs: { name: "bound", tag: "ul" }
                        },
                        [
                          _vm._l(_vm.subjects, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: item.subject_id,
                                staticClass:
                                  "subject row align-items-center pt-3 pb-3"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-md-4 order-0 mb-2 mb-md-0"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "info-subjects d-flex justify-content-center flex-column pl-5 position-relative"
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "color mr-2",
                                          style: { background: item.color }
                                        }),
                                        _vm._v(" "),
                                        _c("v-hover-txt", {
                                          staticClass: "mb-1",
                                          attrs: {
                                            fontSize: "12.5px",
                                            fontWeight: "500",
                                            positionCloud:
                                              index == 0 ? "top" : "bottom",
                                            txt: item.name
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-hover-txt", {
                                          attrs: {
                                            fontSize: "10px",
                                            txt: item.category,
                                            positionCloud:
                                              index == 0 ? "top" : "bottom",
                                            colorTxt: "#9b9b9b"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-2 order-sm-1"
                                  },
                                  [
                                    _c("v-lesson-tag", {
                                      attrs: {
                                        type: item.type,
                                        options: { lessonLimit: item.limit }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-1 order-sm-2"
                                  },
                                  [
                                    _c("v-lesson-tag", {
                                      attrs: { type: item.modality }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-3"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "duration d-block" },
                                      [
                                        _c("svgicon", {
                                          staticClass: "icon-mode",
                                          attrs: { icon: "clock" }
                                        }),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.formatTime(item.duration_time)
                                            ) +
                                            "\n                                    "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.spinner
                            ? _c(
                                "div",
                                {
                                  key: "spinner",
                                  staticClass:
                                    "lesson-spinner w-100 p-3 text-center"
                                },
                                [
                                  _c("v-spinner", {
                                    attrs: { height: "30px", width: "30px" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-circles", { attrs: { vCircle: 3, size: "300px" } }),
              _vm._v(" "),
              _c("v-circles", { attrs: { vCircle: 4, size: "200px" } })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }