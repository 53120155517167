<template>
	<div class="v-select-lang">
		<select class="language" v-model="lang" @change="setLang($event.target.value)">
			<option :value="lang.short" v-for="lang in languages">{{ lang.long }}</option>
		</select>
		<div class="content-icon">
			<svgicon class="icon" name="chevron-down"></svgicon>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations } from 'vuex';
	export default{
		data(){
            return{
            	lang: ''
            }
		},
        computed:{
            ...mapState(['i18n','languages'])
        },
        created(){
            this.lang = this.i18n.locale;
        },
        methods: {
        	...mapMutations(['setLang'])
        }
	}
</script>