var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-lesson pt-2 pb-2",
      style: {
        paddingLeft: _vm.padding,
        paddingRight: _vm.padding,
        border: `2px solid ${_vm.userType == "tutor" ? "#005E58" : "#2188FC"}`,
        boxShadow: `0 0 0 4px ${
          _vm.userType == "tutor"
            ? "rgba(0,94,88,0.24)"
            : "rgba(0,145,255,0.24)"
        }`
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "row",
          style: {
            marginLeft: `-${_vm.padding}`,
            marginRight: `-${_vm.padding}`
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vl-tm col-12 col-sm d-flex mb-2 mb-xl-0 align-items-center order-0",
              class: { parting: _vm.statusParting.typeModality },
              style: {
                ..._vm.gridSize.typeModality,
                paddingLeft: "12px",
                paddingRight: _vm.padding
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex ml-n1 mr-n1 align-items-center w-100" },
                [
                  _c(
                    "div",
                    { ref: "photo", staticClass: "pl-1 pr-1" },
                    [
                      _c("v-lesson-photo", {
                        attrs: { options: _vm.lessonPhotoOpc }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "pl-1 pr-1",
                      style: { maxWidth: `calc( 100% - ${_vm.photoSize}px )` }
                    },
                    [
                      _c("v-hover-txt", {
                        staticClass: "mb-2",
                        attrs: {
                          colorTxt: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                          txt: _vm.cardName
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex ml-n1 mr-n1" }, [
                        _c(
                          "div",
                          { staticClass: "col-auto pl-1 pr-1" },
                          [
                            _c("v-lesson-tag", {
                              attrs: {
                                type: _vm.lesson.type,
                                options: _vm.tagTypeOpc
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-auto pl-1 pr-1" },
                          [
                            _c("v-lesson-tag", {
                              attrs: { type: _vm.lesson.modality }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vl-topic col-12 col-sm mb-2 mb-xl-0 d-flex flex-column justify-content-center order-1 order-sm-2 order-md-1",
              class: { parting: _vm.statusParting.topic },
              style: {
                ..._vm.gridSize.topic,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-hover-txt", {
                attrs: {
                  colorTxt: "#000",
                  fontSize: "16px",
                  fontWeight: "400",
                  txt: _vm.lesson.subject
                }
              }),
              _vm._v(" "),
              _vm.lesson.topic &&
              ((_vm.userType == "student" && _vm.lesson.type != "Grupal") ||
                _vm.userType == "tutor")
                ? _c("v-lesson-topic", {
                    staticClass: "mt-2",
                    attrs: {
                      options: {
                        lessonId: _vm.lesson.id,
                        topic: _vm.lesson.topic,
                        hideBtn: true
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vl-sch col-12 col-sm mb-2 mb-xl-0 d-flex align-items-center order-2 order-sm-3 order-md-2",
              class: { parting: _vm.statusParting.schedule },
              style: {
                ..._vm.gridSize.schedule,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-lesson-schedule", {
                staticClass: "w-100",
                attrs: {
                  options: {
                    date: _vm.lesson.scheduledDate,
                    startTime: _vm.lesson.scheduledTime,
                    endTime: _vm.lesson.endScheduledTime
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.lesson.modality == "Presencial"
            ? _c(
                "div",
                {
                  staticClass:
                    "vl-place col pt-2 pb-2 mb-2 mb-md-0 d-flex align-items-center order-4",
                  style: {
                    ..._vm.gridSize.place,
                    paddingLeft: _vm.padding,
                    paddingRight: _vm.padding
                  }
                },
                [
                  _c("v-lesson-place", {
                    attrs: {
                      options: {
                        icon: _vm.lesson.placeIcon,
                        place: _vm.lesson.place,
                        modality: _vm.lesson.modality,
                        type: _vm.lesson.type,
                        lessonId: _vm.lesson.id
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.statusActions1
            ? _c(
                "div",
                {
                  ref: "actions",
                  staticClass:
                    "col-12 mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-sm-start order-6 order-sm-5",
                  class: _vm.colOnlineAuto ? "col-sm-auto" : "col-sm",
                  style: {
                    ..._vm.gridSize.btnsOnline,
                    paddingLeft: _vm.padding,
                    paddingRight:
                      _vm.statusMenu || _vm.bootstrapGrid != "xl"
                        ? _vm.padding
                        : "0"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row align-items-center h-100",
                      style: {
                        marginLeft: _vm.statusMenu ? "-6px" : "-4px",
                        marginRight: _vm.statusMenu ? "-6px" : "-4px"
                      }
                    },
                    [
                      _vm.lesson.modality == "Online"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-auto pt-1 pb-1",
                              style: {
                                paddingLeft: _vm.statusMenu ? "6px" : "4px",
                                paddingRight: _vm.statusMenu ? "6px" : "4px"
                              }
                            },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "online",
                                    btnType:
                                      _vm.btnLarge || _vm.bootstrapGrid != "xl"
                                        ? "large2"
                                        : "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userType == "tutor" && _vm.lesson.type == "Grupal"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-auto pt-1 pb-1",
                              style: {
                                paddingLeft: _vm.statusMenu ? "6px" : "4px",
                                paddingRight: _vm.statusMenu ? "6px" : "4px"
                              }
                            },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "takeAssistant",
                                    btnType:
                                      _vm.btnLarge || _vm.bootstrapGrid != "xl"
                                        ? "large2"
                                        : "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "actions2",
              staticClass: "col-12 mb-2 mb-md-0 col-sm-auto order-7 order-sm-6",
              style: { paddingLeft: _vm.padding, paddingRight: _vm.padding }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "row align-items-center justify-content-center justify-sm-content-end h-100 ml-n1 mr-n1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-auto pt-1 pb-1",
                      style: {
                        paddingLeft: _vm.statusMenu ? "3px" : "4px",
                        paddingRight: _vm.statusMenu ? "3px" : "4px"
                      }
                    },
                    [
                      _c("v-lesson-btn", {
                        staticClass: "w-100",
                        attrs: {
                          options: {
                            type: "chat",
                            btnType:
                              _vm.bootstrapGrid == "sm" ||
                              _vm.bootstrapGrid == "xs"
                                ? "large"
                                : "small",
                            idLesson: _vm.lesson.id
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.userType == "tutor"
                    ? _c(
                        "div",
                        {
                          staticClass: "col-auto pt-1 pb-1",
                          style: {
                            paddingLeft: _vm.statusMenu ? "3px" : "4px",
                            paddingRight: _vm.statusMenu ? "3px" : "4px"
                          }
                        },
                        [
                          _c("v-lesson-btn", {
                            staticClass: "w-100",
                            attrs: {
                              options: {
                                type: "stop",
                                btnType:
                                  _vm.bootstrapGrid == "sm" ||
                                  _vm.bootstrapGrid == "xs"
                                    ? "large"
                                    : "small",
                                idLesson: _vm.lesson.id
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "time",
              staticClass: "col-12 mb-2 mb-md-0 order-5 order-sm-1 order-md-7",
              class: _vm.colAutoTime ? "col-sm-auto" : "col-sm",
              style: {
                ..._vm.gridSize.time,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-lesson-time", {
                key: _vm.keyTime,
                staticClass: "text-center text-sm-left",
                attrs: {
                  options: {
                    initialTime: _vm.lesson.initialTime,
                    tzScheduled: _vm.lesson.tz_scheduled
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }