var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-main-format d-flex flex-column" },
    [
      _vm.isTutor
        ? _c("v-student-seeker", {
            staticClass: "mb-5",
            on: { getStudentFormat: _vm.getStudentFormat }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "bound", mode: "out-in" } }, [
        !_vm.isTutor
          ? _c("div", { staticClass: "mb-4" }, [
              _c("h2", { staticClass: "mf-title h2 mb-4" }, [
                _vm._v(_vm._s(_vm.$t("formats.t", { name: _vm.userName })))
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mf-txt mb-0",
                domProps: { innerHTML: _vm._s(_vm.$t("formats.dHtml")) }
              })
            ])
          : _vm.isTutor && _vm.studentFormats && _vm.currentStudent
          ? _c("div", { staticClass: "mb-5" }, [
              _c("h2", { staticClass: "mf-title h2 mb-4" }, [
                _vm._v("Formatos del estudiante")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ml-n3 mr-n3 mb-4" }, [
                _c("div", { staticClass: "pl-3 pr-3" }, [
                  _c("div", { staticClass: "v-fotmat-user-info" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4 mb-3" }, [
                        _c("p", { staticClass: "vf-txt mb-0" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm2.name")))
                          ]),
                          _vm._v(" " + _vm._s(_vm.currentStudent.fullName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 text-md-center mb-3" },
                        [
                          _c("p", { staticClass: "vf-txt mb-0" }, [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("formats.fm2.email")))
                            ]),
                            _vm._v(" " + _vm._s(_vm.currentStudent.email))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 text-md-right mb-3" },
                        [
                          _c("p", { staticClass: "vf-txt mb-0" }, [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("formats.fm2.noControl")))
                            ]),
                            _vm._v(" " + _vm._s(_vm.currentStudent.studentId))
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mf-txt mb-0" }, [
                _vm._v(
                  "A continuación puedes visualizar los formatos del estudiante seleccionado así como sus resultados obtenidos. Haz clic sobre cada formato para visualizarlo a detalle."
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "bound", mode: "out-in" } }, [
        _vm.isTutor && _vm.spinner
          ? _c(
              "div",
              {
                key: "spinner",
                staticClass: "v-format-spinner position-relative h-100"
              },
              [_c("v-spinner-logo", { attrs: { size: "120px" } })],
              1
            )
          : _vm.isTutor && !_vm.studentFormats
          ? _c(
              "div",
              {
                key: "alert",
                staticClass:
                  "h-100 d-flex flex-column justify-content-center align-items-center"
              },
              [
                _c("svgicon", {
                  staticClass: "v-format-msg-icon mb-3",
                  attrs: { name: "file-empty" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "v-format-msg text-center mb-0" }, [
                  _vm._v(
                    "Utilizar el buscador de arriba para consultar los formatos de estudiante requeridos en el manual de tutor."
                  )
                ])
              ],
              1
            )
          : !_vm.isTutor || (_vm.isTutor && _vm.studentFormats)
          ? _c(
              "div",
              { key: "memu", staticClass: "mf-menu mb-4" },
              [
                _c("v-item-menu-format", {
                  attrs: {
                    txt: `1. ${_vm.$t(`formats.fm1.t`)}`,
                    completed: _vm.statusIdentity.completed,
                    disabled:
                      _vm.statusIdentity.disabled &&
                      !_vm.statusIdentity.completed
                  },
                  nativeOn: {
                    click: function($event) {
                      !_vm.statusIdentity.disabled ||
                      _vm.statusIdentity.completed
                        ? _vm.handleFormat(_vm.step.identificationFile)
                        : null
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-item-menu-format", {
                  attrs: {
                    txt: `2. ${_vm.$t(`formats.fm2.t`)}`,
                    completed: _vm.statusStudySkills.completed,
                    disabled:
                      _vm.statusStudySkills.disabled &&
                      !_vm.statusStudySkills.completed
                  },
                  nativeOn: {
                    click: function($event) {
                      !_vm.statusStudySkills.disabled ||
                      _vm.statusStudySkills.completed
                        ? _vm.handleFormat(_vm.step.studySkills)
                        : null
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-item-menu-format", {
                  attrs: {
                    txt: `3. ${_vm.$t(`formats.fm3.t`)}`,
                    completed: _vm.statusLearningInventory.completed,
                    disabled:
                      _vm.statusLearningInventory.disabled &&
                      !_vm.statusLearningInventory.completed
                  },
                  nativeOn: {
                    click: function($event) {
                      !_vm.statusLearningInventory.disabled ||
                      _vm.statusLearningInventory.completed
                        ? _vm.handleFormat(_vm.step.learningInventory)
                        : null
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-item-menu-format", {
                  attrs: {
                    txt: `4. ${_vm.$t(`formats.fm4.t`)}`,
                    completed: _vm.statusSelfEsteem.completed,
                    disabled:
                      _vm.statusSelfEsteem.disabled &&
                      !_vm.statusSelfEsteem.completed
                  },
                  nativeOn: {
                    click: function($event) {
                      !_vm.statusSelfEsteem.disabled ||
                      _vm.statusSelfEsteem.completed
                        ? _vm.handleFormat(_vm.step.selfEsteem)
                        : null
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-item-menu-format", {
                  attrs: {
                    txt: `5. ${_vm.$t(`formats.fm5.t`)}`,
                    completed: _vm.statusAssertiveness.completed,
                    disabled:
                      _vm.statusAssertiveness.disabled &&
                      !_vm.statusAssertiveness.completed
                  },
                  nativeOn: {
                    click: function($event) {
                      !_vm.statusAssertiveness.disabled ||
                      _vm.statusAssertiveness.completed
                        ? _vm.handleFormat(_vm.step.assertiveness)
                        : null
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }