var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-room-users h-100" }, [
    _c(
      "div",
      { staticClass: "v-users pl-3 pr-3 d-flex flex-column h-100" },
      [
        _c(
          "perfect-scrollbar",
          { staticClass: "mt-3 mb-3 h-100 d-flex flex-column" },
          [
            _vm.currentRoster
              ? _c(
                  "div",
                  {
                    staticClass:
                      "v-room-user d-flex align-items-center pt-2 pb-2 order-0"
                  },
                  [
                    _c("div", { staticClass: "col-auto pl-0 pr-0 mr-1" }, [
                      _vm.roster[_vm.currentRoster.roster].muted
                        ? _c(
                            "div",
                            { staticClass: "audio-mute" },
                            [
                              _c("svgicon", {
                                staticClass: "icon-mic",
                                attrs: { name: "no-micro", original: "" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vol-bars d-flex align-items-center justify-content-center"
                            },
                            [
                              _c("div", { staticClass: "content-vol-bar" }, [
                                _c("div", {
                                  staticClass: "vol-bar",
                                  style: {
                                    width: `${
                                      _vm.roster[_vm.currentRoster.roster]
                                        .volume <= 25
                                        ? _vm.roster[_vm.currentRoster.roster]
                                            .volume * 4
                                        : 100
                                    }%`
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content-vol-bar" }, [
                                _c("div", {
                                  staticClass: "vol-bar",
                                  style: {
                                    width: `${
                                      _vm.roster[_vm.currentRoster.roster]
                                        .volume <= 70 &&
                                      _vm.roster[_vm.currentRoster.roster]
                                        .volume > 30
                                        ? _vm.roster[_vm.currentRoster.roster]
                                            .volume * 1.33333
                                        : _vm.roster[_vm.currentRoster.roster]
                                            .volume <= 30
                                        ? 0
                                        : 100
                                    }%`
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content-vol-bar" }, [
                                _c("div", {
                                  staticClass: "vol-bar",
                                  style: {
                                    width: `${
                                      _vm.roster[_vm.currentRoster.roster]
                                        .volume > 75
                                        ? _vm.roster[_vm.currentRoster.roster]
                                            .volume
                                        : 0
                                    }%`
                                  }
                                })
                              ])
                            ]
                          )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-auto d-flex align-items-center pl-2 pr-1 user-info",
                        style: {
                          maxWidth: `calc(100% - 23px - 37px ${
                            _vm.currentRoster.type == "tutor" ? "- 50px" : ""
                          } )`
                        }
                      },
                      [
                        !_vm.roster[_vm.currentRoster.roster].sharing
                          ? _c(
                              "div",
                              {
                                staticClass: "v-user-photo",
                                style: {
                                  background: `url(${_vm.currentRoster.avatar}) center center / cover no-repeat`
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "handlePin",
                                      _vm.pin == _vm.currentRoster.roster
                                        ? null
                                        : _vm.currentRoster.roster
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "expanding-user",
                                    class: {
                                      "t-eu": _vm.permission == "tutor",
                                      "s-eu": _vm.permission == "student",
                                      active:
                                        _vm.pin == _vm.currentRoster.roster
                                    }
                                  },
                                  [
                                    _c("svgicon", {
                                      staticClass: "icon-pin",
                                      attrs: { name: "pin" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "msg-hover" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.pin == _vm.currentRoster.roster
                                        ? _vm.$t("room.header.pin.nf")
                                        : _vm.$t("room.header.pin.f")
                                    )
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "v-user-sharing",
                                class: {
                                  "t-eu": _vm.permission == "tutor",
                                  "s-eu": _vm.permission == "student"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "handlePin",
                                      _vm.pin == _vm.currentRoster.roster
                                        ? null
                                        : _vm.currentRoster.roster
                                    )
                                  }
                                }
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "icon-sharing",
                                  attrs: { name: "screen" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "expanding-user",
                                    class: {
                                      "t-eu": _vm.permission == "tutor",
                                      "s-eu": _vm.permission == "student",
                                      active:
                                        _vm.pin == _vm.currentRoster.roster
                                    }
                                  },
                                  [
                                    _c("svgicon", {
                                      staticClass: "icon-pin",
                                      attrs: { name: "pin" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "msg-hover" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.pin == _vm.currentRoster.roster
                                        ? _vm.$t("room.header.pin.nf")
                                        : _vm.$t("room.header.pin.f")
                                    )
                                  )
                                ])
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "user-info" },
                          [
                            _c("v-hover-txt", {
                              staticClass: "pl-2 w-100",
                              attrs: {
                                fontSize: "14px",
                                fontWeight: "400",
                                colorTxt: "#383838",
                                txt: _vm.currentRoster.name
                              }
                            }),
                            _vm._v(" "),
                            _vm.roster[_vm.currentRoster.roster].sharing
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "txt-sharing w-100 pl-2 d-block"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentRoster.id ==
                                          _vm.currentRoster.id
                                          ? _vm.$t(
                                              "room.header.sharingScreen.local"
                                            )
                                          : _vm.$t(
                                              "room.header.sharingScreen.remote"
                                            )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.roster[_vm.currentRoster.roster].sharing
                      ? _c("span", { staticClass: "col label-you pl-0 pr-1" }, [
                          _vm._v(_vm._s("(" + _vm.$t("room.camera.you") + ")"))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentRoster.type == "tutor"
                      ? _c(
                          "div",
                          {
                            staticClass: "col-auto pl-2 pr-2 ml-auto is-tutor"
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("room.header.tutor")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.remoteRosters, function(user) {
              return _c(
                "div",
                {
                  key: user.roster,
                  staticClass:
                    "v-room-user d-flex align-items-center pt-2 pb-2",
                  class:
                    user.id == _vm.currentRoster.id &&
                    _vm.roster[user.roster].sharing
                      ? "order-1"
                      : user.type == "tutor"
                      ? "order-2"
                      : "order-3"
                },
                [
                  _c("div", { staticClass: "col-auto pl-0 pr-0 mr-1" }, [
                    _vm.roster[user.roster].muted
                      ? _c(
                          "div",
                          { staticClass: "audio-mute" },
                          [
                            _c("svgicon", {
                              staticClass: "icon-mic",
                              attrs: { name: "no-micro", original: "" }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "vol-bars d-flex align-items-center justify-content-center"
                          },
                          [
                            _c("div", { staticClass: "content-vol-bar" }, [
                              _c("div", {
                                staticClass: "vol-bar",
                                style: {
                                  width: `${
                                    _vm.roster[user.roster].volume <= 25
                                      ? _vm.roster[user.roster].volume * 4
                                      : 100
                                  }%`
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "content-vol-bar" }, [
                              _c("div", {
                                staticClass: "vol-bar",
                                style: {
                                  width: `${
                                    _vm.roster[user.roster].volume <= 70 &&
                                    _vm.roster[user.roster].volume > 30
                                      ? _vm.roster[user.roster].volume * 1.33333
                                      : _vm.roster[user.roster].volume <= 30
                                      ? 0
                                      : 100
                                  }%`
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "content-vol-bar" }, [
                              _c("div", {
                                staticClass: "vol-bar",
                                style: {
                                  width: `${
                                    _vm.roster[user.roster].volume > 75
                                      ? _vm.roster[user.roster].volume
                                      : 0
                                  }%`
                                }
                              })
                            ])
                          ]
                        )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col d-flex align-items-center pl-2 pr-1 user-info",
                      style: {
                        maxWidth: `calc(100% - 23px ${
                          user.type == "tutor" ? "- 60px" : ""
                        } )`
                      }
                    },
                    [
                      !_vm.roster[user.roster].sharing
                        ? _c(
                            "div",
                            {
                              staticClass: "v-user-photo",
                              style: {
                                background: `url(${user.avatar}) center center / cover no-repeat`
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "handlePin",
                                    _vm.pin == user.roster ? null : user.roster
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "expanding-user",
                                  class: {
                                    "t-eu": _vm.permission == "tutor",
                                    "s-eu": _vm.permission == "student",
                                    active: _vm.pin == user.roster
                                  }
                                },
                                [
                                  _c("svgicon", {
                                    staticClass: "icon-pin",
                                    attrs: { name: "pin" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "msg-hover" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.pin == user.roster
                                      ? _vm.$t("room.header.pin.nf")
                                      : _vm.$t("room.header.pin.f")
                                  )
                                )
                              ])
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "v-user-sharing",
                              class: {
                                "t-eu": _vm.permission == "tutor",
                                "s-eu": _vm.permission == "student"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "handlePin",
                                    _vm.pin == user.roster ? null : user.roster
                                  )
                                }
                              }
                            },
                            [
                              _c("svgicon", {
                                staticClass: "icon-sharing",
                                attrs: { name: "screen" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "expanding-user",
                                  class: {
                                    "t-eu": _vm.permission == "tutor",
                                    "s-eu": _vm.permission == "student",
                                    active: _vm.pin == user.roster
                                  }
                                },
                                [
                                  _c("svgicon", {
                                    staticClass: "icon-pin",
                                    attrs: { name: "pin" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "msg-hover" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.pin == user.roster
                                      ? _vm.$t("room.header.pin.nf")
                                      : _vm.$t("room.header.pin.f")
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "user-info" },
                        [
                          _c("v-hover-txt", {
                            staticClass: "pl-2 w-100",
                            attrs: {
                              fontSize: "14px",
                              fontWeight: "400",
                              colorTxt: "#383838",
                              txt: user.name
                            }
                          }),
                          _vm._v(" "),
                          _vm.roster[user.roster].sharing
                            ? _c(
                                "span",
                                {
                                  staticClass: "txt-sharing w-100 pl-2 d-block"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      user.id == _vm.currentRoster.id
                                        ? _vm.$t(
                                            "room.header.sharingScreen.local"
                                          )
                                        : _vm.$t(
                                            "room.header.sharingScreen.remote"
                                          )
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  user.type == "tutor" && !_vm.roster[user.roster].sharing
                    ? _c(
                        "div",
                        { staticClass: "col-auto pl-2 pr-2 is-tutor" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("room.header.tutor")))
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }