/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye2': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 7',
    data: '<g data-name="Capa 2"><path pid="0" d="M12 3.3a.56.56 0 000-.08v-.06A7.88 7.88 0 006.1 0H6C4 0 2 1.07.12 3.19a.42.42 0 00-.12.3v.09a.46.46 0 00.11.22C2 5.92 4 7 6 7h.1a7.86 7.86 0 005.72-3.14.33.33 0 00.06-.1.41.41 0 00.12-.26.49.49 0 000-.2zM5.28 1.14a1 1 0 011 1 1 1 0 11-2 0 1 1 0 011-1zm.81 5H6c-1.69.02-3.39-.87-5-2.64a9.1 9.1 0 013-2.2 2.75 2.75 0 00-.51 1.6A2.66 2.66 0 006 5.6a2.66 2.66 0 002.61-2.7 2.73 2.73 0 00-.53-1.62 8.29 8.29 0 013 2.22 7 7 0 01-4.99 2.66z" data-name="Capa 1"/></g>'
  }
})
