/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jump2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 8a.5.5 0 00-.5.5v2.652A10.49 10.49 0 009.5 8a10.39 10.39 0 00-4.77 1.144 10.597 10.597 0 00-3.63 3.055.5.5 0 00.799.6A9.426 9.426 0 019.499 9a9.49 9.49 0 016.928 3h-2.928a.5.5 0 000 1h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5z"/><path pid="1" d="M9 15c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z"/>'
  }
})
