/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'barcode3': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 3h1v12H0V3zM16 3h.5v11H16V3zM3.5 3H4v11h-.5V3zM12 3h1v11h-1V3zM2 3h1v11H2V3zM7.5 3H8v11h-.5V3zM6 3h1v11H6V3zM19.5 3h.5v12h-.5V3zM17 3h1v11h-1V3zM8.5 3H9v11h-.5V3zM10 3h1v11h-1V3zM3.5 14.5v.5h1v.5h-1V17H5v-.5H4V16h1v-1.5zM2 14.5h.5V17H2v-2.5zM6 14.5v.5h1v.5H6V17h1.5v-.5h-1V16h1v-1.5zM9.5 14.5v1H9v-1h-.5V16h1v1h.5v-2.5zM11 14.5v.5h1v.5h-1v.5h1v.5h-1v.5h1.5v-2.5zM15 15v-.5h-1.5V16h1v.5h-1v.5H15v-1.5h-1V15zM16 14.5h.5V17H16v-2.5zM19 15v-.5h-1.5V17H19v-1.5h-1V15h1zm-.5 1v.5H18V16h.5z"/>'
  }
})
