/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stars2': {
    width: 37,
    height: 47,
    viewBox: '0 0 37 47',
    data: '<g _fill="#F5A623" fill-rule="nonzero"><path pid="0" d="M18.5 11l6.166 11.218L37 24.75l-8.523 9.466L29.934 47 18.5 41.633 7.066 47l1.457-12.783L0 24.75l12.334-2.533z"/><path pid="1" fill-opacity=".4" d="M29.5 8l1.833 3.428 3.667.774-2.534 2.892L32.9 19 29.5 17.36 26.1 19l.434-3.906L24 12.202l3.667-.774z"/><path pid="2" fill-opacity=".3" d="M22 1l1 1.87 2 .422-1.382 1.577L23.854 7 22 6.105 20.146 7l.236-2.13L19 3.291l2-.422z"/><path pid="3" fill-opacity=".2" d="M29 0l.333.623.667.14-.46.527.078.71L29 1.702 28.382 2l.079-.71L28 .764l.667-.14z"/></g>'
  }
})
