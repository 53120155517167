/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat': {
    width: 23,
    height: 20,
    viewBox: '0 0 23 20',
    data: '<g _fill="#383838" fill-rule="nonzero"><path pid="0" d="M5.48 5.435h11.594v1.087H5.48zM17.086 9.602l-.002-1.087H5.464l.002 1.087z"/><path pid="1" d="M22.023 0H.533A.537.537 0 000 .547v13.97c0 .3.232.565.532.565h5.469l.008 3.767a.554.554 0 00.857.444l5.993-4.211h9.164c.3 0 .532-.265.532-.565V.547A.537.537 0 0022.023 0zm-.555 13.995h-8.781a.534.534 0 00-.313.088l-5.28 3.714-.008-3.268a.537.537 0 00-.543-.534H1.087V1.087h20.38v12.908z"/></g>'
  }
})
