/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right2': {
    width: 16,
    height: 16,
    viewBox: '0 0 20.42 16.4',
    data: '<g data-name="Capa 2"><g data-name="Capa 1"><path pid="0" d="M0 9.56h15.77l-4.48 4.49a1.37 1.37 0 00-.37 1.33 1.36 1.36 0 002.3.6L20 9.16a1.37 1.37 0 000-1.93L13.22.42a1.36 1.36 0 00-2.3.6 1.35 1.35 0 00.37 1.32l4.48 4.49H0z" data-name="noun Enter 3878779"/></g></g>'
  }
})
