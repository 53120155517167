/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 10H10V1.5a.5.5 0 00-1 0V10H.5a.5.5 0 000 1H9v8.5a.5.5 0 001 0V11h8.5a.5.5 0 000-1z"/>'
  }
})
