
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Getter, Mutation } from 'vuex-class';

	@Component
	export default class VideoOnboarding extends Vue{
		@Prop({ type: Boolean, default: false }) readonly loading!: string;
        @Prop({ type: String, default: '' }) readonly deviceId!: string;
		@Prop({ type: Number, default: null }) readonly camMsg!: boolean | null;
        @Prop({ type: Boolean, default: true }) readonly showActions!: boolean;
        @Prop({ type: Boolean, default: false }) readonly ready!: boolean;
		@Prop({ type: String, default: '#000' }) readonly bgColor!: String;
		@Prop({default: null }) readonly stream!: any;

		@Prop({ type: Object, default: null }) readonly mediaOpc!: any;

		/*-- ========== Datas ========= */
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour;
        @State( state => state.devices.cameras ) cameras
        @State( state => state.typePermission ) typePermission // Enum
        @State( state => state.permissions.cam ) permissionCam
		@Getter('audioVideo') audioVideo

		@Mutation('handlePermissions') handlePermissions

		/* ========Computed======= */
		get isVideoMute(){
			if (this.mediaOpc)
				return this.mediaOpc.muteCam;
			else
			    return false;
		}
		get isAudioMute(){
			if (this.mediaOpc)
				return this.mediaOpc.muteMic;
			else
				return false;
		}
		get volume(){
			if (this.mediaOpc && !this.isAudioMute)
				return this.mediaOpc.volume;
			else
				return 0;
		}
		get refs(){
            return(this as any).$refs;
        }
        get emit(){
            return(this as any).$emit;
        }

		async mounted(){
			if(!this.showActions && this.stream){
            	this.initCloneVideo();
			}
            else
		        (this as any).$watch('deviceId', async (newVal: string, oldVal: string) => {
		    	    if(this.loading){
		    		    if(this.showActions){
		    		        console.log('video input device is changed');
		    		        try {
		    			        await this.openVideoInputFromSelection(this.deviceId);
		    			        if (this.permissionCam == this.typePermission.denied) {
		    				        this.emit('handleCam');
		    			        }
		    		        } catch (err) {
		    			        console.log('no video input device selected');
		    		        }
		    	        }
		    		    this.startVideoPreview();
						this.emit('handleStream');
		            }
		        })
		}

		beforeDestroy(){
			if(this.showActions)
			    this.openVideoInputFromSelection(null);
			else if(this.refs.videoElement){
				this.endCloneVideo();
			}
		}

        async openVideoInputFromSelection(deviceId: string | null): Promise<void>{ // Change video device
            console.log(`Switching to: ${deviceId}`);
            if (deviceId === null) {
            	console.log('Stopping video preview');
                this.audioVideo.stopVideoPreviewForVideoInput(this.refs.videoElement as HTMLVideoElement);
            }
            try {
                if (deviceId != '') {
					if(deviceId != null)
                        this.handlePermissions({ cam: this.cameras.length>0?this.typePermission.granted:this.typePermission.deviceEmpty });
                    await this.audioVideo.chooseVideoInputDevice(deviceId);
                }
                else{
                    await this.audioVideo.chooseVideoInputDevice(null);
                } 
                this.emit('resetVideoSettings');
            } catch (e: any) {
                console.log(`failed to chooseVideoInputDevice ${deviceId}`, e.cause.name, e.cause.message);  
                if (e.cause.name == 'PermissionDeniedError' || (e.cause.name == 'NotAllowedError' && e.cause.message != 'Permission denied by system') || e.cause.name == 'TypeError') {
                    this.handlePermissions({ cam: this.typePermission.denied });
                    console.log('Permission denied', e);
                }
                else if((e.cause.name == 'NotAllowedError' && e.cause.message == 'Permission denied by system') || e.cause.name == 'NotFoundError' || e.cause.name == 'DevicesNotFoundError'){
                    this.handlePermissions({ cam: this.typePermission.errorSystem });
                    this.emit('handleCam');
                }
                else{
                    this.handlePermissions({ cam: this.typePermission.errorDevice });
                    this.emit('handleCam');
                    console.log('Not Readable', e);
                }
            }
        }

		initCloneVideo(): void{
			console.log('clone video');
			const clonedStream = this.stream.clone();
            
			this.refs.videoElement.srcObject = clonedStream;
            this.refs.videoElement.play().catch(() => {});
		}

		async endCloneVideo(): Promise<void>{
			console.log('Stop clone video');
			let video = this.refs.videoElement.srcObject;
			if(video){
				await this.refs.videoElement.srcObject.getTracks().forEach(track => {
					track.stop();
				});
				
				video = null;
			}
		}

		startVideoPreview(): void{
			if (this.audioVideo && this.deviceId) {
				console.log('Starting video preview');
				this.audioVideo.startVideoPreviewForVideoInput( this.refs.videoElement as HTMLVideoElement );
			}
		}

		@Watch('loading')
		async init(band: Boolean){
            if(band)
            	this.startVideoPreview();
		}

		@Watch('stream')
		handleStream(newVal: any){
			if(this.refs.videoElement){
				this.endCloneVideo();
			}

			if(!this.showActions && newVal){
            	this.initCloneVideo();
			}
		}

	}

