/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal-0': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 19h-2a.5.5 0 010-1h2a.5.5 0 010 1zM14.5 19h-2a.5.5 0 010-1h2a.5.5 0 010 1zM10.5 19h-2a.5.5 0 010-1h2a.5.5 0 010 1zM6.5 19h-2a.5.5 0 010-1h2a.5.5 0 010 1zM2.5 19h-2a.5.5 0 010-1h2a.5.5 0 010 1z"/>'
  }
})
