/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enter-right': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10.646 7.146a.5.5 0 000 .707l2.146 2.146H.499a.5.5 0 000 1h12.293l-2.146 2.146a.5.5 0 00.707.707l3-3a.498.498 0 000-.708l-3-3a.5.5 0 00-.707 0z"/><path pid="1" d="M19 4.5v12c0 .827-.673 1.5-1.5 1.5h-10c-.827 0-1.5-.673-1.5-1.5v-4a.5.5 0 011 0v4a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v4a.5.5 0 01-1 0v-4C6 3.673 6.673 3 7.5 3h10c.827 0 1.5.673 1.5 1.5z"/>'
  }
})
