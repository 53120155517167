/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play2': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M12.717 9.232L2.244 15.21A1.5 1.5 0 010 13.906V1.952A1.5 1.5 0 012.244.65l10.473 5.977a1.5 1.5 0 010 2.605z" _fill="#000" fill-rule="evenodd"/>'
  }
})
