var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "error-403 d-flex flex-column align-items-center justify-content-center",
      attrs: { id: "error" }
    },
    [
      _c(
        "h2",
        {
          staticClass: "txt-error mb-0",
          class: {
            "color-tutor-main": _vm.auth.permission === "tutor",
            "color-student-main": _vm.auth.permission !== "tutor"
          }
        },
        [_vm._v(_vm._s(_vm.$t("403.error")))]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "descrip-error mb-0" }, [
        _vm._v(_vm._s(_vm.$t("403.msg")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "circles-error mb-4 mb-5 w-100 d-flex justify-content-center"
        },
        [
          _c("div", { staticClass: "circle-transform" }, [
            _c("div", { staticClass: "content-circle" }, [
              _c("div", {
                staticClass: "circle",
                class: {
                  "bgColor-tutor-main": _vm.auth.permission === "tutor",
                  "bgColor-student-main": _vm.auth.permission !== "tutor"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-circle" }, [
              _c("div", {
                staticClass: "circle",
                class: {
                  "bgColor-tutor-light": _vm.auth.permission === "tutor",
                  "bgColor-student-light": _vm.auth.permission !== "tutor"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-circle" }, [
              _c("div", {
                staticClass: "circle",
                class: {
                  "bgColor-tutor-light": _vm.auth.permission === "tutor",
                  "bgColor-student-light": _vm.auth.permission !== "tutor"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-circle" }, [
              _c("div", {
                staticClass: "circle",
                class: {
                  "bgColor-tutor-main": _vm.auth.permission === "tutor",
                  "bgColor-student-main": _vm.auth.permission !== "tutor"
                }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("v-btn", {
        attrs: { txt: _vm.$t("403.btn"), min: "180px", height: "32px" },
        nativeOn: {
          click: function($event) {
            return _vm.$router.push({
              name: "dashboard-" + _vm.auth.permission
            })
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }