<template>
	<div class="confirm-modal" :class="{ active : show }" v-if="modalVal.modal=='v-modal-alert'">
		<div class="blurColor" @click="close()"></div>
		<div class="v-content">
			<h4 class="title bgColor-yellow-main pt-4 pb-3 pl-5 pr-5 mb-0">
				{{ $t(`modals.${modalVal.type}.t`) }}
				<svgicon class="btn-close" name="cross2" @click="close()"/>
			</h4>
			<div class="text-center pt-3 pb-4 pl-4 pr-4">
				<svgicon class="icon mb-3" name="notification" color="#F5A623"/>
                <p class="alert-legend w-100 mb-4">{{ $t(`modals.${modalVal.type}.msg`) }}</p>
			</div>
		</div>
	</div>
</template>
<script>
    import { modal } from '../../mixins/index.js';
	import { mapMutations, mapGetters } from 'vuex';
	export default{
        mixins: [ modal ],
        computed:{
            ...mapGetters(['modalVal'])
        },
        methods:{
        	...mapMutations(['setModal']),
            afterClose(){
                this.setModal('');
            }
        }
	}
</script>