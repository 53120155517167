<template>
	<component class="v-dropdown nav-link position-relative" :class="{ arrow : statusArrow && (show == 'focus' || show == 'hover') && statusSlot  }" @mouseover="showHover()" @mouseleave="statusHover = false" :is="tag" @click="toggleAction()" >
		<input type="text" ref="input" @focus="show = statusSlot?'focus':'blur'" @blur="blurAction()" readonly>
		<slot name="content"></slot>
		<div class="v-dropdown-hover" v-if="show == 'focus' || show == 'hover' || show == 'click'"></div>
		<transition name="dropdown" >
			<div class="v-dropdown-list" key="hover" :style="{ left: '50%' }" @mouseover="hoverAction()" @mouseleave="leaveAction()" v-if="show == 'blur' && statusHover">
				<div class="v-dropdown-text-hover">
				    {{ txtHover }}
			    </div>
		    </div>
		    <v-event-listener class="v-dropdown-list" key="dropdown" :style="{ [side]: 0 }" @mouseover.native="hoverAction()" @mouseleave.native="leaveAction()" v-else-if="(show == 'focus' || show == 'hover') && statusSlot" @close="doSomethink">
		    	<slot name="dropdown">
		    		<ul class="submenu" >
		    			<li class="link-submenu" v-for="(item,i) in submenu" :class="{ disabled: item.disabled }" :key="`sub-${i}`" @click="!item.disabled?doSomethink(item):null" :style="item.icon?'padding: 12px 15px 12px 50px':''">
		    				<svgicon class="icon" original :name="item.icon" v-if="item.icon" />
		    				<span class="d-block" v-html="item[val]"></span>
		    				<span class="d-block sub" v-html="item[sub]" v-if="item[sub]"></span>
		    			</li>
		    		</ul>
		    	</slot>
		    </v-event-listener>
		</transition>
	</component>
</template>
<script>
	export default{
		props: {
			submenu: { type: Array, required: false },
			side: { type: String, default: 'right', required: false },
			val: { type: String, default: 'txt' },
			sub: { type: String, default: 'sub', required: false },
			tag: { type: String, default: 'li', required: false },
			txtHover: { type: String, default: null, required: false },
			statusSlot: { type: Boolean, default: true, required: false },
			statusArrow: { type: Boolean, default: false, required: false }, 
		},
		data(){
			return{
				statusHover: false,
				show: 'blur' // blur, focus, hover, click
			}
		},
		methods:{
			doSomethink(item){
				this.show = 'click';
				if(this.statusSlot)
				    this.statusHover = false;

				if (item.action)
					item.action();
				else
					this.$emit('input',item);
			},
			showHover(){
                if(this.show == 'blur' && this.txtHover)
                	this.statusHover = true;
			},
			toggleAction(){
				if(this.statusSlot)
				    this.statusHover = false;
				if (this.show == 'blur')
					this.focusAction();
				else
					this.blurAction();
            },
            focusAction(){
            	if (this.show == 'blur' || this.show == 'focus')
            		this.$refs.input.focus();
            },
            blurAction(){
            	if (this.show != 'hover') 
            		this.show = 'blur';
            },
            hoverAction(){
            	if (this.show == 'focus')
            		this.show = 'hover';
            },
            leaveAction(){
            	if (this.show == 'hover') {
            		this.show = 'focus'; 
            		this.focusAction();
            	}
            }
		},
		watch: {
			show: function(newVal){
				if(newVal == 'focus' || newVal == 'hover')
				   this.$emit('handleDropdown', true);
				else
				this.$emit('handleDropdown', false);
			}
		}
	}
</script>