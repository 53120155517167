
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Getter, Mutation, Action } from 'vuex-class';
	import { AudioInputDevice, RemovableAnalyserNode } from 'amazon-chime-sdk-js';

    @Component
    export default class RoomOnboarding extends Vue{
        @Prop({ type: Object }) readonly options!: any;
    	/*-- ========== Datas ========= */
        imReady: boolean = false;
        deviceAlerts: any;
        config: any;
    	volume: number = 0;
    	analyserNode: RemovableAnalyserNode | undefined;
    	statusModalSettings: boolean = false;
        videoSettings: number = 0;
        joinToRoom: boolean = false;
        videoStream: any;

    	private analyserNodeCallback: undefined | (() => void);
        /* =========== State and getters Vuex ========= */
        /* State */
		@State( state => state.auth.institution ) institution;
        @State( state => state.auth.permission ) userType;
		@State('meetingSession') meetingSession
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour
        @State( state => state.devices ) devices
        @State( state => state.permissions.cam ) permissionCam
        @State( state => state.typePermission ) typePermission // Enum
        @State( state => state.currentCam?.deviceId ) currentCam
        @State( 'currentVideoQuality' ) currentVideoQuality
        @State( state => state.currentMic?.deviceId ) currentMic
        @State('enableVoiceFocus') enableVoiceFocus
        @State('voiceFocusDevice') voiceFocusDevice
        @State('enableWebAudio') enableWebAudio;
        /* Getter */
        @Getter('audioVideo') audioVideo
        /* Mutation */
        @Mutation('handlePermissions') handlePermissions
        @Mutation('handleAudioInputDevice') handleAudioInputDevice
        @Mutation('cleanRoomDatas') cleanDatas
        @Mutation('handleVoiceFocus') handleVF;
        /* Action */
        @Action('audioInputSelectionWithOptionalVoiceFocus') audioInputSelectionWithOptionalVoiceFocus

        /* ========== Computed ========= */
        /* Libraries */
        get t(){
        	return(this as any).$t;
        }
        get refs(){
            return(this as any).$refs;
        }

        /* General */
        get lesson(): any{
            return this.options.lesson;
        }
        get muteCam(): boolean{
            return this.options.muteCam;
        }
        get muteMic(): boolean{
            return this.options.muteMic;
        }
        get loading(): boolean{
            return this.options.loading && this.imReady;
        }

        get lessonPhotoOpc(): object{
            return this.options.lesson?{ size: '65px', photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type }:{};
        }
        get name(): string{
            if (this.userType == 'student')
                return this.lesson?.tutor;
            else{
                if (this.lesson?.type == 'Grupal')
                    return this.t('groupLarge');
                else
                    return this.lesson?.student;
            }
        }
        get tagTypeOpc(): object{
            if (this.lesson?.type == 'Grupal')
                return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
            else
                return {};  
        }
        
        /* Vue hooks */
        created(): void{
            this.staticVars();
            if(this.options.loading)
                this.init();
        }

        async beforeDestroy(){
            if (this.meetingSession) {
                await this.endOnboarding();
            }

            if (!this.joinToRoom)
                this.cleanDatas();
        }

        /*-- ========== room functions ========= */
        private staticVars(): void{
            const { DeviceAlerts, config } = this.options;
            this.deviceAlerts = DeviceAlerts;
            this.config = config;
        }

        async init(): Promise<void>{
            if (this.refs.camera) {
                await this.openAudioInputFromSelectionAndPreview(this.currentMic);
                this.$emit('handleVideoQuality', this.currentVideoQuality);
                await this.refs.camera.openVideoInputFromSelection(this.currentCam);
                const { config } = this.options;
                if(typeof config?.microphone?.AVF == "boolean")
                    this.handleVF(config.microphone.AVF);
                else if(this.enableWebAudio)
                    this.handleVF(true);
                
                this.$emit('handleDevice');  
                this.imReady = true;
            } 
        }

        async joinRoom(): Promise<void> {
            this.joinToRoom = true;
            this.$emit('joinRoom');
        }

        handleModalSettings(band: boolean): void{
            this.handleStream();
            this.statusModalSettings = band;
        }

        /* End chime */
        async endOnboarding(): Promise<void>{
            console.log('left onboarding');
            // Stop the volume.
            this.stopAudioPreview();

            // Stop watching device changes in the UI.
            if (!this.joinToRoom) this.audioVideo.removeDeviceChangeObserver(this);

            // Drop the audio output.
            //if (!this.joinToRoom) await this.openAudioOutputFromSelection(null);

            // Stop Voice Focus.
            if (!this.joinToRoom) await this.voiceFocusDevice?.stop();

            // Drop the input device.
            if (this.currentMic != '' && !this.joinToRoom) await this.selectAudioInputDevice(null);
        }

        /* Audio input */
        async handleAudioInput(){
            if(this.muteMic)
                await this.openAudioInputFromSelectionAndPreview(this.muteMic?this.currentMic:null);
            this.$emit('handleDevice', 'mic');
        }

        async openAudioInputFromSelectionAndPreview(deviceId: string | null): Promise<void> {
            await this.stopAudioPreview();
            await this.openAudioInputFromSelection(deviceId);
            console.log('Starting audio preview.');
            await this.startAudioPreview();
        }

        async openAudioInputFromSelection(deviceId: string | null): Promise<void> {
            let device = deviceId;
            if (device != '')
                device = await this.audioInputSelectionWithOptionalVoiceFocus(deviceId);
            await this.selectAudioInputDevice(device);
        }

        async selectAudioInputDevice(device: AudioInputDevice): Promise<void>{ // Change audio device
            if (!this.joinToRoom) this.handleAudioInputDevice(device);
            console.log('Selecting audio input', device);
            try {
                if (device != '') {
                    this.handlePermissions({ mic: this.devices.microphones.length>0?this.typePermission.granted:this.typePermission.deviceEmpty });
                    await this.audioVideo.chooseAudioInputDevice(device);
                    this.startAudioPreview();
                }
                else{
                    await this.audioVideo.chooseAudioInputDevice(null);
                } 
            } catch (e: any) {
                console.log(`failed to choose audio input device ${device}`, e);
                if (e.cause.name == 'PermissionDeniedError' || (e.cause.name == 'NotAllowedError' && e.cause.message != 'Permission denied by system') || e.cause.name == 'TypeError') {
                    this.handlePermissions({ mic: this.typePermission.denied });
                    console.log('Permission denied', e);
                }
                else if((e.cause.name == 'NotAllowedError' && e.cause.message == 'Permission denied by system') || e.cause.name == 'NotFoundError' || e.cause.name == 'DevicesNotFoundError'){
                    this.handlePermissions({ mic: this.typePermission.errorSystem });  
                }
                else{
                    this.handlePermissions({ mic: this.typePermission.errorDevice });
                    console.log('Not Readable', e);
                }
            }
        }

        startAudioPreview(): void {  
            this.volume = 0;

            // Recreate.
            if (this.analyserNode) {
                // Disconnect the analyser node from its inputs and outputs.
                this.analyserNode.disconnect();
                this.analyserNode.removeOriginalInputs();
                this.analyserNode = undefined;
            }

            const analyserNode = this.audioVideo.createAnalyserNodeForAudioInput();

            if (!analyserNode)
                return;

            if(!analyserNode.getByteTimeDomainData)
                return;

            this.analyserNode = analyserNode;
            const data = new Uint8Array(analyserNode.fftSize);
            let frameIndex = 0;
            this.analyserNodeCallback = () => {
                if (frameIndex === 0) {
                    analyserNode.getByteTimeDomainData(data);
                    const lowest = 0.01;
                    let max = lowest;
                    for (const f of data) {
                        max = Math.max(max, (f - 128) / 128);
                    }
                    let normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
                    this.volume = Math.min(Math.max(normalized * 100, 0), 100);
                }
                frameIndex = (frameIndex + 1) % 2;
                if (this.analyserNodeCallback) {
                    requestAnimationFrame(this.analyserNodeCallback);
                }
            };
            requestAnimationFrame(this.analyserNodeCallback);
        }

        async stopAudioPreview(): Promise<void> {
            console.log('Stopping audio preview.');
            if (!this.analyserNode) {
                return;
            }

            this.analyserNodeCallback = undefined;

            // Disconnect the analyser node from its inputs and outputs.
            this.analyserNode.disconnect();
            this.analyserNode.removeOriginalInputs();

            this.analyserNode = undefined;
        }

        /* Video */
        resetVideoSettings():void{
            if (this.statusModalSettings)
                this.videoSettings += 1;
        }

        handleStream(): void{
            const v = this.refs.camera.$el.querySelector('video');
            this.videoStream = v.srcObject;
            this.resetVideoSettings();
        }

        @Watch('enableVoiceFocus')
        async handleVoiceFocus(newVal: boolean){
            if (this.loading){
                console.log('[DEMO] Amazon Voice Focus setting toggled to', newVal);
                this.openAudioInputFromSelectionAndPreview(this.currentMic);
            }
        }

        @Watch('muteMic')
        handleMic2(newVal: string | null){
            if (newVal)
                this.openAudioInputFromSelectionAndPreview(null);
        }

        @Watch('currentMic')
        handleMic(newVal: string | null){
            if (newVal && this.loading)
                this.openAudioInputFromSelectionAndPreview(newVal);
        }

        @Watch('muteCam')
        handleVideoStream(newVal: boolean){
            if(this.statusModalSettings && newVal){
                this.videoStream = null;
            }
        }

        @Watch('currentCam')
        handleVideoStream2(newVal: any){
            if(this.statusModalSettings){
                const v = this.refs.camera.$el.querySelector('video');
                this.videoStream = v.srcObject;
            }
        }

        @Watch('currentVideoQuality')
        async videoQualityChange(newVal: string): Promise<void>{
            if (this.loading){
                this.$emit('handleVideoQuality', newVal);
                await this.refs.video.endCloneVideo();

                if (!this.muteCam){
                    await this.refs.camera.openVideoInputFromSelection(this.currentCam, true);
                    await this.refs.camera.startVideoPreview();
                    this.handleStream();
                }
            }
        }

        @Watch('options.loading')
        handleView(newVal){
            if(newVal)
                this.init();
        }
    }
