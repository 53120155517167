import Vue from 'vue';
import Router from 'vue-router';
import store from './store'

Vue.use(Router);

// Init forms
import institutionalEmail from './pages/init-forms/institutional-email.vue';
import confirmInstitutionalEmail from './pages/init-forms/confirm-institutional-email.vue';
import institutionalEmailSuccess from './pages/init-forms/institutional-email-success.vue';
import login from './pages/init-forms/login.vue';
import sightUp from './pages/init-forms/sign-up.vue';
import recoverPassword from './pages/init-forms/recover-password.vue';
import resetPassword from './pages/init-forms/reset-password.vue';
import resetPasswordVerifyEmail from './pages/init-forms/reset-password-verify-email.vue';
import resetPasswordSuccess from './pages/init-forms/reset-password-success.vue';

// General
import notifications from './pages/notifications.vue';
import error403 from './pages/403.vue';
import error404 from './pages/404.vue';
import format from './pages/formats/index.vue';
import room from './pages/room/index.vue';

// Students
import dashboardStudent from './pages/student/dashboard.vue';
import manageStudent from './pages/student/lessons/manage.vue';
import historyStudent from './pages/student/lessons/history.vue';
import calendardStudent from './pages/student/lessons/calendar.vue';
import infoStudent from './pages/student/account-settings/account-details.vue';
import passwordStudent from './pages/student/account-settings/change-password.vue';
import preferencesStudent from './pages/student/account-settings/notification-preferences.vue';
import chatStudent from './pages/student/chat.vue';
import appStudent from './pages/student/get-the-app.vue';
import scheduleStudent from './pages/student/schedule-lesson.vue';
import tutorProfileStudent from './pages/student/tutor-profile.vue';
import resultsStudent from './pages/student/search-results/index.vue';
import alertStudent from './pages/student/search-results/msg-success.vue';

// Tutor
import dashboardTutor from './pages/tutor/dashboard.vue';
import profileTutor from './pages/tutor/profile.vue';
import manageTutor from './pages/tutor/lessons/manage.vue';
import historyTutor from './pages/tutor/lessons/history.vue';
import calendarTutor from './pages/tutor/lessons/calendar.vue';
import infoTutor from './pages/tutor/account-settings/account-details.vue';
import passwordTutor from './pages/tutor/account-settings/change-password.vue';
import preferencesTutor from './pages/tutor/account-settings/notification-preferences.vue';
import subjectsTutor from './pages/tutor/tutoring-preferences/subjects.vue';
import biographyTutor from './pages/tutor/tutoring-preferences/biografhy.vue';
import availabilityTutor from './pages/tutor/tutoring-preferences/availability.vue';
import chatTutor from './pages/tutor/chat.vue';
import appTutor from './pages/tutor/get-the-app.vue';
import scheduleSession from './pages/tutor/schedule-session.vue';

const router = new Router({
    mode: 'history', //URLS without #	
    base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            redirect: { name : '404' }
        },
        { 
            path: '/', 
            name: 'email-validatation',
            component: institutionalEmail,
            meta: {
                emailValidation: false
            },
        },
        { 
            path: '/login', 
            name: 'login',
            component: login,
            meta: {
                title: 'title.login',
                emailValidation: true
            },
        },
        { 
            path: '/sign-up', 
            name: 'sign-up',
            component: sightUp,
            meta: {
                title: 'title.sightUp',
                emailValidation: true
            },
        },
        { 
            path: '/password-recovery', 
            name: 'recovery-password',
            component: recoverPassword,
            meta: {
                title: 'title.recoveryPassword',
                emailValidation: true
            },
        },
        { 
            path: '/confirm', 
            name: 'confirm-email',
            component: confirmInstitutionalEmail,
            meta: {
                title: 'title.confirmEmail',
                emailValidation: true
            },
        },
        { 
            path: '/reset-password', 
            name: 'confirm-password',
            component: resetPasswordVerifyEmail,
            meta: {
                title: 'title.confirmEmail',
                emailValidation: true
            },
        },
        { 
            path: '/confirm/:token', 
            name: 'confirm-token-email',
            component: institutionalEmailSuccess,
            meta: {
                title: 'title.confirmSucces',
                emailValidation: false,
                validateToken: 'email'
            },
        },
        { 
            path: '/reset-password/:token', 
            name: 'reset-password',
            component: resetPassword,
            meta: {
                title: 'title.resetPassword',
                emailValidation: false,
                validateToken: 'password'
            }
        },
        { 
            path: '/confirm-password', 
            name: 'confirm-token-password',
            component: resetPasswordSuccess,
            meta: {
                title: 'title.confirmSucces',
                emailValidation: true,
            },
        },
        { 
            path: '/404', 
            name: '404',
            component: error404,
            meta: {
                title: 'title.404',
                error: true,
                emailValidation: false
            }
        },
        /* =============== routes student ============= */
        { 
            path: '/student/403', 
            name: '403-student',
            component: error403,
            meta: {
                title: 'title.403',
                permission: 'student',
                error: true
            },
        },
        { 
            path: '/student/404', 
            name: '404-student',
            component: error404,
            meta: {
                title: 'title.404',
                permission: 'student',
                error: true
            },
        },
        {
            path: '/student',
            redirect: { name : 'dashboard-student' }
        },
        {
            path: '/student/dashboard',
            component: dashboardStudent,
            name: 'dashboard-student',
            meta: {
                permission: 'student',
                title: 'title.dashboard'
            },

        },
        {
            path: '/student/lessons',
            name: 'lessons-student',
            component: manageStudent,
            meta: {
                permission: 'student',
                title: 'title.lessons'
            }
        },
        {
            path: '/student/history',
            name: 'history-student',
            component: historyStudent,
            meta: {
                permission: 'student',
                title: 'title.history'
            }
        },
        {
            path: '/student/calendar',
            name: 'calendar-student',
            component: calendardStudent,
            meta: {
                permission: 'student',
                title: 'title.calendar'
            }
        },
        {
            path: '/student/settings/info',
            name: 'info-student',
            component: infoStudent,
            meta: {
                permission: 'student',
                title: 'title.acount'
            }
        },
        {
            path: '/student/settings/password',
            name: 'password-student',
            component: passwordStudent,
            meta: {
                permission: 'student',
                title: 'title.changePassword'
            }
        },
        {
            path: '/student/settings/preferences',
            name: 'preferences-student',
            component: preferencesStudent,
            meta: {
                permission: 'student',
                title: 'title.preferences'
            }
        },
        {
            path: '/student/messaging',
            name: 'chat-student',
            component: chatStudent,
            meta: {
                permission: 'student',
                title: 'title.chat'
            }
        },
        {
            path: '/student/messaging/:idlesson',
            name: 'chat-student-id',
            component: chatStudent,
            meta: {
                permission: 'student',
                title: 'title.chat'
            }
        },
        {
            path: '/student/linc-app',
            name: 'app-student',
            component: appStudent,
            meta: {
                permission: 'student',
                title: 'title.app'
            }
        },
        {
            path: '/student/schedule-lesson',
            name: 'schedule-student',
            component: scheduleStudent,
            meta: {
                permission: 'student',
                title: 'title.schedule'
            }
        },
        {
            path: '/student/tutor/:tutor',
            name: 'tutorProfile-student',
            component: tutorProfileStudent,
            meta: {
                permission: 'student',
                compactMenu: true
            }
        },
        {
            path: '/student/search/:modality/:subject',
            name: 'results-student',
            component: resultsStudent,
            meta: {
                permission: 'student',
                title: 'title.search'
            }
        },
        {
            path: '/student/search/:subject',
            name: 'results-student2',
            component: resultsStudent,
            meta: {
                permission: 'student',
                title: 'title.search'
            }
        },
        {
            path: '/student/lesson/request',
            name: 'request-student',
            component: alertStudent,
            meta: {
                permission: 'student',
                title: 'title.schedule'
            }
        },
        { 
            path: '/student/room/:roomId', 
            name: 'room-student',
            component: room,
            meta: {
                title: 'title.virtualRoom',
                permission: 'student',
                classRoom: true
            }
        },
        {
            path: '/student/notifications', 
            name: 'notifications-student',
            component: notifications,
            meta: {
                title: 'title.notifications',
                permission: 'student',
            }
        },
        {   path: '/student/formats',
            name: 'formats-student',
            component: format,
            meta:{
                title: 'title.formats',
                permission: 'student'
            }
        },
        /* =============== routes tutor ============= */
        { 
            path: '/tutor/403', 
            name: '403-tutor',
            component: error403,
            meta: {
                title: 'title.403',
                permission: 'tutor',
                error: true
            },
        },
        { 
            path: '/tutor/404', 
            name: '404-tutor',
            component: error404,
            meta: {
                title: 'title.404',
                permission: 'tutor',
                error: true
            },
        },
        {
            path: '/tutor',
            redirect: { name : 'dashboard-tutor' }
        },
        { 
            path: '/tutor/dashboard', 
            name: 'dashboard-tutor',
            component: dashboardTutor,
            meta: {
                permission: 'tutor',
                title: 'title.dashboard'
            }
        },
        { 
            path: '/tutor/profile', 
            name: 'profile-tutor',
            component: profileTutor,
            meta: {
                permission: 'tutor',
                title: 'title.profiletTutor'
            }
        },
        { 
            path: '/tutor/lessons', 
            name: 'lessons-tutor',
            component: manageTutor,
            meta: {
                permission: 'tutor',
                title: 'title.lessons'
            }
        },
        { 
            path: '/tutor/history', 
            name: 'history-tutor',
            component: historyTutor,
            meta: {
                permission: 'tutor',
                title: 'title.history'
            }
        },
        { 
            path: '/tutor/calendar', 
            name: 'calendar-tutor',
            component: calendarTutor,
            meta: {
                permission: 'tutor',
                title: 'title.calendar'
            }
        },
        { 
            path: '/tutor/settings/info', 
            name: 'info-tutor',
            component: infoTutor,
            meta: {
                permission: 'tutor',
                title: 'title.acount'
            }
        },
        { 
            path: '/tutor/settings/password', 
            name: 'password-tutor',
            component: passwordTutor,
            meta: {
                permission: 'tutor',
                title: 'title.changePassword'
            }
        },
        { 
            path: '/tutor/settings/preferences', 
            name: 'preferences-tutor',
            component: preferencesTutor,
            meta: {
                permission: 'tutor',
                title: 'title.preferences'
            }
        },
        { 
            path: '/tutor/subjects', 
            name: 'subjects-tutor',
            component: subjectsTutor,
            meta: {
                permission: 'tutor',
                title: 'title.subjects'
            }
        },
        { 
            path: '/tutor/availability', 
            name: 'availability-tutor',
            component: availabilityTutor,
            meta: {
                permission: 'tutor',
                title: 'title.availability'
            }
        },
        { 
            path: '/tutor/bio', 
            name: 'bio-tutor',
            component: biographyTutor,
            meta: {
                permission: 'tutor',
                title: 'title.biography'
            }
        },
        { 
            path: '/tutor/linc-app', 
            name: 'app-tutor',
            component: appTutor,
            meta: {
                permission: 'tutor',
                title: 'title.app'
            }
        },
        { 
            path: '/tutor/messaging', 
            name: 'chat-tutor',
            component: chatTutor,
            meta: {
                permission: 'tutor',
                title: 'title.chat'
            }
        },
        { 
            path: '/tutor/messaging/:idlesson', 
            name: 'chat-tutor-id',
            component: chatTutor,
            meta: {
                permission: 'tutor',
                title: 'title.chat'
            }
        },
        { 
            path: '/tutor/room/:roomId', 
            name: 'room-tutor',
            component: room,
            meta: {
                title: 'title.virtualRoom',
                permission: 'tutor',
                classRoom: true
            }
        },
        {
            path: '/tutor/notifications', 
            name: 'notifications-tutor',
            component: notifications,
            meta: {
                title: 'title.notifications',
                permission: 'tutor',
            }
        },
        {   path: '/tutor/formats',
            name: 'formats-tutor',
            component: format,
            meta:{
                title: 'title.formats',
                permission: 'tutor'
            }
        },
        {   path: '/tutor/schedule-session',
            name: 'schedule-tutor',
            component: scheduleSession,
            meta:{
                title: 'title.scheduleSession',
                permission: 'tutor'
            }
        }
    ]
});

router.beforeEach ( async (to, from, next) => {
    // Get institution data
    if (!store.state.auth.institution.id){
        const subdomain = window.location.host.split('.')[0];
        await store._actions.axiosRequest[0]({ config : {
            method: 'GET',
            url: `${store.state.auth.api}/validation/${subdomain}`
        }}).then( resp => resp.data )
        .then( data => {
            store.commit('setInstitution', data.institution);
            if (this.$route.path==='/')
                store.commit('changeTitle', data.institution.name);
        }).catch( error => {
        } )
    }
    
    if (store.state.auth.token == ''){
        const token = Vue.localStorage.get('tokenLinkWebApp');
        if (token && store.state.auth.path!=='confirm-email') {
            store._actions.getUser[0](token).then( async permission => {
                if(store.state.auth.institution.code == 'BASIC'){   
                    store._actions.logOut[0]();
                }
                else if(permission === 'confirm-email'){
                    Vue.localStorage.remove('tokenLinkWebApp');
                    next({ name: 'confirm-email' })
                }
                else if(!store.getters.permissionFormats && to.name == `formats-${store.state.auth.permission}`)
                    next({ name: '404-'+store.state.auth.permission })
                else if(store.getters.statusFormats && !store.state.auth.user.is_tutor && to.name != 'formats-student')
                    next({ name: `formats-${store.state.auth.permission}` });
                else if (typeof to.meta.emailValidation !== 'undefined' && !to.meta.error)
                    next({ name: `dashboard-${store.state.auth.permission}` });
                else if (to.meta.permission === store.state.auth.permission)
                    next();
                else if (to.meta.permission && to.meta.permission !== store.state.auth.permission)
                    next({ name: '403-'+store.state.auth.permission })
                else
                    next({ name: '404-'+store.state.auth.permission })
            }).catch( error => {
                store._actions.stopEvents[0]();
                store._actions.signOutFirebase[0]();
                Vue.localStorage.remove('tokenLinkWebApp');
                Vue.localStorage.remove('permissionLinkWebApp');
                //if (to.name != `dashboard-${to.meta.permission}`)
                    next({ name : 'email-validatation', query: { next: encodeURIComponent(to.fullPath) } });
                //else
                    //next({ name: 'email-validatation' });
            })
        }
        else{
            if (to.meta.permission && store.state.auth.permission == '' && store.state.auth.institution.code != 'BASIC'){
                //if (to.name != `dashboard-${to.meta.permission}`)
                    next({ name : 'email-validatation', query: { next: encodeURIComponent(to.fullPath) } });
                //else
                    //next({ name: 'email-validatation' });
            }
            else if ((to.meta.emailValidation && store.state.auth.path == '') || (to.meta.permission && store.state.auth.permission == ''))
                next({ name : 'email-validatation'});
            else if (to.meta.validateToken && store.state.auth.path == ''){
                if (to.meta.validateToken === 'email')
                    store._actions.confirmEmail[0](to.params.token).then( resp => {
                        next();
                    }).catch( error => {
                        next({ name : 'confirm-email' });
                    });
                else
                    store._actions.confirmPassword[0](to.params.token).then( resp => {
                        if (resp)
                            next();
                        else
                            next({ name : 'confirm-email' });
                    }).catch( error => {
                        next({ name : 'confirm-email' });
                    });
            }
            else
                next();
        }
    }else{ // After login
        if(store.state.auth.institution.code == 'BASIC')
            store._actions.logOut[0]();
        else if(!store.getters.permissionFormats && to.name == `formats-${store.state.auth.permission}`)
            next({ name: '404-'+store.state.auth.permission })
        else if(store.getters.statusFormats && !store.state.auth.user.is_tutor && to.name != 'formats-student'){
            if(from.name != `formats-${store.state.auth.permission}`)
                next({ name: `formats-${store.state.auth.permission}` });
        }
        else if (!store.getters.statusFormats && typeof to.meta.emailValidation !== 'undefined' && (store.state.auth.permission === 'student' || store.state.auth.permission === 'tutor'))
            next({ name: `dashboard-${store.state.auth.permission}` });
        else if (to.meta.permission && to.meta.permission !== store.state.auth.permission)
            next({ name: '403-'+store.state.auth.permission });
        else
            next();
    }
});

export default router;
