/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cronometro-amarillo': {
    width: 101,
    height: 117,
    viewBox: '0 0 101 117',
    data: '<g _fill="#F5A623" fill-rule="evenodd"><g fill-rule="nonzero"><path pid="0" d="M88.218 43.585c-6.4-9.855-16.001-16.855-27.242-19.93l1.179-5.548 2.733.58a2.795 2.795 0 001.162-5.465l-10.932-2.324a2.795 2.795 0 00-1.162 5.466l2.733.581-1.18 5.548c-11.52-1.763-23.138.727-32.993 7.127C11.878 36.528 4.56 47.17 1.922 59.578-.715 71.986 1.636 84.684 8.55 95.323c6.908 10.638 17.55 17.956 29.958 20.593 12.408 2.638 25.106.286 35.745-6.628 10.638-6.908 17.956-17.55 20.593-29.958 2.638-12.408.286-25.105-6.628-35.745zm-45.795 67.353l.561-2.64a2.795 2.795 0 00-5.466-1.162l-.561 2.64C16.812 104.083 3.907 84.212 6.9 63.493l2.64.561a2.795 2.795 0 001.162-5.466l-2.64-.561c5.693-20.146 25.564-33.05 46.283-30.057l-.56 2.64a2.795 2.795 0 005.465 1.162l.561-2.64c20.146 5.693 33.05 25.564 30.057 46.283l-2.64-.561a2.795 2.795 0 00-1.162 5.466l2.64.561c-5.693 20.146-25.564 33.05-46.283 30.057z"/><path pid="1" d="M47.803 72.187a2.795 2.795 0 01-2.152-3.314l5.81-27.33a2.795 2.795 0 015.466 1.161l-5.81 27.331a2.795 2.795 0 01-3.314 2.152z"/></g><g transform="rotate(12 -9.48 401.367)"><rect pid="2" transform="rotate(40 13.28 10.732)" x="11.28" y="2.732" width="4" height="16" rx="2"/><rect pid="3" transform="rotate(40 6 6)" x="4" width="4" height="12" rx="2"/></g><g transform="scale(-1 1) rotate(-12 -19.728 214.987)"><rect pid="4" transform="rotate(40 13.192 11.04)" x="11.192" y="3.039" width="4" height="16" rx="2"/><rect pid="5" transform="rotate(40 5.991 6.442)" x="3.991" y=".442" width="4" height="12" rx="2"/></g></g>'
  }
})
