/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border-style': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9 2h1v1H9V2zM17 18h1v1h-1v-1zM9 18h1v1H9v-1zM3 2h1v1H3V2zM15 2h1v1h-1V2zM3 18h1v1H3v-1zM5 18h1v1H5v-1zM7 18h1v1H7v-1zM11 18h1v1h-1v-1zM13 18h1v1h-1v-1zM15 18h1v1h-1v-1zM1.5 19a.5.5 0 01-.5-.5v-16a.5.5 0 011 0v16a.5.5 0 01-.5.5zM7.5 3h-2a.5.5 0 010-1h2a.5.5 0 010 1zM13.5 3h-2a.5.5 0 010-1h2a.5.5 0 010 1zM17.5 5a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM17.5 9a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM17.5 13a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM17.5 17a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5z"/>'
  }
})
