
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class lessonPhoto extends Vue{
		// Required { photo, lessonType, showPhoto }, opcional { size, iconSize, statusType }
        @Prop({ type: Object, default: () => { return {} } }) readonly options!: any; 

        @State( state => state.auth.permission ) userType;

        statusImgDefault: boolean = false;

        get size(): string{
        	if (this.options.size)
        		return this.options.size;
        	else
        		return '60px';
        }

        get sizeType(): string{
            if (this.options.sizeType)
                return this.options.sizeType;
            else
                return '20px';
        }

        get iconSize(): string{
        	if (this.options.iconSize)
        		return this.options.iconSize;
        	else
        		return '60%';
        }

        get iconSizeType(): string{
            if (this.options.iconSizeType)
                return this.options.iconSizeType;
            else
                return '55%';
        }

        get photo(): string {
            if(this.statusImgDefault || !this.options?.photo)
                return 'https://media.linc.mx/images/universities/app/profile/default-profile-other.png';
            else
            	return this.options.photo;
        }

        get statusPhoto(): boolean{
            return (this.userType == 'student' || (this.options.lessonType == 'Individual' && this.userType == 'tutor') || this.options?.showPhoto);
        }
	}
