/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tennis': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.994 17.42a.5.5 0 00-.27-.367l-.927-.464-3.443-3.443-.135-.134c-1.001-.994-2.514-2.496-3.359-6.613a7.944 7.944 0 00-2.126-3.913C8.107.859 6.367-.001 4.7-.001c-1.248 0-2.39.483-3.304 1.397C.365 2.427-.122 3.781.026 5.207c.156 1.507 1.007 3.073 2.46 4.526a7.969 7.969 0 003.913 2.125v.001c4.117.844 5.619 2.357 6.613 3.359l.134.135 3.443 3.443.464.927a.5.5 0 00.8.13l2-2a.499.499 0 00.14-.434zM9.36 10.653L7.957 9.25 9.25 7.957l1.403 1.403a2.808 2.808 0 01-.536.757 2.776 2.776 0 01-.757.536zM5.957 7.25L7.25 5.957 8.543 7.25 7.25 8.543 5.957 7.25zm.586 2l-1.221 1.221a7.06 7.06 0 01-1.609-.977l1.536-1.537L6.542 9.25zm4.342-2.626c.017.082.032.165.045.247.08.493.091.96.038 1.39L9.956 7.249l.872-.872c.019.077.037.154.053.231l.003.015zM9.25 6.543L7.957 5.25l1.537-1.537c.396.489.727 1.036.976 1.609l-1.22 1.22zm-2-2L5.957 3.25 7.36 1.847c.485.301.971.681 1.45 1.136l-1.56 1.56zm-.707.707L5.25 6.543 3.957 5.25 5.25 3.957 6.543 5.25zm-.115-3.885L5.25 2.543 3.816 1.109C4.101 1.037 4.397 1 4.7 1a4.54 4.54 0 011.728.365zm-3.604.166L4.543 3.25 3.25 4.543 1.529 2.822c.157-.252.348-.492.574-.718a3.99 3.99 0 01.72-.573zM1.105 3.813L2.543 5.25 1.367 6.426c-.378-.913-.468-1.801-.262-2.613zm.744 3.545L3.25 5.957 4.543 7.25l-1.56 1.56a9.033 9.033 0 01-1.134-1.452zm4.528 3.472l.873-.873 1.01 1.01a4.468 4.468 0 01-.547.033 5.42 5.42 0 01-1.028-.102l-.084-.018c-.074-.016-.149-.032-.223-.051zm3.084.868a3.835 3.835 0 001.362-.874c.384-.384.677-.843.874-1.361.824 2.146 1.843 3.281 2.594 4.038l-.792.792c-.757-.751-1.893-1.771-4.039-2.595zm8.176 6.958l-.19-.38a.502.502 0 00-.094-.13L14.207 15l.793-.793 3.146 3.146c.038.038.082.07.13.094l.38.19-1.019 1.019zM2.5 20C1.122 20 0 18.878 0 17.5S1.122 15 2.5 15 5 16.122 5 17.5 3.878 20 2.5 20zm0-4c-.827 0-1.5.673-1.5 1.5S1.673 19 2.5 19 4 18.327 4 17.5 3.327 16 2.5 16z"/>'
  }
})
