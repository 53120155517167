/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 11H.5a.5.5 0 010-1h18a.5.5 0 010 1z"/>'
  }
})
