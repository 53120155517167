<template>
	<div class="applyToMultiple">

        <!-- ========== Radio buttons ========== -->
        <div class=" l-borrom">
        	<div class="am-radioButton d-flex align-items-center mb-3">
        		<div class="radio-check mr-2" :class="{ active: view === 'week' }"  @click=" view = 'week' "></div>
        		<span class="txt-radio mb-0">{{ $t('availability.modals.availability.btnRadioDays') }}</span>
        	</div>
        	<div class="am-radioButton d-flex align-items-center mb-3">
        		<div class="radio-check mr-2" :class="{ active: view === 'dates' }" @click=" view = 'dates' "></div>
        		<span class="txt-radio mb-0">{{ $t('availability.modals.availability.btnRadioCalendar') }}</span>
        	</div>
        </div>
        

		<transition class="mb-4" name="bound" mode="out-in">
			<!-- ============= Repeating days of the week =========== -->
			<div key="week" class="pt-4 pb-4" v-if=" view === 'week' ">
				<div class="checkbox-day d-flex align-items-center mb-3" :class="{ disabled: disabledDisponibility }" v-for=" (item,i) in days ">
					<div class="am-checkbox mr-2" :class="{ active : dWeek.find(val => val === item.val), check: item.val === day }">
						<svgicon class="icon-check" name="check" />
						<input type="checkbox" :id="'v-check-'+i" :value="item.val" v-model="dWeek">
					</div>
					<span class="text-checkbox mb-0" :for="'v-check-'+i">{{ item.txt }}</span>
				</div>
			</div>

			<!-- ============= Specific dates =========== -->
			<div key="dates" class="pt-3 pb-3" v-else-if=" view === 'dates' ">
				<v-date-picker class="v-calendar w-100 mb-1" :class="{ disabled: disabledDisponibility }" :is-inline="true"
				                              is-expanded
				                              mode="multiple"
				                              :disabled="disabledDisponibility"
				                              :min-date='$moment().toDate()'
				                              :max-date='$moment($moment().format("MMMM"),"MMMM").add(7, "M").subtract(1,"days").toDate()'
				                              :themeStyles="calendar.themeStyles"
                                              :key="'calendar'+i18n.locale"
				                              tintColor="#2188FC"
				                              :formats="{ weekdays: 'WW' }" 
				                              :disabled-dates='calendar.disabledDays'
				                              :attributes='attrs'
				                              v-model="date"
				                              ref="calendar" />  
				<div class="d-flex justify-content-between align-items-center">
					<span class="days-selected">{{ $t('availability.modals.availability.selectedDays', { cont: date.length-1 }) }}</span>
					<button class="btn-clear" @click="date = [ selectedDay.toDate() ]">{{ $t('availability.modals.availability.btnClear') }}</button>
				</div> 
			</div>
		</transition>

		<div class="d-flex flex-wrap justify-content-center">
			<v-btn class="mb-3 w-100" height="40px" :txt="$t('availability.modals.availability.btnApply')" @click.native="actionsBtnAply()" :disabled="disabledBtn || disabledDisponibility" />
			<button class="btn-cancel" @click="view === 'week'; $emit('close')">{{ $t('availability.btn.cancel') }}</button>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props: {
			defaultView: { type: String, default: 'week', required: false },
			disabledBtn: { type: Boolean, default: false, required: false },
			disabledDisponibility: { default: false, required: false },
			selectedDay: ''
		},
		data(){
			return{
				view: 'week', // week, dates 
				dWeek: [],
				date: [],
				calendar: {
					themeStyles: {
						wrapper: {
							backgroundColor: '#fff',
						},
					    headerArrows: {
                            color: '#72BD00',
                            height: '1.3rem'
					    },
					    header: {
					    	justifyContent: 'center'
					    },
					    headerTitle: {
					    	fontSize: '15px',
					    	color: '#252525'
					    },
					    weekdays:{
					    	color: '#c6c6c6'
					    },
					    dayContent: {
					    	color: '#838181'
					    }
					},
                    disabledDays: []
				},
				attrs: [
				    /*{
				    	key: "ejem",
				    	highlight: {
				    		color: '#000',
				    		contentClass: 'my-dot-class',
				    	},
				    	dot: {
				    		color: '#000',
                            class: 'my-dot-class',
				    	},
				    	dates: [
				    	    new Date()
				    	]
				    },*/
				]
			}
		},
		computed:{
			...mapState(['i18n']),
			days(){
				return [ { txt: this.$t('days.sunday'), val: 'Domingo' },
				         { txt: this.$t('days.monday'), val: 'Lunes' },
				         { txt: this.$t('days.tuesday'), val: 'Martes' },
				         { txt: this.$t('days.wednesday'), val: 'Miercoles' },
				         { txt: this.$t('days.thursday'), val: 'Jueves' },
				         { txt: this.$t('days.friday'), val: 'Viernes' },
				         { txt: this.$t('days.saturday'), val: 'Sabado' } ]
			},
			day(){
				let day = this.selectedDay.locale('es').format('dddd').replace('é','e').replace('á','a');
				    day = day.charAt(0).toUpperCase() + day.slice(1);

				return day;
			}
		},
		methods:{
			actionsBtnAply(){
				if (this.view === 'week') {
					this.$emit('applyToAll', [...this.dWeek, this.day])
				}else{
					this.$emit('applyToSpecificDays', this.formatDays());
				}
			},
            formatDays(){
				return this.date.reduce( (arr,item) => {
					let d = this.$moment(item).format('YYYY-MM-DD');
					arr.push(d);
					return arr;
				},[])
			}
		},
		created(){
			this.view = this.defaultView;
			this.date.push(this.selectedDay.toDate());
			this.calendar.disabledDays.push(this.selectedDay.toDate());
		},
		watch:{
			defaultView: function(newVal){
                this.view = newVal;
			}
		}
	}
</script>