<template>
	<div class="v-chat-input" :style="disabled?'pointer-events: none; opacity: 0.5;':''">
		<div class="col v-content-field-text pr-2">
			<div class="v-chat-input position-relative">
				<input class="v-field-text w-100" @keyup.enter="sendMsg" ref="input" v-model="txt"  @blur="txt.trim(); showKeyboardEmoji = false;" :placeholder="$t('chat.placeholder')" :emoji="showKeyboardEmoji">
				<v-emojis ref="emojis" :disabled="disabled" @insertEmoji="insertEmoji" @showEmojis="showKeyboardEmoji = true; $refs.input.focus()" />
			</div>
		</div>
		<div class="col-auto pl-0 pr-0">
			<button class="btn-fields" @click="$emit('loadFile')">
				<svgicon class="icon-clip" name="clip" original/>
				<div class="hover-txt">{{ $t('chat.attachFile') }}</div>
			</button>
		</div>
		<div class="col v-content-btn-send pl-2">
			<button class="v-btn-send w-100 h-100" @click="sendMsg"><svgicon class="v-btn-send-icon" :class="{ 'fill-t': auth.permission == 'tutor', 'fill-s': auth.permission == 'student' }" name="shape" /></button>
		</div>
    </div>
</template>

<script>
	import { mapState } from 'vuex';
	export default{
        props: {
        	disabled: { type: Boolean, default: false, required: false }
        },
        data(){
        	return{
                txt: '',
                showKeyboardEmoji: false
        	}
        },
        computed:{
            ...mapState(['auth'])
        },
        methods:{
			handleInput(value){
				this.$emit('input', value);
			},
			insertEmoji(emoji){
                const pos = this.$refs.input.selectionStart;
                this.txt = (this.txt.slice(0, pos) + emoji.data + this.txt.slice(pos)).trim();
            },
            sendMsg(){
            	this.txt.trim();
                if (this.txt.length > 0)
                	this.$emit('send', this.txt);
            },
            clearMsg(){
            	this.txt = '';
            }
		}
	}
</script>