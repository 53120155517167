var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-lesson-sch d-flex align-items-center" }, [
    _c(
      "div",
      { staticClass: "w-100 position-relative" },
      [
        _c("svgicon", {
          staticClass: "v-lsch-calendar",
          class: { "v-spt": _vm.options.spt },
          attrs: { name: "calendar", color: "#000" }
        }),
        _vm._v(" "),
        _c("v-hover-txt", {
          staticClass: "mb-2 pl-4",
          attrs: {
            colorTxt: "#000",
            fontSize: "1rem",
            lineHeight: "18px",
            fontWeight: "400",
            txt: _vm.date
          }
        }),
        _vm._v(" "),
        _c("v-hover-txt", {
          staticClass: "pl-4",
          attrs: {
            colorTxt: "#000",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "400",
            txt: _vm.time
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }