import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

let state = {
	title: '',
	statusHour: false,
	isMovile: false,
	grid: 'xl',
	color: {
        student: {
			light: '#00C0ff',
			main: '#2188FC',
			dark: '#1b7ae5'
		},
		tutor: {
			light: '#72BD00',
			main: '#005E58',
			dark: '#004844'
		}
	},
	contentSize: {
        headerH: 60,
        windowH: 0,
        paddingView: 24
	},
	currentBrowser: {
		OP_C: false,
		name: '',
		version: '',
		model: ''
	},
	version: '1.4.0',
	lessonRequest: {
		lesson: {},
		route: '',
		status: ''
	},
	notification: {}
}

export default {
    state,
	getters,
    mutations,
    actions
}

