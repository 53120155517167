/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sheet': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<path pid="0" d="M14.5 0c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13zm0 1h-13a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5zm-1 3v7h-11V4h11zm-8 4h-2v2h2V8zm7 0h-6v2h6V8zm-7-3h-2v2h2V5zm7 0h-6v2h6V5z" _fill="#000" fill-rule="nonzero"/>'
  }
})
