
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { State, Getter, Mutation } from 'vuex-class';
	import { modal } from '../../mixins/index.js';

	@Component({
        mixins: [ modal ]
    })
    export default class JoinOnboarding extends Vue{
    	@State( state => state.auth.permission ) permission;
    	@State( state => state.i18n.locale ) lang;
    	@State( state => state.currentLesson.lesson ) currentLesson;
        @Getter('modalVal') modalVal;
        @Mutation('setModal') setModal;

        lesson: any = {};
        
        get t(){
        	return (this as any).$t;
        }

        get tagTypeOpc(): object{
            if (this.lesson.type == 'Grupal')
                return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
            else
                return {};  
        }

        get lessonPhotoOpc(): object{
            return { size: '65px', photo: this.lesson.tutorPhoto, lessonType: this.lesson.type };
        }

        created(){
        	this.lesson = this.currentLesson;
        }

        afterClose(){
            this.setModal('');
        }
    }
