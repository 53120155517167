
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { State, Action } from 'vuex-class';

    enum StepsDropdown{
        blur,
        focus,
        hover,
        click
    }

    enum StepsResults{
        none,
        spinner,
        results,
        message
    }

    @Component
    export default class studentSeeker extends Vue {
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @Action('axiosRequest') axios;

        val: any = '';
        lastResults: any = '';
        delay: any = '';
        stepsDropdown: any = StepsDropdown;
        stepsResults: any = StepsResults;
        statusDropdown: StepsDropdown = StepsDropdown.blur; // blur, focus, hover
        statusResults: StepsResults = StepsResults.none; // none, spinner, results, message
        resultHover: string = '';
        keyPoss: number = -1;
        results: Array<any> = [];

        get refs(): any{
            return (this as any).$refs;
        }

        async getStudents(search: string): Promise<void>{
            try {
                const resp = await this.axios({ config: {
                    url: `${this.api}/tecnm/users`,
                    method: 'GET',
                    params: { search: search },
                    headers: { Authorization: this.token }
                } });

                if(resp.status == 200){
                    this.results = resp.data.students;
                }

                if(this.results.length > 0)
                    this.statusResults = this.stepsResults.results;
                    
            } catch (err) {
                this.statusResults = this.stepsResults.message;
            }
        }

        keyFuction(key): void{ // Focus y enter sobre el dropdown
            if(key == 'ArrowUp'  || key == 'ArrowDown' || key == 'Enter'){
			    const scrollBar = this.refs.scroll,
                      dropdown = scrollBar.$el,
				      dropdowns = this.refs.dropdownItem;
				  
                if (this.keyPoss > 0 && key == 'ArrowUp') {
				    this.keyPoss--;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop > dropdowns[this.keyPoss].offsetTop )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop - 60;
			    }
                else if (this.keyPoss < dropdowns.length-1 && key == 'ArrowDown') {
				    this.keyPoss++;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop < ((dropdowns[this.keyPoss].offsetTop + 60) - dropdown.offsetHeight) )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop + 60;
			    }
                else if (this.keyPoss > -1 && key == 'Enter'){
                    dropdowns[this.keyPoss].click();
			    }
            }
		}
        
        textBold(student): string{ // Función para cambiar a negritas la conincidencia con el texto buscado
            let text = `${student.fullName} | ${student.email}${student.studentId?' | '+student.studentId:''}`;
			let param = RegExp(this.val.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i"),
				    textNormalize = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    pos = textNormalize.search(param),
                    textLength = this.val.length,
                    textSub = text.substring(pos,pos+textLength);
                    
            if (pos != -1)    
				text = text.replace(textSub,'<span class="bold">'+textSub+'</span>');

            return text;
		}

        getStudentFormat(student): void{ // Click item dropdown
            (this as any).$emit('getStudentFormat', student);
            this.statusDropdown = this.stepsDropdown.click; 
            this.blurAction();
		}

		toggleAction(): void{
            if (this.statusDropdown == this.stepsDropdown.blur)
            	this.focusAction();
        }
        focusAction(): void{
            if (this.statusDropdown == this.stepsDropdown.blur || this.statusDropdown == this.stepsDropdown.focus)
            	this.refs.input.focus();
        }
        blurAction(): void{
            if (this.statusDropdown != this.stepsDropdown.hover) 
            	this.statusDropdown = this.stepsDropdown.blur;
        }
        hoverAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.focus)
        		this.statusDropdown = this.stepsDropdown.hover;
        }
        leaveAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.hover) {
        		this.statusDropdown = this.stepsDropdown.focus; 
        		this.focusAction();
        	}
        }

        @Watch('val')
        search(newVal: any, oldVal: any): void{
            const newV = newVal.trim(),
            	  oldV = oldVal.trim();

            clearTimeout(this.delay);
            	if (newV.length > 2/* && !this.noWatch*/){
            		this.statusResults = this.stepsResults.spinner;
            		this.delay = setTimeout( async () => {
                        this.statusDropdown = this.stepsDropdown.focus; 
            			if (newV != this.lastResults){
            				await this.getStudents(newV);

                            this.lastResults = newV;
            				this.delay = '';
            		    }else{
                            if(this.results.length > 0)
                                this.statusResults = this.stepsResults.results;
                            else
                                this.statusResults = this.stepsResults.message;
                        }
            		},700);
            	}else{
                    this.blurAction();
                    this.statusDropdown = this.stepsDropdown.none; 
                }
            	//if (newV != oldV && (newV.length <= oldV.length+1 || newV.length - oldV.length > 1/* || this.lastChars >= 5*/) && !this.noWatch)
            		//this.focus(newV);
            	/*else
            		this.noWatch = false;*/
        }
    }
