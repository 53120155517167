var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": true,
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "letter",
            "pdf-orientation": "portrait",
            image: { type: "jpg", quality: 0.95 },
            "html-to-pdf-options": {
              margin: [10, 0],
              html2canvas: { scale: 1, useCORS: true },
              pagebreak: { mode: ["avoid-all", "css", "legacy"] }
            }
          }
        },
        [
          _c(
            "section",
            {
              staticClass: "format-pdf",
              attrs: { slot: "pdf-content" },
              slot: "pdf-content"
            },
            [
              _c(
                "h3",
                { staticClass: "pdf-title text-center mb-4 pl-3 pr-3" },
                [_vm._v(_vm._s(_vm.$t("formats.fm1.t")))]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass: "pdf-txt pt-2 mb-4 pl-5 pr-5 position-relative"
                },
                [
                  _vm._v(
                    "\n             " +
                      _vm._s(_vm.$t("formats.fm1.d")) +
                      "\n             "
                  ),
                  _c(
                    "section",
                    { staticClass: "w-100 position-relative pt-4" },
                    [_c("section", { staticClass: "bottom-line" })]
                  )
                ]
              ),
              _vm._v(" "),
              _c("section", { staticClass: "row h-100 pl-5 pr-5 mb-3" }, [
                _c("section", { staticClass: "col-9" }, [
                  _c(
                    "section",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-between h-100"
                    },
                    [
                      _c("section", { staticClass: "row mr-n2 ml-n2 mb-3" }, [
                        _c(
                          "section",
                          { staticClass: "col-12 mb-4 pb-2 pl-2 pr-2" },
                          [
                            _c("p", { staticClass: "pdf-txt mb-0" }, [
                              _c("span", { staticClass: "bold" }, [
                                _vm._v(_vm._s(_vm.$t("formats.fm1.date")))
                              ]),
                              _vm._v(" " + _vm._s(_vm.data.createdDate))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("section", { staticClass: "col-4 mb-4 pl-2 pr-2" }, [
                          _c("span", { staticClass: "pdf-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("formats.fm1.form.career.label"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "pdf-input-content w-100" },
                            [
                              _c("span", { staticClass: "pdf-input" }, [
                                _vm._v(_vm._s(_vm.data.major))
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("section", { staticClass: "col-4 mb-4 pl-2 pr-2" }, [
                          _c("span", { staticClass: "pdf-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("formats.fm1.form.period.label"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "pdf-input-content w-100" },
                            [
                              _c("span", { staticClass: "pdf-input" }, [
                                _vm._v(_vm._s(_vm.data.period))
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("section", { staticClass: "col-4 mb-4 pl-2 pr-2" }, [
                          _c("span", { staticClass: "pdf-label" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm1.form.noControl")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "pdf-input-content w-100" },
                            [
                              _c("span", { staticClass: "pdf-input" }, [
                                _vm._v(_vm._s(_vm.data.studentId))
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "pdf-subTitle mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("formats.fm1.generalDatas")))
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3 mb-3 text-center" }, [
                  _c("img", {
                    style: {
                      width: "120px",
                      height: "120px",
                      borderRadius: "60px"
                    },
                    attrs: { src: _vm.photo }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-12" }, [
                  _c(
                    "section",
                    { staticClass: "w-100 position-relative pb-4" },
                    [_c("section", { staticClass: "top-line" })]
                  )
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.lastName")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.lastNameF))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.motherLastName")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.lastNameM))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.name")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.name))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.gender.label")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.gender))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.email")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.email))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.addressPhone")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.landlinePhone))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.phone1")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.mobilePhone1))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.phone2")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.mobilePhone2))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.birthday")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "row ml-n1 mr-n1" }, [
                    _c(
                      "section",
                      { staticClass: "pdf-input-content col-4 pl-1 pr-1" },
                      [
                        _c("span", { staticClass: "pdf-input" }, [
                          _vm._v(_vm._s(_vm.data.birthday.day))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "pdf-input-content col-4 pl-1 pr-1" },
                      [
                        _c("span", { staticClass: "pdf-input" }, [
                          _vm._v(_vm._s(_vm.data.birthday.month))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "pdf-input-content col-4 pl-1 pr-1" },
                      [
                        _c("span", { staticClass: "pdf-input" }, [
                          _vm._v(_vm._s(_vm.data.birthday.year))
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.age")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.age))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.placeOfBirth")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.birthplace))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.civilStatus.label")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.maritalStatus))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.address")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.homeAddress))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.scholarship.label")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.educationLevel))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.schoolName")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.schoolName))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.average")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.schoolScore))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.onScholarship")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(
                        _vm._s(
                          _vm.data.scholarshipStatus
                            ? _vm.$t("yesNo.y")
                            : _vm.$t("yesNo.n")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("formats.fm1.form.typeOfScholarship.label"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.scholarshipType))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-4" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.institutionName")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.scholarshipInstitution))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.job")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(
                        _vm._s(
                          _vm.data.workStatus
                            ? _vm.$t("yesNo.y")
                            : _vm.$t("yesNo.n")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-3" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.institutionJob")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.workCompany))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-4" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.jobSchedule")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.workSchedule))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-6" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.liveWith.label")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.livingWith))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-5" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.supportPerson")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.emergencyContact))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-5 offset-1" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.supportPhone")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.emergencyPhone))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-5" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("formats.fm1.form.dadScholarship.label"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.fatherEduLevel))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-5 offset-1" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("formats.fm1.form.momScholarship.label"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.motherEduLevel))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5 mb-4" }, [
                _c("section", { staticClass: "col-5" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.dadStatus")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(
                        _vm._s(
                          _vm.data.fatherDeceased
                            ? _vm.$t("parentStatus.opc1")
                            : _vm.$t("parentStatus.opc2")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-5 offset-1" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.momStatus")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(
                        _vm._s(
                          _vm.data.motherDeceased
                            ? _vm.$t("parentStatus.opc1")
                            : _vm.$t("parentStatus.opc3")
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "row pl-5 pr-5" }, [
                _c("section", { staticClass: "col-5" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.addressJobDad")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.fatherWorkplace))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "col-5 offset-1" }, [
                  _c("span", { staticClass: "pdf-label" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.form.addressJobMom")))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "pdf-input-content w-100" }, [
                    _c("span", { staticClass: "pdf-input" }, [
                      _vm._v(_vm._s(_vm.data.motherWorkplace))
                    ])
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }