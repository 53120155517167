/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lan': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 10H10V8h1.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v4a.5.5 0 00.5.5H9v2H.5a.5.5 0 000 1H4v2H2.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H5v-2h9v2h-1.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H15v-2h3.5a.5.5 0 000-1zM8 4h3v3H8V4zM6 17H3v-3h3v3zm10 0h-3v-3h3v3z"/>'
  }
})
