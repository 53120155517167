/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'magnifying2': {
    width: 59,
    height: 59,
    viewBox: '0 0 59 59',
    data: '<path pid="0" d="M41.24 37.005c7.38-9.614 6.033-23.304-3.078-31.296-9.111-7.992-22.86-7.542-31.43 1.028s-9.02 22.318-1.028 31.43c7.992 9.11 21.682 10.457 31.296 3.078l17 17 4.24-4.24-17-17zm-18.24 3c-9.389 0-17-7.611-17-17 0-9.39 7.611-17 17-17s17 7.61 17 17c0 9.389-7.611 17-17 17z" _fill="#000" fill-rule="nonzero"/>'
  }
})
