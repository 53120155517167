/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-work': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13 6.467a4.906 4.906 0 00-.351-1.659c-.214-.514-.514-.928-.891-1.229a2.582 2.582 0 00-.303-.208A1.99 1.99 0 0012 2.001c0-1.103-.897-2-2-2s-2 .897-2 2c0 .53.208 1.012.545 1.37a2.668 2.668 0 00-.303.208c-.377.302-.677.716-.891 1.229A4.884 4.884 0 007 6.5a.5.5 0 00.5.5h5.001a.5.5 0 00.5-.5L13 6.467zM9 2c0-.551.449-1 1-1s1 .449 1 1-.449 1-1 1c-.551 0-1-.449-1-1zm-.95 4a3.69 3.69 0 01.224-.808C8.608 4.39 9.173 4 10 4c1.429 0 1.837 1.268 1.953 2H8.049zM6 17.467a4.906 4.906 0 00-.351-1.659c-.214-.514-.514-.927-.891-1.229a2.582 2.582 0 00-.303-.208A1.99 1.99 0 005 13.001c0-1.103-.897-2-2-2s-2 .897-2 2c0 .53.208 1.012.545 1.37a2.668 2.668 0 00-.303.208c-.377.302-.677.716-.891 1.229A4.884 4.884 0 000 17.5a.5.5 0 00.5.5h5.001a.5.5 0 00.5-.5L6 17.467zM2 13c0-.551.449-1 1-1s1 .449 1 1-.449 1-1 1-1-.449-1-1zm-.95 4a3.69 3.69 0 01.224-.808C1.608 15.39 2.173 15 3 15c1.429 0 1.837 1.268 1.953 2H1.049zM20 17.467a4.906 4.906 0 00-.351-1.659c-.214-.514-.514-.927-.891-1.229a2.582 2.582 0 00-.303-.208 1.99 1.99 0 00.545-1.37c0-1.103-.897-2-2-2s-2 .897-2 2c0 .53.208 1.012.545 1.37a2.668 2.668 0 00-.303.208c-.377.302-.677.716-.891 1.229A4.884 4.884 0 0014 17.5a.5.5 0 00.5.5h5.001a.5.5 0 00.5-.5L20 17.467zM16 13c0-.551.449-1 1-1s1 .449 1 1-.449 1-1 1-1-.449-1-1zm-.95 4a3.69 3.69 0 01.224-.808C15.608 15.39 16.173 15 17 15c1.429 0 1.837 1.268 1.953 2h-3.904zM10 20a8.02 8.02 0 01-3.734-.923.5.5 0 01.468-.884 7.013 7.013 0 006.532 0 .5.5 0 01.468.884A8.014 8.014 0 0110 20zM17.23 10.5a.5.5 0 01-.482-.367 6.997 6.997 0 00-3.015-4.056.501.501 0 01.534-.846 7.989 7.989 0 013.445 4.635.5.5 0 01-.482.633zM2.77 10.5a.5.5 0 01-.482-.633 7.993 7.993 0 013.445-4.635.5.5 0 01.534.846 6.986 6.986 0 00-3.015 4.056.5.5 0 01-.482.367z"/>'
  }
})
