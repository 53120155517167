<template>
	<div class="v-modal mFeedback" :class="{ active : show }" v-if="modalVal.modal=='v-modal-feedback' && auth.permission === $route.meta.permission && !$route.meta.error && !$route.meta.classRoom">
		<div class="v-blurColor"></div>
		<div class="v-modal-paper d-flex flex-column">
			<div class="v-modal-title" :class="currentLesson.lesson.type==='Grupal'?'bgColor-yellow-main':`bgColor-${auth.permission}-main`">
				<h4 class="v-t">{{ $t('modals.feedback.t') }}</h4>
			</div>
			<perfect-scrollbar tag="div">
				<div class="pt-3 pl-4 pr-4 mb-4 d-flex flex-column align-items-center">
					<div class="img-feedback-group mb-3" v-if="currentLesson.lesson.type === 'Grupal'">
						<svgicon class="icon" name="group" />
					</div>
					<v-img-profile class="mb-3" :img="auth.permission === 'tutor'?currentLesson.lesson.studentPhoto:currentLesson.lesson.tutorPhoto" size="105px" v-else/>
					<span class="v-feedback-name mb-2" v-if="currentLesson.lesson.type === 'Grupal' && auth.permission === 'tutor'">{{ $t('modals.feedback.group') }}</span>
					<span class="v-feedback-name text-center mb-2" v-else>{{ auth.permission === 'tutor'?currentLesson.lesson.student:currentLesson.lesson.tutor }}</span>
					<span class="v-feedback-subject text-center mb-2">{{ currentLesson.lesson.subject }}</span>
					<span class="v-feedback-duration mb-5"><span class="duration">{{ $t('modals.feedback.duration') }}: </span>{{ currentLesson.lesson.duration }}</span>
					<label class="txt-review mb-1 w-100" for="feedback-review">{{ $t('modals.feedback.review') }}</label>
					<textarea class="w-100 mb-3" :class="{ wrg: wrg }" ref="textarea" id="feedback-review" v-model.trim="form.comment" :placeholder="$t('modals.feedback.placeholder')" maxlength="255"></textarea>
					<star-rating class="stars d-flex justify-content-center mb-3" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" :star-size="35" :border-width="0.1" :show-rating="false" inactive-color="#FCE5BF" active-color="#F5A623" v-model="form.rating"/>
				</div>
				<div class="pl-4 pr-4 pb-4">
					<v-btn class="btn-accept mb-3 w-100" :txt="$t('modals.feedback.btn')" :btn="currentLesson.lesson.type!=='Grupal'?'main':'warning'" height="44px" fontSize="20px" @click.native="send()" :disabled="$v.form.$invalid || sending"/>
				</div>
			</perfect-scrollbar>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	import {  maxLength, minLength, minValue, required } from 'vuelidate/lib/validators';
	export default{
		mixins: [ modal ],
		data(){
            return{
            	sending: false,
            	openModal: '',
                wrg: false,
            	form: {
            		comment: '',
            	    rating: 0
            	}
            }
		},
		computed:{
			...mapState(['auth','currentLesson']),
			...mapGetters(['modalVal'])
		},
		methods:{
			...mapMutations(['setNotification','setpendingFeedback','setModal']),
			...mapActions(['getLesson', 'axiosRequest']),
			send(){
				if (this.auth.commentRequired && this.form.comment.length == 0) {
					this.sending = false;
					this.wrg = true;
					this.$refs.textarea.focus();
				}
				else if (!this.$v.form.$invalid && !this.sending){
					this.sending = true;
                    this.$Progress.start();
					this.axiosRequest({ config : {
						method: 'PATCH',
						url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/feedback/${this.auth.permission}`,
						params: this.form,
						headers: { authorization : this.auth.token }
					}}).then( resp => resp.data)
					.then( data => {
						this.sending = false;
                        this.$Progress.finish();
						this.setNotification({ type: data.type, msg: data.message });
						const newArray = this.auth.pendingFeedback.filter( i => i.id+'' !== this.currentLesson.lesson.id+'');
						this.setpendingFeedback(newArray);
						if (this.auth.pendingConfirmation.length > 0)
							this.openModal = 'v-modal-crud:confirmLesson';
						else if (newArray.length > 0){
							this.openModal = 'v-modal-feedback';
						}
                        this.close();
					}).catch( err => {
						this.sending = false;
                        this.$Progress.fail();
                        if(err.response.status == 422){
                        	this.setNotification({ type: 'warning', msg: err.response.data.error.message });
                        	const newArray = this.auth.pendingFeedback.filter( i => i.id+'' !== this.currentLesson.lesson.id+'');
						    this.setpendingFeedback(newArray);
                        	this.close();
                        }
					});
				}
			},
			afterClose(){
				if (this.openModal == 'v-modal-feedback'){
					this.setModal('');
                    this.getLesson(this.auth.pendingFeedback[0].id);
				}
                else
                    this.setModal(this.openModal);
            }
		},
		validations() {
			return{
				form: {
					comment: {
						minLength: minLength(this.auth.commentRequired?3:0),
						maxLength: maxLength(255)
					},
					rating: {
                        minValue: minValue(1)
					}
				}
			}
        }
	}
</script>