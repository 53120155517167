import Vue from 'vue';
import router from '../../router.js';
import { firebaseAction } from 'vuexfire';

const loginFirebase = ({state, commit, dispatch}) => { // login firebase
	return new Promise((resolve, reject) => {
		commit('setUnsubscribe', Vue.prototype.$firebase.auth.onAuthStateChanged( user => { // Start function onAuthStateChanged
			if (!state.flag){ // Load once
				if (user){ // If user is login do this
					/*Vue.prototype.$firebase.analytics.setUserId(user.uid);
					Vue.prototype.$firebase.analytics.setUserProperties({
                        account_type: state.auth.permission // can help you to define audiences
                    });*/
					if (user.uid === state.auth.user.id+''){
					    commit('setUserFirebase', user);
					    commit('statusFlag', true);
					    dispatch('startEvents').then( resp => {
						    // If nofification is granted and user reload the page
						    // + Init onMessage and tokenRefresh
						    // + Get token
						    if (Vue.prototype.$firebase.messaging) {
							    if (!state.listenNotification) 
						            dispatch('onNotificationPermissionChange');	
						        if (Notification.permission === 'granted' && !state.tokenNotifications){
						    	    dispatch('msgAndTokenRefresh');
						            dispatch('getTokenNotifications');						    	
						        }
						    }
						    resolve(true);
					    } ).catch( err => {
						    reject(err);
					    } );
				    }else{
				    	resolve(true);
				    	if (Vue.prototype.$firebase.messaging) {
				    		if (Notification.permission === 'granted')
				    			Vue.prototype.$firebase.messaging.getToken().then( (token) => { 
				    				Vue.prototype.$firebase.messaging.deleteToken( token ).then( () => {
				    					console.log('Token deleted');
				    					dispatch('signOutFirebase', true);
				    				}).catch( err => {
				    					console.log('Unable to delete token.', err);
				    				});
				    			}).catch( (err) => {
				    				dispatch('signOutFirebase', true);
				    				console.log('Unable to retrieve refreshed token ', err);
				    			});
				    		else
				    		    dispatch('signOutFirebase', true); // logout last user and login current user
				        }
				    }
				}
				else{ // Login in firebase
					Vue.prototype.$firebase.auth.signInWithCustomToken(Vue.localStorage.get('tokenFirebase')).then( user => {
						commit('setUserFirebase', user.user);
						commit('statusFlag', true);
						Vue.prototype.$firebase.analytics.setUserId(user.user.uid);
					    Vue.prototype.$firebase.analytics.setUserProperties({
                            account_type: state.auth.permission // can help you to define audiences
                        });
                        Vue.prototype.$firebase.analytics.logEvent('login', { 'method': 'Google' });
						dispatch('startEvents').then( resp => {
				            // If nofification is granted and user login
						    // + Init onMessage and tokenRefresh
						    // + Get token
						    // + Save token in DB
						    if (Vue.prototype.$firebase.messaging) {
						    	if (!state.listenNotification) 
						    		dispatch('onNotificationPermissionChange');
						    	if (Notification.permission === 'granted' && !state.tokenNotifications){
						    		dispatch('msgAndTokenRefresh');
						    		dispatch('getTokenNotifications', true)
						    	}
						    }	
						    resolve(true);
						} ).catch( err => {
							reject(err);
						} );
					} ).catch( err => {
						reject(err);
					} );   
				}
		    }
		}))
    })
}

const getPermissions = ({dispatch}) => { // Get permissions
	Vue.localStorage.set('statusModalNotifications',1);
    Vue.prototype.$firebase.messaging.requestPermission().then( () => {
	    console.log('Notification permission granted.');
	} ).catch( err => {
	    console.log('Unable to get permission to notify.', err);
    } ); 
}

const getTokenNotifications = ({dispatch, commit}, save = false) => {  // Get token
	Vue.prototype.$firebase.messaging.getToken().then( (token) => { 
		console.log('Token created');
		commit('setTokenNotifications', token);

		if (save || token !== Vue.localStorage.get('tokenPushNotification'))
			dispatch('saveTokenNotification', token);
	}).catch( (err) => {
		console.log('Unable to retrieve refreshed token ', err);
	});
}

const msgAndTokenRefresh = async ({state, dispatch, commit}) => {
	await Vue.prototype.$firebase.messaging.onTokenRefresh( () => { // On token refresh
        	Vue.prototype.$firebase.messaging.getToken().then( (token) => {
        		console.log('Token refreshed');
        		commit('setTokenNotifications', token);
        		dispatch('saveTokenNotification', token);
        	}).catch( (err) => {
        		console.log('Unable to retrieve refreshed token ', err)
        	})
    });

    Vue.prototype.$firebase.messaging.onMessage( (payload) => { // Get foreground notifications
    	//console.log('Message received. ', payload);
    	console.log('Notification front', payload);
		if (payload.notification){

			const route = router.history.current;
			let send = true;
			if (route.name === `chat-${state.auth.permission}-id`){
				if (route.params.idlesson+'' === payload.data.lesson)
					send = false
			}
			else if(route.name === `room-${state.auth.permission}`){
				if(route.params.roomId+'' != payload.data.lesson || state.statusChatVideoRoom)
                    send = false;
			}
			
			if (send)
		        commit('setNotification',{ type: 'success', title: payload.notification.title, icon:'alarm-ringing', msg: payload.notification.body });
		}
	});
}

const endNotifications = ({state, commit}) => { // End push notifications
	if (Vue.prototype.$firebase.messaging) {
		if (state.tokenNotifications && Notification.permission === 'granted')
			Vue.prototype.$firebase.messaging.getToken().then( (token) => { 
				Vue.prototype.$firebase.messaging.deleteToken( token ).then( () => {
					console.log('Token deleted');
				}).catch( err => {
					console.log('Unable to delete token.', err);
				});
			}).catch( (err) => {
				console.log('Unable to retrieve refreshed token ', err);
			});	
	}
}

const saveTokenNotification = ({state, dispatch, commit}, token) => { // Save notification token
	let tokenBrowser = Vue.localStorage.get('tokenBrowser');
	Vue.localStorage.set('tokenPushNotification', token);
	if (!tokenBrowser) { // If tokenBrowser is empty, generate one
		console.log('New token browser (uid)');
        tokenBrowser = '';
        do { 
        	tokenBrowser += Math.random().toString(36).substr(2); 
        }while (tokenBrowser.length < 32);
        
        tokenBrowser = tokenBrowser.substr(0, 32);
        Vue.localStorage.set('tokenBrowser', tokenBrowser);
        commit('setTokenBrowser', tokenBrowser);
    }

	dispatch('axiosRequest', { config : {
        method: 'POST',
		url: `${state.auth.api}/user/${state.auth.user.id}/device`,
		data: {
            device_token : token,
            app_version: state.version,
            uid: tokenBrowser,
            device_model: state.currentBrowser.model,
            device_brand: state.currentBrowser.name,
            device_version: state.currentBrowser.version
		},
		headers: { Authorization : state.auth.token }
    }}).then( resp => resp.data.valid )
    .then( data => { 
    	if (data)
    		console.log('Save device token and browser info'); 
    	else{
    		console.log("Token isn't Registered");
    		if (state.contToken > 0){
    			commit('setContToken', state.contToken--);
    		    dispatch('getTokenNotifications', true)
    		}
    	}
    	
    } ).catch( err => { } )
}

const onNotificationPermissionChange = ({ state, commit, dispatch }) => { // On notification permission change
	navigator.permissions.query({name:'notifications'}).then( notificationPerm => {
		commit('setListenNotification', true);
		notificationPerm.onchange = () => {
			console.log("User changed notification permissions to: " +notificationPerm.state);
			if (state.token !== '' && state.permission !== '')
				if (notificationPerm.state === 'granted') {
					dispatch('msgAndTokenRefresh');
					commit('setContToken', 5);
					dispatch('getTokenNotifications', true);
				}
		}
	})
}

const signOutFirebase = ({state,commit, dispatch}, login=false) => { // Logout
	Vue.prototype.$firebase.auth.signOut().then( async () => {
		commit('setUserFirebase', '');
		if (state.unsubscribe){
		    await state.unsubscribe(); // Finish function onAuthStateChanged
		    if (login)
		        dispatch('loginFirebase');
		    else
		    	Vue.localStorage.remove('tokenFirebase');
		}
    }).catch(function(e) {
        console.log('$ Error on signOut user firebase: ', e);
    });
}

const getFirstMsg = ({ state, commit }, ref) => { // First msg
	ref.orderByChild('createdAt').limitToFirst(1).once('value').then( snapshot => {
		commit('setFirstMsg', snapshot.val());
	} )
}

const getlastMsgs = ({ state, commit }, ref) => {  // last Msgs
	ref.orderByChild('createdAt').limitToLast(50).once('value').then( snapshot => {
		if (snapshot.val()) {
			const msgs = Object.values(snapshot.val());
		    const lastMsgKey = Object.keys(snapshot.val())[0];
		    commit('setLastMsgVisible', { msg: msgs[0], key: lastMsgKey});
		    commit('setMessages', msgs);
		}else{
			commit('setLastMsgVisible', null);
		    commit('setMessages', []);
		}
	} )
}

const getNextMsgs = ({ state, commit }, ref) => { // msgs paginate
	ref.orderByChild('createdAt').limitToLast(50).endAt(state.lastMsgVisible.msg._id, state.lastMsgVisible.key).once('value').then( snapshot => {
		let msgs = Object.values(snapshot.val());
		const lastMsgKey = Object.keys(snapshot.val())[0];
		msgs.pop();
		commit('setLastMsgVisible', { msg: msgs[0], key: lastMsgKey});
		commit('setMessages', [ ...msgs, ...state.chat]);
	} )
}


const getNewMsgs = ({ state, commit }, ref) => { // New msgs
	ref.orderByChild('createdAt').limitToLast(8).on('value', snapshot => {
		if (snapshot.val() && state.statusChat){
		    commit('setMessages', [ ...state.chat, ...Object.values(snapshot.val()) ]);
		}
		commit('setStatusChat', true);
    })
}

const stopChatRef = ({commit} ,ref) => {
	if (ref){
		commit('setMessages', []);
        ref.off();
	}
}

const getLastNotifications = async ({ state, commit }, ref) => { // Last notification
    let n = await ref.orderByChild('profile').equalTo(state.auth.permission).limitToFirst(1).once('value');
    if (n.val())
        commit('handleLastestNof', Object.values(n.val())[0]);
}

const getFisrtNotifications =  async ({ state, commit }, ref) => { // First notifications
	commit('handleWaitNotification', true);
    const n = await ref.orderByChild('profile').equalTo(state.auth.permission).limitToLast(state.notifications.paginate).once('value');

    let arr = [];
    if (n.val()){
    	arr = Object.values(n.val()).sort( (a, b) => {
    		if (a.time < b.time) {
    			return 1;
    		}
    		if (a.time > b.time) {
    			return -1;
    		}
    		return 0;
    	});

    	commit('handleNotificationsList', arr);
        commit('handleLastVisibleNof', arr[arr.length -1]);
        commit('handleMissingNotifications', state.notifications.missing - arr.length);
    }

    commit('handleWaitNotification', false);
}

const getNextNotifications = async ({state, commit, dispatch }, ref) => { // Next notifications
	commit('handleWaitNotification', true);
	if ((state.notifications.missing - state.notifications.paginate) <= 0)
        await dispatch('getLastNotifications', ref);

    let n = await ref.orderByChild('time').startAt(state.notifications.lastest.time).endAt(state.notifications.lastVisible.time).limitToLast(state.notifications.paginate+1).once('value');

    const list = state.notifications.list;
    if (n.val()){
    	n = Object.values(n.val());
    	n.pop();
    	n.sort( (a, b) => {
    		if (a.time < b.time)
    			return 1;
    		if (a.time > b.time)
    			return -1;

    		return 0;
    	});

    	if((n.length + list.length) > state.notifications.total){
			n.splice(state.notifications.total - list.length);
			commit('handleLastestNof', n[n.length - 1]);
		}

    	commit('handleNotificationsList', [ ...list, ...n ]);
        commit('handleLastVisibleNof', n[n.length -1]);
        commit('handleMissingNotifications', state.notifications.missing - n.length);
    }
    
    commit('handleWaitNotification', false);
}

const getNewsNotifications = ({state, commit}, ref) => { // News notifications
    ref.orderByChild('profile').equalTo(state.auth.permission).limitToLast(5).on('value', snapshot => {
		let n = snapshot.val();
		const lastTotal = state.notifications.list.length,
		      list = state.notifications.list;

		if(n){
			n = Object.values(n);

			n = n.reduce( (arr, item) => {
				const band = state.notifications.list.find( i => i.time == item.time );
				if(!band)
					arr = [ ...arr, item ];

				return arr;
			},[]).sort( (a, b) => {
				if (a.time < b.time)
					return 1;
				if (a.time > b.time)
					return -1;

				return 0;
			});

			if(n.length + list.length > state.notifications.total){
				list.splice(state.notifications.total - (n.length + list.length - state.notifications.total));
				commit('handleLastestNof', list[list.length - 1]);
			}

			commit('handleNotificationsList', [ ...n, ...list ]);
			commit('handleLastVisibleNof', list[list.length -1]);
			commit('handleMissingNotifications', state.notifications.missing - n.length);
	    }		
    })
}

const resetNotificationsCount = async ({ state, commit }, ref) => { // Reset count
	commit('handleWaitNotification', true);
    try {
		await ref.orderByChild('seen').equalTo(0).once('value', snap => {
            var updates = {};
            snap.forEach(child => {
                if (child.val().profile === state.auth.permission) {
                    let data = child.val().time.split("_");
                    let id = data[1];
                    updates[`notifications/${state.auth.user.university_id}/${state.auth.user.id}/notificationsCenter/` + id + '/seen'] = 1;
					const i = state.notifications.list.findIndex( i => i.time == child.val().time );
                    if (i != -1)
                	    commit('handleNotificationSeen', i);
                }
            });
            console.log("$ UPDATES", updates);
			Vue.prototype.$firebase.db.ref().update(updates);
        });

        await Vue.prototype.$firebase.db.ref().child(`/total_counter/${state.auth.user.university_id}/${state.auth.user.id}/notifications`).update({ [`${state.auth.permission}_counter`]: 0 });

        if(state.activeLists == 0){
            ref.off();
			commit('clearnotifications');
		}

        commit('handleWaitNotification', false);
        console.log('Transaction success!');
    } catch (e) {
        console.log('Transaction failure:', e);
    }
}

const startEvents = firebaseAction( ({ state, dispatch, bindFirebaseRef }) => {
	return new Promise((resolve, reject) => {
		bindFirebaseRef('events', Vue.prototype.$firebase.db.ref().child(`/events/${state.auth.user.id}`)).then( resp => {
			resolve(true);
		} ).catch( err => {
			reject(false);
		} );
		bindFirebaseRef('msgs', Vue.prototype.$firebase.db.ref().child(`/chat_counter/${state.auth.user.university_id}/${state.auth.user.id}`));
		bindFirebaseRef('counters', Vue.prototype.$firebase.db.ref().child(`/total_counter/${state.auth.user.university_id}/${state.auth.user.id}`));
    })
});

const stopEvents = firebaseAction(({ unbindFirebaseRef }) => {
    unbindFirebaseRef('events');
    unbindFirebaseRef('msgs');
    unbindFirebaseRef('counters');
});

const changeCont = async ({state}, payload) => {
	await payload.db.update({ counter: 0 });
	await Vue.prototype.$firebase.db.ref().child(`/total_counter/${state.auth.user.university_id}/${state.auth.user.id}/chats`).update({ [`${state.auth.permission}_counter`]: payload.counter });
}

export default{
	loginFirebase,
	getPermissions,
	msgAndTokenRefresh,
	getTokenNotifications,
	saveTokenNotification,
	onNotificationPermissionChange,
	endNotifications,
	signOutFirebase,
	getFirstMsg,
	getlastMsgs,
	getNextMsgs,
	getNewMsgs,
	stopChatRef,
	getLastNotifications,
	getFisrtNotifications,
	getNextNotifications,
	getNewsNotifications,
	resetNotificationsCount,
	startEvents,
	stopEvents,
	changeCont
}