var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "reset-password-success" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c("div", { staticClass: "v-paper w-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3"
            },
            [
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("confirmEmailSuccess.t")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4 d-flex flex-column align-items-center" },
            [
              _c(
                "div",
                {
                  staticClass: "bg-icon mb-4",
                  style: { background: "#72BD00" }
                },
                [
                  _c("svgicon", {
                    staticClass: "inset-icon",
                    attrs: { name: "paper-plane", color: "#fff" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                staticClass: "v-if-txt text-center pb-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("confirmEmailSuccess.msg2", { email: _vm.userEmail })
                  )
                }
              }),
              _vm._v(" "),
              _c("v-btn", {
                staticClass: "w-100 mt-4",
                attrs: {
                  fontSize: "1rem",
                  fontWeight: "400",
                  txt: _vm.$t("confirmEmailSuccess.btn"),
                  name: "institutionalEmail",
                  height: "40px"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.redirect()
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }