var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "roomOboarding" } },
    [
      _c(
        "header",
        {
          staticClass: "pl-4 pr-4",
          class:
            _vm.userType == "tutor"
              ? "bgColor-tutor-main"
              : "bgColor-student-main"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "row h-100 justify-content-between align-items-center"
            },
            [
              _c(
                "div",
                { staticClass: "col h-100 d-flex align-items-center logo" },
                [
                  _c("router-link", {
                    attrs: {
                      src: _vm.institution.dashboard_logo,
                      alt: _vm.institution.name,
                      tag: "img",
                      to: { name: `dashboard-${_vm.userType}` }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-auto h-100 d-flex align-items-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn-exit d-flex align-items-center pt-3 pr-sm-2 pr-1 pb-3 pl-sm-2 pl-1",
                      attrs: {
                        to: { name: `dashboard-${_vm.userType}` },
                        tag: "button"
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "icon-exit mr-sm-1",
                        attrs: { name: "exit" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "exit-txt d-none d-sm-block" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("room.onboarding.header.btnExit"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-roomOboarding container-xl pl-4 pr-4 pl-xl-3 pr-xl-3 pt-4 pt-sm-5 pb-4 pb-sm-5 d-md-flex align-items-md-center"
        },
        [
          _c("div", { staticClass: "w-100" }, [
            _c(
              "div",
              {
                staticClass: "row align-items-center justify-content-lg-between"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-7 col-sm-10 col-12 offset-md-0 offset-sm-1 order-0"
                  },
                  [
                    _c("v-onboarding-video", {
                      ref: "camera",
                      staticClass: "w-100 position-relative",
                      attrs: {
                        id: "ovVideo",
                        loading: _vm.loading,
                        deviceId:
                          _vm.muteCam &&
                          _vm.permissionCam != _vm.typePermission.denied
                            ? null
                            : _vm.currentCam,
                        camMsg:
                          _vm.muteCam && _vm.options.camReady
                            ? _vm.typePermission.mute
                            : _vm.permissionCam,
                        mediaOpc: {
                          muteMic: _vm.muteMic,
                          muteCam: _vm.muteCam,
                          volume: _vm.volume
                        }
                      },
                      on: {
                        handleCam: function($event) {
                          return _vm.$emit(
                            "handleDevice",
                            _vm.loading ? "cam" : undefined
                          )
                        },
                        resetVideoSettings: _vm.resetVideoSettings,
                        handleStream: _vm.handleStream,
                        handleMic: function($event) {
                          return _vm.handleAudioInput()
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 col-sm-10 col-12 order-1 order-md-2 mt-3 mb-4 offset-md-0 offset-sm-1"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row ml-n1 mr-n1 justify-content-center justify-content-md-start"
                      },
                      [
                        _c("div", { staticClass: "col-auto pl-1 pr-1 mb-2" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-settings d-flex align-items-center",
                              style: {
                                "--pColor":
                                  _vm.userType == "student"
                                    ? "#2288fc"
                                    : "#005E58"
                              },
                              attrs: { disabled: !_vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.handleModalSettings(true)
                                }
                              }
                            },
                            [
                              _c("svgicon", {
                                staticClass: "noun-icon mr-2",
                                attrs: { name: "noun_check", color: "#00C0ff" }
                              }),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t("room.onboarding.btnSettings")
                                  ) +
                                  "\n                                "
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-auto pl-1 pr-1 d-block d-md-none mb-2"
                          },
                          [
                            _c("v-btn", {
                              staticClass: "btn-join pl-sm-4 pr-sm-4",
                              attrs: {
                                height: "37px",
                                fontSize: "13px",
                                txt: _vm.$t("room.onboarding.btnJoin"),
                                disabled: !_vm.loading
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.joinRoom.apply(null, arguments)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "info-session col-md-5 col-sm-10 col-12 offset-md-0 offset-sm-1 order-2 order-md-1"
                  },
                  [
                    _c("h1", { staticClass: "ro-title mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("room.onboarding.lessonDetail.t")))
                    ]),
                    _vm._v(" "),
                    _vm.lesson
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row ml-n1 mr-n1 align-items-center mb-3"
                              },
                              [
                                _c("v-lesson-photo", {
                                  staticClass: "col-auto pl-2 pr-2",
                                  attrs: { options: _vm.lessonPhotoOpc }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col pl-2 pr-2",
                                    style: {
                                      maxWidth: `calc(100% - ${_vm.lessonPhotoOpc.size})`
                                    }
                                  },
                                  [
                                    _c("v-hover-txt", {
                                      staticClass: "w-100 mb-2",
                                      attrs: {
                                        txt: _vm.name,
                                        fontSize: "16px",
                                        fontWeight: "400"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row ml-n1 mr-n1" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-auto pl-1 pr-1" },
                                          [
                                            _c("v-lesson-tag", {
                                              attrs: {
                                                type: _vm.lesson.type,
                                                options: {
                                                  ..._vm.tagTypeOpc,
                                                  minWidth: "80px"
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-auto pl-1 pr-1" },
                                          [
                                            _c("v-lesson-tag", {
                                              attrs: {
                                                type: _vm.lesson.modality,
                                                options: { minWidth: "80px" }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ro-subject d-flex align-items-center position-relative pl-4"
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "ro-icon",
                                  attrs: {
                                    name: _vm.lesson.icon,
                                    color: "#000"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ro-txt" }, [
                                  _vm._v(_vm._s(_vm.lesson.subject))
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.lesson.topic &&
                            ((_vm.userType == "student" &&
                              _vm.lesson.type != "Grupal") ||
                              _vm.userType == "tutor")
                              ? _c("v-lesson-topic", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    options: {
                                      lessonId: _vm.lesson.id,
                                      topic: _vm.lesson.topic,
                                      hideBtn: true
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-lesson-schedule", {
                              staticClass: "mb-4 mt-3",
                              attrs: {
                                options: {
                                  date: _vm.lesson.scheduledDate,
                                  startTime: _vm.lesson.scheduledTime,
                                  endTime: _vm.lesson.endScheduledTime,
                                  spt: true
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("v-btn", {
                              staticClass: "d-none d-md-block",
                              attrs: {
                                min: "190px",
                                height: "48px",
                                fontSize: "16px",
                                txt: _vm.$t("room.onboarding.btnJoin"),
                                disabled: !_vm.loading
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.joinRoom.apply(null, arguments)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "pt-3 pb-4 text-center" },
                          [
                            _c("v-spinner", {
                              attrs: { height: "50px", width: "50px" }
                            })
                          ],
                          1
                        )
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.statusModalSettings && _vm.loading
        ? _c(
            "v-room-settings",
            {
              ref: "modal-settings",
              attrs: {
                showModal: _vm.statusModalSettings,
                audioMute: _vm.muteMic,
                volume: _vm.volume,
                statusTestSound: _vm.options.statusTestSound
              },
              on: {
                close: function($event) {
                  _vm.statusModalSettings = false
                },
                playTestSound: function($event) {
                  return _vm.$emit("playTestSound")
                }
              }
            },
            [
              _c(
                "template",
                { slot: "video" },
                [
                  _c("v-onboarding-video", {
                    key: _vm.videoSettings,
                    ref: "video",
                    staticClass: "w-100 h-100 position-relative",
                    attrs: {
                      deviceId: _vm.muteCam ? null : _vm.currentCam,
                      camMsg:
                        _vm.muteCam &&
                        _vm.permissionCam == _vm.typePermission.granted
                          ? _vm.typePermission.mute
                          : _vm.permissionCam,
                      showActions: false,
                      loading: _vm.loading,
                      bgColor: _vm.muteCam ? "transparent" : "#000",
                      stream: _vm.videoStream
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }