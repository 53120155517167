
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';

    @Component
    export default class formatQuestionYesNo extends Vue {
        @Prop({ type: Array, default: [] }) readonly questions!: Array<object>;
        @Prop({ type: String, default: '' }) readonly score!: string;
        @Prop({ type: Boolean, default: false }) readonly error!: boolean;
        @Prop({ default: false }) readonly statusFormat!: boolean;
        @Prop() readonly value!: any;

        questionsVal: any = [];
        status: boolean = false;

        get emit(): any{
            return (this as any).$emit;
        }

        created(): void{
            this.questionsVal = this.questions.reduce( (arr: any, item: any) => {
                const q = { id: item.id, answer: null };

                if(this.statusFormat)
                    q.answer = item.answer;

                arr = [ ...arr, q ];

                return arr;
            }, []);
        }

        handleAnswer(a: any): void{
            const i = this.questionsVal.findIndex( q => q.id == a.id );
            this.questionsVal[i].answer = a.answer;

            let emptyQuestion: boolean = false,
                contNoAnswers: number = 0;
            
            this.questionsVal.forEach( item  => {
                if(item.answer == null)
                   emptyQuestion = true;
                else if(item.answer == 0)
                   contNoAnswers += 1;
            });
                
            this.status = !!emptyQuestion;
            
            this.emit('input', { questions: this.questionsVal, status: !emptyQuestion, cont: contNoAnswers });
        }
    }    
