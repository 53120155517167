<template>
	<div id="history">
		<section class="past-lessons" ref="lessons">
			<div class="row mb-3 align-items-center">
                    <div class="col-6">
                    <h4 class="mb-0 main-title">{{ currentDate }}</h4>
                    </div>
                    <div class="col-6 pr-4 text-right d-flex justify-content-end">
                         <div class="v-select-v2 position-relative" color="green">
                              <select class="select-content" name="" id="" v-model="valDate" @change="changeDate()">
                                   <option :value="date.month.n+'-'+date.year" v-for="date in filterDates" selected>{{ `${date.month.txt} ${date.year}` }}</option>
                              </select>
                              <div class="icon-select">
                                   <svgicon class="icon" color="#005E58" name="chevron-down"></svgicon>
                              </div>
                         </div>
                    </div>
               </div>
               <transition name="bound" mode="out-in">
                    <div class="lesson-spinner w-100 p-4 text-center" key="spinner" v-if="spinner">
                         <v-spinner />
                    </div>
                    <div class="basic-paper empty-lessons d-flex flex-column align-items-center pt-4 pb-3 pl-3 pr-3" key="alert" v-else-if="!spinner && lessons.length === 0">
                         <svgicon class="img mb-2" original name="dashboardt1" />
                         <p class="txt mb-1">{{ $t('msgEmpty.msgPast') }}</p>
                    </div>
                    <div class="lessons" key="lessons" v-else>
                         <div class="row mr-0 ml-0">
                              <v-lesson-history class="w-100 mb-3" :lesson="lesson" :w="cardW" v-for="(lesson,index) in lessons" :key="lesson.id"/>
                              <!--<v-history-tutor :lesson="lesson" v-for="(lesson,index) in lessons" :key="lesson.id"/>-->
                         </div>
                    </div>
               </transition>
		</section>
	</div>
</template>
<script>
     import { mapState, mapActions } from 'vuex';
     export default{
          data(){
               return{
                    spinner: true,
                    first_lesson: null,
                    serverTime: null,
                    pendingLessonsResults: 10,
                    lessons: [],
                    valAxios: {
                        month: '',
                        year: '',
                    },
                    valDate: '',
                    cardW: undefined
               }
          },
          computed:{
               ...mapState(['auth' ,'i18n', 'menu']),
               filterDates(){
                    let dates = [];
                    const firstD = this.$moment.utc(this.first_lesson).locale(this.i18n.locale),
                    actualD = this.$moment(this.serverTime).locale(this.i18n.locale).add(1, 'months');
                    while(`${firstD.format("M")} ${firstD.year()}` !== `${actualD.format("M")} ${actualD.year()}`){
                         const txt = firstD.format("MMMM");
                         dates.push({ month: { n : firstD.format("M"), txt : txt.charAt(0).toUpperCase()+txt.slice(1) }, year: firstD.year() })
                         firstD.add(1, 'months');
                    }
                    return dates; 
               },
               currentDate(){
                  const date = this.filterDates.find( i => i.year == this.valAxios.year+'' && i.month.n == this.valAxios.month);
                  if (date)
                    return `${date.month.txt} ${this.valAxios.year}`; 
                  else
                    return '';
               }
          },
          methods:{
               ...mapActions(['axiosRequest']),
               getLessons(){
                    this.spinner = true;
                    this.axiosRequest({ config : {
                         method: 'GET',
                         url: `${this.auth.api}/history/t`,
                         params: ({ month: this.valAxios.month, year: this.valAxios.year }),
                         headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then( data => {
                         setTimeout( () => {
                              this.lessons = data.lessons;
                              this.first_lesson = data.first_lesson? data.first_lesson: this.$moment.utc();
                              this.serverTime = data.serverTime;
                              this.spinner = false;
                         },500)
                    }).catch( error => {
                         this.spinner = false;
                    });
               },
               changeDate(){
                    const date = this.valDate.split('-');
                    this.valAxios.month = date[0];
                    this.valAxios.year = date[1];      
               },
               getCardW(){
                    this.cardW = this.$refs.lessons.offsetWidth;
               },
               getScrollbarWidth() {
                   // Creating invisible container
                   const outer = document.createElement('div');
                   outer.style.visibility = 'hidden';
                   outer.style.overflow = 'scroll'; // forcing scrollbar to appear
                   outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
                   document.body.appendChild(outer);

                   // Creating inner element and placing it in the container
                   const inner = document.createElement('div');
                   outer.appendChild(inner);

                   // Calculating difference between container's full width and the child width
                   const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

                   // Removing temporary elements from the DOM
                   outer.parentNode.removeChild(outer);

                   return scrollbarWidth;
              }
          },
          created() {
               const date = new Date();
               this.valAxios.month = date.getMonth()+1,
               this.valAxios.year = date.getFullYear();
               this.valDate = (date.getMonth()+1)+'-'+date.getFullYear();
          },
          mounted(){
               this.cardW = this.$refs.lessons.offsetWidth - this.getScrollbarWidth();
               window.addEventListener('resize', this.getCardW);
          },
          beforeDestroy(){
               window.removeEventListener('resize', this.getCardW);
          },
          watch:{
               valDate : function(){
                    this.getLessons();
               },
               'menu.compact': function(){
                    setTimeout(() => {
                        this.getCardW();
                    }, 500)
               }
          }
     }
</script>