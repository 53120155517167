var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-4", attrs: { id: "calendar" } }, [
    _c(
      "section",
      {
        staticClass:
          "calendar-lessons p-4 p-md-5 d-flex justify-content-center position-relative"
      },
      [
        _c("div", { staticClass: "content" }, [
          _c(
            "h1",
            { staticClass: "title mb-3" },
            [
              _c("svgicon", {
                staticClass: "icon",
                attrs: { name: "calendar-full" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("calendar.title")) + " ")
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pl-sm-3 pr-sm-3 pl-md-5 pr-md-5" }, [
            _c("p", {
              staticClass: "text b-line pb-4 mb-4",
              domProps: { innerHTML: _vm._s(_vm.$t("calendar.descripHtml")) }
            }),
            _vm._v(" "),
            _c("h3", { staticClass: "title-url mb-3" }, [
              _vm._v(_vm._s(_vm.$t("calendar.titleUrl")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "content-url pl-2 pr-2 pl-sm-3 pr-sm-3 pl-md-5 pr-md-5 mb-5"
              },
              [
                _c("p", {
                  staticClass: "text mb-3",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("calendar.descripUrlHtml"))
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "link-calendar position-relative mb-2",
                    class: { disabled: _vm.auth.user.disabled_calendar }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getLink,
                          expression: "getLink"
                        }
                      ],
                      attrs: {
                        id: "input-clipboard",
                        type: "link",
                        readonly: "readonly"
                      },
                      domProps: { value: _vm.getLink },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.getLink = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      { staticClass: "btn-copy", on: { click: _vm.copyURL } },
                      [_vm._v(_vm._s(_vm.$t("calendar.btnCopy")))]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-8 text-center text-md-right" },
                    [
                      _c("span", {
                        staticClass: "text-enabled",
                        domProps: {
                          innerHTML: _vm._s(
                            !_vm.auth.user.disabled_calendar
                              ? _vm.$t("calendar.enabledTxt")
                              : _vm.$t("calendar.disabledTxt")
                          )
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.auth.user.disabled_calendar
                    ? _c(
                        "div",
                        { staticClass: "col-md-4 text-center text-md-left" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn-disable",
                              on: {
                                click: function($event) {
                                  return _vm.changeStatusCalendar({
                                    disabled_calendar: 0
                                  })
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("calendar.btnEnabled")))]
                          )
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "col-md-4 text-center text-md-left" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn-enabled-s color-student-main",
                              on: {
                                click: function($event) {
                                  return _vm.changeStatusCalendar({
                                    disabled_calendar: 1
                                  })
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("calendar.btnDisable")))]
                          )
                        ]
                      )
                ])
              ]
            ),
            _vm._v(" "),
            _c("h3", { staticClass: "title-calendars mb-3" }, [
              _vm._v(_vm._s(_vm.$t("calendar.instructions")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content-calendars pl-3 pr-3 pl-md-5 pr-md-5 row",
                class: { disabled: _vm.auth.user.disabled_calendar }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn-calendar pt-3 pb-3 col-12 text-center",
                    attrs: { href: _vm.$t("calendar.icloud"), target: "_blank" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/media/images/calendar/icloud.png",
                        alt: _vm.$t("image.icloud")
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn-calendar pt-3 pb-3 col-12 text-center",
                    attrs: { href: _vm.$t("calendar.google"), target: "_blank" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/media/images/calendar/google.png",
                        alt: _vm.$t("image.google")
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn-calendar pt-3 pb-3 col-6 text-right",
                    attrs: {
                      href: _vm.$t("calendar.outlook"),
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/media/images/calendar/outlook.png",
                        alt: _vm.$t("image.outlook")
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn-calendar pt-3 pb-3 col-6",
                    attrs: {
                      href: _vm.$t("calendar.outlook"),
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/media/images/calendar/exchange.png",
                        alt: _vm.$t("image.exchange")
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("svgicon", {
          staticClass: "trello-left d-none d-sm-block",
          attrs: { name: "trello-3", original: "" }
        }),
        _vm._v(" "),
        _c("svgicon", {
          staticClass: "trello-right d-none d-sm-block",
          attrs: { name: "trello-1", original: "" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }