/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-bound': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M23.41 11.996a3.6 3.6 0 01-1.722 4.158h0L7.686 23.856c-.871.48-1.854.56-2.739.303a3.6 3.6 0 01-2.596-3.457h0V5.298c0-.994.403-1.894 1.054-2.546a3.6 3.6 0 014.28-.609h0L21.69 9.847a3.589 3.589 0 011.722 2.15z" _fill="#72BD00" _stroke="#72BD00" stroke-width="3.3" fill-rule="evenodd" stroke-opacity=".238"/>'
  }
})
