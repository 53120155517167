/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen-share': {
    width: 33,
    height: 24,
    viewBox: '0 0 33 24',
    data: '<path pid="0" d="M21.56 9.04c.387 0 .737.157.99.41s.41.603.41.99h0v10.88c0 .387-.157.737-.41.99s-.603.41-.99.41h0H2c-.387 0-.737-.157-.99-.41a1.396 1.396 0 01-.41-.99h0V10.44c0-.387.157-.737.41-.99s.603-.41.99-.41h0zm9-8.44c.387 0 .737.157.99.41s.41.603.41.99h0v10.88c0 .387-.157.737-.41.99s-.603.41-.99.41h0-4.4V9c0-.683-.263-1.304-.694-1.768a2.594 2.594 0 00-1.736-.827h0L9.6 6.4V2c0-.387.157-.737.41-.99S10.613.6 11 .6h0z" _stroke="#FFF" stroke-width="1.2" _fill="none" fill-rule="evenodd"/>'
  }
})
