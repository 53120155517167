const addInProgressFile = (state, file) => {
	state.chatFiles.inProgress[file.file] = { progress: file.progress, source: file.source };
}

const removeInProgressFile = (state, file) => {
	delete state.chatFiles.inProgress[file.file];
}

const handleProgress = (state, file) => {
	state.chatFiles.inProgress = { ...state.chatFiles.inProgress, [file.file]: { progress: file.progress, source: file.source } };
}

const addDownloadingFile = (state, file) => {
	const keys = Object.keys(state.chatFiles.downloading);
	if (keys.length >= 30){
		delete state.chatFiles.downloading[keys[0]];
	}
	state.chatFiles.downloading[file.file] = { url: file.blod, name: file.name };
}

export default{
	addInProgressFile,
	removeInProgressFile,
	handleProgress,
	addDownloadingFile
}