<template>
	<div id="availability" class="container-fluid pb-5">
		<div class="paper ml-sm-0 d-flex flex-wrap mr-sm-0 pl-md-4 pr-md-4 pb-sm-5 pt-md-4 pb-4 justify-content-center">
			<div class="content-availability w-100">
				<!-- =============== title ============== -->
				<div class="bgColor-tutor-main ml-0 mr-0 mb-4 overflow-hidden position-relative">
					<div class="p-3 p-sm-4 content-title">
						<h2 class="title-availability mb-2">{{ title.t }}</h2>
						<p class="descrip-availability mb-0 pl-0 pr-0 col-md-10">{{ title.d }}</p>
					</div>
					<div class="icon-clock pr-4 pl-0 d-none d-md-block">
					    <svgicon class="icon" name="clock" />
					</div>
				</div>

                <!-- ============== Toolbar ============= -->
                <div class="toolbar-calendar navbar navbar-expand flex-wrap p-0 mb-2 pl-3 pr-3 pl-md-0 pr-md-0">
                    <!-- ============== Btns toolbar ============ -->
                	<nav class="collapse navbar-collapse order-1 order-sm-0" v-if="view === 'calendar'">
                		<ul class="btns-calendar-view navbar-nav mr-auto">
                			<li class="nav-item pl-4 pr-4 pt-2 pb-2" :class="{ active: calendar.mode === 'dayGridWeek' }" @click="setCalendarHistory({ ...calendar, mode:'dayGridWeek'})">{{ $t('availability.calendar.week') }}</li>
                			<li class="nav-item pl-4 pr-4 pt-2 pb-2" :class="{ active: calendar.mode === 'dayGridMonth' }" @click="setCalendarHistory({ ...calendar, mode:'dayGridMonth'})">{{ $t('availability.calendar.month') }}</li>
                		</ul>
                	</nav>
                    
                    <!-- ============== btn prev section ============ -->
                	<button class="btn-prev mr-auto p-0 mt-2 mb-2 order-1 order-sm-0" @click="changeview(view==='customize'?'subjects':'calendar')" v-else> <svgicon class="icon mr-3" name="chevron-left" /> {{ view === 'customize'?$t('availability.toolbar.prevSubjects'):$t('availability.toolbar.prevCalendar') }} </button>

                    <!-- ============== Switch vacation mode ============== -->
                	<div class="vacation-mode d-flex align-items-center justify-content-end order-0 order-sm-1 mb-1 mb-sm-0">
                		<div class="icon-plane mr-2">
                			<svgicon class="icon" name="plane" />
                		</div>
                		<span class="txt-vacation-mode">{{ $t('availability.toolbar.vacationMode') }}</span>
                        <v-more-info class="ml-1 mr-2" minWidthCloud="250px" :txtCloud="$t('moreInfo.infoVacationsMode')" />
                		<div class="switch" :class="{ active : auth.user.vacation_mode }" @click="showModalVacationMode = true"></div>
                	</div>
                </div>
                
                <div class="position-relative pl-3 pr-3 pl-md-0 pr-md-0">
                    <transition name="bound" mode="out-in">
                        <div class="position-relative" key="calendar" v-if="view === 'calendar'">
                	        <!-- ============ Edit by subject ============= -->
                	        <div class="d-flex justify-content-end mb-4 mb-sm-2">
                		        <button class="editBySubject" @click="view = 'subjects'">
                			        <svgicon class="icon" name="pencil-ruler2" />
                			        {{ $t('availability.toolbar.editBySubjects') }}
                		        </button>
                	        </div>

                	        <!-- ================ Full calendar ================== -->
                            <v-full-calendar :minRangetime="minRangetime" :initTime="initTime" :endTime="endTime" :disabledDisponibility="disabledDisponibility?true:false" ref="fullCalendar" v-model="generalSchedule" v-if="generalSchedule.size > 0" />
                        </div>

                        <!-- ============== Subjects ============== -->
                        <div class="pt-4" key="subjects" v-else-if="view === 'subjects'">
                        	<v-assigned-subjects @customizeSubject="sub => { subject = sub; changeview('customize') }" />
                        </div>

                        <!-- ================ Customize subject =============== -->
                        <div class="pt-2" key="customize" v-else-if="view === 'customize'">
                        	<v-customize-subject ref="customize" :subject="subject" :generalSchedule="generalSchedule" :initTime="initTime" :endTime="endTime" :minRangetime="minRangetime" :disabledDisponibility="disabledDisponibility?true:false" />
                        </div>
                    </transition>

                    <!-- =========== Msg vacation mode ========== -->
                    <div class="mode-vacation-mode" :class="{ active: showMsgVacationMode }" v-if="statusMsgVacationMode">
                	    <div class="info-vacation-mode">
                		    <h4 class="title mb-2">
                			    <div class="icon-plane mr-2">
                				    <svgicon class="icon" name="plane" />
                			    </div>
                			    {{ $t('availability.vacationMode.t') }}
                		    </h4>
                		    <p class="descrip mb-0">{{ $t('availability.vacationMode.d') }}</p>
                	    </div>
                    </div>
                </div>	
			</div>
	    </div>

	    <!-- ============= Modal vacation mode =============== -->
	    <v-vacation-mode @closeVacationMode="showModalVacationMode = false;" :show="showModalVacationMode" />
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		data(){
			return{
				statusMsgVacationMode: false, // Add or remove msg vacation mode
				showMsgVacationMode: false, // Show or hide msg vacation mode
				showModalVacationMode: false, // Show or hide modal vacation mode
                disabledDisponibility: false,
				view: 'calendar', // calendar, subjects, customize
                initTime: '06:00',
                endTime: '23:00',
                minRangetime: 5, 
				generalSchedule: new Map(), // General Schedule
				subject: {},
			}
		},
		computed:{
			...mapState(['calendar', 'auth' ]),
            title(){
            	if (this.view === 'calendar')
            		return { t: this.$t('availability.calendar.t'), d: this.$t('availability.calendar.d') }
            	else
            		return { t: this.$t('availability.customize.t'), d: this.$t('availability.customize.d') }
            }
		},
		created(){
            this.statusMsgVacationMode = this.auth.user.vacation_mode?true:false;
            this.getSchedule();
		},
        beforeDestroy(){ // Remove all history
            this.setCalendarHistory({ mode: 'dayGridMonth', defaultDate: null });
            this.setAssignedSubjectsHistory({ subjects: [], filterSubjects: [], page: 1, filters: { orderBy: '', subject: 'asc', duration: 'asc', search: '', type: 'All', mode: 'All' } });
            this.setCutomizeSubjectHistory({idSubject: null, statusPlaces: false, statusSchedule: false});
        },
		methods:{
            ...mapMutations(['setCalendarHistory', 'setAssignedSubjectsHistory', 'setCutomizeSubjectHistory']),
			...mapActions(['axiosRequest']),
			getSchedule(){ // Get general Schedule
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/tutor/${this.auth.user.id}/schedules`,
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data.data )
                .then( data => {
                    this.disabledDisponibility = data.disabledDisponibility;
                    this.getSchedules(data.schedules).then( sch => {
                        this.generalSchedule = sch;
                    });
                }).catch( error => {
                    console.log(error)
                });
			},
			async getSchedules(data){ // Restructure array Schedule by day
                let sch = new Map();

                await sch.set('Domingo', this.getScheduleByDay(data.Sunday));
                await sch.set('Lunes', this.getScheduleByDay(data.Monday));
                await sch.set('Martes', this.getScheduleByDay(data.Tuesday));
                await sch.set('Miercoles', this.getScheduleByDay(data.Wednesday));
                await sch.set('Jueves', this.getScheduleByDay(data.Thursday));
                await sch.set('Viernes', this.getScheduleByDay(data.Friday));
                await sch.set('Sabado', this.getScheduleByDay(data.Saturday));

                return sch;
            },
            getScheduleByDay(data){
                return data?data.reduce( (arr,item) => {
                    if (item.active){
                        arr = { ...arr, status: item.active, 
                                    ranges: [ ...arr.ranges, item.ranges[0] ] }
                    }
                    return arr;                
                },{ status: 0, ranges: [] }) : { status: 1, ranges: [] };
            },
            changeview(view){
                
                if (view == 'calendar') // When i come back to calendar, remove history from subjets
                    this.setAssignedSubjectsHistory({ subjects: [], filterSubjects: [], page: 1, filters: { orderBy: '', subject: 'asc', duration: 'asc', search: '', type: 'All', mode: 'All' } });
                
                if(view === 'subjects' && this.view === 'customize'){ // IF i'm in customize subject and there are changes, show alert before come back to subjects
                    if (this.$refs.customize.placesChange) {
                        this.$refs.customize.msgType = 'areChanges';
                        this.$refs.customize.statusModalPlaces = true;
                        return;
                    }
                }

                this.view = view;
            }
		},
		watch: {
			statusMsgVacationMode: function(newVal){
				if (newVal)
				    setTimeout( () => {
					    this.showMsgVacationMode = true;
				    },50) 		
			},
			'auth.user.vacation_mode': function(newVal){
				if (newVal)
					this.statusMsgVacationMode = true;
				else{
					this.showMsgVacationMode = false;
					setTimeout( () => {
						this.statusMsgVacationMode = false;
					},400) 
				}	
			},
            view: function(newVal, oldVal){
                if (oldVal == 'subjects' && newVal == 'calendar')
                    this.getSchedule();
            }
		}
	}
</script>