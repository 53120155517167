var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.statusOngoingLesson && _vm.lesson.id
    ? _c(
        "div",
        { class: { active: _vm.show }, attrs: { id: "v-ongoing-lesson" } },
        [
          _c(
            "div",
            {
              staticClass: "notification-lesson",
              class: { hide: _vm.statusModal },
              on: {
                click: function($event) {
                  return _vm.startEventListener()
                }
              }
            },
            [
              _c("div", {
                staticClass: "bg-color",
                class:
                  _vm.lesson.type === "Grupal"
                    ? "bgColor-yellow-main"
                    : `bgColor-${_vm.userType}-main`
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "og-content d-flex align-items-center justify-content-center"
                },
                [
                  _c("p", {
                    staticClass: "og-txt text-center mb-0",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("lessons.tOngoingHtml"))
                    }
                  }),
                  _vm._v(" "),
                  _c("svgicon", {
                    staticClass: "icon-eye",
                    attrs: { name: "eye" }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "v-modal-lesson pt-4 pb-4",
              class: { show: _vm.statusModal }
            },
            [
              _c("svgicon", {
                staticClass: "btn-close",
                attrs: { name: "cross2" },
                on: {
                  click: function($event) {
                    return _vm.endEventListener()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "perfect-scrollbar",
                {
                  staticClass:
                    "v-modal-content pl-4 pr-4 lesson h-100 d-flex flex-wrap justify-content-center align-content-start",
                  attrs: { tag: "div" }
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "v-modal-title text-center position-relative mb-4 w-100"
                    },
                    [_vm._v(_vm._s(_vm.$t("lessons.tOngoingModal")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center ml-n1 mr-n1 mb-4 w-100"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "v-ol-content col-12 pl-1 pr-1 d-flex flex-wrap align-items-center justify-content-center mb-4"
                        },
                        [
                          _c("v-lesson-photo", {
                            staticClass: "mb-2",
                            attrs: { options: _vm.lessonPhotoOpc }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-100 text-center" }, [
                            _c("p", { staticClass: "v-ol-name mb-0" }, [
                              _vm._v(_vm._s(_vm.name))
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "v-ol-subject mb-0" }, [
                              _vm._v(_vm._s(_vm.lesson.subject))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-auto pl-1 pr-1" },
                        [
                          _c("v-lesson-tag", {
                            staticClass: "mb-1",
                            attrs: {
                              type: _vm.lesson.type,
                              options: _vm.tagTypeOpc
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-auto pl-1 pr-1" },
                        [
                          _c("v-lesson-tag", {
                            staticClass: "mb-1",
                            attrs: { type: _vm.lesson.modality }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-lesson-schedule", {
                        staticClass:
                          "col-12 pl-1 pr-1 mb-3 mt-3 position-relative",
                        attrs: {
                          options: {
                            date: _vm.lesson.scheduledDate,
                            startTime: _vm.lesson.scheduledTime,
                            endTime: _vm.lesson.endScheduledTime,
                            spt: true
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("v-lesson-place", {
                        staticClass: "col-12 pl-1 pr-1 mb-3",
                        attrs: {
                          options: {
                            icon: _vm.lesson.placeIcon,
                            place: _vm.lesson.place,
                            modality: _vm.lesson.modality,
                            type: _vm.lesson.type,
                            lessonId: _vm.lesson.id,
                            edit: false
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.lesson.topic &&
                      ((_vm.userType == "student" &&
                        _vm.lesson.type != "Grupal") ||
                        _vm.userType == "tutor")
                        ? _c("v-lesson-topic", {
                            staticClass: "col-12 pl-1 pr-1 mb-3",
                            attrs: {
                              options: {
                                lessonId: _vm.lesson.id,
                                topic: _vm.lesson.topic,
                                hideBtn: true
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 pl-1 pr-1 mt-2 mb-4" },
                        [
                          _c("v-lesson-time", {
                            key: _vm.resetTime,
                            staticClass: "text-center",
                            attrs: {
                              options: {
                                initialTime: _vm.lesson.initialTime,
                                tzScheduled: _vm.lesson.tz_scheduled
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 pl-1 pr-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row ml-n1 mr-n1 justify-content-center"
                          },
                          [
                            _vm.lesson.modality == "Online" &&
                            _vm.lesson.status == "Activa"
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 pl-1 pr-1 mb-4" },
                                  [
                                    _c("v-lesson-btn", {
                                      staticClass: "w-100",
                                      attrs: {
                                        options: {
                                          type: "online",
                                          idLesson: _vm.lesson.id
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.userType == "tutor" &&
                            _vm.lesson.type == "Grupal" &&
                            _vm.lesson.status == "Activa"
                              ? _c(
                                  "div",
                                  { staticClass: "col-6 pl-1 pr-1 mb-2" },
                                  [
                                    _c("v-lesson-btn", {
                                      staticClass: "w-100",
                                      attrs: {
                                        options: {
                                          type: "takeAssistant",
                                          idLesson: _vm.lesson.id
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 pl-1 pr-1 mb-2" },
                              [
                                _c("v-lesson-btn", {
                                  staticClass: "w-100",
                                  attrs: {
                                    options: {
                                      type: "chat",
                                      idLesson: _vm.lesson.id
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.userType == "tutor" &&
                            _vm.lesson.status == "Activa"
                              ? _c(
                                  "div",
                                  { staticClass: "col-6 pl-1 pr-1 mb-2" },
                                  [
                                    _c("v-lesson-btn", {
                                      staticClass: "w-100",
                                      attrs: {
                                        options: {
                                          type: "stop",
                                          idLesson: _vm.lesson.id
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }