/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transform': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 4a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V2h-5v-.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V2H3v-.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H1v5H.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H1v5H.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V19h5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V19h5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H18v-5h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H18V4h.5zM17 2h1v1h-1V2zM9 2h1v1H9V2zM1 2h1v1H1V2zm0 8h1v1H1v-1zm1 9H1v-1h1v1zm8 0H9v-1h1v1zm8 0h-1v-1h1v1zm0-8h-1v-1h1v1zm-1-2h-.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.5v5h-.5a.5.5 0 00-.5.5v.5h-5v-.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v.5H3v-.5a.5.5 0 00-.5-.5H2v-5h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H2V4h.5a.5.5 0 00.5-.5V3h5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V3h5v.5a.5.5 0 00.5.5h.5v5z"/><path pid="1" d="M11.5 10H10V8.5a.5.5 0 00-1 0V10H7.5a.5.5 0 000 1H9v1.5a.5.5 0 001 0V11h1.5a.5.5 0 000-1z"/>'
  }
})
