var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pl-0 pr-0", attrs: { id: "v-room" } },
    [
      _c(_vm.viewComponent, {
        ref: "viewRoom",
        tag: "component",
        attrs: { options: _vm.options },
        on: {
          joinRoom: _vm.joinRoom,
          handleDevice: _vm.handleDevice,
          handleCam: band => (_vm.options.muteCam = band),
          handleVideoQuality: _vm.handleVideoQuality,
          handleMic: band => (_vm.options.muteMic = band),
          playTestSound: _vm.playTestSound,
          "stopDuration()": function($event) {
            return _vm.clearInterval(_vm.durationInterval)
          },
          reload: _vm.reload
        }
      }),
      _vm._v(" "),
      _c("audio", { ref: "audioOutputElement", style: { display: "none" } }),
      _vm._v(" "),
      _vm.statusModalAlert && _vm.currentDeviceAlert != undefined
        ? _c("v-modal-permissions", {
            attrs: { alert: _vm.currentDeviceAlert },
            on: {
              close: function($event) {
                _vm.statusModalAlert = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }