import mutations from './mutations.js';
import actions from './actions.js';

const state = {
    calendar: {
        mode: 'dayGridMonth', // dayGridMonth, dayGridWeek
        defaultDate: null
    },
    assignedSubjects: {
    	subjects: [],
    	filterSubjects: [],
    	page: 1,
    	filters: {
    		orderBy: '', // subject, duration
            subject: 'asc', // des, asc
            duration: 'asc', // asc, des
            search: '',
            type: 'All',
            mode: 'All'
    	}
    },
    cutomizeSubject: {
    	idSubject: null,
    	statusPlaces: false,
    	statusSchedule: false
    }
}

export default{
	state,
	mutations,
	actions
}