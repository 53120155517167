
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { State } from 'vuex-class';
    import { modal } from '../../mixins/index.js';

    @Component({
		mixins: [ modal ]
	})
	export default class SystemPreferences extends Vue{
        @Prop({ type: Boolean, default: false }) readonly showModal!: boolean;
        @Prop({ type: String, default: '' }) readonly device!: string; // mic, cam

        @State( state => state.auth.permission ) permission;
        @State( state => state.permissions.mic ) permissionMic
        @State( state => state.permissions.cam ) permissionCam
        @State( state => state.typePermission ) typePermission // Enum
   
        get t(){
        	return(this as any).$t;
        }
        get emit(){
        	return(this as any).$emit;
        }
        get title(){
        	if (this.device == 'mic') {
        		if (this.permissionMic == this.typePermission.errorSystem)
        			return this.t('room.microphone.alerts.errorSystem.issue');
                else if (this.permissionMic == this.typePermission.denied)
                    return this.t('room.microphone.alerts.denied.issue');
        	}else{
        		if (this.permissionCam == this.typePermission.errorSystem)
        			return this.t('room.camera.alerts.errorSystem.issue');
                else (this.permissionCam == this.typePermission.denied)
                    return this.t('room.camera.alerts.denied.issue');
        	}
        }
        get descrip(){
        	if (this.device == 'mic') {
        		if (this.permissionMic == this.typePermission.errorSystem)
        			return this.t('room.microphone.alerts.errorSystem.info');
                else if (this.permissionMic == this.typePermission.denied)
                    return this.t('room.microphone.alerts.denied.info');
        	}else{
        		if (this.permissionCam == this.typePermission.errorSystem)
        			return this.t('room.camera.alerts.errorSystem.info');
                else if (this.permissionCam == this.typePermission.denied)
                    return this.t('room.camera.alerts.denied.info');
        	}
        }

        afterClose(){
        	this.emit('close');
        }

        @Watch('showModal')
        handleModal(newVal: boolean){
        	if (newVal)
        		(this as any).show = true;
        }
	}
