var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pb-5", attrs: { id: "availability" } },
    [
      _c(
        "div",
        {
          staticClass:
            "paper ml-sm-0 d-flex flex-wrap mr-sm-0 pl-md-4 pr-md-4 pb-sm-5 pt-md-4 pb-4 justify-content-center"
        },
        [
          _c("div", { staticClass: "content-availability w-100" }, [
            _c(
              "div",
              {
                staticClass:
                  "bgColor-tutor-main ml-0 mr-0 mb-4 overflow-hidden position-relative"
              },
              [
                _c("div", { staticClass: "p-3 p-sm-4 content-title" }, [
                  _c("h2", { staticClass: "title-availability mb-2" }, [
                    _vm._v(_vm._s(_vm.title.t))
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "descrip-availability mb-0 pl-0 pr-0 col-md-10"
                    },
                    [_vm._v(_vm._s(_vm.title.d))]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon-clock pr-4 pl-0 d-none d-md-block" },
                  [
                    _c("svgicon", {
                      staticClass: "icon",
                      attrs: { name: "clock" }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "toolbar-calendar navbar navbar-expand flex-wrap p-0 mb-2 pl-3 pr-3 pl-md-0 pr-md-0"
              },
              [
                _vm.view === "calendar"
                  ? _c(
                      "nav",
                      {
                        staticClass:
                          "collapse navbar-collapse order-1 order-sm-0"
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass: "btns-calendar-view navbar-nav mr-auto"
                          },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "nav-item pl-4 pr-4 pt-2 pb-2",
                                class: {
                                  active: _vm.calendar.mode === "dayGridWeek"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setCalendarHistory({
                                      ..._vm.calendar,
                                      mode: "dayGridWeek"
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("availability.calendar.week"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "nav-item pl-4 pr-4 pt-2 pb-2",
                                class: {
                                  active: _vm.calendar.mode === "dayGridMonth"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setCalendarHistory({
                                      ..._vm.calendar,
                                      mode: "dayGridMonth"
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("availability.calendar.month"))
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "btn-prev mr-auto p-0 mt-2 mb-2 order-1 order-sm-0",
                        on: {
                          click: function($event) {
                            return _vm.changeview(
                              _vm.view === "customize" ? "subjects" : "calendar"
                            )
                          }
                        }
                      },
                      [
                        _c("svgicon", {
                          staticClass: "icon mr-3",
                          attrs: { name: "chevron-left" }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.view === "customize"
                                ? _vm.$t("availability.toolbar.prevSubjects")
                                : _vm.$t("availability.toolbar.prevCalendar")
                            ) +
                            " "
                        )
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vacation-mode d-flex align-items-center justify-content-end order-0 order-sm-1 mb-1 mb-sm-0"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-plane mr-2" },
                      [
                        _c("svgicon", {
                          staticClass: "icon",
                          attrs: { name: "plane" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "txt-vacation-mode" }, [
                      _vm._v(
                        _vm._s(_vm.$t("availability.toolbar.vacationMode"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("v-more-info", {
                      staticClass: "ml-1 mr-2",
                      attrs: {
                        minWidthCloud: "250px",
                        txtCloud: _vm.$t("moreInfo.infoVacationsMode")
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "switch",
                      class: { active: _vm.auth.user.vacation_mode },
                      on: {
                        click: function($event) {
                          _vm.showModalVacationMode = true
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "position-relative pl-3 pr-3 pl-md-0 pr-md-0" },
              [
                _c("transition", { attrs: { name: "bound", mode: "out-in" } }, [
                  _vm.view === "calendar"
                    ? _c(
                        "div",
                        { key: "calendar", staticClass: "position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mb-4 mb-sm-2"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "editBySubject",
                                  on: {
                                    click: function($event) {
                                      _vm.view = "subjects"
                                    }
                                  }
                                },
                                [
                                  _c("svgicon", {
                                    staticClass: "icon",
                                    attrs: { name: "pencil-ruler2" }
                                  }),
                                  _vm._v(
                                    "\n                \t\t\t        " +
                                      _vm._s(
                                        _vm.$t(
                                          "availability.toolbar.editBySubjects"
                                        )
                                      ) +
                                      "\n                \t\t        "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.generalSchedule.size > 0
                            ? _c("v-full-calendar", {
                                ref: "fullCalendar",
                                attrs: {
                                  minRangetime: _vm.minRangetime,
                                  initTime: _vm.initTime,
                                  endTime: _vm.endTime,
                                  disabledDisponibility: _vm.disabledDisponibility
                                    ? true
                                    : false
                                },
                                model: {
                                  value: _vm.generalSchedule,
                                  callback: function($$v) {
                                    _vm.generalSchedule = $$v
                                  },
                                  expression: "generalSchedule"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm.view === "subjects"
                    ? _c(
                        "div",
                        { key: "subjects", staticClass: "pt-4" },
                        [
                          _c("v-assigned-subjects", {
                            on: {
                              customizeSubject: sub => {
                                _vm.subject = sub
                                _vm.changeview("customize")
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm.view === "customize"
                    ? _c(
                        "div",
                        { key: "customize", staticClass: "pt-2" },
                        [
                          _c("v-customize-subject", {
                            ref: "customize",
                            attrs: {
                              subject: _vm.subject,
                              generalSchedule: _vm.generalSchedule,
                              initTime: _vm.initTime,
                              endTime: _vm.endTime,
                              minRangetime: _vm.minRangetime,
                              disabledDisponibility: _vm.disabledDisponibility
                                ? true
                                : false
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.statusMsgVacationMode
                  ? _c(
                      "div",
                      {
                        staticClass: "mode-vacation-mode",
                        class: { active: _vm.showMsgVacationMode }
                      },
                      [
                        _c("div", { staticClass: "info-vacation-mode" }, [
                          _c("h4", { staticClass: "title mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "icon-plane mr-2" },
                              [
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "plane" }
                                })
                              ],
                              1
                            ),
                            _vm._v(
                              "\n                \t\t\t    " +
                                _vm._s(_vm.$t("availability.vacationMode.t")) +
                                "\n                \t\t    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "descrip mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("availability.vacationMode.d"))
                            )
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("v-vacation-mode", {
        attrs: { show: _vm.showModalVacationMode },
        on: {
          closeVacationMode: function($event) {
            _vm.showModalVacationMode = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }