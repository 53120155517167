/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fence': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3 6.5a.5.5 0 11-1 0 .5.5 0 011 0zM10 6.5a.5.5 0 11-1 0 .5.5 0 011 0zM17 6.5a.5.5 0 11-1 0 .5.5 0 011 0z"/><path pid="1" d="M18.854 2.646l-2-2a.5.5 0 00-.707 0l-2 2a.5.5 0 00-.146.354v1h-2V3a.504.504 0 00-.146-.354l-2-2a.5.5 0 00-.707 0l-2 2A.5.5 0 007.002 3v1h-2V3a.504.504 0 00-.146-.354l-2-2a.5.5 0 00-.707 0l-2 2A.5.5 0 00.003 3v16.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V18h2v1.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V18h2v1.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V3a.504.504 0 00-.146-.354zM12 9h2v4h-2V9zm2-4v3h-2V5h2zM5 9h2v4H5V9zm2-4v3H5V5h2zM4 19H1V3.207l1.5-1.5 1.5 1.5V19zm1-2v-3h2v3H5zm6 2H8V3.207l1.5-1.5 1.5 1.5V19zm1-2v-3h2v3h-2zm6 2h-3V3.207l1.5-1.5 1.5 1.5V19z"/><path pid="2" d="M3 15.5a.5.5 0 11-1 0 .5.5 0 011 0zM10 15.5a.5.5 0 11-1 0 .5.5 0 011 0zM17 15.5a.5.5 0 11-1 0 .5.5 0 011 0z"/>'
  }
})
