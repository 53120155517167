var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass:
        "v-notification d-flex align-items-center pl-3 pt-2 pr-3 pb-2",
      class: { active: !_vm.notification.seen }
    },
    [
      _c("v-lesson-photo", { attrs: { options: _vm.lessonPhotoOpc } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pl-3 pr-4 position-relative",
          style: { width: `calc(100% - ${_vm.lessonPhotoOpc.size})` }
        },
        [
          _c("h5", { staticClass: "n-title mb-1" }, [
            _vm._v(
              _vm._s(
                _vm.$t(`notifications.type.${_vm.keyFormat.lessonStatus}.t`)
              )
            )
          ]),
          _vm._v(" "),
          _c("v-hover-txt", {
            staticClass: "mb-1",
            attrs: {
              colorTxt: "#000",
              fontSize: "14px",
              fontWeight: "400",
              txt: _vm.notification.subject
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row align-items-center ml-n1 mr-n1" }, [
            _c(
              "div",
              { staticClass: "col-auto pl-1 pr-1" },
              [
                _c("v-lesson-tag", {
                  attrs: {
                    type: _vm.modality[_vm.keyFormat.modality],
                    options: { minWidth: "80px" }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto pl-1 pr-1" }, [
              _c("p", { staticClass: "n-time mb-0" }, [
                _vm._v(_vm._s(_vm.time))
              ])
            ])
          ]),
          _vm._v(" "),
          !_vm.notification.seen
            ? _c("div", {
                staticClass: "n-check",
                style: {
                  background: _vm.userType == "tutor" ? "#005E58" : "#0091FF"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }