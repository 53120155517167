import rangeTime from './v-range-time.vue';
import scheduleByDay from './schedule-by-day.vue';
import vacationMode from './modal-vacationMode.vue';
import availability from './modal-availability.vue';
import applyToMultiple from './apply-to-multiple.vue';
import assignedsubjects from './assigned-subjects.vue';
import availabilitySelect from './availability-select.vue';
import customizeSubject from './customize-subject.vue';
import specifigScheduling from './modal-specifig-scheduling.vue';
import fullCalendar from './full-calendar.vue';
import clear from './modal-clear.vue';

function plugin(Vue){
	Vue.component('v-range-time', rangeTime); //
    Vue.component('v-sch-by-day', scheduleByDay); //
	Vue.component('v-apply-to-multiple', applyToMultiple); //
    Vue.component('v-vacation-mode', vacationMode);
    Vue.component('v-availability', availability);
    Vue.component('v-assigned-subjects', assignedsubjects);
    Vue.component('v-availability-select', availabilitySelect);
    Vue.component('v-customize-subject', customizeSubject);
    Vue.component('v-specifig-scheduling', specifigScheduling);
    Vue.component('v-full-calendar', fullCalendar);
    Vue.component('v-modal-places', clear);
}

export default plugin