var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-select-lang" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.lang,
            expression: "lang"
          }
        ],
        staticClass: "language",
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.lang = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function($event) {
              return _vm.setLang($event.target.value)
            }
          ]
        }
      },
      _vm._l(_vm.languages, function(lang) {
        return _c("option", { domProps: { value: lang.short } }, [
          _vm._v(_vm._s(lang.long))
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-icon" },
      [_c("svgicon", { staticClass: "icon", attrs: { name: "chevron-down" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }