export default {
	formatDate: 'll',
	formatDateC: 'dddd, MMMM D, YYYY',
	formatDateChat: 'MM/DD/YY',
    lessonFormatDate: 'dddd Do MMM YY',
    lastweek: '[Last] dddd',
	googlePlay: '/media/images/badge-google-play-en.png',
	appStore: '/media/images/badge-app-store-en.svg',
	linkSupport: 'https://support.linc.io/hc/en-us',
	error429: 'Too many requests. Please try again later.',
	msgOnline: 'Connected',
	msgOffline: 'Connection has been lost',
    copyRight: '© {year} Linc Learning, Inc. All rights reserved.',
	languages: { // Component lang
	    en: 'English',
	    es: 'Spanish'
	},
	gender: { // Select gender
        m: 'Male',
        f: 'Female',
        o: 'Other'
	},
    civilStatus: {
        s: 'Soltero',
        csh: 'Casado sin hijos',
        cch: 'Casado con hijos',
        o: 'Otro'
    },
    scholarship: {
        opc1: 'Primaria',
        opc2: 'Secundaria',
        opc3: 'Preparatoria',
        opc4: 'Bachillerato técnico',
        opc5: 'Licenciatura',
        opc6: 'Posgrado',
        opc7: 'Sin estudios'
    },
    typeOfScholarship: {
        opc1: 'Excelencia académica',
        opc2: 'Apoyo económico',
        opc3: 'Deportiva o cultural',
        opc4: 'Otra'
    },
    yesNo: {
        y: 'Sí',
        n: 'No'
    },
    liveWith: {
        opc1: 'Con mi familia',
        opc2: 'Con familiares cercanos',
        opc3: 'Con otros estudiantes',
        opc4: 'Solo'
    },
    parentStatus: {
        opc1: 'Vive',
        opc2: 'Finado',
        opc3: 'Finada'
    },
	group: 'Group',
    groupLarge: 'Group session',
	modality: { // Select session mode
        p: 'In-person',
        o: 'Online'
	},
    months: {
        jan: 'Ene',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Abr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dic: 'Dic'
    },
	noPlace: {
        t: 'Define a place',
        s: 'Place to be defined'
    },
    editPlace: 'Edit place',
	oneOnOne: '1-on-1',
	lessonType: { // Select session type
        g: 'Group',
        a: '1-on-1',
        m: 'max'
	},
    topic: {
        tag: 'Topic',
        opc: '(Opcional)',
        cloudBtnEdit: 'Edit topic',
        placeholder: 'Define a topic'
    },
	image:{
		getTheAppS: "Student's mobile app",
        getTheAppT: "Tutor's mobile app",
        appStore: 'Get it on App Store',
        googlePlay: 'Get it on Google Play',
        icloud: 'iCloud',
        google: 'Google',
        outlook: 'Outlook',
        exchange: 'Exchange'
	},
	today: 'Today',
	yesterday: 'Yesterday',
	tomorrow: 'Tomorrow',
	days: {
		monday: 'Monday',
		tuesday: 'Tuesday',
		wednesday: 'Wednesday', 
		thursday: 'Thursday',
		friday: 'Friday',
		saturday: 'Saturday',
		sunday: 'Sunday'
	},
	intitutionalEmail: { // Institutional email
        t: 'Sign in to Linc',
        label: 'Enter your institutional email',
        btn: 'Continue',
        invalidEmail: {
        	error: 'Oops...',
        	msgHtml: 'The email you entered does not correspond to this institution',
        	btn: 'Try again' 
        },
        planBasic: {
            d: 'Your institution has the basic Linc plan that includes in-person sessions and unlimited use of our mobile app.',
            iHtml: 'For more information, please contact our <a href="https://help.linc.io/contact" target="_blank">customer service</a>'
        }
	},
	404: { // Error 404
        error: 'Error 404',
        msg: 'Page not found',
        btnD: 'Go home',
        btnE: 'Go home'
	},
	403: { // Error 403
        error: '403 Forbidden',
        msg: "You don't have permission to access on this server.",
        btn: 'Go home'
	},
	signUp: { // Sign up
		t: 'Create your account',
		form: {
			name: {
				label: 'Name',
				placeholder: 'Type your name'
			},
			lastName: {
				label: 'Last name',
				placeholder: 'Type your last name'
			},
			gender: {
				label: 'Gender',
				placeholder: 'Select your gender'
			},
			birthday: 'Birthday',
			campus: {
				label: 'Campus',
				placeholder: 'Select your campus'
			},
			career: {
				label: 'Major',
				placeholder: 'Select your major'
			},
			major: {
				label: 'Major',
				placeholder: 'Select your major'
			},
			period: {
				label: 'School period',
				placeholder: 'Select your period'
			},
			password: { 
                label: 'Password',
                placeholder: 'Enter your password',
                alert: 'Your password must have at least 8 characters'
            }
		},
		legalHtml: 'I agree to the <a class="v-btn-linc" href="https://linc.io/terms-and-conditions" target="_blank">Terms</a> and <a class="v-btn-linc" href="https://linc.io/privacy-notice" target="_blank">Privacy Policy.</a>',
		btn: 'Start'
	},
	confirmInstitutionalEmail: { // txt confirm email
        t: 'Verify your email',
        msgHtml: 'We sent you an email to <br> <strong> {email} </strong> <br> with a link to confirm your account',
        invalidEmail: 'Invalid email?',
        btn: 'Register again'
	},
	confirmEmailSuccess:{ // Institutional Email confirmed
        t: 'Your account has been successfully activated!',
        msg2: 'You can now access the Linc platform',
        btn: 'Back to login'
	},
	login:{ // login
        t: 'Sign in to Linc',
        form: {
        	email: 'School email address',
        	password: {
                label: 'Password',
                placeholder: 'Enter your password'
            }
        },
        btn: 'Login',
        forgot: 'Forgot password?'
	},
	recoveryPassword: { // Recovery password
        t: 'Recover your password',
        label: 'Please enter your email address below',
        placeholder: 'Email Address',
        btn: 'Send password instructions',
        btnPrev: 'Or sign in with your credentials'
	},
	resetPasswordVerifyEmail: { // txt reset password
        t: 'Verify your email',
		msgHtml: 'We sent you an email to <br> <strong> {email} </strong> <br> with instructions to reset your password',
		btn: 'Back to login'
	},
	resetPassword:{ // Reset password
		t: 'Reset password',
		form: {
			password: 'New Password',
			samePassword: 'Enter new password again'
		},
		step1: '• Passwords do not match',
		step2: '• Password must have a minimum of 8 characters',
		btn: 'Reset password'
	},
	resetPasswordSuccess: {  // Updated password
		t: 'Password successfully updated!',
		txt: 'You can now enter with your new password',
		btn: 'Back to login'
	},
	header: { // Header
		help: 'Help',
		helpSub: {
            docs: {
            	t: 'Online documentation',
            	descrip: 'Getting started guides',
            	link: 'https://help.linc.io/'
            },
            online: {
            	t: 'Online Support',
            	descrip: 'Contact us via WhatsApp',
            	link: 'https://wa.me/5214791135742'
            },
            email: {
            	t: 'Email Support',
            	descrip: 'Contact us via email',
            	link: 'https://help.linc.io/contact'
            }
		},
		menu: {
			account: 'My account',
			profile: 'View profile',
			tutorMode: 'Switch to tutor mode',
			studentMode: 'Switch to student mode',
			preferences: 'Tutoring preferences',
            settings: 'Account settings',
			logOut: 'Log out'
		}
	},
	title: { // titles page
		login: 'Login', // /login
		sightUp: 'Sign Up', // /sign-up
		recoveryPassword: 'Password Recovery',// /revocery-password
		confirmEmail: 'Confirm your email',// /confirm
		confirmSucces: 'Account activation',// /confirm/:token
		resetPassword: 'Reset password', // /reset-password/:token
		404: 'Page not found', // /404
		403: '403 Forbidden', // /403
		dashboard: 'Dashboard',
		lessons: 'Manage sessions',
		history: 'Past sessions',
		calendar: 'Calendar',
		acount: 'Account details',
		changePassword: 'Change password',
		preferences: 'Notification preferences',
		subjects: 'Subjects',
		availability: 'Availability',
		biography: 'Professional biography',
        chat: 'Chat',
        app: 'Download the app',
        schedule: 'Book session',
        search: 'Search for tutors',
        profile: "{name}'s Profile",
        profiletTutor: 'My profile',
        virtualRoom: 'Virtual Room',
        notifications: 'Notifications',
        formats: 'Formats',
        scheduleSession: 'Book session'
	},
	mainMenu: { // menu
        dashboard: 'Dashboard',
        schedule: 'Book session',
        profile: 'View profile',
        chat: 'Chat',
        formatsS: 'Mis formatos',
        formatsT: 'Formatos de Estudiantes',
        appHtml: `<img class="icon" src="/media/images/logo-f.png">
                  <span class="text thin">Download the </span>
				  <span class="text bold">app</span>
				  <span class="text all">Get the Linc App</span>`,
        lessons: {
        	lessons: 'Sessions',
        	manage: 'Manage sessions',
        	history: 'History',
        	calendar: 'Calendar'
        },
        preferences: {
        	preferences: 'Tutoring preferences',
        	subjects: 'Subjects',
        	availability: 'Availability',
        	biography: 'Professional biography',
        },
        settings: {
        	settings: 'Account settings',
        	acount: 'Account details',
        	changePassword: 'Change password',
        	notifications: 'Notification preferences'
        }
	},
	search:{ // Component search
		placeholder: 'Search subject or tutor ...',
		cloud: 'When searching by subject, you will get a list of tutors available to help you, while when searching by tutor name, you will be taken to their profile.'
	},
	msgEmpty: { // Session msg
		msgUpcoming: "You <span class='bold'>don't have</span> any upcoming sessions.",
		msgOngoing: "You <span class='bold'>don't have</span> any ongoing sessions.",
		msgPending: "You <span class='bold'>don't have</span> any pending session requests.",
		msgLatest: "Your latest requested subjects will appear here.",
		msgPast: "Your completed sessions history will appear here.",
		msgTutor: "Your most recently requested tutors will appear here.",
        msgTutor2: "Suggestions will appear once you book your first session.",
		will: 'Your sessions will be shown here.',
		search: "We couldn't find any tutors matching your search.",
        inputsearch: 'Your search did not match any subjects or tutors.',
        subjectSearch: 'Your search did not match any subjects.',
		btn: 'Book session',
	},
	lessons: { // Sessions components ( Manage, history )
        vistualRoom: 'Virtual room',



        btnChat: 'Chat',
        btnChatLog: 'Chat log',
        btnCancel: 'Cancel',
        btnConfirm: 'Confirm',
        btnStart: 'Start',
        btnAttendance: 'Attendance',
        btnStop: 'End',
        confirm: 'Confirmed',
        btnJoin: 'Join virtual room',
        tOngoing: 'Ongoing sessions',
        tOngoingModal: 'Ongoing session',
        tOngoingHtml: '<span class="bold">Ongoing</span> session',
        tUpcoming: 'Upcoming sessions',
        tPending: 'Pending requests',
        tPast: 'Completed sessions',
        currentTime: 'Time:',
        btnAll: "View all",
        start: 'Start:',
        end: 'End:',
        duration: 'Duration:',
        myFeedback: 'My feedback',
        studentFeedback: "Student's feedback",
        studentsFeedback: 'Students rating average',
        viewFeedback: 'View students feedback',
        seats: 'seats'
	},
	dashboard: { // Dashboard
		upcoming: 'Upcoming sessions',
		pending: 'Pending session requests',
		statistics: 'Tutoring statistics',
		latestLessons: 'Latest subjects requested',
		latestTutors: 'Latest tutors requested',
		btnProfile: 'View profile',
		this: 'Last',
		week: '7 days',
		completed: 'Completed',
		lessons: 'Sessions',
		tutoring: 'Tutoring',
		hours: 'Hours',
		canceled: 'Canceled session',
		requested: 'Requests'
	},
	calendar: { // Calendar
        title: 'Sync your calendar',
        descripHtml: 'Synchronize <strong>Linc’s Calendar</strong> with your favorite calendar application. View sessions events with the time, date, and location of your upcoming scheduled sessions.',
        titleUrl: 'Linc Calendar URL',
        descripUrlHtml: 'Copy and paste this URL to subscribe to your <strong>Linc Calendar</strong> in your favorite calendar app.',
        btnCopy: 'COPY',
        enabledTxt: 'Linc calendar is currently enabled',
        disabledTxt: 'Linc calendar is currently disabled',
        btnEnabled: 'Enabled calendar',
        btnDisable: 'Disable calendar',
        instructions: 'Instructions to setup your Linc Calendar',
        icloud: 'https://help.linc.io/article/167-how-do-i-sync-my-linc-calendar-with-icloud',
        google: 'https://help.linc.io/article/168-how-do-i-sync-my-linc-calendar-with-google-calendar',
        outlook: 'https://help.linc.io/article/169-how-do-i-sync-my-linc-calendar-with-outlook-office-365-or-microsoft-exchange',
        exchange: 'https://help.linc.io/article/169-how-do-i-sync-my-linc-calendar-with-outlook-office-365-or-microsoft-exchange'
	},
	accountDetails: { // Account details
		title: 'My Account',
		form: {
			name: 'First name',
			lastName: 'Last name',
			email: 'Institutional email',
			campus: 'Campus',
			phone: 'Phone',
			birthday: 'Birthday',
			gender: 'Gender',
			major: 'Major',
			semester: 'School period',
			btn: 'Update account'
		},
		cropImg: {
			title: 'Crop photo',
			btn: 'Update profile photo'
		}
	},
	changePassword: { // Change password
		title: 'Change your password',
		form: {
            cPassword: 'Current password',
            nPassword: 'New password',
            btn: 'Update password'
		}
	},
	notificationsPreferences: { // Notifications preferences
        titleEmail: 'Email notifications',
        titlePush: 'Push notifications',
        general: 'General',
        lessons: 'Sessions',
        descrip: 'Push notifications can only be controlled from the mobile app.',
        app: 'Download the app',
        btn: 'Save preferences',
        checkboxes: {
        	newsletter: {
        		t: 'Linc Newsletter',
        		dHtml: 'Emails about new Linc features (sent once every month).'
        	},
        	lesson_request: {
                t: 'New session requests',
                d: 'Get notified when you receive new session requests.'
        	},
        	lesson_cancelled: {
                t: 'Canceled sessions',
                d: 'Get notified when your upcoming sessions are canceled by students.'
        	},
        	lesson_status: {
        		t: 'Session status update',
        		d: 'Get notified when your sessions requests are accepted or canceled by tutors.'
        	},
        	lesson_summary: {
        		t: 'Completed session summary',
        		d: 'Get a summary of each completed session.'
        	},
        	upcoming_lessons: {
                t: 'Upcoming session reminders',
        		dHtml: 'Get a reminder about your upcoming sessions.'
        	}
        }
	},
	lincAppS: { // Get the Linc App (student)
        title: 'Expert tutors from your institution at your fingertips',
        subTitle: 'Get the free app to connect faster with tutors.',
        img: '/media/images/features/EN/dashboard.png',
        qr: 'qr_code_EN',
        qrtxt1: 'Scan the QR code',
        qrtxt2: 'to download the app',
        benefits: {
        	b1: {
        		t: 'Find your ideal tutor',
        		d1: '• Compare tutors based on their rating, availability and more.'
        	},
        	b2: {
        		t: 'Book with ease',
        		d1: '• No mattter where you are, book on the go.'
        	},
        	b3: {
        		t: 'Connect instantly',
        		d1: '• Chat in real-time with tutors.'
        	},
        	b4: {
        		t: 'Real-time notifications',
        		d1: '• Reminders, sessions confirmations and more.'
        	}
        }
	},
	lincAppT: { // Get the Linc App (tutor)
		title: 'Manage your tutoring demand on the go',
		subTitle: 'Get the free app to connect faster with students.',
        img: '/media/images/asesores.png',
        benefits: {
        	b1: '• Easily keep in touch with students anytime, anywhere.',
        	b2: '• Manage your sessions.',
        	b3: '• Update your profile & availability.',
        	b4: '• Stay updated! Get real-time PUSH notifications.'
        }
	},
	schedule: { // Schedule session
		title: 'Start by searching a subject or tutor.',
		sub: 'or select the area & subject below',
		selectDay: "Select a day in the calendar",
		noTimes: "No schedules available",
		switch: {
			alone: '1-on-1 session',
			group: 'Group session'
		},
		area: 'Area',
		subject: 'Subject',
		msg: 'Select a department',
		msgNoSubjects: 'No available subjects were found.'
	},
    scheduleT: {
        t: 'Book session',
        HowItWorks: 'How does it work?',
        HowItWorksHref: 'https://help.linc.io/article/262-how-do-i-schedule-a-tutoring-lesson-for-my-students',
        subjects: {
            t: 'Subject',
            tooltip: 'You can only choose between your assigned subjects.',
            placeholder: 'Select a subject',
            alertNotFound: 'Subjects not found'
        },
        dateTime: {
            t: 'Date & Time',
            tooltip: "Ensure that your schedule works for all parties. The selected date & time overrides your defined availability & students' agenda.",
            date: 'Select a date',
            time: 'Select a time'
        },
        students: {
            t: 'Students',
            tooltip: "Enrolled students will get automatically notified about this session.",
            placeholder: 'Search students by name or institutional email',
            alertStudentsNotFound: 'Your search did not match any student registered on the platform.',
            alertInvalidExtHtml: 'The email entered does not belong to your educational institution. Please use one of the following official institution domains: {exts} or {lastExt}.',
            alertStudentIsntExist: 'Your email did not match any existing students. Add a full name and click “Invite” and we’ll send them an invite email to join this session.',
            studentAdd: 'The student was already added',
            studentLimit: 'You can only invite up to {quote} students',
            unconfirmed: 'Unconfirmed',
            form: {
                name: 'First name',
                lastName: 'Last name',
                btn: 'Invite'
            },
            alertSuccess: 'Session successfully scheduled!'
        },
        places: {
            t: 'Place',
            placeholder: 'Select a place',
            placeholder2: 'Add a place',
            alertPlacesNotFound: 'Place not found',
        },
        summary: {
            t: 'Session summary',
            enrolled: 'Enrolled students: {count}'
        },
        btnBookSession: 'Book session',
        alerts: {
            1: 'The selected place doesn’t belong to the university.',
            2: 'The selected date and time has already passed. Please select a different one with more anticipation.',
            4: 'You don’t have permission to schedule sessions for the selected subject.',
            3: '',
            5: 'You already have another session scheduled that overlaps with the selected date and time. Please try selecting a different schedule.',
            6: 'The selected place is not allowed for this subject.',
            7: 'It is not possible to add one or more invited (unconfirmed) users to this session. Please try again.'
        }
    },
	searchResult: { //Search results
		tutorsFound: '{count} {subject} tutors found',
        filtersBtn: 'Filters',
        filters: {
        	t: 'Search filters',
            hour: 'Hour',
            availability: 'Availability',
            age: 'Tutor age',
            gender: 'Gender',
            type: 'Session type',
            mode: 'Session mode',
            any: 'Both',
            all: 'All'
        }
	},
	scheduleLesson: { // Component schedule session
        subjectst: 'Subjects:',
        seeAll: 'See all',
        btn: 'Book session',
        btnNext: 'Next',
        seats: 'seats',
        subjects: {
        	t: 'Subjects',
        	in : 'IN',
        	search: 'Search subject ...',
        	allAreas: 'All areas'
        },
        date: {
        	t: 'Select a day & time',
        	hour: 'Select hour'
        },
        place: {
        	t: 'Select a Place',
        	prev: 'Day & time'
        },
        summary: {
        	t: 'Session Summary',
        	lesson: 'session',
        	btn: 'Send session request'
        },
        msgSuccess: {
        	t: 'Session request successfully sent',
        	descrip: "You will receive the tutor's confirmation very soon.",
        	details: 'Session Details',
        	btn: 'Chat',
        	btnPrev: 'View all my sessions'
        },
        msgError: {
        	t: '¡Oops!',
        	descrip: "We're sorry, something wrong happened on our end, please try again.",
        	btn: 'Go back'
        }
	},
    room:{
        onboarding: {
            btnSettings: 'Check your audio and video',
            btnJoin: 'Join now',
            header:{
                btnExit: 'Leave virtual room'
            },
            lessonDetail:{
                t: 'Session details',
            }
        },
        btnY: 'Yes',
        btnN: 'No',
        formatDateOnboarding: '{date} from {initialTime} to {endTime}',
        alertsOnboarding: {
            btnYes: 'Continue without access',
            btnNo: 'Try again',
            btnClose: 'Close',
            btnTryAgain: 'Got it',
            permissions: {
                t: 'Are you sure you want to continue without mic or video?',
                d: "<p class='descrip mb-0'>If you don't enable access to your devices, you won't be able to communicate effectively with the other participants.</p>"
            },
            microphone: {
                t: 'Are you sure you want to continue without audio?',
                d: "<p class='descrip mb-0'>If your microphone isn't working properly, the other participants will have difficulty hearing you.</p>"
            },
            audioOuput: {
                t: 'Are you sure you want to continue without sound?',
                d: "<p class='descrip mb-0'>If your speakers or headphones aren't working properly, you will have difficulty hearing the other participants.</p>"
            },
            camera: {
                t: 'Are you sure you want to continue without camera?',
                d: "<p class='descrip mb-0'>If your camera isn't working properly, the other participants won't be able to see you.</p>"
            },
            meetingEnded: {
                t: 'Heads up!',
                d: 'We have detected that no one has joined the virtual room in the last 30 minutes. Would you like to stay in the virtual room?',
                btnY: 'Yes',
                btnN: 'Leave virtual room'
            }
        },
        permissions: {
            cheking: 'Checking browser permissions...',
            gifLink: '/media/gifs/en-us/',
            gifAlt: 'Allow camera and microphone permissions.',
            btnSeeArticle: {
                link: 'https://help.linc.io/article/196-virtual-room-unblock-browser-camera-microphone-permissions',
                txt: 'See help article'
            },
            btnNextWithoutPermissions: 'Continue without permissions',
            prompt: {
                t: 'Allow browser permissions',
                d: 'Linc needs access to your camera and microphone so that other participants can see and hear you. You will need to confirm this decision on each browser and computer you use.'
            },
            block: {
                t: 'Your camera and microphone are blocked',
                d: "Linc needs access to your camera and microphone. Click the camera blocked icon in your browser's address bar, allow access, and then refresh the page."
            },
            empty: {
                t: 'No camera/microphone found',
                d: 'Check your system settings to make sure that a camera and microphone are available. If not, plug one in. You might need to restart your browser.'
            },
            errorSystem: {
                t: "Can't use your camera and microphone",
                d: 'The browser might not have access to your camera and microphone. To fix this problem, open System Preferences.'
            },
            errorDevice: {
                t: "Microphone and camera failed",
                d: 'Check your system settings to make sure that a camera and microphone are available. You might need to restart your browser.'
            },
            config: {
                t: 'Allow browser permissions',
                d: 'You need to allow your web browser camera and microphone permissions to access the virtual room.'
            },
            msg: 'You do not have permission to enter this room.',
            msgFinish: "Virtual room will automatically close in {time} minute.",
            titleFinish: "Session has finished",
            alert: {
                t: 'Click "allow" above',
                msg: 'to grant camera and microphone access.',
                aTxt: 'See help article',
                aLink: 'https://help.linc.io/article/194-virtual-room-allow-browser-camera-microphone-permissions',
            }  
        },
        tips: {
            tip: 'TIP',
            btnBack: 'Back',
            promptPermissions:{
                tip1: 'You will have to refresh your browser after allowing access.'
            },
            microphone: {
                tipsT: 'Microphone not working?',
                tipsD: 'Check your control panel for microphone input settings.',
                tip1: 'Make sure your microphone volume is up.',
                tip2: 'Try updating your browser to the latest version and restarting it.',
                tip3: 'Try moving closer to the microphone and reducing any background noise.',
                tip4: 'Using a headset? Try a different cable or plugging it into a different port.'
            },
            audioOuput: {
                tipsT: "Can't hear the audio cue?",
                tipsD: 'Make sure your volume is turned up and headphones or any external speakers are turned on.',
                tip1: 'Make sure your sound is not muted.',
                tip2: 'Wearing headphones during your session helps reduce outside noise and echoes.',
                tip3: 'Wearing headphones during your session helps reduce outside noise and echoes.',
                tip4: 'Try updating your browser to the latest version and restarting it.'
            },
            camera: {
                tipsT: 'Video not working?',
                tipsD: "Check your computer's settings in the control panel.",
                tip1: 'Make sure your camera is plugged in and nothing is blocking it.',
                tip2: 'Double-check that your camera is plugged in and nothing is blocking it.',
                tip3: 'Try updating your browser to the latest version and restarting it.'
            }  
        },
        microphone: {
            config: {
                t: 'Speak into your microphone',
                d: 'Make sure your microphone is plugged in and connected before you start.',
                q: 'Is the audio level meter moving?',
                btnN: 'Continue without microphone'
            },
            audioLvl: 'Audio level',
            select: {
                label: 'Select your microphone'
            },
            alerts: {
                denied: {
                    issue: 'Your microphone is blocked',
                    info: "Linc needs access to your microphone. Click the camera blocked icon in your browser's address bar, allow access, and then refresh the page."
                },
                fail: 'Microphone failed',
                empty: {
                    issue: 'No microphone found',
                    info: 'Check your system settings to make sure that a microphone is available. If not, plug one in. You might need to restart your browser.'
                },
                mute: 'Microphone is muted',
                change: '{mic} connected',
                errorSystem: {
                    issue: "Can't use your microphone",
                    info: 'The browser might not have access to your microphone. To fix this problem, open System Preferences.'
                },
                errorDevice: {
                    issue: "Microphone failed",
                    info: 'Check your system settings to make sure that a microphone is available. You might need to restart your browser.'
                }
            }
        },
        voiceFocus: {
            active: 'Active',
            block: 'Disable',
            alert: 'Lorem ipsum dolor sit amet'
        },
        audioOuput:{
            btnTest: 'Play test sound',
            playingTestSound: 'Playing...',
            config: {
                t: 'Listen for the audio cue',
                q: 'Is the speaker working?',
                btnN: 'Continue without speakers',
            },
            select: {
                label: 'Select your speaker'
            },
            alerts: {
                empty: 'System default speakers',
                denied: 'The speaker is blocked'
            }
        },
        camera: {
            you: 'You',
            config: {
                t: 'Check for video',
                q: 'Can you see yourself?',
                btnN: 'Continue without camera',
            },
            select: {
                label: 'Select your camera'
            },
            alerts: {
                loading: 'Camera is starting',
                denied: {
                    issue: 'Your camera is blocked',
                    info: "Linc needs access to your camera. Click the camera blocked icon in your browser's address bar, allow access, and then refresh the page."
                },
                fail: 'Camera failed',
                empty: {
                    issue: 'No camera found',
                    info: 'Check your system settings to make sure that a camera is available. If not, plug one in. You might need to restart your browser.'
                },
                mute: 'Camera is muted',
                change: '{cam} connected',
                errorSystem: {
                    issue: "Can't use your camera",
                    info: 'The browser might not have access to your camera. To fix this problem, open System Preferences.'
                },
                errorDevice: {
                    issue: "Camera failed",
                    info: 'Check your system settings to make sure that a camera is available. You might need to restart your browser.'
                }
            }
        },
        videoQuality: {
            select: {
                label: 'Video quality (send)'
            }
        },
        sharingScreen: {
            alert: {
                t: 'Screen sharing not available',
                msg: "Sorry, Your browser doesn't allow screen sharing. To share your screen, you must use either Chrome or Firefox from a desktop computer."
            }
        },
        header:{
            individualType: '1-on-1 session',
            groupType: 'Group session',
            tabUsers: 'Users',
            tabChat: 'Chat',
            student: 'Student',
            tutor: 'Tutor',
            duration: 'Time',
            sharingScreen: {
                local: 'Your presentation',
                remote: 'Presentation'
            },
            pin:{
                f: 'Pin to screen',
                nf: 'Unpin'
            }
        },
        toolbar:{
            cam: {
                m: 'Turn off camera',
                u: 'Turn on camera'
            },
            mic: {
                m: 'Turn off microphone',
                u: 'Turn on microphone',
                spk: 'Are you talking? Your mic is off. Click the mic to turn it on.'
            },
            screenShare: {
                m: 'Stop sharing screen',
                u: 'Share screen'
            },
            takeAttendance: 'Take attendance',
            exit: 'Leave virtual room',
            more: 'More',
            fullScreen: {
                m: 'Exit full screen',
                u: 'Full screen'
            },
            help:{
                t: 'Help'
            }
        },
        sessionDetails: {
            btnSessionDetails: 'Session details',
            typeHtml: '<span class="sd-item-info">Type:</span> {type}',
            subjectHtml: '<span class="sd-item-info">Subject:</span> {subject}',
            tutorHtml: '<span class="sd-item-info">Tutor:</span> {tutor}',
            dateHtml: '<span class="sd-item-info">Scheduled date:</span> {date}',
            timeHtml: '<span class="sd-item-info">Scheduled time:</span> {time}',
            individualType: '1-on-1',
            groupType: 'Group'
        },
        modalSettings: {
            t: 'Audio & video',
            subT: 'Settings',
            voiceFocus:{
                t: 'Noise cancellation',
                d: "Filters out background noise that isn't speech automatically"
            }
        }
    },
	modals: { // modals
		cancel: {
			tTop: 'Cancel',
            tDown: 'Session',
            msg: 'Are you sure you want to cancel the session?',
            legendT: 'We will notify the tutor',
			legendS: 'We will notify the student',
            btnA: 'Yes, cancel session',
            btnN: "Not yet",
		},
		confirm: {
            tTop: 'Confirm',
            tDown: 'Session',
            msg: 'Are you sure you want to confirm the session?',
            btnA: 'Yes, confirm session',
            btnN: 'Not yet',
		},
		start: {
            tTop: 'Start',
            tDown: 'Session',
            msg: 'Are you sure you want to start the session?',
            btnA: 'Yes, start session',
            btnN: 'Not yet',
		},
		finished: {
            tTop: 'End',
            tDown: 'Session',
            msg: 'Are you sure you want to finish the session?',
            btnA: 'Yes, end session',
            btnN: 'Not yet',
            btnLeaveRoom: 'Leave virtual room without ending the session'
		},
		confirmLesson: { // Confirm 1-on-1 session (student profile)
            tTop: 'Session start',
            tDown: 'Request',
            msg: "There's a session awaiting for your approval to start",
            btnA: 'Confirm the session start',
            btnN: 'Reject session start request',
		},
		attendance: {
            t: 'Attendance',
            btn: 'Update attendance'
		},
		feedback: {
            t: 'Feedback',
            duration: 'Duration',
            review: 'Comments',
            group: 'Group session',
            placeholder: 'Type your comments ...',
            btn: 'Send feedback'
		},
		movil:{
			t: 'Browser not supported',
			msg: 'Virtual room is only available through a desktop computer.'
		},
		browser: {
			t: 'Unsupported browser',
			msg: 'Screen sharing tool is not supported in this browser. Please use Chrome to share.'
		},
        shareScreen: {
            t: 'Screen sharing not available',
            msg: 'Only 2 participants can share their screen at the same time.'
        },
        meetingEndedFromAnotherDevice: {
            t: 'Heads up!',
            msg: 'You have joined the virtual room using another device.'
        },
        joinOnboarding: {
            t: 'Your session has started!',
            tDetail: 'Session details',
            btnOtherMoment: 'Not yet'
        },
		/*feedback: {
			duration: 'Duration',
            place: 'Place',
			rt: 'Selecciona los motivos de la calificación',
			placeholder: 'Escribe tus comentarios',
            btn: 'Rate',
			tutor: {
				t: 'Rate the student',
                r1: 'No explica con claridad',
                r2: 'Poco conocimiento del tema',
                r3: 'No fue puntual',
                r4: 'No fue amable',
                r5: 'Conducta inapropiada',
                review: '¿Cómo puede mejorar el tutor?'
			},
			student: {
				t: 'Rate the tutor',
                r1: 'Se distrae en clase',
                r2: 'No intenta aprender',
                r3: 'Sin disponibilidad a la retroalimentación',
                r4: 'Impuntual',
                r5: 'Conducta inapropiada',
                review: '¿Cómo puede mejorar el estudiante?'
			},
			group: {
				t: 'Rate the group',
                r1: 'Se distrae en clase',
                r2: 'No intenta aprender',
                r3: 'Sin disponibilidad a la retroalimentación',
                r4: 'Impuntual',
                r5: 'Conducta inapropiada',
                review: '¿Cómo pueden mejorar los estudiantes?'
			},	
		},*/
		assistants: {
			t: 'Assistants',
			btn: 'Update attendance'
		},
		join: {
			t: 'Enrolled students'
		},
		place: {
			j: 'Define a place',
			t: 'for the session',
			descrip: 'You can update the place where the in-person session is going to take place. Students will get notified.',
			placeholder: 'Place',
			btn: 'Update place'
		},
        topic: {
            j: 'Define topic',
            descrip: 'If you would like the tutor to cover a specific topic, type it below.',
            placeholder: 'Type here…',
            btn: 'Update topic'
        },
		notification: {
			tHtml: 'Turn on <span class="d-block w-100">Notifications</span>',
			msgHtml: 'We will let you know the status of your <strong>sessions requests</strong> or when you receive <strong>new messages.</strong>',
			btn : 'Activate',
			btn2: 'Not now'
		}
	},
	subjects: { // Subjects
		t: 'Assigned subjects',
		descripHtml: 'The following are the subjects you are allowed to teach. If you would like to add or <strong>remove subjects</strong>, please contact the administrator of the platform in your institution.',
		tType: 'Type',
		tMode: 'Mode',
		duration: 'Duration'
	},
	availability: { // Availability
		/*t: 'Availability',
		t1: 'availability.',
		descrip: 'Manage your availability below',
		btnAdd: 'Add block of time',
		btnSave: 'Update availability',
		vatationsMode: 'Vacation mode',
		disableDay: 'Disable day'*/
        btn: {
            cancel: 'Cancel',
            more: 'more'
        },
        vacationMode:{
            t: 'Vacation mode enabled',
            d: 'Turn off vacation mode using the upper right switch.'
        },
        toolbar: {
            prevSubjects: 'Go back to subjects',
            prevCalendar: 'Go back to general availability',
            vacationMode: 'Vacation mode',
            editBySubjects: 'Customize by subject'
        },
        calendar: {
            t: 'Set your general availability',
            d: 'Use the calendar below your set your availability to teach your sessions.',
            week: 'Week',
            month: 'Month'
        },
        customize: {
            t: 'Set your availability by subject',
            d: 'Optionally, define specific schedules and places to teach each of your subjects.',
            tAS: 'Assigned subjects',
            availability: 'Custom Availability',
            places: 'Custom Places',
            placeholderSearchSubject: 'Search for a subject',
            placeholderSearchPlace: 'Search for a place',
            subject: 'Subject',
            filters:{
                duration: 'Duration',
                both: 'Both'
            },
            btnCustomize: 'Customize',
            msgNotFound: 'Your search did not match any subjects.',
            type: 'Type',
            mode: 'Mode',
            specificScheduling: {
                t: 'Specific schedules',
                btnClear: 'Clear schedules'
            },
            specificPlaces: {
                t: 'Specific places',
                btnClear: 'Clear selected places',
                btnSave: 'Save',
                filterSelected: 'Selected first',
                msgNotFound: 'Your search did not match any places.'
            }
        },
        modals:{
            vacationMode: {
                t: 'Vacation mode',
                d1: 'Enabling vacation mode will remove you from search results and prevent students from requesting sessions with you. You can disable vacation mode at any time.',
                d2: 'Disabling vacation mode will make you available in search results, and students will be able to request sessions with you.',
                btnAvailable: 'Disable vacation mode',
                btndisabled: 'Enable vacation mode'
            },
            availability: {
                t1: 'Edit Availability',
                t2: 'Apply to multiple...',
                d: 'Manage your availability below.',
                msgChanges: 'Do you want to close without saving the changes?',
                tCustom: 'Override date-specific availability?',
                msgCustomHtml: `<p class="cgh-msg mb-4">For {dates} you have date-specific availability defined on {totalDates} dates. Would you like to override these dates?</p>`,
                btnYes: 'Yes',
                btnNo: 'No',
                btnDontOverride: "Don't override",
                btnOverride: 'Override',
                and: 'and',
                from: 'From',
                to: 'To',
                unavailable: 'Unavailable',
                btnAdd: 'Add block of time',
                btnUnavailable: "I'm unavailable",
                btnClear: 'Clear',
                btnApplySpecificDay: 'Apply to {date} only',
                btnApplyToAll: 'Apply to all {day}',
                btnApplyToMultiple: 'Or apply to multiple...',
                btnRadioDays: 'repeating days of the week',
                btnRadioCalendar: 'specific dates',
                selectedDays: '{cont} dates selected',
                btnClear: 'Clear',
                btnApply: 'Apply',
            },
            specificScheduling: {
                t: 'Edit Availability',
                d: 'Manage your availability below.',
                msgChanges: 'Are you sure do you want to close without save changes?',
                checkboxesDays:{
                    su: 'SU',
                    mo: 'MO',
                    tu: 'TU',
                    we: 'WE',
                    th: 'TH',
                    fr: 'FR',
                    sa: 'SA'
                },
                t1: 'Are you sure you want to clear all your schedules for this subject?',
                msg1: 'Every block of time on each day of the week is going to be deleted.',
                btnY: 'Yes, I do'
            },
            specificPlaces: {
                t1: 'Are you sure you want to clear all your selected places for this subject?',
                msg1: 'Students will be able to select any place from the list when scheduling a 1-on-1 session for this subject.',
                btnY: 'Yes, I do'
            }
        }
	},
	bio: { // Biography
		t: 'Professional biography',
        placeholder: 'Write your biography here ...',
		descrip1: 'Your biography is visible for all students in your',
		descrip2: "tutor's profile.",
		descrip3: 'Include your qualifications, any experience tutoring, and something interesting about yourself.',
		btnSave: 'Update bio'
	},
	profile: { // Tutor profile
		about: 'About',
		availability: 'Availability',
		subjects: 'Subjects',
		reviews: 'Reviews',
		btnEdit: 'Edit',
		btnMore: 'View more',
		btnLess: 'View less',
		btnScheduleShort: 'Book',
        btnScheduleLarge: 'Book session',
		seeProfile: "Show tutor's profile",
		hideProfile: "Hide tutor's profile"
	},
	chat: { // Chat
        tp: 'Pending session request',
        pending: 'Pending',
        tu: 'Upcoming session',
        upcoming: 'Upcoming',
        to: 'Ongoing session',
        ongoing: 'Ongoing',
        tf: 'Completed session',
        tc: 'Canceled session',
        selectAChat: 'Select a session to enter the chat conversation',
        placeholder: 'Type a message...',
        memmers: 'Students',
        attachFile: 'Attach file',
        emoji: 'Emojis',
        download: 'Download',
        cancelDownload: 'Cancel download',
        dragFiles: 'Drag and drop files here',
        btnClose: 'Cancel',
        fileTooBig: 'The maximum upload size is 10 MB',
        unsupportedFileType: 'Unsupported file type',
        fileNotFound: 'File was deleted or not found',
        listChatsEmpty: {
            s: 'No chats yet! Schedule a session to start chatting with your tutors',
            t: 'Your sessions chats will appear here as soon as you receive new tutoring requests'
        },
        emojiPicker: {
            search: 'Search',
            activity: "Activity",
            flags: "Flags",
            foods: "Food & Drink",
            frequently: "Frequently Used",
            objects: "Objects",
            nature: "Animals & Nature",
            peoples: "Smileys & People",
            symbols: "Symbols",
            places: "Travel & Places"
        }
	},
    notifications: {
        t: 'Notifications',
        btnBack: 'Back',
        status: {
            pending: {
                t: 'Pending',
                tooltip: {
                    t: "You haven't yet accepted the request to teach this session.",
                    s: "The tutor hasn't yet accepted the request to teach this session."
                }
            },
            upcoming: {
                t: 'Upcoming',
                tooltip: {
                    t: "You have accepted the request to teach this session.",
                    s: 'The tutor has accepted the request to teach this session.'
                }
            },
            ongoing: {
                t: 'Ongoing',
                tooltip: {
                    t: 'This session is ongoing.',
                    s: 'This session is ongoing.'
                }
            },
            canceled: {
                t: 'Canceled',
                tooltip: {
                    t: 'This session was canceled.',
                    s: 'This session was canceled.'
                }
            },
            finished: {
                t: 'Completed',
                tooltip: {
                    t: 'You have completed this session.',
                    s: 'The tutor has already completed this session.'
                }
            }
        },
        settings:{
            checkAll: 'Mark all as read',
            seeAll: 'View all'
        },
        type: {
            // Student
            US : { 
                t: 'Upcoming session'
            },
            PS : {
                t: 'Session start request'
            },
            OS : {
                t: 'The session has started'
            },
            CR: {
                t: 'Session request declined'
            },
            CS: {
                t: 'Session completed'
            },
            // Tutor
            PN: {
                t: 'Session request'
            },
            PR: {
                t: 'Session start request declined'
            },
            PA: {
                t: 'The session has started'
            },
            CA: {
                t: 'Session completed'
            },
            CE: {
                t: 'Session completed'
            },
            RT: {
                t: 'Reminder to start your session on time'
            },
            RO: {
                t: 'Reminder to end your session'
            },
            // Both
            CC: {
                t: 'Session canceled'
            },
            AP: {
                t: 'Session canceled'
            },
            AU: {
                t: 'Session canceled'
            },
            RU: {
                t: 'Upcoming session reminder'
            }
        },
        alerts:{
            empty: 'No notifications yet! We’ll notify you when something arrives',
            select: 'Select a notification'
        },
        time: {
            recent: 'Just now',
            minuteAgo: '1 min ago',
            minutesAgo: '{time} mins ago',
            hourAgo: '1 hr ago',
            hoursAgo: '{time} hrs ago',
            dayAgo: '1 day ago',
            daysAgo: '{time} days ago',
            weekAgo: '1 week ago',
            weeksAgo: '{time} weeks ago',
            monthAgo: '1 month ago',
            monthsAgo: '{time} months ago',
            yearAgo: '1 year ago',
            yearsAgo: '{time} years ago'
        }  
    },
	moreInfo: { // More info
		infoPendingT: 'Sessions requests that require your confirmation.', // Pending
		infoPendingS: "Sessions requests waiting for tutor's confirmation.", // Pending
		infoOngoingT: 'Sessions being taught at this moment.', // Ongoing
		infoOngoingS: 'Sessions being taught at this moment by the tutor.', // Ongoing
		infoUpcomingT: 'Sessions you have confirmed to teach.', // Upcoming
		infoUpcomingS: 'Sessions that have been confirmed by the tutor to be taught.', // Upcoming
		infoStatistics: "Statistics about your tutoring activity.", // Statistics
		infoSLastesSubjects: 'List of your latest completed sessions.', // Latest subjects 
		infoSLastesTutors: 'List of the tutors with whom you have recently book sessions.', // Latest Tutors 
        infoVacationsMode: "Enabling vacation mode will remove you from search results and prevent students from requesting sessions with you.",
		infoDisableDay: "When activating this option, you will stop appearing in the tutor's offer so you will not receive new sessions requests."
	},
    formats: {
        tooltip: {
            t: '¿Eres docente de tu institución educativa?',
            txt: 'Por favor omite los formatos que se piden a continuación y comunícate con el Departamento de Desarrollo Académico para obtener acceso al perfil de asesor dentro de la plataforma de Linc',
        },
        t: '¡Te damos la bienvenida {name}!',
        dHtml: 'Linc es la plataforma que utilizarás para gestionar tu actividad tutorial y solicitar asesorías académicas en caso de requerirlo. De acuerdo al manual del tutor, <span class="bold">es necesario llenar los siguientes formatos antes de continuar:</span>',
        btnToCoplete: 'Completar',
        completed: 'Completado',
        consult: 'Consultar',
        qualityResult: {
            t: 'CALIFICACIÓN GENERAL',
            low: 'MUY BAJO'
        },
        fm1: {
            t: 'Ficha de identificación',
            d: 'Este documento es confidencial, tus respuestas serán utilizadas para mejorar el servicio que puede brindar la institución. Por lo que se te pide contestar todas las preguntas con la mayor sinceridad.',
            date: 'Fecha de llenado:',
            generalDatas: 'DATOS GENERALES',
            form: {
                btn: 'Enviar ficha',
                btnPhoto: 'Subir foto de perfil',
                career: {
                    label: 'Carrera',
                    placeholder: 'Selecciona tu carrera'
                },
                period: {
                    label: 'Semestre',
                    placeholder: 'Selecciona tu semestre'
                },
                noControl: 'No. control',
                lastName: 'Apellido paterno',
                motherLastName: 'Apellido materno',
                name: 'Nombre(s)',
                gender: {
                    label: 'Género',
                    placeholder: 'Selecciona tu género'
                },
                email: 'Correo electrónico',
                addressPhone: 'Teléfono domicilio',
                phone1: 'Celular 1',
                phone2: 'Celular 2',
                birthday: 'Fecha de nacimiento',
                age: 'Edad',
                placeOfBirth: 'Lugar de nacimiento',
                civilStatus: {
                    label: 'Estado civil',
                    placeholder: 'Selecciona tu estado civil'
                },
                address: 'Domicilio actual',
                scholarship: {
                    label: 'Escolaridad',
                    placeholder: 'Selecciona tu escolaridad'
                },
                schoolName: 'Nombre de la escuela',
                average: 'Promedio obtenido',
                onScholarship: '¿Has estado becado?',
                typeOfScholarship: {
                    label: 'Tipo de beca recibida',
                    placeholder: 'Selecciona un tipo de beca'
                },
                institutionName: 'Nombre de la institución',
                job: '¿Trabajas?',
                institutionJob: 'Nombre de la empresa',
                jobSchedule: 'Horario de trabajo',
                liveWith: {
                    label: 'En el transcurso de tus estudios vivirás con …',
                    placeholder: 'Selecciona una opción'
                },
                supportPerson: 'En caso de accidente avisar a',
                supportPhone: 'Teléfono del responsable',
                dadScholarship: {
                    label: 'Máximo grado de escolaridad de tu padre',
                    placeholder: 'Selecciona un grado de escolaridad'
                },
                momScholarship: {
                    label: 'Máximo grado de escolaridad de tu madre',
                    placeholder: 'Selecciona un grado de escolaridad'
                },
                dadStatus: 'Actualmente tu padre',
                momStatus: 'Actualmente tu madre',
                addressJobDad: 'Nombre o lugar de trabajo de tu padre',
                addressJobMom: 'Nombre o lugar de trabajo de tu madre'
            }
        },
        fm2: {
            t: 'Encuesta sobre las habilidades de estudio',
            dHtml: `<p class="v-formt-txt mb-sm-4 mb-3">Instrucciones: La presente encuesta está formada por tres breves cuestionarios, en los cuales puedes indicar los problemas referentes a organización, técnicas y motivación en el estudio, que quizá perjudican tu rendimiento académico. Si contestas todas las preguntas con sinceridad y reflexión podrás identificar mucho de tus actuales defectos el estudiar.</p>
                    <p class="v-formt-txt mb-sm-4 mb-3">Cada cuestionario contiene veinte preguntas, a las que se contestará con sí o no.</p>
                    <p class="v-formt-txt mb-0">No hay respuestas "correctas" o "incorrectas", ya que la contestación adecuada es tu juicio sincero sobre tu modo de actuar y tus actitudes personales, respecto al estudio. Responde tan rápido como puedas. Pero son caer en el descuido, y no dediques demasiado tiempo a una sola pregunta. No omitas ninguna de ellas.</p>`,
            date: 'Fecha de llenado:',
            name: 'Nombre:',
            semester: 'Semestre:',
            email: 'Correo:',
            noControl: 'No. control:',
            sub1: 'ENCUESTA PARA ORGANIZACIÓN DEL ESTUDIO',
            sub2: 'ENCUESTA SOBRE TÉCNICAS DE ESTUDIO',
            sub3: 'ENCUESTA SOBRE MOTIVACIÓN PARA EL ESTUDIO',
            tableT: 'Preguntas',
            scaleT: 'Puntaje',
            tableAYes: 'SI',
            tableANo:'NO',
            quality: 'CALIFICACIÓN',
            btn: 'Enviar Encuesta'
        },
        fm3: {
            t: 'Inventario sobre estilos de aprendizaje',
            btn: 'Enviar Inventario',
            dHtml: `<p class="v-formt-txt mb-sm-4 mb-3">Este inventario es para ayudarle a descubrir se manera preferida de aprender. Cada persona tiene su manera preferida de aprender. Reconocer sus preferencias le ayudará a comprender sus fuerzas en cualquier situación de aprendizaje.</p>
                    <p class="v-formt-txt mb-sm-4 mb-3">Por favor, responda verdaderamente a cada pregunta. Responda según lo que hace actualmente, no según lo que piense que sea la respuesta correcta.</p>
                    <p class="v-formt-txt mb-0">Use la escala siguiente para responder a cada pregunta:</p>`,
            qstT: {
                q1: '1 = Nunca',
                q2: '2 = Raramente',
                q3: '3 = Ocasionalmente',
                q4: '4 = Usualmente.',
                q5: '5 = Siempre'
            },
            result: 'RESULTADOS'
        },
        fm4: {
            t: 'Determinación de problemas emocionales (test de autoestima)',
            tHtml: 'Determinación de problemas emocionales <br> TEST DE AUTOESTIMA',
            dHtml: '<p class="v-formt-txt mb-0">Instrucciones: Realiza el siguiente test para evaluar y comprobar tu nivel de autoestima. Contesta con la mayor sinceridad posible a cada una de las siguientes preguntas eligiendo la respuesta que más se identifique con tu forma de pensar o de actuar.</p>',
            btn: 'Enviar Test',
            r1: 'Tienes un nivel algo bajo de autoestima y se nota en la valoración que haces de ti mismo, de tu trabajo y de tu forTienes tuna en la vida. Una de las razones por las que percibimos más las cosas negativas es que estas son más destacables que las positivas. En la escuela o de niños en casa, siempre nos regañaban por lo malo pero se olvidaban de felicitamos por lo bueno. Para elevar el nivel de nuestra autoestima es necesario aceptarse tal como uno es y valorar más lo que somos y lo que tenemos. A veces puede parecer difícil, pero si practicas unos minutos diarios a pensar en las cosas positivas que tienes, irás poco a poco queriéndote más.',
            r2: 'Tu nivel de autoestima es suficiente pero más a menudo de lo que te gustaría, te falla y te abandona. Los sucesos negativos que nos pasan absorben más nuestros sentidos pues son más desagradables que las cosas positivas, por ello les damos más importancia de la que merecen y no nos fijamos en lo bueno con igual intensidad. Todas las personas tenemos cosas positivas y todos cometemos errores o tenemos días flojos. La clave está en darle a cada cosa el justo valor que tiene, ver los errores como maneras de aprendizaje y reconocer las cosas buenas que poseemos. También podemos aprender a querernos a nosotros mismos cuidándonos con más mimo y dándonos gustos.',
            r3: 'Tu nivel de autoestima es muy bueno, sabes dar a las cosas el valor que merecen, reconoces lo bueno y no te dejas amilanar fácilmente por las adversidades. Eres una persona sensata y realista que tiene confianza en sí misma, tus objetivos no son irrealizables y te gusta luchar para conseguirlos. Has aprendido que las cosas no salen siempre como quieres ni cuando quieres, que todo requiere un esfuerzo y que es normal equivocarse. Cuando tienes un error procuras aprender lo que este te enseña y a evitarlo en futuras ocasiones. Un buen nivel de autoestima nos hace tener más ganas de luchar por las cosas, nos ayuda a no desistir en nuestro empeño de lograr algo y hace que la vida nos sea más amable y agradable.',
            r4: 'Tienes un alto nivel de autoestima y mucha confianza y seguridad en ti mismo. Ambos sentimientos son muy positivos y necesarios para conseguir mucho más de lo que nos proponemos, sin embargo, es preciso ser cautelosos. Al igual que una baja autoestima, un exceso de esta puede hacernos perder la objetividad de nuestras acciones, hacernos creer demasiado buenos en algo, y si sobreviene una decepción o un fracaso, hacernos sufrir más de lo razonable. También si nos creemos demasiado especiales podemos hacer daño a los demás minusvalorando su esfuerzo o no apreciándolo en lo que vale. Siempre conviene tener una dosis de modestia.'
        },
        fm5: {
            t: 'Test de Asertividad',
            dHtml: '<p class="v-formt-txt mb-0">Instrucciones: Selecciona el número de la opción con la que mejor te identificas.</p>',
            qstT: {
                q1: '1 = Con frecuencia',
                q2: '2 = De vez en cuando',
                q3: '3 = Casi nunca',
                q4: '4 = Nunca'                                          
            },
            btn: 'Enviar Test'
        }
    }
}