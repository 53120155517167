var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-field",
      class: {
        focus: _vm.isFocus,
        active: _vm.isActive,
        error: _vm.error,
        disabled: _vm.disabled,
        onlyRead: _vm.onlyRead
      },
      style: { "--focus-color": _vm.focusColor }
    },
    [
      _vm.label != ""
        ? _c(
            "label",
            {
              staticClass: "v-placeholder",
              class: { isActive: _vm.activeLabel },
              style: _vm.labelStyles
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "v-content-field position-relative" }, [
        _c("input", {
          staticClass: "field_",
          attrs: {
            name: _vm.name,
            maxlength: _vm.maxlength,
            type: _vm.statusEye ? "text" : _vm.type,
            placeholder: _vm.placeholder,
            required: _vm.required,
            disabled: _vm.disabled,
            readonly: _vm.onlyRead >= 1
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.handleInput($event.target.value)
            },
            focus: function($event) {
              _vm.focus = true
            },
            blur: function($event) {
              _vm.focus = false
            }
          }
        }),
        _vm._v(" "),
        _vm.type === "password" && _vm.iconEye
          ? _c(
              "div",
              {
                staticClass: "content-icon btn-eye",
                on: {
                  click: function($event) {
                    _vm.statusEye = !_vm.statusEye
                  }
                }
              },
              [
                !_vm.statusEye
                  ? _c("svgicon", {
                      staticClass: "v-icon",
                      attrs: { name: "eye" }
                    })
                  : _c("svgicon", {
                      staticClass: "v-icon",
                      attrs: { name: "eye-crossed" }
                    })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }