import { render, staticRenderFns } from "./card-lesson.vue?vue&type=template&id=24471661&"
import script from "./card-lesson.vue?vue&type=script&lang=ts&"
export * from "./card-lesson.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24471661')) {
      api.createRecord('24471661', component.options)
    } else {
      api.reload('24471661', component.options)
    }
    module.hot.accept("./card-lesson.vue?vue&type=template&id=24471661&", function () {
      api.rerender('24471661', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lessons/card-lesson.vue"
export default component.exports