var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-chat-input",
      style: _vm.disabled ? "pointer-events: none; opacity: 0.5;" : ""
    },
    [
      _c("div", { staticClass: "col v-content-field-text pr-2" }, [
        _c(
          "div",
          { staticClass: "v-chat-input position-relative" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.txt,
                  expression: "txt"
                }
              ],
              ref: "input",
              staticClass: "v-field-text w-100",
              attrs: {
                placeholder: _vm.$t("chat.placeholder"),
                emoji: _vm.showKeyboardEmoji
              },
              domProps: { value: _vm.txt },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.sendMsg.apply(null, arguments)
                },
                blur: function($event) {
                  _vm.txt.trim()
                  _vm.showKeyboardEmoji = false
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.txt = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("v-emojis", {
              ref: "emojis",
              attrs: { disabled: _vm.disabled },
              on: {
                insertEmoji: _vm.insertEmoji,
                showEmojis: function($event) {
                  _vm.showKeyboardEmoji = true
                  _vm.$refs.input.focus()
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto pl-0 pr-0" }, [
        _c(
          "button",
          {
            staticClass: "btn-fields",
            on: {
              click: function($event) {
                return _vm.$emit("loadFile")
              }
            }
          },
          [
            _c("svgicon", {
              staticClass: "icon-clip",
              attrs: { name: "clip", original: "" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "hover-txt" }, [
              _vm._v(_vm._s(_vm.$t("chat.attachFile")))
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col v-content-btn-send pl-2" }, [
        _c(
          "button",
          { staticClass: "v-btn-send w-100 h-100", on: { click: _vm.sendMsg } },
          [
            _c("svgicon", {
              staticClass: "v-btn-send-icon",
              class: {
                "fill-t": _vm.auth.permission == "tutor",
                "fill-s": _vm.auth.permission == "student"
              },
              attrs: { name: "shape" }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }