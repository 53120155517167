import attendance from './attendance.vue';
import feedback from './feedback.vue';
import assistants from './assistants.vue';
import joinedStudentsModal from './joined-students-modal.vue';
import tutoringPlace from './tutoring-place.vue';
import crud from './crud.vue';
import alert from './alert.vue';
import pushNotifications from './push-notifications.vue';
import lessonTopic from './lesson-topic.vue';

function plugin(Vue){
	Vue.component('v-modal-attendance', attendance); // ready
    Vue.component('v-modal-feedback', feedback);  // ready
    Vue.component('v-modal-assistants', assistants); // ready
    Vue.component('v-modal-joinedStudents', joinedStudentsModal); // ready
    Vue.component('v-modal-changePlace', tutoringPlace); // ready
    Vue.component('v-modal-crud', crud);  // ready
    Vue.component('v-modal-alert', alert);  // ready
    Vue.component('v-modal-changeTopic', lessonTopic);  // ready
    Vue.component('v-push-notifications', pushNotifications);
}

export default plugin