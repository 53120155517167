<template>
	<div id="tutor-profile" class="pt-5">

		<section class="container-fluid pb-4 mt-5">
			<div class="row">
				<!-- ================ Tutor profile ================== -->
				<div class="col-md pl-0 pr-0">
				    <div class="paper d-flex justify-content-center flex-wrap p-4 mb-3">
					    <div class="image-tutor mb-4">
						    <v-img-profile :img="tutorInfo.photo" size="180px"></v-img-profile>
						    <v-circles :vCircle="1" size="50%"/>
						    <v-circles :vCircle="2" size="50%"/>
					    </div>
					    <h3 class="name-tutor text-center w-100" :class="tutorInfo.rating?'mb-0':'mb-3'">{{ fullName }}</h3>
					    <star-rating class="score mb-3" :increment="0.1" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" :rating="parseFloat(tutorInfo.rating)" :star-size="20" :border-width="0.1" text-class="score-n m-0" inactive-color="#FCE5BF" active-color="#F5A623" read-only v-if="tutorInfo.rating"/>

					    <!-- ================ About ================== -->
					    <div class="d-flex justify-content-center w-100 mb-4">
						    <div class="col-12 row section-tutor pl-0 pr-0">
						    	<div class="title col-12 col-lg text-lg-right pl-0 mb-2">
						    		<h4 class="mb-0">{{ $t('profile.about') }}</h4>
						    		<router-link class="btn-edit" :to="{ name: 'bio-tutor' }" tag="button"><svgicon class="icon" name="pencil3"/> {{ $t('profile.btnEdit') }}</router-link>
						    	</div>
							    <div class="about line col-12 col-lg pb-3 pr-0 pl-0 pl-lg-3">
								    <div class="description" :class="{ suspend : statusBtnDescript && heightDescrip=='200px' }" ref="descrip" :style="{ height: heightDescrip }">
							    		<pre class="txt">{{ tutorInfo.description }}</pre>
							    	</div>
								    <button class="btn-read-more color-tutor-light mt-2" @click="showDescrip(heightDescrip=='200px')" v-if="statusBtnDescript">{{ heightDescrip=='200px'?$t('profile.btnMore'):$t('profile.btnLess') }}</button>
							    </div>		
						    </div>
					    </div>

					    <!-- ================ Schedule ================== -->
					    <div class="d-flex justify-content-center w-100">
						    <div class="col-12 row section-tutor pl-0 pr-0">
						    	<div class="title col col-12 col-lg text-lg-right pl-0 mb-2">
						    		<h4 class="mb-0">{{ $t('profile.availability') }}</h4>
						    		<router-link class="btn-edit" :to="{ name: 'availability-tutor' }" tag="button"><svgicon class="icon" name="pencil3"/> {{ $t('profile.btnEdit') }}</router-link>
						    	</div>
							    <div class="col line col-12 col-lg pb-3 pr-0 pl-0 pl-lg-3">
								    <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Monday'].length > 0 }">{{ $t('days.monday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Monday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Tuesday'].length > 0 }">{{ $t('days.tuesday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Tuesday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Wednesday'].length > 0 }">{{ $t('days.wednesday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Wednesday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Thursday'].length > 0 }">{{ $t('days.thursday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Thursday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Friday'].length > 0 }">{{ $t('days.friday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Friday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Saturday'].length > 0 }">{{ $t('days.saturday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Saturday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							        <div class="schedule tutor row mb-2">
								        <span class="day col" :class="{ active : availability['Sunday'].length > 0 }">{{ $t('days.sunday') }}</span>
							            <div class="dates col">
								            <span class="date" v-for="sch in availability['Sunday']">{{ `${sch.from} - ${sch.to}` }}</span>
							            </div>
							        </div>
							    </div>
						    </div>
					    </div>

					    <!-- ================ Subjects ================== -->
					    <div class="d-flex justify-content-center w-100 mt-4">
						    <div class="col-12 row section-tutor pl-0 pr-0">
						    	<div class="title col col-12 col-lg text-lg-right pl-0 mb-2">
						    		<h4 class="mb-0">{{ $t('profile.subjects') }}</h4>
						    		<router-link class="btn-edit" :to="{ name: 'subjects-tutor' }" tag="button"><svgicon class="icon" name="pencil3"/> {{ $t('profile.btnEdit') }}</router-link>
						    	</div>
						    	<transition-group class="line col col-12 col-lg pb-3 pl-0 pr-0 pl-lg-3 pr-lg-3" name="bound" tag="div">
						    		<div class="subject row align-items-center pt-2 pb-2 ml-n2 mr-n2" :key="subject.subject_id" v-for="subject in subjects.slice(0,subjectsSlice)">
						    			<div class="col-12 col-sm-6  col-xl-7 pl-2 pr-2 info namet d-flex align-items-center">
						    				<svgicon class="icon-subject mr-2" :name="subject.icon" :color="subject.color"/>
						    				<div>
						    					<v-hover-txt class="mb-1" fontSize="1rem" :txt="subject.name" />
						    					<v-hover-txt fontSize="12px" colorTxt="#9b9b9b" :txt="subject.category" />
						    				</div>
						    			</div>
						    			<div class="col-sm col-12 pl-2 pr-2 pt-sm-0 pt-2 d-flex justify-content-start align-items-center">
						    				<div class="row ml-n2 mr-n2 w-100">
						    					<div class="col-auto info type d-flex align-items-center pl-2 pr-2">
						    						<v-lesson-tag :options="{ minWidth: '90px' }" :type="subject.type"/>
						    					</div>
						    					<div class="col-auto info data pl-2 pr-2">
						    						<v-lesson-tag :options="{ minWidth: '90px' }" :type="subject.modality"/>
						    					</div>
						    				</div>
						    			</div>
						    		</div>
                                    <button class="btn-read-more color-tutor-light mt-3" @click="subjects.length!==subjectsTotal?getSubjects(subjectsLimit):subjectsSlice=subjectsSlice+subjectsLimit" key="btn-more" v-if="btnSubjects">{{ $t('profile.btnMore') }}</button>
                                    <button class="btn-read-more color-tutor-light mt-3" key="btn-less" @click="subjectsSlice=subjectsLimit" v-else-if="subjectsTotal>subjectsLimit">{{ $t('profile.btnLess') }}</button>
						    	</transition-group>
						    </div>
					    </div>

					    <!-- ================ Reviews ================== -->
					    <div class="d-flex justify-content-center w-100 mt-4 mb-3" v-if="reviews.length > 0">
						    <div class="col-12 row section-tutor pl-0 pr-0">
							    <h4 class="title col col-12 col-lg text-lg-right pl-0 mb-2">{{ $t('profile.reviews') }}</h4>
							    <transition-group class="col-12 col-lg pb-3 pr-0 pl-0 pl-lg-3" name="bound">
							    	<div class="preview d-flex flex-wrap flex-sm-row align-items-center mb-3" :key="review.lesson_id+'-'+i" v-for="(review,i) in reviews.slice(0,reviewsSlice)">
							    		<div class="info-review col pl-0 pr-0 d-flex">
							    			<div class="content-img col pl-0 pr-0" v-if="review.type == 'Individual'">
							    				<v-img-profile class="col" :img="review.user_photo"></v-img-profile>
							    			</div>
							    			<div class="content-img v-group col pl-0 pr-0" v-else>
										    	<svgicon class="icon-group" name="group" color="#fff" />
										    </div>
							    			<div class="col pr-0 pr-sm-3">
							    				<span class="name-review d-block">{{ review.type == 'Individual'?review.student_name:$t('schedule.switch.group') }}</span>
							    				<span class="name-subject d-block mb-1"> {{ review.subject_name }} </span>
							    				<p class="txt-preview">{{ review.student_comments }}</p>
							    			</div>
							    		</div>
							    		<div class="score-date col d-flex justify-content-end flex-wrap">
							    			<star-rating class="score mb-1" :increment="0.1" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" :rating="parseFloat(review.student_rating)" :star-size="20" :border-width="0.1" text-class="score-n m-0" inactive-color="#FCE5BF" active-color="#F5A623" read-only/>
							    			<span class="date d-block w-100 text-right">{{ setDate(review.lesson_date) }}</span>
							    		</div>
							    	</div>
							    	<button class="btn-read-more color-tutor-light mt-3" @click="reviews.length!==reviewsTotal?getReviews(reviewsLimit):reviewsSlice=reviewsSlice+reviewsLimit" key="btn-more" v-if="btnReviews">{{ $t('profile.btnMore') }}</button>
							    	<button class="btn-read-more color-tutor-light mt-3" key="btn-less" @click="reviewsSlice=reviewsLimit" v-else-if="reviewsTotal>reviewsLimit">{{ $t('profile.btnLess') }}</button>
							    </transition-group>
						    </div>
					    </div>
				    </div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';

	export default{
		data(){
			return{
				tutorInfo: {},
				heightDescrip: 'initial',
				statusBtnDescript: false,
				availability: {
					Monday: [],
					Tuesday: [],
					Wednesday: [],
					Thursday: [],
					Friday: [],
					Saturday: [],
					Sunday: []
				},
				subjects: [],
				subjectsLimit: 5,
				subjectsOffset: 0,
				subjectsTotal: 0,
				subjectsSlice: 0,
				reviews: [],
				reviewsLimit: 4,
				reviewsOffset: 0,
				reviewsTotal: 0,
				reviewsSlice: 0,
			}
		},
		computed: {
            ...mapState(['auth','i18n']),
            fullName(){
            	return `${this.auth.user.name || ''} ${this.auth.user.last_name || ''}`;
            },
            btnSubjects(){
            	if (this.subjectsTotal>this.subjectsLimit && this.subjects.length!==this.subjectsTotal) {
            		return true
            	}else if(this.subjectsTotal>this.subjectsLimit && this.subjectsSlice<=this.subjectsTotal && this.subjectsSlice!=this.subjectsTotal){
            		return true;
            	}else{
            		return false;
            	}
            },
            btnReviews(){
            	if (this.reviewsTotal>this.reviewsLimit && this.reviews.length!==this.reviewsTotal) {
            		return true
            	}else if(this.reviewsTotal>this.reviewsLimit && this.reviewsSlice<=this.reviewsTotal && this.reviewsSlice!=this.reviewsTotal){
            		return true;
            	}else{
            		return false;
            	}
            }
		},
		methods: {
			...mapActions(['axiosRequest']),
            showDescrip(show){
                let inner = this.$refs.descrip.querySelector('pre').offsetHeight;
                if (show)   
                    this.heightDescrip = inner+'px';
                else
                	this.heightDescrip = '200px';
			},
            setDate(date){
                return this.$moment.utc(date).locale(this.i18n.locale).format('LL');      
            },
			getTutor(){
				this.axiosRequest({ config : {
					method: 'GET',
                    url: `${this.auth.api}/tutor/profile`,
                    params: ({ tutor_id: this.auth.user.id }),
                    headers: { authorization : this.auth.token }
				}}).then( resp => resp.data)
                .then( data => {
                    this.tutorInfo = data.tutor_info;
                    data.availability.map( data => {
                        this.availability[data.week_day].push({ from: this.$moment(data.from, 'HH:mm:ss').format('LT'), to: this.$moment(data.to, 'HH:mm:ss').format('LT') });
                    });
                }).catch( error => { });
			},
			getSubjects(offset=0){
				this.subjectsOffset += offset; 
				this.axiosRequest({ config : {
					method: 'GET',
                    url: `${this.auth.api}/tutor/subjects`,
                    params: ({ limit : this.subjectsLimit, offset: this.subjectsOffset, tutor_id: this.auth.user.id }),
                    headers: { authorization : this.auth.token }
				}}).then( resp => resp.data)
                .then( data => {
                	this.subjectsTotal = data.total;
                	this.subjectsSlice = data.total;
                	this.subjects = [ ...this.subjects, ...data.subjects ];
                }).catch( error => { });
			},
			getReviews(offset=0){
				this.reviewsOffset += offset; 
				this.axiosRequest({ config : {
					method: 'GET',
                    url: `${this.auth.api}/tutor/reviews`,
                    params: ({ limit : this.reviewsLimit, offset: this.reviewsOffset, tutor_id: this.auth.user.id }),
                    headers: { authorization : this.auth.token }
				}}).then( resp => resp.data)
                .then( data => {
                	this.reviewsTotal = data.total;
                	this.reviewsSlice = data.total;
                	this.reviews = [ ...this.reviews, ...data.reviews ];
                }).catch( error => { });
			} 
		},
		created(){
			this.getTutor();
			this.getSubjects();
			this.getReviews();
		},
		updated(){
			this.$nextTick(function () {
				if (this.$refs.descrip) {
                    let inner = this.$refs.descrip.querySelector('pre').offsetHeight;
				    if (200>=inner){
					    this.statusBtnDescript = false;
				        this.heightDescrip = 'initial';
				    }
				    else if(this.heightDescrip=='initial' && inner > 200){
					    this.statusBtnDescript = true;
					    this.heightDescrip = '200px';
				    }
			    }
            });
		}
	}
</script>