import mutations from './mutations.js';

const state = {
	menu: {
        compact: false,
        showMenuResponsive: false,
        hideText: false
    }
}

export default {
    state,
    mutations
}