/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil4': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.5 17a.5.5 0 01-.47-.671l2-5.5a.51.51 0 01.116-.183l10.5-10.5a.5.5 0 01.707 0l3.5 3.5a.5.5 0 010 .707l-10.5 10.5a.496.496 0 01-.183.116l-5.5 2a.505.505 0 01-.171.03zm2.432-5.725l-1.596 4.389 4.389-1.596L18.793 4 16 1.207 5.932 11.275z"/><path pid="1" d="M17.5 20h-16C.673 20 0 19.327 0 18.5v-16C0 1.673.673 1 1.5 1h10a.5.5 0 010 1h-10a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-10a.5.5 0 011 0v10c0 .827-.673 1.5-1.5 1.5z"/>'
  }
})
