var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalVal.modal == "v-modal-crud"
    ? _c("div", { staticClass: "confirm-modal", class: { active: _vm.show } }, [
        _c("div", {
          staticClass: "blurColor",
          on: {
            click: function($event) {
              _vm.modalVal.type != "confirmLesson" ? _vm.close() : ""
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "v-content" }, [
          _c(
            "h4",
            {
              staticClass: "title pt-4 pb-3 pl-5 pr-5 mb-0",
              class:
                _vm.modalVal.type === "cancel"
                  ? "bgColor-red-dark"
                  : `bgColor-${_vm.auth.permission}-main`
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t(`modals.${_vm.modalVal.type}.tTop`)) +
                  " "
              ),
              _c("span", { staticClass: "d-block w-100" }, [
                _vm._v(_vm._s(_vm.$t(`modals.${_vm.modalVal.type}.tDown`)))
              ]),
              _vm._v(" "),
              _vm.modalVal.type != "confirmLesson"
                ? _c("svgicon", {
                    staticClass: "btn-close",
                    attrs: { name: "cross2" },
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center pt-3 pb-4 pl-4 pr-4" },
            [
              _c("svgicon", {
                staticClass: "icon mb-3",
                attrs: { name: _vm.svgIcon.name, color: _vm.svgIcon.color }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "alert-msg w-100 mb-4" }, [
                _vm._v(_vm._s(_vm.$t(`modals.${_vm.modalVal.type}.msg`)))
              ]),
              _vm._v(" "),
              _vm.modalVal.type === "cancel"
                ? _c("p", { staticClass: "alert-legend w-100 mb-4 mb-sm-5" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          `modals.cancel.legend${
                            _vm.auth.permission == "tutor" ? "S" : "T"
                          }`
                        )
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-btn", {
                staticClass: "btn-accept mb-3 w-100",
                attrs: {
                  txt: _vm.$t(`modals.${_vm.modalVal.type}.btnA`),
                  btn: _vm.modalVal.type === "cancel" ? "error" : "main",
                  height: "44px",
                  fontSize: "13px",
                  disabled: _vm.sending
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.send()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$route.name != `room-${_vm.auth.permission}` ||
              _vm.auth.permission == "student"
                ? _c(
                    "button",
                    {
                      staticClass: "btn-cancel",
                      on: {
                        click: function($event) {
                          _vm.modalVal.type != "confirmLesson"
                            ? _vm.close()
                            : _vm.cancelLesson()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t(`modals.${_vm.modalVal.type}.btnN`)))]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn-cancel",
                      on: {
                        click: function($event) {
                          return _vm.leaveRoom()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(`modals.${_vm.modalVal.type}.btnLeaveRoom`)
                        )
                      )
                    ]
                  )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }