var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tutoring-lesson-alert" } },
    [
      _c("v-search", { ref: "search", staticClass: "mb-4" }),
      _vm._v(" "),
      _c("section", { staticClass: "container-fluid pb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "pt-4 pb-4 pt-sm-5 pb-sm-5 pl-3 pr-3 pl-sm-4 pr-sm-4 paper row justify-content-center"
          },
          [
            _vm.lessonInfo.status == "success"
              ? _c("div", { staticClass: "lesson-success w-100" }, [
                  _c(
                    "div",
                    { staticClass: "ls-title mb-4 position-relative" },
                    [
                      _c("svgicon", {
                        staticClass: "ls-title-icon",
                        attrs: { name: "checkmark-circle-fill", original: "" }
                      }),
                      _vm._v(" "),
                      _c("h3", { staticClass: "ls-title-msg mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("scheduleLesson.msgSuccess.t")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "ls-title-descript mb-0" }, [
                        _vm._v(
                          _vm._s(_vm.$t("scheduleLesson.msgSuccess.descrip"))
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ls-info p-3 p-sm-4 w-100 mb-3" }, [
                    _c("div", { staticClass: "pl-sm-3 pr-sm-3" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "ls-info-title mb-4 pb-sm-2 text-center text-sm-left"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("scheduleLesson.msgSuccess.details"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row ml-n2 mr-n2" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm-auto pl-2 pr-2 mb-3" },
                          [
                            _c("v-lesson-photo", {
                              staticClass: "ml-auto mr-auto",
                              attrs: {
                                options: {
                                  photo: _vm.lessonInfo.lesson.tutor.photo,
                                  size: "80px"
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col pl-2 pr-2" },
                          [
                            _c("div", { staticClass: "row mb-4" }, [
                              _c(
                                "div",
                                { staticClass: "col-lg-5 mb-2 mb-lg-0" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "col-12 v-lesson-tutor col-auto mb-2"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.lessonInfo.lesson.tutor.name
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.lessonInfo.lesson.tutor
                                                .last_name
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row ml-n2 mr-n2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-2 pr-2 pb-1 pt-1"
                                            },
                                            [
                                              _c("v-lesson-tag", {
                                                attrs: {
                                                  type:
                                                    _vm.lessonInfo.lesson.lesson
                                                      .lesson.type,
                                                  options: {
                                                    minWidth: "80px",
                                                    lessonLimit:
                                                      _vm.lessonInfo.lesson
                                                        .lesson.date.time
                                                        .places,
                                                    lessonQuota:
                                                      _vm.lessonInfo.lesson
                                                        .lesson.date.time
                                                        .occupied
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto pl-2 pr-2 pb-1 pt-1"
                                            },
                                            [
                                              _c("v-lesson-tag", {
                                                attrs: {
                                                  type:
                                                    _vm.lessonInfo.lesson.lesson
                                                      .lesson.modality,
                                                  options: { minWidth: "80px" }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-lg-7" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "v-lesson-subject mb-2 position-relative"
                                      },
                                      [
                                        _c("svgicon", {
                                          staticClass: "v-ls-icon mr-2",
                                          attrs: {
                                            name:
                                              _vm.lessonInfo.lesson.lesson
                                                .lesson.icon,
                                            color: "#000"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.lessonInfo.lesson.lesson
                                                .lesson.name
                                            ) +
                                            "\n                                                    "
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.lessonInfo.lesson.lesson.lesson.type ==
                                  "Individual"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "col-12 col-md pt-1 pb-1"
                                        },
                                        [
                                          _vm.lessonInfo.lesson.lesson.lesson
                                            .type == "Individual"
                                            ? _c("v-lesson-topic", {
                                                attrs: {
                                                  options: {
                                                    lessonId:
                                                      _vm.lessonInfo.lesson
                                                        .lesson.id,
                                                    topic:
                                                      _vm.lessonInfo.lesson
                                                        .lesson.topic
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "v-lesson-date mb-3 position-relative"
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "v-ls-icon mr-2",
                                  attrs: { name: "calendar", color: "#000" }
                                }),
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(`${_vm.setDate} ${_vm.setTime}`) +
                                    "\n                                    "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-lesson-place", {
                              staticClass: "mb-3",
                              attrs: {
                                options: {
                                  icon: _vm.lessonInfo.lesson.lesson.place.name
                                    ? _vm.lessonInfo.lesson.lesson.place.icon
                                        .icon_name
                                    : null,
                                  place: _vm.lessonInfo.lesson.lesson.place.name
                                    ? _vm.lessonInfo.lesson.lesson.place.name
                                    : null,
                                  modality:
                                    _vm.lessonInfo.lesson.lesson.lesson
                                      .modality,
                                  type: _vm.lessonInfo.lesson.lesson.lesson.type
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row ml-n1 mr-n1 mb-3" }, [
                              _c(
                                "div",
                                { staticClass: "col-auto pl-1 pr-1" },
                                [
                                  _c("v-lesson-btn", {
                                    staticClass: "w-100",
                                    attrs: {
                                      options: {
                                        type: "chat",
                                        btnType: "large",
                                        idLesson:
                                          _vm.lessonInfo.lesson.lesson.id
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-auto pl-1 pr-1" },
                                [
                                  _c("v-lesson-btn", {
                                    staticClass: "w-100",
                                    attrs: {
                                      options: {
                                        type: "cancel",
                                        btnType: "large",
                                        idLesson:
                                          _vm.lessonInfo.lesson.lesson.id
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn-lessons",
                          attrs: {
                            tag: "button",
                            to: { name: `lessons-${_vm.auth.permission}` }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("scheduleLesson.msgSuccess.btnPrev")
                            ) + " "
                          ),
                          _c("svgicon", {
                            staticClass: "bl-icon",
                            attrs: { name: "arrow-right" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.lessonInfo.status == "error") | (_vm.lessonInfo.status == "")
              ? _c("div", { staticClass: "lesson-failed w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lf-title d-flex flex-wrap justify-content-center pb-5 mb-5"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "icon-alert error mb-3",
                        attrs: { name: "shocked" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "status-msg w-100 text-center" },
                        [
                          _c("h3", { staticClass: "msg" }, [
                            _vm._v(_vm._s(_vm.$t("scheduleLesson.msgError.t")))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "descrip mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("scheduleLesson.msgError.descrip"))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap justify-content-center" },
                    [
                      _c("v-btn", {
                        staticClass: "w-100",
                        attrs: {
                          fontSize: "16px",
                          max: "280px",
                          txt: _vm.$t("scheduleLesson.msgError.btn")
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$router.push(
                              _vm.lessonInfo.route == ""
                                ? "/student/schedule-lesson"
                                : _vm.lessonInfo.route
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }