<template>
	<div class="confirm-modal" :class="{ active : show }" v-if="modalNotifications">
		<div class="blurColor"></div>
		<div class="v-content">
			<h4 class="title pt-4 pb-3 pl-5 pr-5 mb-0" :class="`bgColor-${auth.permission}-main`" v-html="$t('modals.notification.tHtml')"></h4>
			<div class="text-center pt-3 pb-4 pl-4 pr-4">
				<svgicon class="icon mb-4 mt-3" name="altavoz-ondas" color="#000"/>
			    <p class="alert-legend w-100 mb-4" v-html="$t('modals.notification.msgHtml')"></p>
			    <v-btn class="btn-accept mb-3 w-100" :txt="$t('modals.notification.btn')" btn="main" height="44px" @click.native="getPermissions(); close();"/>
			    <button class="btn-cancel" @click="close()">{{ $t('modals.notification.btn2') }}</button>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		mixins: [ modal ],
		data(){
			return{
				eventListener: false
			}
		},
        computed:{
        	...mapState(['modalNotifications', 'auth'])
        },
        methods:{
        	...mapMutations(['setModalNotifications']),
        	...mapActions(['getPermissions']),
			afterClose(){
				this.$localStorage.set('statusModalNotifications',1);
				this.setModalNotifications(false);
			}
        }
	}
</script>