<template>
    <div class="v-format position-relative">

        <div class="v-format-spinner" v-if="!imready">
            <v-spinner-logo size="120px" />
        </div>

        <div class="v-format-info">
            <h3 class="v-format-title text-center mb-sm-5 mb-4">{{ $t('formats.fm1.t') }}</h3>
            <p class="v-formt-txt mb-4">{{ $t('formats.fm1.d') }}</p>
        </div>
        
        <div class="mt-4">
            <div class="row h-100 mb-4">
                <div class="d-md-none d-block col-12 pb-3" :class="((statusMenu || !statusFormat) && grid == 'lg') || grid == 'xl'?'d-lg-none':'d-lg-block'">
                    <p class="vf-txt mb-0" v-if="statusFormat && createdDate"><span class="bold">{{ $t('formats.fm1.date') }}</span> {{ createdDate }}</p>
                </div>
                <div class="order-1 order-md-0 col-md-9" :class="statusMenu || !statusFormat?'col-lg-9':'col-xl-9'">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <div class="row">
                            <div class="d-none d-md-block col-12 mb-4 pb-2" :class="((statusMenu || !statusFormat) && grid == 'lg') || grid == 'xl'?'d-lg-block':'d-lg-none'">
                                <p class="vf-txt mb-0" v-if="statusFormat && createdDate"><span class="bold">{{ $t('formats.fm1.date') }}</span> {{ createdDate }}</p>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-3">
                                <v-select class="w-100" v-model="form.majorId" :label="$t('formats.fm1.form.career.label')" :defaultOpc="$t('formats.fm1.form.career.placeholder')" :opc="careers" :options="opcInput" fieldVal="id" fieldTxt="name" :required="true" :disabled="sending" :error="error && $v.form.majorId.$invalid" :onlyRead="statusFormat" />
                            </div>
                            <div class="col-md-4 col-sm-6 mb-3">
                                <v-select class="w-100" v-model="form.periodId" :label="$t('formats.fm1.form.period.label')" :defaultOpc="$t('formats.fm1.form.period.placeholder')" :opc="periods" :options="opcInput" fieldVal="id" fieldTxt="period" :required="true" :disabled="sending" :error="error && $v.form.periodId.$invalid" :onlyRead="statusFormat" />
                            </div>
                            <div class="col-md-4 col-sm-6 mb-3">
                                <v-textField class="w-100" v-model="form.studentId" :label="$t('formats.fm1.form.noControl')" :maxlength="$v.form.studentId.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.studentId.$invalid" :onlyRead="statusFormat" />
                            </div>
                        </div>
                        <h4 class="v-format-subTitle mb-0">{{ $t('formats.fm1.generalDatas') }}</h4>
                    </div>
                </div>
                <div class="order-0 order-md-1 col-md-3 mb-3 d-flex flex-wrap flex-column align-items-center" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3'">
                    <v-lesson-photo class="mb-3" :options="{ photo: userPhoto, size: '130px', lessonType: 'Individual' }" v-if="imready">
                        <slot>
                            <input id="input-file" type="file" ref="inputPhoto" accept="image/png,image/jpg,image/jpeg" @change="uploadPhoto" class="fileinput" v-if="!statusFormat">
                        </slot>
                    </v-lesson-photo>
                    <v-btn class="w-100" fontSize="12px" fontWeight="500" max="150px" :txt="$t('formats.fm1.form.btnPhoto')" :disabled="sending" @click.native="actionInputPhoto" v-if="!statusFormat"/>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <div class="v-border-top col-12 mb-4"></div>
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.lastNameF" :label="$t('formats.fm1.form.lastName')" :maxlength="$v.form.lastNameF.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.lastNameF.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.lastNameM" :label="$t('formats.fm1.form.motherLastName')" :maxlength="$v.form.lastNameM.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.lastNameM.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.name" :label="$t('formats.fm1.form.name')" :maxlength="$v.form.name.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.name.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-select class="w-100" v-model="form.gender" :label="$t('formats.fm1.form.gender.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.gender.placeholder')" :opc="gender" fieldVal="val" fieldTxt="txt" :required="true" :disabled="sending" :error="error && $v.form.gender.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.email" type="email" :label="$t('formats.fm1.form.email')" :maxlength="$v.form.email.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.email.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.landlinePhone" type="tel" :label="$t('formats.fm1.form.addressPhone')" :maxlength="$v.form.landlinePhone.$params.maxLength.max" :options="opcInput" :disabled="sending" :error="error && $v.form.landlinePhone.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.mobilePhone1" type="tel" :label="$t('formats.fm1.form.phone1')" :maxlength="$v.form.mobilePhone1.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.mobilePhone1.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.mobilePhone2" type="tel" :label="$t('formats.fm1.form.phone2')" :maxlength="$v.form.mobilePhone2.$params.maxLength.max" :options="opcInput"  :disabled="sending" :error="error && $v.form.mobilePhone2.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-2 align-items-end">
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <label class="vf-label mb-1" :style="{ opacity: sending?'0.6':null }">{{ $t('formats.fm1.form.birthday') }}</label>
                    <div class="row ml-n1 mr-n1">
                        <div class="col-4 pl-1 pr-1">
                            <v-select class="w-100" :value="birthday.day" @input.native=" e => { birthday = { ...birthday, day: parseInt(e.target.value) } }" :opc="days" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :onlyRead="statusFormat" />
                        </div>
                        <div class="col-4 pl-1 pr-1">
                            <v-select class="w-100" :value="birthday.month" @input.native=" e => { birthday = { ...birthday, month: parseInt(e.target.value) } }" :opc="months" fieldVal="id" fieldTxt="val" :required="true" :disabled="sending" :onlyRead="statusFormat" />
                        </div>
                        <div class="col-4 pl-1 pr-1">
                            <v-select class="w-100" :value="birthday.year" @input.native=" e => { birthday = { ...birthday, year: parseInt(e.target.value) } }" :opc="years" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :onlyRead="statusFormat" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="age" :disabled="true" :label="$t('formats.fm1.form.age')" :options="opcInput" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.birthplace" :label="$t('formats.fm1.form.placeOfBirth')" :maxlength="$v.form.birthplace.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.birthplace.$invalid" :onlyRead="statusFormat"/>
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-select class="w-100" v-model="form.maritalStatus" :label="$t('formats.fm1.form.civilStatus.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.civilStatus.placeholder')" :opc="civilStatus" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :error="error && $v.form.maritalStatus.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.homeAddress" :label="$t('formats.fm1.form.address')" :maxlength="$v.form.homeAddress.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.homeAddress.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-select class="w-100" v-model="form.educationLevel" :label="$t('formats.fm1.form.scholarship.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.scholarship.placeholder')" :opc="scholarship" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :error="error && $v.form.educationLevel.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.schoolName" :label="$t('formats.fm1.form.schoolName')" :maxlength="$v.form.schoolName.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.schoolName.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-3 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                    <v-textField class="w-100" v-model="form.schoolScore" :label="$t('formats.fm1.form.average')" :maxlength="$v.form.schoolScore.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.schoolScore.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row align-items-end mb-2">
                <div class="col-md-4 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-radio class="w-100" v-model="form.scholarshipStatus" :label="$t('formats.fm1.form.onScholarship')" fieldVal="val" fieldTxt="txt" :opc="yesNo" :disabled="sending" :error="error && $v.form.scholarshipStatus.$invalid" :onlyRead="statusFormat"/> 
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-select class="w-100" v-model="scholar.scholarshipType" :label="$t('formats.fm1.form.typeOfScholarship.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.typeOfScholarship.placeholder')" :opc="typeOfScholarship" fieldVal="val" fieldTxt="val" :disabled="(!form.scholarshipStatus || form.scholarshipStatus == '') || sending" :required="true" :error="error && form.scholarshipStatus == true && scholar.scholarshipType == ''" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-textField class="w-100" v-model="scholar.scholarshipInstitution" :label="$t('formats.fm1.form.institutionName')" :maxlength="$v.scholar.scholarshipInstitution.$params.maxLength.max" :options="opcInput" :disabled="(!form.scholarshipStatus || form.scholarshipStatus == '') || sending" :required="true" :error="error && form.scholarshipStatus == true && scholar.scholarshipInstitution == ''" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row align-items-end mb-2">
                <div class="col-md-4 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-radio class="w-100" v-model="form.workStatus" :label="$t('formats.fm1.form.job')" fieldVal="val" fieldTxt="txt" :opc="yesNo" :disabled="sending" :error="error && $v.form.workStatus.$invalid" :onlyRead="statusFormat" /> 
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-textField class="w-100" v-model="work.workCompany" :label="$t('formats.fm1.form.institutionJob')" :maxlength="$v.work.workCompany.$params.maxLength.max" :options="opcInput" :disabled="(!form.workStatus || form.workStatus == '') || sending" :required="true" :error="error && form.workStatus == true && work.workCompany == ''" :onlyRead="statusFormat" key=""/>
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-4'">
                   <v-textField class="w-100" v-model="work.workSchedule" :label="$t('formats.fm1.form.jobSchedule')" :maxlength="$v.work.workSchedule.$params.maxLength.max" :options="opcInput" :disabled="(!form.workStatus || form.workStatus == '') || sending" :required="true" :error="error && form.workStatus == true && work.workSchedule == ''" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-4 mb-3" :class="statusMenu || !statusFormat?'col-lg-6':'col-xl-6 col-lg-12'">
                   <v-select class="w-100" v-model="form.livingWith" :label="$t('formats.fm1.form.liveWith.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.liveWith.placeholder')" :opc="liveWith" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :error="error && $v.form.livingWith.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                   <v-textField class="w-100" v-model="form.emergencyContact" :label="$t('formats.fm1.form.supportPerson')" :maxlength="$v.form.emergencyContact.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.emergencyContact.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-4 col-sm-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-3':'col-xl-3 col-lg-6'">
                   <v-textField class="w-100" v-model="form.emergencyPhone" :label="$t('formats.fm1.form.supportPhone')" :maxlength="$v.form.emergencyPhone.$params.maxLength.max" :options="opcInput" :required="true" :disabled="sending" :error="error && $v.form.emergencyPhone.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-6 mb-3" :class="statusMenu || !statusFormat?'col-lg-5':'col-xl-5 col-lg-6'">
                    <v-select class="w-100" v-model="form.fatherEduLevel" :label="$t('formats.fm1.form.dadScholarship.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.dadScholarship.placeholder')" :opc="parentScholarship" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :error="error && $v.form.fatherEduLevel.$invalid" :onlyRead="statusFormat" />
                </div>
                <div class="col-md-6 offset-xl-1 mb-3" :class="statusMenu || !statusFormat?'col-lg-5 offset-lg-1':'col-xl-5 offset-xl-1 col-lg-6'">
                    <v-select class="w-100" v-model="form.motherEduLevel" :label="$t('formats.fm1.form.momScholarship.label')" :options="opcInput" :defaultOpc="$t('formats.fm1.form.momScholarship.placeholder')" :opc="parentScholarship" fieldVal="val" fieldTxt="val" :required="true" :disabled="sending" :error="error && $v.form.motherEduLevel.$invalid" :onlyRead="statusFormat" />
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-6" :class="statusMenu || !statusFormat?'col-lg-5':'col-xl-5 col-lg-12'">
                    <div class="row">
                        <div class="col-md-12 mb-3" :class="statusMenu || !statusFormat?'col-lg-12':'col-xl-12 col-lg-6'">
                            <v-radio class="w-100" v-model="form.fatherDeceased" :label="$t('formats.fm1.form.dadStatus')" fieldVal="val" fieldTxt="txt" :opc="dadStatusOpc" :disabled="sending" :error="error && $v.form.fatherDeceased.$invalid" :onlyRead="statusFormat" /> 
                        </div>
                        <div class="col-md-12 mb-3" :class="statusMenu || !statusFormat?'col-lg-12':'col-xl-10 col-lg-6'">
                            <v-textField class="w-100" v-model="form.fatherWorkplace" :label="$t('formats.fm1.form.addressJobDad')" :maxlength="$v.form.fatherWorkplace.$params.maxLength.max" :options="opcInput" :required="form.fatherDeceased === false" :disabled="(form.fatherDeceased === true || form.fatherDeceased === '') || sending" :error="error && form.fatherDeceased === false && form.fatherWorkplace == ''" :onlyRead="statusFormat"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" :class="statusMenu || !statusFormat?'col-lg-5 offset-lg-1':'col-xl-5 offset-xl-1 col-lg-12'">
                    <div class="row">
                        <div class="col-md-12 mb-3" :class="statusMenu || !statusFormat?'col-lg-12':'col-xl-12 col-lg-6'">
                            <v-radio class="w-100" v-model="form.motherDeceased" :label="$t('formats.fm1.form.momStatus')" fieldVal="val" fieldTxt="txt" :opc="monStatusOpc" :disabled="sending" :error="error && $v.form.motherDeceased.$invalid" :onlyRead="statusFormat" /> 
                        </div>
                        <div class="col-md-12 mb-3" :class="statusMenu || !statusFormat?'col-lg-12':'col-xl-10 col-lg-6'">
                            <v-textField class="w-100" v-model="form.motherWorkplace" :label="$t('formats.fm1.form.addressJobMom')" :maxlength="$v.form.motherWorkplace.$params.maxLength.max" :options="opcInput" :required="form.motherDeceased === false" :disabled="(form.motherDeceased === true || form.motherDeceased === '') || sending" :error="error && form.motherDeceased === false && form.motherWorkplace == ''" :onlyRead="statusFormat"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center" v-if="!statusFormat">
                <v-btn class="w-100" :txt="$t('formats.fm1.form.btn')" height="40px" max="450px" fontSize="1rem" fontWeight="500" :spinner="sending" @click.native="submit" />
            </div>  
        </div>

        <v-crop-image :bgIMG="imgFile" v-model="imgCrob" :show="statusCropImage" :statusBtn="statusBtn" />

        <v-identification-pdf ref="pdf" :data="pdfData" v-if="imready && statusFormat && pdfData.createdDate" />
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex';
    import { required, email, maxLength } from 'vuelidate/lib/validators';

    export default{
        props: {
            studentFormats: { default: null, required: false }
        },
        data(){
            return{
                imready: false,
                sending: false,
                createdDate: null,
                userPhoto: '',
                error: false,
                form: {
                    majorId: '',
                    periodId: '',
                    studentId: '',
                    name: '',
                    lastNameF: '',
                    lastNameM: '',
                    gender: '',
                    email: '',
                    landlinePhone: '',
                    mobilePhone1: '',
                    mobilePhone2: '',
                    birthday: '',
                    birthplace: '',
                    maritalStatus: '',
                    homeAddress: '',
                    educationLevel: '',
                    schoolName: '',
                    schoolScore: '',
                    scholarshipStatus: '',
                    workStatus: '',
                    livingWith: '',
                    emergencyContact: '',
                    emergencyPhone: '',
                    fatherEduLevel: '',
                    motherEduLevel: '',
                    fatherDeceased: '',
                    motherDeceased: '',
                    fatherWorkplace: '',
                    motherWorkplace: ''
                },
                pdfData: {},
                scholar: {
                    scholarshipType: '',
                    scholarshipInstitution: '',
                },
                work: {
                    workCompany: '',
                    workSchedule: '',
                },
                opcInput: {
			        activeLabel: true,
			        styles: {
			            label: {
					        color: '#4A4A4A',
					        fontWeight: 500,
					        marginBottom: '5px',
					        '--transform': 'initial',
					        '--transform-active': 'initial',
                            '--focus-color': 'rgb(74, 74, 74)'
				        }
			        }
		        },
                careers: [],
                periods: [],
                imgFile: '',
                imgCrob: '',
                statusCropImage: false,
                statusBtn: false,
                birthday: {
                    day: 1,
                    month: 1,
                    year: 1900,
                },
                years: [],
                days: [],
                age: null
            }
        },
        computed:{
            ...mapState({ currentUserPhoto: state => state.auth.user.photo,
                          api: state => state.auth.api,
                          token: state => state.auth.token,
                          statusIdentity: state => state.auth.formats.identity,
                          lang: state => state.i18n.locale,
                          statusMenu: state => state.menu.compact,
                          grid: state => state.grid }),
            statusFormat(){
                const st = this.studentFormats?.forms;

                return st?st.identity:this.statusIdentity;
            },
            gender(){
                return [
                    { val: 'F', txt: this.$t('gender.m') },
                    { val: 'M', txt: this.$t('gender.f') }/*,
                    { val: 'O', txt: this.$t('gender.o') }*/
                ]
            },
            months(){            
                return [
                    { id: 1, val: this.$t('months.jan') },
                    { id: 2, val: this.$t('months.feb') },
                    { id: 3, val: this.$t('months.mar') },
                    { id: 4, val: this.$t('months.apr') },
                    { id: 5, val: this.$t('months.may') },
                    { id: 6, val: this.$t('months.jun') },
                    { id: 7, val: this.$t('months.jul') },
                    { id: 8, val: this.$t('months.aug') },
                    { id: 9, val: this.$t('months.sep') },
                    { id: 10, val: this.$t('months.oct') },
                    { id: 11, val: this.$t('months.nov') },
                    { id: 12, val: this.$t('months.dic') }
                ];
            },
            civilStatus(){
                return [
                    { val: this.$t('civilStatus.s') },
                    { val: this.$t('civilStatus.csh') },
                    { val: this.$t('civilStatus.cch') },
                    { val: this.$t('civilStatus.o') },
                ]
            },
            scholarship(){
                return [
                    { val: this.$t('scholarship.opc3') },
                    { val: this.$t('scholarship.opc4') }
                ]
            },
            yesNo(){
                return [
                    { val: true, txt: this.$t('yesNo.y') },
                    { val: false, txt: this.$t('yesNo.n') }
                ]
            },
            typeOfScholarship(){
                return [
                    { val: this.$t('typeOfScholarship.opc1') },
                    { val: this.$t('typeOfScholarship.opc2') },
                    { val: this.$t('typeOfScholarship.opc3') },
                    { val: this.$t('typeOfScholarship.opc4') }
                ]
            },
            liveWith(){
                return [
                    { val: this.$t('liveWith.opc1') },
                    { val: this.$t('liveWith.opc2') },
                    { val: this.$t('liveWith.opc3') },
                    { val: this.$t('liveWith.opc4') }
                ]
            },
            parentScholarship(){
                return [
                    { val: this.$t('scholarship.opc1') },
                    { val: this.$t('scholarship.opc2') },
                    { val: this.$t('scholarship.opc3') },
                    { val: this.$t('scholarship.opc4') },
                    { val: this.$t('scholarship.opc5') },
                    { val: this.$t('scholarship.opc6') },
                    { val: this.$t('scholarship.opc7') }
                ]
            },
            dadStatusOpc(){
                return [
                    { val: false, txt: this.$t('parentStatus.opc1') },
                    { val: true, txt: this.$t('parentStatus.opc2') }
                ]
            },
            monStatusOpc(){
                return [
                    { val: false, txt: this.$t('parentStatus.opc1') },
                    { val: true, txt: this.$t('parentStatus.opc3') }
                ]
            }
        },
        created(){
            this.getYears();
            this.getDays();
            this.getAge();
            this.getFormat();
        },
        methods:{
            ...mapActions(['axiosRequest', 'changeUserPhoto']),
            ...mapMutations(['handleFormat', 'setNotification']),
            getFormat(){
                const params = this.studentFormats?.studentId?{ params: { studentId: this.studentFormats?.studentId } }:{};

                this.axiosRequest({ config: {
                    method: 'Get',
                    url: `${this.api}/tecnm/forms/identity`,
                    ...params,
                    headers: { Authorization: this.token }
                } }).then( resp => resp.data )
                    .then( data => {
                    const d = data.user.birthday.split('-');

                    this.careers = data.majors;
                    this.periods = data.schoolPeriods;

                    const formatDate = this.$moment(data.created).locale(this.lang).format(this.$t('formatDateC'));
                    this.createdDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);

                    this.birthday = { day: parseInt(d[2]), month: parseInt(d[1]), year: parseInt(d[0]) };
                    this.form.birthday = `${parseInt(d[0])}-${parseInt(d[1])<=9?`0${parseInt(d[1])}`:parseInt(d[1])}-${parseInt(d[1])<=9?`0${parseInt(d[2])}`:parseInt(d[2])}`;

                    this.form.majorId = data.user.majorId;
                    this.form.periodId = data.user.periodId;
                    this.form.name = data.user.name;
                    this.form.gender = data.user.gender;
                    this.userPhoto = data.user?.photo?data.user?.photo:this.currentUserPhoto;

                    if(data.created == ''){
                        this.form.email = data.user.email;
                        this.form.lastNameF = data.user.lastName;
                        this.form.mobilePhone1 = data.user.phone;
                    }else{
                        this.form.studentId = data.idForm.studentId;
                        this.form.lastNameF = data.idForm.lastNameF;
                        this.form.lastNameM = data.idForm.lastNameM;
                        this.form.email = data.idForm.email;
                        this.form.landlinePhone = data.idForm.landlinePhone;
                        this.form.mobilePhone1 = data.idForm.mobilePhone1;
                        this.form.mobilePhone2 = data.idForm.mobilePhone2;
                        this.form.birthday = data.idForm.birthday;
                        this.form.birthplace = data.idForm.birthplace;
                        this.form.maritalStatus = data.idForm.maritalStatus;
                        this.form.homeAddress = data.idForm.homeAddress;
                        this.form.educationLevel = data.idForm.educationLevel;
                        this.form.schoolName = data.idForm.schoolName;
                        this.form.schoolScore = data.idForm.schoolScore;
                        this.form.scholarshipStatus = !!data.idForm.scholarshipStatus;
                        this.form.workStatus = !!data.idForm.workStatus;
                        this.form.livingWith = data.idForm.livingWith;
                        this.form.emergencyContact = data.idForm.emergencyContact;
                        this.form.emergencyPhone = data.idForm.emergencyPhone;
                        this.form.fatherEduLevel = data.idForm.fatherEduLevel;
                        this.form.motherEduLevel = data.idForm.motherEduLevel;
                        this.form.fatherDeceased = !!data.idForm.fatherDeceased;
                        this.form.motherDeceased = !!data.idForm.motherDeceased;
                        this.form.fatherWorkplace = data.idForm.fatherWorkplace;
                        this.form.motherWorkplace = data.idForm.motherWorkplace;

                        this.scholar = {
                            scholarshipType: data.idForm.scholarshipType,
                            scholarshipInstitution: data.idForm.scholarshipInstitution,
                        }
                        this.work = {
                           workCompany: data.idForm.workCompany,
                           workSchedule: data.idForm.workSchedule,
                        }
                        
                        this.pdfData = { ...this.form, 
                                         ...this.scholar,
                                         ...this.work,
                                         createdDate: this.createdDate,
                                         major: this.careers.find( i => i.id == data.user.majorId ).name,
                                         period: this.periods.find( i => i.id == data.user.periodId ).period,
                                         gender: this.gender.find( i => i.val == data.user.gender ).txt,
                                         birthday: {
                                            day: this.birthday.day,
                                            month: this.months.find( i => i.id == this.birthday.month ).val,
                                            year: this.birthday.year
                                         },
                                         age: this.age,
                                         userPhoto: data.user.photo };
                    }

                    this.imready = true;
                }).catch( err => {
                    this.imready = true;
                    console.log(err);
                } )
            },
            getYears(){
                let years = [];
                const currentYear = parseInt(this.$moment().format('YYYY'));
                this.birthday.year = currentYear - 20;

                for(let i = currentYear; i >= (currentYear - 120); i--)
                    years = [ ...years, { val : i} ];
            
                this.years = years;
            },
            getDays(){
                let days = [];
                const daysInMonth = this.$moment(this.birthday.month, 'M').daysInMonth();

                for(let i = 1; i <= daysInMonth; i++)
                    days = [ ...days, { val : i} ];
            
                if(this.birthday.day > daysInMonth)
                    this.birthday.day = daysInMonth;

                this.days = days;
            },
            getAge(){
                const { day, month, year } = this.birthday;
                const birthday = this.$moment(`${year}-${month}-${day}`, 'YYYY-M-D'),
                      currentDate = this.$moment(),
                      diff = currentDate.diff(birthday, 'years');
            
                this.age = diff;
            },
            actionInputPhoto(){
                this.$refs.inputPhoto.click();
            },
            uploadPhoto(e){
                this.imgFile = e.target.files[0];
                this.statusCropImage = true;
            },
            changeIMG(){
			    this.statusBtn = true;
			    var that = this;
                this.imgCrob.toBlob(function(blob) {
				    var formData = new FormData();

				    formData.append('picture', blob, `filename.jpeg`);
				    that.changeUserPhoto(formData).then( resp => {
				        that.statusBtn = false;
				        that.statusCropImage = false;
				        that.imgFile = '';
				        that.imgCrob = '';
                        that.$refs.inputPhoto.value = null;
                        that.userPhoto = that.currentUserPhoto;
				    }).catch( err => {
				        that.statusBtn = false;
				    });
                }, 'image/jpeg', 0.9);
		    },
            submit(){
                const scholar = this.form.scholarshipStatus == true?this.scholar:{ scholarshipType: '', scholarshipInstitution: '' },
                      work = this.form.workStatus == true?this.work:{ workCompany: '', workSchedule: '' };
                
                if(this.$v.form.$invalid || (!this.$v.form.$invalid && ((this.form.scholarshipStatus == true && (scholar.scholarshipType == '' || scholar.scholarshipInstitution == '')) 
                                                                    || (this.form.workStatus == true && (work.workCompany == '' || work.workSchedule == ''))
                                                                    || (this.form.fatherDeceased == false && this.form.fatherWorkplace == '')
                                                                    || (this.form.motherDeceased == false && this.form.motherWorkplace == '')))){
                    this.error = true;
                    this.setNotification({ type: 'warning', msg: 'Es necesario llenar todos los campos indicados en rojo' });
                    return;
                }

                if(!this.sending){
                    this.sending = true;
                    
                    const form = { ...this.form, ...scholar, ...work };
                    
                    this.axiosRequest({ config: {
                        method: 'POST',
                        url: `${this.api}/tecnm/forms/identity`,
                        headers: { Authorization: this.token },
                        data: form
                    }}).then( resp => resp.data )
                       .then( data => {
                        this.handleFormat({ format: 'identity', status: 1 });
                        this.sending = false;
                        this.setNotification({ type: 'success', msg: data.msg });
                        this.$emit('openMenu');
                    }).catch( err => {
                        this.sending = false;
                    } )
                }
            },
            print(){
                this.$refs.pdf.print();
            }
        },
        watch:{
            imgCrob: function(newVal){
                if (newVal !== '' && newVal !== false)
                    this.changeIMG();
                else if(newVal === false){
                    this.statusCropImage = false;
                    this.imgFile = '';
            	    this.imgCrob = '';
            	    this.$refs.inputPhoto.value = null;
			    }
            },
            birthday: function(newVal, oldVal){
                if(newVal.month != oldVal.month)
                   this.getDays();   
            
                this.form.birthday = `${this.birthday.year}-${this.birthday.month<=9?`0${this.birthday.month}`:this.birthday.month}-${this.birthday.day<=9?`0${this.birthday.day}`:this.birthday.day}`;
                this.getAge();
            }
        },
        validations: {
            form: {
            	majorId: {
            		required
            	},
                periodId: {
                    required
                },
                studentId: {
                    required,
                    maxLength: maxLength(30)
                },
                name: {
                    required,
                    maxLength: maxLength(50)
                },
                lastNameF: {
                    required,
                    maxLength: maxLength(24)
                },
                lastNameM: {
                    required,
                    maxLength: maxLength(24)
                },
                gender: {
                    required
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(255)
                },
                landlinePhone: {
                    maxLength: maxLength(15)
                },
                mobilePhone1: {
                    required,
                    maxLength: maxLength(15)
                },
                mobilePhone2: {
                    maxLength: maxLength(15)
                },
                birthday: {
                    required
                },
                birthplace: {
                    required,
                    maxLength: maxLength(35)
                },
                maritalStatus: {
                    required
                },
                homeAddress: {
                    required,
                    maxLength: maxLength(50)
                },
                educationLevel: {
                    required
                },
                schoolName: {
                    required,
                    maxLength: maxLength(35)
                },
                schoolScore: {
                    required,
                    maxLength: maxLength(5)
                },
                scholarshipStatus: {
                    required
                },
                workStatus: {
                    required
                },
                livingWith: {
                    required,
                    maxLength: maxLength(25)
                },
                emergencyContact: {
                    required,
                    maxLength: maxLength(50)
                },
                emergencyPhone: {
                    required,
                    maxLength: maxLength(15)
                },
                fatherEduLevel: {
                    required
                },
                motherEduLevel: {
                    required
                },
                fatherDeceased: {
                    required
                },
                motherDeceased: {
                    required
                },
                fatherWorkplace: {
                    maxLength: maxLength(35)
                },
                motherWorkplace: {
                    maxLength: maxLength(35)
                }
            },
            scholar: {
                scholarshipInstitution: {
                    maxLength: maxLength(35)
                }
            },
            work: {
                workCompany: {
                    maxLength: maxLength(35)
                },
                workSchedule: {
                    maxLength: maxLength(20)
                },
            }
        }
    }
</script>