import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router.js';
import store from '@/store';
import Library from '@/libs.js';
import components from '@/components/components.js';
import { initSentry } from '@/sentry.js';

// Global libs
Vue.use(Library);

// Global components
Vue.use(components);

initSentry();

// Disabled warnings
Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
