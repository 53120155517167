/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit-down': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.5 0h-12C2.673 0 2 .673 2 1.5v10c0 .827.673 1.5 1.5 1.5h4a.5.5 0 000-1h-4a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-4a.5.5 0 000 1h4c.827 0 1.5-.673 1.5-1.5v-10c0-.827-.673-1.5-1.5-1.5z"/><path pid="1" d="M12.853 16.354l-3 3a.5.5 0 01-.707 0l-3-3a.5.5 0 01.707-.707L9 17.793V6.5a.5.5 0 011 0v11.293l2.147-2.146a.497.497 0 01.706 0 .5.5 0 010 .707z"/>'
  }
})
