<template>
	<div id="chat" ref="content">
		<div class="row ml-n2 mr-n2 justify-content-between">
			<!-- ============== Btn prev ================= -->
			<div class="col-12 pl-2 pr-2" :style="{ '--hover-color': auth.permission == 'student'?'#2188FC':'#005E58' }">
                <button class="vn-btn-prev d-flex align-items-center mb-3" ref="btnBack" @click="step = 'chats'; $router.push({ name: `chat-${auth.permission}` })" v-if="statusBtnPrev">
	    		    <svgicon class="vn-btn-icon mr-2" name="arrow-left" />
	    		    <span class="vn-btn-txt">{{ $t('notifications.btnBack') }}</span>
	    	    </button>
			</div>
            
			<!-- ============== List chats ================= -->
			<div class="col-xl-3 col-12 order-md-0 contacts pl-2 pr-2" :class="{ 'col-lg-4': menu.compact }" :style="{ height: listChats }" v-if="statusChats && ((statusLesson !== 'Finalizada' && statusLesson !== 'Cancelada') || !$route.params.idlesson)">
				<v-list-chats class="h-100" :chats="lessons" />
			</div>

			<div class="chat col-lg order-xl-1 order-md-2 pl-2 pr-2 " v-if="statusDetail">
				<!-- ============== Lesson info top ================= -->
				<v-chat-lesson2 class="mb-2" ref="lessonInfo" :key="`info-top-${currentLessonChat.id}`" :lesson="currentLessonChat" v-if="statusLessonInfoTop"/>
				<!-- ============== Chat ================= -->
				<v-chat :idLesson="$route.params.idlesson || null" :disabled="typeof $route.params.idlesson === 'undefined' || statusLesson === 'Finalizada' || statusLesson === 'Cancelada'" :key="userFirebase?$route.params.idlesson:''" :users="chatUsers" :style="{ height: chatH, maxHeight: statusH?'700px':null }"  />
			</div>
			<!-- ============== Lesson info right ================= -->
			<div class="pending-request col-xl-4 order-xl-2 order-md-1 mb-3 pl-2 pr-2 d-none d-xl-block" v-if="statusLessonInfo">
				<v-chat-lesson :key="`info-right-${currentLessonChat.id}`" :lesson="currentLessonChat" :users="chatUsers" />
			</div>
		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	export default{
		data(){
			return{
				step: 'chats', // chats, detail
				statusLesson: '',
				listChats: 'auto',
				chatH: 'calc(100vh - 60px - 112px) !important',
				statusH: true,
				lessons: [],
				currentLessonChat : null,
				chatUsers: []
			}
		},
		computed:{
			...mapGetters(['contChat','events']),
            ...mapState(['auth', 'menu', 'lessonChanges', 'userFirebase', 'grid']),
			statusChats(){
                if(this.grid == 'xl')
				    return true;
				else if(this.grid == 'lg' && (this.menu.compact || this.step == 'chats'))
				    return true;
				else if((this.grid == 'xs' || this.grid == 'sm' || this.grid == 'md') && this.step == 'chats')
				    return true;
				else
				    return false;
			},
			statusBtnPrev(){
                return (((this.grid == 'xs' || this.grid == 'sm' || this.grid == 'md') && this.step == 'detail') || (this.grid == 'lg' && this.step == 'detail' && !this.menu.compact)) && this.statusLesson !== 'Finalizada' && this.statusLesson !== 'Cancelada';
			},
			statusDetail(){
                if(this.grid == 'xl')
				    return true;
				else if(this.grid == 'lg' && (this.menu.compact || this.step == 'detail'))
				    return true;
				else if((this.grid == 'xs' || this.grid == 'sm' || this.grid == 'md') && this.step == 'detail')
				    return true;
				else
				    return false;
			},
			statusLessonInfo(){
				return (this.grid == 'xl' && this.$route.params.idlesson && this.currentLessonChat && (this.menu.compact || this.menu.showMenuResponsive || this.statusLesson === 'Finalizada' || this.statusLesson === 'Cancelada' ))
			},
			statusLessonInfoTop(){
				return (!this.statusLessonInfo && !!this.currentLessonChat);
			}
		},
		created(){
			this.setCompact(true);
			if (this.contChat > 0)
				this.getLessons();
			if (this.$route.params.idlesson)
				this.getLesson();
		},
		mounted(){
			this.$nextTick( () => {
                this.getChatHeight();
			} );
			window.addEventListener('resize', this.getChatHeight);
            this.$watch('events', val => {
            	const lessonLS = JSON.parse(this.$localStorage.get('lesson'));
            	if (val && lessonLS.lesson && this.statusLesson !== 'Finalizada'){
            		const lesson = val.split('_');
            		if (lesson[1] === 'canceled' || lesson[1] === 'pendingFeedback' || lesson[1] === 'completed'){
                        this.getLessons();
                		if (lesson[0] === this.$route.params.idlesson+'')
                		    this.$router.push(`/${this.auth.permission}/messaging`);
            		}else if ((lesson[1] !== 'requestRejected' || lesson[1] === 'userGroupJoined' || lesson[1] === 'userGroupLeft' || lesson[1] === 'changedPlace') && this.$route.params.idlesson){
            			this.getLesson();
            		}

            		let status = undefined;
                	if (lesson[1] == 'upcoming')
            			status = 'Confirmada';
            		else if(lesson[1] == 'ongoing')
            			status = 'Activa';
                    
                	if (status) {
                        const lessonIndex = this.lessons.findIndex( item => item.id+'' === lesson[0] );
                        if (lessonIndex != -1)
                            this.lessons[lessonIndex].status = status;
                	}
            	}
            });
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.getChatHeight);
		},
		methods: {
			...mapMutations(['setCompact']),
			...mapActions(['axiosRequest']),
			getLessons(){
				this.axiosRequest({ config : {
					method: 'GET',
					url: `${this.auth.api}/user/lessons/t`,
					headers: { Authorization : this.auth.token }
				}}).then( resp => resp.data )
				.then( data => {
                    this.lessons = data;
				} ).catch( e => {

				} )
			},
			getLesson(){
				this.$Progress.start();
				this.axiosRequest({ config : {
					method: 'GET',
					url: `${this.auth.api}/lesson/${this.$route.params.idlesson}/info`,
					headers: { Authorization : this.auth.token }
				}}).then( resp => resp.data )
				.then( data => {
					this.$Progress.finish();
					this.currentLessonChat = null;
				    this.currentLessonChat = data.lesson[0];
					this.step = 'detail';
					this.statusLesson = data.lesson[0].status;
					this.chatUsers = data.users;
				} ).catch( e => {
                    this.$Progress.fail();
				} )
			},
			getChatHeight(){
				try{
					const windowH = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;
				    // Getting header height
				    const headerH = this.$parent.$refs.header.$el.clientHeight;
                    // Getting content paddings
			        const contentE = this.$refs.content;
				    const contentPT = window.getComputedStyle(contentE, null).getPropertyValue('padding-top').replace('px','');
				    const contentPb = window.getComputedStyle(contentE, null).getPropertyValue('padding-bottom').replace('px','');
				    let contentP = parseInt(contentPT) + parseInt(contentPb);
                    // Getting height btn prev
				    let btnPrevH = 0;
				    if(this.statusBtnPrev){
					    const btnE = this.$refs.btnBack;
			            let btnMB = btnE.currentStyle || window.getComputedStyle(btnE);
				            btnMB = btnMB.marginBottom;
				            btnMB = parseInt(btnMB.replace('px',''));
				        btnPrevH = btnE.clientHeight + btnMB;
				    }
				    // Getting lesson info height
				    let lessonH = 0;
				    if(this.statusLessonInfoTop){
					    const lessonE = this.$refs.lessonInfo.$el;
			            let lessonMB = lessonE.currentStyle || window.getComputedStyle(lessonE);
				            lessonMB = lessonMB.marginBottom;
				            lessonMB = parseInt(lessonMB.replace('px',''));
				        lessonH = lessonE.clientHeight + lessonMB;
				    }
                    
					this.listChats = `${windowH - (btnPrevH + headerH + contentP)}px !important`;
					this.chatH = `calc(100vh - ${(btnPrevH + lessonH + headerH + contentP)}px) !important`;
					this.statusH = false; 
				}catch(e){
					this.statusH = true; 
					this.listChats = '700px';
					this.chatH = 'calc(100vh - 60px - 112px) !important';
				}
			}
		},
		watch: {
			contChat: function(newVal, oldVal){
				if (newVal !== oldVal)
				    this.getLessons();
			},
			'$route.path': function(){
				this.chatUsers = [];
                if (this.$route.params.idlesson)
                	this.getLesson();
                else{
					this.step = 'chats';
                	this.statusLesson = '';
					this.currentLessonChat = null;
                }
			},
            lessonChanges: function(newVal){
                if (newVal) {
                    const { data, lesson } = newVal;
                    this.currentLessonChat[data] = lesson.val;
                }
            },
			statusLessonInfoTop: function(newVal){
                if(newVal)
				    this.$nextTick( () => {
                        this.getChatHeight();
					} )
			}
		}
	}
</script>