var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customize-subject" },
    [
      _c("div", { staticClass: "row align-items-center mb-3" }, [
        _c("div", { staticClass: "col-md-4 mb-3 mb-md-0" }, [
          _c(
            "div",
            { staticClass: "cs-info" },
            [
              _c("div", {
                staticClass: "color mr-2",
                style: { background: _vm.subject.color }
              }),
              _vm._v(" "),
              _c("v-hover-txt", {
                attrs: {
                  fontSize: "22px",
                  fontWeight: "500",
                  txt: _vm.subject.name
                }
              }),
              _vm._v(" "),
              _c("v-hover-txt", {
                attrs: {
                  fontSize: "16px",
                  txt: _vm.subject.category,
                  colorTxt: "#9b9b9b"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm text-sm-center mb-3 mb-sm-0" }, [
          _c("p", { staticClass: "subject-info mb-0" }, [
            _vm._v(
              _vm._s(_vm.$t("availability.customize.filters.duration")) + ": "
            ),
            _c("span", { staticClass: "txt-bold" }, [
              _vm._v(_vm._s(_vm.formatTime(_vm.subject.duration_time)))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm text-sm-center mb-3 mb-sm-0 d-flex" },
          [
            _c("span", { staticClass: "subject-info mr-1" }, [
              _vm._v(_vm._s(_vm.$t("availability.customize.type")) + ":")
            ]),
            _vm._v(" "),
            _c("v-lesson-tag", { attrs: { type: _vm.subject.type } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm text-sm-center mb-3 mb-sm-0 d-flex" },
          [
            _c("span", { staticClass: "subject-info mr-1" }, [
              _vm._v(_vm._s(_vm.$t("availability.customize.mode")) + ":")
            ]),
            _vm._v(" "),
            _c("v-lesson-tag", { attrs: { type: _vm.subject.modality } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "specific-scheduling d-flex flex-wrap justify-content-end justify-content-sm-between pl-4 pr-4 pt-3 pb-3 mb-2"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm pl-0 pr-0 d-flex align-items-center mb-2 mb-sm-0"
                },
                [
                  _c("h4", { staticClass: "title mb-0 mr-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("availability.customize.specificScheduling.t")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "switch",
                    class: { active: _vm.statusScheduling },
                    on: {
                      click: function($event) {
                        return _vm.actionSwitch("scheduling")
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "bound" } }, [
                _vm.statusScheduling
                  ? _c("div", { staticClass: "col-auto pl-0 pr-0" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_",
                          class: {
                            disabled:
                              !_vm.cutomizeSubject.statusSchedule ||
                              _vm.disabledDisponibility
                          },
                          attrs: {
                            disabled:
                              !_vm.cutomizeSubject.statusSchedule ||
                              _vm.disabledDisponibility
                          },
                          on: {
                            click: function($event) {
                              _vm.statusModalPlaces = true
                              _vm.msgType = "clearScheduling"
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "availability.customize.specificScheduling.btnClear"
                              )
                            )
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-animation",
            {
              ref: "scheduleSlick",
              staticClass: "w-100",
              attrs: { show: _vm.statusScheduling }
            },
            [
              _c(
                "carousel",
                {
                  key: `sch-${_vm.updateSch}`,
                  ref: "slickNews",
                  staticClass: "schedule-slick row ml-n1 mr-n1",
                  attrs: {
                    perPageCustom: _vm.scheduleSlick,
                    autoplay: true,
                    speed: 1500,
                    autoplayTimeout: 4000,
                    loop: true,
                    paginationEnabled: false,
                    "mouse-drag": true
                  }
                },
                _vm._l(_vm.specificScheduling.entries(), function([
                  key,
                  value
                ]) {
                  return _c(
                    "slide",
                    {
                      key: key,
                      staticClass: "item p-1",
                      nativeOn: {
                        click: function($event) {
                          return _vm.setDatasScheduling(key, value.ranges)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "sch-day pl-2 pr-2 pt-3 pb-3" },
                        [
                          _c(
                            "span",
                            { staticClass: "sch-title d-block mb-1" },
                            [_vm._v(_vm._s(_vm.$t(value.day)))]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "pl-0 mb-1" },
                            _vm._l(value.ranges.slice(0, 4), function(sch) {
                              return _c("li", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$moment(sch.from, "HH:mm").format("LT")
                                  ) +
                                    " - " +
                                    _vm._s(
                                      _vm.$moment(sch.to, "HH:mm").format("LT")
                                    )
                                )
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          value.ranges.length > 4
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center"
                                },
                                [
                                  _c("button", { staticClass: "btn-more" }, [
                                    _vm._v(
                                      "+ " +
                                        _vm._s(value.ranges.length - 4) +
                                        " " +
                                        _vm._s(_vm.$t("availability.btn.more"))
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.subject.type != "Grupal" && _vm.subject.modality == "Presencial"
        ? _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "specific-places d-flex flex-wrap justify-content-between pl-4 pr-4 pt-2 pb-2 mb-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-md pl-0 pr-0 mb-2 mb-md-0 d-flex align-items-center"
                    },
                    [
                      _c("h4", { staticClass: "title mb-0 mr-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("availability.customize.specificPlaces.t")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "switch",
                        class: {
                          active: _vm.statusPlaces,
                          disabled: _vm.disabledDisponibility
                        },
                        on: {
                          click: function($event) {
                            return _vm.actionSwitch("places")
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "bound" } }, [
                    _vm.statusPlaces
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-md-auto row pl-0 pr-0 ml-0 mr-0 justify-content-end align-items-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-sm pl-0 pr-0 search mr-sm-3 mb-2 mb-sm-0 w-100"
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "icon-search",
                                  attrs: { name: "magnifier" }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filters.search,
                                      expression: "filters.search"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "availability.customize.placeholderSearchPlace"
                                    )
                                  },
                                  domProps: { value: _vm.filters.search },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.filters,
                                        "search",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "col-auto pl-0 pr-0 btn_",
                                class: {
                                  disabled:
                                    _vm.placesToSave.length == 0 ||
                                    _vm.disabledDisponibility
                                },
                                attrs: {
                                  disabled:
                                    _vm.placesToSave.length == 0 ||
                                    _vm.disabledDisponibility
                                },
                                on: {
                                  click: function($event) {
                                    _vm.statusModalPlaces = true
                                    _vm.msgType = "clearPlaces"
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "availability.customize.specificPlaces.btnClear"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-slide-animation", { attrs: { show: _vm.statusPlaces } }, [
                _c(
                  "div",
                  { staticClass: "list-places" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end align-items-center mb-2"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn-firsts ml-3 mr-3",
                            class: {
                              active: _vm.filters.orderBy == "selected"
                            },
                            on: {
                              click: function($event) {
                                _vm.filters.orderBy = "selected"
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "availability.customize.specificPlaces.filterSelected"
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn-az ml-3 mr-3",
                            class: { active: _vm.filters.orderBy == "place" },
                            on: {
                              click: function($event) {
                                _vm.filters.place =
                                  _vm.filters.orderBy != "place"
                                    ? "asc"
                                    : _vm.filters.place == "asc"
                                    ? "des"
                                    : "asc"
                                _vm.filters.orderBy = "place"
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            A-Z\n                            "
                            ),
                            _vm.filters.place == "des"
                              ? _c("svgicon", {
                                  staticClass: "icon",
                                  style:
                                    _vm.filters.orderBy != "place"
                                      ? "opacity:0"
                                      : "",
                                  attrs: { name: "arrow-down" }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.filters.place == "asc"
                              ? _c("svgicon", {
                                  staticClass: "icon",
                                  style:
                                    _vm.filters.orderBy != "place"
                                      ? "opacity:0"
                                      : "",
                                  attrs: { name: "arrow-up" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { mode: "out-in", name: "bound" } },
                      [
                        _vm.spinner
                          ? _c(
                              "div",
                              {
                                key: "spinner",
                                staticClass:
                                  "lesson-spinner w-100 p-4 text-center"
                              },
                              [_c("v-spinner")],
                              1
                            )
                          : !_vm.spinner && _vm.filterPlaces.length > 0
                          ? _c(
                              "div",
                              { key: "places" },
                              _vm._l(
                                _vm.filterPlaces.slice(
                                  _vm.pageInit,
                                  _vm.pageEnd
                                ),
                                function(item, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass:
                                        "place_ d-flex justify-content-between align-items-center position-relative mb-2",
                                      class: {
                                        disabled: _vm.disabledDisponibility
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.$refs[
                                            "place-" + item.id
                                          ][0].click()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "place-icon",
                                          style: "background: #92117F"
                                        },
                                        [
                                          _c("svgicon", {
                                            staticClass: "icon",
                                            attrs: { name: item.icon.icon_name }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-hover-txt", {
                                        staticClass: "pl-3 pr-3 w-100",
                                        attrs: {
                                          fontWeight: 500,
                                          txt: item.name
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "check",
                                          class: { active: item.status }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.placesToSave,
                                                expression: "placesToSave"
                                              }
                                            ],
                                            ref: "place-" + item.id,
                                            refInFor: true,
                                            attrs: {
                                              type: "checkbox",
                                              disabled:
                                                _vm.disabledDisponibility
                                            },
                                            domProps: {
                                              value: item.id,
                                              checked: Array.isArray(
                                                _vm.placesToSave
                                              )
                                                ? _vm._i(
                                                    _vm.placesToSave,
                                                    item.id
                                                  ) > -1
                                                : _vm.placesToSave
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a = _vm.placesToSave,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = item.id,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.placesToSave = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.placesToSave = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.placesToSave = $$c
                                                  }
                                                },
                                                function($event) {
                                                  return _vm.checkPlace(
                                                    $event.target.checked,
                                                    i
                                                  )
                                                }
                                              ]
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm.filterPlaces.length === 0 && !_vm.spinner
                          ? _c(
                              "div",
                              {
                                key: "alert",
                                staticClass:
                                  "empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mb-3"
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "img pl-3 pr-3",
                                  attrs: { original: "", name: "dashboardt2" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pl-3 pr-3 text-center text-sm-left mt-2",
                                    style: { maxWidth: "300px" }
                                  },
                                  [
                                    _c("p", { staticClass: "txt mb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "availability.customize.specificPlaces.msgNotFound"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _vm.paginate > 1
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-center pt-3" },
                          _vm._l(_vm.paginate, function(n) {
                            return _c(
                              "button",
                              {
                                staticClass: "btn-pagination",
                                class: { active: _vm.page === n },
                                on: {
                                  click: function($event) {
                                    _vm.page = n
                                  }
                                }
                              },
                              [_vm._v(_vm._s(n))]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-specifig-scheduling", {
        ref: "specificScheduling",
        attrs: {
          show: _vm.statusModalScheduling,
          subject_id: _vm.subject.subject_assignment_id,
          datas: _vm.datasScheduling,
          initTime: _vm.initTime,
          endTime: _vm.endTime,
          minRangetime: _vm.subject.duration_time,
          disabledDisponibility: _vm.disabledDisponibility
        },
        on: {
          closeAvailability: function($event) {
            _vm.statusModalScheduling = false
          }
        },
        model: {
          value: _vm.specificScheduling,
          callback: function($$v) {
            _vm.specificScheduling = $$v
          },
          expression: "specificScheduling"
        }
      }),
      _vm._v(" "),
      _c("v-modal-places", {
        attrs: {
          show: _vm.statusModalPlaces,
          msgType: _vm.msgType,
          disabledDisponibility: _vm.disabledDisponibility
        },
        on: {
          closeModalplace: function($event) {
            _vm.statusModalPlaces = false
          },
          clear: function($event) {
            return _vm.clear()
          },
          close: function($event) {
            _vm.closeSwitch = false
          },
          prevAction: function($event) {
            _vm.$parent.view = "subjects"
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }