<template>
	<div id="login" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">

        <div class="v-init-form-content d-flex flex-column">
            <div class="v-paper w-100 mb-4">
                <div class="v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3">
                    <router-link class="v-btn-prev mr-2" :to="{ name: 'email-validatation' }" name="institutionEmail" type="button" tag="button">
                        <svgicon class="v-btn-prev-icon" name="arrow-left" color="#fff" />
                    </router-link>
                    <h2 class="v-if-title mb-0">{{ $t('login.t') }}</h2>
                </div>
                
                <!-- ========== Form ========== -->
                <div class="p-4 d-flex flex-column align-items-center">
                    <img class="v-institution-logo mb-4" :src="institution.icon" :alt="institution.name">
                    <form class="w-100" @submit.prevent="send()" >
                        <v-textField class="mb-3" :label="$t('login.form.email')" type="email" v-model="form.email" :options="opcInput" :required="true" :disabled="true"/>
					    <v-textField class="pb-2" :label="$t('login.form.password.label')" :placeholder="$t('login.form.password.placeholder')" type="password" maxlength="50" v-model="form.password" :options="opcInput" :required="true" ref="password"/>
					    <v-btn class="mb-2 mt-4 w-100" fontSize="1rem" fontWeight="400" :txt="$t('login.btn')" height="40px" type="submit" name="login" :spinner="sending" :disabled="$v.validationGroup.$invalid" />
                        <div class="text-center">
                            <router-link class="v-btn-linc" :to="{ name: 'recovery-password' }" name="forgotPassword" type="button" tag="button">{{ $t('login.forgot') }}</router-link>
                        </div>
                    </form>
                </div>
            </div>

            <!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
        </div>

		<!-- ========== Select language ========== -->
		<v-select-lang />
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	import { required, maxLength, email } from 'vuelidate/lib/validators';
	export default{
		data(){
			return{
				sending: false,
				form: {
					email: '',
					password: ''
				},
                opcInput: {
					activeLabel: true,
					styles: {
						label: {
							color: '#5E5E5E',
							fontWeight: 500,
							marginBottom: '.5rem',
							'--transform': 'initial',
							'--transform-active': 'initial'
						}
					}
				}
			}
		},
		computed:{
			...mapState({ institution: state => state.auth.institution,
			              user: state => state.auth.user }),
			emailLowerCase(){
				return this.form.email.toLowerCase();
			}
		},
		created(){
            this.form.email = this.user.email;
		},  
		mounted(){
            this.$refs.password.$el.querySelector('input').focus();
		},
		methods: {
			...mapActions(['login']),
			send(){
				if (!this.$v.validationGroup.$invalid && !this.sending){
					this.sending = true;
				    this.login({ ...this.form, email: this.emailLowerCase }).then( permission => {
				    	this.sending = false;
				    	let url = '';
				    	if (this.$route.query.next)
				    	    url = decodeURIComponent(this.$route.query.next);
				    	if (permission !== 'confirm-email')
					        url.split('/')[1] != permission?this.$router.push({ name : 'dashboard-'+permission }).catch( () => {} ):this.$router.push(url).catch( () => {} ); 
					    else if(permission === 'confirm-email')
                            this.$router.push({ name : 'confirm-email' }); 
                    }).catch( error => {
                    	this.sending = false;
					} )
				}
			}
		},
	 	validations: {
            form: {
            	email: {
            		required
            	},
            	password: {
                    required,
                    maxLength: maxLength(50)
            	}
            },
            emailLowerCase: {
                email
            },
            validationGroup: [ 'form', 'emailLowerCase' ]
        }
	}
</script>