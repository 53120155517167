
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';

    @Component
    export default class itemMenuFormat extends Vue{
        @Prop({ type: String, required: true }) readonly txt!: String;
        @Prop({ type: Boolean, required: false }) readonly disabled!: Boolean;
        @Prop({ required: false }) readonly completed!: Boolean;

        @State( state => state.color ) color;
        @State( state => state.auth.permission ) userType;

        get userColor(): string{
            return this.color[this.userType];
        }
    }
