var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "v-btn position-relative",
      class: { disabled: _vm.disabled, sending: _vm.spinner },
      style: {
        minHeight: _vm.height,
        maxWidth: _vm.max,
        minWidth: _vm.min,
        background: _vm.bgColor
      },
      attrs: { type: _vm.type, disabled: _vm.disabled, btn: _vm.btn }
    },
    [
      _vm.icon !== "" && !_vm.spinner
        ? _c("svgicon", { staticClass: "icon", attrs: { name: _vm.icon } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.spinner
        ? _c(
            "span",
            {
              staticClass: "txt",
              style: { fontSize: _vm.fontSize, fontWeight: _vm.fontWeight }
            },
            [_vm._v(_vm._s(_vm.txt))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.spinner
        ? _c("v-spinner", {
            attrs: {
              height: _vm.spinnerSize,
              width: _vm.spinnerSize,
              options: { color: _vm.spinnerColor }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }