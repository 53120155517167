var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-lesson-history",
      style: { paddingLeft: _vm.padding, paddingRight: _vm.padding }
    },
    [
      _c(
        "div",
        {
          staticClass: "row pt-2 pb-2 v-lh-content",
          style: {
            marginLeft: `-${_vm.padding}`,
            marginRight: `-${_vm.padding}`
          },
          on: {
            click: function($event) {
              _vm.statusDetail = !_vm.statusDetail
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vl-tm col-12 col-sm d-flex ml-n1 mr-n1 mb-3 mb-xl-0 align-items-center",
              class: { parting: _vm.statusParting.typeModality },
              style: {
                ..._vm.gridSize.typeModality,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c(
                "div",
                { ref: "photo", staticClass: "pl-1 pr-1" },
                [
                  _c("v-lesson-photo", {
                    attrs: { options: _vm.lessonPhotoOpc }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pl-1 pr-1",
                  style: { maxWidth: `calc( 100% - ${_vm.photoSize}px )` }
                },
                [
                  _c("v-hover-txt", {
                    staticClass: "mb-2",
                    attrs: {
                      colorTxt: "#000",
                      fontSize: "16px",
                      fontWeight: "400",
                      txt: _vm.cardName
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex ml-n1 mr-n1" }, [
                    _c(
                      "div",
                      { staticClass: "col-auto pl-1 pr-1" },
                      [
                        _c("v-lesson-tag", {
                          attrs: {
                            type: _vm.lesson.type,
                            options: _vm.tagTypeOpc
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-auto pl-1 pr-1" },
                      [
                        _c("v-lesson-tag", {
                          attrs: { type: _vm.lesson.modality }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vl-topic col-12 col-sm mb-3 mb-xl-0 d-flex flex-column justify-content-center",
              class: { parting: _vm.statusParting.topic },
              style: {
                ..._vm.gridSize.topic,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-hover-txt", {
                attrs: {
                  colorTxt: "#000",
                  fontSize: "16px",
                  fontWeight: "400",
                  txt: _vm.lesson.subject
                }
              }),
              _vm._v(" "),
              _vm.lesson.modality == "Presencial" && _vm.lesson.topic
                ? _c("v-lesson-topic", {
                    staticClass: "mt-2",
                    attrs: {
                      options: {
                        lessonId: _vm.lesson.id,
                        topic: _vm.lesson.topic,
                        hideBtn: true
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vl-sch col-12 col-sm mb-3 mb-xl-0 d-flex align-items-center",
              class: { parting: _vm.statusParting.schedule },
              style: {
                ..._vm.gridSize.schedule,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-lesson-schedule", {
                staticClass: "w-100",
                attrs: {
                  options: {
                    date: _vm.lesson.scheduledDate,
                    startTime: _vm.lesson.scheduledTime,
                    endTime: _vm.lesson.endScheduledTime
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vl-place col-12 col-sm mb-3 mb-md-0 pt-2 pb-2 d-flex align-items-center",
              class: { parting: _vm.statusParting.place },
              style: {
                ..._vm.gridSize.place,
                paddingLeft: _vm.padding,
                paddingRight: _vm.padding
              }
            },
            [
              _c("v-lesson-place", {
                attrs: {
                  options: {
                    icon: _vm.lesson.placeIcon,
                    place: _vm.lesson.place,
                    modality: _vm.lesson.modality,
                    type: _vm.lesson.type,
                    lessonId: _vm.lesson.id,
                    edit: false
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "stars",
              staticClass:
                "vl-stars col-auto ml-auto ml-md-0 d-flex align-items-center",
              style: { paddingLeft: _vm.padding, paddingRight: _vm.padding }
            },
            [
              _c("star-rating", {
                staticClass: "stars",
                class: { "hide-num-rating": !_vm.lesson.studentRating },
                attrs: {
                  increment: 0.1,
                  "rounded-corners": true,
                  "star-points": [
                    23,
                    2,
                    14,
                    17,
                    0,
                    19,
                    10,
                    34,
                    7,
                    50,
                    23,
                    43,
                    38,
                    50,
                    36,
                    34,
                    46,
                    19,
                    31,
                    17
                  ],
                  rating:
                    _vm.lesson.type == "Grupal"
                      ? _vm.lesson.feedbackGroupAvg
                      : parseFloat(_vm.lesson.studentRating),
                  "star-size": 16,
                  "border-width": 0.1,
                  "text-class": "custom-text",
                  "inactive-color": "#FCE5BF",
                  "active-color": "#F5A623",
                  "read-only": ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "btnDown",
              staticClass: "btn-show-info col-auto align-items-center d-flex",
              style: { paddingLeft: _vm.padding, paddingRight: _vm.padding }
            },
            [
              _c(
                "button",
                { staticClass: "btn-show" },
                [
                  !_vm.statusDetail
                    ? _c("svgicon", {
                        staticClass: "icon",
                        attrs: { name: "chevron-down" }
                      })
                    : _c("svgicon", {
                        staticClass: "icon",
                        attrs: { name: "chevron-up" }
                      })
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("v-slide-animation", { attrs: { show: _vm.statusDetail } }, [
        _c(
          "div",
          {
            staticClass: "lesson-detail",
            style: { paddingLeft: _vm.padding, paddingRight: _vm.padding }
          },
          [
            _c(
              "div",
              {
                staticClass: "row",
                style: {
                  marginLeft: `-${_vm.padding}`,
                  marginRight: `-${_vm.padding}`
                }
              },
              [
                _c("div", {
                  staticClass: "col-auto d-none d-xl-block",
                  style: {
                    ..._vm.gridSize.typeModality,
                    paddingLeft: _vm.padding,
                    paddingRight: _vm.padding
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col pt-4",
                    style: {
                      paddingLeft: _vm.padding,
                      paddingRight: _vm.padding,
                      maxWidth: _vm.contentLesson
                        ? `${_vm.contentLesson}px`
                        : "initial"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row align-items-center mb-4",
                        style: {
                          marginLeft: `-${_vm.padding}`,
                          marginRight: `-${_vm.padding}`
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-sm-6 mb-3",
                            class: _vm.contentLessonAuto
                              ? "col-md-auto"
                              : "col-md",
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "h-start" },
                              [
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "play-circle" }
                                }),
                                _vm._v(" "),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("lessons.start")) + " ")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.initialTime) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-md col-sm-6 mb-3",
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "h-end" },
                              [
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "stop-circle" }
                                }),
                                _vm._v(" "),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("lessons.end")) + " ")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.endTime) + "\n\t\t\t\t\t\t\t"
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-sm-6 mb-3",
                            class: _vm.contentLessonAuto
                              ? "col-md-auto"
                              : "col-md",
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "h-duration" },
                              [
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "timer" }
                                }),
                                _vm._v(" "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lessons.duration")) + " "
                                  )
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.lesson.duration) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-md col-sm-6 mb-3",
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c("v-lesson-btn", {
                              staticClass: "w-100",
                              attrs: {
                                options: {
                                  type: "chat",
                                  idLesson: _vm.lesson.id
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row pb-5",
                style: {
                  marginLeft: `-${_vm.padding}`,
                  marginRight: `-${_vm.padding}`
                }
              },
              [
                _c("div", {
                  staticClass: "col-auto d-none d-xl-block",
                  style: {
                    ..._vm.gridSize.typeModality,
                    paddingLeft: _vm.padding,
                    paddingRight: _vm.padding
                  }
                }),
                _vm._v(" "),
                _vm.lesson.type !== "Grupal" && _vm.userType == "tutor"
                  ? _c(
                      "div",
                      {
                        staticClass: "col-md studentFeedback",
                        style: {
                          paddingLeft: _vm.padding,
                          paddingRight: _vm.padding,
                          maxWidth: _vm.contentLesson
                            ? `${_vm.contentLesson / 2}px`
                            : "initial"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row align-items-center mb-3",
                            style: {
                              marginLeft: `-${_vm.padding}`,
                              marginRight: `-${_vm.padding}`
                            }
                          },
                          [
                            _c("div", { staticClass: "pl-3 pr-4" }, [
                              _c(
                                "span",
                                { staticClass: "title" },
                                [
                                  _c("svgicon", {
                                    staticClass: "icon",
                                    attrs: { name: "tab" }
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("lessons.studentFeedback")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("star-rating", {
                              staticClass: "stars",
                              class: {
                                "hide-num-rating": !_vm.lesson.studentRating
                              },
                              attrs: {
                                increment: 0.1,
                                "rounded-corners": true,
                                "star-points": [
                                  23,
                                  2,
                                  14,
                                  17,
                                  0,
                                  19,
                                  10,
                                  34,
                                  7,
                                  50,
                                  23,
                                  43,
                                  38,
                                  50,
                                  36,
                                  34,
                                  46,
                                  19,
                                  31,
                                  17
                                ],
                                rating:
                                  _vm.lesson.type == "Grupal"
                                    ? _vm.lesson.feedbackGroupAvg
                                    : parseFloat(_vm.lesson.studentRating),
                                "star-size": 16,
                                "border-width": 0.1,
                                "text-class": "custom-text",
                                "inactive-color": "#FCE5BF",
                                "active-color": "#F5A623",
                                "read-only": ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("p", { staticClass: "feedback" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.lesson.studentComments
                                    ? `"${_vm.lesson.studentComments}"`
                                    : ""
                                )
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  : _vm.userType == "tutor"
                  ? _c(
                      "div",
                      {
                        staticClass: "col-md studentFeedback",
                        style: {
                          paddingLeft: _vm.padding,
                          paddingRight: _vm.padding,
                          maxWidth: _vm.contentLesson
                            ? `${_vm.contentLesson / 2}px`
                            : "initial"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row align-items-center mb-3",
                            style: {
                              marginLeft: `-${_vm.padding}`,
                              marginRight: `-${_vm.padding}`
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  paddingLeft: _vm.padding,
                                  paddingRight: _vm.padding
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "title" },
                                  [
                                    _c("svgicon", {
                                      staticClass: "icon",
                                      attrs: { name: "tab" }
                                    }),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t("lessons.studentsFeedback")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("star-rating", {
                              staticClass: "stars",
                              class: {
                                "hide-num-rating": !_vm.lesson.studentRating
                              },
                              style: {
                                paddingLeft: _vm.padding,
                                paddingRight: _vm.padding
                              },
                              attrs: {
                                increment: 0.1,
                                "rounded-corners": true,
                                "star-points": [
                                  23,
                                  2,
                                  14,
                                  17,
                                  0,
                                  19,
                                  10,
                                  34,
                                  7,
                                  50,
                                  23,
                                  43,
                                  38,
                                  50,
                                  36,
                                  34,
                                  46,
                                  19,
                                  31,
                                  17
                                ],
                                rating:
                                  _vm.lesson.type == "Grupal"
                                    ? _vm.lesson.feedbackGroupAvg
                                    : parseFloat(_vm.lesson.studentRating),
                                "star-size": 16,
                                "border-width": 0.1,
                                "text-class": "custom-text",
                                "inactive-color": "#FCE5BF",
                                "active-color": "#F5A623",
                                "read-only": ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            style: {
                              marginLeft: `-${_vm.padding}`,
                              marginRight: `-${_vm.padding}`
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                style: {
                                  paddingLeft: _vm.padding,
                                  paddingRight: _vm.padding
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-feedback",
                                    on: {
                                      click: function($event) {
                                        return _vm.beforeShowModal({
                                          lesson: _vm.lesson,
                                          action: "v-modal-assistants"
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("lessons.viewFeedback"))
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md myFeedback",
                    style: {
                      paddingLeft: _vm.padding,
                      paddingRight: _vm.padding,
                      maxWidth: _vm.contentLesson
                        ? `${_vm.contentLesson}px`
                        : "initial"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row align-items-center mb-3",
                        style: {
                          marginLeft: `-${_vm.padding}`,
                          marginRight: `-${_vm.padding}`
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "title" },
                              [
                                _c("svgicon", {
                                  staticClass: "icon",
                                  attrs: { name: "tab" }
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("lessons.myFeedback")) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("star-rating", {
                          staticClass: "stars",
                          class: { "hide-num-rating": !_vm.lesson.tutorRating },
                          style: {
                            paddingLeft: _vm.padding,
                            paddingRight: _vm.padding
                          },
                          attrs: {
                            increment: 0.1,
                            "rounded-corners": true,
                            "star-points": [
                              23,
                              2,
                              14,
                              17,
                              0,
                              19,
                              10,
                              34,
                              7,
                              50,
                              23,
                              43,
                              38,
                              50,
                              36,
                              34,
                              46,
                              19,
                              31,
                              17
                            ],
                            rating: _vm.lesson.tutorRating,
                            "star-size": 16,
                            "border-width": 0.1,
                            "text-class": "custom-text",
                            "inactive-color": "#FCE5BF",
                            "active-color": "#F5A623",
                            "read-only": ""
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row",
                        style: {
                          marginLeft: `-${_vm.padding}`,
                          marginRight: `-${_vm.padding}`
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-12",
                            style: {
                              paddingLeft: _vm.padding,
                              paddingRight: _vm.padding
                            }
                          },
                          [
                            _c("p", { staticClass: "feedback" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.lesson.tutorComments
                                    ? `"${_vm.lesson.tutorComments}"`
                                    : ""
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }