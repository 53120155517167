/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'landscape': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.88 13.175l-2.349-2.741c-.263-.307-.638-.488-1.029-.496s-.773.157-1.049.452l-2.033 2.178A3.506 3.506 0 0010.501 11c-.77 0-1.5.245-2.112.709a3.54 3.54 0 00-.919 1.042l-1.943-2.307c-.262-.312-.636-.493-1.026-.499s-.768.166-1.039.471L.126 14.169a.5.5 0 10.748.664L4.21 11.08a.363.363 0 01.278-.135c.1.001.198.052.274.143l7.356 8.735a.5.5 0 00.765-.644l-2.023-2.402 2.756-2.952a.466.466 0 00.1-.108l2.468-2.644c.083-.089.189-.137.297-.135s.212.054.291.147l2.349 2.741a.5.5 0 10.759-.651zm-11.707.41A2.517 2.517 0 0110.5 12c.927 0 1.78.523 2.209 1.329l-2.498 2.676-2.038-2.42z"/><path pid="1" d="M10.5 10a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM13 11a.5.5 0 01-.354-.853l1-1a.5.5 0 01.707.707l-1 1a.498.498 0 01-.354.146zM8 11a.502.502 0 01-.354-.146l-1-1a.5.5 0 01.707-.707l1 1a.5.5 0 01-.354.854zM14.493 2.004A.494.494 0 0114 1.503a.75.75 0 00-.082-.298C13.862 1.104 13.769 1 13.5 1a.5.5 0 010-1c.624 0 1.022.288 1.25.647C15.193.337 15.828 0 16.5 0a.5.5 0 010 1c-.645 0-1.408.617-1.647.854a.512.512 0 01-.36.15zM9 2c-.057 0-.113.003-.169.007A3.005 3.005 0 006 0a3.02 3.02 0 00-2.43 1.241A2.503 2.503 0 000 3.5C0 4.878 1.122 6 2.5 6H9c1.103 0 2-.897 2-2s-.897-2-2-2zm0 3H2.5C1.673 5 1 4.327 1 3.5a1.502 1.502 0 012.625-.992.5.5 0 10.75-.662l-.005-.005C4.742 1.321 5.349 1 6 1c1.103 0 2 .897 2 2a.5.5 0 001 0c.551 0 1 .449 1 1s-.449 1-1 1zM17.493 4.004A.494.494 0 0117 3.503a.75.75 0 00-.082-.298C16.862 3.104 16.769 3 16.5 3a.5.5 0 010-1c.624 0 1.022.288 1.25.647.443-.31 1.078-.647 1.75-.647a.5.5 0 010 1c-.645 0-1.408.617-1.647.854a.512.512 0 01-.36.15zM14.493 7.004A.494.494 0 0114 6.503a.75.75 0 00-.082-.298C13.862 6.104 13.769 6 13.5 6a.5.5 0 010-1c.624 0 1.022.288 1.25.647.443-.31 1.078-.647 1.75-.647a.5.5 0 010 1c-.645 0-1.408.617-1.647.854a.512.512 0 01-.36.15z"/>'
  }
})
