var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "v-crop-image", class: { show: _vm.statusShow } },
        [
          _c("div", { staticClass: "blurcolor", on: { click: _vm.close } }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-crop-image" }, [
            _c("div", { staticClass: "text-center p-3 position-relative" }, [
              _c("h3", { staticClass: "text-center crop-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("accountDetails.cropImg.title")))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-close",
                  attrs: { type: "button" },
                  on: { click: _vm.close }
                },
                [
                  _c("svgicon", {
                    staticClass: "icon-close",
                    attrs: { name: "cross2" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("input", {
              ref: "input",
              attrs: {
                type: "file",
                name: "image",
                accept: "image/png,image/jpg,image/jpeg"
              },
              on: { change: e => _vm.setImage(e.target.files[0]) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img-cropper position-relative" },
              [
                _vm.statusImg
                  ? _c(
                      "div",
                      { staticClass: "crop-spinner" },
                      [_c("v-spinner")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("vue-cropper", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.statusImg,
                      expression: "!statusImg"
                    }
                  ],
                  ref: "cropper",
                  attrs: {
                    "aspect-ratio": 1,
                    minCropBoxWidth: 140,
                    minCropBoxHeight: 140,
                    cropBoxResizable: true,
                    zoomOnWheel: false,
                    zoomOnTouch: false,
                    background: false,
                    minContainerHeight: 150,
                    minContainerWidth: 280,
                    viewMode: 1,
                    alt: ""
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center p-3" },
              [
                _c("v-btn", {
                  attrs: {
                    txt: _vm.$t("accountDetails.cropImg.btn"),
                    min: "100px",
                    disabled: _vm.statusBtn
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.save.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }