var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalVal.modal == "v-modal-changeTopic"
    ? _c(
        "div",
        { staticClass: "v-student-topic", class: { active: _vm.show } },
        [
          _c("div", {
            staticClass: "blurColor",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "v-content" }, [
            _c(
              "h4",
              {
                staticClass: "title pt-4 pb-3 pl-4 pr-5 mb-0",
                class: `bgColor-${_vm.auth.permission}-main`
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.$t("modals.topic.j")) + "\n\t\t\t\t"
                ),
                _c("svgicon", {
                  staticClass: "btn-close",
                  attrs: { name: "cross2" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pt-5 pb-5 pl-4 pr-4" },
              [
                _c("p", { staticClass: "descrip mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("modals.topic.descrip")))
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.topic,
                      expression: "topic",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "field-topic w-100",
                  attrs: {
                    placeholder: _vm.$t("modals.topic.placeholder"),
                    maxlength: "50",
                    type: "text"
                  },
                  domProps: { value: _vm.topic },
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      return _vm.handleTopic()
                    },
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.topic = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-btn", {
                  staticClass: "w-100",
                  attrs: {
                    txt: _vm.$t("modals.topic.btn"),
                    btn: "success",
                    height: "44px",
                    fontSize: "20px",
                    disabled: _vm.$v.topic.$invalid || _vm.sending
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleTopic()
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }