/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M.5 12a.498.498 0 01-.37-.835l8.829-9.758c.274-.303.644-.47 1.042-.47.397 0 .767.167 1.042.47l8.829 9.758a.5.5 0 01-.741.671l-8.829-9.758c-.082-.091-.189-.141-.3-.141s-.218.05-.3.141L.873 11.836a.5.5 0 01-.371.165z"/><path pid="1" d="M15.5 20h-11c-.827 0-1.5-.673-1.5-1.5v-8a.5.5 0 011 0v8a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-8a.5.5 0 011 0v8c0 .827-.673 1.5-1.5 1.5z"/>'
  }
})
