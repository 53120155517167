var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-radio",
      class: {
        error: _vm.error,
        focus: _vm.value != "",
        disabled: _vm.disabled,
        onlyRead: _vm.onlyRead,
        onlyRead: _vm.onlyRead
      }
    },
    [
      _c("label", { staticClass: "v-radio-label" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.opc, function(item, i) {
          return _c(
            "div",
            {
              key: `radio-${i}`,
              staticClass: "col-auto",
              on: {
                click: function($event) {
                  return _vm.handleVal(item[_vm.fieldVal])
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "v-radio-item d-flex align-tiems-center",
                  class: { active: _vm.value === item[_vm.fieldVal] }
                },
                [
                  _c("div", {
                    staticClass: "v-radio-check mr-3 position-relative"
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item[_vm.fieldTxt]))])
                ]
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }