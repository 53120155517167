var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-lesson-topic d-flex align-items-center ml-n1 mr-n1" },
    [
      _c("div", { ref: "tag", staticClass: "col-auto pl-1 pr-1" }, [
        _c("div", { staticClass: "v-lt-tag pt-1 pr-3 pb-1 pl-3" }, [
          _vm._v(_vm._s(_vm.$t("topic.tag")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col pl-1 position-relative",
          class:
            _vm.userType == "student" && !_vm.options.hideBtn ? "pr-4" : "pr-1",
          style: { maxWidth: `calc( 100% - ${_vm.topicSize}px )` }
        },
        [
          _c("v-hover-txt", {
            attrs: {
              colorTxt: _vm.options.topic ? "#000" : "rgba(0,0,0, 0.3)",
              fontSize: "12px",
              fontWeight: "400",
              txt: _vm.options.topic
                ? _vm.options.topic
                : _vm.$t("topic.placeholder")
            }
          }),
          _vm._v(" "),
          _vm.userType == "student" && !_vm.options.hideBtn
            ? _c(
                "button",
                {
                  staticClass: "v-lt-btnEdit cloud-hover",
                  attrs: { name: "btn-edit-topic" },
                  on: {
                    click: function($event) {
                      return _vm.beforeShowModal({
                        lesson: {
                          id: _vm.options.lessonId,
                          topic: _vm.options.topic
                        },
                        action: "v-modal-changeTopic"
                      })
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "v-lt-btnIcon",
                    attrs: { name: "pencil6", color: "#767676" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "cloud-txt" }, [
                    _vm._v(_vm._s(_vm.$t("topic.cloudBtnEdit")))
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }