var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-check" }, [
    _c("span", { staticClass: "title-checkbox d-block" }, [
      _vm._v(_vm._s(_vm.title) + ":")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "checkbox-group" },
      _vm._l(_vm.items, function(check, index) {
        return _c(
          "div",
          {
            staticClass: "checkbox-item mb-1",
            style: {
              pointerEvents:
                _vm.checked(_vm.vals[index]) && _vm.dvalue.length == 1
                  ? "none"
                  : "all"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "checkbox",
                class: { active: _vm.checked(_vm.vals[index]) }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dvalue,
                      expression: "dvalue"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "v-check-" + check + "-" + index
                  },
                  domProps: {
                    value: _vm.vals[index],
                    checked: Array.isArray(_vm.dvalue)
                      ? _vm._i(_vm.dvalue, _vm.vals[index]) > -1
                      : _vm.dvalue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.dvalue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.vals[index],
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.dvalue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.dvalue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.dvalue = $$c
                      }
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "text-checkbox mb-0",
                attrs: { for: "v-check-" + check + "-" + index }
              },
              [_vm._v(_vm._s(check))]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }