import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';

const state = {
    modalNotifications: false, // Modal to get notification permission
	userFirebase : null,
	unsubscribe: null, // Logout user firebase
    flag: false,
    tokenNotifications: null,
    tokenBrowser: null, // uid
    listenNotification: false,
    statusChatVideoRoom: false,
    contToken: 5, // Limit to getToken 5 times (firefox)
	events: [],
	firstMsg: null, // First msg current chat
	lastMsgVisible: null,
    chat: [], // Current chat
    statusChat: false,
    msgs: {},  // All chats
    counters: {}, // chat messages and notifications counters
    notifications: {
        total: 50,
        missing: 50,
        paginate: 15,
        wait: false,
        activeLists: 0,
        lesson: undefined,
        list: [],
        lastest: undefined,
        lastVisible: undefined
    }
}

export default{
    state,
    getters,
    mutations,
    actions
}