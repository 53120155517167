import chat from './chat.vue';
import chatBubble from './chat-bubble.vue';
import dropZone from './dropzone.vue';
import filePreview from './file-preview.vue';
import emojis from './emojis.vue';
import chatTools from './chat-tools.vue';
import lessonInfo from './lesson-info.vue';
import lessonInfo2 from './lesson-info2.vue';
import listChats from './list-chats.vue';

function plugin(Vue){
    Vue.component('v-chat', chat);
    Vue.component('v-chat-bubble', chatBubble);
    Vue.component('v-dropzone', dropZone);
    Vue.component('v-file-preview', filePreview);
    Vue.component('v-emojis', emojis);
    Vue.component('v-chat-tools', chatTools);
    Vue.component('v-chat-lesson', lessonInfo);
    Vue.component('v-chat-lesson2', lessonInfo2);
    Vue.component('v-list-chats', listChats);
}

export default plugin