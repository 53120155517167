/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'feather3': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.501 15a.5.5 0 01-.499-.547c.239-2.52 1.062-4.786 2.449-6.734C7.589 6.12 9.1 4.737 10.944 3.609 14.134 1.657 17.468 1 18.5 1a.5.5 0 01.5.5c0 .944-.877 1.821-1.252 2.196-.611.611-1.712.993-2.777 1.363l-.093.032a32.313 32.313 0 011.557-.039h.101a.5.5 0 01.405.787 9.034 9.034 0 01-2.983 2.727c-.579.331-1.217.485-2.009.485-.462 0-.926-.052-1.375-.102-.433-.048-.881-.098-1.309-.098-.774 0-1.363.162-1.909.526a.36.36 0 01-.054.031c-.725.362-1.352 1.351-1.815 2.859a14.12 14.12 0 00-.491 2.289.5.5 0 01-.496.445zM17.805 2.068c-1.305.2-3.851.872-6.338 2.394-1.376.842-2.917 2.068-4.157 3.794.576-.275 1.211-.405 1.956-.405.484 0 .96.053 1.42.104.442.049.859.096 1.265.096.611 0 1.091-.112 1.513-.353a7.925 7.925 0 002.028-1.629c-.928.029-2.277.11-3.125.336a.499.499 0 01-.545-.76c.477-.715 1.616-1.111 2.823-1.53.958-.333 1.949-.677 2.398-1.126.158-.158.532-.532.763-.921z"/><path pid="1" d="M7.568 16.925C7.562 16.919 7 16.284 7 15.5c0-.36-.193-.701-.544-.961a.5.5 0 00-.594.804c.114.084.139.146.139.157 0 .017-.054.132-.309.26-.305.153-.739.24-1.191.24s-.885-.088-1.191-.24c-.256-.128-.309-.243-.309-.26 0-.008.016-.054.095-.122a.5.5 0 10-.654-.756c-.364.315-.441.651-.441.878 0 .785-.564 1.42-.568 1.425a1.622 1.622 0 000 2.15c.087.095.91.925 3.068.925s2.981-.83 3.068-.925a1.622 1.622 0 000-2.15zm-.737 1.474c-.006.006-.627.601-2.331.601-1.656 0-2.293-.566-2.336-.606a.62.62 0 01.005-.793c.022-.024.366-.405.604-.993.029.016.058.032.089.047.446.223 1.027.346 1.638.346s1.192-.123 1.638-.346a2.5 2.5 0 00.089-.047c.238.588.582.969.604.993a.619.619 0 010 .799z"/>'
  }
})
