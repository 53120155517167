import Vue from 'vue';

const getResults = ({ commit, state, dispatch }, payload) => {
    return new Promise( (resolve,reject) => {
        dispatch('axiosRequest', { config : {
            method: 'GET',
            url: `${state.auth.api}/search`,
            params: { subject_name : payload, limit : state.browser.limit },
            headers: { Authorization : state.auth.token }
        }}).then( resp => resp.data )
        .then( data => {
            resolve(data.subjects.length>0||data.tutors.length>0?true:false);
            commit('setResults',data);
        } ).catch( error => {
            reject(false);
            console.log(error);
        } )
    });    
}

export default{
	getResults
}