/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil6': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M12.763 0a2.24 2.24 0 00-1.585.66l-.876.882L13.46 4.7l.883-.876a2.232 2.232 0 000-3.164 2.227 2.227 0 00-1.58-.66zM9.747 2.097l-8.68 8.672L0 15l4.232-1.067 8.673-8.678-3.158-3.158z" _fill="#000" fill-rule="nonzero"/>'
  }
})
