/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'voicemail': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15 6c-2.206 0-4 1.794-4 4 0 1.194.526 2.266 1.357 3H6.642a3.99 3.99 0 001.357-3c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4h11c2.206 0 4-1.794 4-4s-1.794-4-4-4zM1 10c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3zm14 3c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"/>'
  }
})
