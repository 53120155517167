
    import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class mainBtn extends Vue{
        @Prop({ type: String, default: '25px', required: false }) readonly size!: String;
		@Prop({ type: String, default: 'button', required: false }) readonly type!: String;
		@Prop({ type: String, default: '', required: true }) readonly txt!: String;
		@Prop({ type: String, default: '36px', required: false }) readonly height!: String;
		@Prop({ type: String, default: 'initial', required: false }) readonly max!: String;
		@Prop({ type: String, default: 'initial', required: false }) readonly min!: String;
		@Prop({ type: String, default: '14px', required: false }) readonly fontSize!: String;
		@Prop({ default: 300, required: false }) readonly fontWeight!: Number;
		@Prop({ type: Boolean, default: false, required: false }) readonly disabled!: Boolean;
		@Prop({ type: String, default: 'main', required: false }) readonly btn!: String;
		@Prop({ type: String, default: '', required: false }) readonly icon!: String;
		@Prop({ type: Boolean, default: false, required: false }) readonly spinner!: Boolean;
		@Prop({ type: String, default: '25px', required: false }) readonly spinnerSize!: String;
		@Prop({ type: String, default: '#fff', required: false }) readonly spinnerColor!: any;
		@Prop({ type: Object, default: () => { return {} }, required: false }) readonly options!: any;

		@State(state => state.auth.permission) userType;
		@Getter('userColor') userColor;

		get bgColor(): String{
            if(this.options?.bgColor)
				return this.options.bgColor;
			else
				return this.userColor.main;
		}
	}
