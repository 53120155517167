import { DefaultAudioMixController, DefaultBrowserBehavior, Logger, TimeoutScheduler, } from "amazon-chime-sdk-js";

class TestSound {
    constructor(
        private logger: Logger,
        private sinkId: string | null,
        private frequency: number = 440,
        private durationSec: number = 2,
        private rampSec: number = 0.1,
        private maxGainValue: number = 1
        ) {}

    async init(): Promise<void> {
        // @ts-ignore
        const audioContext: AudioContext = new (window.AudioContext || window.webkitAudioContext)();
        const gainNode = audioContext.createGain();
        gainNode.gain.value = 0;
        const oscillatorNode = audioContext.createOscillator();
        oscillatorNode.frequency.value = this.frequency;
        oscillatorNode.connect(gainNode);
        const destinationStream = audioContext.createMediaStreamDestination();
        gainNode.connect(destinationStream);
        const currentTime = audioContext.currentTime;
        const startTime = currentTime + 0.1;
        gainNode.gain.linearRampToValueAtTime(0, startTime);
        gainNode.gain.linearRampToValueAtTime(this.maxGainValue, startTime + this.rampSec);
        gainNode.gain.linearRampToValueAtTime(
            this.maxGainValue,
            startTime + this.rampSec + this.durationSec
            );
        gainNode.gain.linearRampToValueAtTime(0, startTime + this.rampSec * 2 + this.durationSec);
        oscillatorNode.start();
        const audioMixController = new DefaultAudioMixController(this.logger);
        if (new DefaultBrowserBehavior().supportsSetSinkId()) {
            try {
                // @ts-ignore
                await audioMixController.bindAudioDevice({ deviceId: this.sinkId });
            } catch (e) {
                console.log(`Failed to bind audio device: ${e}`);
            }
        }
        try {
            await audioMixController.bindAudioElement(new Audio());
        } catch (e) {
            console.log(`Failed to bind audio element: ${e}`);
        }
        await audioMixController.bindAudioStream(destinationStream.stream);
        new TimeoutScheduler((this.rampSec * 2 + this.durationSec + 1) * 1000).start(() => {
            audioContext.close();
        });
    }
}

export { TestSound };