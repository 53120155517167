const setTxtBrowser = (state,result) => {
	state.browser.txtBrowser = result;
}

const setResults = (state,results) => {
	state.browser.searchResults = [...results.subjects,...results.tutors];
}

export default{
	setTxtBrowser,
	setResults
}