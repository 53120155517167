var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-full-calendar w-100" },
    [
      !_vm.calenarIsReady
        ? _c(
            "div",
            { staticClass: "bgColor position-absolute w-100 h-100 pt-5 pb-5" },
            [
              _c(
                "div",
                { staticClass: "content-spinner" },
                [_c("v-spinner")],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("FullCalendar", {
        ref: "fullCalendar",
        staticClass: "full-calendar",
        attrs: { options: _vm.calendarOptions }
      }),
      _vm._v(" "),
      _c("v-availability", {
        attrs: {
          minRangetime: _vm.minRangetime,
          initTime: _vm.initTime,
          endTime: _vm.endTime,
          disabledDisponibility: _vm.disabledDisponibility,
          datas: _vm.availabilityDatas,
          show: _vm.showModalAvailability
        },
        on: {
          closeAvailability: _vm.closeAvailability,
          changeDays: _vm.updateCalendar,
          changeEvents: function($event) {
            return _vm.addScheduleTodays()
          },
          changeEventAndRemoveCustoms: _vm.changeEventAndRemoveCustoms
        },
        model: {
          value: _vm.generalSchedule,
          callback: function($$v) {
            _vm.generalSchedule = $$v
          },
          expression: "generalSchedule"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }