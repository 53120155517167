<template>
	<div class="w-100 d-flex flex-column">
		<div class="v-chat-user w-100" v-if="!userMap && !isMyMsg" >
			<p class="mr-1 mb-0 d-inline">{{ $t('room.header.student') }}</p>
		</div>
		<div class="v-chat-user w-100" v-else-if="!isMyMsg && showAvatar" >
			<p class="mr-1 mb-0 d-inline">{{ userMap.name }}</p>
			<div class="label-t" v-if="userMap.type == 'tutor'">
				<span>{{ $t('room.header.tutor') }}</span>
			</div>  
		</div>

		<div class="v-chat-bubble" :class="isMyMsg?`v-chat-txt-right align-self-end bgColor-${permission}-main`:'v-chat-txt-left align-self-start'" :type="type" @click="$emit('showPreview')" >
			<!-- ======== Default image ======= -->
			<div class="v-chat-img n-img" v-if="!userMap">
				<svgicon class="icon-users" name="group" />
			</div> 
			<!-- ======== Avatar ======= -->
			<div class="v-chat-img" :style="{ background: `url(${userMap.avatar})center center / cover no-repeat` }" v-else-if="showAvatar"></div> 
			<!-- ======== Message ======= -->
			<div class="v-chat-content d-flex position-relative">
				<div class="v-icon-file col-auto pl-0 pr-0" v-if="msg.file">
					<svgicon class="v-icon" :name="iconFile" color="#000" />
					<div class="v-content-ih" :class="isMyMsg?`bgColor-${permission}-light`:null" v-if="iconPreview == 'magnifying2' || (iconPreview == 'download3' && !chatFile)">
						<svgicon class="v-icon-hover" :name="iconPreview" />
					</div>
				</div>
				<div class="col-auto pl-0 pr-0 mb-1">
					<span class="v-msg d-block" v-html="linkify()"></span>
					<span class="v-file-size d-block" v-if="type == 'file'">{{ chatFile?`${chatFile.progress} %`:msg.size }}</span>
				</div>
				<!-- ======== Btn download file ======= -->
				<button class="btn-download" :class="isMyMsg?`bgColor-${permission}-main`:null" v-if="type == 'file' && !chatFile" @click.stop="downloadFile({ file: msg.file, name: msg.text, idLesson: idLesson})">
					<svgicon class="d-icon" name="download3" />
					<div class="hover-txt">{{ $t('chat.download') }}</div>
				</button>
				<!-- ======== Btn cancel download ======= -->
				<button class="btn-cancel-download" :class="isMyMsg?`bgColor-${permission}-main`:null" v-if="type == 'file' && chatFile"  @click.stop="chatFile.source.cancel()">
					<div class="cv-spinner">
					    <v-spinner height="100%" width="100%" />
				    </div>
					<svgicon class="d-icon" name="cross2" />
					<div class="hover-txt">{{ $t('chat.cancelDownload') }}</div>
				</button>
			</div>
			<span class="v-time d-block w-100">{{ $moment(msg.createdAt).format('LT') }}</span>
		</div>
    </div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	export default{
        props: { 
            type: { type: String, default: 'text', required: false }, // text, file
            idLesson: { default: null, required: true },
            msg: { type: Object, default: () => {}, required: false },
            userMap: { type: Object, default: () => {}, required: false },
            showAvatar: { type: Boolean, default: false, required: false },
            iconFile: { type: String, default: 'file', required: false },
            iconPreview: { type: String, default: 'magnifying2', required: false }
        },
        computed:{
        	...mapState({ 
        		    permission(state){ return state.auth.permission },
        	        userId(state){ return state.auth.user.id }, 
        		    chatFile(state){
        		    const file = state.chatFiles.inProgress[this.msg.file];
        		        if (file)
        			        if (file.progress != 100)
        				        return file;
        			        else
        				        return undefined
        	        }
        	}),
        	isMyMsg(){
        		return this.userId+'' == this.msg.uid;
        	}
        },
        methods:{
        	...mapActions([ 'downloadFile' ]),
        	linkify() {
                if(this.type == 'text'){
                    const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                    return this.msg.text.replace(urlRegex, (url) => {
                        return '<a class="v-link" href="' + url + '" target="_blank">' + url + '</a>';
                    });
                }else{
                    const ext = this.msg.text.split('.').pop(),
                          name = this.msg.text.substr(0, (this.msg.text.length - (ext.length + 1)));
                    if (name.length >= 30)
                        return `${name.substr(0, 30)}...${ext}`;
                    else
                        return this.msg.text;
                }
            }
        }
	}
</script>