/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.5 11c-.827 0-1.5-.673-1.5-1.5S12.673 8 13.5 8s1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 100 1 .5.5 0 000-1zM6.5 11C5.673 11 5 10.331 5 9.509s.673-1.491 1.5-1.491S8 8.687 8 9.509C8 10.331 7.327 11 6.5 11zm0-1.982c-.276 0-.5.22-.5.491s.224.491.5.491.5-.22.5-.491-.224-.491-.5-.491zM10 16a4.998 4.998 0 01-4.158-2.222.5.5 0 01.831-.557 3.999 3.999 0 006.654 0 .5.5 0 01.831.557A4.996 4.996 0 0110 16z"/><path pid="1" d="M18.419 7.815a9.045 9.045 0 00-3.467-4.331c.031-.251.054-.579.048-.992-.016-1.051-.178-1.538-.196-1.59-.138-.393-.427-.689-.792-.813s-.774-.065-1.123.162l-1.726 1.123a1.99 1.99 0 00-2.326 0L7.111.251C6.762.024 6.353-.035 5.988.089s-.653.42-.792.813c-.018.052-.18.539-.196 1.59-.006.413.016.742.048.992a9.04 9.04 0 00-3.467 4.331C.588 8.57 0 9.749 0 11s.588 2.43 1.58 3.185a8.972 8.972 0 003.186 4.137C6.299 19.42 8.108 20 10 20s3.701-.58 5.234-1.678a8.972 8.972 0 003.186-4.137C19.413 13.43 20 12.251 20 11s-.588-2.43-1.581-3.185zm-4.985-6.726c.092-.06.183-.079.256-.054s.133.094.169.195c.009.028.127.425.141 1.277.013.833-.109 1.228-.119 1.26a.377.377 0 01-.214.227.38.38 0 01-.315-.03l-1.366-.727a1.997 1.997 0 00-.176-1.09l1.626-1.058zM10 2c.551 0 1 .449 1 1s-.449 1-1 1c-.551 0-1-.449-1-1s.449-1 1-1zm-4 .508c.013-.853.132-1.249.141-1.277.036-.101.096-.17.169-.195s.164-.006.256.054l1.626 1.058a1.982 1.982 0 00-.176 1.09l-1.366.727a.38.38 0 01-.315.03.377.377 0 01-.214-.227c-.01-.031-.132-.426-.119-1.26zm11.716 10.953a.5.5 0 00-.184.241C16.395 16.871 13.368 19 10 19s-6.395-2.129-7.532-5.298a.5.5 0 00-.184-.241C1.48 12.899 1 11.979 1 11s.48-1.899 1.284-2.461a.5.5 0 00.184-.241 8.037 8.037 0 012.908-3.827 1.337 1.337 0 001.075.544c.227 0 .457-.057.667-.168l1.265-.673a1.998 1.998 0 003.235 0l1.265.673c.21.112.439.168.667.168.161 0 .321-.028.472-.086.24-.091.448-.251.603-.458a8.037 8.037 0 012.908 3.827.5.5 0 00.184.241c.804.562 1.284 1.482 1.284 2.461s-.48 1.899-1.284 2.461z"/>'
  }
})
