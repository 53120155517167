var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "confirm-modal v-modal-permissions",
      class: { active: _vm.show }
    },
    [
      _c("div", {
        staticClass: "blurColor",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-content" },
        [
          _c(
            "h4",
            {
              ref: "title",
              staticClass: "title pt-3 pt-sm-4 pb-3 pl-4 pr-4 pr-md-5 mb-0",
              class: `bgColor-${_vm.permission}-main`
            },
            [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.content.t) + "\n\t\t\t\t"),
              _c("svgicon", {
                staticClass: "btn-close",
                attrs: { name: "cross2" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              style: { height: _vm.contentH },
              attrs: { options: { suppressScrollX: true } }
            },
            [
              _c(
                "div",
                {
                  ref: "content",
                  staticClass: "pt-4 pt-sm-5 pr-4 pb-4 pb-sm-5 pl-4"
                },
                [
                  _vm.alert == _vm.deviceAlerts.block ||
                  _vm.alert == _vm.deviceAlerts.micBlock ||
                  _vm.alert == _vm.deviceAlerts.camBlock
                    ? _c("img", {
                        staticClass: "gif w-100 mb-3",
                        attrs: {
                          src: _vm.gifPermissions,
                          alt: _vm.$t("room.permissions.gifAlt")
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "txt-info mb-0" }, [
                    _vm._v(_vm._s(_vm.content.d))
                  ])
                ]
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }