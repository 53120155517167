export default {
	formatDate: 'DD MMM YYYY',
	formatDateC: 'dddd, D [de] MMMM [de] YYYY',
	lessonFormatDate: 'dddd D MMM YY',
	formatDateChat: 'DD/MM/YY',
	lastweek: '[El] dddd [Pasado]',
	googlePlay: '/media/images/badge-google-play.png',
	appStore: '/media/images/badge-app-store.png',
	linkSupport: 'https://support.linc.io/hc/es-mx',
	error429: 'Has realizado demasiadas solicitudes recientemente. Por favor, inténtalo de nuevo más tarde.',
    msgOnline: 'Conectado',
    msgOffline: 'Se perdió la conexión',
    copyRight: '© {year} Linc Learning, Inc. All rights reserved.',
	languages: { // Component lang
	    en: 'Inglés',
	    es: 'Español'
	},
	gender: { // Select gender
		m: 'Hombre',
        f: 'Mujer',
        o: 'Otro'
	},
    civilStatus: {
        s: 'Soltero',
        csh: 'Casado sin hijos',
        cch: 'Casado con hijos',
        o: 'Otro'
    },
    scholarship: {
        opc1: 'Primaria',
        opc2: 'Secundaria',
        opc3: 'Preparatoria',
        opc4: 'Bachillerato técnico',
        opc5: 'Licenciatura',
        opc6: 'Posgrado',
        opc7: 'Sin estudios'
    },
    typeOfScholarship: {
        opc1: 'Excelencia académica',
        opc2: 'Apoyo económico',
        opc3: 'Deportiva o cultural',
        opc4: 'Otra'
    },
    yesNo: {
        y: 'Sí',
        n: 'No'
    },
    liveWith: {
        opc1: 'Con mi familia',
        opc2: 'Con familiares cercanos',
        opc3: 'Con otros estudiantes',
        opc4: 'Solo'
    },
    parentStatus: {
        opc1: 'Vive',
        opc2: 'Finado',
        opc3: 'Finada'
    },
	group: 'Grupal',
	groupLarge: 'Sesión grupal',
	modality: { // Select Lesson mode
        p: 'Presencial',
        o: 'En línea'
	},
    months: {
        jan: 'Ene',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Abr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dic: 'Dic'
    },
	noPlace: {
        t: 'Define un lugar',
        s: 'Lugar por definir'
    },
	editPlace: 'Editar lugar',
	oneOnOne: 'Individual',
	lessonType: {
        g: 'Grupal',
        a: 'Individual',
        m: 'max'
	},
	topic: {
        tag: 'Tema',
        cloudBtnEdit: 'Editar tema',
        opc: '(Opcional)',
        placeholder: 'Define un tema'
    },
	image:{
		getTheAppS: "Perfil de estudiante de la plataforma móvil",
        getTheAppT: "Perfil de asesor de plataforma móvil",
        appStore: 'Consíguelo en el App Store',
        googlePlay: 'Disponible en Google Play',
        icloud: 'iCloud',
        google: 'Calendario',
        outlook: 'Outlook',
        exchange: 'Exchange'
	},
	today: 'Hoy',
	yesterday: 'Ayer',
	tomorrow: 'Mañana',
	days: {
		monday: 'Lunes',
		tuesday: 'Martes',
		wednesday: 'Miércoles', 
		thursday: 'Jueves',
		friday: 'Viernes',
		saturday: 'Sábado',
		sunday: 'Domingo'
	},
	intitutionalEmail: { // Institutional email
        t: 'Inicia sesión en Linc',
        label: 'Ingrese su correo electrónico institucional',
        btn: 'Continuar',
        invalidEmail: {
        	error: 'Oops...',
        	msgHtml: 'El correo electrónico ingresado <br> no corresponde a esta institución educativa',
        	btn: 'Intentar de nuevo'
        },
        planBasic: {
            d: 'Tu institución educativa cuenta con el servicio básico de Linc que incluye asesorías presenciales y el uso ilimitado de la app móvil.',
            iHtml: 'Para mayor información, comunícate con nuestro <a href="https://ayuda.linc.io/contact" target="_blank">servicio al cliente</a>'
        },
        copyRight: '© {year} Linc Learning, Inc. All rights reserved.'
	},
	404: { // Error 404
        error: 'Error 404',
        msg: 'Lo sentimos, la página que buscas no existe.',
        btnD: 'Volver a la página principal',
        btnE: 'Volver a la página principal'
	},
	403: { // Error 403
        error: 'Error 403',
        msg: 'Lo sentimos, no tienes permiso para acceder a esta página.',
        btn: 'Volver a la página principal'
	},
	signUp: { // Sign up
		t: 'Ingresa tu información de registro',
		form: {
			name: {
				label: 'Nombre',
				placeholder: 'Nombre'
			},
			lastName: {
				label: 'Apellidos',
				placeholder: 'Apellido'
			},
			gender: {
				label: 'Género',
				placeholder: 'Selecciona tu género'
			},
			birthday: 'Fecha de nacimiento',
			campus: {
				label: 'Campus',
				placeholder: 'Selecciona tu campus'
			},
			career: {
				label: 'Carrera',
				placeholder: 'Selecciona tu carrera'
			},
			major: {
				label: 'Carrera',
				placeholder: 'Selecciona tu carrera'
			},
			period: {
				label: 'Período escolar',
				placeholder: 'Selecciona tu período escolar'
			},
            password: { 
                label: 'Contraseña',
                placeholder: 'Ingresa tu contraseña',
                alert: 'Su contraseña debe tener al menos 8 caracteres'
            }
		},
		legalHtml: 'Acepto los <a href="https://linc.io/es/terminos-y-condiciones" target="_blank">Términos</a> y el <a href="https://linc.io/es/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a>',
		btn: 'Comenzar'
	},
	confirmInstitutionalEmail: { // txt confirm email
        t: 'Verifica tu correo',
        msgHtml: 'Te hemos enviado un correo a <br> <strong> {email} </strong> <br> con un enlace para confirmar tu cuenta.',
        invalidEmail: '¿Correo incorrecto?',
        btn: 'Regístrate de nuevo'
	},
	confirmEmailSuccess:{ // Institutional Email confirmed
        t: '¡Tu cuenta ha sido confirmada exitosamente!',
        msg2: 'A partir de este momento, ya puedes ingresar a la plataforma de Linc.',
        btn: 'Iniciar sesión'
	},
	login:{ // login
        t: 'Inicia sesión en Linc',
        form: {
        	email: 'Correo institucional',
        	password: {
                label: 'Contraseña',
                placeholder: 'Ingresa tu contraseña'
            }
        },
        btn: 'Iniciar sesión',
        forgot: '¿Olvidaste tu contraseña?'
	},
	recoveryPassword: { // Recovery password
        t: 'Recuperar contraseña',
        label: 'Te enviaremos un correo para restablecer la contraseña.',
        placeholder: 'Correo institucional',
        btn: 'Enviar',
        btnPrev: 'O inicia sesión con tus credenciales'
	},
	resetPasswordVerifyEmail: { // txt reset password
        t: 'Verify your email',
		msgHtml: 'Te enviamos un correo a <br> <strong> {email} </strong> <br> con las instrucciones para restablecer tu contraseña.',
		btn: 'Volver al inicio'
	},
	resetPassword:{ // Reset password
		t: 'Restablecer contraseña',
		form: {
			password: 'Nueva contraseña',
			samePassword: 'Vuelve a escribir la contraseña'
		},
		step1: '• Las contraseñas no coinciden. Por favor intente de nuevo.',
		step2: '• La contraseña debe de tener un mínimo de 8 caracteres.',
		btn: 'Restablecer contraseña'
	},
	resetPasswordSuccess: { // Updated password
		t: '¡Tu contraseña ha sido actualizada exitosamente!',
		txt: 'A partir de este momento, ya puedes ingresar con tu nueva contraseña.',
		btn: 'Iniciar sesión'
	},
	header:{  // Header
		help: 'Ayuda',
		helpSub: {
            docs: {
            	t: 'Documentación en línea',
            	descrip: 'Guías de inicio',
            	link: 'https://ayuda.linc.io/'
            },
            online: {
            	t: 'Soporte en línea',
            	descrip: 'Contactanos vía Whatsapp',
            	link: 'https://wa.me/5214791135742'
            },
            email: {
            	t: 'Soporte por correo',
            	descrip: 'Contactanos vía email',
            	link: 'https://ayuda.linc.io/contact'
            }
		},
		menu: {
			account: 'Mi cuenta',
			profile: 'Ver perfil',
			tutorMode: 'Cambiar a modo asesor',
			studentMode: 'Cambiar a modo estudiante',
			preferences: 'Preferencias de asesor',
            settings: 'Configuración de cuenta',
			logOut: 'Cerrar sesión'
		}
	},
	title: { // titles page
		login: 'Login', // /login
		sightUp: 'Registro', // /sign-up
		recoveryPassword: 'Recuperar contraseña', // /revocery-password
		confirmEmail: 'Confirma tu correo electrónico',// /confirm
		confirmSucces: 'Confirmación exitosa',// /confirm/:token
		resetPassword: 'Restablecer contraseña',// /reset-password/:token
		404: 'La página no existe',// /404
		403: 'Error 403',// /403
		dashboard: 'Dashboard',
		lessons: 'Asesorías',
		history: 'Historial de Asesorías',
		calendar: 'Calendario',
		acount: 'Mi Cuenta',
		changePassword: 'Cambiar Contraseña',
		preferences: 'Preferencias',
		subjects: 'Materias Asignadas',
		availability: 'Disponibilidad',
		biography: 'Biografía profesional',
        chat: 'Chats',
        app: 'Descarga la App',
        schedule: 'Agendar Asesoría',
        search: 'Búsqueda de asesores',
        profile: 'Perfil de {name}',
        profiletTutor: 'Mi Perfil',
        virtualRoom: 'Salón virtual',
        notifications: 'Notificaciones',
        formats: 'Formatos',
        scheduleSession: 'Agendar asesoría'
	},
	mainMenu: {
        dashboard: 'Dashboard',
        schedule: 'Agendar Asesoría',
        profile: 'Ver perfil',
        chat: 'Chats',
        formatsS: 'Mis formatos',
        formatsT: 'Formatos de Estudiantes',
        appHtml: `<img class="icon" src="/media/images/logo-f.png">
                  <span class="text thin">Descarga la </span>
				  <span class="text bold">app</span>
				  <span class="text all">Descarga la app de Linc</span>`,
        lessons: {
        	lessons: 'Asesorías',
        	manage: 'Administrar',
        	history: 'Historial',
        	calendar: 'Calendario'
        },
        preferences: {
        	preferences: 'Preferencias de asesor',
        	subjects: 'Materias asignadas',
        	availability: 'Disponibilidad',
        	biography: 'Biografía profesional',
        },
        settings: {
        	settings: 'Cuenta',
        	acount: 'Detalles de cuenta',
        	changePassword: 'Cambiar contraseña',
        	notifications: 'Preferencias'
        }
	},
	search:{ // Component search
		placeholder: 'Buscar materia o asesor ...',
		cloud: 'Al buscar por materia te mostraremos los asesores que la pueden impartir, mientras que al buscar por asesor, te llevaremos a su perfil.'
	},
	msgEmpty: {
		msgUpcoming: '<span class="bold">No tienes</span> ninguna asesoría confirmada.',
		msgOngoing: '<span class="bold">No tienes</span> ninguna asesoría en curso.',
		msgPending: '<span class="bold">No tienes</span> ninguna asesoría pendiente de confirmar.',
		msgLatest: 'Aquí aparecerán las últimas materias solicitadas.',
		msgPast: 'Aquí aparecerá el historial de asesorías finalizadas.',
		msgTutor: 'Aquí aparecerán los últimos asesores solicitados.',
		will: 'En este apartado se mostrarán las asesorías.',
		msgTutor2: 'Los asesores aparecerán una vez que agendes tu primera asesoría.',
        search: "No se encontraron asesores",
		msgTutor2: 'Los asesores aparecerán una vez que agendes tu primera asesoría.',
        search: "No encontramos ningún asesor que coincida con tu búsqueda.",
        inputsearch: 'No se han encontrado materias o asesores para tu búsqueda.',
        subjectSearch: 'No se han encontrado materias para tu búsqueda.',
		btn: 'Agendar asesoría'
	},
	lessons: { // lessons components ( Manage, history )
	    vistualRoom: 'Salón virtual',


        btnChat: 'Chat',
        btnChatLog: 'Historial de chat',
        btnCancel: 'Cancelar',
        btnConfirm: 'Confirmar',
        btnStart: 'Iniciar',
        btnAttendance: 'Asistencia',
        btnStop: 'Finalizar',
        confirm: 'Confirmada',
        btnJoin: 'Unirse al salón virtual',
        tOngoing: 'Asesorías en curso',
        tOngoingModal: 'Asesoría en curso',
        tOngoingHtml: '<span class="bold">Asesoría</span> en curso',
        tUpcoming: 'Asesorías confirmadas',
        tPending: 'Asesorías pendientes',
        tPast: 'Historial de asesorías',
        currentTime: 'Duración:',
        btnAll: 'Ver todas',
        start: 'Inicio:',
        end: 'Fin:',
        duration: 'Duración:',
        myFeedback: 'Mi calificación',
        studentFeedback: 'Calificación del estudiante',
        studentsFeedback: 'Calificación promedio del grupo',
        viewFeedback: 'Ver feedback de los estudiantes',
        seats: 'estudiantes',
	},
	dashboard: {  // Dashboard
		upcoming: 'Asesorías confirmadas',
		pending: 'Peticiones de asesorías pendientes',
		statistics: 'Estadísticas',
		latestLessons: 'Últimas materias solicitadas',
		latestTutors: 'Últimos asesores solicitados',
		btnProfile: 'Ver perfil',
		this: 'Últimos',
		week: '7 días',
		completed: 'Asesorías',
		lessons: 'completadas',
		tutoring: 'Horas',
		hours: 'impartidas',
		canceled: 'Asesorías',
		requested: 'canceladas'
	},
	calendar: { // Calendar
        title: 'Sincronice su calendario',
        descripHtml: 'Suscribete al <strong>Calendario de Linc</strong> con tu aplicación de calendario favorita. Visualiza tus eventos de asesorías próximas y pasadas con su fecha, hora y lugar.',
        titleUrl: 'URL del calendario',
        descripUrlHtml: 'Utiliza la siguiente URL en tu aplicación de calendario para suscribirte al <strong>Calendario de Linc</strong>.',
        btnCopy: 'COPIAR',
        enabledTxt: 'Tu calendario de Linc se encuentra habilitado',
        disabledTxt: 'Tu calendario de Linc se encuentra deshabilitado',
        btnEnabled: 'Habilitar calendario',
        btnDisable: 'Deshabilitar calendario',
        instructions: 'Instrucciones para configurar el calendario',
        icloud: 'https://ayuda.linc.io/article/42-como-sincronizo-el-calendario-de-linc-con-icloud',
        google: 'https://ayuda.linc.io/article/43-como-sincronizo-el-calendario-de-linc-con-google-calendar',
        outlook: 'https://ayuda.linc.io/article/44-como-sincronizo-el-calendario-de-linc-con-outlook-office-365-o-microsoft-exchange',
        exchange: 'https://ayuda.linc.io/article/44-como-sincronizo-el-calendario-de-linc-con-outlook-office-365-o-microsoft-exchange'
	},
	accountDetails: { // Account details
		title: 'Mi Cuenta',
		form: {
			name: 'Nombre',
			lastName: 'Apellido',
			email: 'Correo institucional',
			campus: 'Campus',
			phone: 'Teléfono',
			birthday: 'Fecha de nacimiento',
			gender: 'Género',
			major: 'Carrera',
			semester: 'Período escolar',
			btn: 'Actualizar cuenta'
		},
		cropImg: {
			title: 'Recortar Foto',
			btn: 'Actualizar foto de perfil'
		}
	},
	changePassword: { // Change password
		title: 'Cambiar contraseña',
		form: {
            cPassword: 'Contraseña actual',
            nPassword: 'Nueva contraseña',
            btn: 'Actualizar contraseña'
		}
	},
	notificationsPreferences: { // Notifications preferences
        titleEmail: 'Notificaciones por correo',
        titlePush: 'Notificaciones Push',
        general: 'General',
        lessons: 'Asesorías',
        descrip: 'Las notificaciones Push solo pueden ser controladas desde la aplicación móvil.',
        app: 'Descarga la app',
        btn: 'Guardar preferencias',
        checkboxes: {
        	newsletter: {
        		t: 'Newsletter de Linc',
        		dHtml: 'Correos sobre nuevas actualizaciones de la plataforma (una vez al mes).'
        	},
        	lesson_request: {
                t: 'Nuevas peticiones de asesorías',
                d: 'Recibe notificaciones por correo cuando te soliciten nuevas asesorías.'
        	},
        	lesson_cancelled: {
                t: 'Asesorías canceladas',
                d: 'Recibe notificaciones por correo cuando los estudiantes cancelen asesorías programadas.'
        	},
        	lesson_status: {
        		t: 'Estado de las asesorías',
        		d: 'Recibe notificaciones por correo cuando tus asesorías sean confirmadas o canceladas por los asesores.'
        	},
        	lesson_summary: {
        		t: 'Resumen de asesorías finalizadas',
        		d: 'Obtén en tu correo un resumen con los detalles de cada asesoría impartida.'
        	},
        	upcoming_lessons: {
        		t: 'Recordatorios de asesorías próximas',
        		dHtml: 'Obtén recordatorios en tu correo cuando se aproximen tus asesorías confirmadas.'
        	}
        }
	},
	lincAppS: { // Get the Linc App (student)
        title: 'Agenda las asesorías desde tu celular',
        subTitle: 'Descarga la app gratuita que ofrece las mismas funciones que la plataforma web.',
        img: '/media/images/features/ES/dashboard.png',
        qr: 'qr_code_ES',
        qrtxt1: 'Escanea el código QR',
        qrtxt2: 'para descargar la app',
        benefits: {
        	b1: {
        		t: 'Encuentra al asesor ideal',
        		d1: '• Compara a los asesores en función de su calificación, disponibilidad y más.'
        	},
        	b2: {
        		t: 'Agenda en segundos',
        		d1: '• Sin importar donde te encuentres, agenda asesorías en segundos.'
        	},
        	b3: {
        		t: 'Conexión instantánea',
        		d1: '• Comunícate con los asesores en tiempo real a través del chat.'
        	},
        	b4: {
        		t: 'Notificaciones en tiempo real',
        		d1: '• Recordatorios, confirmaciones de asesorías y más.'
        	}
        }
	},
	lincAppT: { // Get the Linc App (tutor)
		title: 'Administra la demanda acádemica desde cualquier lugar',
		subTitle: 'Descarga la app gratuita que ofrece las mismas funciones que la plataforma web.',
        img: '/media/images/asesores.png',
        benefits: {
        	b1: '• Mantente en contacto con los estudiantes en todo momento.',
        	b2: '• Administra tus asesorías',
        	b3: '• Actualiza tus preferencias de asesor incluyendo disponibilidad.',
        	b4: '• Recibe notificaciones en tiempo real.'
        }
	},
	schedule: { // Schedule lesson
		title: 'Comience buscando una materia o asesor.',
		sub: 'o seleccione el departamento y materia a continuación',
		selectDay: "Selecciona una fecha en el calendario",
        noTimes: "No hay horarios disponibles",
		switch: {
			alone: 'Asesoría individual',
			group: 'Asesoría grupal'
		},
		area: 'Departamento',
		subject: 'Materia',
		msg: 'Seleccione un departamento',
        msgNoSubjects: 'No se encontraron materias disponibles.',
	},
    scheduleT: {
        t: 'Agendar Asesoría',
        HowItWorks: '¿Cómo funciona?',
        HowItWorksHref: 'https://ayuda.linc.io/article/261-como-agendo-una-asesoria-a-mis-estudiantes',
        subjects: {
            t: 'Materia',
            tooltip: 'Solamente puedes seleccionar materias que tengas asignadas por tu institución educativa.',
            placeholder: 'Selecciona una materia',
            alertNotFound: 'Subjects not found'
        },
        dateTime: {
            t: 'Fecha y Hora',
            tooltip: "Asegúrate de definir una fecha y hora donde los estudiantes tengan disponibilidad para asistir a la sesión.",
            date: 'Selecciona un día',
            time: 'Selecciona una hora'
        },
        students: {
            t: 'Estudiantes',
            tooltip: "Los estudiantes invitados serán notificados automáticamente sobre esta sesión.",
            placeholder: 'Busca a los estudiantes por nombre o correo institucional',
            alertStudentsNotFound: 'Su búsqueda no coincidió con ningún estudiante registrado en la plataforma.',
            alertInvalidExtHtml: ' El correo electrónico ingresado no pertenece a su institución educativa. Utilice uno de los siguientes dominios oficiales: {exts} o {lastExt}.',
            alertStudentIsntExist: 'El correo electrónico ingresado no coincide con ningún estudiante existente. Para enviarle una invitación a su correo para unirse a esta sesión, escriba su nombre completo y haga clic en "Invitar"',
            studentAdd: 'The student was already added',
            studentLimit: 'Solo puedes invitar un máximo de {quote} estudiantes',
            unconfirmed: 'No confirmado',
            form: {
                name: 'Nombre',
                lastName: 'Apellidos',
                btn: 'Invitar'
            },
            alertSuccess: 'Asesoría agendada correctamente'
        },
        places: {
            t: 'Lugar de la asesoría',
            placeholder: 'Selección de lugar',
            placeholder2: 'Agregar lugar',
            alertPlacesNotFound: 'Lugar no encontrado',
        },
        summary: {
            t: 'Resumen de la sesión',
            enrolled: 'Estudiantes invitados: {count}'
        },
        btnBookSession: 'Agendar asesoría',
        alerts: {
            1: 'El lugar seleccionado no pertenece a la universidad.',
            2: 'La fecha y hora seleccionada ya ha pasado. Por favor selecciona otra con mayor anticipación a la fecha actual.',
            4: 'No tienes permiso para agendar asesorías para la materia seleccionada.',
            3: '',
            5: 'Ya cuentas con otra asesoría agendada que interfiere con la fecha y hora seleccionada. Por favor intenta en otro horario.',
            6: 'El lugar seleccionado no está permitido en esta materia.',
            7: 'No es posible agregar uno o más usuarios invitados (no confirmados) a esta asesoría. Por favor intente de nuevo.'
        }
    },
	searchResult: { //Search results
		tutorsFound: '{count} asesores de {subject} encontrados',
		filtersBtn: 'Filtros',
        filters: {
        	t: 'Filtros de búsqueda',
            hour: 'Hora',
            availability: 'Disponibilidad',
            age: 'Edad del asesor',
            gender: 'Género',
            type: 'Tipo de asesoría',
            mode: 'Modalidad de asesoría',
            any: 'Ambas',
            all: 'Todos'
        }
	},
	scheduleLesson: { // Component schedule lesson
        subjectst: 'Materias:',
        seeAll: 'Ver todas',
        btn: 'Agendar asesoría',
        btnNext: 'Continuar',
        seats: 'estudiantes',
        subjects: {
        	t: 'Selección de materia',
        	in : 'EN',
        	search: 'Buscar materia ...',
        	allAreas: 'Todos los departamentos'
        },
        date: {
        	t: 'Selección de día y hora',
        	hour: 'Seleccionar hora'
        },
        place: {
        	t: 'Selección de lugar',
        	prev: 'Día y hora'
        },
        summary: {
        	t: 'Resumen de solicitud',
        	lesson: 'asesoría',
        	btn: 'Enviar solicitud'
        },
        msgSuccess: {
        	t: '¡La petición de asesoría ha sido enviada al asesor!',
        	descrip: 'Recibirás una confirmación del asesor muy pronto.',
        	details: 'Detalles de la asesoría',
        	btn: 'Ingresar al chat',
        	btnPrev: 'Ver todas mis asesorías'
        },
        msgError: {
        	t: '¡Oops!',
        	descrip: 'Lo sentimos, sucedió un inconveniente de nuestro lado, por favor intente nuevamente.',
        	btn: 'Regresar'
        }
	},
    room:{
    	onboarding: {
    		btnSettings: 'Comprobar audio y video',
            btnJoin: 'Unirse ahora',
            header:{
                btnExit: 'Salir del salón virtual'
            },
            lessonDetail:{
                t: 'Detalle de la asesoría'
            }
    	},
        btnY: 'Sí',
        btnN: 'No',
        formatDateOnboarding: '{date} de {initialTime} a {endTime}',
        alertsOnboarding: {
            btnYes: 'Continuar sin acceso',
            btnNo: 'Intentar de nuevo',
            btnClose: 'Cerrar',
            btnTryAgain: 'Entendido',
            permissions: {
                t: '¿Estás seguro de que deseas continuar sin micrófono o cámara?',
                d: "<p class='descrip mb-0'>Si no habilitas el acceso a tus dispositivos, no podrás comunicarte de manera efectiva con los demás participantes.</p>"
            },
            microphone: {
                t: '¿Estás seguro de que deseas continuar sin audio?',
                d: "<p class='descrip mb-0'>Si tu micrófono no funciona correctamente, los demás participantes tendrán dificultades para escucharte.</p>"
            },
            audioOuput: {
                t: '¿Estás seguro de que deseas continuar sin sonido?',
                d: "<p class='descrip mb-0'>Si tus bocinas o audífonos no funcionan correctamente, tendrás dificultades para escuchar a los demás participantes.</p>"
            },
            camera: {
                t: '¿Estás seguro de que deseas continuar sin cámara?',
                d: "<p class='descrip mb-0'>Si tu cámara no funciona correctamente, los demás participantes no podrán verte.</p>"
            },
            meetingEnded: {
                t: '¡Atención!',
                d: 'Hemos detectado que ningún otro participante se ha unido al salón virtual en los últimos 30 minutos. ¿Deseas continuar dentro del salón virtual?',
                btnY: 'Sí',
                btnN: 'Salir del salón virtual'
            }
        },
        permissions: {
            cheking: 'Verificando permisos del navegador...',
            gifLink: '/media/gifs/es-mx/',
            gifAlt: 'Autoriza los permisos de cámara y micrófono.',
            btnSeeArticle: {
                link: 'https://ayuda.linc.io/article/195-salon-virtual-desbloquear-permisos-de-camara-microfono-del-navegador',
                txt: 'Ver artículo de ayuda'
            },
            btnNextWithoutPermissions: 'Continuar sin permisos',
            prompt: {
                t: 'Autoriza los permisos del navegador',
                d: 'Linc necesita acceso a tu cámara y micrófono para que los otros participantes te vean y te oigan. Tendrás que confirmar esta decisión en cada navegador y computadora que utilices.'
            },
            block: {
            	t: 'Tu cámara y micrófono están bloqueados',
            	d: 'Linc necesita acceder a tu cámara y micrófono. Haz clic en el icono de cámara bloqueada en la barra de direcciones del navegador, permite el acceso y actualiza la página.'
            },
            empty: {
                t: 'No se encontró ninguna cámara/micrófono',
                d: 'Verifica la configuración del sistema para asegurarte de que haya una cámara y micrófono disponibles. De lo contrario, conecta una. Es posible que luego debas reiniciar tu navegador.'
            },
            errorSystem: {
                t: "No es posible utilizar la cámara y micrófono",
                d: 'Puede que el navegador no tenga acceso a la cámara y micrófono. Para solucionar el problema, abre Preferencias del Sistema.'
            },
            errorDevice: {
                t: "Falló la cámara y micrófono",
                d: 'Verifica la configuración del sistema para asegurarte de que haya una cámara y micrófono disponibles. Es posible que luego debas reiniciar tu navegador.'
            },
            config: { // Remover
                t: 'Autoriza los permisos del navegador',
                d: 'Necesitas autorizar los permisos de cámara y micrófono solicitados por tu navegador para ingresar al salón virtual.'
            },
            msg: 'No tienes permisos para ingresar a este salón.',
            msgFinish: "El salón virtual se cerrará automáticamente en {time} minuto.",
            titleFinish: "La asesoría ha finalizado",
            alert: {
                t: 'Haga clic en "Permitir" arriba',
                msg: 'para otorgar acceso a la cámara y micrófono.',
                aTxt: 'Ver artículo de ayuda',
                aLink: 'https://ayuda.linc.io/article/193-salon-virtual-permitir-permisos-de-camara-microfono-del-navegador'
            } 
        },
        tips: {
            tip: 'TIP',
            btnBack: 'Regresar',
            promptPermissions:{
                tip1: 'Deberás refrescar la página después de autorizar los permisos.'
            },
            microphone: {
                tipsT: '¿El micrófono no funciona?',
                tipsD: 'Verifica la configuración de entrada de sonido en las preferencias del sistema.',
                tip1: 'Asegúrate de que el volumen del micrófono esté alto.',
                tip2: 'Intenta actualizar tu navegador a la última versión y reinícialo.',
                tip3: 'Intenta acercarte al micrófono y reduce cualquier ruido de fondo.',
                tip4: '¿Usas audífonos? Prueba con un cable diferente o conéctalo a un puerto diferente.'
            },
            audioOuput: {
                tipsT: '¿El sonido no funciona?',
                tipsD: 'Asegúrate de que los audífonos o cualquier bocina externa se encuentren encendidos.',
                tip1: 'Asegúrate de que tu sonido no esté silenciado.',
                tip2: 'El uso de audífonos durante la sesión ayuda a reducir el ruido exterior y los ecos.',
                tip3: 'El uso de audífonos durante la sesión ayuda a reducir el ruido exterior y los ecos.',
                tip4: 'Intenta actualizar tu navegador a la última versión y reinícialo.'
            },
            camera: {
                tipsT: '¿La cámara no funciona?',
                tipsD: 'Verifica la configuración de la cámara en las preferencias del sistema.',
                tip1: 'Asegúrate de que la cámara esté enchufada y que nada la bloquee.',
                tip2: 'Verifica nuevamente que la cámara esté enchufada y que nada la esté bloqueando.',
                tip3: 'Intenta actualizar tu navegador a la última versión y reinícialo.',
            }
        },
        microphone: {
            config: {
                t: 'Prueba de micrófono',
                d: 'Asegúrate de que tu micrófono esté enchufado y conectado antes de comenzar.',
                q: '¿Se está moviendo el medidor de audio?',
                btnN: 'Continuar sin micrófono',
            },
            audioLvl: 'Medidor de audio',
            select: {
                label: 'Selecciona tu micrófono'
            },
            alerts: {
                denied: {
                    issue: 'Tu micrófono está bloqueado',
                    info: 'Linc necesita acceder a tu micrófono. Haz clic en el icono de cámara bloqueada en la barra de direcciones del navegador, permite el acceso y actualiza la página.'
                },
                fail: 'Fallo del micrófono',
                empty: {
                    issue: 'No se encontró ningún micrófono',
                    info: 'Verifica la configuración del sistema para asegurarte de que haya un micrófono disponible. De lo contrario, conecta uno. Es posible que luego debas reiniciar tu navegador.'
                },
                mute: 'El micrófono está desactivado',
                change: '{mic} conectado',
                errorSystem: {
                    issue: 'No es posible utilizar el micrófono',
                    info: 'Puede que el navegador no tenga acceso al micrófono. Para solucionar el problema, abre Preferencias del Sistema.'
                },
                errorDevice: {
                    issue: "Falló el micrófono",
                    info: 'Verifica la configuración del sistema para asegurarte de que haya un micrófono disponible. Es posible que luego debas reiniciar tu navegador.'
                }
            }
        },
        voiceFocus: {
            active: 'Activar',
            block: 'Desactivar',
            alert: 'Lorem ipsum dolor sit amet'
        },
        audioOuput:{
            btnTest: 'Probar',
            playingTestSound: 'Reproduciendo...',
            config: {
                t: 'Prueba de sonido',
                q: '¿Puedes escuchar el sonido?',
                btnN: 'Continuar sin sonido',
            },
            select: {
                label: 'Selecciona tu altavoz'
            },
            alerts: {
                empty: 'Altavoz predeterminado del sistema',
                denied: 'La bocina está bloqueada'
            }
        },
        camera: {
            you: 'Tú',
            config: {
                t: 'Prueba de cámara',
                q: '¿Puedes verte a ti mismo?',
                btnN: 'Continuar sin cámara',
            },
            select: {
                label: 'Selecciona tu cámara'
            },
            alerts: {
            	loading: 'Iniciando cámara',
                denied: {
                    issue: 'Tu cámara está bloqueada',
                    info: 'Linc necesita acceder a tu cámara. Haz clic en el icono de cámara bloqueada en la barra de direcciones del navegador, permite el acceso y actualiza la página.'
                },
                fail: 'Fallo de la cámara',
                empty: {
                    issue: 'No se encontró ninguna cámara',
                    info: 'Verifica la configuración del sistema para asegurarte de que haya una cámara disponible. De lo contrario, conecta una. Es posible que luego debas reiniciar tu navegador.'
                },
                mute: 'La cámara está desactivada',
                change: '{cam} conectado',
                errorSystem: {
                    issue: 'No es posible utilizar la cámara',
                    info: 'Puede que el navegador no tenga acceso a la cámara. Para solucionar el problema, abre Preferencias del Sistema.'
                },
                errorDevice: {
                    issue: "Falló la cámara",
                    info: 'Verifica la configuración del sistema para asegurarte de que haya una cámara disponible. Es posible que luego debas reiniciar tu navegador.'
                }
            }
        },
        videoQuality: {
            select: {
                label: 'Calidad de video (envío)'
            }
        },
        sharingScreen: {
            alert: {
                t: 'Compartir pantalla no disponible',
			    msg: "Lo sentimos, Tu navegador no permite compartir pantalla. Para compartirla, debes utilizar Chrome o Firefox desde una computadora."
            }
        },
        header:{
            individualType: 'Asesoría individual',
            groupType: 'Asesoría grupal',
            tabUsers: 'Participantes',
            tabChat: 'Chat',
            student: 'Estudiante',
            tutor: 'Asesor',
            duration: 'Duración',
            sharingScreen: {
                local: 'Tu presentación',
                remote: 'Presentación'
            },
            pin:{
                f: 'Fijar en la pantalla',
                nf: 'No fijar'
            }
        },
        toolbar:{
            cam: {
                m: 'Desactivar cámara',
                u: 'Activar cámara'
            },
            mic: {
                m: 'Desactivar micrófono',
                u: 'Activar micrófono',
                spk: '¿Estás hablando? El micrófono está desactivado. Haz clic en él para activarlo.'
            },
            screenShare: {
                m: 'Dejar de compartir pantalla',
                u: 'Compartir pantalla'
            },
            takeAttendance: 'Tomar asistencia',
            exit: 'Salir del salón virtual',
            more: 'Más',
            fullScreen: {
                m: 'Salir de pantalla completa',
                u: 'Pantalla completa'
            },
            help:{
                t: 'Ayuda'
            }
        },
        sessionDetails: {
            btnSessionDetails: 'Detalles de la asesoría',
            typeHtml: '<span class="sd-item-info">Tipo:</span> {type}',
            subjectHtml: '<span class="sd-item-info">Materia:</span> {subject}',
            tutorHtml: '<span class="sd-item-info">Asesor:</span> {tutor}',
            dateHtml: '<span class="sd-item-info">Fecha:</span> {date}',
            timeHtml: '<span class="sd-item-info">Hora:</span> {time}',
            individualType: 'Individual',
            groupType: 'Grupal'
        },
        modalSettings: {
            t: 'Audio y video',
            subT: 'Configuración',
            voiceFocus:{
                t: 'Cancelación de ruido',
                d: 'Elimina los ruidos de fondo automáticamente'
            }
        }
    },
	modals: {
		cancel: {
			tTop: 'Cancelar',
            tDown: 'Asesoría',
            msg: '¿Estás seguro que deseas cancelar la asesoría?',
            legendT: 'El asesor será notificado.',
			legendS: 'El estudiante será notificado.',
            btnA: 'Sí, deseo cancelar la asesoría',
            btnN: "En otro momento",
		},
		confirm: {
            tTop: 'Confirmar',
            tDown: 'Asesoría',
            msg: '¿Estás seguro que deseas confirmar la asesoría?',
            btnA: 'Sí, deseo confirmar la asesoría',
            btnN: 'En otro momento',
		},
		start: {
            tTop: 'Iniciar',
            tDown: 'Asesoría',
            msg: '¿Estás seguro que deseas iniciar la asesoría?',
            btnA: 'Sí, deseo iniciar la asesoría',
            btnN: 'En otro momento',
		},
		finished: {
            tTop: 'Finalizar',
            tDown: 'Asesoría',
            msg: '¿Estás seguro que deseas finalizar la asesoría?',
            btnA: 'Sí, deseo finalizar la asesoría',
            btnN: 'En otro momento',
            btnLeaveRoom: 'Salir del salón virtual sin finalizar asesoría'
		},
		confirmLesson: { // Confirm 1-on-1 lesson (student profile)
            tTop: 'Petición de',
            tDown: 'Asesoría',
            msg: 'Existe una asesoría en espera de su aprobación para iniciar',
            btnA: 'Sí, deseo confirmar el inicio de la asesoría',
            btnN: 'Rechazar petición de inicio de la asesoría',
		},
		attendance: {
            t: 'Asistencia',
            btn: 'Guardar asistencia'
		},
		feedback: {
            t: 'Calificación',
            duration: 'Duración',
            review: 'Comentarios',
            group: 'Asesoría grupal',
            placeholder: 'Escribe tus comentarios ...',
            btn: 'Enviar feedback'
		},
		movil:{
			t: 'Navegador incompatible',
			msg: 'El salón virtual solo se encuentra disponible a través de una computadora.'
		},
		browser: {
			t: 'Navegador no compatible',
			msg: 'La función de compartir pantalla no es compatible con este navegador. Utiliza Chrome para compartir.'
		},
        shareScreen: {
            t: 'Compartir pantalla no disponible',
            msg: 'Solamente 2 participantes pueden compartir su pantalla al mismo tiempo.'
        },
        meetingEndedFromAnotherDevice: {
            t: '¡Atención!',
            msg: 'Ingresaste al salón virtual de la misma asesoría desde otro dispositivo.'
        },
        joinOnboarding: {
            t: 'Tu asesoría ha comenzado',
            tDetail: 'Detalle de la asesoría',
            btnOtherMoment: 'En otro momento'
        },
		/*feedback: {
			duration: 'Duration',
            place: 'Place',
			rt: 'Selecciona los motivos de la calificación',
			placeholder: 'Escribe tus comentarios',
            btn: 'Rate',
			tutor: {
				t: 'Rate the student',
                r1: 'No explica con claridad',
                r2: 'Poco conocimiento del tema',
                r3: 'No fue puntual',
                r4: 'No fue amable',
                r5: 'Conducta inapropiada',
                review: '¿Cómo puede mejorar el tutor?'
			},
			student: {
				t: 'Rate the tutor',
                r1: 'Se distrae en clase',
                r2: 'No intenta aprender',
                r3: 'Sin disponibilidad a la retroalimentación',
                r4: 'Impuntual',
                r5: 'Conducta inapropiada',
                review: '¿Cómo puede mejorar el estudiante?'
			},
			group: {
				t: 'Rate the group',
                r1: 'Se distrae en clase',
                r2: 'No intenta aprender',
                r3: 'Sin disponibilidad a la retroalimentación',
                r4: 'Impuntual',
                r5: 'Conducta inapropiada',
                review: '¿Cómo pueden mejorar los estudiantes?'
			},	
		},*/
		assistants: {
			t: 'Asistentes'
		},
		join: {
			t: 'Estudiantes inscritos'
		},
		place: {
			j: 'Define el lugar',
			t: 'de la asesoría',
			descrip: 'Puedes actualizar el lugar donde se llevará a cabo la sesión presencial. Se les notificará a los estudiantes.',
			placeholder: 'Lugar de la asesoría',
			btn: 'Actualizar lugar'
		},
		topic: {
            j: 'Definir tema',
			descrip: 'Si deseas que el asesor cubra un tema específico, escríbelo a continuación.',
			placeholder: 'Escribe aquí…',
			btn: 'Actualizar tema'
		},
        notification: {
            tHtml: 'Activar <span class="d-block w-100">Notificaciones</span>',
            msgHtml: 'Te informaremos sobre el estado de tus <strong> asesorías</strong> o cuando recibas <strong>mensajes nuevos.</strong>',
            btn : 'Activar',
            btn2: 'Ahora no'
        }
	},
	subjects: { // Subjects
		t: 'Materias asignadas',
		descripHtml: 'El siguiente listado muestra las materias que tu institución educativa te ha asignado para impartir las asesorías. Si desea agregar o <strong>quitar materias</strong>, comunícate con la persona encargada de administrar la plataforma dentro de tu institución.',
		tType: 'Tipo',
		tMode: 'Modalidad',
        duration: 'Duración'
	},
	availability: { // Availability
		/*t: 'Disponibilidad',
		t1: 'disponibilidad',
		descrip: 'Administre su disponibilidad semanal a continuación.',
		btnAdd: 'Agregar bloque de hora',
		btnSave: 'Actualizar disponibilidad',
		vatationsMode: 'Modo vacaciones',
        disableDay: 'Deshabilitar día',*/
        btn: {
            cancel: 'Cancelar',
            more: 'más'
        },
        vacationMode:{
            t: 'Modo vacaciones activado',
            d: 'Desactívalo utilizando el switch ubicado en la esquina superior derecha.'
        },
        toolbar: {
            prevSubjects: 'Volver a materias asignadas',
            prevCalendar: 'Volver a disponibilidad general',
            vacationMode: 'Modo vacaciones',
            editBySubjects: 'Personalizar por materia'
        },
        calendar: {
            t: 'Define tu disponibilidad',
            d: 'Utiliza el calendario para definir los horarios en los cuales puedes impartir las asesorías.',
            week: 'Semana',
            month: 'Mes'
        },
        customize: {
            t: 'Define tu disponibilidad por materia',
            d: 'Opcionalmente, define horarios y lugares específicos para impartir cada una de tus materias.',
            tAS: 'Materias asignadas',
            availability: 'Horarios específicos',
            places: 'Lugares específicos',
            placeholderSearchSubject: 'Buscar materia',
            placeholderSearchPlace: 'Buscar lugar',
            subject: 'Materia',
            filters:{
                duration: 'Duración',
                both: 'Ambas'
            },
            btnCustomize: 'Personalizar',
            msgNotFound: 'No se encontraron materias.',
            type: 'Tipo',
            mode: 'Modalidad',
            specificScheduling: {
                t: 'Horarios específicos',
                btnClear: 'Borrar horarios'
            },
            specificPlaces: {
                t: 'Lugares específicos',
                btnClear: 'Borrar lugares seleccionados',
                btnSave: 'Guardar',
                filterSelected: 'Seleccionados primero',
                msgNotFound: 'No se encontraron lugares.'
            }
        },
        modals:{
            vacationMode: {
                t: 'Modo vacaciones',
                d1: 'Activar el modo de vacaciones te excluirá de la oferta de asesores y evitará que los estudiantes puedan agendar asesorías contigo. Puedes desactivar el modo vacaciones en cualquier momento.',
                d2: 'Al desactivar el modo vacaciones estarás disponible nuevamente en la oferta de asesores y los estudiantes podrán agendar asesorías contigo.',
                btnAvailable: 'Desactivar modo vacaciones',
                btndisabled: 'Activar modo vacaciones'
            },
            availability: {
                t1: 'Editar la disponibilidad',
                t2: 'Aplicar a múltiples...',
                d: 'Gestiona tu disponibilidad a continuación.',
                msgChanges: '¿Estás seguro de que deseas cerrar sin guardar los cambios?',
                tCustom: '¿Invalidar la disponibilidad en fechas específicas?',
                msgCustomHtml: `<p class="cgh-msg w-100 mb-0">Se ha definido disponibilidad personalizada en {totalDates} fechas específicas que caen en {dates}.</p>
                                <p class="cgh-msg w-100 mb-4">¿Te gustaría invalidar estas fechas?</p>`,
                btnYes: 'Sí',
                btnNo: 'No',
                btnDontOverride: 'No invalidar',
                btnOverride: 'Invalidar',
                and: 'y',
                from: 'De',
                to: 'A',
                unavailable: 'No disponible',
                btnAdd: 'Añadir intervalo',
                btnUnavailable: "No disponible",
                btnClear: 'Borrar',
                btnApplySpecificDay: 'Aplicar solo el {date}',
                btnApplyToAll: 'Aplicar a todos los {day}',
                btnApplyToMultiple: 'O aplicar a múltiples...',
                btnRadioDays: 'días repetidos de la semana',
                btnRadioCalendar: 'fechas específicas',
                selectedDays: '{cont} fechas seleccionadas',
                btnClear: 'Borrar',
                btnApply: 'Aplicar',
            },
            specificScheduling: {
                t: 'Editar Disponibilidad',
                d: 'Gestiona tu disponibilidad a continuación.',
                msgChanges: '¿Estás seguro de que deseas cerrar sin guardar los cambios?',
                checkboxesDays:{
                    su: 'DO',
                    mo: 'LU',
                    tu: 'MA',
                    we: 'MI',
                    th: 'JU',
                    fr: 'VI',
                    sa: 'SA'
                },
                t1: '¿Estás seguro de que deseas borrar todos sus horarios para esta materia?',
                msg1: 'Se borrarán todos los bloques de tiempo personalizados en cada día de la semana.',
                btnY: 'Sí, borrar horarios personalizados'
            },
            specificPlaces: {
                t1: '¿Estás seguro de que deseas borrar todos los lugares seleccionados para esta materia?',
                msg1: 'Los estudiantes podrán seleccionar cualquier lugar de lista al agendar asesorías individuales.',
                btnY: 'Sí, borrar lugares personalizados'
            }
        }
    },
	bio: { // Biografhy
		t: 'Biografía profesional',
        placeholder: 'Escribe tu biografía aquí ...',
		descrip1: 'La biografía profesional se encuentra visible para todos los estudiantes dentro del',
		descrip2: 'perfil de asesor.',
		descrip3: 'Debe de ser corta y tiene como objetivo dar a conocer un poco más acerca de ti.',
		btnSave: 'Guardar cambios'
	},
	profile: { // Tutor profile
		about: 'Biografía',
		availability: 'Disponibilidad',
		subjects: 'Materias',
		reviews: 'Opiniones',
		btnEdit: 'Editar',
		btnMore: 'Ver más',
		btnLess: 'Ver menos',
		btnScheduleShort: 'Agendar',
        btnScheduleLarge: 'Agendar asesoría',
		seeProfile: 'Ver perfil del asesor',
		hideProfile: 'Esconder perfil del asesor'
	},
	chat: { // Chat
        tp: 'Asesoría pendiente',
        pending: 'Pendiente',
        tu: 'Asesoría confirmada',
        upcoming: 'Confirmada',
        to: 'Asesoría en curso',
        ongoing: 'En curso',
        tf: 'Asesoría finalizada',
        tc: 'Asesoría cancelada',
        selectAChat: 'Selecciona una asesoría para ingresar al chat',
        placeholder: 'Escribe tu mensaje ...',
        memmers: 'Estudiantes',
        attachFile: 'Compartir archivos',
        emoji: 'Emojis',
        download: 'Descargar',
        cancelDownload: 'Cancelar Descarga',
        dragFiles: 'Arrastra archivos aquí',
        btnClose: 'Cancelar',
        fileTooBig: 'El archivo no debe pesar más de 10 MB',
        unsupportedFileType: 'El formato del archivo no está permitido',
        fileNotFound: 'Archivo eliminado o no encontrado',
        listChatsEmpty: {
            s: 'Agenda una asesoría para comunicarte con tus asesores',
            t: 'Los chats de sus asesorías aparecerán aquí tan pronto como reciba nuevas solicitudes'
        },
        emojiPicker: {
            search: "Buscar",
            activity: "Actividad",
            flags: "Banderas",
            foods: "Alimentos y bedidas",
            frequently: "Usados frecuentemente",
            objects: "Objetos",
            nature: "Animales y naturaleza",
            peoples: "Caras y personas",
            symbols: "Símbolos",
            places: "Viajes y lugares"
        }
	},
	notifications: {
        t: 'Notificaciones',
        btnBack: 'Regresar',
        status: {
            pending: {
                t: 'Pendiente',
                tooltip: {
                    t: 'Todavía no aceptas la solicitud para impartir esta asesoría.',
                    s: "El asesor aún no ha aceptado la solicitud para impartir esta asesoría."
                }
            },
            upcoming: {
                t: 'Confirmada',
                tooltip: {
                    t: 'Has aceptado la solicitud para impartir esta asesoría.',
                    s: 'El asesor ha aceptado la solicitud para impartir esta asesoría.'
                }
            },
            ongoing: {
                t: 'En curso',
                tooltip: {
                    t: 'Esta asesoría se encuentra en curso.',
                    s: 'Esta asesoría se encuentra en curso.'
                }
            },
            canceled: {
                t: 'Cancelada',
                tooltip: {
                    t: 'Esta asesoría fue cancelada.',
                    s: 'Esta asesoría fue cancelada.'
                }
            },
            finished: {
                t: 'Finalizada',
                tooltip: {
                    t: 'Ya has finalizado esta asesoría.',
                    s: 'El asesor ya ha finalizado esta asesoría.'
                }
            }
        },
        settings:{
            checkAll: 'Marcar todas como leídas',
            seeAll: 'Ver todas'
        },
        type:{
            // Student
            US : { 
                t: 'Asesoría confirmada'
            },
            PS : {
                t: 'Petición de inicio de asesoría'
            },
            OS : {
                t: 'Asesoría iniciada'
            },
            CR: {
                t: 'Asesoría rechazada'
            },
            CS: {
                t: 'Asesoría finalizada '
            },
            // Tutor
            PN: {
                t: 'Solicitud de asesoría'
            },
            PR: {
                t: 'Petición de inicio de asesoría rechazada'
            },
            PA: {
                t: 'Asesoría iniciada'
            },
            CA: {
                t: 'Asesoría finalizada'
            },
            CE: {
                t: 'Asesoría finalizada'
            },
            RT: {
                t: 'Recordatorio de asesoría no iniciada'
            },
            RO: {
                t: 'Recordatorio de finalización de asesoría'
            },
            // Both
            CC: {
                t: 'Asesoría cancelada'
            },
            AP: {
                t: 'Asesoría cancelada'
            },
            AU: {
                t: 'Asesoría cancelada'
            },
            RU: {
                t: 'Recordatorio de asesoría próxima'
            }
        },
        alerts:{
            empty: '¡Vaya! Por el momento no tienes ninguna notificación por ver',
            select: 'Selecciona una notificación'
        },
        time: {
        	recent: 'Hace un momento',
        	minuteAgo: 'Hace 1 min',
	        minutesAgo: 'Hace {time} mins',
	        hourAgo: 'Hace 1 hr',
	        hoursAgo: 'Hace {time} hrs',
	        dayAgo: 'Hace 1 día',
	        daysAgo: 'Hace {time} días',
	        weekAgo: 'Hace 1 semana',
	        weeksAgo: 'Hace {time} semanas',
	        monthAgo: 'Hace 1 mes',
	        monthsAgo: 'Hace {time} meses',
	        yearAgo: 'Hace 1 año',
	        yearsAgo: 'Hace {time} años'
        }    
    },
	moreInfo: { // More info
		infoPendingT: 'Solicitudes de asesorías que requieren de su confirmación.', // Pending
		infoPendingS: 'Solicitudes de asesorías en espera de la confirmación de los asesores.', // Pending
		infoOngoingT: 'Asesorías que se encuentran en curso.', // Ongoing
		infoOngoingS: 'Asesorías que se encuentran siendo impartidas.', // Ongoing
		infoUpcomingT: 'Asesorías que has confirmado para impartir.', // Upcoming
		infoUpcomingS: 'Asesorías que se encuentran confirmadas para ser impartidas por los asesores.', // Upcoming
		infoStatistics: 'Estadísticas de tu actividad como asesor.', // Statistics
		infoSLastesSubjects: 'Listado de las últimas materias en las que has solicitado asesorías.', // Latest subjects 
		infoSLastesTutors: 'Listado de los últimos asesores con los que has agendado asesorías.', // Latest Tutors 
		infoVacationsMode: 'Al activar esta opción dejarás de aparecer en la oferta de asesores por lo que no te llegarán nuevas solicitudes de asesorías.',
        infoDisableDay: 'Al activar esta opción dejarás de aparecer en la oferta de asesores por lo que no te llegarán nuevas solicitudes de asesorías.'
	},
    formats: {
        tooltip: {
            t: '¿Eres docente de tu institución educativa?',
            txt: 'Por favor omite los formatos que se piden a continuación y comunícate con el Departamento de Desarrollo Académico para obtener acceso al perfil de asesor dentro de la plataforma de Linc',
        },
        t: '¡Te damos la bienvenida {name}!',
        dHtml: 'Linc es la plataforma que utilizarás para gestionar tu actividad tutorial y solicitar asesorías académicas en caso de requerirlo. De acuerdo al manual del tutor, <span class="bold">es necesario llenar los siguientes formatos antes de continuar:</span>',
        btnToCoplete: 'Completar',
        completed: 'Completado',
        consult: 'Consultar',
        qualityResult: {
            t: 'CALIFICACIÓN GENERAL',
            low: 'MUY BAJO'
        },
        fm1: {
            t: 'Ficha de identificación',
            d: 'Este documento es confidencial, tus respuestas serán utilizadas para mejorar el servicio que puede brindar la institución. Por lo que se te pide contestar todas las preguntas con la mayor sinceridad.',
            date: 'Fecha de llenado:',
            generalDatas: 'DATOS GENERALES',
            form: {
                btn: 'Enviar ficha',
                btnPhoto: 'Subir foto de perfil',
                career: {
                    label: 'Carrera',
                    placeholder: 'Selecciona tu carrera'
                },
                period: {
                    label: 'Semestre',
                    placeholder: 'Selecciona tu semestre'
                },
                noControl: 'No. control',
                lastName: 'Apellido paterno',
                motherLastName: 'Apellido materno',
                name: 'Nombre(s)',
                gender: {
                    label: 'Género',
                    placeholder: 'Selecciona tu género'
                },
                email: 'Correo electrónico',
                addressPhone: 'Teléfono domicilio',
                phone1: 'Celular 1',
                phone2: 'Celular 2',
                birthday: 'Fecha de nacimiento',
                age: 'Edad',
                placeOfBirth: 'Lugar de nacimiento',
                civilStatus: {
                    label: 'Estado civil',
                    placeholder: 'Selecciona tu estado civil'
                },
                address: 'Domicilio actual',
                scholarship: {
                    label: 'Escolaridad',
                    placeholder: 'Selecciona tu escolaridad'
                },
                schoolName: 'Nombre de la escuela',
                average: 'Promedio obtenido',
                onScholarship: '¿Has estado becado?',
                typeOfScholarship: {
                    label: 'Tipo de beca recibida',
                    placeholder: 'Selecciona un tipo de beca'
                },
                institutionName: 'Nombre de la institución',
                job: '¿Trabajas?',
                institutionJob: 'Nombre de la empresa',
                jobSchedule: 'Horario de trabajo',
                liveWith: {
                    label: 'En el transcurso de tus estudios vivirás con …',
                    placeholder: 'Selecciona una opción'
                },
                supportPerson: 'En caso de accidente avisar a',
                supportPhone: 'Teléfono del responsable',
                dadScholarship: {
                    label: 'Máximo grado de escolaridad de tu padre',
                    placeholder: 'Selecciona un grado de escolaridad'
                },
                momScholarship: {
                    label: 'Máximo grado de escolaridad de tu madre',
                    placeholder: 'Selecciona un grado de escolaridad'
                },
                dadStatus: 'Actualmente tu padre',
                momStatus: 'Actualmente tu madre',
                addressJobDad: 'Nombre o lugar de trabajo de tu padre',
                addressJobMom: 'Nombre o lugar de trabajo de tu madre'
            }
        },
        fm2: {
            t: 'Encuesta sobre las habilidades de estudio',
            dHtml: `<p class="v-formt-txt mb-sm-4 mb-3">Instrucciones: La presente encuesta está formada por tres breves cuestionarios, en los cuales puedes indicar los problemas referentes a organización, técnicas y motivación en el estudio, que quizá perjudican tu rendimiento académico. Si contestas todas las preguntas con sinceridad y reflexión podrás identificar mucho de tus actuales defectos el estudiar.</p>
                    <p class="v-formt-txt mb-sm-4 mb-3">Cada cuestionario contiene veinte preguntas, a las que se contestará con sí o no.</p>
                    <p class="v-formt-txt mb-0">No hay respuestas "correctas" o "incorrectas", ya que la contestación adecuada es tu juicio sincero sobre tu modo de actuar y tus actitudes personales, respecto al estudio. Responde tan rápido como puedas. Pero son caer en el descuido, y no dediques demasiado tiempo a una sola pregunta. No omitas ninguna de ellas.</p>`,
            date: 'Fecha de llenado:',
            name: 'Nombre:',
            semester: 'Semestre:',
            email: 'Correo:',
            noControl: 'No. control:',
            sub1: 'ENCUESTA PARA ORGANIZACIÓN DEL ESTUDIO',
            sub2: 'ENCUESTA SOBRE TÉCNICAS DE ESTUDIO',
            sub3: 'ENCUESTA SOBRE MOTIVACIÓN PARA EL ESTUDIO',
            tableT: 'Preguntas',
            scaleT: 'Puntaje',
            tableAYes: 'SI',
            tableANo:'NO',
            quality: 'CALIFICACIÓN',
            btn: 'Enviar Encuesta'
        },
        fm3: {
            t: 'Inventario sobre estilos de aprendizaje',
            btn: 'Enviar Inventario',
            dHtml: `<p class="v-formt-txt mb-sm-4 mb-3">Este inventario es para ayudarle a descubrir se manera preferida de aprender. Cada persona tiene su manera preferida de aprender. Reconocer sus preferencias le ayudará a comprender sus fuerzas en cualquier situación de aprendizaje.</p>
                    <p class="v-formt-txt mb-sm-4 mb-3">Por favor, responda verdaderamente a cada pregunta. Responda según lo que hace actualmente, no según lo que piense que sea la respuesta correcta.</p>
                    <p class="v-formt-txt mb-0">Use la escala siguiente para responder a cada pregunta:</p>`,
            qstT: {
                q1: '1 = Nunca',
                q2: '2 = Raramente',
                q3: '3 = Ocasionalmente',
                q4: '4 = Usualmente.',
                q5: '5 = Siempre'
            },
            result: 'RESULTADOS'
        },
        fm4: {
            t: 'Determinación de problemas emocionales (test de autoestima)',
            tHtml: 'Determinación de problemas emocionales <br> TEST DE AUTOESTIMA',
            dHtml: '<p class="v-formt-txt mb-0">Instrucciones: Realiza el siguiente test para evaluar y comprobar tu nivel de autoestima. Contesta con la mayor sinceridad posible a cada una de las siguientes preguntas eligiendo la respuesta que más se identifique con tu forma de pensar o de actuar.</p>',
            btn: 'Enviar Test',
            r1: 'Tienes un nivel algo bajo de autoestima y se nota en la valoración que haces de ti mismo, de tu trabajo y de tu forTienes tuna en la vida. Una de las razones por las que percibimos más las cosas negativas es que estas son más destacables que las positivas. En la escuela o de niños en casa, siempre nos regañaban por lo malo pero se olvidaban de felicitamos por lo bueno. Para elevar el nivel de nuestra autoestima es necesario aceptarse tal como uno es y valorar más lo que somos y lo que tenemos. A veces puede parecer difícil, pero si practicas unos minutos diarios a pensar en las cosas positivas que tienes, irás poco a poco queriéndote más.',
            r2: 'Tu nivel de autoestima es suficiente pero más a menudo de lo que te gustaría, te falla y te abandona. Los sucesos negativos que nos pasan absorben más nuestros sentidos pues son más desagradables que las cosas positivas, por ello les damos más importancia de la que merecen y no nos fijamos en lo bueno con igual intensidad. Todas las personas tenemos cosas positivas y todos cometemos errores o tenemos días flojos. La clave está en darle a cada cosa el justo valor que tiene, ver los errores como maneras de aprendizaje y reconocer las cosas buenas que poseemos. También podemos aprender a querernos a nosotros mismos cuidándonos con más mimo y dándonos gustos.',
            r3: 'Tu nivel de autoestima es muy bueno, sabes dar a las cosas el valor que merecen, reconoces lo bueno y no te dejas amilanar fácilmente por las adversidades. Eres una persona sensata y realista que tiene confianza en sí misma, tus objetivos no son irrealizables y te gusta luchar para conseguirlos. Has aprendido que las cosas no salen siempre como quieres ni cuando quieres, que todo requiere un esfuerzo y que es normal equivocarse. Cuando tienes un error procuras aprender lo que este te enseña y a evitarlo en futuras ocasiones. Un buen nivel de autoestima nos hace tener más ganas de luchar por las cosas, nos ayuda a no desistir en nuestro empeño de lograr algo y hace que la vida nos sea más amable y agradable.',
            r4: 'Tienes un alto nivel de autoestima y mucha confianza y seguridad en ti mismo. Ambos sentimientos son muy positivos y necesarios para conseguir mucho más de lo que nos proponemos, sin embargo, es preciso ser cautelosos. Al igual que una baja autoestima, un exceso de esta puede hacernos perder la objetividad de nuestras acciones, hacernos creer demasiado buenos en algo, y si sobreviene una decepción o un fracaso, hacernos sufrir más de lo razonable. También si nos creemos demasiado especiales podemos hacer daño a los demás minusvalorando su esfuerzo o no apreciándolo en lo que vale. Siempre conviene tener una dosis de modestia.'
        },
        fm5: {
            t: 'Test de Asertividad',
            dHtml: '<p class="v-formt-txt mb-0">Instrucciones: Selecciona el numero de la opción con la que mejor te identificas.</p>',
            qstT: {
                q1: '1 = Con frecuencia',
                q2: '2 = De vez en cuando',
                q3: '3 = Casi nunca',
                q4: '4 = Nunca'                                          
            },
            btn: 'Enviar Test'
        }
    }
}