
    import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class spinner extends Vue{
        @Prop({ default: '80px' }) readonly height!: String;
		@Prop({ default: '80px' }) readonly width!: String;
		@Prop({ default: () => { return {} } }) readonly options!: any;

		@State(state => state.auth.permission) userType;
		@Getter('userColor') userColor;

		get color(): String{
            if(this.options?.color)
				return this.options.color;
			else
				return this.userColor.light;
		}
    }
