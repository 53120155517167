/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leaf': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.883.178A.5.5 0 0019.501 0h-.51C13.951-.002 9.97-.003 7.039.698c-1.575.377-2.793.946-3.723 1.739-1.004.856-1.688 1.977-2.093 3.429-.269.964-.395 2.705.136 4.437.196.64.502 1.244.91 1.799C1.053 14.007.002 16.516.002 19.501a.5.5 0 001 0c0-4.605 2.728-8.021 4.354-9.646 1.167-1.167 2.486-2.152 3.816-2.848 1.238-.648 2.421-1.005 3.331-1.005a.5.5 0 000-1c-1.084 0-2.396.387-3.794 1.12-1.418.743-2.822 1.789-4.059 3.027a17.933 17.933 0 00-1.774 2.07 4.947 4.947 0 01-.559-1.207c-.5-1.632-.316-3.204-.129-3.875C3.618 1.012 9.05.999 18.905 1.002 17.73 7.29 15.539 11.36 12.389 13.103c-2.922 1.617-5.82.735-6.931.28a5.366 5.366 0 01-.699-.35.5.5 0 00-.511.859c.272.162.552.302.832.417a9.917 9.917 0 003.679.722 8.392 8.392 0 004.115-1.053c1.609-.89 3.001-2.339 4.139-4.304 1.334-2.305 2.338-5.362 2.983-9.086a.5.5 0 00-.11-.407z"/>'
  }
})
