var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-room-header", class: { show: _vm.showChat } },
    [
      _c("div", { staticClass: "v-room-chat d-flex flex-column h-100" }, [
        _c(
          "div",
          { staticClass: "v-actions d-flex" },
          [
            _c("transition", { attrs: { name: "chatHide" } }, [
              !_vm.showChat
                ? _c(
                    "div",
                    {
                      staticClass: "v-sh-chat col-auto pl-0 pr-0 d-flex h-100",
                      on: {
                        click: function($event) {
                          return _vm.$emit("handleChat")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "v-users col-auto pl-2 pr-2 d-flex justify-content-center align-items-center",
                          on: {
                            click: function($event) {
                              _vm.tabChat = "users"
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "v-content-ricon" },
                            [
                              _vm.totalUsers > 0
                                ? _c("div", { staticClass: "v-total-users" }, [
                                    _vm._v(_vm._s(_vm.totalUsers))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("svgicon", {
                                staticClass: "v-ricon",
                                attrs: { name: "users2", color: "#383838" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg-hover" }, [
                            _vm._v(_vm._s(_vm.$t("room.header.tabUsers")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "v-rchat col-auto pl-2 pr-2 d-flex justify-content-center align-items-center",
                          on: {
                            click: function($event) {
                              _vm.tabChat = "chat"
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "v-content-ricon" },
                            [
                              _vm.totalMsgs > 0
                                ? _c("div", { staticClass: "v-total-msgs" }, [
                                    _c("span", [_vm._v(_vm._s(_vm.totalMsgs))])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("svgicon", {
                                staticClass: "v-ricon",
                                attrs: { name: "chat", color: "#383838" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg-hover" }, [
                            _vm._v(_vm._s(_vm.$t("room.header.tabChat")))
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "chatHide" } }, [
              _vm.windowGrid != "movil" ||
              (!_vm.showChat && _vm.windowGrid == "movil")
                ? _c(
                    "div",
                    {
                      staticClass:
                        "v-rduration col-auto pl-0 pr-0 d-flex h-100 justify-content-center align-items-center"
                    },
                    [
                      _c("div", { staticClass: "v-duration text-center" }, [
                        _c("span", { staticClass: "v-duration-title" }, [
                          _vm._v(_vm._s(_vm.$t("room.header.duration")))
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "v-duration-time mb-0",
                          domProps: { innerHTML: _vm._s(_vm.duration) }
                        })
                      ])
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "chatHide" } }, [
              _vm.windowGrid != "movil" ||
              (!_vm.showChat && _vm.windowGrid == "movil")
                ? _c(
                    "div",
                    {
                      staticClass: "v-ruser col-auto pl-0 pr-0",
                      on: {
                        click: function($event) {
                          ;(_vm.lesson.type == "Grupal" &&
                            _vm.remoteRosters.length > 0) ||
                          (_vm.lesson.type != "Grupal" &&
                            (_vm.isSharing || _vm.remoteRosters.length > 0))
                            ? _vm.$emit(
                                "handleCurrentVideo",
                                !_vm.showCurrentVideo
                              )
                            : ""
                        }
                      }
                    },
                    [
                      _vm.currentUser
                        ? _c(
                            "div",
                            { staticClass: "w-100 h-100 position-relative" },
                            [
                              _c("v-room-video", {
                                key: _vm.resetLocalVideo,
                                ref: "video",
                                class:
                                  _vm.statePermission == "student"
                                    ? "bb-blue"
                                    : "bb-green",
                                attrs: {
                                  deviceId: _vm.deviceId,
                                  stream: _vm.stream,
                                  roster: _vm.roster[_vm.currentUser.roster],
                                  user: _vm.currentUser,
                                  onlyRead: true,
                                  muteVideo: _vm.muteVideo,
                                  isLocal: true,
                                  typeLesson: _vm.lesson.type,
                                  height: "50px",
                                  bottomName: "0",
                                  minHeightPhoto: 32
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showChat,
                expression: "showChat"
              }
            ],
            key: "chatAndUsers",
            staticClass: "content-header h-100"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "v-close-chat d-flex justify-content-between align-items-center"
              },
              [
                _c("div", { staticClass: "col-auto" }, [
                  _c("span", { staticClass: "v-rquote" }, [
                    _vm._v(_vm._s(_vm.lessonType))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "button",
                    {
                      staticClass: "v-btn-close",
                      on: {
                        click: function($event) {
                          return _vm.$emit("handleChat")
                        }
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "icon-cross",
                        attrs: { name: "cross" }
                      })
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex tabs-uc" }, [
              _c(
                "button",
                {
                  staticClass: "v-tab-chat col-6 d-flex align-items-center",
                  class: {
                    active: _vm.tabChat == "users",
                    "bb-blue": _vm.statePermission == "student",
                    "bb-green": _vm.statePermission == "tutor"
                  },
                  on: {
                    click: function($event) {
                      _vm.tabChat = "users"
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "v-icon-tab mr-2",
                    attrs: { name: "users2" }
                  }),
                  _vm._v(
                    "\n            \t\t\t\t" +
                      _vm._s(_vm.$t("room.header.tabUsers")) +
                      "\n            \t\t\t"
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "v-tab-chat col-6 d-flex align-items-center",
                  class: {
                    active: _vm.tabChat == "chat",
                    "bb-blue": _vm.statePermission == "student",
                    "bb-green": _vm.statePermission == "tutor"
                  },
                  on: {
                    click: function($event) {
                      _vm.tabChat = "chat"
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "position-relative align-self-end mr-2" },
                    [
                      _vm.totalMsgs > 0
                        ? _c("div", { staticClass: "v-total-msgs" }, [
                            _c("span", [_vm._v(_vm._s(_vm.totalMsgs))])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("svgicon", {
                        staticClass: "v-icon-tab",
                        attrs: { name: "chat" }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n            \t\t\t\t" +
                      _vm._s(_vm.$t("room.header.tabChat")) +
                      "\n            \t\t\t"
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-tabs" },
              [
                _c(
                  "transition",
                  { attrs: { name: "bound" } },
                  [
                    _c("v-room-users", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabChat == "users" && _vm.showChat,
                          expression: "tabChat == 'users' && showChat"
                        }
                      ],
                      attrs: {
                        remoteRosters: _vm.remoteRosters,
                        roster: _vm.roster,
                        currentRoster: _vm.currentRoster,
                        pin: _vm.pin
                      },
                      on: { handlePin: val => _vm.$emit("handlePin", val) }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "bound" } },
                  [
                    _vm.chatIsReady
                      ? _c("v-chat", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tabChat == "chat" && _vm.showChat,
                              expression: "tabChat == 'chat' && showChat"
                            }
                          ],
                          ref: "chat",
                          attrs: {
                            refreshChat: _vm.refreshChat,
                            idLesson: _vm.lessonId,
                            users: _vm.arrayUsers,
                            disabled: _vm.arrayUsers.length == 0,
                            editCounter:
                              _vm.showChat &&
                              _vm.tabChat == "chat" &&
                              _vm.arrayUsers.length > 0
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }