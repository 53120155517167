
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class inputField extends Vue{
        @Prop({ type: String, default: '', required: false }) readonly placeholder!: String;
		@Prop({ type: Boolean, default: false, required: false }) readonly required!: Boolean;
		@Prop({ type: Boolean, default: false, required: false }) readonly disabled!: String;
		@Prop({ type: String, default: 'text', required: false }) readonly type!: String;
		@Prop({ type: String, default: '', required: false }) readonly label!: String;
		@Prop({ type: Boolean, default: false, required: false }) readonly error!: Boolean;
		@Prop({ default: '' }) readonly value!: any;
		@Prop({ type: Object, default: () => { return {} }, required: false }) readonly options!: any;
        @Prop({ default: false, required: false }) readonly db!: boolean;
		
		

		@State( state => state.auth.permission ) userType;
		@State( state => state.i18n.locale.substring(0,2) ) lang;
		@Getter('userColor') userColor;

		focus: Boolean = false;
		val: any = '';
    
	    get moment(): any{
			return (this as any).$moment;
		}

		get isFocus(): Boolean{
            return this.focus && !this.disabled;
		}

		get isActive():Boolean{
			return this.value != '';
		}

		get focusColor(): String{
            if(this.options?.styles?.colorFocus)
				return this.options.styles.colorFocus;
			else
				return this.userColor.main;
		}

		get activeLabel(): Boolean{
            return !!this.options.activeLabel || this.isFocus || this.placeholder != '' || this.isActive;
		}

		get labelStyles(): Object{
            let styles: object = { 
				            color: (this.focus || this.value != '' || this.placeholder != '')?this.focusColor:'#9b9b9b', 
					        fontSize: '1rem',
							lineHeight: '1.2em', 
							fontWeight: '400',
							'--transform': 'translateY(25px)',
							'--transform-active': 'translateY(0px) scale(0.7)'
					    }

				if(this.options?.styles?.label)
				    styles = { ...styles, ...this.options.styles.label };
				
				return styles;
		}

		created(): void{
		    this.val = this.moment(this.value, 'YYYY/MM/DD HH:mm:ss').toDate();	
		}

		handleInput(value): void{
			(this as any).$emit('input', this.moment(this.val).format('YYYY/MM/DD HH:mm:ss'));
		}

		disabledDate(date): any{
			if(this.db)
			    return this.moment(date).isBefore(this.moment().set({hour:0,minute:0,second:0,millisecond:0}));
			else
           		return this.moment(date).isAfter(this.moment().subtract(3,'years'));
		}

		@Watch('value')
		handleVal(newVal: any): void{
            this.val = this.moment(newVal, 'YYYY/MM/DD HH:mm:ss').toDate();
		}
	}
