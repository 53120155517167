var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "content-img",
    style: {
      background: `url(${_vm.img})center center / cover no-repeat`,
      width: _vm.size,
      height: _vm.size
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }