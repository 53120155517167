/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-right-square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 18.5v-16C0 1.673.673 1 1.5 1h16c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5h-16C.673 20 0 19.327 0 18.5zm18-16a.5.5 0 00-.5-.5h-16a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-16z"/><path pid="1" d="M6.5 15.5c0-.128.049-.256.146-.354l4.646-4.646-4.646-4.646a.5.5 0 01.707-.707l5 5a.5.5 0 010 .707l-5 5A.5.5 0 016.5 15.5z"/>'
  }
})
