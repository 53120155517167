/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compressed': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M14.5 19h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM1.5 1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z" _fill="#000" fill-rule="nonzero"/><g transform="translate(2)"><path pid="1" _fill="#000" d="M1.467 0h1.067v12.6H1.467z"/><path pid="2" d="M2.316 12.45l.38.798-.632.3-.38-.798.632-.3z" _stroke="#000" stroke-width=".7"/><rect pid="3" _fill="#000" y="2.8" width="4" height="1" rx=".28"/><rect pid="4" _fill="#000" y="4.368" width="4" height="1" rx=".28"/><rect pid="5" _fill="#000" y="5.936" width="4" height="1" rx=".28"/><rect pid="6" _fill="#000" y="7.504" width="4" height="1" rx=".28"/><rect pid="7" _fill="#000" y="9.072" width="4" height="1" rx=".28"/><rect pid="8" _fill="#000" y="10.64" width="4" height="1" rx=".28"/></g></g>'
  }
})
