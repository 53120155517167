<template>
	<div id="tutoring-lesson-alert">
		<!-- ================ Search ================== -->
		<v-search class="mb-4" ref="search"></v-search>

		<!-- ============ Message ============== -->
		<section class="container-fluid pb-4">
			<div class="pt-4 pb-4 pt-sm-5 pb-sm-5 pl-3 pr-3 pl-sm-4 pr-sm-4 paper row justify-content-center">
                <!-- ============ Success ============== -->
                <div class="lesson-success w-100" v-if="lessonInfo.status=='success'">
                    <!-- =========== Head =========== -->
                    <div class="ls-title mb-4 position-relative">
                        <svgicon class="ls-title-icon" name="checkmark-circle-fill" original/>
                        <h3 class="ls-title-msg mb-2">{{ $t('scheduleLesson.msgSuccess.t') }}</h3>
                        <p class="ls-title-descript mb-0">{{ $t('scheduleLesson.msgSuccess.descrip') }}</p>
                    </div>

                    <div class="ls-info p-3 p-sm-4 w-100 mb-3">
                        <div class="pl-sm-3 pr-sm-3">
                            <h3 class="ls-info-title mb-4 pb-sm-2 text-center text-sm-left">{{ $t('scheduleLesson.msgSuccess.details') }}</h3>

                            <div class="row ml-n2 mr-n2">
                                <div class="col-12 col-sm-auto pl-2 pr-2 mb-3">
                                    <v-lesson-photo class="ml-auto mr-auto" :options="{ photo: lessonInfo.lesson.tutor.photo, size: '80px' }" />
                                </div>
                                <div class="col pl-2 pr-2">
                                    <div class="row mb-4">
                                        <div class="col-lg-5 mb-2 mb-lg-0">
                                            <div class="row">
                                                <p class="col-12 v-lesson-tutor col-auto mb-2">{{ 
                                                lessonInfo.lesson.tutor.name }} {{ lessonInfo.lesson.tutor.last_name }}</p>
                                                <div class="col-12">
                                                    <div class="row ml-n2 mr-n2">
                                                        <div class="col-auto pl-2 pr-2 pb-1 pt-1">
                                                            <v-lesson-tag :type="lessonInfo.lesson.lesson.lesson.type" :options="{ minWidth: '80px', lessonLimit: lessonInfo.lesson.lesson.date.time.places, lessonQuota: lessonInfo.lesson.lesson.date.time.occupied }" />
                                                        </div>
                                                        <div class="col-auto pl-2 pr-2 pb-1 pt-1">
                                                            <v-lesson-tag :type="lessonInfo.lesson.lesson.lesson.modality" :options="{ minWidth: '80px' }" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    <p class="v-lesson-subject mb-2 position-relative">
                                                        <svgicon class="v-ls-icon mr-2" :name="lessonInfo.lesson.lesson.lesson.icon" color="#000"></svgicon>
                                                        {{ lessonInfo.lesson.lesson.lesson.name }}
                                                    </p>
                                                </div>
                                                <div class="col-12 col-md pt-1 pb-1" v-if="lessonInfo.lesson.lesson.lesson.type == 'Individual'">
                                                    <v-lesson-topic :options="{ lessonId: lessonInfo.lesson.lesson.id, topic: lessonInfo.lesson.lesson.topic  }" v-if="lessonInfo.lesson.lesson.lesson.type == 'Individual'"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="v-lesson-date mb-3 position-relative">
                                        <svgicon class="v-ls-icon mr-2" name="calendar" color="#000"></svgicon>
                                        {{ `${setDate} ${setTime}` }}
                                    </p>

                                    <v-lesson-place  class="mb-3" :options="{ icon: lessonInfo.lesson.lesson.place.name?lessonInfo.lesson.lesson.place.icon.icon_name:null, place: lessonInfo.lesson.lesson.place.name?lessonInfo.lesson.lesson.place.name:null, modality: lessonInfo.lesson.lesson.lesson.modality, type: lessonInfo.lesson.lesson.lesson.type }"/>

                                    <div class="row ml-n1 mr-n1 mb-3">
                                        <div class="col-auto pl-1 pr-1">
                                            <v-lesson-btn class="w-100" :options="{ type: 'chat', btnType: 'large', idLesson: lessonInfo.lesson.lesson.id }" />
                                        </div>
                                        <div class="col-auto pl-1 pr-1">
                                            <v-lesson-btn class="w-100" :options="{ type: 'cancel', btnType: 'large', idLesson: lessonInfo.lesson.lesson.id }" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link class="btn-lessons" tag="button" :to="{ name: `lessons-${auth.permission}`}">{{ $t('scheduleLesson.msgSuccess.btnPrev') }} <svgicon class="bl-icon" name="arrow-right" /></router-link>
                    </div>
                </div>
                <!-- ============ Error ============== -->
                <div class="lesson-failed w-100" v-if="lessonInfo.status=='error' | lessonInfo.status == ''">
                	<div class="lf-title d-flex flex-wrap justify-content-center pb-5 mb-5">
                		<svgicon class="icon-alert error mb-3" name="shocked"/>
                		<div class="status-msg w-100 text-center">
                			<h3 class="msg">{{ $t('scheduleLesson.msgError.t') }}</h3>
                		    <p class="descrip mb-0">{{ $t('scheduleLesson.msgError.descrip') }}</p>
                		</div>
                	</div>
                	<div class="d-flex flex-wrap justify-content-center">
                        <v-btn class="w-100" fontSize="16px" max="280px" :txt="$t('scheduleLesson.msgError.btn')" @click.native="$router.push(lessonInfo.route==''?'/student/schedule-lesson':lessonInfo.route)" />
                	</div>
                </div>
			</div>
		</section>
	</div>
</template>
<script>
	import { mapState, mapGetters, mapMutations } from 'vuex';

	export default{
        data(){
            return{
                lessonInfo: {}
            }
        },
		computed:{
			...mapState(['lessonRequest', 'lessonChanges', 'i18n','auth', 'currentLesson']),
            ...mapGetters(['modalVal']),
            setDate(){
                const date = this.$moment(this.lessonInfo.lesson.lesson.date.date).locale(this.i18n.locale).format(this.$t('formatDateC'));
                return date.charAt(0).toUpperCase()+date.slice(1);
            },
            setTime(){
                return `${this.$moment(this.lessonInfo.lesson.lesson.date.time.start, 'HH:mm:ss').format('LT')} - ${this.$moment(this.lessonInfo.lesson.lesson.date.time.end, 'HH:mm:ss').format('LT')}`;
            },
            place(){
                if (this.lessonInfo.lesson.lesson.place.name)
                    return this.lessonInfo.lesson.lesson.place.name;
                else
                    return this.$t('lessons.vistualRoom');
            }
		},
        methods:{
            ...mapMutations(['setRoute','setStatusLesson', 'setLessonRequest'])
        },
        created(){
            this.lessonInfo = { ...this.lessonRequest };
            this.setLessonRequest({ lesson: {}, route: '', status: '' });
        },
        beforeDestroy(){
            if (this.lessonInfo.status == 'success') {
                this.setRoute('');
                this.setStatusLesson('');
            }
        },
        watch:{
            modalVal: function(newVal, oldVal){
                if(oldVal.modal == 'v-modal-crud'){
                    const { lesson } = this.currentLesson;
                    if (oldVal.type === 'cancel' && lesson.id == this.lessonInfo.lesson.lesson.id)
                        this.$router.push({ name: 'dashboard-student' });
                }
            },
            lessonChanges: function(newVal){
                if (newVal) {
                    const { data, lesson } = newVal;
                    if (data == 'topic')
                        this.lessonInfo.lesson.lesson.topic = lesson.val;
                }
            }
        } 
	}
</script>