import svgicon from "vue-svgicon";
import svgs from "./svgs/icons/index.js";
import rangeSlider from "vue-slider-component";
import VCalendar from 'v-calendar';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueLocalStorage from 'vue-localstorage';
import Vuelidate from 'vuelidate';
import hexToRgba from 'hex-to-rgba';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import axios from 'axios';
import rating from 'vue-star-rating';
import scrollTo from "vue-scrollto";
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import fullscreen from 'vue-fullscreen';
import VueProgressBar from 'vue-progressbar';
import * as firebase from './firebase.js';
import VueMeta from 'vue-meta';
import VueCropper from 'vue-cropperjs';
import VueOffline from 'vue-offline';
import FullCalendar from '@fullcalendar/vue';
import VueCarousel from 'vue-carousel';
import VEmojiPicker from 'v-emoji-picker';
import vue2Dropzone from 'vue2-dropzone';
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueHtml2pdf from 'vue-html2pdf';

function fire(Vue){

    //Iconos svg
    Vue.use(svgicon, {
       tagName: 'svgicon',
       classPrefix: 'vue-svg'
    });
    Vue.use(svgs);

    // Vue range slider
    Vue.component('range-slider',rangeSlider)

    // Calendar
    Vue.use(VCalendar);

    // Scroll bar
    Vue.use(PerfectScrollbar);

    // locale storage
    Vue.use(VueLocalStorage);

    // vuelidate
    Vue.use(Vuelidate);

    // hex to rgba
    Object.defineProperty(Vue.prototype, '$hexToRgba', { value: hexToRgba });

    // axios
    window.axios = axios;

    // rating
    Vue.component('star-rating', rating);
    
    // Scroll to
    Vue.use(scrollTo,{
        duration: 700,
        easing: 'ease-out',
        offset: -59
    });
    
    // moment
    Vue.use(VueMoment, {
        moment
    });

    // Input date picker
    Vue.component('date-picker', DatePicker);
    
    // Full screen
    Vue.use(fullscreen);

    // Progress bar
    Vue.use(VueProgressBar, {
        thickness: '3px',
        transition: {
            speed: '5s',
            opacity: '0.6s',
            termination: 400
        },
        autoRevert: true,
        location: 'top',
        inverse: false
    });

    // Firebase
    Object.defineProperty(Vue.prototype, '$firebase', { value: firebase });
    
    // Meta datas
    Vue.use(VueMeta, {
        refreshOnceOnNavigation: true
    })

    // Vue crop image
    Vue.component('vue-cropper', VueCropper);

    // Vue online and offline
    Vue.use(VueOffline, { mixin: true, storage: false });

    // FullCalendar
    Vue.component('FullCalendar' ,FullCalendar);

    // Carrousel
    Vue.use(VueCarousel);

    // Emojis
    Vue.use(VEmojiPicker);

    Vue.component('vue-dropzone', vue2Dropzone);

    Vue.use(InlineSvgPlugin);

    Vue.use(VueHtml2pdf);

}

// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(fire)
}

export default fire;