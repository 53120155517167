<template>
	<div class="selected-list-item paper w-100">
		<!-- ============= Title =========== -->
		<h4 class="title-list text-center mb-0">{{ title }}</h4>

		<div class="list-items d-flex flex-column">
			<!-- ============= Search =========== -->
            <div class="list-item-search" :class="{ disabled: disabledSearch && statusSearch }" v-if="statusSearch">
            	<input type="text" v-model="valueSearch" :disabled="disabledSearch && statusSearch" :placeholder="$t('scheduleLesson.subjects.search')"/>
            </div>

            <!-- ============= Msg =========== -->
            <div class="content-msg d-flex justify-content-center p-3" v-if="msgEmpty && statusSearch">
            	<p class="msg-list">{{ msgEmpty }}</p>
            </div>

            <!-- ============= List =========== -->
			<perfect-scrollbar class="pl-0 mb-0" ref="scroll" tag="ul" :options="{ wheelSpeed: 1 }" v-show="setListItems.length > 0">
				<li class="btn-list pl-3" v-for="(item,index) in listItems"
				                          @click="send(item); doSomething()" 
				                          :class="{ 'pr-3' : !item[fieldIcon], 'list-item-active' : activeItem === item.id }"  
				                          :key="item.id">
				    <v-hover-txt fontSize="16px" fontWeight="400" :positionCloud="index==0?'top':'bottom'" :txt="item.name" />                     
				    <svgicon class="icon" v-if="item[fieldIcon]" :name="item[fieldIcon]" :color="item.color"/>
				</li>                     
				<div class="lesson-spinner w-100 p-3 text-center" key="spinner" v-if="statusSpinner">
				    <v-spinner height="25px" width="25px" />
				</div>  
			</perfect-scrollbar>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			title: { type: String, default: '', required: true },
			setListItems: { type: Array, default: [], required: true },
			fieldIcon: { type: String, default: 'icon', required: false },
            statusSearch: { type: Boolean, default: false, required: false },
            disabledSearch: { type: Boolean, defaul: false, required: false },
            msgEmpty: { type: String, default: null, required: false },
            statusSpinner: { type: Boolean, default: false, required: false },
            statusSrollEvent: { type: Boolean, default: true, required: false },
			value: ''
		},
		data(){
			return{
				activeItem: '',
				listItems: [],
				valueSearch: '',
				delay: ''
			}
		},
		methods: {
			send(item){
				this.activeItem = item.id;
				this.$emit('input',item);
			},
			doSomething(){
                this.$emit('doSomething');
			},
			scrollEvent(e){
                if (((Math.round(e.target.scrollTop) + this.$refs.scroll.ps.containerHeight) >= this.$refs.scroll.ps.contentHeight - 100) && !this.statusSpinner && this.statusSrollEvent) {
        			this.$emit('changeList', true, this.valueSearch);
        		}
			}
		},
		created(){
			this.listItems = this.setListItems;
		},
        mounted(){
        	this.$nextTick(() => {
        		this.$refs.scroll.$el.addEventListener('ps-scroll-down', this.scrollEvent);
        	});
        },
        beforeDestroy(){
            this.$refs.scroll.$el.removeEventListener('ps-scroll-down', this.scrollEvent);
        },
        watch: {
        	setListItems: function(newVal,oldVal){
                this.listItems = newVal;
        	},
        	valueSearch: function(newVal){ // Return val if input search change
        		var that = this;
        		clearTimeout(this.delay);
        		this.delay = setTimeout( () => {
        			that.$emit('changeList', false, newVal);
        		},700);
        	}
        }
	}
</script>