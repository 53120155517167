const changeStatusSubject = ({ state, commit }, payload) => {
    const i1 = state.assignedSubjects.subjects.findIndex( item => state.cutomizeSubject.idSubject == item.subject_id ),
          i2 = state.assignedSubjects.filterSubjects.findIndex( item => state.cutomizeSubject.idSubject == item.subject_id );

    let sb = state.assignedSubjects;

    sb.subjects[i1] = { ...sb.subjects[i1], places: payload.places, availability: payload.availability  };
    sb.filterSubjects[i2] = { ...sb.filterSubjects[i2], places: payload.places, availability: payload.availability  };


    commit('setAssignedSubjectsHistory', sb);
    commit('setCutomizeSubjectHistory', { ...state.cutomizeSubject, statusPlaces: payload.places, statusSchedule: payload.availability });
}

export default{
	changeStatusSubject
}