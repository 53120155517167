<template>
	<div class="v-switch row flex-nowrap" :isActive="disabledOpc!=='both'?opcActive:''">
        <button class="btn-switch" v-for=" (item,i) in switchBtns.slice(0,2)" @click="send(item,i)" :key="i" :disabled="i+1 == disabledOpc || disabledOpc == 'both'"> 
        	<svgicon class="icon" :name="item.icon"></svgicon>
        	<span class="btn-text">{{ item.textBtn }} </span>
        </button>
    </div>
</template>
<script>
	export default{
		props: { 
			switchBtns : { type: Array, default:[], required: true },
			defaultVal : { type: Number, default: 1, required: false}, // 1, 2
			disabledOpc: { default: null, required: false }, // 1, 2, both
			value: ''
		},
		data(){
			return{
                opcActive: ''
			}
		},
		created(){
            this.opcActive = `opc${this.defaultVal}`;
		},
		methods:{
			send(item,i){
				this.$emit('input', item.opc);
			}
		},
		watch: {
		    value: function(newVal){ 
			    this.switchBtns.find( (item,i) => { 
			    	if(item.opc === newVal) 
			    		this.opcActive = `opc${i+1}`;
			    })
		    }
		}
	}
</script>