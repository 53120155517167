var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.modalVal.modal == "v-join-onboarding" &&
    _vm.permission === _vm.$route.meta.permission &&
    !_vm.$route.meta.error &&
    !_vm.$route.meta.classRoom
    ? _c(
        "div",
        { staticClass: "join-onboarding", class: { active: _vm.show } },
        [
          _c("div", {
            staticClass: "blurColor",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "v-modal-paper" }, [
            _c(
              "div",
              {
                staticClass:
                  "v-modal-title bg-lesson-online pt-3 pr-4 pb-3 pl-4 position-relative"
              },
              [
                _c("h4", { staticClass: "v-t mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("modals.joinOnboarding.t")))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "v-modal-info p-4" }, [
              _c("h5", { staticClass: "vt-detail mb-4" }, [
                _vm._v(_vm._s(_vm.$t("modals.joinOnboarding.tDetail")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vt-lesson-content" },
                [
                  _c(
                    "div",
                    { staticClass: "row ml-n1 mr-n1 align-items-center mb-3" },
                    [
                      _c("v-lesson-photo", {
                        staticClass: "col-auto pl-2 pr-2",
                        attrs: { options: _vm.lessonPhotoOpc }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col pl-2 pr-2",
                          style: {
                            maxWidth: `calc(100% - ${_vm.lessonPhotoOpc.size})`
                          }
                        },
                        [
                          _c("v-hover-txt", {
                            staticClass: "w-100 mb-2",
                            attrs: {
                              txt: _vm.lesson.tutor,
                              fontSize: "16px",
                              fontWeight: "400"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row ml-n1 mr-n1" }, [
                            _c(
                              "div",
                              { staticClass: "col-auto pl-1 pr-1" },
                              [
                                _c("v-lesson-tag", {
                                  attrs: {
                                    type: _vm.lesson.type,
                                    options: {
                                      ..._vm.tagTypeOpc,
                                      minWidth: "80px"
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-auto pl-1 pr-1" },
                              [
                                _c("v-lesson-tag", {
                                  attrs: {
                                    type: _vm.lesson.modality,
                                    options: { minWidth: "80px" }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vt-subject d-flex align-items-center position-relative pl-4"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "vt-icon",
                        attrs: { name: _vm.lesson.icon, color: "#000" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "vt-txt" }, [
                        _vm._v(_vm._s(_vm.lesson.subject))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.lesson.topic && _vm.lesson.type != "Grupal"
                    ? _c("v-lesson-topic", {
                        staticClass: "mb-3 mt-2",
                        attrs: {
                          options: {
                            lessonId: _vm.lesson.id,
                            topic: _vm.lesson.topic,
                            hideBtn: true
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-lesson-schedule", {
                    staticClass: "mb-4 mt-3",
                    attrs: {
                      options: {
                        date: _vm.lesson.scheduledDate,
                        startTime: _vm.lesson.scheduledTime,
                        endTime: _vm.lesson.endScheduledTime,
                        spt: true
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-lesson-btn", {
                    staticClass: "w-100 mb-2",
                    attrs: {
                      options: { type: "online", idLesson: _vm.lesson.id }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-cancel",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("modals.joinOnboarding.btnOtherMoment"))
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }