/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'guitar': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 11c-.61 0-1.192-.123-1.638-.346C7.306 10.376 7 9.966 7 9.5s.306-.876.862-1.154C8.308 8.123 8.889 8 9.5 8s1.192.123 1.638.346c.556.278.862.688.862 1.154s-.306.876-.862 1.154c-.446.223-1.027.346-1.638.346zm0-2c-.451 0-.885.088-1.191.24-.256.128-.309.243-.309.26s.054.132.309.26c.305.153.739.24 1.191.24s.885-.088 1.191-.24c.256-.128.309-.243.309-.26s-.054-.132-.309-.26C10.386 9.087 9.952 9 9.5 9zM12.499 14h-6a.5.5 0 010-1h6a.5.5 0 010 1z"/><path pid="1" d="M16.17 11.648c-.521-.371-1.17-.833-1.17-1.148 0-.294.038-.603.074-.903.096-.792.205-1.691-.424-2.401-.491-.554-1.366-.902-2.74-1.072l-.793-3.638a.5.5 0 00.348-.669L10.973.342A.5.5 0 0010.499 0h-2a.5.5 0 00-.474.342l-.5 1.5a.499.499 0 00.357.644l-.794 3.638c-1.374.17-2.248.518-2.739 1.072-.629.71-.52 1.608-.423 2.4.036.3.074.61.074.904 0 .315-.649.777-1.17 1.149C1.972 12.26 1 12.953 1 14v3c0 .519.203.983.603 1.379.368.364.912.669 1.616.907 1.4.474 3.513.714 6.28.714s4.88-.24 6.281-.714c.704-.238 1.248-.544 1.616-.907.4-.396.603-.86.603-1.379v-3c0-1.048-.972-1.74-1.83-2.352zM8.86 1h1.279l.167.5H8.693L8.86 1zm.043 1.5h1.195l.77 3.534C10.448 6.011 9.993 6 9.5 6s-.948.011-1.368.034L8.903 2.5zM3.41 12.463C4.192 11.906 5 11.331 5 10.5c0-.355-.041-.695-.082-1.024-.094-.771-.135-1.261.179-1.617C5.602 7.289 7.083 7 9.498 7s3.897.289 4.402.859c.315.355.274.846.18 1.617-.04.329-.081.669-.081 1.024 0 .831.808 1.406 1.59 1.963.661.471 1.41 1.005 1.41 1.537 0 1.252-2.804 2-7.501 2-4.696 0-7.499-.748-7.499-2 0-.532.749-1.066 1.41-1.537zM9.5 19C4.804 19 2 18.252 2 17v-1.297c.327.224.735.419 1.219.583 1.4.474 3.513.714 6.28.714s4.881-.24 6.281-.714a5.005 5.005 0 001.22-.583V17c0 1.252-2.804 2-7.5 2z"/>'
  }
})
