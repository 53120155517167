import subjects from './subjects.vue';
import dateTime from './date-time.vue';
import students from './students.vue';
import places from './places.vue';

const plugins = Vue => {
   Vue.component('v-subjects-t', subjects);
   Vue.component('v-date-time-t', dateTime);
   Vue.component('v-students-t', students);
   Vue.component('v-places-t', places);
}

export default plugins;