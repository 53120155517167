/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-fill': {
    width: 12,
    height: 8,
    viewBox: '0 0 12 8',
    data: '<g _fill="#000" fill-rule="nonzero"><circle pid="0" cx="6" cy="4" r="1.6"/><path pid="1" d="M11.871 3.565A8.473 8.473 0 009.39 1.052C8.325.364 7.15 0 5.992 0 4.929 0 3.884.304 2.885.903 1.867 1.513.945 2.406.143 3.554a.798.798 0 00-.016.889c.66 1.033 1.51 1.904 2.457 2.516C3.65 7.65 4.798 8 5.992 8c1.168 0 2.345-.36 3.405-1.043.961-.62 1.818-1.492 2.477-2.523a.805.805 0 00-.003-.869zM6.001 6.4a2.4 2.4 0 110-4.8 2.4 2.4 0 010 4.8z"/></g>'
  }
})
