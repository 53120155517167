TY<template>
	<div class="row w-100">
		<div class="col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3">
        	<div class="btn-prev-step order-0 col-auto col-md">
				<v-prev-link class="col-12 pt-3 pb-3 pl-0 pr-0" :txt="$t('scheduleLesson.place.t')" type="step" :click="() => prevAction()" v-if="form.lesson.type != 'Grupal'"/>
               <v-prev-link class="col-12 pt-3 pb-3 pl-0 pr-0" :txt="$t('scheduleLesson.place.prev')" type="step" :click="() => prevAction()" v-else/>
			</div>
		    <div class="btn-close-step order-1 order-md-2 col-auto col-md text-right">
			    <button @click="closeAction()"><svgicon name="cross2"></svgicon></button>
		    </div>
	    </div>
		<div class="v-lesson-summary w-100 d-flex flex-wrap justify-content-end">
			<div class="pl-3 pr-3 w-100">
				<div class="v-lesson-summary-info p-3 p-sm-4 w-100 mb-3">
					<div class="pl-sm-3 pr-sm-3" :style="{ maxWidth: '900px' }">
						<h3 class="title-step mb-4 pb-sm-2">{{ $t('scheduleLesson.summary.t') }}</h3>
						<div class="row ml-n2 mr-n2">
							<div class="col-12 col-sm-auto pl-2 pr-2 mb-3" v-if="tutor.last_name">
                                <v-lesson-photo class="ml-auto mr-auto" :options="{ photo: tutor.photo, size: '80px' }" />
                            </div>
                            <div class="col pl-2 pr-2">
                            	<div class="row mb-3">
							        <div class="col-xl-4 col-lg-5 mb-2 mb-lg-0">
								        <div class="row">
									        <p class="v-lesson-tutor col-12 mb-1">{{ tutor.name }} {{ tutor.last_name }}</p>
									        <div class="col-12">
										        <div class="row ml-n2 mr-n2">
											        <div class="col-auto pl-2 pr-2 pb-1 pt-1">
												        <v-lesson-tag :type="form.lesson.type" :options="{ minWidth: '80px', lessonLimit: form.date.time.places, lessonQuota: form.date.time.occupied }" />
											        </div>
											        <div class="col-auto pl-2 pr-2 pb-1 pt-1">
												        <v-lesson-tag :type="form.lesson.modality" :options="{ minWidth: '80px' }" />
											        </div>
									            </div>
								            </div>
								        </div>
							        </div>
							        <div class="col-xl-8 col-lg-7">
								        <div class="row">
									        <div class="col-12">
										        <p class="v-lesson-subject mb-1 position-relative">
											        <svgicon class="v-ls-icon mr-2" :name="form.lesson.icon" color="#000"></svgicon>
											        {{ form.lesson.name }}
										        </p>
									        </div>
									        <div class="col-12 col-md" v-if="form.lesson.type == 'Individual'">
										        <div class="row ml-n2 mr-n2 align-items-center">
											        <div class="col-auto pl-2 pr-2 pt-1 pb-1">
												        <div class="v-topic-tag pt-1 pr-3 pb-1 pl-3">{{ $t('topic.tag') }}</div>
											        </div>
											        <div class="col-auto d-flex align-items-center pl-2 pr-2">
												        <span class="v-topic-opc">{{ $t('topic.opc') }}</span>
											        </div>
											        <div class="col pl-2 pr-2">
												        <input class="v-topic-field w-100" v-model="topic" maxlength="50" :placeholder="$t('topic.placeholder')" type="text">
											        </div>
										        </div>
									        </div>
								        </div>	
							        </div>
							    </div>
							    <p class="v-lesson-date mb-3 position-relative">
							        <svgicon class="v-ls-icon mr-2" name="calendar" color="#000"></svgicon>
							        {{ `${setDate} ${setTime}` }}
						        </p>

						        <v-lesson-place :options="{ icon: form.place.name?form.place.icon.icon_name:null, place: form.place.name?form.place.name:null, modality: form.lesson.modality, type: form.lesson.type }"/>

						    </div>
						</div>
					</div>			
				</div>
		        <v-btn class="float-right" :txt="$t('scheduleLesson.summary.btn')" @click.native="send()" fontSize="20px" min="250px" :disabled="sending"/>
		    </div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	import { maxLength } from 'vuelidate/lib/validators';

	export default{
        props: {
        	closeAction: { type: Function, required: true },
			prevAction: { type: Function, required: true },
        	tutor: { type: Object, required: true },
        	form: { type: Object, required: true }  
        },
        data(){
            return{
                sending: false,
                topic: ''
            }
        },
        computed:{
        	...mapState(['auth','i18n']),
            setDate(){
            	const date = this.$moment(this.form.date.date).locale(this.i18n.locale).format(this.$t('formatDateC'));
                return date.charAt(0).toUpperCase()+date.slice(1);
            },
            setTime(){
            	return `${this.$moment(this.form.date.time.start, 'HH:mm:ss').format('LT')} - ${this.$moment(this.form.date.time.end, 'HH:mm:ss').format('LT')}`;
            }
		},
		methods:{
			...mapMutations(['setLessonRequest','setRoute','setStatusLesson','setNotification', 'setActiveLessons', 'handleServerTime']),
			...mapActions(['axiosRequest', 'beforeShowModal']),
			send(){
				if (!this.sending){
					this.sending = true;
			        this.$Progress.start();
			        this.axiosRequest({ config : {
			        	method: 'POST',
			        	url: `${this.auth.api}/lessons/create`,
			        	data: {
			        		subjectId: this.form.lesson.subject_id,
			        		tutorId: this.tutor.id,
			        		date: this.$moment(this.form.date.date).format('YYYY-MM-DD'),
			        		time: this.form.date.time.start,
			        		placeId: this.form.place.id,
			        		type: this.form.lesson.type,
			        		modality: this.form.lesson.modality,
			        		topic: this.topic
			        	},
			        	headers: { authorization : this.auth.token, 'Content-Type' : 'application/json' }
			        }}).then( resp => resp.data)
			        .then( data => {
			        	this.sending = false;
			        	if(data.type === 'Success'){
			        		this.setLessonRequest({ tutor: this.tutor, lesson: { ...this.form, id : data.data.lesson, topic: this.topic } });
			        		this.setStatusLesson('success');
			        		this.$router.push({ name : 'request-student' }); 
			        		if (data.data.ongoing && this.form.lesson.type !== 'Individual'){
								this.handleServerTime(data.data.serverTime);
			        			const newArray = this.auth.activeLessons,
			        			      session = { endScheduledTime: this.form.date.time.end,
			        				                      icon: this.form.lesson.icon,
			        				                      id: data.data.lesson,
			        				                      initialTime: data.data.initialTime,
			        				                      limit: this.form.date.time.places,
			        				                      modality: this.form.lesson.modality,
			        				                      participantsTotal: this.form.date.time.occupied+1,
			        				                      place: data.data.place,
			        				                      placeIcon: this.form.place.icon?this.form.place.icon.icon_name:'',
			        				                      scheduledDate: this.$moment.utc(this.form.date.date).valueOf(),
			        				                      scheduledTime: this.form.date.time.start,
			        				                      status: "Activa",
			        				                      student: `${this.auth.user.name} ${this.auth.user.last_name}`,
			        				                      studentPhoto: this.auth.user.photo,
			        				                      subject: this.form.lesson.name,
			        				                      tutor: this.tutor.name,
			        				                      tutorPhoto: this.tutor.photo,
			        				                      type: this.form.lesson.type,
			        				                      tz_scheduled: data.data.tz_scheduled,
			        				                      topic: this.topic };
			        			      newArray.push(session);
                      
			        			this.setActiveLessons(newArray);
			        			if (session.modality == "Online") this.beforeShowModal({ lesson: session, action: 'v-join-onboarding' });
			        		}
			        	}else{
			        		this.setNotification({ type: data.type, msg: data.message });
			        	}
			        	this.$Progress.finish();
			        }).catch( error => {
			        	this.sending = false;
			        	if (error.response.status === 422 || error.response.status === 403){
			        		this.setNotification({ type: 'warning', msg: error.response.data.error.message });
			        	}else{
			        		this.setLessonRequest({ tutor: this.tutor, lesson: this.form });
			        		this.setStatusLesson('error');
			        		this.setRoute(this.$route.path);
			        		this.$router.push({ name : 'request-student' }); 
			        	}
			        	this.$Progress.fail()
			        })
                }
			}
		},
		validations: {
            topic: {
            	maxLength: maxLength(50)
            }
        }
	}
</script>