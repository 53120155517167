const setPath = (state,path) => {
	state.auth.path = path;
}

const setToken = (state,token) => {
	state.auth.token = token;
}

const setStatusFormats = (state, formats) => {
	state.auth.formats = formats;
}

const handleFormat = (state, payload) => {

	if(payload.format == 'identity')
	    state.auth.formats.identity = payload.status;
	else if(payload.format == 'studySkills')
	    state.auth.formats.studySkills = payload.status;
	else if(payload.format == 'learningStyles')
	    state.auth.formats.learningStyles = payload.status;
	else if(payload.format == 'selfEsteem')
	    state.auth.formats.selfEsteem = payload.status;
	else if(payload.format == 'assertiveness')
	    state.auth.formats.assertiveness = payload.status;
}

const setInstitution = (state,institution) => {
	state.auth.institution = institution;
}

const setUser = (state,datas) => {
	if (datas.email)
	    datas.email = datas.email.toLowerCase();
	state.auth.user = datas;
}

const setPermission = (state,data) => {
	state.auth.permission = data;
}

const showError403 = (state,payload) => {
	state.auth.error = payload;
}

const logOut = (state) => {
	state.auth.permission = '';
	state.auth.user = {};
	state.auth.token = '';
	state.auth.pendingFeedback = [];
	state.auth.pendingConfirmation = [];
	state.auth.activeLessons = [];
	state.auth.commentRequired = false;
	state.flag = false;
}

const setStatusCalendar = (state,payload) => {
	state.auth.user.disabled_calendar = payload;
}

const setUserData = (state,payload) => {
	state.auth.user.name = payload.name;
	state.auth.user.last_name = payload.last_name;
	state.auth.user.phone = payload.phone;
	state.auth.user.birthday = payload.birthday;
	state.auth.user.gender = payload.gender;
	if (payload.career_id)
	    state.auth.user.career_id = payload.career_id;
	if (payload.period_id)
	    state.auth.user.period_id = payload.period_id;
}

const setUserPhoto = (state,payload) => {
	state.auth.user.photo = payload;
}

const setNotifications = (state,payload) => {
	
	const typePermission = state.auth.permission == 'tutor'?'t':'s';
	state.auth.user.preferences.notif_newsletter = +payload.newsletter;
	state.auth.user.preferences[`notif_lesson_summary_${typePermission}`] = +payload[`lesson_summary_${typePermission}`];
	state.auth.user.preferences[`notif_upcoming_lessons_${typePermission}`] = +payload[`upcoming_lessons_${typePermission}`];
    if (typeof payload[`lesson_status_${typePermission}`] !== 'undefined') 
	    state.auth.user.preferences[`notif_lesson_status_${typePermission}`] = +payload[`lesson_status_${typePermission}`];
	if (typeof payload.lesson_cancelled_t !== 'undefined') 
	    state.auth.user.preferences.notif_lesson_cancelled_t = +payload.lesson_cancelled_t;
	if (typeof payload.lesson_request_t !== 'undefined') 
	    state.auth.user.preferences.notif_lesson_request_t = +payload.lesson_request_t;
}

const setDescription = (state, payload) => {
	state.auth.user.description = payload;
}

const setpendingFeedback = (state, payload) => {
	state.auth.pendingFeedback = payload;
}

const setcommentFeedback = (state, payload) => {
	state.auth.commentRequired = payload;
}

const setpendingConfirmation = (state, payload) => {
	state.auth.pendingConfirmation = payload;
}

const setStatusTutor = (state, payload) => {
	state.auth.user.vacation_mode = payload;
}

const setActiveLessons = (state, payload) => {
	state.auth.activeLessons = payload;
	if (payload.length > 0)
	    state.auth.currentActiveLesson = payload[payload.length-1];
	else
		state.auth.currentActiveLesson = null;
}

const changeUserLang = (state, payload) => {
	state.auth.user.preferences.web_lang = payload;
}

const handleCampusType = (state, payload) => {
	state.auth.campusType = payload;
}

export default{
	setPath,
	setInstitution,
	setToken,
	setStatusFormats,
	handleFormat,
	setUser,
	logOut,
	setPermission,
	showError403,
	setStatusCalendar,
	setUserData,
	setUserPhoto,
	setNotifications,
	setDescription,
	setpendingFeedback,
	setcommentFeedback,
	setpendingConfirmation,
	setStatusTutor,
	setActiveLessons,
	changeUserLang,
	handleCampusType
}