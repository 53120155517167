var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "recover-password" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c("div", { staticClass: "v-paper w-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3"
            },
            [
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("recoveryPassword.t")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4 d-flex flex-column align-items-center" },
            [
              _c(
                "div",
                { staticClass: "bg-icon mb-4" },
                [
                  _c("svgicon", {
                    staticClass: "inset-icon lock2",
                    attrs: { name: "lock2", color: "#fff" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "w-100",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.send()
                    }
                  }
                },
                [
                  _c("v-textField", {
                    ref: "email",
                    staticClass: "mb-4 pb-2",
                    attrs: {
                      label: _vm.$t("recoveryPassword.label"),
                      placeholder: _vm.$t("recoveryPassword.placeholder"),
                      type: "email",
                      options: _vm.opcInput,
                      required: true
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-btn", {
                    staticClass: "mb-2 w-100",
                    attrs: {
                      fontSize: "1rem",
                      fontWeight: "400",
                      txt: _vm.$t("recoveryPassword.btn"),
                      height: "40px",
                      type: "submit",
                      name: "login",
                      spinner: _vm.sending,
                      disabled: _vm.$v.validationGroup.$invalid
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "v-btn-linc",
                          attrs: {
                            to: { name: "login" },
                            name: "recoverPassword",
                            type: "button",
                            tag: "button"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("recoveryPassword.btnPrev")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }