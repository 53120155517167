<template>
	<div class="v-schedule-subjects w-100">
        <!-- ========= Title and btn close ======== -->
		<div class="col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3 mt-3">
			<h3 class="title-step col-9 col-md-auto mb-0 pl-0">{{ $t('scheduleLesson.subjects.t') }}</h3>
		    <div class="btn-close-step col-3 col-md-auto pr-0 text-right" v-if="closeAction">
			    <button @click="closeAction()"><svgicon name="cross2"></svgicon></button>
		    </div>
	    </div>

        <!-- ========= Filters ======== -->
        <div class="row align-items-center mb-3">
        	<div class="col pr-1 pr-sm-3">
        		<input class="search-subject w-100" v-model="search" type="text" :placeholder="$t('scheduleLesson.subjects.search')">
        	</div>
        	<span class="in col text-center pl-0 pr-0">{{ $t('scheduleLesson.subjects.in') }}</span>
        	<div class="col pl-1 pl-sm-3">
        		<div class="v-select-v2 w-100">
        			<select class="select-content w-100" v-model="areaSelect">
        				<option value="">{{ $t('scheduleLesson.subjects.allAreas') }}</option>
        				<option v-for="area in areas" :value="area.name">{{ area.name }}</option>
        			</select>
        			<div class="icon-select">
        				<svgicon class="icon" name="chevron-down" />
        			</div>
        		</div>
        	</div>
        </div>

        <perfect-scrollbar ref="scroll" class="v-subjects-list mb-3" tag="div">
            <transition name="bound" mode="out-in">
                <!-- ========= Spinner ======== -->
                <div class="w-100 p-3 text-center" key="spinner" v-if="show === 'spinner'">
                    <v-spinner :height="'40px'" :width="'40px'"/>
                </div>
                <!-- ========= Message ======== -->
                <div class="w-100 p-3 text-center" key="spinner" v-else-if="show === 'message'">
                    <p class="msg-search mt-2 mb-2">{{ $t('msgEmpty.subjectSearch') }}</p>
                </div>
                <!-- ========= List subjects ======== -->
                <transition-group name="bound" key="results" class="pl-0 mb-0" tag="ul" v-else-if="show === 'results'">
                    <li class="radio-subjects d-flex justify-content-between align-items-center pt-2 pb-2 pl-2 pl-sm-3 pr-3 pr-md-4 w-100" :color="(index+1)%2==0?'clear':'dark'" :key="item.subject_id" v-for="(item,index) in subjects" @click="handleInput(item)" :class="{ active : value.subject_id === item.subject_id }">
                        <div class="l-subject w-100">
                            <div class="icon-subject" :color="item.type == 'Grupal'?'y':'b'">
                                <svgicon class="icon" :name="item.type == 'Grupal'?'group':'user2'" />
                            </div>
                            <div class="content-subject-name">
                                <v-hover-txt class="mb-1" fontWeight="400" :txt="item.name" :positionCloud="index==0?'top':'bottom'" />
                                <v-hover-txt fontSize="9px" :txt="item.category" :positionCloud="index==0 && subjects.length>1?'top':'bottom'" />   
                            </div>
                            <div class="modality-subject">
                                <v-lesson-tag :type="item.modality" :options="{ minWidth: '80px' }" />
                                <div class="check-subject"></div>
                            </div>
                        </div>
                        <div class="val-place"></div>
                    </li>
                </transition-group>
            </transition>
	    </perfect-scrollbar>

	    <v-btn class="float-right" :txt="$t('scheduleLesson.btnNext')" fontSize="20px" type="Next" min="200px" @click.native="nextAction()" :disabled="value==''" />

	</div>

</template>
<script>
	import { mapState, mapActions } from 'vuex';
	export default{
		props: {
			subject: { type: String, default: '', required: false },
			tutorId: { type: Number, required: true },
			closeAction: { type: Function, required: true },
			nextAction: { type: Function, required: true },
			value: ''
		},
        data(){
        	return{
        		areas: [],
        		areaSelect: '',
        		limit: 5,
        		offset: 0,
                gettingSubjects: false,
        		search: '',
        		subjects: [],
        		totalSubjects: 0,
                delay: '',
                show: 'results'
        	}
        },
        computed:{
            ...mapState(['auth'])
        },
        created(){
            if (this.subject === '-ALL-')
                this.search = '';
            else if(this.value !== '')
                this.search = this.value.name;
            else
                this.search = this.subject;

            this.getSubjects();
            this.$watch( 'search', newVal => {
                const that = this;
                clearTimeout(this.delay);
                this.show = 'spinner';
                this.delay = setTimeout( () => {
                    that.offset = 0;
                    that.handleInput('');
                    that.getSubjects().then( resp => {
                        if (resp)
                            this.show = 'results'
                        else
                            this.show = 'message';
                    }).catch( err => {
                        that.show = '';
                    } );
                },700);
            })
        },
        mounted(){
            this.$refs.scroll.$el.addEventListener('ps-scroll-down', e => {
        		if ((Math.round(e.target.scrollTop) + this.$refs.scroll.ps.containerHeight) >= this.$refs.scroll.ps.contentHeight - 150 && (this.offset+5) < this.totalSubjects && !this.gettingSubjects) {
        			this.offset += 5;
                    this.gettingSubjects = true;
        			this.getSubjects();
        		}
            });
        },
        methods:{
            ...mapActions(['axiosRequest']),
            getSubjects(){
                return new Promise( (resolve, reject) => {
                    this.axiosRequest({ config : {
                        method: 'GET',
                        url: `${this.auth.api}/tutor/subjects`,
                        params: ({  limit: this.limit,
                            offset: this.offset,
                            search: this.search,
                            tutor_id: this.tutorId,
                            area: this.areaSelect }),
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then( data => {
                       this.totalSubjects = data.total;
                       this.areas = data.categories;
                       this.gettingSubjects = false;
                       if (this.offset == 0) 
                           this.subjects = data.subjects;
                       else
                          this.subjects = [...this.subjects, ...data.subjects]
                        resolve(this.subjects.length>0?true:false);
                    }).catch( error => {
                        reject(false);
                        console.log(error)
                    });
                })
            },
            handleInput(value){
            	if (value===this.value)
				    this.$emit('input', '');
				else
                    if (value!='')
					   this.$emit('input', value);
                    else{
                        this.$emit('input', this.value);
                    }
			}
        },
        watch:{
        	subject: function(newVal){
                if (newVal === '-ALL-')
        		   this.search = '';
                else
                    this.search = newVal;
        	},
        	areaSelect: function(newVal){
                this.offset = 0;
        		this.handleInput('');
        		this.getSubjects();
        	}
        }
	}
</script>