var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "confirm-modal", class: { active: _vm.show } },
    [
      _c("div", {
        staticClass: "blurColor",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "v-content" }, [
        _c(
          "h4",
          { staticClass: "title bgColor-yellow-main pt-4 pb-3 pl-5 pr-5 mb-0" },
          [
            _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t\t"),
            _c("svgicon", {
              staticClass: "btn-close",
              attrs: { name: "cross2" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center pt-3 pb-4 pl-4 pr-4" },
          [
            _c("svgicon", {
              staticClass: "icon mb-3",
              attrs: { name: "notification", color: "#F5A623" }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "alert-legend w-100 mb-4" }, [
              _vm._v(_vm._s(_vm.descrip))
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }