<template>
	<div class="d-flex align-items-center" :style="{ maxWidth: maxWidth, minWidth: minWidth }">
		<div class="content-img mr-2">
			<div class="content-icon w-100 h-100" :class="`bgColor-${auth.permission}-main`" v-if="type!=='Grupal'">
				<svgicon class="icon w-75 h-75" name="user"></svgicon>
			</div>
			<div class="content-icon w-100 h-100 bgColor-yellow-main" v-else>
				<svgicon class="icon w-75 h-75" name="users2"></svgicon>
			</div>
		</div>
		<div class="info" v-if="auth.permission == 'student'">
			<span class="d-block w-100 name" :class="type!=='Grupal'?'color-student-main':'color-yellow-main'">{{ name }}</span>
			<span class="d-block w-100 seats" v-if="type==='Grupal' && seats">{{ seats }}</span>
		</div>
		<div class="info" v-else>
			<span class="d-block w-100 name" :class="type!=='Grupal'?'color-tutor-main':'color-yellow-main'">{{ name }}</span>
			<span class="d-block seats w-100" @click="statusMemmers=true" v-if="type === 'Grupal' && seats">
				{{ seats }}
				<svgicon class="eye icon ml-1" name="eye" @click="showMembers()"/>
			</span>
		</div>
	</div>
	
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props: {
			name: { type: String, default: '', required: true },
			seats: '',
			type: { type: String, default: '', required: true },
			maxWidth: { type: String, default: '150px', required: false },
			minWidth: { type: String, default: 'initial', required: false },
			showMembers: { type: Function, required: false }
		},
		computed: {
			...mapState(['auth'])
		}
	}
	
</script>