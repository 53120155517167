/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tab': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.354 7.146l-3-3a.5.5 0 00-.707.707l2.146 2.146H2.5a.5.5 0 000 1h14.293l-2.146 2.146a.5.5 0 00.708.707l3-3a.5.5 0 000-.707zM17.5 13H3.207l2.146-2.146a.5.5 0 00-.707-.707l-3 3a.5.5 0 000 .707l3 3a.498.498 0 00.708 0 .5.5 0 000-.707l-2.146-2.146h14.293a.5.5 0 000-1z"/>'
  }
})
