/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M5.5 17.5a.502.502 0 01-.354-.146l-5-5a.5.5 0 01.707-.707l4.646 4.646L19.145 2.647a.5.5 0 01.707.707l-14 14a.498.498 0 01-.354.146z"/>'
  }
})
