var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-date-time-t" }, [
    _c(
      "h5",
      { staticClass: "v-sch-t-title d-flex align-items-center mb-3" },
      [
        _vm._v(
          "\n       " + _vm._s(_vm.$t("scheduleT.dateTime.t")) + "\n       "
        ),
        _c("v-more-info", {
          staticClass: "ml-2",
          attrs: { txtCloud: _vm.$t("scheduleT.dateTime.tooltip") }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pl-2 pr-2" }, [
      _c("div", { staticClass: "row ml-n2 mr-n2" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 pl-0 pr-sm-2 pr-0" },
          [
            _c("v-datePicker", {
              staticClass: "mb-3",
              attrs: {
                value: _vm.val.date,
                placeholder: _vm.$t("scheduleT.dateTime.date"),
                disabled: _vm.disabled,
                options: _vm.inputOpc,
                db: true
              },
              on: { input: i => (_vm.val = { date: i, time: _vm.val.time }) }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 pl-sm-2 pl-0 pr-0" },
          [
            _c("v-select", {
              key: _vm.reset,
              staticClass: "mb-3",
              attrs: {
                value: _vm.val.time,
                defaultOpc: _vm.$t("scheduleT.dateTime.time"),
                disabled: _vm.disabled,
                options: _vm.inputOpc,
                opc: _vm.rangeTimes,
                fieldVal: "txt",
                fieldTxt: "txt"
              },
              on: { input: i => (_vm.val = { date: _vm.val.date, time: i }) }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }