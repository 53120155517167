/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toggle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.5 20a.495.495 0 01-.224-.053L10.382 18H.5a.5.5 0 01-.5-.5v-14A.5.5 0 01.5 3h9.882l3.894-1.947a.5.5 0 01.723.447v18a.5.5 0 01-.5.5zM1 17h9.5c.078 0 .154.018.224.053L14 18.691V2.309l-3.276 1.638A.496.496 0 0110.5 4H1v13zM19.5 18h-3a.5.5 0 010-1H19V4h-2.5a.5.5 0 010-1h3a.5.5 0 01.5.5v14a.5.5 0 01-.5.5z"/><path pid="1" d="M8.354 10.146l-2-2a.5.5 0 00-.707.707l1.146 1.146H2.5a.5.5 0 000 1h4.293l-1.146 1.146a.5.5 0 00.708.707l2-2a.5.5 0 000-.707zM10.5 7a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM10.5 10a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM10.5 13a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM10.5 16a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5z"/>'
  }
})
