var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass:
        "v-lesson-btn d-flex align-items-center justify-content-center",
      class: `${_vm.bgColor} ${_vm.btnType == "small" ? "cloud-hover" : ""}`,
      style: { ..._vm.btnCss, height: _vm.btnSize },
      attrs: { name: `btn-lesson-${_vm.options.type}` },
      on: { click: _vm.lessonAction }
    },
    [
      _c(
        "div",
        {
          staticClass: "v-lb-content-icon",
          style: { height: _vm.btnSize, width: _vm.btnSize }
        },
        [
          _c("svgicon", {
            staticClass: "v-lb-icon",
            style: _vm.iconCss,
            attrs: { name: _vm.icon }
          }),
          _vm._v(" "),
          _vm.btnType == "large" && _vm.options.type == "cancel"
            ? _c("div", { staticClass: "bg-W" })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.btnType == "large" || _vm.btnType == "large2"
        ? _c("span", { staticClass: "v-lb-txt", style: _vm.txtCss }, [
            _vm._v(_vm._s(_vm.txt))
          ])
        : _c("div", { staticClass: "cloud-txt" }, [_vm._v(_vm._s(_vm.txt))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }