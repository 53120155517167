<template>
	<div class="pl-3 pr-3 pt-4 pb-5">
		<transition name="bound" tag="div" mode="out-in">
			<!-- ================ Subjects ================ -->
			<v-subjects class="mb-4 mt-3 ml-auto mr-auto" key="subjects" :subject="subject" :tutorId="tutor.id" v-model="form.lesson" :closeAction="() => $emit('close')" :nextAction="() => changeStep('date')"  v-if="step == 'subject'"/>

			<!-- ============== Select day and time ============= -->
			<v-schedule class="mb-4 mt-3 ml-auto mr-auto" key="date" v-model="form.date" :lesson="form.lesson" :idTutor="tutor.id" :closeAction="() => $emit('close')" :prevAction="() => changeStep('subject')" :nextAction="() => changeStep(form.lesson.type=='Grupal' || form.lesson.modality == 'Online'?'summary':'place')" v-if="step == 'date'" />

			<!-- ============== Place ============= -->
			<v-place class="mb-4 mt-3 ml-auto mr-auto" key="place" v-model="form.place" :lesson="form.lesson" :idTutor="tutor.id" :closeAction="() => $emit('close')" :prevAction="() => changeStep('date')" :nextAction="() => changeStep('summary')" v-if="step == 'place'" />

			<!-- ============== Summary ============= -->
			<v-summary class="mb-4 mt-3 ml-auto mr-auto" key="summary" :tutor="tutor" :form="form" :closeAction="() => $emit('close')" :prevAction="() => changeStep(form.lesson.type=='Grupal' || form.lesson.modality == 'Online'?'date':'place')" v-if="step == 'summary'" />	
		</transition>
	</div>
</template>

<script>
	export default{
		props:{
            tutor: { type: Object, default: () => { return {} } },
            lesson: { type: Object, default: () => { return {} } }
		},
		data(){
			return{
				step: 'subject', // subject, date, place, summary
				form: {
					lesson: '',
					date: {},
					place: {}
				}
			}
		},
		computed: {
            subject(){
            	return this.lesson.name;
            }
		},
		created(){
            if(this.lesson.subject_id){
            	this.form.lesson = this.lesson;
            	this.step = 'date';
            }
		},
		methods: {
			changeStep(step){
                this.step = step;
			} 
		}
	}
</script>