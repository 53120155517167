import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/analytics';

let firebaseConfig = process.env.NODE_ENV==='production'?{ // Production
  apiKey: "AIzaSyDQsVj6iIyKCLtzqYWyTU20gvG1DSdLgz8",
  authDomain: "linc-institutions-app.firebaseapp.com",
  databaseURL: "https://linc-institutions-app.firebaseio.com",
  projectId: "linc-institutions-app",
  storageBucket: "linc-institutions-app.appspot.com",
  messagingSenderId: "648702883720",
  appId: "1:648702883720:web:820959a7b415d8b505ac3d",
  measurementId: "G-KEXV81V889"
}:{ // Development
  apiKey: "AIzaSyA1ZEf2IoZ2FDRwkErOuFBHGqTATeHqLKc",
  authDomain: "linc-institutions-app-dev.firebaseapp.com",
  databaseURL: "https://linc-institutions-app-dev.firebaseio.com",
  projectId: "linc-institutions-app-dev",
  storageBucket: "linc-institutions-app-dev.appspot.com",
  messagingSenderId: "449670515075",
  appId: "1:449670515075:web:f78f82199263f7f79b4eb6",
  measurementId: "G-KYSHMZDC7L"
}

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}

const auth = firebase.auth(),
      db = firebase.database(),
      functions = firebase.functions(),
      analytics = firebase.analytics();

let messaging = null;

const pairKey = process.env.NODE_ENV==='production'?'BDfeDdRemnFxwIUD3KXQ5Ctd3edetA2vsohwWnwZRts7PxLilkZmdEjpl6GDoDWeSGGKJbuKOEmEUdkTNBtBHSw':'BGaAcKU17B7twUlyRgRpuTBiNuKUEjDaBcnxgp8bvWXD4fWswSYzZ14p4CxzJOdyTcACUaIPxYGBLxhuFCqlU98'; 

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(pairKey); // Generate a new key pair
}
      
export{
  firebase,
  auth,
  db,
  functions,
  messaging,
  analytics
}