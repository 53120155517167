/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-return': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 5a.5.5 0 00-.5.5v4a.5.5 0 01-.5.5H2.207l4.146-4.146a.5.5 0 00-.707-.707l-5 5a.5.5 0 000 .707l5 5a.498.498 0 00.708 0 .5.5 0 000-.707l-4.146-4.146h15.293c.827 0 1.5-.673 1.5-1.5v-4a.5.5 0 00-.5-.5z"/>'
  }
})
