/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no-camera': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#E82719" cx="21" cy="21" r="21"/><g _fill="#FFF" fill-rule="nonzero"><path pid="1" d="M32 28.927c-.278 0-.557-.11-.832-.327l-3.954-3.117C26.58 24.982 26.1 24 26.1 23.199v-3.4c0-.801.478-1.783 1.114-2.284l3.954-3.118c.275-.216.554-.326.833-.326.498 0 .999.37.999 1.193v12.467c0 .315-.074.58-.218.787a.942.942 0 01-.781.406v.003zM23.225 28.867h-11.5c-.951 0-1.725-.763-1.725-1.7V15.833c0-.937.774-1.7 1.725-1.7h11.5c.951 0 1.725.763 1.725 1.7v11.334c0 .937-.774 1.7-1.725 1.7z"/></g><path pid="2" _stroke="#FFF" stroke-linecap="square" d="M10.5 32.5l22-22"/></g>'
  }
})
