var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-wrap",
      class: {
        compact: _vm.menu.compact,
        show: _vm.menu.showMenuResponsive,
        "hide-text": _vm.menu.hideText
      },
      attrs: { id: "main-menu" }
    },
    [
      _c(
        "div",
        { staticClass: "w-100" },
        [
          _c(
            "div",
            {
              staticClass: "position-relative w-100 schedule",
              style: { "--bg-color": _vm.color[_vm.auth.permission].main }
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn-schedule",
                  style: { background: _vm.color[_vm.auth.permission].main },
                  attrs: {
                    to: { name: `schedule-${_vm.auth.permission}` },
                    tag: "button"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setStatusMenuResponsive(false)
                    }
                  }
                },
                [
                  _vm.menu.compact && (_vm.grid == "lg" || _vm.grid == "xl")
                    ? _c("svgicon", {
                        staticClass: "icon",
                        attrs: { name: "clock" }
                      })
                    : _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("mainMenu.schedule")))
                      ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "perfect-scrollbar",
            {
              key: `menu-${_vm.auth.permission}`,
              staticClass: "menu navbar w-100 p-0 align-items-start",
              attrs: {
                activeColor:
                  _vm.auth.permission === "student" ? "blue" : "green",
                tag: "nav",
                options: { suppressScrollX: true }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav w-100" },
                _vm._l(_vm.setMenu, function(btn, i) {
                  return _c(
                    "li",
                    {
                      key: `btnMenu-${i}`,
                      staticClass: "w-100 content-btn",
                      on: {
                        mouseover: function($event) {
                          _vm.btnActive =
                            _vm.menu.compact && _vm.activeHover
                              ? btn.id
                              : _vm.btnActive
                        },
                        mouseleave: function($event) {
                          _vm.btnActive =
                            _vm.menu.compact && _vm.activeHover
                              ? ""
                              : _vm.btnActive
                        }
                      }
                    },
                    [
                      btn.sub
                        ? _c(
                            "a",
                            {
                              staticClass: "btn-menu nav-link",
                              class: { "active-sub": _vm.btnActive == btn.id },
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.btnActive =
                                    _vm.btnActive == btn.id ? "" : btn.id
                                }
                              }
                            },
                            [
                              _c("svgicon", {
                                staticClass: "icon",
                                attrs: { name: btn.icon }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(btn.name))
                              ]),
                              _vm._v(" "),
                              _c("svgicon", {
                                staticClass: "icon-sub",
                                attrs: { name: "chevron-down" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "router-link",
                            {
                              staticClass: "btn-menu nav-link",
                              attrs: { to: btn.route },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.setStatusMenuResponsive(false)
                                }
                              }
                            },
                            [
                              _c("svgicon", {
                                staticClass: "icon",
                                attrs: { name: btn.icon }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(btn.name))
                              ])
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-slide-animation",
                        {
                          key: btn.id,
                          class: { "d-none": _vm.hideSub },
                          attrs: {
                            boundAnimation: false,
                            show:
                              btn.id == _vm.btnActive ||
                              (_vm.menu.compact && _vm.activeHover)
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "navbar-nav w-100 sub" },
                            [
                              _vm.menu.compact
                                ? _c(
                                    "li",
                                    {
                                      staticClass:
                                        "content-btn d-none d-lg-block"
                                    },
                                    [
                                      btn.sub
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn-sub nav-link nav-name",
                                              attrs: { href: "#" }
                                            },
                                            [_vm._v(_vm._s(btn.name))]
                                          )
                                        : _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "btn-sub nav-link nav-name",
                                              attrs: { to: btn.route }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text" },
                                                [_vm._v(_vm._s(btn.name))]
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(btn.sub || [], function(btnSub, j) {
                                return _c(
                                  "li",
                                  {
                                    key: `btnSubmenu-${j}`,
                                    staticClass: "content-btn"
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn-sub nav-link",
                                        attrs: { to: btnSub.route },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.setStatusMenuResponsive(
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("svgicon", {
                                          staticClass: "icon",
                                          attrs: { name: btnSub.icon }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(btnSub.name))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "position-relative w-100 align-self-end linc-app",
          attrs: {
            activeColor: _vm.auth.permission === "student" ? "blue" : "green"
          }
        },
        [
          _c("router-link", {
            staticClass: "btn-linc-app",
            attrs: {
              to: { name: `app-${_vm.auth.permission}` },
              tag: "button"
            },
            domProps: { innerHTML: _vm._s(_vm.$t("mainMenu.appHtml")) },
            nativeOn: {
              click: function($event) {
                return _vm.setStatusMenuResponsive(false)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }