var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-wrap align-items-center pt-sm-3 pb-sm-3 pt-2 pb-2",
      style: {
        maxWidth: _vm.max + "px",
        minWidth: _vm.min + "px",
        paddingLeft: "10px",
        paddingRight: "10px"
      }
    },
    [
      _c(
        "div",
        { staticClass: "text text-left position-relative" },
        [
          _c("svgicon", { staticClass: "icon", attrs: { name: _vm.icon } }),
          _vm._v(" "),
          _vm.hover
            ? _c("v-hover-txt", {
                staticClass: "d-block",
                attrs: { fontSize: "1rem", txt: _vm.txt, colorTxt: "#000" }
              })
            : _c("span", { staticClass: "txt1 d-block position-relative" }, [
                _vm._v(_vm._s(_vm.txt))
              ]),
          _vm._v(" "),
          _vm.txt2
            ? _c("span", { staticClass: "txt2 d-block position-relative" }, [
                _vm._v(_vm._s(_vm.txt2))
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }