
    import { Vue, Component, Prop } from "vue-property-decorator";
    import { State, Mutation, Action } from 'vuex-class';

    enum Ratings{
        higher ='Muy alto', // 20
        high = 'Alto', // 18/19
        aboveAverage = 'Por encima del promedio', // 17
        hightAverage = 'Promedio alto', // 16,
        average = 'Promedio', // 14/15
        lowAverage = 'Promedio bajo', // 13
        belowAverage = 'Por debajo del promedio', // 12
        low = 'Bajo', // 11
        lower = 'Muy bajo' // > 10
    }

    @Component
    export default class formatStudentSkills extends Vue {
        @Prop({ default: null, required: false }) readonly studentFormats: any;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.formats.studySkills ) statusStudySkills;
 
        @Mutation('handleFormat') handleFormat;
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;

        imReady: boolean = false;
        sending: boolean = false;
        error: boolean = false;
        ratings: any = Ratings;

        qst1: any = [];
        qst1Val: any = null;
        qst2: any = [];
        qst2Val: any = null;
        qst3: any = [];
        qst3Val: any = null;
        score: any = null;
        user: any = null;
        createdDate: any = null;
        finalRating: any = null;
        pdfData: any = {};

        get moment(): any{
            return (this as any).$moment;
        }

        get statusFormat(): boolean{
            const st = this.studentFormats?.forms;

            return st?st.studySkills:this.statusStudySkills;
        }

        created() {
            this.getFormat();
        }

        getFormat():void {
            const params = this.studentFormats?.studentId?{ params: { studentId: this.studentFormats?.studentId } }:{};

            this.axios({ config: {
                url: `${this.api}/tecnm/forms/study-skills`,
                method: 'GET',
                headers: { Authorization: this.token },
                ...params,
            } }).then( resp => resp.data )
                .then( data => {
                
                this.qst1 = data.organizationQuestions;
                this.qst2 = data.technicalQuestions;
                this.qst3 = data.motivationQuestions;
                
                if(this.statusFormat && data.created){
                    const formatDate = this.moment(data.created).locale(this.lang).format(this.$t('formatDateC'));
                    this.createdDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
                }

                if(this.statusFormat && data.score)
                    this.score = data.score;
                
                if(this.statusFormat && data.user)
                    this.user = data.user;
                    
                if(this.statusFormat){
                    this.pdfData = {
                         createdDate: this.createdDate, 
                         score: this.score.final, 
                         ...this.user, 
                         questions: [ { q: this.qst1, t: this.$t('formats.fm2.sub1') ,s: this.score.organization }, 
                                      { q: this.qst2, t: this.$t('formats.fm2.sub2'), s: this.score.technical }, 
                                      { q: this.qst3, t: this.$t('formats.fm2.sub3'), s: this.score.motivation } ]
                    }
                }

                this.imReady = true;
            }).catch( err => {
                this.imReady = true;
                console.log(err);
            } )
        }

        getRating(cont: number = 0): Promise<Ratings>{
            if(cont == 20)
                return this.ratings.higher;
            else if(cont == 18 || cont == 19)
                return this.ratings.high;
            else if(cont == 17)
                return this.ratings.aboveAverage;
            else if(cont == 16)
                return this.ratings.hightAverage;
            else if(cont == 14 || cont == 15)
                return this.ratings.average;
            else if(cont == 13)
                return this.ratings.lowAverage;
            else if(cont == 12)
                return this.ratings.belowAverage;
            else if(cont == 11)
                return this.ratings.low;
            else
                return this.ratings.lower;
        }

        getGeneralRating(cont: number = 0): Promise<Ratings>{
            if(cont <= 60 && cont >= 57)
                return this.ratings.higher;
            else if(cont <= 56 && cont >= 51)
                return this.ratings.high;
            else if(cont <= 50 && cont >= 49)
                return this.ratings.aboveAverage;
            else if(cont <= 48 && cont >= 47)
                return this.ratings.hightAverage;
            else if(cont <= 46 && cont >= 42)
                return this.ratings.average;
            else if(cont <= 41 && cont >= 38)
                return this.ratings.lowAverage;
            else if(cont <= 37 && cont >= 36)
                return this.ratings.belowAverage;
            else if(cont <= 35 && cont >= 33)
                return this.ratings.low;
            else
                return this.ratings.lower;
        }

        sendFormat(): void{
            if(!this.qst1Val?.status || !this.qst2Val?.status || !this.qst3Val?.status){
                this.error = true;
                this.notification({ type: 'warning', msg: 'Lorem ipsum dolor sit amet consectetur adipiscing elit congue natoque justo nec ac.' });
                return;
            }

            if(!this.sending){
                this.error = false;
                this.sending = true;

                this.axios({ config: {
                    url: `${this.api}/tecnm/forms/study-skills`,
                    method: 'POST',
                    headers: { Authorization: this.token },
                    data: {
                        organizationQuestions: this.qst1Val.questions,
                        technicalQuestions: this.qst2Val.questions,
                        motivationQuestions: this.qst3Val.questions,
                        rating: {
                            organization: this.getRating(this.qst1Val.cont),
                            technical: this.getRating(this.qst2Val.cont),
                            motivation: this.getRating(this.qst3Val.cont),
                            final: this.getGeneralRating(this.qst1Val.cont + this.qst2Val.cont + this.qst3Val.cont)
                        }
                    }
                } }).then( resp => resp.data )
                    .then( data => {
                    this.handleFormat({ format: 'studySkills', status: 1 });
                    this.sending = false;
                    this.notification({ type: 'success', msg: data.msg });
                    this.$emit('openMenu');
                }).catch( err => {
                    this.sending = false;
                    console.log(err);
                } )
            }
        }

        print(): void{
            (this as any).$refs.pdf.print();
        }
    }
