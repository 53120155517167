/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'woman': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 20h-15a.502.502 0 01-.494-.582c.272-1.632.55-2.669 1.238-3.473.687-.803 1.741-1.317 3.638-1.773.365-.088.918-.726 1.084-1.417.12-.5.022-.891-.292-1.161-1.037-.889-1.414-2.224-1.548-3.187-.162-1.168-.034-2.235.166-2.774C6.485 5.11 6.784 5 7 5c.444 0 1.795 0 2.649-.854a.5.5 0 01.708 0C11.211 5 12.558 5 13.001 5c.216 0 .515.11.708.633.199.539.328 1.606.166 2.774-.134.963-.511 2.298-1.548 3.187-.333.286-.443.679-.325 1.168.164.682.735 1.318 1.117 1.41 1.897.456 2.951.97 3.638 1.773.688.804.966 1.841 1.238 3.473a.5.5 0 01-.494.582zM3.096 19h13.809c-.458-2.434-1.046-3.14-4.021-3.856-.796-.192-1.611-1.135-1.855-2.148-.207-.859.023-1.627.646-2.161.912-.782 1.168-2.105 1.239-2.824.096-.964-.034-1.717-.136-2.012-.611-.011-1.803-.102-2.776-.837-.974.735-2.168.826-2.78.837-.102.296-.231 1.048-.136 2.012.071.719.327 2.042 1.239 2.824.605.519.823 1.284.614 2.154-.246 1.022-1.046 1.969-1.822 2.155-2.975.716-3.564 1.422-4.021 3.856z"/><path pid="1" d="M13.5 13a.5.5 0 01-.224-.948c.919-.46 2.51-.779 3.435-.936-.9-1.712-1.375-3.504-1.765-4.975-.153-.577-.285-1.075-.421-1.483-.439-1.318-1.397-2.271-2.562-2.55-.672-.161-1.31-.064-1.625.248a.502.502 0 01-.351.144h-.003a.5.5 0 01-.353-.149c-.295-.299-.929-.399-1.577-.248-1.173.273-2.137 1.228-2.579 2.555-.137.41-.269.91-.423 1.488-.391 1.476-.868 3.273-1.774 4.99.994.193 2.742.565 3.445.917a.5.5 0 01-.447.895c-.696-.348-3.025-.807-3.863-.955a.5.5 0 01-.342-.75C3.136 9.467 3.662 7.484 4.085 5.89c.158-.594.294-1.108.44-1.548.553-1.66 1.788-2.861 3.302-3.213.814-.189 1.602-.094 2.162.244.575-.342 1.379-.435 2.207-.237a4.323 4.323 0 011.891 1.016c.627.572 1.093 1.308 1.387 2.19.146.438.282.95.439 1.543.423 1.595.949 3.581 2.015 5.358a.499.499 0 01-.362.753c-.027.004-2.668.364-3.843.952a.497.497 0 01-.223.053z"/>'
  }
})
