import Vue from 'vue';

const chat = state => { // msgs chat
	let lastDate = '';
	let newChat = new Map();
	state.chat.map( msg => {
		const date = Vue.prototype.$moment(msg.createdAt).locale(state.i18n.locale).calendar(null,{ 
			sameDay: `[${Vue.prototype.$t('today')}]`,
			nextDay: `[${Vue.prototype.$t('tomorrow')}]`,
			lastDay: `[${Vue.prototype.$t('yesterday')}]`,
			nextWeek: 'dddd', // Monday, Tuesday ....
			lastWeek: Vue.prototype.$t('lessonFormatDate'), // Last Monday, Last Tuesday ....
			sameElse: Vue.prototype.$t('lessonFormatDate') // Monday 9th Aug 21
		});
		if (date !== lastDate){
			newChat.set('d'+date, { _id : 'd'+msg._id, date : date });
			lastDate = date;
		}
		if (!newChat.has(msg._id))
		    newChat.set(msg._id ,msg);
	});
	return Array.from(newChat.values());
}

const contChat = state => Object.values(state.msgs).length; // Count chats

const events = state =>state.events[0]?state.events[0]['.value']:null; // Action lesson (cancel, confirm, start ...)

export default{
	chat,
	contChat,
	events
}