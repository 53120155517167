/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sync': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.854 8.646a.5.5 0 00-.707 0l-1.149 1.149a7.94 7.94 0 00-2.341-5.452A7.948 7.948 0 0010 2a7.988 7.988 0 00-7.077 4.266.5.5 0 00.884.468A6.99 6.99 0 0110 3c3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143a.5.5 0 00-.707.707l2 2a.498.498 0 00.708 0l2-2a.5.5 0 000-.707zM16.869 13.058a.5.5 0 00-.676.208A6.99 6.99 0 0110 17c-3.789 0-6.885-3.027-6.997-6.789l1.143 1.143a.498.498 0 00.708 0 .5.5 0 000-.707l-2-2a.5.5 0 00-.707 0l-2 2a.5.5 0 00.707.707l1.149-1.149a7.94 7.94 0 002.341 5.452A7.948 7.948 0 0010.001 18a7.988 7.988 0 007.077-4.266.5.5 0 00-.208-.676z"/>'
  }
})
