/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-left-square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19 18.5v-16c0-.827-.673-1.5-1.5-1.5h-16C.673 1 0 1.673 0 2.5v16c0 .827.673 1.5 1.5 1.5h16c.827 0 1.5-.673 1.5-1.5zM1 2.5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v16a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5v-16z"/><path pid="1" d="M12.5 15.5a.502.502 0 00-.146-.354L7.708 10.5l4.646-4.646a.5.5 0 00-.707-.707l-5 5a.5.5 0 000 .707l5 5a.5.5 0 00.853-.354z"/>'
  }
})
