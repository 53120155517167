
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Getter } from 'vuex-class';

	@Component
	export default class listChats extends Vue{
		@Prop({ type: Array, default: [], required: true }) readonly chats!: Array<any>;
		
		@State('msgs') msgs;
		@State(state => state.auth.permission) userType;
		@State(state => state.i18n.locale) lang;
		@State(state => state.counters?.chats) counter;
		@State(state => state.auth.user.university_id) universityId;
		@State(state => state.auth.user.id) userId;
		@Getter('contChat') cont;

		get  orderChats(): Array<object>{
			if(this.chats.length > 0 && this.cont > 0){
				const that = this; 

                // Array order by counter and date
                return this.chats.sort( (a: any,b: any): any => {
                    if (that.msgs[a.id] && that.msgs[b.id]) {
                    	if (0 < that.msgs[b.id].counter)
                    		return 1;
                    	else if(that.msgs[a.id].counter > 0)
                    		return -1; 
                    	else if(that.msgs[a.id].counter === 0){
                    		if ((that.msgs[a.id].date || 0) < (that.msgs[b.id].date || 0))
                    			return 1;
                    		else
                    			return -1
                    	}
                    	return 0;
                    }
                } )
            }else{
                return []
            }
        }

        created(): void{
        	this.changeCounter();
        }

        changeCounter(): void{
        	const totalCounter = this.chats.reduce( (n, item) => {
				if(this.msgs[item.id])
                    n += this.msgs[item.id].counter;

                return n;
        	}, 0);
        	let counter = this.counter;
        	if(counter)
        	    if(counter[`${this.userType}_counter`])
        		    counter = counter[`${this.userType}_counter`];

        	if(counter !== totalCounter && counter)
        		(this as any).$firebase.db.ref().child(`/total_counter/${this.universityId}/${this.userId}/chats`).update({ [`${this.userType}_counter`]: totalCounter });
        }

        @Watch('chats')
        handleCounter(newVal){
            this.changeCounter();
        }
	}
