<template>
	<div class="v-joined-students" :class="{ active : show }" v-if="modalVal.modal=='v-modal-joinedStudents'">
		<div class="blurColor" @click="close()"></div>
		<div class="v-joined-students-modal">
			<h4 class="title pt-4 pb-3 pl-5 pr-5 mb-0">
				{{ $t('modals.join.t') }}
				<svgicon class="btn-close" name="cross2" @click="close()" />
			</h4>
			<div class="pt-3 pb-4 pl-5 pr-4">
				<perfect-scrollbar class="list-students pl-0 pr-4" tag="ul">
                    <li class="student d-flex align-items-center pt-2 pb-2 pr-4" v-for="student in currentLesson.students" :class="student.status?'join':''" :key="student.id">
                    	<v-img-profile class="mr-1" :img="student.photo" size="40px" />
                    	<div class="col pl-0">
                    		<span class="name d-block w-100">{{ student.name }}</span>
							<span class="subject d-block w-100" v-if="!student.guest">{{ student.career }}</span>
                    		<span class="subject d-block w-100" v-else>{{ student.guest ? $t('scheduleT.students.unconfirmed') : '' }}</span>
                    	</div>
                    </li>
				</perfect-scrollbar>
			</div>
		</div>
	</div>
</template>
<script>
	import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapGetters } from 'vuex';
	export default{
		mixins: [ modal ],
		computed:{
			...mapState(['currentLesson']),
			...mapGetters(['modalVal'])
		},
		methods:{
			...mapMutations(['setModal']),
			afterClose(){
				this.setModal('');
			}
		}
	}
</script>