const handleLang = ({state, commit}, payload) => {

	let ln = payload.split(/-|_/),
	    ln1 = ln[0]?ln[0].toLowerCase():'',
	    ln2 = ln[1]?ln[1].toUpperCase():'',
	    lang = 'en_US';

    if (state.i18n.translations[`${ln1}_${ln2}`])
    	lang = `${ln1}_${ln2}`;
    else if(state.langs[ln1])
    	lang = state.langs[ln1][0];

    commit('setLang', lang);
}

export default{
	handleLang
}