
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Action } from 'vuex-class';

    @Component
	export default class lessonTag extends Vue{
		@Prop({ required: true }) readonly type!: string;
		// Required { }, opcional { idLesson, lessonQuota, lessonLimit, height, minWidth, groupAction }
        // type: Grupal, Individual, Online, Presencial
        @Prop({ type: Object, default: () => { return {} } }) readonly options!: any;

        @State( state => state.auth.permission ) userType;

        @Action('beforeShowModal') beforeShowModal

        get t(): any{
            return (this as any).$t;
        }

        get height(): string{
        	if (this.options.height)
        		return this.options.height;
        	else
        		return '20px';
        }

        get minWidth(): string{
            if (this.options.minWidth)
                return this.options.minWidth;
            else
                return 'initial';
        }

        get bgColor(): string{
            if (this.type == 'Grupal')
            	return 'bg-lesson-group';
            else if(this.type == 'Individual')
            	return `bg-lesson-1-on-1`;
            else if(this.type == 'Online')
            	return 'bg-lesson-online';
            else
            	return 'bg-lesson-in-person';
        }

        get icon(): string{
        	if (this.type == 'Grupal'){
                if (this.options.lessonLimit)
            	   return 'eye-fill';
                else
                    return 'group';
            }
            else if(this.type =='Individual')
            	return 'user2';
            else if(this.type == 'Online')
            	return 'camera3';
            else
            	return 'faceToFace';
        }

        get txt1(): string | undefined{
        	if(this.type == 'Grupal' && this.options.lessonLimit){
        		if (this.userType == 'student' && !this.options.groupAction)
        			return this.$t('lessonType.g');
        		else{
        			if (this.options.lessonLimit && this.options.lessonQuota)
        				return `${this.options.lessonQuota}/${this.options.lessonLimit}`;
                    else if(this.options.lessonLimit && !this.options.lessonQuota)
                        return this.$t('lessonType.g');
        			else
        				return undefined;
        		}
            }
            else
            	return undefined;
        }

        get txt2(): string | undefined{
        	if (this.type == 'Grupal'){
        		if (this.userType == 'student' && !this.options.groupAction){
                    if (!this.options.lessonLimit)
            	       return this.$t('lessonType.g');
                    else
                        return `${this.options.lessonQuota}/${this.options.lessonLimit}`;
        		}
            	else{
                    if (!this.options.lessonLimit)
            		    return this.$t('lessonType.g');
                    else if(this.options.lessonLimit && !this.options.lessonQuota)
                        return `${this.options.lessonLimit} ${this.$t('lessonType.m')}`;
                    else
                        return undefined;
                        
                }
        	}
            else if(this.type == 'Presencial')
            	return this.$t('modality.p');
            else if(this.type == 'Online')
            	return this.$t('modality.o');
            else
            	return this.$t('lessonType.a');
        }
	} 
