import search from './search.vue'
import dropdown from './dropdown.vue'
import prevLink from './prev-link.vue';
import imgProfile from './img-profile.vue';
import vCircle from './circles.vue';
import slide from './slide.vue';
import btn from './btn-main.vue';
import notifications from './notifications.vue';
import spinner from './spinner.vue';
import moreInfo from './v-more-info.vue';
import hoverTxt from './v-hover-txt.vue';
import eventListener from './event-listener.vue';
import spinnerLogo from './spinner-logo.vue';

function plugin(Vue){
	Vue.component('v-search', search); //
	Vue.component('v-dropdown', dropdown); //
	Vue.component('v-prev-link', prevLink); //
    Vue.component('v-img-profile', imgProfile); //
    Vue.component('v-circles', vCircle); //
    Vue.component('v-slide-animation', slide); //
    Vue.component('v-btn', btn);
    Vue.component('v-notification', notifications); //
    Vue.component('v-spinner', spinner);
    Vue.component('v-more-info', moreInfo);
    Vue.component('v-hover-txt', hoverTxt);
    Vue.component('v-event-listener', eventListener);
    Vue.component('v-spinner-logo', spinnerLogo);
}

export default plugin
