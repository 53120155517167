/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loudspeaker': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.5 20h-11c-.827 0-1.5-.673-1.5-1.5v-16C3 1.673 3.673 1 4.5 1h11c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM4.5 2a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-11z"/><path pid="1" d="M10 7c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1c.551 0 1-.449 1-1s-.449-1-1-1zM10 18c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-9c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z"/><path pid="2" d="M10 15c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1c.551 0 1-.449 1-1s-.449-1-1-1z"/>'
  }
})
