
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { State, Getter, Mutation, Action } from 'vuex-class';
    import { TestSound } from "@/classes/TestSound";
    import {
        DeviceChangeObserver
    } from "amazon-chime-sdk-js";
    import Onboarding from '@/pages/room/onboarding.vue';
    import StudentRoom from '@/pages/room/studentRoom.vue';
    import TutorRoom from '@/pages/room/tutorRoom.vue';

    enum DeviceAlerts {
        prompt,
        block,
        micBlock,
        camBlock,
        empty,
        micEmpty,
        camEmpty,
        errorSystem,
        micErrorSystem,
        camErrorSystem,
        errorDevice,
        errorCam,
        errorMic
    }

    enum View {
        onboarding,
        room
    }

    type State = {
        devMode: boolean,
        loading: boolean,
        config: any,
        muteCam: boolean,
        camReady: boolean,
        muteMic: boolean,
        micReady: boolean,
        statusTestSound: boolean,
        lesson: any,
        durationFormat,
        users: Array<object>
    }

    @Component
    export default class Room extends Vue implements DeviceChangeObserver{
        
        options: State = {
            devMode: false,
            loading: false,
            config: undefined,
            muteCam: false,
            camReady: false,
            muteMic: false,
            micReady: false,
            statusTestSound: false,
            durationFormat: '',
            lesson: undefined,
            users: []
        }
        delayPermissionPrompt: any;
        delayEndRoom: any;
        currentView: View = View.onboarding;
        viewComponent: any = Onboarding;
        currentDeviceAlert: DeviceAlerts | undefined;
        statusModalAlert: Boolean = false;
        duration: any;
        durationInterval: any = '';

        private deviceAlerts = DeviceAlerts;
        private view = View;

        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.auth.user.id ) userId;
        @State( state => state.auth.activeLessons ) activeLessons;
        @State( state => state.auth.permission ) userType;
        @State('serverTime') serverTime;
        @State( 'askingForPermissions' ) askingForPermissions;
        @State('devices') devices;
        @State( state => state.permissions.mic ) permissionMic;
        @State( state => state.permissions.cam ) permissionCam;
        @State( state => state.typePermission ) typePermission;
        @State( 'currentVideoQuality' ) currentVideoQuality;
        @State( 'currentSpk' ) currentSpk;
        @State( state => state.currentMic?state.currentMic.deviceId:null ) currentMic;
        @State( state => state.currentCam?state.currentCam.deviceId:null ) currentCam;
        @State('meetingSession') meetingSession;
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour; // Supports SetSinkId
        @State('meetingEventPOSTLogger') meetingEventPOSTLogger;
        @State('priorityBasedDownlinkPolicy') priorityBasedDownlinkPolicy;

        @Getter('audioVideo') audioVideo;
        @Getter('events') events;

        @Mutation('setNotification') notification;
        @Mutation('setModal') setModal;
        @Mutation('setActiveLessons') setActiveLessons;
        @Mutation('handleServerTime') handleServerTime;
        @Mutation('handleVoiceFocus') handleVF;
        @Mutation('handleDevice') handleVuexDevice;
        @Mutation('handleCamTracks') handleCamTracks;
        @Mutation('handleMicTracks') handleMicTracks;
        
        @Action('axiosRequest') axios;
        @Action('authenticate') authenticate;
        @Action('setupDeviceLabelTrigger') setupDeviceLabelTrigger;
        @Action('populateAllDeviceLists') populateAllDeviceLists;
        @Action('populateAudioInputList') populateAudioInputList;
        @Action('populateVideoInputList') populateVideoInputList;
        @Action('populateAudioOutputList') populateAudioOutputList;
        @Action('initVoiceFocus') initVoiceFocus;

        get localStorage(): any{
            return (this as any).$localStorage;
        }

        get moment(): any{
            return (this as any). $moment;
        }

        get refs(): any{
            return (this as any).$refs;
        }

        async created(): Promise<void>{
            let config = this.localStorage.get('vrConf');
            if(config) this.options.config = JSON.parse(config);
            await this.getLesson();
            const { lesson } = this.options;

            if(lesson?.status == 'Activa'){ 
                await this.authenticate(lesson.id); // Star chime
                if (!this.defaultBrowserBehaviour.isSupported() && !this.defaultBrowserBehaviour.isIOSFirefox() && !this.defaultBrowserBehaviour.isIOSChrome()){
                    this.setModal('v-modal-alert:browser');
                    this.$router.push({ name: "dashboard-" + this.userType }); 
                }else{
                    await this.initializeMeetingSession();
                }
            }
            else
                this.$router.push({ name: "dashboard-" + this.userType }); 
        }

        beforeDestroy() {
            console.log('clear', this.delayEndRoom );
            if(this.delayEndRoom){
                clearTimeout(this.delayEndRoom);   
                this.notification({ type: "warning", noClose:'close', msg: '' });
            }
        }

        /*-- ========== room functions ========= */
        async getLesson(): Promise<void>{ // Get lesson data
            await this.axios({
                config: {
                    method: "GET",
                    url: `${this.api}/lesson/room/${this.$route.params.roomId}`,
                    headers: { Authorization: this.token }
                }
            }).then(resp => resp.data).then(data => {
                const myUser = data.users.find(user => user.id === this.userId);
                if (data.lesson[0].status === "Activa" && myUser) { // If lesson is active and if the current user is in the users array
                    if (myUser.type === this.userType) { // If the user is in the correct profile
                        if (this.options.users.length === 0) { // If array users is empty
                            this.options.lesson = data.lesson[0];
                            this.options.users = data.users;
                        } else { // If array users isn't empty
                            this.options.users = data.users;
                        }
                        this.getDuration();
                    } else {
                        this.notification({ type: "warning", msg: this.$t("room.permissions.msg") });
                    }
                } else {
                    this.notification({ type: "warning", msg: this.$t("room.permissions.msg") });
                }
            }).catch(error => {
                this.notification({ type: "error", msg: error.response.data.error.message });
            });
        }

        getDuration() : void{
            const { initialTime, serverTime, tz_scheduled } = this.options.lesson;
            const lt = initialTime.split(":");
            let duration = this.moment(serverTime);
            clearInterval(this.durationInterval);

            duration = duration.utcOffset(tz_scheduled); // timeZone
            duration = duration.subtract({ hours: lt[0], minutes: lt[1], seconds: lt[2] }); // Get hours, minutes and seconds
            this.duration = duration;
            this.getDurationFormat();
            const delay = ( 60 - duration.seconds()) * 1000;
            setTimeout(() => {
                this.duration = this.duration.add(1, "minutes");
                this.getDurationFormat();
                this.durationInterval = setInterval(() => {
                    this.duration = this.duration.add(1, "minutes");
                    this.getDurationFormat();
                }, 60000);
            }, delay); 
        }

        getDurationFormat(): void{
            const minutes = this.duration.minutes() + (this.duration.hours() * 60);
            let duration: string = '';
            if (minutes < 60 )
                duration =  `<span class="time-bold">${minutes}</span> min`;
            else{
                if (minutes%60===0)
                    duration =  `<span class="time-bold">${(minutes/60)}</span> hr`;
                else{
                    const hr = Math.trunc(minutes/60);
                    duration =  `<span class="time-bold">${hr}</span> hr <span class="time-bold">${minutes-(hr*60)}</span> min`;
                }
            }

            this.options.durationFormat = duration;
        }

        joinRoom(): void{
            this.currentView = this.view.room;
            if(this.userType == 'student')
                this.viewComponent = StudentRoom;
            else
                this.viewComponent = TutorRoom;
        }
        
        // Show devices modal alert
        handleDevice(device: string | undefined = undefined, muted: boolean = true): void{
            const { camReady, micReady, loading } = this.options;
            let allRight: boolean = false;
            // Asking for permissions
            if(this.currentDeviceAlert == this.deviceAlerts.prompt){
                this.currentDeviceAlert = undefined;
                allRight = true;
            }
            else if (!loading && this.askingForPermissions)
                this.currentDeviceAlert = this.deviceAlerts.prompt;
            // Permissions denied
            else if(loading && this.permissionMic == this.typePermission.denied && this.permissionCam == this.typePermission.denied){
                if (device == 'cam')
                    this.currentDeviceAlert = this.deviceAlerts.camBlock;
                else if (device == 'mic')
                    this.currentDeviceAlert = this.deviceAlerts.micBlock;
                else
                    this.currentDeviceAlert = this.deviceAlerts.block;
            }
            else if(loading && this.permissionCam == this.typePermission.denied && device != 'mic')
                this.currentDeviceAlert = this.deviceAlerts.camBlock;
            else if(loading && this.permissionMic == this.typePermission.denied && device != 'cam')
                this.currentDeviceAlert = this.deviceAlerts.micBlock;
            // No Devices
            else if(loading && this.permissionMic == this.typePermission.deviceEmpty && this.permissionCam == this.typePermission.deviceEmpty){
                if (device == 'cam')
                    this.currentDeviceAlert = this.deviceAlerts.camEmpty;
                else if (device == 'mic')
                    this.currentDeviceAlert = this.deviceAlerts.micEmpty;
                else
                    this.currentDeviceAlert = this.deviceAlerts.empty;
            }
            else if(loading && this.permissionCam == this.typePermission.deviceEmpty && device != 'mic')
                this.currentDeviceAlert = this.deviceAlerts.camEmpty;
            else if(loading && this.permissionMic == this.typePermission.deviceEmpty && device != 'cam')
                this.currentDeviceAlert = this.deviceAlerts.micEmpty;
            // Block by system
            else if(loading && this.permissionMic == this.typePermission.errorSystem && this.permissionCam == this.typePermission.errorSystem){
                if (device == 'cam')
                    this.currentDeviceAlert = this.deviceAlerts.camErrorSystem;
                else if (device == 'mic')
                    this.currentDeviceAlert = this.deviceAlerts.micErrorSystem;
                else
                    this.currentDeviceAlert = this.deviceAlerts.errorSystem;
            }
            else if(loading && this.permissionCam == this.typePermission.errorSystem && device != 'mic')
                this.currentDeviceAlert = this.deviceAlerts.camErrorSystem;
            else if(loading && this.permissionMic == this.typePermission.errorSystem && device != 'cam')
                this.currentDeviceAlert = this.deviceAlerts.micErrorSystem;
            // Device issue
            else if(loading && this.permissionMic == this.typePermission.errorDevice && this.permissionCam == this.typePermission.errorDevice){
                if (device == 'cam')
                    this.currentDeviceAlert = this.deviceAlerts.errorCam;
                else if (device == 'mic')
                    this.currentDeviceAlert = this.deviceAlerts.errorMic;
                else
                    this.currentDeviceAlert = this.deviceAlerts.errorDevice;
            }
            else if(loading && this.permissionCam == this.typePermission.errorDevice && device != 'mic')
                this.currentDeviceAlert = this.deviceAlerts.errorCam;
            else if(loading && this.permissionMic == this.typePermission.errorDevice && device != 'cam')
                this.currentDeviceAlert = this.deviceAlerts.errorMic;
            // Succesfull
            else
                allRight = true;
            
            // Active and disabled video buttons
            if (loading && !camReady && device != 'mic')
                this.options.muteCam = true;
            else if(device == 'cam' && muted)
                this.options.muteCam = !this.options.muteCam;

            if (loading && !micReady && device != 'cam')
                this.options.muteMic = true;
            else if(device == 'mic' && muted)
                this.options.muteMic = !this.options.muteMic;

            // Show alert if something opened
            this.statusModalAlert = !allRight;
        }

        /* Start chime */
        async initializeMeetingSession(): Promise<void>{ // Star meeting
            // Amazon voice focus
            const { config } = this.options;
            //if(typeof config?.microphone?.AVF == "boolean")
                //this.handleVF(config.microphone.AVF);

            // Video Quality
            let videoQuality = this.devices.videoQuality[1].deviceId;
            const videoQualityExist = this.devices.videoQuality.find( (i: any) => i.deviceId == config?.camera?.quality );
            if(videoQualityExist) videoQuality = config.camera.quality;
            this.handleVuexDevice({ type: 'VQ', deviceId: videoQuality });
            if (!this.currentVideoQuality)
                this.handleVideoQuality(videoQuality);

            this.audioVideo.addDeviceChangeObserver(this);
            this.setupDeviceLabelTrigger();
            await this.initEventListeners();
        }

        async initEventListeners(): Promise<void>{
            const { config } = this.options;
            await this.initVoiceFocus();
            await this.populateAllDeviceLists();

            // Microphone
            const micExist = this.devices.microphones.find( (i: any) => i.deviceId == config?.microphone?.id );
            if(micExist) this.handleVuexDevice({ type: 'mic', device: micExist });

            // Camera
            const camExist = this.devices.cameras.find( (i: any) => i.deviceId == config?.camera?.id );
            if(camExist) this.handleVuexDevice({ type: 'cam', device: camExist });

            // Audio autput
            const spkExist = this.devices.speakers.find( (i: any) => i.deviceId == config?.audioOutput?.id );
            if(spkExist) this.handleVuexDevice({ type: 'spk', deviceId: spkExist.deviceId });

            await this.openAudioOutputFromSelection(this.currentSpk);
            this.options.loading = true;
        }

        async reload(): Promise<void>{
            this.options.loading = false;
            await this.authenticate(this.options.lesson.id); // Star chime
            await this.initializeMeetingSession();
        }
        
        /* Video quality */
        handleVideoQuality(quality: string): void{
            console.log('Video input quality is changed to:', quality);
            switch (quality) {
                case '360p':
                    this.audioVideo.chooseVideoInputQuality(640, 360, 15, 600);
                break;
                case '480p':
                    this.audioVideo.chooseVideoInputQuality(854, 480, 15, 900);
                break;
                case '720p':
                    this.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
                break;
            }
        }

        /* Audio Output */
        async openAudioOutputFromSelection(deviceId: string | null): Promise<void> { // Change output audio device
            if (this.defaultBrowserBehaviour?.supportsSetSinkId()) {
                console.log('Selecting audio output', deviceId);
                try {
                    await this.audioVideo.chooseAudioOutputDevice(deviceId);
                } catch (e) {
                    console.log('failed to chooseAudioOutputDevice', e);
                }
            }
            const audioMix = this.refs.audioOutputElement as HTMLAudioElement;
            try {
                await this.audioVideo.bindAudioElement(audioMix);
            } catch (e) {
                console.log('failed to bindAudioElement', e);
            }
        }

        /* Test sound */
        playTestSound (): void{
            const audioOutput = this.currentSpk?this.currentSpk:'';
            const testSound = new TestSound(this.meetingEventPOSTLogger, audioOutput);
            this.options.statusTestSound = true;
            testSound.init();
            setTimeout( () => {
                this.options.statusTestSound = false;
            }, 2000)
        }

        /* Events of addDeviceChangeObserver */ 
        audioInputMuteStateChanged(device: string | MediaStream, muted: boolean): void {
            console.log('Mute state: device', device, muted ? 'is muted' : 'is not muted');
        }
        audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): void {
            if (this.options.loading)
                this.handleMicTracks(true);
            this.populateAudioInputList();
        }
        videoInputsChanged(_freshVideoInputDeviceList: MediaDeviceInfo[]): void {
            if (this.options.loading)
                this.handleCamTracks(true);
            this.populateVideoInputList();
        }
        audioOutputsChanged(_freshAudioOutputDeviceList: MediaDeviceInfo[]): void {
            this.populateAudioOutputList();
        }
        audioInputStreamEnded(deviceId: string): void {
            if(this.currentMic == deviceId)
                 this.options.muteMic = true;
            console.log(`Current audio input stream from device id ${deviceId} ended.`);
        }

        videoInputStreamEnded(deviceId: string): void {
            if(this.currentCam == deviceId)
                this.options.muteCam = true;
            console.log(`Current video input stream from device id ${deviceId} ended.`);
        }

        @Watch('askingForPermissions')
        showPropmtAlert(newVal: boolean): void{
            if(newVal){
                this.delayPermissionPrompt = setTimeout( () => {
                    this.handleDevice();
                }, 600)
            }
            else if(this.currentDeviceAlert != this.deviceAlerts.prompt && !newVal && !this.options.loading){
                clearTimeout(this.delayPermissionPrompt);
            }
        }

        @Watch('permissionCam')
        handleCamReady(newVal: boolean): void{
            this.options.camReady = !(newVal == this.typePermission.denied || newVal == this.typePermission.deviceEmpty || newVal == this.typePermission.errorSystem || newVal == this.typePermission.errorDevice);
        }

        @Watch('permissionMic')
        handleMicReady(newVal: boolean): void{
            this.options.micReady = !(newVal == this.typePermission.denied || newVal == this.typePermission.deviceEmpty || newVal == this.typePermission.errorSystem || newVal == this.typePermission.errorDevice);
        }

        @Watch('currentSpk')
        async audioOuputChange(newVal: string): Promise<void>{
            if (this.options.loading && this.meetingSession){
                console.log('audio output device is changed');
                await this.openAudioOutputFromSelection(newVal);
            }
        }

        @Watch('events')
        handleAuth(newVal: string): void{
            this.$nextTick(() => {
                const lessonLS = JSON.parse(this.localStorage.get("lesson"));
                if (newVal && lessonLS.lesson && this.options.lesson){
                    const lesson = newVal.split("_");
                    if (lesson[0] == this.options.lesson.id+'') {
                        if (lesson[1] === "pendingFeedback" || lesson[1] === "completed") {
                            const newArray = this.activeLessons.filter(i => i.id + "" !== lesson[0]);
                            this.setActiveLessons(newArray);
                            clearInterval(this.durationInterval);
                            if(this.currentView == this.view.onboarding){
                                this.$router.push({ name: "dashboard-" + this.userType });
                            }else if(this.options.lesson.status == 'Activa'){                  
                                this.options.lesson.status = 'Finalizada';
                                this.notification({ type: "warning", noClose: true, title: this.$t("room.permissions.titleFinish"), msg: this.$t("room.permissions.msgFinish", { time: 1 }) });
                                this.delayEndRoom = setTimeout( async () => {
                                    this.notification({ type: "warning", noClose:'close', msg: '' });
                                    await this.refs.viewRoom.endRoom();
                                }, 60000);
                            }
                        }
                        else if (lesson[1] === "userGroupJoined" && (this.permissionCam === this.typePermission.granted || this.permissionCam >= this.typePermission.errorSystem) && (this.permissionMic === this.typePermission.granted || this.permissionMic >= this.typePermission.errorSystem)) {
                            this.getLesson();
                        }
                    }
                }
            });
        }   
    }
