var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-lesson-place position-relative d-flex align-items-center"
    },
    [
      _c("svgicon", {
        staticClass: "v-lp-icon",
        attrs: { name: _vm.icon, color: "#000" }
      }),
      _vm._v(" "),
      _vm.userType == "student" ||
      (_vm.userType == "tutor" &&
        (_vm.options.modality == "Online" ||
          _vm.options.type == "Individual" ||
          !_vm.edit))
        ? _c("v-hover-txt", {
            staticClass: "pl-4",
            attrs: {
              colorTxt: "#000",
              lines: _vm.options.place ? 2 : 1,
              fontSize: "14px",
              fontWeight: "400",
              txt: _vm.place
            }
          })
        : _c(
            "button",
            {
              staticClass: "v-lp-btn cloud-hover w-100 text-left",
              on: {
                click: function($event) {
                  return _vm.beforeShowModal({
                    lesson: {
                      id: _vm.options.lessonId,
                      place: _vm.options.place
                    },
                    action: "v-modal-changePlace"
                  })
                }
              }
            },
            [
              _c("v-hover-txt", {
                staticClass: "pl-4",
                attrs: {
                  colorTxt: _vm.options.place ? "#72BD00" : "#DE3030",
                  lines: _vm.options.place ? 2 : 1,
                  fontSize: "14px",
                  fontWeight: "400",
                  txt: _vm.place
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }