var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selected-list-item paper w-100" }, [
    _c("h4", { staticClass: "title-list text-center mb-0" }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-items d-flex flex-column" },
      [
        _vm.statusSearch
          ? _c(
              "div",
              {
                staticClass: "list-item-search",
                class: { disabled: _vm.disabledSearch && _vm.statusSearch }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.valueSearch,
                      expression: "valueSearch"
                    }
                  ],
                  attrs: {
                    type: "text",
                    disabled: _vm.disabledSearch && _vm.statusSearch,
                    placeholder: _vm.$t("scheduleLesson.subjects.search")
                  },
                  domProps: { value: _vm.valueSearch },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.valueSearch = $event.target.value
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.msgEmpty && _vm.statusSearch
          ? _c(
              "div",
              { staticClass: "content-msg d-flex justify-content-center p-3" },
              [
                _c("p", { staticClass: "msg-list" }, [
                  _vm._v(_vm._s(_vm.msgEmpty))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "perfect-scrollbar",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.setListItems.length > 0,
                expression: "setListItems.length > 0"
              }
            ],
            ref: "scroll",
            staticClass: "pl-0 mb-0",
            attrs: { tag: "ul", options: { wheelSpeed: 1 } }
          },
          [
            _vm._l(_vm.listItems, function(item, index) {
              return _c(
                "li",
                {
                  key: item.id,
                  staticClass: "btn-list pl-3",
                  class: {
                    "pr-3": !item[_vm.fieldIcon],
                    "list-item-active": _vm.activeItem === item.id
                  },
                  on: {
                    click: function($event) {
                      _vm.send(item)
                      _vm.doSomething()
                    }
                  }
                },
                [
                  _c("v-hover-txt", {
                    attrs: {
                      fontSize: "16px",
                      fontWeight: "400",
                      positionCloud: index == 0 ? "top" : "bottom",
                      txt: item.name
                    }
                  }),
                  _vm._v(" "),
                  item[_vm.fieldIcon]
                    ? _c("svgicon", {
                        staticClass: "icon",
                        attrs: { name: item[_vm.fieldIcon], color: item.color }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.statusSpinner
              ? _c(
                  "div",
                  {
                    key: "spinner",
                    staticClass: "lesson-spinner w-100 p-3 text-center"
                  },
                  [
                    _c("v-spinner", {
                      attrs: { height: "25px", width: "25px" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }