var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-content-question row" }, [
    _c(
      "div",
      { staticClass: "col pl-sm-4 pl-3 pt-2 pb-2 d-flex align-items-center" },
      [
        _c("p", { staticClass: "v-question mb-0" }, [
          _vm._v(_vm._s(_vm.question.question))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-auto" }, [
      _c("div", { staticClass: "row h-100" }, [
        _c(
          "div",
          {
            staticClass:
              "v-content-question-radio col-sm-auto col-6 pt-2 pb-2 h-100 d-flex justify-content-center align-items-center"
          },
          [
            _c("div", {
              staticClass: "v-question-radio",
              class: {
                active: _vm.val == 1,
                error: _vm.error && _vm.val == null,
                disabled: _vm.statusFormat
              },
              on: {
                click: function($event) {
                  return _vm.handleAnswer(1)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "v-answer col-auto pl-2 pr-2 d-sm-none" },
              [
                _c("div", { staticClass: "v-fq-title-txt" }, [
                  _vm._v(_vm._s(_vm.$t("formats.fm2.tableAYes")))
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "v-content-question-radio col-sm-auto col-6 pt-2 pb-2 h-100 d-flex justify-content-center align-items-center"
          },
          [
            _c("div", {
              staticClass: "v-question-radio",
              class: {
                active: _vm.val == 0,
                error: _vm.error && _vm.val == null,
                disabled: _vm.statusFormat
              },
              on: {
                click: function($event) {
                  return _vm.handleAnswer(0)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "v-answer col-auto pl-2 pr-2 d-sm-none" },
              [
                _c("div", { staticClass: "v-fq-title-txt" }, [
                  _vm._v(_vm._s(_vm.$t("formats.fm2.tableANo")))
                ])
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }