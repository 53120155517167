/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'end-call': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#E82719" cx="21" cy="21" r="21"/><path pid="1" d="M7.828 25.536c-1.304-3.266.491-5.924 1.206-6.66.21-.217 3.306-3.764 12.464-4.012 7.37-.2 11.691 3.3 12.465 4.014.753.695 2.34 2.752 1.207 6.662-.14.484-.672 1.183-.985 1.183-.139 0-5.61-1.376-5.777-1.437a1.071 1.071 0 01-.414-.258c-.156-.16-.24-.474-.25-.943a8.5 8.5 0 01.08-1.405c.067-.469.102-.93.106-1.382.003-.452-.065-.75-.205-.895-.378-.358-1.975-1.392-6.232-1.109-4.258.283-5.91.83-6.054.897-.144.068-.25.139-.32.21-.14.145-.209.443-.205.895.005.453.276 2.24.264 2.708-.011.469-.095.783-.25.943a1.069 1.069 0 01-.414.258c-.167.06-5.569 1.516-5.7 1.515-.358-.005-.8-.716-.986-1.184" _fill="#FFF" fill-rule="nonzero"/></g>'
  }
})
