import mutations from './mutations.js'
import getters from './getters.js';
import actions from './actions.js';

let state = {
	currentLesson:'',
	lessonChanges: undefined,
	statusOngoingLesson: false,
	showModal: '',
	serverTime: null
}

export default{
	state,
	actions,
	mutations,
	getters
}