/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'database-lock': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.067 1.609c-.497-.326-1.193-.615-2.069-.858-1.742-.484-4.05-.75-6.498-.75s-4.756.267-6.498.75c-.877.243-1.573.532-2.069.858C.314 2.016 0 2.483 0 3v12c0 .517.314.985.933 1.391.497.326 1.193.615 2.069.858 1.742.484 4.05.75 6.498.75.685 0 1.369-.021 2.031-.063a.5.5 0 10-.063-.999c-.642.041-1.304.061-1.969.061-2.36 0-4.573-.254-6.23-.714-1.795-.499-2.27-1.059-2.27-1.286v-2.566c.492.309 1.164.583 2.002.816 1.742.484 4.05.75 6.498.75a31.36 31.36 0 003.049-.145.5.5 0 00-.097-.995c-.945.093-1.938.14-2.951.14-2.36 0-4.573-.254-6.23-.714C1.475 11.785 1 11.225 1 10.998V8.432c.492.309 1.164.583 2.002.816 1.742.484 4.05.75 6.498.75s4.756-.267 6.498-.75c.838-.233 1.511-.507 2.002-.816v1.566a.5.5 0 001 0v-7c0-.517-.314-.985-.933-1.391zM3.27 1.714C4.928 1.254 7.14 1 9.5 1s4.573.254 6.23.714C17.525 2.213 18 2.773 18 3s-.474.787-2.27 1.286C14.072 4.746 11.86 5 9.5 5s-4.573-.254-6.23-.714C1.475 3.787 1 3.227 1 3s.474-.787 2.27-1.286zm12.46 6.572C14.072 8.746 11.86 9 9.5 9s-4.573-.254-6.23-.714C1.475 7.787 1 7.227 1 7V4.434c.492.309 1.164.583 2.002.816C4.744 5.734 7.052 6 9.5 6s4.756-.267 6.498-.75c.838-.233 1.511-.507 2.002-.816V7c0 .227-.474.787-2.27 1.286z"/><path pid="1" d="M19 14.086V13.5c0-1.378-1.122-2.5-2.5-2.5S14 12.122 14 13.5v.586c-.582.206-1 .762-1 1.414v3c0 .827.673 1.5 1.5 1.5h4c.827 0 1.5-.673 1.5-1.5v-3c0-.652-.418-1.208-1-1.414zM16.5 12c.827 0 1.5.673 1.5 1.5v.5h-3v-.5c0-.827.673-1.5 1.5-1.5zm2.5 6.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3z"/>'
  }
})
