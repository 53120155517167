
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State, Mutation, Action } from 'vuex-class';

    @Component
    export default class formatSelfEsteem extends Vue {
        @Prop({ default: null, required: false }) readonly studentFormats: any;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.formats.selfEsteem ) statusSelfEsteem;
 
        @Mutation('handleFormat') handleFormat;
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;

        imReady: boolean = false;
        sending: boolean = false;
        error: boolean = false;
        createdDate: any = null;
        user: any = null;
        qst: any = null;
        qstVal: any = null;
        result: number = 0;
        pdfData: any = {};

        get moment(): any{
            return (this as any).$moment;
        }

        get statusFormat(): boolean{
            const st = this.studentFormats?.forms;

            return st?st.selfEsteem:this.statusSelfEsteem;
        }

        created(): void {
            this.getFormat();
        }

        getFormat(): void{
            const params = this.studentFormats?.studentId?{ params: { studentId: this.studentFormats?.studentId } }:{};

            this.axios({ config: {
                method: 'GET',
                url: `${this.api}/tecnm/forms/self-esteem`,
                headers: { Authorization: this.token },
                ...params
            } }).then( resp => resp.data )
                .then( data => {
                    this.qst = data.selfEsteemQuestions;

                    this.result = data.score;

                    if(this.statusFormat && data.created){
                        const formatDate = this.moment(data.created).locale(this.lang).format(this.$t('formatDateC'));
                        this.createdDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
                    }
                    
                    if(this.statusFormat && data.user)
                        this.user = data.user;

                    if(this.statusFormat){
                        this.pdfData = { 
                            createdDate: this.createdDate, 
                            ...this.user, 
                            q: this.qst, 
                            score: this.result 
                        }
                    }

                    this.imReady = true;
            }).catch( err => {
                this.imReady = true;
                console.log(err);
            } )
        }

        getScore(): number{
            let store = 0,
                max = 0;
            let a = 0,
                b = 0,
                c = 0,
                d = 0;
            
            this.qstVal.questions.forEach( item  => {
                const val = this.qst.find( q => q.id == item.id ).answers.find( a => a.id == item.answer ).val;

                if(val == 1)
                    a += 1;
                else if(val == 2)
                    b += 1;
                else if(val == 3)
                    c += 1;
                else if(val == 4)
                    d += 1;
            });

            for(let i: number = 0; i < 4; i++){
                let aux = 0,
                    s = 0;

                if(i == 0){ 
                    aux = a;
                    s = 1;
                }
                else if(i == 1){
                    aux = b;
                    s = 2;
                }
                else if(i == 2){
                    aux = c;
                    s = 3;
                }
                else if(i == 3){
                    aux = d;
                    s = 4;
                }

                if(max < aux){
                    max = aux;
                    store = s;
                }
            }
            
            return store;
        }

        sendFormat(): void{
            if(!this.qstVal?.status){
                this.error = true;
                this.notification({ type: 'warning', msg: 'Lorem ipsum dolor sit amet consectetur adipiscing elit congue natoque justo nec ac.' });
                return;
            }

            if(!this.sending){
                this.error = false;
                this.sending = true;

                this.axios({ config: {
                    url: `${this.api}/tecnm/forms/self-esteem`,
                    method: 'POST',
                    headers: { Authorization: this.token },
                    data: {
                        selfEsteemQuestionsAnswers: this.qstVal.questions.reduce( (arr, item) => {
                            arr = [ ...arr, item.answer ];

                            return arr;
                        },[]),
                        score: this.getScore()
                    }
                } }).then( resp => resp.data )
                    .then( data => {
                    this.handleFormat({ format: 'selfEsteem', status: 1 });
                    this.sending = false;
                    this.notification({ type: 'success', msg: data.msg });
                    this.$emit('openMenu');
                }).catch( err => {
                    this.sending = false;
                    console.log(err);
                } )
            }
        }

        print(): void{
            (this as any).$refs.pdf.print();
        }
    }
