var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-chat pb-3 pt-3 h-100",
      on: {
        dragover: function($event) {
          _vm.showDropZone = !_vm.statusFilePreview ? true : _vm.showDropZone
        }
      }
    },
    [
      _c(
        "perfect-scrollbar",
        {
          key: _vm.refreshChat,
          ref: "v-chat",
          staticClass: "pl-3 pr-3 mb-3 h-100",
          style: {
            "--scroll-color":
              _vm.auth.permission == "tutor" ? "#72BD00" : "#00C0ff"
          },
          attrs: { tag: "div", options: _vm.options }
        },
        [
          _c(
            "ul",
            {
              staticClass: "v-content-chat mb-0 h-100 pt-4",
              class: { "chat-t": _vm.auth.permission === "tutor" }
            },
            [
              _vm.idLesson && _vm.usersMap.size > 0
                ? _c(
                    "transition-group",
                    {
                      staticClass: "w-100 tran-chat",
                      attrs: { name: "bound-chat", tag: "div" }
                    },
                    [
                      _vm.loadingMsgs
                        ? _c(
                            "div",
                            { key: "spinner", staticClass: "chat-spinner p-3" },
                            [
                              _c("v-spinner", {
                                attrs: { width: "40px", height: "40px" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.chat, function(item, i) {
                        return _c(
                          "li",
                          {
                            key: item._id + "",
                            staticClass: "position-relative"
                          },
                          [
                            !item.date
                              ? _c("v-chat-bubble", {
                                  attrs: {
                                    type: item.file ? "file" : "text",
                                    idLesson: _vm.idLesson,
                                    msg: item,
                                    userMap: _vm.usersMap.get(
                                      parseInt(item.uid)
                                    ),
                                    showAvatar:
                                      i == 0
                                        ? true
                                        : _vm.chat[i - 1].uid !== item.uid,
                                    iconFile: _vm.getIcon(item.file),
                                    iconPreview: _vm.showPreview(item.file)
                                      ? "magnifying2"
                                      : "download3"
                                  },
                                  on: {
                                    showPreview: function($event) {
                                      return _vm.handleFilePreview(item, true)
                                    }
                                  }
                                })
                              : _c(
                                  "div",
                                  { staticClass: "v-chat-date text-center" },
                                  [_vm._v(_vm._s(item.date))]
                                )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !(_vm.idLesson && _vm.usersMap.size > 0)
        ? _c(
            "div",
            { staticClass: "v-chat-msg text-center" },
            [
              _c("svgicon", {
                staticClass: "img mb-2",
                attrs: { color: "#F0F0F0", name: "chat2" }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "msg mb-1" }, [
                _vm._v(_vm._s(_vm.$t("chat.selectAChat")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.idLesson && _vm.usersMap.size > 0
        ? _c("v-chat-tools", {
            ref: "chatInput",
            staticClass: "row align-items-center ml-0 mr-0",
            attrs: { disabled: _vm.disabled },
            on: {
              send: _vm.sendMsg,
              loadFile: function($event) {
                return _vm.$refs.dropzone.loadFile()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled
        ? _c("v-dropzone", {
            ref: "dropzone",
            attrs: { show: _vm.showDropZone, idLesson: _vm.idLesson },
            on: {
              handleDropzone: band => (_vm.showDropZone = band),
              saveFile: _vm.saveFile
            },
            nativeOn: {
              dragleave: function($event) {
                _vm.showDropZone = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.statusFilePreview
        ? _c("v-file-preview", {
            attrs: { options: _vm.optionsFilePreview },
            on: {
              download: function($event) {
                return _vm.downloadFile({
                  file: _vm.optionsFilePreview.file,
                  name: _vm.optionsFilePreview.name,
                  idLesson: _vm.idLesson
                })
              },
              close: file => _vm.handleFilePreview(file, false)
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }