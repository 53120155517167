<template>
	<div id="search-results" class="container-fluid">
		<div class="row">
			<div class="content-filters col-auto pt-4" :class="{ 'no-fix': menu.compact, active: statusFilters }">
				<div class="blurcolor d-block d-xl-none" :class="{ 'd-lg-none' : menu.compact }" @click="statusFilters = false"></div>
				<div class="filters" >
					<div class="paper">
						<h4 class="title-filters pl-3 pr-3 mb-0 position-relative">{{ $t('searchResult.filters.t') }} <svgicon class="btn-close d-block d-xl-none" :class="{ 'd-lg-none' : menu.compact }" @click="statusFilters = false" name="cross2"/></h4>
						<form class="pl-3 pr-3 pt-4 pb-4">
							<v-range class="mb-3" :title="$t('searchResult.filters.hour')" v-model="filters.times" startRange="06:00" endRange="23:00" type="time" iconStart="sun" iconEnd="moon" />
							<v-checkbox class="mb-3" :title="$t('searchResult.filters.availability')" v-model="filters.dates" :items="days" :vals="[ 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo' ]" />	
							<!--<v-range class="mb-3" :title="$t('searchResult.filters.age')" v-model="filters.age" :startRange="18"  :endRange="100" iconStart="walk" iconEnd="tired" />-->
							<!--<v-radio-button class="mb-3" :title="$t('searchResult.filters.gender')" v-model="filters.gender" :statusAny="true" :items="[$t('gender.m'),$t('gender.f'),$t('gender.o')]" :vals="['M','F','O']" :bgColor="['primary','pink','secundary']" />-->
							<v-radio-button class="mb-3" :title="$t('searchResult.filters.type')" v-model="filters.type" :statusAny="true" :items="[$t('lessonType.g'),$t('lessonType.a')]" :vals="['Grupal','Individual']" :bgColor="['yellow','primary']" />
							<v-radio-button class="mb-3" :title="$t('searchResult.filters.mode')" v-model="filters.modality" :statusAny="true" :items="[$t('modality.o'),$t('modality.p')]" :vals="['Online','Presencial']" :bgColor="['green','purple']" />	
						</form>
					</div>
				</div>
			</div>
			<div class="content-results col-md pb-5">
				<div class="mt-4 mb-3 w-100 d-flex">
					<!-- =========== btn-filters ============ -->
					<button class="btn-filters mr-2 mr-sm-3 pl-2 pr-2 d-block d-xl-none" :class="{ 'd-lg-none' : menu.compact }" @click="statusFilters = true">
						<svgicon class="icon-equalizer" name="equalizer" />
						<span class="txt-filters">{{ $t('searchResult.filtersBtn') }}</span>
					</button>
					<!-- ================ Search ================== -->
		            <v-search class="col pl-0 pr-0" ref="search" :data="[]" :removeTxtInput="false" :fields="[ 'name' ]" :defaultVal="getSubject"></v-search>
				</div>
				

		        <!-- ================ Results ================== -->
		        <div class="results" ref="tutors">
		        	<h2 class="title-results mb-3 mt-4">{{ $t('searchResult.tutorsFound', { count: totalTutors, subject: getSubject }) }}</h2>
		        	<transition name="bound" mode="out-in">
		        		<div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mb-3" v-if="tutors.length === 0 && !spinner" key="alert">
		        			<svgicon class="img pl-3 pr-3" original name="dashboards1" />
		        			<div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
		        				<p class="txt mb-3" v-html="$t('msgEmpty.search')"></p>
		        			</div>
		        		</div>
		        		<transition-group name="bound" key="results" class="subjects-list pl-0" tag="ul" v-else>
		        			<card-tutor class="mb-3" :class="`ct-${index}`" v-for="(tutor,index) in tutors" :key="tutor.id" :datas="tutor" :currentSubject="getSubject" @scrollTo="scrollTo(index)" />
		        			<div class="lesson-spinner w-100 p-4 text-center" key="spinner" v-if="spinner">
		        				<v-spinner width="65px" height="65px"  />
		        			</div>
		        		</transition-group>
		            </transition>
		        </div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		data(){
			return{
				spinner: false,
				isReady: false,
				filters: {
					limit: 8,
					offset: 0,
				    times: ['06:00','23:00'],
				    age: [18,100],
				    dates: [ 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes' ],
				    gender: [],
				    type: [],
				    modality: [],
			    },
			    timer: '',
			    tutors: [],
			    totalTutors: 0,
			    subject: {},
			    statusFilters: false
			}		
		},
		computed:{
            ...mapState(['auth', 'browser', 'menu']),
            getSubject(){
                if (this.browser.txtBrowser===''){
                	const paramPath = this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/')+1);
                	let subject = '';
                	if (this.$route.params.subject === paramPath){
                		subject = decodeURIComponent(this.$route.params.subject.replace(/\+/g, " ").replace(/%2B/g, "+"));
                		subject = subject.charAt(0).toUpperCase()+subject.slice(1);
                	}
                	else{
                		subject = decodeURIComponent(paramPath.replace(/\+/g, " ").replace(/%2B/g, "+"));
                		subject = subject.charAt(0).toUpperCase()+subject.slice(1);
                	}
                	return subject;
				}else{
					return this.browser.txtBrowser;
				}
            },
            days(){
            	return [ this.$t('days.monday'), this.$t('days.tuesday'), this.$t('days.wednesday'), this.$t('days.thursday'), this.$t('days.friday'), this.$t('days.saturday'), this.$t('days.sunday') ]
            }
		},
		methods: {
			...mapMutations(['setTxtBrowser', 'setResults']),
			...mapActions(['axiosRequest']),
			getTutors(){
				this.axiosRequest({ config : {
					method: 'GET',
                    url: `${this.auth.api}/search/subject`,
                    params: ({ subject_name: this.getSubject,
                    	       limit: this.filters.limit,
                    	       offset: this.filters.offset,
                    	       time1: this.filters.times[0], 
                    	       time2: this.filters.times[1], 
                    	       ageFrom: this.filters.age[0]==18&&this.filters.age[1]==100?null:this.filters.age[0],
                    	       ageTo: this.filters.age[0]==18&&this.filters.age[1]==100?null:this.filters.age[1],
                    	       gender: this.filters.gender.length===3?'':this.filters.gender[0],
                    	       gender2: this.filters.gender.length===2?this.filters.gender[1]:'',
                    	       type: this.filters.type.length>1?'':this.filters.type[0],
                    	       modality: this.filters.modality.length>1?'':this.filters.modality[0],
                    	       dates: this.filters.dates }),
                    headers: { authorization : this.auth.token }
				}}).then( resp => resp.data)
                .then( data => {
                	this.totalTutors = data.total;
                	if (this.filters.offset == 0) 
                	    this.tutors = data.tutors; 
                	else
                		this.tutors = [...this.tutors, ...data.tutors]
                	this.spinner = false;
                }).catch( error => {
                    this.spinner = false;
                });
			},
			getTutorsOnChange(){
				if (this.isReady) {
				    var that = this;
				    clearTimeout(this.timer);
				    if (this.tutors === 0){
				    	this.spinner = true;
				    	this.getTutors();
				    }
				    else{
				    	this.spinner = true;
				    	this.tutors = [];
				    	this.timer = setTimeout(() => {
				    		this.filters.offset= 0;
				    		that.getTutors();
				    	}, 1000);
				    }
			    }
			},
			scroll(){
				const scrollVal = window.scrollY,
				      windowH = window.innerHeight,
				      contentH = document.getElementById('search-results').offsetHeight,
				      headerH = document.getElementsByTagName('header')[0].offsetHeight;

				if ((scrollVal+windowH-headerH)>(contentH-200) && (this.filters.offset+this.filters.limit) < this.totalTutors && this.tutors.length > 0 && !this.spinner){
				    this.filters.offset += this.filters.limit;
				    this.spinner = true;
				    this.getTutors();
				}
			},
			onKeyPress(e){ // Close modal on key esc press
				if ("key" in e) {
					if (e.key === "Escape" || e.key === "Esc") {
						this.statusFilters = false;
					}
				}else if(e.keyCode === 27){
					this.statusFilters = false;
				}
		    },
		    scrollTo(i){
		    	this.$scrollTo(`.ct-${i}`, 500, { offset: -75 });
		    }
		},
		created(){
			const { subject, modality } = this.$route.params;
			/*if(modality)
                this.filters.type[0] = modality.charAt(0).toUpperCase()+modality.slice(1);*/
            this.spinner = true;
		},
		mounted(){
			this.isReady = true;
            this.$watch('filters.limit', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.times', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.age', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.dates', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.gender', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.type', () => {
                this.getTutorsOnChange();
            });
            this.$watch('filters.modality', () => {
                this.getTutorsOnChange();
            });
            document.addEventListener('scroll',this.scroll);
            document.addEventListener('keydown', this.onKeyPress);
		},
		beforeDestroy(){
			this.setTxtBrowser('');
			this.setResults({ subjects: [], tutors: [] });
			document.removeEventListener('scroll',this.scroll);
			document.removeEventListener('keydown', this.onKeyPress);
		},
		watch: {
			'$route.params.subject': function(){
				this.isReady = true;
				this.getTutorsOnChange();
			}
		}
	}
</script>