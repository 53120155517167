/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sigma': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.5 18h-10a.498.498 0 01-.407-.79l4.792-6.709-4.792-6.709a.5.5 0 01.407-.79h10a.5.5 0 010 1H5.472l4.435 6.209a.5.5 0 010 .581l-4.435 6.209H14.5a.5.5 0 010 1z"/>'
  }
})
