
    import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
    import { State, Action } from 'vuex-class';

    enum StepsDropdown{
        blur,
        focus,
        hover,
        click
    }

    enum StepsResults{
        none,
        spinner,
        results,
        message
    }

    @Component
    export default class schSubjectsT extends Vue {
        @Prop({ default: '' }) readonly value: any;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.grid ) grid;
        @Action('axiosRequest') axios;

        val: any = '';
        lastResults: any = '';
        stepsDropdown: any = StepsDropdown;
        stepsResults: any = StepsResults;
        statusDropdown: StepsDropdown = StepsDropdown.blur;
        statusResults: StepsResults = StepsResults.none;
        keyPoss: number = -1;
        delay: any = '';
        resultHover: string = '';
        results: Array<object> = [];
        filterResults: Array<object> = [];
        noWatch: boolean = false;

        get refs(): any{
            return (this as any).$refs;
        }

        async getSubjects(): Promise<void> {
            const resp = await this.axios({ config: {
                method: 'GET',
                url: `${this.api}/tutors/subjects`,
                headers: { Authorization: this.token }
            } });

            if(resp.status == 200){
                this.results = resp.data.subjects;
            }else{
                this.statusResults = this.stepsResults.message;
            }
        }

        handleSubject(subject): void{ // Click item dropdown
            (this as any).$emit('input', subject);
            this.statusDropdown = this.stepsDropdown.click;

            if(this.val != subject.name){
                this.noWatch = true;
                this.val = subject.name;
            }

            this.blurAction();
		}

        async toggleAction(): Promise<void>{
            if (this.statusDropdown == this.stepsDropdown.blur)
            	this.focusAction();
            
            if(this.results.length == 0){
                this.statusDropdown = this.stepsDropdown.focus;
                this.statusResults = this.stepsResults.spinner;
                await this.getSubjects();
                this.filterResults = this.results;

                if(this.filterResults.length > 0)
                    this.statusResults = this.stepsResults.results;
                else
                    this.statusResults = this.stepsResults.message;
            }
        }
        focusAction(): void{
            if (this.statusDropdown == this.stepsDropdown.blur || this.statusDropdown == this.stepsDropdown.focus)
            	this.refs.input.focus();
        }
        blurAction(): void{
            if (this.statusDropdown != this.stepsDropdown.hover) 
            	this.statusDropdown = this.stepsDropdown.blur;
        }
        hoverAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.focus)
        		this.statusDropdown = this.stepsDropdown.hover;
        }
        leaveAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.hover) {
        		this.statusDropdown = this.stepsDropdown.focus; 
        		this.focusAction();
        	}
        }

        keyFuction(key): void{ // Focus y enter sobre el dropdown
            if(key == 'ArrowUp'  || key == 'ArrowDown' || key == 'Enter'){
			    const scrollBar = this.refs.scroll,
                      dropdown = scrollBar.$el,
				      dropdowns = this.refs.dropdownItem;
				  
                if (this.keyPoss > 0 && key == 'ArrowUp') {
				    this.keyPoss--;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop > dropdowns[this.keyPoss].offsetTop )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop - 60;
			    }
                else if (this.keyPoss < dropdowns.length-1 && key == 'ArrowDown') {
				    this.keyPoss++;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop < ((dropdowns[this.keyPoss].offsetTop + 60) - dropdown.offsetHeight) )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop + 60;
			    }
                else if (this.keyPoss > -1 && key == 'Enter'){
                    dropdowns[this.keyPoss].click();
			    }
            }
		}

        findText(search, arr: Array<string>): boolean{
            let param = RegExp(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i");
				
            let status = false;
            arr.forEach( (item: string)  => {
                let textNormalize = item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    pos = textNormalize.search(param);

                if(pos >= 0) status = true;
            });

            return status;
        }

        @Watch('value')
        reset(newVal: any): void{
            if(!newVal){
                this.val = '';
                this.noWatch = true;
            }
        }

        @Watch('val')
        search(newVal: any, oldVal: any): void{
            const newV = newVal.trim();

            clearTimeout(this.delay);

            if(!this.noWatch){
                this.statusResults = this.stepsResults.spinner;

            	this.delay = setTimeout( async () => {
                    this.statusDropdown = this.stepsDropdown.focus; 
            		if (newV != this.lastResults){
                        if(this.results.length == 0)
                            await this.getSubjects();
                            
                        this.filterResults = await this.results.filter( (r: any) => {
                            return this.findText(newVal, [r.name, r.category]);
                        } );
   
                        this.lastResults = newV;
            			this.delay = '';
            		}

                    if(this.filterResults.length > 0)
                        this.statusResults = this.stepsResults.results;
                    else
                        this.statusResults = this.stepsResults.message;
            	},700);
            }
            else{
                this.filterResults = this.results.filter( (r: any) => {
                    return this.findText(newVal, [r.name]);
                });
   
                this.lastResults = newV;
                this.noWatch = false;
            }
        }
    }
