<template>
	<div class="customize-subject">

		<!-- =========== Subject info ========== -->
		<div class="row align-items-center mb-3">
			<div class="col-md-4 mb-3 mb-md-0">
				<div class="cs-info">
					<div class="color mr-2" :style="{ background: subject.color }"></div>
					<v-hover-txt fontSize="22px" fontWeight="500" :txt="subject.name" />
					<v-hover-txt fontSize="16px" :txt="subject.category" colorTxt="#9b9b9b" />
				</div>
			</div>
			<div class="col-sm text-sm-center mb-3 mb-sm-0">
				<p class="subject-info mb-0">{{ $t('availability.customize.filters.duration') }}: <span class="txt-bold">{{ formatTime(subject.duration_time) }}</span></p>
			</div>
			<div class="col-sm text-sm-center mb-3 mb-sm-0 d-flex">
                <span class="subject-info mr-1">{{ $t('availability.customize.type') }}:</span>
				<v-lesson-tag :type="subject.type" />
			</div> 
			<div class="col-sm text-sm-center mb-3 mb-sm-0 d-flex">
                <span class="subject-info mr-1">{{ $t('availability.customize.mode') }}:</span>
				<v-lesson-tag :type="subject.modality" />
			</div>
		</div>

		<!-- =========== Specific scheduling ========== -->
		<div class="mb-3">
			<div class="specific-scheduling d-flex flex-wrap justify-content-end justify-content-sm-between pl-4 pr-4 pt-3 pb-3 mb-2">
				<div class="col-12 col-sm pl-0 pr-0 d-flex align-items-center mb-2 mb-sm-0">
					<h4 class="title mb-0 mr-3">{{ $t('availability.customize.specificScheduling.t') }}</h4>
					<div class="switch" :class="{ active : statusScheduling }" @click="actionSwitch('scheduling')"></div>
				</div>
                <transition name="bound">
                    <div class="col-auto pl-0 pr-0" v-if="statusScheduling">
                        <button class="btn_" @click="statusModalPlaces=true; msgType='clearScheduling'" :class="{ disabled: (!cutomizeSubject.statusSchedule || disabledDisponibility) }" :disabled="(!cutomizeSubject.statusSchedule || disabledDisponibility)">{{ $t('availability.customize.specificScheduling.btnClear') }}</button>
                    </div>
                </transition>
			</div>
			<v-slide-animation class="w-100" ref="scheduleSlick" :show="statusScheduling">
                <carousel class="schedule-slick row ml-n1 mr-n1" ref="slickNews" :key="`sch-${updateSch}`" :perPageCustom="scheduleSlick" :autoplay="true" :speed="1500" :autoplayTimeout="4000" :loop="true" :paginationEnabled="false" :mouse-drag="true">
                    <slide class="item p-1" v-for="([key, value]) in specificScheduling.entries()" :key="key" @click.native="setDatasScheduling(key, value.ranges)">
                        <div class="sch-day pl-2 pr-2 pt-3 pb-3">
                            <span class="sch-title d-block mb-1">{{ $t(value.day) }}</span>
                            <ul class="pl-0 mb-1">
                                <li v-for=" sch in value.ranges.slice(0, 4)">{{ $moment(sch.from, 'HH:mm').format('LT') }} - {{ $moment(sch.to, 'HH:mm').format('LT') }}</li>
                            </ul>
                            <div class="d-flex justify-content-center" v-if="value.ranges.length > 4">
                                <button class="btn-more">+ {{ value.ranges.length - 4 }} {{ $t('availability.btn.more') }}</button>
                            </div>
                        </div>
                    </slide>
                </carousel>
			</v-slide-animation>
		</div>

		<!-- =========== Specific places ========== -->
		<div class="mb-3" v-if="subject.type != 'Grupal' && subject.modality=='Presencial'">
			<div class="specific-places d-flex flex-wrap justify-content-between pl-4 pr-4 pt-2 pb-2 mb-2">
				<div class="col-12 col-md pl-0 pr-0 mb-2 mb-md-0 d-flex align-items-center">
					<h4 class="title mb-0 mr-3">{{ $t('availability.customize.specificPlaces.t') }}</h4>
					<div class="switch" :class="{ active : statusPlaces, disabled: disabledDisponibility }" @click="actionSwitch('places')"></div>
				</div>
                <transition name="bound">
                    <div class="col-12 col-md-auto row pl-0 pr-0 ml-0 mr-0 justify-content-end align-items-center" v-if="statusPlaces">
                        <div class="col-12 col-sm pl-0 pr-0 search mr-sm-3 mb-2 mb-sm-0 w-100">
                            <svgicon class="icon-search" name="magnifier"></svgicon>
                            <input type="text" :placeholder="$t('availability.customize.placeholderSearchPlace')" v-model="filters.search" />
                        </div>
                        <button class="col-auto pl-0 pr-0 btn_" @click="statusModalPlaces=true; msgType='clearPlaces'" :class="{ disabled: (placesToSave.length == 0 || disabledDisponibility) }" :disabled="(placesToSave.length == 0 || disabledDisponibility)">{{ $t('availability.customize.specificPlaces.btnClear') }}</button>
                    </div>
                </transition>
			</div>
			<v-slide-animation :show="statusPlaces">
				<div class="list-places">
                    <!-- ============== Filters ============ -->
					<div class="d-flex justify-content-end align-items-center mb-2">
						<button class="btn-firsts ml-3 mr-3" :class="{ active: filters.orderBy == 'selected' }" @click="filters.orderBy = 'selected'">{{ $t('availability.customize.specificPlaces.filterSelected') }}</button>
						<button class="btn-az ml-3 mr-3" :class="{ active: filters.orderBy == 'place' }" @click="filters.place = filters.orderBy != 'place'?'asc':filters.place=='asc'?'des':'asc'; filters.orderBy = 'place';">
                            A-Z
                            <svgicon class="icon" name="arrow-down" :style="filters.orderBy!='place'?'opacity:0':''" v-if="filters.place == 'des'"/>
                            <svgicon class="icon" name="arrow-up" :style="filters.orderBy!='place'?'opacity:0':''" v-if="filters.place == 'asc'"/>
                        </button>
					</div>
                    <transition mode="out-in" name="bound" >
                        <!-- ============== Spinner =============== -->
                        <div class="lesson-spinner w-100 p-4 text-center" key="spinner" v-if="spinner">
                            <v-spinner />
                        </div>
                        <!-- ================ Places ============= -->
                        <div key="places" v-else-if="!spinner && filterPlaces.length > 0">
                            <div class="place_ d-flex justify-content-between align-items-center position-relative mb-2" :class="{ disabled : disabledDisponibility }" :key="item.id" v-for="(item, i) in filterPlaces.slice(pageInit , pageEnd)" @click="$refs['place-'+item.id][0].click()">
                                <div class="place-icon" :style="'background: #92117F'">
                                    <svgicon class="icon" :name="item.icon.icon_name" />
                                </div>
                                <v-hover-txt class="pl-3 pr-3 w-100" :fontWeight="500" :txt="item.name" />
                                <div class="check" :class="{ active: item.status }">
                                    <input type="checkbox" :ref="'place-'+item.id" :value="item.id" :disabled="disabledDisponibility" v-model="placesToSave" @change="checkPlace($event.target.checked, i)">               
                                </div>
                            </div>
                        </div>
                        <!-- ============== Msg not found =============== -->
                        <div class="empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mb-3" v-else-if="filterPlaces.length === 0 && !spinner" key="alert">
                            <svgicon class="img pl-3 pr-3" original name="dashboardt2" />
                            <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                                <p class="txt mb-0">{{ $t('availability.customize.specificPlaces.msgNotFound') }}</p>
                            </div>
                        </div>
                    </transition>
				    <div class="d-flex justify-content-center pt-3" v-if="paginate > 1">
                        <button class="btn-pagination" :class="{ active: page === n }" v-for="n in paginate" @click="page = n">{{ n }}</button>
				    </div>
				</div>
			</v-slide-animation>
		</div>

        <!-- =========== Modal Specific scheduling ========== -->
		<v-specifig-scheduling ref="specificScheduling" :show="statusModalScheduling" :subject_id="subject.subject_assignment_id" :datas="datasScheduling" :initTime="initTime" :endTime="endTime" :minRangetime="subject.duration_time" :disabledDisponibility="disabledDisponibility" v-model="specificScheduling" @closeAvailability="statusModalScheduling=false" />

        <!-- =============== Modal to changes =============== -->
        <v-modal-places :show="statusModalPlaces" :msgType="msgType" :disabledDisponibility="disabledDisponibility" @closeModalplace="statusModalPlaces = false" @clear="clear()" @close="closeSwitch = false;" @prevAction="$parent.view = 'subjects'" />
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		props: {
			subject: { type: Object, default: () => {}, required: true },
			generalSchedule: { type: Map, default: () => new Map, required: true },
			initTime: { type: String, default: '06:00', required: false },
			endTime: { type: String, default: '21:00', required: false },
            minRangetime: { type: Number, default: 30, required: false },
            disabledDisponibility: { default: false, required: false }
		},
		data(){
			return{
                filters:{
                    orderBy: 'selected', // selected, place
                    place: 'asc', // des, asc
                    search: ''
                },
				statusScheduling: false,
				statusModalScheduling: false,
				statusPlaces: false,
				page: 1,
				selectedFirst: false,
				order: 'des',
                updateSch: 0,
				specificScheduling: new Map(),
				places: [],
                filterPlaces: [],
                placesToSave: [],
                searchDelay: '',
				datasScheduling: {},
                spinner: false,
                sending: false,
                statusModalPlaces: false,
                msgType: '',
                closeSwitch: false
			}
		},
		computed:{
			...mapState(['cutomizeSubject', 'auth', 'menu']),
            pageInit(){
                return this.page*10-10;
            },
            pageEnd(){
                return this.page*10;
            },
            paginate(){
                return Math.ceil(this.filterPlaces.length/10);
            },
			scheduleSlick(){
				return [ ...this.menu.compact?[ [991, 6], [1060, 7] ]:
                                              [ [991, 5], [1200, 7] ],
                                              [0, 2],
                                              [420, 3],
                                              [550, 4],
                                              [660, 5],
                                              [850, 6],
                       ]
			}    
		},
		created(){
			this.getSchedule();
            if (this.subject.type != 'Grupal' && this.subject.modality=='Presencial')
                this.getPlaces();
		}, 
		methods:{
            ...mapMutations(['setNotification', 'setCutomizeSubjectHistory']),
			...mapActions(['axiosRequest', 'changeStatusSubject']),
			getPlaces(){
                this.spinner = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/subject/${this.subject.subject_assignment_id}/places`,
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data)
                .then( async data => {
                    const newPlaces = data.selected_places.reduce( (arr, item) => {
                        const { id } = item;
                        const index = data.all_places.findIndex( p => p.id === id );
                        if (index !== -1){
                            arr[index] = { ...arr[index], status: true };
                            this.placesToSave.push(arr[index].id);
                        }
                        return arr;
                    }, data.all_places);
                    this.places = newPlaces;
                    this.filterPlaces = newPlaces;
                    await this.filtersActions();
                    if (this.placesToSave.length > 0)      
                        this.statusPlaces = true;
                }).catch( err => {
                    this.spinner = false;
                })
            },
            getSchedule(){ // Get specific Scheduling
            	this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/subject/${this.subject.subject_assignment_id}/schedules`,
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data)
                .then( data => {
                	this.getSchedules(data).then( sch => {
                		this.specificScheduling = sch;
                	} );
                    if (Object.keys(data).length > 0){
                        setTimeout(()=> {
                            this.statusScheduling = true;
                        },500)
                    }
                }).catch( error => {
                    console.log(error)
                })
            },
            async getSchedules(data){ // Restructure array Schedule by day
                let sch = new Map();

                await sch.set('Domingo', {...(data.Sunday?{ ...this.generalSchedule.get('Domingo'), ranges: data.Sunday, isCustom: true  }:{...this.generalSchedule.get('Domingo'), ranges: [], isCustom: false}), day: 'days.sunday'  });
                await sch.set('Lunes', {...(data.Monday?{ ...this.generalSchedule.get('Lunes'), ranges: data.Monday, isCustom: true  }:{...this.generalSchedule.get('Lunes'), ranges: [], isCustom: false}), day: 'days.monday'  });
                await sch.set('Martes', {...(data.Tuesday?{ ...this.generalSchedule.get('Martes'), ranges: data.Tuesday, isCustom: true  }:{...this.generalSchedule.get('Martes'), ranges: [], isCustom: false}), day: 'days.tuesday'  });
                await sch.set('Miercoles', {...(data.Wednesday?{ ...this.generalSchedule.get('Miercoles'), ranges: data.Wednesday, isCustom: true  }:{...this.generalSchedule.get('Miercoles'), ranges: [], isCustom: false}), day: 'days.wednesday'  });
                await sch.set('Jueves', {...(data.Thursday?{ ...this.generalSchedule.get('Jueves'), ranges: data.Thursday, isCustom: true  }:{...this.generalSchedule.get('Jueves'), ranges: [], isCustom: false}), day: 'days.thursday'  });
                await sch.set('Viernes', {...(data.Friday?{ ...this.generalSchedule.get('Viernes'), ranges: data.Friday, isCustom: true  }:{...this.generalSchedule.get('Viernes'), ranges: [], isCustom: false}), day: 'days.friday'  });
                await sch.set('Sabado', {...(data.Saturday?{ ...this.generalSchedule.get('Sabado'), ranges: data.Saturday, isCustom: true  }:{...this.generalSchedule.get('Sabado'), ranges: [], isCustom: false}), day: 'days.saturday'  });

                return sch;
            },
            setDatasScheduling(day, ranges){
            	this.datasScheduling = { day: day, ranges: ranges.reduce( (arr, item) => {
                   const from = item.from.split(':'),
                         to = item.to.split(':');

                    arr.push({ from: `${from[0]}:${from[1]}`, to: `${to[0]}:${to[1]}` });
                    return arr;
                }, []) };
            	this.statusModalScheduling = true;
            },
            savePlaces(places){
                if (!this.disabledDisponibility){
                    this.$Progress.start();
                    this.sending = true;
                    this.axiosRequest({ config : {
                        method: 'POST',
                        url: `${this.auth.api}/subject/${this.subject.subject_assignment_id}/places`,
                        data: { places: places },
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then( data => {
                        this.$Progress.finish();
                        this.placesToSave = places;

                        if (places.length === 0 && this.cutomizeSubject.statusPlaces) {
                            this.places.map( item => { delete item.status });
                            this.filterPlaces.map( item => { delete item.status });
                        }

                        if (places.length === 0 && this.cutomizeSubject.statusPlaces || places.length > 0 && !this.cutomizeSubject.statusPlaces)
                            this.changeStatusSubject({ places: +!this.cutomizeSubject.statusPlaces, availability: this.cutomizeSubject.statusSchedule });

                        if (this.closeSwitch) {
                            this.closeSwitch = false;
                            this.statusPlaces = !this.statusPlaces;
                        }

                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                        this.sending = false;
                    }).catch( error => {
                        this.$Progress.finish();
                        this.setNotification({ type: 'erro', msg: err.response.data.error.message  });
                        this.sending = false;
                    })
                }
            },
            checkPlace(isChecked, index){
                if (!this.disabledDisponibility){
                    this.places[((this.page-1) * 10 ) + index].status = isChecked;
                    this.savePlaces(this.placesToSave);
                }
            },
            async filtersActions(){
                this.spinner = true;
                let places = [];
                clearTimeout(this.searchDelay);
                this.searchDelay = setTimeout( () => {
                    if (this.filters.search !== '') // Filter by mode,type or name
                        places = this.places.filter( item => {
                            if (item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.filters.search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1)
                                return item;
                        });
                    else
                        places = this.places;

                    if (this.filters.orderBy == 'place')
                        places = places.sort( (a,b) => {
                            const nameA = a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                  nameB = b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                            if (nameA > nameB)
                                return this.filters.place == 'asc'?1:-1;
                            else if (nameA < nameB)
                                return this.filters.place == 'asc'?-1:1;

                            return 0;
                        })
                    else if(this.filters.orderBy == 'selected')
                        places = places.sort( (a,b) => {
                            if (a.status && !b.status)
                                return -1;
                            else if (!a.status && b.status)
                                return 1;

                            return 0;
                        })

                    this.filterPlaces = places;
                    this.spinner = false;
                }, 700)
            },
            getAllDays(){
                const days = [];
                for (var day of this.generalSchedule.keys()) {
                    days.push(day);
                }
                return days;
            },
            async clear(){
                if (!this.disabledDisponibility){
                    if (this.msgType == 'clearPlaces')
                        this.savePlaces([]);
                    else{
                        this.$refs.specificScheduling.days = this.getAllDays();
                        this.$refs.specificScheduling.available = false;
                        await this.$refs.specificScheduling.applyToAll();
                        if (this.closeSwitch){
                            this.closeSwitch = false;
                            this.statusScheduling = !this.statusScheduling;
                        }
                    }
                }
            },
            actionSwitch(type){
                if (type == 'scheduling') {
                    let isEmpty = true;
                    for (var day of this.specificScheduling.values()) {
                        if (day.ranges.length > 0) {
                            isEmpty = false
                            break;
                        }
                    }
                    if (!isEmpty && this.statusScheduling && !this.disabledDisponibility){
                        this.statusModalPlaces=true; 
                        this.msgType='clearScheduling';
                        this.closeSwitch = true;
                    }
                    else
                        this.statusScheduling = !this.statusScheduling;
                }else{
                    if (this.placesToSave.length > 0 && this.statusPlaces && !this.disabledDisponibility){
                        this.statusModalPlaces=true; 
                        this.msgType='clearPlaces';
                        this.closeSwitch = true;
                    }
                    else
                        this.statusPlaces = !this.statusPlaces;
                }  
            },
            formatTime(minutes){
                if (minutes < 60 )
                    return minutes+' min';
                else{
                    if (minutes%60===0)
                        return (minutes/60)+' hr';
                    else{
                        const hr = Math.trunc(minutes/60);
                        return `${hr} hr ${ minutes-(hr*60) } min`;
                    }

                }
            }
		},
		watch: {
			'menu.compact': function(newVal){
                setTimeout(()=> {
                    if(this.$refs.scheduleSlick)
                        this.updateSch +=1;
                },500);
            },
            'filters.search': function(newVal){
                this.page = 1;
                this.filtersActions();
            },
            'filters.orderBy': function(){
                this.filtersActions();
            },
            'filters.place': function(){
                this.filtersActions();
            }
		}
	}
</script>