var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-onboarding-video", style: { background: _vm.bgColor } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isVideoMute,
              expression: "!isVideoMute"
            }
          ],
          staticClass: "v-ov-content w-100 h-100 overflow-hidden"
        },
        [_c("video", { ref: "videoElement" })]
      ),
      _vm._v(" "),
      _vm.camMsg >= _vm.typePermission.denied || !_vm.loading
        ? _c(
            "div",
            { staticClass: "v-ov-content w-100 h-100 noVideo cam-error" },
            [
              !_vm.loading
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("room.camera.alerts.loading")))
                  ])
                : _vm.camMsg == _vm.typePermission.errorSystem
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.$t("room.camera.alerts.errorSystem.issue"))
                    )
                  ])
                : _vm.camMsg == _vm.typePermission.denied
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.$t("room.camera.alerts.denied.issue")) + " "
                    )
                  ])
                : _vm.camMsg == _vm.typePermission.deviceEmpty
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("room.camera.alerts.empty.issue")))
                  ])
                : _vm.camMsg == _vm.typePermission.errorDevice
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("room.camera.alerts.fail")))
                  ])
                : _vm.camMsg == _vm.typePermission.mute
                ? _c("p", { staticClass: "noCamera mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("room.camera.alerts.mute")))
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showActions && _vm.loading
        ? _c("div", { staticClass: "v-actions d-flex align-items-center" }, [
            !_vm.isAudioMute
              ? _c(
                  "div",
                  { staticClass: "vol-bars d-flex align-items-center" },
                  [
                    _c("div", { staticClass: "content-vol-bar" }, [
                      _c("div", {
                        staticClass: "vol-bar",
                        style: {
                          width: `${_vm.volume <= 25 ? _vm.volume * 4 : 100}%`
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-vol-bar" }, [
                      _c("div", {
                        staticClass: "vol-bar",
                        style: {
                          width: `${
                            _vm.volume <= 70 && _vm.volume > 30
                              ? _vm.volume * 1.33333
                              : _vm.volume <= 30
                              ? 0
                              : 100
                          }%`
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-vol-bar" }, [
                      _c("div", {
                        staticClass: "vol-bar",
                        style: { width: `${_vm.volume > 75 ? _vm.volume : 0}%` }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "v-audioVideo row flex-nowrap flex-sm-wrap" },
              [
                _c(
                  "div",
                  {
                    staticClass: "hl-btn col-auto pl-0 pr-0 ml-2 mr-1 mr-md-2",
                    class: { disabled: _vm.isVideoMute },
                    on: {
                      click: function($event) {
                        return _vm.$emit("handleCam")
                      }
                    }
                  },
                  [
                    !_vm.isVideoMute
                      ? _c("svgicon", {
                          staticClass: "icon-action",
                          attrs: { name: "camera" }
                        })
                      : _c("svgicon", {
                          staticClass: "icon-action i-100",
                          attrs: { name: "no-camera", original: "" }
                        }),
                    _vm._v(" "),
                    _c("span", { staticClass: "msg-hover" }, [
                      _vm._v(
                        _vm._s(
                          !_vm.isVideoMute
                            ? _vm.$t("room.toolbar.cam.m")
                            : _vm.$t("room.toolbar.cam.u")
                        )
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "hl-btn col-auto pl-0 pr-0 ml-2 mr-1 mr-md-2",
                    class: { disabled: _vm.isAudioMute },
                    on: {
                      click: function($event) {
                        return _vm.$emit("handleMic")
                      }
                    }
                  },
                  [
                    !_vm.isAudioMute
                      ? _c("svgicon", {
                          staticClass: "icon-action",
                          attrs: { name: "mic" }
                        })
                      : _c("svgicon", {
                          staticClass: "icon-action i-100",
                          attrs: { name: "no-micro", original: "" }
                        }),
                    _vm._v(" "),
                    _c("span", { staticClass: "msg-hover" }, [
                      _vm._v(
                        _vm._s(
                          !_vm.isAudioMute
                            ? _vm.$t("room.toolbar.mic.m")
                            : _vm.$t("room.toolbar.mic.u")
                        )
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }