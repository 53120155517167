/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.146.646l-6 6a.5.5 0 00.707.707l5.146-5.146V18.5a.5.5 0 001 0V2.207l5.146 5.146a.5.5 0 00.707-.708l-6-6a.5.5 0 00-.707 0z"/>'
  }
})
