<template>
	<div id="form-email" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">

		<div class="v-init-form-content d-flex flex-column">
		    <div class="v-paper w-100 mb-4">
                <div class="v-if-head pl-4 pr-4 pt-3 pb-3">
                    <h2 class="v-if-title mb-0">{{ $t('intitutionalEmail.t') }}</h2>
                </div>
			    <transition name="bound" mode="out-in">
				    <!-- ========== Form ========== -->
			        <div class="p-4 d-flex flex-column align-items-center" key="form" v-if="content == 'form'">
			    	    <img class="v-institution-logo mb-4" :src="institution.icon" :alt="institution.name">
				        <form class="w-100" key="form-login" @submit.prevent="send()" >
					        <v-textField class="pb-2" v-model="form.email" ref="email" :options="opcInput" name="institutional-email" :label="$t('intitutionalEmail.label')" type="email" :required="true"/>
					        <v-btn class="mb-4 mt-4 w-100" fontSize="1rem" fontWeight="400" :txt="$t('intitutionalEmail.btn')" name="login" type="submit" height="40px" :spinner="sending" :disabled="$v.validationGroup.$invalid" />
				        </form>
			        </div>
                    
					<!-- ========== Basic plan ========== -->
			        <div class="p-4 d-flex flex-column align-items-center" key="plan" v-else-if="content == 'plan'">
			    	    <img class="v-institution-logo mb-4" :src="institution.icon" :alt="institution.name">
			    	    <p class="v-if-txt mb-4 text-center pl-3 pr-3">{{ $t('intitutionalEmail.planBasic.d') }}</p>
			    	    <p class="v-if-txt text-center pl-3 pr-3" weight="bold" v-html="$t('intitutionalEmail.planBasic.iHtml')"></p>	
			        </div>

			        <!-- ========== Msg invalid email ========== -->
			        <div class="p-4 d-flex flex-column align-items-center" key="msg" v-else-if="content == 'alert'">
			    	    <div class="v-if-txt-opps mb-4">{{ $t('intitutionalEmail.invalidEmail.error') }}</div>
			    	    <p class="v-if-txt pb-2 m-0 pl-3 pr-3 text-center" v-html="$t('intitutionalEmail.invalidEmail.msgHtml')"></p>
			    	    <v-btn class="mb-4 mt-4 w-100" fontSize="1rem" fontWeight="400" :txt="$t('intitutionalEmail.invalidEmail.btn')" name="prevForm" type="submit" height="40px" @click.native="content='form'" />	
			        </div>
		        </transition>
		    </div>

			<!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
		</div>
		<!-- ========== Select language ========== -->
		<v-select-lang />
	</div>
</template>
<script>
	import { required, email } from 'vuelidate/lib/validators';
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		data(){
			return{
				content: 'form', // form, plan, alert
				sending: false,
				form: {
					email: ''
				},
				opcInput: {
					activeLabel: true,
					styles: {
						label: {
							color: '#5E5E5E',
							fontWeight: 500,
							marginBottom: '.5rem',
							'--transform': 'initial',
							'--transform-active': 'initial'
						}
					}
				}
			}
		},
		computed:{
            ...mapState({ institution: state => state.auth.institution,
			              user: state => state.auth.user,
			              api: state => state.auth.api }),
            emailLowerCase(){
				return this.form.email.toLowerCase();
			}
		},
		created(){
			this.content = this.institution.code == 'BASIC'?'plan':'form';
            if (this.user.email)
            	this.form.email = this.user.email;
            this.changeTitle(this.institution.name);
		},
		mounted(){
			if (this.$refs.email)
                this.$refs.email.$el.querySelector('input').focus();
		},
		methods: {
			...mapMutations(['setPath','setUser','changeTitle']),
			...mapActions(['axiosRequest']),
			send(){
				if (!this.$v.validationGroup.$invalid && !this.sending){
					this.sending = true;
					this.axiosRequest({ config : {
            			method: 'GET',
						url: `${this.api}/validate/email`,
						params: { email: this.emailLowerCase, institution_id: this.institution.id }
            		}, error401: false }).then( resp => resp.data )
					.then( data => {
						this.sending = false;
						if (data.path) {
							this.setPath(data.path);
                            this.setUser({ email: this.emailLowerCase });
                            if (data.path === 'Login')
                        	    this.$route.query.next?this.$router.push({ name : 'login', query: this.$route.query  }):this.$router.push({ name : 'login' }); 
                            else if (data.path === 'Registro')
                        	    this.$router.push({ name : 'sign-up' }); 
                            else{
                        	    this.content = 'alert';
                            }
                        }
                        else if (data.message){
                    	    resolve(data.message);
                        }
                    }).catch( error => {
                    	this.sending = false;
                	    this.content = 'alert';
                    } )
                }    
			}
		},
		validations: {
            form: {
            	email: {
            		required
            	}
            },
            emailLowerCase: {
                email
            },
            validationGroup: [ 'form', 'emailLowerCase' ]
        }
	}
</script>