
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State } from 'vuex-class';

    @Component
    export default class chatLessonInfo extends Vue{
        @Prop({ type: Object, required: true }) readonly lesson!: any;
        @Prop({ type: Array, required: true }) readonly users!: any;

        @State( state => state.auth.permission ) userType;
        @State( state => state.auth.user.id ) myId;

        statusMemmersList: boolean = false;

        get t(): any{
        	return (this as any).$t;
        }

        get title(): string{
        	if (this.lesson.status === 'Pendiente')
        		return this.t('chat.tp');
        	else if(this.lesson.status === 'Activa')
        		return this.t('chat.to');
        	else if(this.lesson.status === 'Finalizada')
        		return this.t('chat.tf');
        	else if(this.lesson.status === 'Cancelada')
        		return this.t('chat.tc');
        	else	
        		return this.t('chat.tu');
        }

        get bgTitle(): string{
            if (this.lesson.status === 'Pendiente')
                return 'bg-lesson-pending';
            else if(this.lesson.status === 'Activa')
                return 'bg-lesson-ongoing';
            else if(this.lesson.status === 'Finalizada')
                return 'bg-lesson-finished';
            else if(this.lesson.status === 'Cancelada')
                return 'bg-lesson-canceled';
            else    
                return 'bg-lesson-upcoming';
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type, size: '95px' };
        }

        get name(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit, groupAction: this.userType == 'tutor' }
        	else
        		return {};	
        }

        get statusTopic(): boolean{
            if (this.userType == 'student' && this.lesson.type == 'Individual') {
                if ((this.lesson.status == 'Activa' || this.lesson.status == 'Cancelada' || this.lesson.status == 'Finalizada') && this.lesson.topic)
                    return true;
                else if(this.lesson.status == 'Pendiente' || this.lesson.status == 'Confirmada')
                    return true;
                else
                    return false;

            }else if (this.userType == 'tutor' && this.lesson.topic)
                return true;
            else
                return false;
        }
    }
