var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "v-dropzone",
      style: {
        "--bg-color": _vm.auth.permission == "tutor" ? "#005E58" : "#2188FC"
      }
    },
    [
      _c(
        "button",
        {
          staticClass: "v-dropzone-close d-flex align-items-center",
          style: { pointerEvents: _vm.statusCursor ? "initial" : "none" },
          on: { click: _vm.closeAction }
        },
        [
          _c("svgicon", {
            staticClass: "icon-cross mr-2",
            style:
              _vm.auth.permission == "tutor"
                ? "fill-green-main"
                : "fill-blue-main",
            attrs: { name: "cross2" }
          }),
          _vm._v(" "),
          _c("span", { style: `color-${_vm.auth.permission}-main` }, [
            _vm._v(_vm._s(_vm.$t("chat.btnClose")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      this.auth.api
        ? _c(
            "vue-dropzone",
            {
              ref: "myVueDropzone",
              staticClass: "h-100 w-100",
              attrs: {
                id: "dropzone",
                options: {
                  url: `${_vm.auth.api}/lesson/${_vm.idLesson}/chat/file`,
                  previewTemplate: _vm.templateHtml,
                  headers: { Authorization: _vm.auth.token },
                  ..._vm.opc
                },
                useCustomSlot: true
              },
              on: {
                "vdropzone-file-added": _vm.addFile,
                "vdropzone-upload-progress": _vm.dropzoneUploadProgress,
                "vdropzone-success": _vm.fileComplete,
                "vdropzone-error": _vm.fileCanceled,
                "vdropzone-sending": _vm.fileSending,
                "vdropzone-drag-over": function($event) {
                  _vm.statusCursor = false
                },
                "vdropzone-drag-leave": function($event) {
                  _vm.statusCursor = true
                },
                "vdropzone-drop": function($event) {
                  _vm.statusCursor = true
                },
                "vdropzone-removed-file": _vm.fileRemove
              }
            },
            [
              _c("div", { staticClass: "dropzone-custom-content" }, [
                _c("div", { staticClass: "dropzone-custom-title" }, [
                  _c("h3", { staticClass: "dz-title" }, [
                    _vm._v(_vm._s(_vm.$t("chat.dragFiles")))
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }