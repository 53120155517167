import mutations from './mutations.js';
import actions from './actions.js';

let state = {
	browser: {
        txtBrowser: '',
        limit: 10,
        searchResults: []
	}
}

export default {
    state,
    mutations,
    actions
}