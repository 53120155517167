/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud-gear': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 15.663a.5.5 0 01-.215-.952 3.015 3.015 0 001.714-2.712 3.004 3.004 0 00-4-2.83.5.5 0 01-.533-.811 2.002 2.002 0 00-1.467-3.359 2.001 2.001 0 00-1.996 1.875.5.5 0 01-.908.256 5.012 5.012 0 00-4.096-2.131c-2.757 0-5 2.243-5 5a4.983 4.983 0 002.727 4.455.5.5 0 01-.455.891 6.016 6.016 0 01-2.364-2.17A5.986 5.986 0 01-.001 10c0-3.308 2.692-6 6-6a6.01 6.01 0 014.261 1.776A3.007 3.007 0 0112.999 4a3.004 3.004 0 012.828 4.004 4.004 4.004 0 013.536 6.162 4.005 4.005 0 01-1.649 1.449.494.494 0 01-.214.048z"/><path pid="1" d="M10.5 16c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 100 1 .5.5 0 000-1z"/><path pid="2" d="M9.017 19.776c-.05 0-.1-.007-.148-.022a5.523 5.523 0 01-2.101-1.215.498.498 0 01.028-.758c.383-.305.486-.856.241-1.281a1.002 1.002 0 00-1.23-.431.502.502 0 01-.671-.355 5.554 5.554 0 010-2.426.5.5 0 01.671-.355 1.004 1.004 0 001.23-.431 1.006 1.006 0 00-.241-1.281.5.5 0 01-.028-.758 5.543 5.543 0 012.101-1.215.503.503 0 01.643.403c.072.485.498.85.989.85s.916-.366.989-.85a.499.499 0 01.643-.403 5.536 5.536 0 012.101 1.215.498.498 0 01-.028.758 1.006 1.006 0 00-.241 1.281 1.002 1.002 0 001.23.431.502.502 0 01.671.355 5.554 5.554 0 010 2.426.5.5 0 01-.671.355 1.004 1.004 0 00-1.23.431c-.245.425-.142.976.241 1.281a.5.5 0 01.028.758 5.536 5.536 0 01-2.101 1.215.503.503 0 01-.643-.403c-.072-.485-.498-.85-.989-.85s-.916.366-.989.85a.499.499 0 01-.495.426zm-1.19-1.656c.272.201.566.371.874.506A1.997 1.997 0 0110.5 17.5a1.997 1.997 0 011.799 1.126c.308-.135.602-.305.874-.506a1.998 1.998 0 011.799-3.115 4.472 4.472 0 000-1.01A2.006 2.006 0 0113.099 13a2 2 0 01.074-2.119 4.538 4.538 0 00-.874-.506 1.997 1.997 0 01-1.799 1.126 1.997 1.997 0 01-1.799-1.126 4.538 4.538 0 00-.874.506A1.995 1.995 0 017.901 13a2.006 2.006 0 01-1.873.995 4.472 4.472 0 000 1.01A2.006 2.006 0 017.901 16a2 2 0 01-.074 2.119z"/>'
  }
})
