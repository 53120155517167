<template>
	<div id="dashboard">
        <!-- ================ Search ================== -->
		<v-search class="mb-4" ref="search" :placeholder="'Search subject or tutor…'"></v-search>

        <!-- ================ Banners ================== -->
        <transition name="bound" mode="out-in">
            <div class="lesson-spinner w-100 p-4 text-center" key="spinner" v-if="spinner && news.length === 0">
                <v-spinner />
            </div>
            <carousel class="mb-4 dashboard-slick" ref="slickNews" :key="`news-${updateNews}`" :perPageCustom="[[0, 1]]" :autoplay="true" :speed="2000" :autoplayTimeout="5000" :loop="true" v-else>
                <slide v-for="item in news" :key="item.id">
                    <a :href="item.link" target="_blank">
                        <div class="item d-flex align-items-center" :style="{ background: `url(${item.picture})center center / cover no-repeat` }">
                            <div class="bgColor" :style="{ background : `linear-gradient(to right, ${item.color}, ${$hexToRgba(item.color,0.9)}, ${$hexToRgba(item.color,0.7)}, ${$hexToRgba(item.color,0.5)}, ${$hexToRgba(item.color,0.3)}, ${$hexToRgba(item.color,0,1)}, transparent)` }" ></div>
                            <div class="d-flex flex-column pt-4 pl-sm-5 pl-3 w-100 content">
                                <h3 class="mb-0 w-100 title">{{ item.title }}</h3>
                                <p class="mb-0 w-100 descrip" >{{ item.subtitle }}</p>
                            </div>
                        </div>
                    </a>
                </slide>   
            </carousel>
        </transition>

        <!-- ================ Upcoming lessons ================== -->
        <section class="s-lessons mb-4" ref="lessons">
            <div class="row ml-n2 mr-n2 align-items-center justify-content-between mr-0">
                <div class="col-auto pl-2 pr-2">
                    <h4 class="d-title mb-0 d-flex align-items-center">
                        <div class="point bg-lesson-upcoming mr-1"></div>
                        {{ $t('dashboard.upcoming') }}
                        <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoUpcomingS')" />
                    </h4>
                </div>
                <div class="col d-none d-sm-block pl-2 pr-4 text-right position-relative">
                    <router-link class="btn-lessons position-static color-student-main pr-0" :to="{ name: 'lessons-student' }">{{ $t('lessons.btnAll') }} <svgicon class="icon fill-blue-main" name="chevron-right"></svgicon></router-link>
                </div>
            </div>
            <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && upcomingLessons.length === 0">
                   <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="upcomingLessons.length === 0 && !spinner" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboards1" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-3" v-html="$t('msgEmpty.msgUpcoming')"></p>
                        <v-btn :txt="$t('msgEmpty.btn')" min="100%" fontSize="18px" @click.native=" $router.push({ name: 'schedule-student' }) "/>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="lessons" tag="div" v-else>
                    <v-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in upcomingLessons"/>  
                </transition-group>
            </transition>  
        </section>

        <!-- ================ Latest subjects requested ================== -->
        <section class="latest-lessons mb-4">
            <h4 class="d-title mb-0 d-flex align-items-center">
                <!--<div class="point" color="orange"></div>-->
                {{ $t('dashboard.latestLessons') }}
                <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoSLastesSubjects')" />
            </h4>
            <transition name="bound" tag="div" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && subjects.length === 0">
                    <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="!spinner && subjects.length === 0" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboards2" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-3" v-html="$t('msgEmpty.msgLatest')"></p>
                        <v-btn :txt="$t('msgEmpty.btn')" min="100%" fontSize="18px" @click.native=" $router.push({ name: 'schedule-student' }) "/>
                    </div>
                </div>
                <carousel class="mt-4 lessons" ref="slickSubjects" :key="`lessons-${lessons.update}`" :perPageCustom="[[0, 1], [450, 2], [700, 3], [1200, 4]]" :autoplay="true" :speed="1000" :autoplayTimeout="3500" :loop="true" :paginationEnabled="true" paginationActiveColor="#2188FC" :mouse-drag="false" v-else>
                    <slide class="item pl-3 pr-3" v-for="lesson in subjects" :key="lesson.id" @click.native="setTxtBrowser(lesson.name); $router.push({ name: 'results-student2', params: { subject: encodeParam(lesson.name) } })">
                        <div class="lesson text-center p-4"  :style="{ background: lesson.color }">
                            <div class="blurcolor"></div>
                            <div class="lesson-icon pt-3 pb-3 mb-3">
                                <svgicon class="icon" :name="lesson.icon" />                            
                            </div>
                            <v-hover-txt class="mb-2" fontSize="22px" fontWeight="200" colorTxt="#fff" :txt="lesson.name" />
                            <v-hover-txt fontWeight="500" colorTxt="#fff" :txt="lesson.tutor" />   
                        </div>
                    </slide>
                </carousel>
            </transition>
        </section>

        <!-- ================ Latest tutors requested ================== -->
        <section class="latest-tutors mb-4">
            <h4 class="d-title mb-0 d-flex align-items-center">
                <!--<div class="point" color="green-main"></div>-->
                {{ $t('dashboard.latestTutors') }}
                <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoSLastesTutors')" />
            </h4>
            <transition name="bound" class="row mr-0 ml-0" tag="div" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && tutors.length === 0">
                    <v-spinner />
                </div>

                <div class="basic-paper empty-lessons d-flex flex-column align-items-center pt-5 pb-4 pl-3 pr-3 mt-3" v-else-if="!spinner && tutors.length === 0" key="alert">
                    <svgicon class="img mb-3 w-100" original name="tutors-empty1" />
                    <p class="txt bold mb-1">{{ $t('msgEmpty.msgTutor') }}</p>
                    <p class="txt2">{{ $t('msgEmpty.msgTutor2') }}</p>
                </div>

                <carousel class="mt-4 tutors" ref="slickTutors" :key="`tutors-${tutorsOpc.update}`" :perPageCustom="[[0, 1], [450, 2], [700, 3], [1200, 4]]" :autoplay="true" :speed="1000" :autoplayTimeout="3500" :loop="true" :paginationEnabled="true" paginationActiveColor="#2188FC" :mouse-drag="false" v-else>
                    <slide class="item pl-3 pr-3" v-for="tutor in tutors" :key="tutor.id" @click.native="$router.push({ name: 'tutorProfile-student', params: { tutor: tutor.id } })">
                        <div class="tutor">
                            <div class="img position-relative" v-bind:style="{ background : 'url('+tutor.photo+')center center / cover no-repeat' }">
                                <div class="blurcolor"></div>
                            </div>
                            <div class="pl-3 pr-3 pt-2 pb-2 info">
                                <v-hover-txt class="mb-1" fontSize="20px" fontWeight="500" :txt="tutor.name" />
                                <span class="d-block w-100 matter mb-1">{{ tutor.subject }}</span>
                                <span class="d-block w-100 schedule">{{ parceDate(tutor.date) }}</span>
                            </div>
                            <button class="btn-see">{{ $t('dashboard.btnProfile') }} <svgicon class="icon" name="chevron-right"></svgicon></button>
                        </div>
                    </slide>
                </carousel>
            </transition>
        </section>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

	export default{
		data(){
			return{
                spinner: false,
                news: [],
                upcomingLessons: [],
                subjects: [],
                tutors: [],
                updateNews: 0,
                lessons: {  
                    update: 0
                },
                tutorsOpc: {
                    update: 0
                },
                cardW: undefined
			}
		},
        computed:{
            ...mapState(['auth','currentLesson', 'lessonChanges', 'menu','i18n', 'userFirebase']),
            ...mapGetters(['events', 'modalVal'])
        },
        created(){
            this.getDashboard();
        },
        mounted(){
            if (this.$refs.slickSubjects){
                this.resetSlideLessons(this.$refs.slickSubjects.currentPerPage);
                this.$watch('$refs.slickSubjects.currentPerPage', (newVal) => {
                    this.resetSlideLessons(newVal);
                });
            }
            if (this.$refs.slickTutors){
                this.resetSlideTutors(this.$refs.slickTutors.currentPerPage);
                this.$watch('$refs.slickTutors.currentPerPage', (newVal) => {
                    this.resetSlideTutors(newVal);
                });
            }
            
            this.cardW = this.$refs.lessons.offsetWidth - this.getScrollbarWidth();
            window.addEventListener('resize', this.getCardW);
            this.$watch('events', val => {
                const lessonLS = JSON.parse(this.$localStorage.get('lesson'));
                if (val && lessonLS.lesson){
                    const lesson = val.split('_');
                    this.getDashboard(lesson[1]);
                }
            });
        },
        beforeDestroy(){
            window.removeEventListener('resize', this.getCardW);
        },
        methods: {
            ...mapMutations(['setTxtBrowser', 'handleServerTime']),
            ...mapActions(['axiosRequest']),
            getDashboard(action=''){
                this.spinner = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/dashboard/s`,
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data)
                .then( data => {
                    this.handleServerTime(data.serverTime);
                    if (action === ''){
                        this.news = data.news;
                        this.upcomingLessons = data.upcomingLessons;
                        this.subjects = data.subjects;
                        this.tutors = data.tutors;
                    }
                    else if(action === 'upcoming' || action === 'canceled' || action === 'ongoing' || action === 'changedPlace' || action === 'changedTopic' || action === 'userGroupJoined' || action === 'userGroupLeft'){
                        this.upcomingLessons = data.upcomingLessons;
                    }
                    this.spinner = false;
                }).catch( error => {
                    this.spinner = false;
                });
            },
            parceDate(date){
                date = this.$moment.utc(date).locale(this.i18n.locale).format(this.$t('formatDateC'));
                return date.charAt(0).toUpperCase()+date.slice(1);
            },
            encodeParam(param){
                let search = param.toLowerCase().replace(/á/g,'a').replace(/é/g,'e').replace(/í/g,'i').replace(/ó/g,'o').replace(/ú/g,'u');
                // Encoded Characters
                search = encodeURIComponent(search).replace(/%20/g, "+").replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
                return search;
            },
            resetSlideLessons(slides){
                if ((this.subjects.length >= 4) || (this.lessons.mouseDrag !== ( slides < 4)))
                    this.lessons.update++;
            },
            resetSlideTutors(slides){
                if ((this.tutorsOpc.length >= 4) || (this.tutorsOpc.mouseDrag !== ( slides < 4)))
                    this.tutorsOpc.update++;
            },
            getCardW(){
                this.cardW = this.$refs.lessons.offsetWidth;
            },
            getScrollbarWidth() {
                // Creating invisible container
                const outer = document.createElement('div');
                outer.style.visibility = 'hidden';
                outer.style.overflow = 'scroll'; // forcing scrollbar to appear
                outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
                document.body.appendChild(outer);

                // Creating inner element and placing it in the container
                const inner = document.createElement('div');
                outer.appendChild(inner);

                // Calculating difference between container's full width and the child width
                const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

                // Removing temporary elements from the DOM
                outer.parentNode.removeChild(outer);

                return scrollbarWidth;
            }
        },
        watch:{
            'menu.compact': function(newVal){
                setTimeout(()=> {
                    this.getCardW();
                    if (this.$refs.slickNews)
                        this.updateNews += 1;
                    if (this.$refs.slickSubjects)
                        this.lessons.update += 1;
                    if (this.$refs.slickTutors)
                        this.tutorsOpc.update += 1;
                },500);
            },
            lessonChanges: function(newVal){
                if (newVal) {
                    const { data, lesson } = newVal;
                    let lessonIndex = this.upcomingLessons.findIndex( item => item.id === lesson.id );
                    if (lessonIndex != -1)
                        this.upcomingLessons[lessonIndex][data] = lesson.val;
                }
            },
            modalVal: function(newVal, oldVal){
                if (oldVal.modal == 'v-modal-crud' && this.currentLesson.lesson){
                    if(!this.userFirebase){
                        this.upcomingLessons = this.upcomingLessons.filter( i => i.id !== this.currentLesson.lesson.id );
                    }
                }
            }
        }     
	}
</script>