<template>
    <div id="reset-password-success" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">
        <div class="v-init-form-content d-flex flex-column">
            <div class="v-paper w-100 mb-4">
                <div class="v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3">
                    <h2 class="v-if-title mb-0">{{ $t('confirmEmailSuccess.t') }}</h2>
                </div>

                <!-- ========== Form ========== -->
                <div class="p-4 d-flex flex-column align-items-center">
                    <div class="bg-icon mb-4" :style="{ background: '#72BD00' }">
					    <svgicon class="inset-icon" name="paper-plane" color="#fff" />
				    </div>
                    <p class="v-if-txt text-center pb-2 mb-0"  v-html="$t('confirmEmailSuccess.msg2', { email: userEmail })"></p>
                    <v-btn class="w-100 mt-4" fontSize="1rem" fontWeight="400" :txt="$t('confirmEmailSuccess.btn')" name="institutionalEmail" height="40px" @click.native="redirect()"/>
                </div>
            </div>

            <!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
        </div>

        <!-- ========== Select language ========== -->
		<v-select-lang />
    </div>
</template>
<script>
	export default{
		methods:{
            redirect(){
            	this.$router.push({ name : 'login' }); 
            }
		}
	}
</script>