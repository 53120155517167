var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
      style: { maxWidth: _vm.maxWidth, minWidth: _vm.minWidth }
    },
    [
      _c("div", { staticClass: "content-img mr-2" }, [
        _vm.type !== "Grupal"
          ? _c(
              "div",
              {
                staticClass: "content-icon w-100 h-100",
                class: `bgColor-${_vm.auth.permission}-main`
              },
              [
                _c("svgicon", {
                  staticClass: "icon w-75 h-75",
                  attrs: { name: "user" }
                })
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "content-icon w-100 h-100 bgColor-yellow-main" },
              [
                _c("svgicon", {
                  staticClass: "icon w-75 h-75",
                  attrs: { name: "users2" }
                })
              ],
              1
            )
      ]),
      _vm._v(" "),
      _vm.auth.permission == "student"
        ? _c("div", { staticClass: "info" }, [
            _c(
              "span",
              {
                staticClass: "d-block w-100 name",
                class:
                  _vm.type !== "Grupal"
                    ? "color-student-main"
                    : "color-yellow-main"
              },
              [_vm._v(_vm._s(_vm.name))]
            ),
            _vm._v(" "),
            _vm.type === "Grupal" && _vm.seats
              ? _c("span", { staticClass: "d-block w-100 seats" }, [
                  _vm._v(_vm._s(_vm.seats))
                ])
              : _vm._e()
          ])
        : _c("div", { staticClass: "info" }, [
            _c(
              "span",
              {
                staticClass: "d-block w-100 name",
                class:
                  _vm.type !== "Grupal"
                    ? "color-tutor-main"
                    : "color-yellow-main"
              },
              [_vm._v(_vm._s(_vm.name))]
            ),
            _vm._v(" "),
            _vm.type === "Grupal" && _vm.seats
              ? _c(
                  "span",
                  {
                    staticClass: "d-block seats w-100",
                    on: {
                      click: function($event) {
                        _vm.statusMemmers = true
                      }
                    }
                  },
                  [
                    _vm._v("\n\t\t\t" + _vm._s(_vm.seats) + "\n\t\t\t"),
                    _c("svgicon", {
                      staticClass: "eye icon ml-1",
                      attrs: { name: "eye" },
                      on: {
                        click: function($event) {
                          return _vm.showMembers()
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }