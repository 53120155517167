<template>
	<div class="assigned-subjects">
		
		<!-- ================= Filters =============== -->
		<div class="subjects-filters">
			<div class="row align-items-center ml-n1 mr-n1 mb-3">
				<div class="col-12 col-md-4 pl-1 pr-1 mb-2 mb-md-0">
					<h2 class="title mb-0">{{ $t('availability.customize.tAS') }}</h2>
				</div>
				<div class="col-12 col-md row ml-n1 mr-n1 pl-1 pr-1 align-items-center">
					<div class="col-sm-4 pl-1 pr-1 mb-2 mb-sm-0 d-flex justify-content-end">
						<span class="str mr-3" color="pink">{{ $t('availability.customize.availability') }}</span>
						<span class="str mr-3" color="green">{{ $t('availability.customize.places') }}</span>
					</div>
					<div class="col-sm-8 pl-1 pr-1">
						<div class="search">
							<svgicon class="icon-search" name="magnifier"></svgicon>
							<input type="text" :placeholder="$t('availability.customize.placeholderSearchSubject')" v-model="filters.search" />
						</div>
					</div>
				</div>
			</div>

			<div class="row ml-n1 mr-n1 mb-3">
                <!-- ======= Order by subject name ====== -->
				<div class="col-12 col-sm-6 col-md-4 pl-1 pr-1 mb-2 mb-md-0">
					<div class="as-btn d-flex justify-content-between pl-3 pr-3 align-items-center" @click="orderByAction('subject')" >
						<span class="as-txt">{{ $t('availability.customize.subject') }}</span>
                        <div class="a-z d-flex align-items-center">
                        	<span class="az-txt mr-1">A-Z</span>
                        	<svgicon class="icon" name="arrow-down" :style="filters.orderBy!='subject'?'opacity:0':''" v-if="filters.subject == 'des'"/>
                        	<svgicon class="icon" name="arrow-up" :style="filters.orderBy!='subject'?'opacity:0':''" v-if="filters.subject == 'asc'"/>
                        </div>
					</div>
				</div>
                <!-- ======= Order by duration ====== -->
				<div class="col-12 col-sm-6 col-md pl-1 pr-1 mb-2 mb-md-0">
					<div class="as-btn d-flex justify-content-between pl-3 pr-3 align-items-center" @click="orderByAction('duration')" >
						<span class="as-txt">{{ $t('availability.customize.filters.duration') }}</span>
                        <svgicon class="icon" name="arrow-down" :style="filters.orderBy!='duration'?'opacity:0':''" v-if="filters.duration == 'des'"/>
                        <svgicon class="icon" name="arrow-up" :style="filters.orderBy!='duration'?'opacity:0':''" v-if="filters.duration == 'asc'"/>
					</div>
				</div>
                <!-- ======= filter by type ====== -->
				<div class="col-12 col-sm-6 col-md pl-1 pr-1 mb-2 mb-md-0">
					<v-availability-select :txt="$t('availability.customize.type')" :options="type" v-model="filters.type" />
				</div>
                <!-- ======= filter by mode ====== -->
				<div class="col-12 col-sm-6 col-md pl-1 pr-1 mb-2 mb-md-0">
					<v-availability-select :txt="$t('availability.customize.mode')" :options="mode" v-model="filters.mode" />
				</div>
			</div>
		</div>
  
        <!-- ============== List subjects =============== -->
		<div class="availability-subjects">
			<transition mode="out-in" name="bound" >
				<!-- ============== Spinner =============== -->
				<div class="lesson-spinner w-100 p-4 text-center" key="spinner" v-if="spinner">
					<v-spinner />
				</div>
				<!-- ============== Subjects =============== -->
				<div key="subjects" v-else-if="!spinner && filterSubjects.length > 0">
					<div class="av-subject paper mb-2" :key="item.subject_id" @mouseover="itemHover = item.subject_id" @mouseleave="itemHover = ''" v-for="item in filterSubjects.slice(pageInit , pageEnd)" @click="customizeSubject(item)">
						<div class="row align-items-center mr-n1 ml-n1">
							<div class="col-12 col-md-4 pl-1 pr-1 pt-3 pb-md-3 pb-2">
								<div class="nameAndCategory">
									<div class="color mr-2" :style="{ background: item.color }"></div>
									<v-hover-txt fontSize="13px" fontWeight="500" :txt="item.name" />
									<v-hover-txt fontSize="10px" :txt="item.category" colorTxt="#9b9b9b" />
								</div>
							</div>
							<div class="col-12 col-sm pl-1 pr-1 pt-2 pb-2 pb-sm-3 pt-md-0 pb-md-0">
								<span class="txt-info pl-4 pr-3">{{ formatTime(item.duration_time) }}</span>
							</div>
							<div class="col-12 col-sm d-flex flex-wrap pl-sm-1 pr-1 pt-2 pb-2 pb-sm-3 pt-md-0 pb-md-0">
                                <v-lesson-tag :type="item.type" :options="{ lessonLimit: item.limit }"/>
							</div>
							<div class="col-12 col-sm pl-sm-1 pr-1 pt-2 pb-3 pt-md-0 pb-md-0 d-flex align-items-center justify-content-between" :style="{ minWidth: itemHover===item.subject_id?'210px':'' }">
                                <v-lesson-tag :type="item.modality"/>
								<div class="d-flex flex-column mr-3">
									<div class="bgsrt mb-1" color="pink" v-if="item.availability"></div>
									<div class="bgsrt" color="green" v-if="item.places"></div>
								</div>
								<v-btn class="mr-3 btn-customize" height="15px" :txt="$t('availability.customize.btnCustomize')" fontSize="12px" btn="success" />
							</div>
						</div>
				    </div>
				</div>
				<!-- ============== Msg not found =============== -->
				<div class="empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mb-3" v-else-if="filterSubjects.length === 0 && !spinner" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt2" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-0">{{ $t('availability.customize.msgNotFound') }}</p>
                    </div>
                </div>
		    </transition>

            <!-- =============== Pagination ============= -->
			<div class="d-flex justify-content-center pt-3" v-if="paginate > 1">
				<button class="btn-pagination" :class="{ active: page === n }" v-for="n in paginate" @click="page = n">{{ n }}</button>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
        data(){
            return{
            	filters:{
            		orderBy: 'subject', // subject, duration
            		subject: 'asc', // des, asc
            		duration: 'asc', // asc, des
            		search: '',
            		type: 'All',
            		mode: 'All'
            	},
            	limit: 1000,
                offset: 0,
                page: 1,
            	subjects: [],
            	filterSubjects: [],
            	searchDelay: '',
            	itemHover: '',
            	spinner: false
            }
        },
        computed:{
        	...mapState(['assignedSubjects', 'auth']),
            type(){
            	return [ 
            	            { val: 'All', txt: this.$t('availability.customize.filters.both'), color: 'gray' },
            	            { val: 'Individual', txt: this.$t('lessonType.a'), color: 'blue' },
            	            { val: 'Grupal', txt: this.$t('lessonType.g'), color: 'yellow' }
            	       ]
            },
            mode(){
            	return [ 
            	            { val: 'All', txt: this.$t('availability.customize.filters.both'), color: 'gray' },
            	            { val: 'Presencial', txt: this.$t('modality.p'), color: 'purple' },
            	            { val: 'Online', txt: this.$t('modality.o'), color: 'green' }
            	       ]
            },
            pageInit(){
            	return this.page*10-10;
            },
            pageEnd(){
            	return this.page*10;
            },
            paginate(){
            	return Math.ceil(this.filterSubjects.length/10);
            }
        },
        created(){
            if (this.assignedSubjects.subjects.length == 0)
                this.getSubjects();
            else{
                this.subjects = this.assignedSubjects.subjects;
                this.filterSubjects = this.assignedSubjects.filterSubjects;
                this.page = this.assignedSubjects.page;
                this.filters = this.assignedSubjects.filters;
            }

            this.$watch( 'filters.type', () => {
                this.page = 1;
                this.filtersActions();
            } );
            this.$watch( 'filters.mode', () => {
                this.page = 1;
                this.filtersActions();
            } );
            this.$watch( 'filters.search', () => {
                this.page = 1;
                this.filtersActions();
            } );
            this.$watch( 'filters.orderBy', () => {
                this.filtersActions();
            } );
            this.$watch( 'filters.subject', () => {
                this.filtersActions();
            } );
            this.$watch( 'filters.duration', () => {
                this.filtersActions();
            } );
        },
        methods:{
            ...mapMutations(['setAssignedSubjectsHistory', 'setCutomizeSubjectHistory']),
        	...mapActions(['axiosRequest']),
        	getSubjects(){ // Get subjects
                this.spinner = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/tutor/subjects`,
                    params: { limit: this.limit, offset: this.offset },
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data )
                .then( data => {
                    this.spinner = false;
                    this.subjects = data.subjects;
                    this.filterSubjects = data.subjects;
                }).catch( error => {
                    this.spinner = false;
                });
            },
            orderByAction(filter){ // Action for btn subjects and direction
                if (filter == 'subject') {
                	if (this.filters.orderBy !== 'subject')
                		this.filters.subject = 'asc';
                	else
                	    this.filters.subject = this.filters.subject=='asc'?'des':'asc';	
                }else{
                    if (this.filters.orderBy !== 'duration')
                		this.filters.duration = 'asc';
                	else
                	    this.filters.duration = this.filters.duration=='asc'?'des':'asc';	
                }
                this.filters.orderBy = filter;
            },
            filtersActions(){ // Funcion to filter and order subjects
            	this.spinner = true;
            	let subjects = [...this.subjects];
            	clearTimeout(this.searchDelay);
            	this.searchDelay = setTimeout( () => {
            	    if (this.filters.type !== 'All' | this.filters.mode !== 'All' | this.filters.search !== '') // Filter by mode,type or name
            	    	subjects = subjects.filter( item => {
            	    		if ((this.filters.type !== 'All'?item.type === this.filters.type:true) &&
            	    			(this.filters.mode !== 'All'?item.modality === this.filters.mode:true) &&
            	    			(this.filters.search !== ''?item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.filters.search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1:true))
            	    			return item;
            	        });

            	    if (this.filters.orderBy == 'subject')
            	    	subjects = subjects.sort( (a,b) => {
            	    		const nameA = a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            	    		      nameB = b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            	    		if (nameA > nameB)
            	    			return this.filters.subject == 'asc'?1:-1;
            	    		else if (nameA < nameB)
            	    			return this.filters.subject == 'asc'?-1:1;

                            return 0;
            	    	})
            	    else if(this.filters.orderBy == 'duration')
            	    	subjects = subjects.sort( (a,b) => {
            	    		if (a.duration_time > b.duration_time)
            	    			return this.filters.duration == 'asc'?1:-1;
            	    		else if (a.duration_time < b.duration_time)
            	    			return this.filters.duration == 'asc'?-1:1;

                            return 0;
            	    	})

            	    this.filterSubjects = subjects;
            	    this.spinner = false;
                }, 700)
            },
            customizeSubject(sub){ // Save current history
                this.setAssignedSubjectsHistory({ subjects: this.subjects, filterSubjects: this.filterSubjects, page: this.page, filters: this.filters });
                this.setCutomizeSubjectHistory({ idSubject: sub.subject_id, statusPlaces: sub.places, statusSchedule: sub.availability });
                this.$emit('customizeSubject', sub);
            },
            formatTime(minutes){
                if (minutes < 60 )
                    return minutes+' min';
                else{
                    if (minutes%60===0)
                        return (minutes/60)+' hr';
                    else{
                        const hr = Math.trunc(minutes/60);
                        return `${hr} hr ${ minutes-(hr*60) } min`;
                    }

                }
            }
        }
	}
</script>