<template>
    <div class="v-crop-image" :class="{ show: statusShow }" v-if="show">
        <div class="blurcolor" @click="close"></div>
   
        <div class="modal-crop-image" >
            <div class="text-center p-3 position-relative">
                <h3 class="text-center crop-title mb-0">{{ $t('accountDetails.cropImg.title') }}</h3>
                <button class="btn-close" type="button" @click="close"><svgicon class="icon-close" name="cross2" /></button>
            </div>
            <input
            ref="input"
            type="file"
            name="image"
            accept="image/png,image/jpg,image/jpeg"
            @change="e => setImage(e.target.files[0])"
            />
            <div class="img-cropper position-relative">
                <div class="crop-spinner"  v-if="statusImg">
                    <v-spinner />
                </div>
                <vue-cropper
                ref="cropper"
                :aspect-ratio="1"
                :minCropBoxWidth="140"
                :minCropBoxHeight="140"
                :cropBoxResizable="true"
                :zoomOnWheel="false"
                :zoomOnTouch="false"
                :background="false"
                :minContainerHeight="150"
                :minContainerWidth="280"
                :viewMode="1"
                alt="" v-show="!statusImg"/>
                
            </div>
            <div class="text-center p-3">
                <v-btn :txt="$t('accountDetails.cropImg.btn')" min="100px" @click.native="save" :disabled="statusBtn" />
            </div>
        </div>	
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
	export default{
		props: {
			bgIMG: { required: false },
            show: { type: Boolean, default: false, required: false },
            statusBtn: { type: Boolean, default: false, required: false },
            value: ''
		},
		data(){
			return{
                statusShow: false,
                statusImg: false
			}
		},
        computed:{
            ...mapState(['auth'])
        },
		methods:{
            ...mapMutations(['setNotification']),
            onKeyPress(e){ // Close modal on key esc press
                if ("key" in e) {
                    if (e.key === "Escape" || e.key === "Esc") {
                        this.close();
                    }
                }else if(e.keyCode === 27){
                    this.close();
                }
            },
            close(){
                this.statusShow = false;
                document.addEventListener('keydown', this.onKeyPress);
                this.$emit('input', false);
            },
            setImage(e) {
                this.statusImg = true;
            	if (e.type.indexOf('image/') === -1) {
                    this.setNotification({ type: 'warning', msg: 'Please select an image file' });
                    this.statusImg = false;
            		return;
            	}
            	if (typeof FileReader === 'function') {
            		const reader = new FileReader();
            		reader.onload = async (event) => {
                        // rebuild cropperjs with the updated source
                        await this.$refs.cropper.replace(event.target.result);
                        this.statusImg = false;
                    };
                    reader.readAsDataURL(e);
                } else {
                    this.setNotification({ type: 'warning', msg: 'Sorry, FileReader API not supported' });
                    this.statusImg = false;
                }
            },
            save(){
                let canvas = this.$refs.cropper.getCroppedCanvas({ width: 420, height: 420, fillColor: '#fff', imageSmoothingQuality: 'high', imageSmoothingEnabled: true, webkitImageSmoothingEnabled: true, mozImageSmoothingEnabled: true });
                this.$emit('input', canvas);
            }
		},
		watch:{
			bgIMG: function(newVal){
                if (newVal !== '')
                    this.setImage(newVal);
			},
            show: async function(newVal){
                await this.$nextTick( () => {
                    this.statusShow = newVal;
                    if (newVal)
                        document.addEventListener('keydown', this.onKeyPress);
                    else
                        document.removeEventListener('keydown', this.onKeyPress);
                } );
            }
		}
	}
</script>