import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from "@sentry/integrations";

const initSentry = () => {
    if (process.env.NODE_ENV === 'production'){
    	const clientOptionsDefaultIntegrations = {
    		dsn: 'https://22f808e174864c5599ef5bb89328434d@o367121.ingest.sentry.io/5170294',
    		ignoreErrors: ['Network Error'],
    		integrations: [...Sentry.defaultIntegrations, new Integrations.Vue({Vue, attachProps: true }, new Integrations.RewriteFrames())],
    		environment: process.env.NODE_ENV,
    		release: process.env.VUE_APP_SENTRY_RELEASE
    	};

    	const client = new Sentry.BrowserClient(clientOptionsDefaultIntegrations);
    	Sentry.getCurrentHub().bindClient(client);
    }else{
    	console.debug('Not configuring Sentry, environment is', process.env.NODE_ENV);
    }
}

export{
	initSentry
}
