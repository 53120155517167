/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'combine': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 7H13V2.5c0-.827-.673-1.5-1.5-1.5h-10C.673 1 0 1.673 0 2.5v10c0 .827.673 1.5 1.5 1.5H6v4.5c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5v-10c0-.827-.673-1.5-1.5-1.5zm.5 11.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5V13H1.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h10a.5.5 0 01.5.5V8h5.5a.5.5 0 01.5.5v10z"/>'
  }
})
