<template>
	<div class="v-modal" :class="{ active: status }" v-if="show">
		<!-- ============ Background color ============ -->
		<div class="v-blurColor" @click="close(); $emit('close');"></div>

		<!-- =============== Modal =============== -->
		<div class="v-modal-paper modal-place d-flex flex-wrap justify-content-center text-center p-4 p-sm-5">
			<h3 class="title mb-3 w-100 mt-3 mt-sm-4">{{ title }}</h3>

			<svgicon class="icon-warning mb-3" name="warning" color="#F5A623" />

			<p class="descrip w-100 mb-4 mb-sm-5 mt-3">{{ msg }}</p>

			<v-btn class="mb-4 w-100" height="40px" :txt="btn" @click.native="msgType=='areChanges'?$emit('prevAction'):$emit('clear'); close()" />

			<button class="btn-cancel" @click="close(); $emit('close');">{{ $t('availability.btn.cancel') }}</button>
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			show: { type: Boolean, default: false, required: true },
			msgType: { type: String, default: 'areChanges', required: false  }, // areChanges, clear
			disabledDisponibility: { default: false, required: false }
		},
		data(){
			return{
				status: false
			}
		},
		computed:{
            title(){
            	if(this.msgType == 'clearPlaces')
            		return this.$t('availability.modals.specificPlaces.t1');
                else
                    return this.$t('availability.modals.specificScheduling.t1');
            },
            msg(){
            	if(this.msgType == 'clearPlaces')
                    return this.$t('availability.modals.specificPlaces.msg1');
                else
                    return this.$t('availability.modals.specificScheduling.msg1');
            },
            btn(){
            	if(this.msgType == 'clearPlaces')
                    return this.$t('availability.modals.specificPlaces.btnY');
                else
                    return this.$t('availability.modals.specificScheduling.btnY');
            }
		},
		methods:{
			onKeyPress(e){
                if ("key" in e){
                	if (e.key === 'Escape' || e.key === 'Esc'){
                		this.close();
                		this.$emit('close');
                	}
                }
                else if(e.keyCode === 27){
                	this.close();
                	this.$emit('close');
                }
			},
            close(){
            	this.status= false;
            	document.removeEventListener('keydown', this.onKeyPress);
            	setTimeout( () => {
                    this.$emit('closeModalplace');
            	},400);
            }
		},
		watch:{
			show: function(newVal){
				this.$nextTick( () => {
					if (newVal && !this.status && !this.disabledDisponibility){
						this.status = true;
						document.addEventListener('keydown', this.onKeyPress);
					}
				} )
			}
		}
	}
</script>