/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevrons-contract-vertical': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 7.5a.502.502 0 01-.354-.146l-6-6a.5.5 0 01.707-.707l5.646 5.646L15.145.647a.5.5 0 01.707.707l-6 6a.498.498 0 01-.354.146zM15.5 19.5a.502.502 0 01-.354-.146L9.5 13.708l-5.646 5.646a.5.5 0 01-.707-.707l6-6a.5.5 0 01.707 0l6 6a.5.5 0 01-.354.853z"/>'
  }
})
