/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'noun_check': {
    width: 13,
    height: 16,
    viewBox: '0 0 13 16',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M3.99 10.309c.268.419.673.67 1.11.69a.878.878 0 00.056.001c.417 0 .818-.212 1.108-.59l2.603-3.386a.648.648 0 00-.094-.886.59.59 0 00-.851.099L5.319 9.624c-.056.074-.116.122-.166.116-.046-.002-.106-.052-.156-.13l-.888-1.387a.59.59 0 00-.84-.175.646.646 0 00-.167.874l.888 1.387z"/><path pid="1" d="M1.91 15.3h8.2c1.053 0 1.91-.857 1.91-1.91V3.14c0-1.053-.857-1.91-1.91-1.91h-.55A1.255 1.255 0 008.305 0H3.714c-.683 0-1.24.55-1.253 1.23h-.55C.856 1.23 0 2.087 0 3.14v10.25c0 1.053.857 1.91 1.91 1.91zM3.001 1.255c0-.394.32-.714.713-.714h4.592c.393 0 .713.32.713.714v.492c0 .393-.32.713-.713.713H3.714a.714.714 0 01-.713-.713v-.492zM.541 3.14c0-.755.614-1.37 1.37-1.37h.55c.013.68.57 1.23 1.253 1.23h4.592c.683 0 1.24-.55 1.253-1.23h.55c.756 0 1.37.614 1.37 1.37V13.39a1.37 1.37 0 01-1.37 1.369H1.91a1.37 1.37 0 01-1.369-1.37V3.142z"/></g>'
  }
})
