/* eslint-disable */
require('./3d-glasses')
require('./3d-rotate')
require('./8ball')
require('./abacus')
require('./accessibility')
require('./aim')
require('./alarm-add')
require('./alarm-add2')
require('./alarm-check')
require('./alarm-error')
require('./alarm-remove')
require('./alarm-ringing')
require('./alarm-snooze')
require('./alarm')
require('./alarm2')
require('./album')
require('./align-bottom')
require('./align-center-horizontal')
require('./align-center-vertical')
require('./align-left')
require('./align-right')
require('./align-top')
require('./altavoz-ondas')
require('./ambulance')
require('./anchor')
require('./angle')
require('./angle2')
require('./annoyed')
require('./antenna')
require('./apartment')
require('./apple')
require('./archery')
require('./archive')
require('./archive2')
require('./arrow-divert')
require('./arrow-down-circle')
require('./arrow-down-square')
require('./arrow-down')
require('./arrow-left-circle')
require('./arrow-left-square')
require('./arrow-left')
require('./arrow-return')
require('./arrow-right-circle')
require('./arrow-right-square')
require('./arrow-right')
require('./arrow-right2')
require('./arrow-up-circle')
require('./arrow-up-right')
require('./arrow-up-square')
require('./arrow-up')
require('./arrow-wave')
require('./arrows-merge')
require('./arrows-split')
require('./aspect-ratio')
require('./at-sign')
require('./audio-book')
require('./axe')
require('./axe2')
require('./baby-bottle')
require('./baby')
require('./baby2')
require('./baby3')
require('./backward-circle')
require('./bag-dollar')
require('./bag-euro')
require('./bag-pound')
require('./bag-yen')
require('./bag')
require('./bag2')
require('./balance')
require('./balloon')
require('./bandage')
require('./bandages')
require('./barcode')
require('./barcode2')
require('./barcode3')
require('./baseball-bat')
require('./baseball')
require('./basketball')
require('./bathtub')
require('./battery-alert')
require('./battery-charging')
require('./battery-charging2')
require('./battery-charging3')
require('./battery-charging4')
require('./battery-charging5')
require('./battery-charging6')
require('./battery-charging7')
require('./battery-empty')
require('./battery-error')
require('./battery-full')
require('./battery-low1')
require('./battery-low2')
require('./battery-low3')
require('./battery-mid1')
require('./battery-mid2')
require('./battery-mid3')
require('./battery-power')
require('./beaker')
require('./bed')
require('./bell-fill')
require('./bench-press')
require('./bicycle')
require('./bicycle2')
require('./binoculars')
require('./binoculars2')
require('./blog')
require('./boat')
require('./bold')
require('./bone')
require('./book')
require('./book2')
require('./bookmark')
require('./bookmark2')
require('./border-all')
require('./border-bottom')
require('./border-horizontal')
require('./border-inner')
require('./border-left')
require('./border-none')
require('./border-outer')
require('./border-right')
require('./border-style')
require('./border-top')
require('./border-vertical')
require('./bottle')
require('./bottle2')
require('./bow-tie')
require('./bowling-pins')
require('./bowling')
require('./box')
require('./brain')
require('./bread')
require('./briefcase')
require('./broadcast')
require('./broom')
require('./brush')
require('./brush2')
require('./bubble-alert')
require('./bubble-attachment')
require('./bubble-dots')
require('./bubble-emoticon')
require('./bubble-heart')
require('./bubble-pencil')
require('./bubble-picture')
require('./bubble-question')
require('./bubble-quote')
require('./bubble-text')
require('./bubble-user')
require('./bubble-video')
require('./bubble')
require('./bubbles')
require('./bucket')
require('./bug')
require('./bullhorn')
require('./bus')
require('./bus2')
require('./button')
require('./cable')
require('./cable2')
require('./cactus')
require('./cake')
require('./calculator')
require('./calculator2')
require('./calendar-31')
require('./calendar-check')
require('./calendar-cross')
require('./calendar-empty')
require('./calendar-full')
require('./calendar-insert')
require('./calendar-text')
require('./calendar-user')
require('./calendar')
require('./camera-crossed')
require('./camera-flip')
require('./camera-play')
require('./camera')
require('./camera2')
require('./camera3')
require('./candy')
require('./car-battery')
require('./car-lock')
require('./car-siren')
require('./car-wash')
require('./car-wash2')
require('./car-wash3')
require('./car-wash4')
require('./car')
require('./car2')
require('./carrot')
require('./cart-add')
require('./cart-empty')
require('./cart-exchange')
require('./cart-full')
require('./cart-plus')
require('./cart-plus2')
require('./cart-remove')
require('./cart')
require('./cash-dollar')
require('./cash-euro')
require('./cash-pound')
require('./cash-yen')
require('./cashier')
require('./chair')
require('./chart-bars')
require('./chart-growth copy')
require('./chart-growth')
require('./chart-settings')
require('./chat')
require('./chat2')
require('./check-square')
require('./check')
require('./checkmark-circle-fill')
require('./checkmark-circle')
require('./cheese')
require('./chef')
require('./cherry')
require('./chevron-down-circle')
require('./chevron-down-square')
require('./chevron-down')
require('./chevron-left-circle')
require('./chevron-left-square')
require('./chevron-left')
require('./chevron-right-circle')
require('./chevron-right-square')
require('./chevron-right')
require('./chevron-up-circle')
require('./chevron-up-square')
require('./chevron-up')
require('./chevrons-contract-horizontal')
require('./chevrons-contract-vertical')
require('./chevrons-expand-horizontal')
require('./chevrons-expand-vertical')
require('./chicken')
require('./chip-x64')
require('./chip-x86')
require('./chip')
require('./christmas')
require('./circle-minus')
require('./circle')
require('./citrus')
require('./city')
require('./clapboard-play')
require('./clear')
require('./cli')
require('./clip')
require('./clipboard-alert')
require('./clipboard-check')
require('./clipboard-down')
require('./clipboard-empty')
require('./clipboard-left')
require('./clipboard-pencil')
require('./clipboard-text')
require('./clipboard-user')
require('./clock')
require('./clock2')
require('./clock3')
require('./close-sharp')
require('./cloud-alert')
require('./cloud-check')
require('./cloud-cross')
require('./cloud-crossed')
require('./cloud-database')
require('./cloud-download')
require('./cloud-fog')
require('./cloud-gear')
require('./cloud-hailstones')
require('./cloud-lightning')
require('./cloud-lock')
require('./cloud-rain')
require('./cloud-snow')
require('./cloud-sun')
require('./cloud-sync')
require('./cloud-upload')
require('./cloud-windy')
require('./cloud')
require('./clubs')
require('./code')
require('./coffee-bean')
require('./coffee-cup')
require('./cog')
require('./cog2')
require('./coin-dollar')
require('./coin-euro')
require('./coin-pound')
require('./coin-yen')
require('./color-sampler')
require('./combine')
require('./communication-crossed')
require('./communication')
require('./compare')
require('./compass')
require('./compass2')
require('./compressed')
require('./concave')
require('./confused')
require('./construction-cone')
require('./construction')
require('./contacts')
require('./contract')
require('./contract2')
require('./contract3')
require('./contrast')
require('./convex')
require('./cool')
require('./coolant-temperature')
require('./copy')
require('./cord')
require('./couch')
require('./credit-card')
require('./cronometro-amarillo')
require('./crop')
require('./cross-circle')
require('./cross-square')
require('./cross')
require('./cross2')
require('./crown')
require('./cube')
require('./dagger')
require('./dashboard')
require('./dashboards1')
require('./dashboards2')
require('./dashboardt1')
require('./dashboardt2')
require('./database-add')
require('./database-check')
require('./database-download')
require('./database-history')
require('./database-lock')
require('./database-refresh')
require('./database-remove')
require('./database-upload')
require('./database')
require('./delete')
require('./desk-tape')
require('./desktop')
require('./dial')
require('./diamond')
require('./diamond2')
require('./diamond3')
require('./diamond4')
require('./diamonds')
require('./dice')
require('./dinner')
require('./dinner2')
require('./direction-ltr')
require('./direction-rtl')
require('./disc')
require('./dna')
require('./doc')
require('./document')
require('./document2')
require('./dolly')
require('./download')
require('./download2')
require('./download3')
require('./drawers')
require('./drawers2')
require('./drawers3')
require('./dream')
require('./drop-crossed')
require('./drop')
require('./drop2')
require('./dumbbell')
require('./earth-lock')
require('./earth')
require('./easter-egg')
require('./egg')
require('./egg2')
require('./eggs')
require('./eject-circle')
require('./ellipsis-v')
require('./ellipsis')
require('./end-call')
require('./engine')
require('./enter-down')
require('./enter-down2')
require('./enter-horizontal')
require('./enter-left')
require('./enter-left2')
require('./enter-right')
require('./enter-right2')
require('./enter-up')
require('./enter-up2')
require('./enter-vertical')
require('./enter')
require('./enter2')
require('./envelope-open')
require('./envelope')
require('./equalizer')
require('./eraser')
require('./escape')
require('./evil')
require('./exclamation')
require('./exclude')
require('./exit-down')
require('./exit-down2')
require('./exit-left')
require('./exit-left2')
require('./exit-right')
require('./exit-right2')
require('./exit-up')
require('./exit-up2')
require('./exit')
require('./expand')
require('./expand2')
require('./expand3')
require('./expand4')
require('./eye-crossed')
require('./eye-dropper')
require('./eye-fill')
require('./eye-minus')
require('./eye-plus')
require('./eye')
require('./eye2')
require('./face-detection')
require('./faceToFace')
require('./factory')
require('./factory2')
require('./fan')
require('./feather')
require('./feather2')
require('./feather3')
require('./fence')
require('./file-add')
require('./file-audio')
require('./file-charts')
require('./file-check')
require('./file-code')
require('./file-empty')
require('./file-image')
require('./file-lock')
require('./file-preview')
require('./file-search')
require('./file-spreadsheet')
require('./file-stats')
require('./file-video')
require('./file-zip')
require('./file')
require('./files')
require('./film-play')
require('./film')
require('./film2')
require('./find-replace')
require('./finger-tap')
require('./finger-tap2')
require('./fingers-crossed')
require('./fingers-scroll-horizontal')
require('./fingers-scroll-horizontal2')
require('./fingers-scroll-left')
require('./fingers-scroll-left2')
require('./fingers-scroll-right')
require('./fingers-scroll-right2')
require('./fingers-scroll-vertical')
require('./fingers-scroll-vertical2')
require('./fingers-scroll-vertical3')
require('./fingers-tap')
require('./fingers-tap2')
require('./fingers-victory')
require('./fire')
require('./first-aid')
require('./first-circle')
require('./fish')
require('./flag')
require('./flag2')
require('./flag3')
require('./flare')
require('./flash-auto')
require('./flash-memory')
require('./flashlight')
require('./flip-flops')
require('./flip-horizontal')
require('./flip-horizontal2')
require('./flip-vertical')
require('./flip-vertical2')
require('./floppy-disk')
require('./focus')
require('./folder-download')
require('./folder-film')
require('./folder-heart')
require('./folder-minus')
require('./folder-music')
require('./folder-picture')
require('./folder-plus')
require('./folder-search')
require('./folder-shared')
require('./folder-star')
require('./folder-upload')
require('./folder-user')
require('./folder')
require('./football')
require('./footprint')
require('./forward-circle')
require('./frame-contract')
require('./frame-expand')
require('./funnel')
require('./gallon')
require('./game')
require('./gamepad')
require('./gas')
require('./gesture-pinch')
require('./gesture-pinch2')
require('./gesture-zoom')
require('./gesture-zoom2')
require('./ghost-fill')
require('./ghost-hipster')
require('./ghost')
require('./gift')
require('./glass-cocktail')
require('./glass')
require('./glass2')
require('./glasses')
require('./glasses2')
require('./golf')
require('./golf2')
require('./google-play')
require('./gradient')
require('./gradient2')
require('./graduation-hat')
require('./grapes')
require('./graph')
require('./grid-crossed')
require('./grid')
require('./grin-evil')
require('./grin')
require('./group-work')
require('./group')
require('./grumpy')
require('./guitar')
require('./gun')
require('./haircut')
require('./halloween')
require('./hamburger')
require('./hammer-wrench')
require('./hammer')
require('./hammer2')
require('./hand-waving')
require('./hand')
require('./hand2')
require('./hanger')
require('./happy-grin')
require('./happy')
require('./hat')
require('./hdd-down')
require('./hdd-up')
require('./hdd')
require('./headphones')
require('./headset')
require('./hearing')
require('./heart-pulse')
require('./heart')
require('./hearts')
require('./height')
require('./helicopter')
require('./highlight')
require('./history')
require('./history2')
require('./hockey')
require('./home')
require('./home2')
require('./home3')
require('./home4')
require('./home5')
require('./home6')
require('./hotdog')
require('./hourglass')
require('./ice-cream')
require('./ice-cream2')
require('./icons')
require('./icons2')
require('./image')
require('./inbox')
require('./inbox2')
require('./indent-decrease')
require('./indent-increase')
require('./info')
require('./intersect')
require('./italic')
require('./joystick')
require('./jump')
require('./jump2')
require('./key-hole')
require('./key')
require('./keyboard-down')
require('./keyboard-up')
require('./keyboard')
require('./knife')
require('./label')
require('./lamp')
require('./lampshade')
require('./lan')
require('./lan2')
require('./landscape')
require('./laptop-phone')
require('./laptop')
require('./last-circle')
require('./launch')
require('./laundry')
require('./layers-crossed')
require('./layers')
require('./leaf')
require('./library')
require('./library2')
require('./license')
require('./license2')
require('./lifebuoy')
require('./ligature')
require('./lighter')
require('./line-spacing')
require('./linearicons')
require('./link')
require('./link2')
require('./list')
require('./list2')
require('./list3')
require('./list4')
require('./list5')
require('./loading')
require('./loading2')
require('./loading3')
require('./location')
require('./lock')
require('./lock2')
require('./logo')
require('./lollipop')
require('./lotus')
require('./loudspeaker')
require('./loupe-zoom-in')
require('./loupe-zoom-out')
require('./loupe')
require('./luggage-weight')
require('./mad')
require('./magic-wand')
require('./magnet')
require('./magnifier')
require('./magnifying')
require('./magnifying2')
require('./mailbox-empty')
require('./mailbox-full')
require('./man-woman')
require('./man')
require('./man2')
require('./map-marker-check')
require('./map-marker-crossed')
require('./map-marker-down')
require('./map-marker-user')
require('./map-marker')
require('./map')
require('./map2')
require('./medal-empty')
require('./medal-first')
require('./medal-second')
require('./medal-third')
require('./media')
require('./meeting')
require('./menu-circle')
require('./menu-square')
require('./menu')
require('./menu2')
require('./menu3')
require('./mic-mute')
require('./mic')
require('./mic2')
require('./microscope')
require('./minus-square')
require('./minus')
require('./moon')
require('./mouse-both')
require('./mouse-left')
require('./mouse-right')
require('./mouse')
require('./move')
require('./music-note')
require('./music-note2')
require('./music-note3')
require('./mustache-glasses')
require('./mustache')
require('./mustache2')
require('./mute')
require('./network-lock')
require('./network')
require('./neutral')
require('./new-tab')
require('./news')
require('./next-circle')
require('./no-camera')
require('./no-micro')
require('./notification-circle')
require('./notification')
require('./noun_check')
require('./oil-pressure')
require('./outbox')
require('./outlet')
require('./page-break')
require('./page-break2')
require('./paint-roller')
require('./palette')
require('./panorama')
require('./pants')
require('./paper-plane')
require('./paperclip')
require('./papers')
require('./parking')
require('./paste')
require('./pause-circle')
require('./paw')
require('./pdf')
require('./pen-add')
require('./pen-remove')
require('./pen')
require('./pen2')
require('./pen3')
require('./pencil-line')
require('./pencil-ruler')
require('./pencil-ruler2')
require('./pencil')
require('./pencil2')
require('./pencil3')
require('./pencil4')
require('./pencil5')
require('./pencil6')
require('./percent-circle')
require('./percent-square')
require('./percent')
require('./phone-bubble')
require('./phone-error')
require('./phone-in-out')
require('./phone-incoming')
require('./phone-lock')
require('./phone-minus')
require('./phone-outgoing')
require('./phone-pause')
require('./phone-plus')
require('./phone-sip')
require('./phone-wave')
require('./phone')
require('./pickaxe')
require('./picture')
require('./picture2')
require('./picture3')
require('./pictures')
require('./pie-chart')
require('./pie-chart2')
require('./pilcrow')
require('./pills')
require('./pin')
require('./pine-tree')
require('./ping-pong')
require('./pipe')
require('./pizza')
require('./plane-crossed')
require('./plane')
require('./planet')
require('./platter')
require('./play-bound')
require('./play-circle')
require('./play')
require('./play2')
require('./playlist-add')
require('./playlist')
require('./plus-circle')
require('./plus-square')
require('./plus')
require('./podium')
require('./pointer-down')
require('./pointer-down2')
require('./pointer-left')
require('./pointer-left2')
require('./pointer-right')
require('./pointer-right2')
require('./pointer-up')
require('./pointer-up2')
require('./poop')
require('./portrait')
require('./portrait2')
require('./power-crossed')
require('./power-switch')
require('./power')
require('./presentation')
require('./preview')
require('./previous-circle')
require('./printer')
require('./profile')
require('./prohibited')
require('./pulse')
require('./pushpin')
require('./pushpin2')
require('./puzzle')
require('./qr_code_EN')
require('./qr_code_ES')
require('./question-circle')
require('./question')
require('./quote-close')
require('./quote-open')
require('./radar')
require('./radio-button')
require('./radio')
require('./rain')
require('./rank')
require('./rank2')
require('./rank3')
require('./reading')
require('./receipt')
require('./record')
require('./recycle')
require('./redo')
require('./redo2')
require('./refresh')
require('./refresh2')
require('./refund')
require('./register')
require('./reminder')
require('./reminder2')
require('./remote-control')
require('./repeat-one')
require('./repeat-one2')
require('./repeat-one3')
require('./repeat')
require('./reply-all')
require('./reply')
require('./resize-handle')
require('./return')
require('./return2')
require('./road-sign')
require('./road')
require('./rocket')
require('./rotation-lock')
require('./ruler')
require('./rulers')
require('./run')
require('./sad')
require('./satellite')
require('./satellite2')
require('./sausage')
require('./save-arrow')
require('./saw')
require('./scale-truck')
require('./scale')
require('./scale2')
require('./scissors')
require('./screen-share')
require('./screen')
require('./screwdriver')
require('./select')
require('./select2')
require('./self-timer')
require('./send')
require('./server')
require('./shape')
require('./share')
require('./share2')
require('./share3')
require('./sheet')
require('./shield-alert')
require('./shield-check')
require('./shield-cross')
require('./shield')
require('./ship')
require('./ship2')
require('./shirt')
require('./shocked')
require('./shocked2')
require('./shoe')
require('./shoes')
require('./shorts')
require('./shovel')
require('./shredder')
require('./shuffle')
require('./shutter')
require('./shutter2')
require('./sigma')
require('./signal-0')
require('./signal-20')
require('./signal-40')
require('./signal-60')
require('./signal-80')
require('./signal-blocked')
require('./signal-lock')
require('./signal')
require('./sim')
require('./siren')
require('./site-map')
require('./skull')
require('./slide')
require('./slingshot')
require('./smartphone-embed')
require('./smartphone-notification')
require('./smartphone-vibration')
require('./smartphone-waves')
require('./smartphone')
require('./smile-keyboard')
require('./smile')
require('./snow')
require('./snow2')
require('./soccer')
require('./socks')
require('./sort-alpha-asc')
require('./sort-alpha-desc')
require('./sort-amount-asc')
require('./sort-amount-desc')
require('./sort-numeric-asc')
require('./sort-numeric-desc')
require('./sort-time-asc')
require('./sort-time-desc')
require('./spades')
require('./speed-fast')
require('./speed-medium')
require('./speed-slow')
require('./spell-check')
require('./spotlights')
require('./spray')
require('./square')
require('./stamp')
require('./star-empty')
require('./star-half')
require('./star')
require('./stars2')
require('./steak')
require('./stop-circle')
require('./stop')
require('./store-24')
require('./store')
require('./stream-alert')
require('./stream-check')
require('./stream-error')
require('./stream')
require('./strikethrough')
require('./subtract')
require('./sun-small')
require('./sun-wind')
require('./sun')
require('./sun2')
require('./surveillance')
require('./surveillance2')
require('./swim')
require('./sync-crossed')
require('./sync-crossed2')
require('./sync')
require('./sync2')
require('./syringe')
require('./tab')
require('./tablet')
require('./tablet2')
require('./tag')
require('./tags')
require('./tape')
require('./tape2')
require('./taxi')
require('./teacher-female')
require('./teacher-male')
require('./teacup')
require('./teapot')
require('./telephone')
require('./telephone2')
require('./tennis')
require('./tennis2')
require('./text-align-center')
require('./text-align-justify')
require('./text-align-left')
require('./text-align-right')
require('./text-format-remove')
require('./text-format')
require('./text-size')
require('./text-wrap')
require('./texture')
require('./theater')
require('./thermometer')
require('./thumbs-down')
require('./thumbs-down2')
require('./thumbs-down3')
require('./thumbs-up')
require('./thumbs-up2')
require('./thumbs-up3')
require('./ticket')
require('./tie')
require('./time-lapse')
require('./time-lapse2')
require('./timer-crossed')
require('./timer-crossed2')
require('./timer')
require('./timer2')
require('./tip')
require('./tired')
require('./tissue')
require('./toggle-off')
require('./toggle-on')
require('./toggle')
require('./toilet-paper')
require('./tombstone-hipster')
require('./tombstone')
require('./tongue')
require('./tongue2')
require('./toothbrush')
require('./tornado')
require('./traffic-lights')
require('./trailer')
require('./train')
require('./transform')
require('./transmission')
require('./trash')
require('./trash2')
require('./trash3')
require('./tree')
require('./trello-1')
require('./trello-2')
require('./trello-3')
require('./trophy')
require('./trophy2')
require('./truck')
require('./trumpet')
require('./tutors-empty')
require('./tutors-empty1')
require('./tv')
require('./txt')
require('./typewriter')
require('./umbrella')
require('./umbrella2')
require('./underline')
require('./undo')
require('./undo2')
require('./unlink')
require('./unlink2')
require('./unlock')
require('./upload')
require('./upload2')
require('./usb-drive')
require('./user-lock')
require('./user-minus')
require('./user-plus')
require('./user')
require('./user2')
require('./users-minus')
require('./users-plus')
require('./users')
require('./users2')
require('./vault')
require('./vector')
require('./voicemail')
require('./volume-high')
require('./volume-low')
require('./volume-medium')
require('./volume')
require('./walk')
require('./wall')
require('./wall2')
require('./wallet')
require('./warning')
require('./watch')
require('./weight')
require('./whatsapp')
require('./wheelchair')
require('./wifi-alert-low')
require('./wifi-alert-low2')
require('./wifi-alert-mid')
require('./wifi-alert')
require('./wifi-blocked')
require('./wifi-lock')
require('./wifi-low')
require('./wifi-low2')
require('./wifi-mid')
require('./wifi')
require('./wind')
require('./window')
require('./wink')
require('./woman')
require('./woman2')
require('./wondering')
require('./wow')
require('./wrench')
require('./zipped')
require('./zoom-in')
require('./zoom-out')
