<template>
	<div class="card-tutor paper p-3" :class="anchor?'currentCard':''">
		<div class="row ml-0 mr-0">
			<div class="col-sm pl-sm-1 col-img d-flex justify-content-center">
				<div class="content-img mb-1" :style="{ background : `url(${datas.photo})center center / cover no-repeat` }"></div>
			</div>
			<div class="info-tutor col pl-sm-1 mb-3 mb-md-0 d-flex flex-column align-items-center align-items-sm-start">
				<router-link class="mb-1" :to="{ name: 'tutorProfile-student', params: { tutor: datas.id } }" :style="{ maxWidth: '100%' }" tag="div">
					<v-hover-txt class="name-tutor" fontSize="22px" :txt="datas.name" />
				</router-link>
				<div class="score d-flex align-items-end" :class="datas.rating?'mb-2':'mb-sm-4 mb-2'">
					<star-rating class="starts" :increment="0.1" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" :rating="parseInt(datas.rating)" :star-size="18" :border-width="0.1" :attr="`(${datas.total_ratings})`" text-class="custom-text" inactive-color="#FCE5BF" active-color="#F5A623" read-only v-if="datas.rating"/>
				</div>
				<p class="descrip" v-html="datas.description"></p>
			</div>
			<div class="col-md info d-flex flex-wrap pr-0">
				<h4 class="title-subjects w-100">{{ $t('scheduleLesson.subjectst') }}</h4>
				<div class="card-tags mb-3 mb-md-0 w-100">
					<span class="card-tag" :class="{ active : subject==item }" v-for="item in datas.subjects" @click="handleSubject(subject!==item?item:'')">{{ item }}</span>
					<span class="card-tag schedule" :class="{ active : subject=='-ALL-' && statusSteps }" @click="handleSubject(subject=='-ALL-'?'':'-ALL-')">{{ $t('scheduleLesson.seeAll') }}</span>
				</div>
				<v-btn class="w-100 align-self-end mt-2" :txt="$t('scheduleLesson.btn')" @click.native="subject=''; statusSteps=true; $emit('scrollTo')" :disabled="statusSteps"/>
			</div>
		</div>
		<v-slide-animation :show="statusSteps">
		    <div class="schadule-steps d-flex justify-content-center" :name="nameTrans" tag="div" >
		    	<transition name="bound" tag="div" mode="out-in">
			        <!-- ================ Subjects ================ -->
			        <v-subjects class="mb-4 mt-3" key="subjects" :subject="searchSubject" :tutorId="datas.id" v-model="form.lesson" :closeAction="() => close()" :nextAction="() => changeStep('date')"  v-if="step == 'subject'" @changeSchSubject="changeSchSubject"/>

			        <!-- ============== Select day and time ============= -->
			        <v-schedule class="mb-4 mt-3" key="date" v-model="form.date" :lesson="form.lesson" :idTutor="datas.id" :closeAction="() => close()" :prevAction="() => changeStep('subject')" :nextAction="() => changeStep(form.lesson.type=='Grupal' || form.lesson.modality == 'Online'?'summary':'place')" v-if="step == 'date'" />

			        <!-- ============== Place ============= -->
			        <v-place class="mb-4 mt-3" key="place" v-model="form.place" :lesson="form.lesson" :idTutor="datas.id" :closeAction="() => close()" :prevAction="() => changeStep('date')" :nextAction="() => changeStep('summary')" v-if="step == 'place'" />

			        <!-- ============== Summary ============= -->
			        <v-summary class="mb-4 mt-3" key="summary" :tutor="datas" :form="form" :closeAction="() => close()" :prevAction="() => changeStep(form.lesson.type=='Grupal' || form.lesson.modality == 'Online'?'date':'place')" v-if="step == 'summary'" />	
			    </transition>
		    </div>
		</v-slide-animation>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props: {
			datas: { required: true },
			currentSubject: { type: String, default: '' }
		},
		computed:{
			...mapState(['lessonRequest']),
			searchSubject(){
                if (this.subject !== '')
                	return this.subject;
                else if(this.subject === '')
                    return this.schSubject;
			}
		},
		data(){
			return{
				anchor: false,
				subject: '',
				schSubject: '',
				form: {
					lesson: '',
					date: {},
					place: {}
				},
				statusSteps: false,
				step: 'subject',
				nameTrans: 'slide-right'
			}
		},
		created(){
			this.schSubject = this.currentSubject;
			if (this.lessonRequest.lesson.tutor)
                if(this.lessonRequest.lesson.tutor.id==this.datas.id){
                	this.form = this.lessonRequest.lesson.lesson;
                	this.anchor = true;
                }
		},
		mounted(){
            if (this.lessonRequest.lesson.tutor)
                if(this.lessonRequest.lesson.tutor.id==this.datas.id){
                	this.statusSteps = true;
                }
		},
		methods: {
			close(){
				this.subject = '';
                this.statusSteps = false;
			},
			changeStep(step){
                this.step = step;
			},
			handleSubject(subject= ''){
				if(subject != ''){
					this.changeStep('subject');
					this.subject = subject;
					this.form = {
						lesson: '',
						date: {},
						place: {}
					}
                }else
                    this.close();

                this.statusSteps=true;
                this.$emit('scrollTo');
			},
			changeSchSubject(newVal){
                this.schSubject = newVal;
			}
		},
		watch:{
			'form.lesson': function(newVal, oldVal){
            	if (newVal.subject_id != oldVal.subject_id)
            	    this.form = {
					    lesson: newVal,
					    date: {},
					    place: {}
            	    }
            }
		}
	}
</script>