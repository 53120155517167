/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drawers': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.5 3h-11C3.673 3 3 3.673 3 4.5v12a.5.5 0 001 0V16h12v.5a.5.5 0 001 0v-12c0-.827-.673-1.5-1.5-1.5zm-11 1h11a.5.5 0 01.5.5V7H4V4.5a.5.5 0 01.5-.5zM16 8v3H4V8h12zM4 15v-3h12v3H4z"/><path pid="1" d="M10.5 6h-1a.5.5 0 010-1h1a.5.5 0 010 1zM10.5 10h-1a.5.5 0 010-1h1a.5.5 0 010 1zM10.5 14h-1a.5.5 0 010-1h1a.5.5 0 010 1z"/>'
  }
})
