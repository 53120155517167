var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-4", attrs: { id: "manage" } }, [
    _c(
      "section",
      { ref: "lessons", staticClass: "pending-lessons mb-5" },
      [
        _c(
          "div",
          {
            staticClass:
              "row ml-n1 mr-n1 align-items-center justify-content-between"
          },
          [
            _c("div", { staticClass: "col-auto pl-1 pr-1" }, [
              _c(
                "h4",
                { staticClass: "s-title d-flex align-items-center mb-0" },
                [
                  _c("div", { staticClass: "point bg-lesson-pending mr-1" }),
                  _vm._v(
                    " \n                        " +
                      _vm._s(_vm.$t("lessons.tPending")) +
                      "\n                        "
                  ),
                  _c("v-more-info", {
                    staticClass: "ml-2",
                    attrs: { txtCloud: _vm.$t("moreInfo.infoPendingT") }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col pl-1 pr-1 text-right" }, [
              _vm.pendingLimit < _vm.pending.length
                ? _c(
                    "button",
                    {
                      staticClass: "btn-lessons",
                      class: {
                        active: _vm.pendingSlice == _vm.pending.length,
                        "color-tutor-main": true
                      },
                      on: {
                        click: function($event) {
                          _vm.pendingSlice =
                            _vm.pendingSlice == _vm.pendingLimit
                              ? _vm.pending.length
                              : _vm.pendingLimit
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("lessons.btnAll")) +
                          " (" +
                          _vm._s(_vm.pending.length) +
                          ")"
                      ),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [
                          _c("svgicon", {
                            staticClass: "fill-green-main",
                            attrs: { name: "chevron-down" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "bound", mode: "out-in" } },
          [
            _vm.spinner && _vm.pending.length === 0
              ? _c(
                  "div",
                  {
                    key: "spinner",
                    staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                  },
                  [_c("v-spinner")],
                  1
                )
              : !_vm.spinner && _vm.pending.length === 0
              ? _c(
                  "div",
                  {
                    key: "alert",
                    staticClass:
                      "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                  },
                  [
                    _c("svgicon", {
                      staticClass: "img pl-3 pr-3",
                      attrs: { original: "", name: "dashboardt2" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pl-3 pr-3 text-center text-sm-left mt-2",
                        style: { maxWidth: "300px" }
                      },
                      [
                        _c("p", {
                          staticClass: "txt mb-2",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("msgEmpty.msgPending"))
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "txt2 mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("msgEmpty.will")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "transition-group",
                  {
                    key: "lessons",
                    staticClass: "lessons row mr-0 ml-0",
                    attrs: { name: "bound", tag: "div" }
                  },
                  _vm._l(_vm.pending.slice(0, _vm.pendingSlice), function(
                    lesson,
                    i
                  ) {
                    return _c("v-lesson", {
                      key: lesson.id,
                      staticClass: "col-12 mt-3",
                      attrs: { w: _vm.cardW, lesson: lesson }
                    })
                  }),
                  1
                )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "upcoming-lessons mb-5" },
      [
        _c(
          "div",
          {
            staticClass:
              "row ml-n1 mr-n1 align-items-center justify-content-between"
          },
          [
            _c("div", { staticClass: "col-auto pl-1 pr-1" }, [
              _c(
                "h4",
                { staticClass: "s-title mb-0 d-flex align-items-center" },
                [
                  _c("div", { staticClass: "point bg-lesson-upcoming mr-1" }),
                  _vm._v(
                    " \n                        " +
                      _vm._s(_vm.$t("lessons.tUpcoming")) +
                      "\n                        "
                  ),
                  _c("v-more-info", {
                    staticClass: "ml-2",
                    attrs: { txtCloud: _vm.$t("moreInfo.infoUpcomingT") }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col pl-1 pr-1 text-right" }, [
              _vm.upcomingLimit < _vm.upcoming.length
                ? _c(
                    "button",
                    {
                      staticClass: "btn-lessons",
                      class: {
                        active: _vm.upcomingSlice == _vm.upcoming.length,
                        [`color-${_vm.auth.permission}-main`]: true
                      },
                      on: {
                        click: function($event) {
                          _vm.upcomingSlice =
                            _vm.upcomingSlice == _vm.upcomingLimit
                              ? _vm.upcoming.length
                              : _vm.upcomingLimit
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("lessons.btnAll")) +
                          " (" +
                          _vm._s(_vm.upcoming.length) +
                          ")"
                      ),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [
                          _c("svgicon", {
                            staticClass: "fill-green-main",
                            attrs: { name: "chevron-down" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "bound", mode: "out-in" } },
          [
            _vm.spinner && _vm.upcoming.length === 0
              ? _c(
                  "div",
                  {
                    key: "spinner",
                    staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                  },
                  [_c("v-spinner")],
                  1
                )
              : !_vm.spinner && _vm.upcoming.length === 0
              ? _c(
                  "div",
                  {
                    key: "alert",
                    staticClass:
                      "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                  },
                  [
                    _c("svgicon", {
                      staticClass: "img pl-3 pr-3",
                      attrs: { original: "", name: "dashboardt1" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pl-3 pr-3 text-center text-sm-left mt-2",
                        style: { maxWidth: "300px" }
                      },
                      [
                        _c("p", {
                          staticClass: "txt mb-2",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("msgEmpty.msgUpcoming"))
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "txt2 mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("msgEmpty.will")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "transition-group",
                  {
                    key: "lessons",
                    staticClass: "lessons",
                    attrs: { name: "bound", tag: "div" }
                  },
                  _vm._l(_vm.upcoming.slice(0, _vm.upcomingSlice), function(
                    lesson,
                    i
                  ) {
                    return _c("v-lesson", {
                      key: lesson.id,
                      staticClass: "col-12 mt-3",
                      attrs: { w: _vm.cardW, lesson: lesson }
                    })
                  }),
                  1
                )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "ongoing-lesson" },
      [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c("svgicon", {
              staticClass: "ongoing-icon",
              attrs: { name: "play-bound", original: "" }
            }),
            _vm._v(" "),
            _c("h4", { staticClass: "s-title mb-0 pl-1 pr-2" }, [
              _vm._v(_vm._s(_vm.$t("lessons.tOngoing")) + " ")
            ]),
            _vm._v(" "),
            _c("v-more-info", {
              attrs: { txtCloud: _vm.$t("moreInfo.infoOngoingS") }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "bound", mode: "out-in" } },
          [
            _vm.spinner && _vm.active.length === 0
              ? _c(
                  "div",
                  {
                    key: "spinner",
                    staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                  },
                  [_c("v-spinner")],
                  1
                )
              : _vm.active.length === 0 && !_vm.spinner
              ? _c(
                  "div",
                  {
                    key: "alert",
                    staticClass:
                      "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                  },
                  [
                    _c("svgicon", {
                      staticClass: "img pl-3 pr-3",
                      attrs: { original: "", name: "dashboardt2" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pl-3 pr-3 text-center text-sm-left mt-2",
                        style: { maxWidth: "300px" }
                      },
                      [
                        _c("p", {
                          staticClass: "txt mb-2",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("msgEmpty.msgOngoing"))
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "transition-group",
                  {
                    key: "lessons",
                    staticClass: "row ml-0 mr-0",
                    attrs: { name: "bound", tag: "div" }
                  },
                  _vm._l(_vm.active, function(lesson, i) {
                    return _c("v-ongoing-lesson", {
                      key: lesson.id,
                      staticClass: "col-12 mt-3",
                      attrs: { w: _vm.cardW, lesson: lesson }
                    })
                  }),
                  1
                )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }