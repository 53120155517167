
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State } from 'vuex-class';

    enum Type {
    	G = "Grupal",
    	O = "Individual"
    };

    enum Modality {
    	O = "Online",
    	I = "Presencial"
    };

	@Component
	export default class ItemNotification extends Vue{
        @Prop({ type: Object, default: undefined, required: true }) readonly notification!: any;
        @Prop({ type: Boolean, default: false, required: false }) readonly active!: Boolean;

        private type = Type;
        private modality = Modality;

        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.permission ) userType;

        get keyFormat(): any{
            const key = this.notification.key.split('_');
            return { lessonStatus: key[1], type: key[2], modality: key[3] }
        }

        get lessonPhotoOpc(): object{
            return { photo: this.notification?`${process.env.NODE_ENV==='production'?'https://media.linc.mx/images/universities/app/profile/':'https://media.linc.mx/images/dev/universities/app/profile/'}${this.notification.photo}`:'', lessonType: this.type[this.keyFormat.type], size: '50px', statusType: (this.userType == 'student' || (this.userType == 'tutor' && this.type[this.keyFormat.type] == 'Individual')) };
        }

        get t(): any{
        	return (this as any).$t;
        }

        get moment(): any{
        	return (this as any).$moment;
        }

        get time(): string{
            return this.formatTime(parseInt(this.notification.time.split('_')[1]));
        }

        formatTime(time): any{
            const localTime = this.moment(),
                  localTimeWithoutTime = this.moment({h:0, m:0, s:0, ms:0}),
                  fbTime = this.moment(time),
                  diffInSeconds =  localTime.diff(fbTime, 'seconds'),
                  minutes = Math.trunc(diffInSeconds / 60),
                  hours = Math.trunc(minutes / 60),
                  days =  Math.ceil(((localTimeWithoutTime.diff(fbTime, 'seconds') / 60) / 60) / 24),
                  weeks = Math.trunc(days / 7),
                  months = localTimeWithoutTime.diff(fbTime, 'months'),
                  years = Math.trunc(months / 12);

            if (diffInSeconds < 60) // 2 to 59 seconds
                return this.t('notifications.time.recent');
            else if(minutes == 1) // One minute 
                return this.t('notifications.time.minuteAgo');
            else if(minutes < 60) // 2 to 59 minutes
                return this.t('notifications.time.minutesAgo', { time: minutes });
            else if(hours == 1) // One hour
                return this.t('notifications.time.hourAgo');
            else if(hours < 24) // 2 to 23 hours
                return this.t('notifications.time.hoursAgo', { time: hours });
            else if(days == 1) // One day
                return this.t('notifications.time.dayAgo');
            else if(days < 7) // 2 to 6 days
                return this.t('notifications.time.daysAgo', { time: days });
            else if(weeks == 1) // One week
                return this.t('notifications.time.weekAgo');
            else if(months == 0) // 2 to 4 weeks
                return this.t('notifications.time.weeksAgo', { time: weeks });
            else if(months == 1) // One month
                return this.t('notifications.time.monthAgo');
            else if(months < 12) // 2 to 11 months
                return this.t('notifications.time.monthsAgo', { time: months });
            else if(years == 1) // One year
                return this.t('notifications.time.yearAgo');
            else if(years > 1 ) // More than two years
                return this.t('notifications.time.yearsAgo', { time: years });
        }
	}
