var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-lesson-tag",
      class: _vm.bgColor,
      style: { height: _vm.height, minWidth: _vm.minWidth }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-center h-100"
        },
        [
          _c(
            "div",
            {
              class:
                (!_vm.txt1 && _vm.txt2) || (_vm.txt1 && !_vm.txt2)
                  ? "pl-2"
                  : "v-sp"
            },
            [
              _vm.txt1
                ? _c(
                    "span",
                    {
                      staticClass:
                        "v-lt-txt pl-2 pr-2 position-relative d-block"
                    },
                    [_vm._v(_vm._s(_vm.txt1))]
                  )
                : _c(
                    "div",
                    { staticClass: "v-lt-content-icon position-relative" },
                    [
                      _c("svgicon", {
                        staticClass: "v-lt-icon",
                        attrs: { name: _vm.icon, color: "#fff" }
                      })
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _vm.txt2
              ? _c(
                  "span",
                  {
                    staticClass: "v-lt-txt d-block",
                    class:
                      _vm.type == "Grupal" &&
                      (_vm.userType == "student" ||
                        (_vm.userType == "tutor" && !_vm.options.lessonLimit))
                        ? "pl-1 pr-2"
                        : "pl-1 pr-2"
                  },
                  [_vm._v(_vm._s(_vm.txt2))]
                )
              : _c(
                  "div",
                  {
                    staticClass: "v-lt-content-icon position-relative ml-1 mr-2"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "v-lt-btnAssistants cloud-hover",
                        attrs: { name: "btn-lesson-assistants" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            !_vm.options.groupAction
                              ? _vm.beforeShowModal({
                                  lesson: { id: _vm.options.idLesson },
                                  action: "v-modal-joinedStudents"
                                })
                              : _vm.$emit("handleGroup")
                          }
                        }
                      },
                      [
                        _c("svgicon", {
                          staticClass: "v-lt-icon",
                          attrs: { name: _vm.icon, color: "#fff" }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "cloud-txt" }, [
                          _vm._v(_vm._s(_vm.$t("modals.join.t")))
                        ])
                      ],
                      1
                    )
                  ]
                )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }