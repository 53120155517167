var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "v-modal", class: { active: _vm.status } }, [
        _c("div", {
          staticClass: "v-blurColor",
          on: {
            click: function($event) {
              _vm.close()
              _vm.$emit("close")
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "v-modal-paper modal-place d-flex flex-wrap justify-content-center text-center p-4 p-sm-5"
          },
          [
            _c("h3", { staticClass: "title mb-3 w-100 mt-3 mt-sm-4" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "icon-warning mb-3",
              attrs: { name: "warning", color: "#F5A623" }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "descrip w-100 mb-4 mb-sm-5 mt-3" }, [
              _vm._v(_vm._s(_vm.msg))
            ]),
            _vm._v(" "),
            _c("v-btn", {
              staticClass: "mb-4 w-100",
              attrs: { height: "40px", txt: _vm.btn },
              nativeOn: {
                click: function($event) {
                  _vm.msgType == "areChanges"
                    ? _vm.$emit("prevAction")
                    : _vm.$emit("clear")
                  _vm.close()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-cancel",
                on: {
                  click: function($event) {
                    _vm.close()
                    _vm.$emit("close")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("availability.btn.cancel")))]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }