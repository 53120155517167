var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-format-question-scale" }, [
    _c(
      "div",
      { staticClass: "v-table-questions pl-3 pr-3 mb-4" },
      [
        _c(
          "div",
          {
            staticClass:
              "v-fq-title row align-items-center pt-md-2 pt-3 pb-md-2 pb-3"
          },
          [
            _c("div", { staticClass: "col pl-sm-4 pl-3" }, [
              _c("div", { staticClass: "v-fq-title-txt" }, [
                _vm._v(_vm._s(_vm.$t("formats.fm2.tableT")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto pt-2 d-none d-md-block" }, [
              _c("div", { staticClass: "v-fq-title-txt text-center mb-1" }, [
                _vm._v(_vm._s(_vm.$t("formats.fm2.scaleT")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row text-center" },
                _vm._l(_vm.answers, function(item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "v-answer col-auto" },
                    [
                      _c("div", { staticClass: "v-fq-title-txt" }, [
                        _vm._v(_vm._s(i + 1))
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.questions, function(q, i) {
          return _c("v-question-scale", {
            key: `question-${q.id}`,
            class: { "bg-dark": (i + 1) % 2 == 0 },
            attrs: {
              question: q,
              error: _vm.error,
              statusFormat: _vm.statusFormat,
              answers: _vm.answers
            },
            on: { handleAnswer: _vm.handleAnswer }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }