var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "grid-users d-flex flex-wrap",
      class: {
        compact: _vm.compactGrid,
        "align-content-start": _vm.currentScreenShare
      }
    },
    [
      _vm.lesson.type == "Individual" && _vm.currentScreenShare
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pin == _vm.rosterScreenShare || _vm.pin == null,
                  expression: "pin == rosterScreenShare || pin == null"
                }
              ],
              staticClass: "pl-0 pr-0",
              class: `${_vm.colSSXL}`,
              style: { height: _vm.screenShareHeight }
            },
            [
              _c("v-room-video", {
                key: _vm.currentScreenShare.roster,
                ref: "screenShare",
                staticClass: "w-100 h-100 pl-0 pr-0",
                style: { "z-index": _vm.pin == _vm.rosterScreenShare ? 10 : 5 },
                attrs: {
                  roster: _vm.roster[_vm.currentScreenShare.roster],
                  metric: _vm.metrics[_vm.currentScreenShare.roster],
                  devMode: _vm.devMode,
                  stream: _vm.remoteVideos[_vm.currentScreenShare.roster],
                  deviceId: _vm.muteCam ? null : _vm.currentCam,
                  muteVideo: _vm.muteCam,
                  typeLesson: _vm.lesson.type,
                  activePin: _vm.pin == _vm.rosterScreenShare,
                  hideActions: _vm.hideElement
                },
                on: {
                  boundVideoElement: (isLocal, stream, videoElement) =>
                    _vm.$emit(
                      "boundVideoElement",
                      isLocal,
                      stream,
                      videoElement
                    ),
                  handleExpandUser: function($event) {
                    return _vm.$emit(
                      "handlePin",
                      _vm.pin == _vm.rosterScreenShare
                        ? null
                        : _vm.rosterScreenShare
                    )
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.statusGridVideos && _vm.pin != _vm.rosterScreenShare,
              expression: "statusGridVideos && pin != rosterScreenShare"
            }
          ],
          staticClass:
            "col-12 pl-0 pr-0 flex-wrap align-content-center justify-content-center",
          class: {
            "col-xl-4": _vm.colSSXL == "col-xl-8",
            "d-flex": _vm.statusGridVideos
          },
          attrs: { name: "videoRoom", tag: "div" }
        },
        [
          !_vm.loadingRemoteUsers && _vm.localRoster
            ? _c("v-room-video", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.pin == _vm.localRoster.roster ||
                      (!_vm.pin &&
                        (_vm.showCurrentVideo ||
                          (!_vm.showCurrentVideo &&
                            Object.values(_vm.roster).length == 1 &&
                              this.localRoster != ""))),
                    expression:
                      "pin == localRoster.roster || (!pin && (showCurrentVideo || (!showCurrentVideo && (Object.values(roster).length == 1 && this.localRoster != ''))))"
                  }
                ],
                key:
                  _vm.lesson.type == "Grupal"
                    ? "local-roster"
                    : _vm.localRoster.roster,
                ref: "localVideo",
                staticClass: "video-room pl-0 pr-0",
                class: `${_vm.colXl} ${_vm.colLg} ${_vm.colMd} ${_vm.colSm} ${_vm.col}`,
                style: {
                  "z-index": _vm.pin == _vm.localRoster.roster ? 10 : 5
                },
                attrs: {
                  height: _vm.videoHeight,
                  width: _vm.videoWidth,
                  totalUsers: _vm.totalUsers,
                  userSpeaking:
                    _vm.activeUserSpeaking == _vm.localRoster.roster,
                  activePin: _vm.pin == _vm.localRoster.roster,
                  hideActions: _vm.hideElement,
                  stream: _vm.remoteVideos[_vm.localRoster.roster],
                  roster: {
                    ..._vm.roster[_vm.localRoster.roster],
                    volume: _vm.volume
                  },
                  metric: _vm.metrics[_vm.localRoster.roster],
                  devMode: _vm.devMode,
                  user: _vm.localRoster,
                  deviceId: _vm.currentCam,
                  muteVideo: _vm.muteCam,
                  isLocal: true,
                  typeLesson: _vm.lesson.type,
                  handleVideo: true,
                  bottomName:
                    !_vm.hideElement &&
                    ((_vm.totalUsers == 1 && !_vm.currentScreenShare) ||
                      _vm.pin == _vm.localRoster.roster)
                      ? "75px"
                      : "1.2rem"
                },
                on: {
                  boundVideoElement: (isLocal, stream, videoElement) =>
                    _vm.$emit(
                      "boundVideoElement",
                      isLocal,
                      stream,
                      videoElement
                    ),
                  startLocalVideo: function($event) {
                    return _vm.$emit("startLocalVideoTile")
                  },
                  handleExpandUser: function($event) {
                    return _vm.$emit(
                      "handlePin",
                      _vm.pin == _vm.localRoster.roster
                        ? null
                        : _vm.localRoster.roster
                    )
                  },
                  changeVideo: _vm.openVideoInputFromSelection
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.fRosters, function(userGrid) {
            return _c("v-room-video", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.pin || _vm.pin == userGrid.roster,
                  expression: "!pin || pin == userGrid.roster"
                }
              ],
              key: userGrid.roster,
              staticClass: "video-room pl-0 pr-0",
              class: `${_vm.colXl} ${_vm.colLg} ${_vm.colMd} ${_vm.colSm} ${
                _vm.col
              } ${
                _vm.userType == "student"
                  ? userGrid.type == "tutor"
                    ? "order-1"
                    : "order-2"
                  : null
              }`,
              style: { "z-index": _vm.pin == userGrid.roster ? 10 : 5 },
              attrs: {
                height: _vm.videoHeight,
                width: _vm.videoWidth,
                totalUsers: _vm.totalUsers,
                userSpeaking: _vm.activeUserSpeaking == userGrid.roster,
                stream: _vm.remoteVideos[userGrid.roster],
                roster: _vm.roster[userGrid.roster],
                metric: _vm.metrics[userGrid.roster],
                devMode: _vm.devMode,
                user: userGrid,
                typeLesson: _vm.lesson.type,
                activePin: _vm.pin == userGrid.roster,
                hideActions: _vm.hideElement,
                bottomName:
                  !_vm.hideElement &&
                  ((_vm.totalUsers == 1 && !_vm.currentScreenShare) ||
                    _vm.pin == userGrid.roster)
                    ? "75px"
                    : "1.2rem"
              },
              on: {
                boundVideoElement: (isLocal, stream, videoElement) =>
                  _vm.$emit("boundVideoElement", isLocal, stream, videoElement),
                handleExpandUser: function($event) {
                  return _vm.$emit(
                    "handlePin",
                    _vm.pin == userGrid.roster ? null : userGrid.roster
                  )
                }
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }