const setUnsubscribe  = (state,band) =>{
    state.unsubscribe = band;
}

const setUserFirebase  = (state,user) =>{
    state.userFirebase = user;
}

const setFirstMsg  = (state,msg) =>{
    state.firstMsg = msg;
}

const setLastMsgVisible  = (state,msg) =>{
    state.lastMsgVisible = msg;
}

const setMessages  = (state,msgs) =>{
    state.chat = msgs;
}

const setStatusChat  = (state,band) =>{
    state.statusChat = band;
}

const setModalNotifications = (state, band) => {
    state.modalNotifications = band;
}

const setTokenNotifications = (state, token) => {
    state.tokenNotifications = token;
}

const statusFlag = (state, band) => {
    state.flag = band;
}

const setTokenBrowser = (state, token) => {
    state.tokenBrowser = token;
}

const setListenNotification = (state, token) => {
    state.listenNotification = token;
}

const setContToken = (state, cont) => {
    state.contToken = cont;
}

const handleStatusChatVideoRoom = (state, band) => {
	state.statusChatVideoRoom = band;
}

const handleActiveNotifications = (state, payload) => {
    state.notifications.activeLists = payload;
}

const handleWaitNotification = (state, band) => {
    state.notifications.wait = band;
}

const handleLessonNotification = (state, lesson) => {
    state.notifications.lesson = lesson;
}

const handleNotificationsList = (state, nof) => {
    state.notifications.list = nof;
}

const handleNotificationSeen = (state, index) => {
    state.notifications.list[index].seen = 1;
}

const handleLastestNof = (state, nof) => {
    state.notifications.lastest = nof;
}

const handleLastVisibleNof = (state, nof) => {
    state.notifications.lastVisible = nof;
}

const handleMissingNotifications = (state, total) => {
    state.notifications.missing = total<0?0:total;
}

const clearnotifications = (state) => {
    state.notifications.missing = state.notifications.total;
    state.notifications.wait = false;
    state.notifications.lesson = undefined;
    state.notifications.list = [];
    state.notifications.lastest = undefined;
    state.notifications.lastVisible = undefined;
}

export default{
	setUnsubscribe,
	setUserFirebase,
	setFirstMsg,
	setLastMsgVisible,
	setMessages,
	setStatusChat,
	setModalNotifications,
	setTokenNotifications,
	statusFlag,
	setTokenBrowser,
	setListenNotification,
	setContToken,
	handleStatusChatVideoRoom,
    handleActiveNotifications,
    handleWaitNotification,
    handleLessonNotification,
    handleNotificationsList,
    handleNotificationSeen,
    handleLastestNof,
    handleLastVisibleNof,
    handleMissingNotifications,
    clearnotifications
}