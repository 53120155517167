var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.auth.permission === _vm.$route.meta.permission &&
      !_vm.$route.meta.error &&
      !_vm.$route.meta.classRoom
        ? _c("my-header", { ref: "header" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.auth.permission === _vm.$route.meta.permission &&
            !_vm.$route.meta.error &&
            !_vm.$route.meta.classRoom &&
            !_vm.statusFormats
              ? _c("my-menu", { ref: "menu", staticClass: "col pl-0 pr-0" })
              : _vm._e(),
            _vm._v(" "),
            _vm.menu.showMenuResponsive
              ? _c("div", {
                  staticClass: "blurcolor-menu d-block d-lg-none",
                  on: {
                    click: function($event) {
                      return _vm.setStatusMenuResponsive(false)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("router-view", {
              ref: "content",
              staticClass: "col",
              class: {
                "view p-4":
                  _vm.auth.permission === _vm.$route.meta.permission &&
                  !_vm.$route.meta.classRoom &&
                  !_vm.$route.meta.error,
                compact: _vm.menu.compact
              },
              style: { marginLeft: _vm.merginLeft }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("vue-progress-bar"),
      _vm._v(" "),
      _vm.auth.permission === _vm.$route.meta.permission &&
      !_vm.$route.meta.error &&
      !_vm.$route.meta.classRoom
        ? _c("v-modal-ongoing-lesson")
        : _vm._e(),
      _vm._v(" "),
      _vm.modalVal.modal != "close" && _vm.modalVal.modal != "leaveRoom"
        ? _c(_vm.modalVal.modal, {
            key: _vm.modalVal.type || "modal",
            tag: "component"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.auth.permission === _vm.$route.meta.permission &&
      _vm.$firebase.messaging
        ? _c("v-push-notifications")
        : _vm._e(),
      _vm._v(" "),
      _c("v-notification", {
        style: {
          bottom:
            _vm.statusOngoingLesson && !_vm.$route.meta.classRoom
              ? "120px"
              : "20px"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }