
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { State, Mutation, Action } from 'vuex-class';
    import { modal } from '../../mixins/index.js';

    @Component({
		mixins: [ modal ]
	})
	export default class SystemPreferences extends Vue{
		@Prop({ type: Boolean, default: false }) readonly showModal!: boolean;
		@Prop({ type: Boolean, default: false }) readonly audioMute!: boolean;
        @Prop({ type: Boolean, default: false }) readonly statusTestSound!: boolean;
		@Prop({ type: Number, default: 0 }) readonly volume!: number;
        
        @State( state => state.auth.permission ) permission;
        @State( state => state.permissions.mic ) permissionMic
        @State( state => state.permissions.cam ) permissionCam
        @State( state => state.typePermission ) typePermission // Enum
		@State( state => state.devices.cameras ) cameras
		@State( state => state.devices.videoQuality ) videoQuality
		@State( state => state.devices.microphones ) microphones
		@State( state => state.devices.speakers ) speakers
        @State( state => state.currentMic?state.currentMic.deviceId:null ) currentMic
        @State( state => state.currentCam?state.currentCam.deviceId:null ) currentCam
		@State( 'currentVideoQuality' ) currentVideoQuality
		@State( 'currentSpk' ) currentSpk
        @State('supportsVoiceFocus') supportsVoiceFocus
        @State('enableVoiceFocus') enableVoiceFocus
		@Mutation('handleDevice') handleDevice
        @Mutation('handleVoiceFocus') handleVoiceFocus
        @Action('handleLSConfig') handleLSConfig

        contentH: string = 'initial';

		get t(){
        	return(this as any).$t;
        }
        get refs(){
        	return(this as any).$refs;
        }
        get emit(){
        	return(this as any).$emit;
        }

        get defaultOpcVideo(){
            if(this.cameras == 0)
               return this.$t('room.camera.alerts.empty.issue');
            else if(this.permissionCam==this.typePermission.denied)
               return this.$t('room.camera.alerts.denied.issue');
            else
               return '';
        }

        get defaultOpcAudioInput(){
            if(this.microphones == 0)
               return this.$t('room.microphone.alerts.empty');
            else if(this.permissionMic==this.typePermission.denied)
               return this.$t('room.microphone.alerts.denied.issue');
            else
               return '';
        }

        get defaultOpcAudioOutput(){
            if(this.speakers == 0)
               return this.$t('room.audioOuput.alerts.empty');
            else if(this.permissionMic==this.typePermission.denied)
               return this.$t('room.audioOuput.alerts.denied');
            else
               return '';
        }

        /*get msgVoiceFocus(){
            if (this.permissionMic == this.typePermission.denied || this.permissionMic == this.typePermission.errorSystem || this.permissionMic == this.typePermission.deviceEmpty)
                return this.t('room.voiceFocus.alert')
            else if(this.enableVoiceFocus)
                return this.t('room.voiceFocus.block')
            else
                return this.t('room.voiceFocus.active')
        }*/

        get enebledVF(){
            return !(!this.supportsVoiceFocus || this.permissionMic == this.typePermission.denied || this.permissionMic == this.typePermission.errorSystem || this.permissionMic == this.typePermission.deviceEmpty);
        }

        mounted(){
            this.getHeight();
            window.addEventListener("resize", this.getHeight);
        }

        beforeDestroy(){
            window.removeEventListener("resize", this.getHeight);
        }

        afterClose(){
        	this.emit('close');
        }

        getHeight(): void{
            this.contentH = 'initial';
            let windowH = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
                windowH = (windowH/10) * 9;
            const titleH = this.refs.title.clientHeight,
                  contentH = this.refs.content.clientHeight;

            if ((titleH + contentH) >= windowH)
                this.contentH = `${Math.ceil(windowH - titleH)}px`;
        }

        changeDevice(type: string, device: any): void{
            if (type == 'cam'){
                this.handleDevice({ type: 'cam', device: this.cameras.find( item => item.deviceId == device ) });
                this.handleLSConfig({ device: 'camera', data: 'id', val: device });
            }
            else if(type == 'VQ'){
                this.handleDevice({ type: 'VQ', deviceId: device});
                this.handleLSConfig({ device: 'camera', data: 'quality', val: device });
            }
            else if(type == 'mic'){
               this.handleDevice({ type: 'mic', device: this.microphones.find( item => item.deviceId == device) });
               this.handleLSConfig({ device: 'microphone', data: 'id', val: device });
            }
            else if(type == 'AVF'){
                this.handleVoiceFocus(device);
                this.handleLSConfig({ device: 'microphone', data: 'AVF', val: device });
            }
            else if(type == 'spk'){
                this.handleDevice({ type: 'spk', deviceId: device });
                this.handleLSConfig({ device: 'audioOutput', data: 'id', val: device });
            }  
        }
	}
