
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { State, Getter, Mutation, Action } from 'vuex-class'; 

    @Component
	export default class Notifications extends Vue{
		@State( state => state.auth.api ) api;
		@State( state => state.auth.token ) token;
		@State( state => state.i18n.locale) lang; 
		@State( state => state.auth.permission) userType; 
		@State( state => state.notifications.lesson ) lessonNof;
		@State('lessonChanges') lessonChanges;
		@State('grid') grid;
		@State( state => state.menu.compact ) statusMenu;
		@Getter('events') events;
		@Mutation('handleNotificationsList') handleNotifications;
		@Mutation('handleLessonNotification') handleLessonId;
		@Action('axiosRequest') axiosRequest;
		

        lesson: any = null;
        loading: boolean = false;
        tagHistory: any;

        get t(): any{
        	return (this as any).$t;
        }

        get moment(): any{
        	return (this as any).$moment;
        }

        get refs(): any{
        	return (this as any).$refs;
        }

        get btnJoin(): boolean{
            if(this.lesson){
                const { status, lessonStatus } = this.lesson;
                if(this.lesson.modality == 'Online' && this.lesson.status == 'Activa'){
                    if(this.userType == 'student')
                        return lessonStatus == 'OS';
                    else
                        return lessonStatus == 'PA';
                }
                else
                    return false;
            }
            else
                return false;
        }

        get btnStop(): boolean{
            if(this.lesson){
                const { status, lessonStatus } = this.lesson;
                return (this.userType == 'tutor' && status == 'Activa' && (lessonStatus == 'PA' || lessonStatus == 'RO'));
            }
            else
                return false;
        }

        get btnCancel(): boolean{
            if(this.lesson){
                const { status, lessonStatus } = this.lesson;
                if(this.userType == 'student' && status == 'Confirmada')
                    return (lessonStatus == 'US' || lessonStatus == 'RU');
                else
                    return ((status == 'Pendiente' && lessonStatus == 'PN') || (status == 'Confirmada' && (lessonStatus == 'RU' || lessonStatus == 'RT')));
            }
            else
                return false;
        }

        get btnConfirm(): boolean{
            if(this.lesson){
                const { status, lessonStatus } = this.lesson;
                return (this.userType == 'tutor' && status == 'Pendiente' && lessonStatus == 'PN');
            }
            else
                return false;
        }

        get btnStart(): boolean{
            if(this.lesson){
                const { status, lessonStatus } = this.lesson;
                return (status == 'Confirmada' && this.userType == 'tutor' && (lessonStatus == 'PR' || lessonStatus == 'RU' || lessonStatus == 'RT'));
            }
            else
                return false;
        }

        get dateTime(): string{
        	if(!this.lesson)
        		return '';

        	let d = this.moment(this.lesson.time).locale(this.lang).calendar(this.moment({h:0, m:0, s:0, ms:0}),{ 
			    sameDay: `[${this.t('today')}]`,
			    nextDay: `[${this.t('tomorrow')}]`,
			    lastDay: `[${this.t('yesterday')}]`,
			    nextWeek: 'dddd', // Monday, Tuesday ....
			    lastWeek: this.t('lessonFormatDate'), // Last Monday, Last Tuesday ....
			    sameElse: this.t('lessonFormatDate') // Monday 9th Aug 21
		    })

		    d = d.charAt(0).toUpperCase() + d.slice(1);

            return `${this.formatTime(this.lesson.time)} (${d} - ${this.moment(this.lesson.time).locale('en').format('LT')})`;
        }

        get statusList(): boolean{
        	if (this.grid == 'xl' || this.grid == 'lg' && this.statusMenu)
        		return true;
        	else if(this.grid == 'lg' && !this.statusMenu && !this.lesson)
        		return true;
        	else if((this.grid == 'md' || this.grid == 'sm' || this.grid == 'xs') && !this.lesson)
        		return true;
        	else
        		return false;
        }

        get statusLessonInfo(): boolean{
        	if (this.grid == 'xl' || this.grid == 'lg' && this.statusMenu)
        		return true;
        	else if(this.grid == 'lg' && !this.statusMenu && this.lesson)
        		return true;
        	else if((this.grid == 'md' || this.grid == 'sm' || this.grid == 'xs') && this.lesson)
        		return true;
        	else
        		return false;
        }

        get typeMsg(): string{
        	if(this.lesson.lessonStatus == 'CC' || this.lesson.lessonStatus == 'AP' || this.lesson.lessonStatus == 'AU' || this.lesson.lessonStatus == 'RU')
        	    return this.t(`notifications.type.${this.lesson.lessonStatus}.${this.userType}.dHtml`, { subject: this.lesson.subject/*, time: '1 hr'*/ });
            else
            	return this.t(`notifications.type.${this.lesson.lessonStatus}.dHtml`, { subject: this.lesson.subject/*, time: '1 hr'*/ });
            	
        }

        get statusLesson(): any{
        	if (this.lesson.status == 'Pendiente')
        		return { color: 'bg-lesson-pending', t: this.t('notifications.status.pending.t'), tooltip: this.t(`notifications.status.pending.tooltip.${this.userType == 'student'?'s':'t'}`) };
        	else if(this.lesson.status == 'Confirmada')
        		return { color: 'bg-lesson-upcoming', t: this.t('notifications.status.upcoming.t'), tooltip: this.t(`notifications.status.upcoming.tooltip.${this.userType == 'student'?'s':'t'}`) };
        	else if(this.lesson.status == 'Activa')
        		return { color: 'bg-lesson-ongoing', t: this.t('notifications.status.ongoing.t'), tooltip: this.t(`notifications.status.ongoing.tooltip.${this.userType == 'student'?'s':'t'}`) };
        	else if(this.lesson.status == 'Cancelada')
        		return { color: 'bg-lesson-canceled', t: this.t('notifications.status.canceled.t'), tooltip: this.t(`notifications.status.canceled.tooltip.${this.userType == 'student'?'s':'t'}`) };
        	else if(this.lesson.status == 'Finalizada')
        		return { color: 'bg-lesson-finished', t: this.t('notifications.status.finished.t'), tooltip: this.t(`notifications.status.finished.tooltip.${this.userType == 'student'?'s':'t'}`) };
        	else
            	return {};
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type, size: '80px' };
        }

        get name(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get fullDate(): string{
        	const date = this.moment.utc(this.lesson.scheduledDate).locale(this.lang).format(this.t('formatDateC')),
        	      time = `${this.moment(this.lesson.scheduledTime, 'HH:mm:ss').format('LT')} - ${this.moment(this.lesson.endScheduledTime, 'HH:mm:ss').format('LT')}`
        	return `${date.charAt(0).toUpperCase()+date.slice(1)}  ${time}`;
        }

		get statusTopic(): boolean{
            if (this.userType == 'student' && this.lesson.type == 'Individual') {
                if ((this.lesson.status == 'Activa' || this.lesson.status == 'Cancelada' || this.lesson.status == 'Finalizada') && this.lesson.topic)
                    return true;
                else if(this.lesson.status == 'Pendiente' || this.lesson.status == 'Confirmada')
                    return true;
                else
                    return false;

            }else if (this.userType == 'tutor' && this.lesson.topic)
                return true;
            else
                return false;
        }

        created(): void{
            if (this.lessonNof)
            	this.getLesson(this.lessonNof);

            this.$watch('events', val => {
                const lessonLS = JSON.parse((this as any).$localStorage.get('lesson'));
                if (val && lessonLS.lesson && this.lesson){
                    const lesson = val.split('_');
                    if (lesson[0] == this.lesson.id+''){
                    	if (lesson[1] == 'canceled')
                    		this.lesson.status = 'Cancelada';
                    	else if (lesson[1] == 'upcoming')
                    		this.lesson.status = 'Confirmada';
                    	else if (lesson[1] == 'ongoing')
                    		this.lesson.status = 'Activa';
                    	else if (lesson[1] == 'pendingFeedback' || lesson[1] ==  'completed')
                    		this.lesson.status = 'Finalizada';
                    	else if (lesson[1] == 'changedTopic')
                    		this.getLesson(this.lessonNof, 'topic');
                    	else if (lesson[1] == 'changedPlace')
                    		this.getLesson(this.lessonNof, 'place');
                    }
                }
            });
        }

        beforeDestroy(): void{
        	if(this.tagHistory){
        	    this.tagHistory.removeEventListener('click', this.historyEventListener);
        	    this.tagHistory = undefined;
            }
            this.handleNotifications([]);
            this.handleLessonId(undefined);
        }

        getLesson(n, event: undefined | string = undefined): void{ 
        	if (!event) {
        	    this.loading = true;
        	    this.lesson = null;
        	    this.lesson = { ...this.lesson, ...n };
            }

            if(this.tagHistory){
        	    this.tagHistory.removeEventListener('click', this.historyEventListener);
        	    this.tagHistory = undefined;
            }

        	this.axiosRequest({ config : {
                method: 'Get',
                url: `${this.api}/lessons/${n.id}/notifications/${n.key}`,
                headers: { authorization : this.token }
            }}).then( resp => resp.data.data)
            .then( data => {
            	if (!event)
            	    this.lesson = { ...data.lesson, body: data.notification.body, lessonStatus: n.key.split('_')[1], time: n.time };
            	else if (event == 'topic')
            		this.lesson.topic = data.lesson.topic;
            	else if (event == 'place')
            		this.lesson.place = data.lesson.place;
            	this.loading = false;
            }).catch( e => { this.loading = false; });
        }

        formatTime(time): any{
            const localTime = this.moment(),
                  localTimeWithoutTime = this.moment({h:0, m:0, s:0, ms:0}),
        	      fbTime = this.moment(time),
        	      diffInSeconds =  localTime.diff(fbTime, 'seconds'),
        	      minutes = Math.trunc(diffInSeconds / 60),
        	      hours = Math.trunc(minutes / 60),
                  days =  Math.ceil(((localTimeWithoutTime.diff(fbTime, 'seconds') / 60) / 60) / 24),
                  weeks = Math.trunc(days / 7),
                  months = localTimeWithoutTime.diff(fbTime, 'months'),
                  years = Math.trunc(months / 12);
            
            if (diffInSeconds < 60) // 2 to 59 seconds
            	return this.t('notifications.time.recent');
            else if(minutes == 1) // One minute 
                return this.t('notifications.time.minuteAgo');
            else if(minutes < 60) // 2 to 59 minutes
            	return this.t('notifications.time.minutesAgo', { time: minutes });
            else if(hours == 1) // One hour
                return this.t('notifications.time.hourAgo');
            else if(hours < 24) // 2 to 23 hours
            	return this.t('notifications.time.hoursAgo', { time: hours });
            else if(days == 1) // One day
                return this.t('notifications.time.dayAgo');
            else if(days < 7) // 2 to 6 days
                return this.t('notifications.time.daysAgo', { time: days });
            else if(weeks == 1) // One week
                return this.t('notifications.time.weekAgo');
            else if(months == 0) // 2 to 4 weeks
               return this.t('notifications.time.weeksAgo', { time: weeks });
            else if(months == 1) // One month
                return this.t('notifications.time.monthAgo');
            else if(months < 12) // 2 to 11 months
               return this.t('notifications.time.monthsAgo', { time: months });
            else if(years == 1) // One year
                return this.t('notifications.time.yearAgo');
            else if(years > 1 ) // More than two years
                return this.t('notifications.time.yearsAgo', { time: years });
        }

        enter(): void{
        	const tag = this.refs.info;
        	if(tag)
        		this.tagHistory = tag.querySelector('a');

            if(this.tagHistory)
        	    this.tagHistory.addEventListener('click', this.historyEventListener);
        }

        historyEventListener(): void{
            (this as any).$router.push({ name: `history-${this.userType}` });
        }

        @Watch('lessonNof')
        handleLesson(newVal: any): void{
            if (newVal)
            	this.getLesson(newVal);
        }

        @Watch('lessonChanges')
        handleLesson2(newVal: any): void{
        	if (newVal && this.lesson) {
        		const { data, lesson } = newVal;
        		if (lesson.id+'' == this.lesson.id+''){
                    this.lesson[data] = lesson.val;
                }
            }
        }
	}
