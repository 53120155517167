
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class lessonSchedule extends Vue{
		// Required { date, startTime, endTime }, opcional { topic, spt }
        @Prop({ default: () => { return {} } }) readonly options!: any
        
        @State( state => state.i18n.locale.split('_')[0] ) lang;

        get moment(): any{
        	return (this as any).$moment;
        }

        get t(): any{
        	return (this as any).$t;
        }
  
        get date(): string{
            return this.moment.utc(this.options.date).locale(this.lang).calendar(this.moment({h:0, m:0, s:0, ms:0}),{ 
			    sameDay: `[${this.t('today')}]`,
			    nextDay: `[${this.t('tomorrow')}]`,
			    lastDay: `[${this.t('yesterday')}]`,
			    nextWeek: 'dddd', // Monday, Tuesday ....
			    lastWeek: this.t('lessonFormatDate'), // Last Monday, Last Tuesday ....
			    sameElse: this.t('lessonFormatDate') // Monday 9th Aug 21
		    });
        }

        get time(): string{
        	const { startTime, endTime } = this.options;
            return `${this.moment(startTime,'hh:mm:ss').locale('en').format('LT')} - ${this.moment(endTime,'hh:mm:ss').locale('en').format('LT')} `;
        }
	}
