
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State, Mutation, Action } from 'vuex-class';

    @Component
    export default class formatLearningInventory extends Vue {
        @Prop({ default: null, required: false }) readonly studentFormats: any;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.formats.assertiveness ) statusAssertiveness;

        @Mutation('handleFormat') handleFormat;
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;

        sending: boolean = false;
        imReady: boolean = false;
        qst: any = null;
        qstVal: any = null;
        error: boolean = false;
        createdDate: any = null;
        user: any = null;
        score: string = '';
        pdfData: any = {};

        get moment(): any{
            return (this as any).$moment;
        }

        get statusFormat(): boolean{
            const st = this.studentFormats?.forms;

            return st?st.assertiveness:this.statusAssertiveness;
        }

        get answers(): Array<object>{
            return [
                { val:1, t: this.$t('formats.fm5.qstT.q1') },
                { val:2, t: this.$t('formats.fm5.qstT.q2') },
                { val:3, t: this.$t('formats.fm5.qstT.q3') },
                { val:4, t: this.$t('formats.fm5.qstT.q4') }
            ]
        }

        created(): void {
            this.getFormat();
        }

        getFormat(): void{
            const params = this.studentFormats?.studentId?{ params: { studentId: this.studentFormats?.studentId } }:{};

            this.axios({ config: {
                method: 'GET',
                url: `${this.api}/tecnm/forms/assertiveness`,
                headers: { Authorization: this.token },
                ...params
            } }).then( resp => resp.data )
                .then( data => {
                    this.qst = data.assertivenessQuestions;

                    if(this.statusFormat && data.created){
                        const formatDate = this.moment(data.created).locale(this.lang).format(this.$t('formatDateC'));
                        this.createdDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
                    }
                    
                    if(this.statusFormat && data.user)
                        this.user = data.user;

                    if(this.statusFormat && data.score)
                        this.score = data.score;
                    
                    if(this.statusFormat){
                        this.pdfData = { 
                            createdDate: this.createdDate, 
                            ...this.user, 
                            answers: this.answers, 
                            q: this.qst, 
                            score: this.score 
                        }
                    }

                    this.imReady = true;
            }).catch( err => {
                this.imReady = true;
                console.log(err);
            } )
        }

        getScore(): string{
            let val1 = 0,
                val2 = 0;
            
            this.qstVal.questions.forEach( item => {
                if(item.answer == 1 || item.answer == 2)
                   val1 += 1;
                else if(item.answer == 3 || item.answer == 4)
                   val2 += 1;
            } );

            return val1 > val2?'Tu conducta es aceptable':'Plantea cambiar tu conducta si no quieres ver lesionados tus derechos';
        }

        sendFormat(): void{
            if(!this.qstVal?.status){
                this.error = true;
                this.notification({ type: 'warning', msg: 'Lorem ipsum dolor sit amet consectetur adipiscing elit congue natoque justo nec ac.' });
                return;
            }

            if(!this.sending){
                this.error = false;
                this.sending = true;

                this.axios({ config: {
                    url: `${this.api}/tecnm/forms/assertiveness`,
                    method: 'POST',
                    headers: { Authorization: this.token },
                    data: {
                        assertivenessQuestions: this.qstVal.questions,
                        score: this.getScore()
                    }
                } }).then( resp => resp.data )
                    .then( data => {
                    this.handleFormat({ format: 'assertiveness', status: 1 });
                    this.sending = false;
                    this.notification({ type: 'success', msg: data.msg });
                    this.$emit('openMenu');
                }).catch( err => {
                    this.sending = false;
                    console.log(err);
                } )
            }
        }

        print(): void{
            (this as any).$refs.pdf.print();
        }
    }
