<template>
	<div class="schedule-by-day">

		<div class="row mb-3" v-if="available">
			<div class="content-t col col-sm-6 pr-2 pr-sm-3">
				<span class="t-time">{{ $t('availability.modals.availability.from') }}</span>
			</div>
			<div class="content-t col col-sm-6 pl-2 pl-sm-3">
				<span class="t-time">{{ $t('availability.modals.availability.to') }}</span>
			</div>
		</div>

        <!-- ============== Schedule =============== -->
        <transition name="bound" mode="out-in">
        	<div class="imUnavailable d-flex justify-content-center align-items-center mb-3 mr-n5 mr-sm-n1" key="unavailable" v-if="!available">
        		<span>{{ $t('availability.modals.availability.unavailable') }}</span>
        	</div>
        	<transition-group class="mb-3" key="ranges" name="bound" tag="div" v-else>
        		<div class="row align-items-center mb-4 position-relative" :key="sch.id" v-for="(sch,i) in schedule">
        			<v-range-time class="col w-100" :active="!available || !statusSch || disabledDisponibility" :endTime="endTime" :minRangetime="minRangetime" :options="times" label="title" v-model="schedule[i]" :next="getNext(schedule[i])" :prev="getPrev(schedule[i])" :activeTimes="activeTimes" @updateTimes="updateTimes" />
        			<div class="content-btn-delete col pl-0 pr-0 text-left">
        				<svgicon class="btn-delete-availability" :class="{ disabled: !available || disabledDisponibility }" name="clear" @click="removeAvailability(sch,getNext(schedule[i]),getPrev(schedule[i]))"/>
        			</div>
        		</div>
        	</transition-group>
		</transition>

        <!-- ============== Schedule Actions =============== -->
		<div class="row mb-4 align-items-center mr-n5 mr-md-3">
			<div class="col-12 col-sm-6 pr-0">
				<button class="btn-add-block" :class="{ disabled: disabledDisponibility }" @click="addAvailability()"> <svgicon class="icon" name="plus-circle" />{{ $t('availability.modals.availability.btnAdd') }}</button>
			</div>
			<div class="col-12 col-sm-6 d-flex align-items-center justify-content-end">
				<div class="v-checkbox v-green mr-1 position-relative" :class="{ active: !available, disabled: disabledDisponibility }" @click="$emit('changeAvailable', !available)">
					<input type="checkbox" :disabled="disabledDisponibility">
				</div>
				<label class="txt-check mb-0">{{ $t('availability.modals.availability.btnUnavailable') }}</label>
			</div>
		</div>

		<!-- ============== Apply or cancel changes =============== -->
		<div class="d-flex flex-wrap justify-content-center mr-n5 mr-md-0">
			<v-btn class="mb-2 w-100" :txt="$t('availability.modals.availability.btnApplySpecificDay', { date: currentDate.locale(i18n.locale).format('D MMM') })" height="40px" @click.native="$emit('applyToSpecificDays', [currentDate.format('YYYY-MM-DD')])" :disabled="disabledBtns || disabledDisponibility" v-if="currentDate"/>
		    <v-btn class="mb-2 w-100" :txt="$t('availability.modals.availability.btnApplyToAll', { day: currentDate.locale(i18n.locale).format('dddd') })" height="40px" @click.native="$emit('applyToAll', getDay())" :disabled="disabledBtns || disabledDisponibility" v-if="currentDate"/>
		    <v-btn class="mb-3 w-100" btn="confirm" :txt="$t('availability.modals.availability.btnApplyToMultiple')" height="40px" @click.native="$emit('applyToMultiple')" v-if="currentDate"/>
		    <v-btn class="mb-3 w-100" :txt="$t('availability.modals.availability.btnApply')" height="40px" :disabled="disabledBtns || disabledDisponibility" @click.native="$emit('applyToAll')" v-if="!currentDate"/>
		    <button class="btn-cancel" @click="$emit('close')">{{ $t('availability.btn.cancel') }}</button>
		</div>
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	export default{
		props: {
			currentDate: { default: null, required: false },
			initTime: { type: String, required: true },
			endTime: { type: String, required: true },
			minRangetime: { type: Number, default: 30, required: false },
			isActive: false,
			disabledBtns: { type: Boolean, default: false, required: false },
			activeBtnClear: { type: Boolean, default: false, required: false },
			setTimes: { default: null, required: false },
			cont: { type: Number, default: 0, required: false },
			available: { default: true, required: false },
			disabledDisponibility: { default: false, required: false },
			schedulesRangesBlock: { type: Array, default: () => [], required: false }, //10:00, 13:30
			value: null
		},
		data(){
            return{
            	times: [],
            	schedule: [],
            	statusSch: true,
            	//stopGetTimes: false
            }
		},
		computed:{
			...mapState(['auth','i18n']),
			currentSchedulesInOrder(){ // Schedule in order
                return [...this.schedule, ...this.schedulesRangesBlock].sort( (a,b) => {
                	const timeA = this.$moment(a.from,'HH:mm');
                	const timeB = this.$moment(b.from,'HH:mm');
                	if (timeA.isBefore(timeB))
                		return -1;
                	if (timeA.isAfter(timeB))
                		return 1;
                	return 0;
                } );
			},
			activeTimes(){
				let aux = '',
				    arr = new Map();
				this.currentSchedulesInOrder.forEach( item => {
					arr.set(item.to, item.to);
					if (item.from === aux)
						arr.delete(aux)
					else if(item.to === this.endTime)
						arr.delete(this.endTime)
				    aux = item.to;
				});
				return arr;
			}
		},
		created(){
			if (!this.setTimes)
                this.getTimes();
            else
            	this.times = this.setTimes;

            if (this.value)
            	this.schedule = this.value;
		},
		methods:{
			...mapActions(['axiosRequest']),
			getTimes(){
                let time = this.$moment(this.initTime,'HH:mm'),
                    endTime = this.$moment(this.endTime,'HH:mm'),
                    times = [];
                while(time.isSameOrBefore(endTime)){
                	let isBlock = false,
                	    full = false;

                    for (var t of this.schedulesRangesBlock) {
                    	const from = this.$moment(t.from, 'HH:mm'),
                    	      to = this.$moment(t.to, 'HH:mm');
                    	if (time.isSame(from) || time.isSame(to))
                    		full = true;
                        
                    	if ((time.isAfter(from) && time.isBefore(to)) || (time.isSame(from) && time.isSame(this.$moment(this.initTime, 'HH:mm'))) || (time.isSame(to) && time.isSame(this.$moment(this.endTime, 'HH:mm')))){
                    		isBlock = true;
                    		break;
                    	}
                    }

                	if (!isBlock)
                        times = [...times,  !full?{ time: time.format('HH:mm'), format: time.format('LT') }:{ time: time.format('HH:mm'), format: time.format('LT'), full: true }];
                    time.add(this.minRangetime, 'minutes');
                }

                this.times = times;
                this.$emit('getTimes', times);
			},
			setFullTimes(sch){ // Add full val to array times from schedules busy
                let times = [...this.times];
                if (sch.length > 0){
                    let auxTime = 0;
                        sch.forEach( (item,index) => {
                            const schFrom = this.$moment(item.from, 'HH:mm'),
                            schTo = this.$moment(item.to, 'HH:mm'),
                            lastSch = sch.length-1===index;
                            this.schedule.push({ ...sch[index], id: 'time-'+index });
                            this.$emit('addCont');
                            for (var i = auxTime; i < times.length; i++) {
                                const time = this.$moment(times[i].time, 'HH:mm');
                                if (time.isAfter(schTo)) break;
                                else if(time.isSameOrAfter(schFrom) && time.isSameOrBefore(schTo)){
                                    times[i] = { ...times[i], full : true };
                                    if(lastSch && time.isSame(schTo))
                                        break
                                }
                                auxTime = i+1;
                            }
                           
                        });
                    this.times = times;  
                    this.$emit('input', this.schedule); 
                    this.$emit('getTimes', times); 
                } 
            },
			addAvailability(){ // Add a schedule in a availability time
				let t = [...this.times];
				const endTime = this.$moment(t[t.length-1].time,'HH:mm');
				if (this.schedule.length < 10 && !this.disabledDisponibility){
					let auxTimes = 0,
					    statusAdd = false;
					const schLength = this.currentSchedulesInOrder.length;
					if (schLength - this.schedulesRangesBlock.length > 0)    
						for (let i = 0; i < schLength; i++) {
								const schFrom = this.$moment(this.currentSchedulesInOrder[i].from, 'HH:mm'),
								schTo = this.$moment(this.currentSchedulesInOrder[i].to, 'HH:mm');
								if (!statusAdd /*&& !schTo.isSame(endTime)*/)
									for (let j = auxTimes; j < t.length-1; j++) {
										const time = this.$moment(t[j].time, 'HH:mm');
										if (time.isAfter(schTo)) break;
										else if (time.isBefore(schFrom) || (schLength-1 === i && time.isSame(schTo))) {
											t[j] = { ...t[j], full : true };
											t[j+1] = { ...t[j+1], full : true };
											this.schedule.push({ from: t[j].time, to: t[j+1].time, id: 'time-'+this.cont });
											this.$emit('addCont');
											statusAdd = true;
											break;
										}
										auxTimes = j;
									}
								else
									break;
						}
					else{
					 t[0] = { ...t[0], full : true };
					 t[1] = { ...t[1], full : true };
						this.schedule.push({ from: t[0].time, to: t[1].time, id: 'time-'+this.cont });
						this.$emit('addCont');
						
					}
				}
				if (!this.available)
                    this.$emit('changeAvailable', true);

				this.updateTimes(t);
				this.$emit('input', this.schedule); 
			},
			updateTimes(times){
                this.times = times;
                this.$emit('getTimes', times); 
			},
			removeAvailability(sch,next,prev){ // Remove a schedule
				if (this.available && !this.disabledDisponibility){
					let t = [...this.times];
					const schFrom = this.$moment(sch.from, 'HH:mm'),
					schTo = this.$moment(sch.to, 'HH:mm'); 
					this.schedule = this.schedule.filter( item => item.from != sch.from && item.to != sch.to );          
					for (let i = 0; i < t.length; i++) {
						const time = this.$moment(t[i].time, 'HH:mm');
						if (time.isSameOrAfter(schFrom) && time.isSameOrBefore(schTo) && t[i].time !== next.from && t[i].time !== prev.to)
							t[i] = { time: t[i].time, format : t[i].format };
					}
					if (this.schedule.length == 0)
						this.$emit('changeAvailable', false);
					this.updateTimes(t);
					this.$emit('input', this.schedule); 
                }
			},
			getNext(time){ // Next Schedule
				return this.currentSchedulesInOrder.find( sch => this.$moment(sch.from, 'HH:mm').isAfter(this.$moment(time.from, 'HH:mm')) ) || '';
			},
			getPrev(time){ // Prev Schedule
				return [...this.currentSchedulesInOrder].reverse().find( sch => this.$moment(sch.from, 'HH:mm').isBefore(this.$moment(time.from, 'HH:mm')) ) || '';
			},
			getDay(){
                let day = this.currentDate.locale('es').format('dddd').replace('é','e').replace('á','a');
                day = day.charAt(0).toUpperCase() + day.slice(1);
                return [day];
			}
		},
		watch: {
			value: function(newVal, oldVal){
				if (this.schedule.length === 0) { 
					this.setFullTimes([...newVal]);
                }
			},
			schedulesRangesBlock: function(newVal, oldVal){

			}
		}
	}
</script>