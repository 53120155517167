<template>
	<div class="v-modal" :class="{ active: status }" v-if="show">
		<!-- ============ Background color ============ -->
		<div class="v-blurColor" @click="!schChange?close():statusMsg=true"></div>

		<!-- =============== Modal ============== -->
		<div class="v-modal-paper modal-availability d-flex flex-wrap justify-content-center" ref="modal" :style="{ paddingRight: paddingRight }">

            <div class="msg-changes d-flex align-items-center" v-if="statusMsg">
                <div class="content-cgh pt-4 pb-4">
                    <h4 class="cgh-title w-100 pl-4 pr-4 mb-5 text-center">{{ $t('availability.modals.availability.msgChanges') }}</h4>
                    <div class="w-100 d-flex align-items-end">
                        <div class="col-6 pl-5">
                            <v-btn class="w-100" :txt="$t('availability.modals.availability.btnYes')" @click.native="close()" /> 
                        </div>
                        <div class="col-6 pr-5 align-self-end">
                            <v-btn class="w-100" btn="confirm" :txt="$t('availability.modals.availability.btnNo')" @click.native="statusMsg = false" /> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="msg-changes d-flex align-items-center" v-if="statusMsgCustom">
                <div class="content-cgh pt-4 pb-4">
                    <h4 class="cgh-title w-100 pl-4 pr-4 mb-5 text-center">{{ $t('availability.modals.availability.tCustom') }}</h4>
                    <div class="w-100 d-flex flex-wrap align-items-end">
                        <div class="col-12 d-flex flex-wrap justify-content-center text-center" v-html="$t('availability.modals.availability.msgCustomHtml', { totalDates: datasModalMsgCustom.invalidDates, dates: datasModalMsgCustom.txtDays })"></div>
                        <div class="col-6 pl-5">
                            <v-btn class="w-100" :txt="$t('availability.modals.availability.btnDontOverride')" @click.native="applyToAll(datasModalMsgCustom.days)" /> 
                        </div>
                        <div class="col-6 pr-5">
                            <v-btn class="w-100" btn="confirm" :txt="$t('availability.modals.availability.btnOverride')" @click.native="applyToAll(datasModalMsgCustom.days, datasModalMsgCustom.customSch, datasModalMsgCustom.daysNumber)"/> 
                        </div>
                    </div>
                </div>
            </div>
			
			<!-- ============= Title ============ -->
            <transition name="bound" mode="out-in">
                <div class="row w-100 ml-0 mr-0 mb-4 position-relative" :key="view" ref="title" :class="view != 'multiple'?'pr-sm-5':''">
                   <div class="btn-prev" :class="{ active: view === 'multiple' }">
                      <svgicon class="icon-prev" name="chevron-left" @click="changeView('availability', '1.5rem')" />
                  </div>
                  <div class="col pl-4 pr-4 text-center">
                      <h3 class="title mb-1 w-100">{{ title }}</h3>
                      <h4 class="subtitle mb-0 w-100">{{ $t('availability.modals.availability.d') }}</h4>
                  </div>
              </div>
            </transition>

            <transition name="bound" mode="out-in">

                <!-- ============ Edit Availability ============ -->
                <perfect-scrollbar class="w-100 pr-3 pr-sm-5" ref="availability" key="availability" :options="options" tag="div" :style="{ maxHeight: `${availabilityHeight}px` }" v-if="view === 'availability'">
                    <v-sch-by-day class="mr-5 mr-md-0" :currentDate="datas.date" :initTime="initTime" :available="available" :disabledDisponibility="disabledDisponibility" :endTime="endTime" :minRangetime="minRangetime" @close="!schChange?close():statusMsg=true" :setTimes="times" :cont="cont" @addCont="cont++" @changeAvailable=" av => available=av" @getTimes="t => times=t" :disabledBtns="sending" v-model="schedule" @applyToSpecificDays="applyToSpecificDays" @applyToAll="updateGeneralSch" @applyToMultiple="changeView('multiple', '3rem')"/>
                </perfect-scrollbar>
                
                <!-- ============ Edit multiple days ============ -->
                <perfect-scrollbar class="w-100 pr-3" :options="options" ref="multiple" key="multiple" tag="div" :style="{ maxHeight: `${availabilityHeight}px`, overflow: availabilityHeight>=456?'initial !important':'' }" v-else-if="view === 'multiple'">
                    <v-apply-to-multiple @close="!schChange?close():statusMsg=true" @applyToAll="updateGeneralSch" @applyToSpecificDays="applyToSpecificDays" :disabledDisponibility="disabledDisponibility" :selectedDay="datas.date" :disabledBtn="sending" />
                </perfect-scrollbar>
            </transition>
		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		props:{
			show: { type: Boolean, default: false, required: true },
            datas: { type: Object, required: true },
			initTime: { type: String, default: '06:00', required: false },
			endTime: { type: String, default: '21:00', required: false },
            minRangetime: { type: Number, default: 30, required: false },
            disabledDisponibility: { default: false, required: false },
            value: ''
		},
		data(){
			return{
				status: false,
                available: true,
                view: 'availability', // availability, multiple
                paddingRight: '1.5rem', // 1.5, 3
                availabilityHeight: '100%',
                options: {
                    suppressScrollX: true
                },
                statusMsg: false,
                statusMsgCustom: false,
                datasModalMsgCustom: {
                    days: [],
                    daysNumber: [],
                    customSch: [],
                    invalidDates: 0,
                    txtDays: ''
                },
                times: null,
                schedule: null,
                sending: false,
                cont: 0
			}
		},
		computed:{
            ...mapState(['auth', 'i18n']),
            title(){
                if (this.view === 'availability')
                	return this.$t('availability.modals.availability.t1');
                else if(this.view === 'multiple')
                	return this.$t('availability.modals.availability.t2');
            },
            schChange(){ // True is something change
                let change = false;
                if (this.schedule){
                    if (this.schedule.length != this.datas.ranges.length)
                        change = true;
                    else
                        for (var i = 0; i < this.schedule.length; i++) {
                           const sch = this.datas.ranges.find( item => item.from === this.schedule[i].from && item.to === this.schedule[i].to );
                            if (!sch){
                                change = true;
                                break;
                            }
                        }
                }
                
                /*if(+this.available !== +this.datas.isAvailable)
                    change = true;*/

                return change;
            }
		},
		methods:{
            ...mapMutations(['setNotification']),
            ...mapActions(['axiosRequest']),
			onKeyPress(e){
                if ('key' in e){
                	if (e.key === 'Escape' || e.key === 'Esc'){
                        if (!this.schChange)
                		    this.close();
                        else
                            this.statusMsg=true;
                    }
                }
                else if(e.keyCode === 27){
                	if (!this.schChange)
                        this.close();
                    else
                        this.statusMsg=true;
                }
			},
			close(){
                this.status = false;
                document.removeEventListener('keydown', this.onKeyPress);
                window.removeEventListener('resize', this.getContentHeigth);
                setTimeout( () => {
                    this.$emit('closeAvailability');
                    this.view = 'availability';
                    this.schedule = null;
                    this.times = null;
                    this.cont = 0;
                    this.statusMsg = false;
                    this.datasModalMsgCustom.days = [];
                    this.datasModalMsgCustom.daysNumber = [];
                    this.datasModalMsgCustom.customSch = [];
                    this.datasModalMsgCustom.invalidDates = 0;
                    this.datasModalMsgCustom.txtDays = '';
                    this.statusMsgCustom = false;
                    this.paddingRight = '1.5rem';
                }, 400);
			},
            applyToSpecificDays(days){
                this.$Progress.start();

                if (!this.disabledDisponibility){
                    this.sending = true;

                    let schedule = [];
                    if (this.available && this.schedule.length > 0)
                        schedule = this.schedule.reduce( (arr, item) => {
                            arr.push({ from: item.from, to: item.to });
                            return arr;
                        },[]);
                    else
                        schedule = [ { from: null, to: null } ]

                    const dates = days.reduce( (arr, item) => {
                        arr.dates.push({ custom_date: item, times:  schedule });
                        return arr;
                    },{ dates: [] });

                    this.axiosRequest({ config: {
                        method: 'POST',
                        url: `${this.auth.api}/custom-schedule`,
                        data: dates,
                        headers: { 'Content-Type': 'application/json', authorization : this.auth.token }
                    } }).then( resp => resp.data )
                    .then( data => {
                        this.$Progress.finish();
                        this.sending = false;
                        this.$emit('changeDays', { dates: days, sch: schedule });
                        this.setNotification({ type: data.type, msg: data.message });
                        this.close();
                    } ).catch( err => {
                        this.sending = false;
                        this.setNotification({ type: data.type, msg: data.message });
                    } )
                }else{
                    this.$Progress.finish();
                }
            },
            updateGeneralSch(days){
                const { removeDates, invalidDates, daysNumber } = this.$parent.getCustomSchedulesByWeek(days, this.available?this.schedule:[{ from: null, to: null }], this.datas.date);

                if (invalidDates == 0)
                    this.applyToAll(days, removeDates, daysNumber)
                else{
                    this.datasModalMsgCustom.days = days;
                    this.datasModalMsgCustom.daysNumber = daysNumber;
                    this.datasModalMsgCustom.invalidDates = invalidDates;
                    this.datasModalMsgCustom.customSch = removeDates;
                    this.statusMsgCustom = true;
                    this.datasModalMsgCustom.daysNumber.forEach( (day, i, arr) => {
                        const dayn = this.$moment(day, 'e').locale(this.i18n.locale.split('_')[0]).format('dddd');
                        if (i == 0 || arr.length == 1)
                            this.datasModalMsgCustom.txtDays = dayn;
                        else if(i > 0 && i < arr.length-1)
                            this.datasModalMsgCustom.txtDays = `${this.datasModalMsgCustom.txtDays}, ${dayn}`;
                        else if(i != 0 && i == arr.length-1)
                            this.datasModalMsgCustom.txtDays = `${this.datasModalMsgCustom.txtDays} ${this.$t('availability.modals.availability.and')} ${dayn}`;
                    } )
                }
            },
            applyToAll(days, customSch=[], daysN){
                this.$Progress.start();
                if (!this.disabledDisponibility){
                    this.sending = true;
                    let newCurrentSChedule = new Map(),
                        schedules = [],
                        schedule = [];

                    if (this.available)
                        schedule = this.schedule.reduce( (arr, item) => {
                            arr.push({ from: item.from, to: item.to });
                            return arr;
                        },[]);

                    for (var [key, value] of this.value.entries()) {
                        const day = days.find( d => d==key);
                        if (day){
                            schedules.push({ weekDay: key, status: 1, times: schedule });
                            newCurrentSChedule.set(key, { status: 1, ranges: schedule });
                        }
                        else{
                            schedules.push({ weekDay: key, status: 1, times: value.ranges });
                            newCurrentSChedule.set(key, { status: 1, ranges: value.ranges });
                        }
                    }

                    this.axiosRequest({ config: {
                        method: 'PUT',
                        url: `${this.auth.api}/schedules`,
                        data: { schedules: schedules, removeDates: customSch },
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data )
                    .then( data => {
                        this.$Progress.finish();
                        this.sending = false;
                        this.$emit('input', newCurrentSChedule);
                        if (customSch.length > 0)
                            this.$emit('changeEventAndRemoveCustoms', { customs: customSch, days: daysN });
                        else
                            this.$emit('changeEvents');
                        this.datasModalMsgCustom.days = [];
                        this.datasModalMsgCustom.customSch = [];
                        this.datasModalMsgCustom.txtDays = '';
                        this.statusMsgCustom = false;
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                        this.close();
                    } ).catch( err => {
                        this.sending = false;
                        this.setNotification({ type: 'error', msg: err.response.data.error.message  });
                    } )
                }else{
                    this.$Progress.finish();
                }
            },
            getContentHeigth(){
                if (this.$refs.title)
                    this.availabilityHeight = Math.trunc(((window.innerHeight/10*9) - this.$refs.title.offsetHeight - 108));
                if (this.view === 'availability' && this.$refs.availability)
                    this.$refs.availability.update();
                else if(this.view === 'multiple' && this.$refs.multiple)
                    this.$refs.multiple.update();
            },
            changeView(view, pd){
                this.view = view;
                setTimeout( () => { this.paddingRight = pd }, 400)
            }
		},
		watch:{
			show: function(newVal){
                this.$nextTick( () => {
                    if (newVal){
                        this.status = true;
                        this.schedule = this.datas.ranges;
                        this.available = this.datas.isAvailable && this.schedule.length > 0;
                        document.addEventListener('keydown', this.onKeyPress);
                        window.addEventListener('resize', this.getContentHeigth);
                    }
                })
			},
            status: function(newVal){
                if (newVal){
                    this.getContentHeigth();
                }
            }
		}
	}
</script>