var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-field",
      class: {
        focus: _vm.isFocus,
        error: _vm.error,
        active: _vm.isActive,
        disabled: _vm.disabled
      },
      style: { "--focus-color": _vm.focusColor }
    },
    [
      _vm.label != ""
        ? _c(
            "label",
            {
              staticClass: "v-placeholder",
              class: { isActive: _vm.activeLabel },
              style: _vm.labelStyles
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-content-field position-relative" },
        [
          _c("date-picker", {
            attrs: {
              lang: _vm.lang,
              "disabled-date": _vm.disabledDate,
              "input-class": "field_",
              type: _vm.type,
              placeholder: _vm.placeholder,
              "input-attr": { required: _vm.required },
              disabled: _vm.disabled,
              clearable: false
            },
            on: {
              input: function($event) {
                return _vm.handleInput()
              },
              focus: () => (_vm.focus = true),
              blur: () => (_vm.focus = false)
            },
            scopedSlots: _vm._u([
              {
                key: "calendar-icon",
                fn: function() {
                  return [_c("svgicon", { attrs: { name: "calendar-full" } })]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }