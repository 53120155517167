/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 1h-16C.673 1 0 1.673 0 2.5v16c0 .827.673 1.5 1.5 1.5h16c.827 0 1.5-.673 1.5-1.5v-16c0-.827-.673-1.5-1.5-1.5zm-16 18a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v16a.5.5 0 01-.5.5h-16z"/><path pid="1" d="M12.854 12.354l-3 3a.5.5 0 01-.707 0l-3-3a.5.5 0 01.707-.707L9 13.793V5.5a.5.5 0 011 0v8.293l2.146-2.146a.498.498 0 01.708 0 .5.5 0 010 .707z"/>'
  }
})
