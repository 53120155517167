/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pilcrow': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.5 2H7C4.794 2 3 3.794 3 6s1.794 4 4 4h1v7.5a.5.5 0 001 0V3h3v14.5a.5.5 0 001 0V3h2.5a.5.5 0 000-1zM8 9H7C5.346 9 4 7.654 4 6s1.346-3 3-3h1v6z"/>'
  }
})
