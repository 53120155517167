
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class StudentLesson extends Vue{
        @Prop({ type: Object, required: true }) readonly lesson!: any;
        @Prop({ default: undefined }) readonly w!: any;

        @State(state => state.menu.compact) statusMenu;
        @State(state => state.auth.permission) userType;
        @State(state => state.grid) bootstrapGrid;
        
        photoSize: number = 0;
        gridSize: object = {};
        padding: string = '10px';
        btnLarge: boolean = false;
        maxSectionsSize: any = {
        	typeModality: 266,
            topic: 171,
        	schedule: 209,
        	place: 169
        }

        get t(): any{
        	return (this as any).$t;
        }

        get refs(): any{
        	return (this as any).$refs;
        }

        get cardName(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type };
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get statusParting(): any{
            if (this.bootstrapGrid == 'xl')
                return { typeModality: true, topic: true, schedule: true };
            else if (this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md')
                return { typeModality: true, topic: true, schedule: false };
            else if (this.bootstrapGrid == 'sm')
                return { typeModality: true, topic: false, schedule: true };
            else
                return { typeModality: false, topic: false, schedule: false }
        }
        
        created(){
            if (this.bootstrapGrid != 'xl' || this.statusMenu)
                this.btnLarge = true;
            else
                this.btnLarge = false;
        }

        mounted(){
        	this.photoSize = this.refs.photo.offsetWidth;
            (this as any).$nextTick(() => {
                if (this.userType == 'tutor')
                    this.maxSectionsSize = {
                        typeModality: 266,
                        topic: 161,
                        schedule: 201,
                        place: 161
                    }
                this.getGrid();
            });
        }

        getGrid():void {
            let typeModality: number = this.maxSectionsSize.typeModality,
                schedule: number = this.maxSectionsSize.schedule,
                topic: number = this.maxSectionsSize.topic,
                place: number = this.maxSectionsSize.place,
                actions: number = this.refs.actions.offsetWidth + 1;
            const realW = this.w - 2;
        	let w = realW;
                
            let rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic + this.maxSectionsSize.place + actions)),
                p = 10,
                calcPadding = rest / p;

            if (this.bootstrapGrid == 'xl') { // > 1200px
                w  -= actions;
                typeModality = (w / 4) <= this.maxSectionsSize.typeModality?typeModality:(w / 4);
                w  -= typeModality;
                schedule = (w / 3) <= this.maxSectionsSize.schedule?schedule:(w / 3);
                w -= schedule;
                topic = (w / 2) <= this.maxSectionsSize.topic?topic:(w / 2);
                w -= topic;
                place = w - 100;
            }
            else if(this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md'){ // > 768px && < 1199px
                rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule + this.maxSectionsSize.topic));
                p = 6;
                calcPadding = rest / p;

                typeModality = (w / 3) <= this.maxSectionsSize.typeModality?typeModality:(w / 3);
                w  -= typeModality;
                topic = w <= this.maxSectionsSize.topic?topic:(w / 2);
                w  -= topic;
                schedule = w - 100;
                place = realW - actions - 100;
            }
            else if(this.bootstrapGrid == 'sm'){ // > 576px && < 767px
                rest = (realW - (this.maxSectionsSize.typeModality + this.maxSectionsSize.schedule));
                p = 4;
                calcPadding = rest / p;

                typeModality = (w / 2) <= this.maxSectionsSize.typeModality?typeModality:(w / 2);
                schedule = typeModality;
                    w  -= typeModality;

                topic = 0;
                place = 0;
                
                
            }else if(this.bootstrapGrid == 'xs'){
                rest = realW;
                p = 2;
                calcPadding = 15;
                typeModality = 0;
                schedule = 0;
                topic = 0;
                place = 0;
            }

            if (rest >= (p * 10))
                this.padding = calcPadding >= 15?'15px':`${calcPadding}px`;
            else
                this.padding = '10px';

            this.gridSize =  { typeModality: { minWidth: typeModality?`${Math.trunc(typeModality - 1)}px`:null },
                               schedule: { minWidth: schedule?`${Math.trunc(schedule - 1)}px`:null },
                               topic: { minWidth: topic?`${Math.trunc(topic - 1)}px`:null },
                               place: { minWidth: place?`${Math.trunc(place - 1)}px`:null } };
        }

        @Watch('w')
        handleGrid(){
            if (this.bootstrapGrid != 'xl' && this.statusMenu)
                this.btnLarge = true;
            else if(!this.statusMenu)
                this.btnLarge = false;
            this.getGrid();
        }

        @Watch('statusMenu')
        handleGrid2(val: any){
            if (this.bootstrapGrid != 'xl' || val)
                this.btnLarge = true;
            else
                this.btnLarge = false;
                setTimeout(()=> {
                    this.getGrid();
                }, 500)
        }
	}
