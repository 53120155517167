/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ruler': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 7h-16C.673 7 0 7.673 0 8.5v3c0 .827.673 1.5 1.5 1.5h16c.827 0 1.5-.673 1.5-1.5v-3c0-.827-.673-1.5-1.5-1.5zm.5 4.5a.5.5 0 01-.5.5H16v-1.5a.5.5 0 00-1 0V12h-2V9.5a.5.5 0 00-1 0V12h-2v-1.5a.5.5 0 00-1 0V12H7V9.5a.5.5 0 00-1 0V12H4v-1.5a.5.5 0 00-1 0V12H1.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v3z"/>'
  }
})
