import textField from './textField/index.vue';
import datePicker from './v-datePicker.vue';
import select from './v-select.vue';
import selectLang from './select-lang.vue';
import vSwitch from './v-switch.vue';
import range from './v-range.vue';
import checkbox from './v-checkbox.vue';
import radio from './v-radioButton.vue';
import radio2 from './v-radio.vue';
import cropImage from './crop-image.vue';

function plugin(Vue){
	Vue.component('v-textField', textField); 
    Vue.component('v-datePicker', datePicker);
    Vue.component('v-select', select);
    Vue.component('v-select-lang', selectLang);
    Vue.component('v-switch', vSwitch);
    Vue.component('v-range', range); //
    Vue.component('v-checkbox', checkbox); //
    Vue.component('v-radio-button', radio); //
    Vue.component('v-radio', radio2);
    Vue.component('v-crop-image', cropImage); //
}

export default plugin