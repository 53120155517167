
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State, Action } from 'vuex-class';

	@Component
	export default class lessonHistory extends Vue{
        @Prop({ type: Object, default: true }) readonly lesson!: any;
        @Prop({ default: undefined }) readonly w!: any;

        @State(state => state.menu.compact) statusMenu;
        @State(state => state.auth.permission) userType;
        @State(state => state.grid) bootstrapGrid;

        @Action('beforeShowModal') beforeShowModal

        photoSize: number = 0;
        gridSize: object = {};
        padding: string = '14px';
        statusDetail: Boolean = false;
        contentLesson: any = null;
        contentLessonAuto: Boolean = false;
        maxSectionsSize: any = {
        	typeModality: 276,
        	schedule: 171,
        	topic: 171,
        	place: 181
        }
        
        get t(): any{
            return (this as any).$t;
        }

        get moment(): any{
            return (this as any).$moment;
        }

        get refs(): any{
            return (this as any).$refs;
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type };
        }

        get cardName(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get statusParting(): any{
            if (this.bootstrapGrid == 'xl'){
                return { typeModality: true, topic: true, schedule: true, place: true };
            }
            else if (this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md')
                return { typeModality: true, topic: true, schedule: false };
            else if (this.bootstrapGrid == 'sm')
                return { typeModality: true, topic: false, schedule: true };
            else
                return { typeModality: false, topic: false, schedule: false }
        }

        get initialTime(): string{
        	return this.moment(this.lesson.initialTime,'hh:mm:ss').locale('en').format('LT'); 
        }

        get endTime(): string{
        	return this.moment(this.lesson.endTime,'hh:mm:ss').locale('en').format('LT'); 
        }

        mounted(){
        	this.photoSize = this.refs.photo.offsetWidth;
        	this.getGrid();
        }

        getGrid():void {
        	this.contentLesson = null;
        	let typeModality: number = this.maxSectionsSize.typeModality,
                schedule: number = this.maxSectionsSize.schedule,
                topic: number = this.maxSectionsSize.topic,
                place: number = this.maxSectionsSize.place,
                stars: number = this.refs.stars.offsetWidth + 1,
                btnDown: number = this.refs.btnDown.offsetWidth + 1;

            const realW = this.w - 2;

            let w = realW;
            
            if (this.bootstrapGrid == 'xl') {
            	w  = w - stars - btnDown;
            	typeModality = (w / 4) <= this.maxSectionsSize.typeModality?this.maxSectionsSize.typeModality:(w / 4);
                w  -= typeModality;

                topic = (w / 3);
                w  -= topic;

                schedule = (w / 2);
                w  -= schedule;

                place = 2;

                if ((schedule + topic + w) > (this.maxSectionsSize.schedule + this.maxSectionsSize.topic + this.maxSectionsSize.place)){
                	this.contentLessonAuto = false;
                	this.contentLesson = realW - typeModality - btnDown;
                }else{
                	this.contentLessonAuto = true;
                	this.contentLesson = realW - typeModality;
                }
            }
            else if(this.bootstrapGrid == 'lg' || this.bootstrapGrid == 'md'){
            	this.contentLesson = realW;
            	this.contentLessonAuto = false;
            	typeModality = (w / 3) <= this.maxSectionsSize.typeModality?typeModality:(w / 3);
                    w  -= typeModality;
                schedule = (w / 2);
                    w -= schedule;
                topic = w;

                place = realW - stars - btnDown;
            }
            else if(this.bootstrapGrid == 'sm'){
            	typeModality = (w / 2) <= this.maxSectionsSize.typeModality?typeModality:(w / 2);
            	schedule = typeModality;
                    w  -= typeModality;

                topic = w;
                place = w;
            }

            this.gridSize =  { typeModality: { minWidth: `${typeModality - 1}px` },
                               schedule: { minWidth: `${schedule - 1}px` },
                               topic: { minWidth: `${topic - 1}px` },
                               place: { minWidth: `${place - 1}px` } };
        }

        @Watch('w')
        handleGrid(){
            this.getGrid();
        }

        @Watch('statusMenu')
        handleGrid2(val: any){
            setTimeout(()=> {
                this.getGrid();
            }, 500)
        }
	}
