/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-left': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14 20a.5.5 0 00.354-.853l-8.646-8.646 8.646-8.646a.5.5 0 00-.707-.707l-9 9a.5.5 0 000 .707l9 9a.498.498 0 00.354.146z"/>'
  }
})
