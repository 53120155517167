
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class lessonTime extends Vue{
		// Required { initialTime, tzScheduled }, opcional { titleCSS, timeCSS }
        @Prop({ type: Object, required: true }) readonly options!: any;

		@State( state => state.auth.permission ) userType;
		@State('serverTime') serverTime;

		duration: any = '0:00:00';
		interval: any = '';

		get moment(): any{
			return (this as any).$moment;
		}

		get titleCSS(){
			const css = { fontSize: '17px', fontWeight: 400, color: '#4A4A4A', lineHeight: '19px' };
			if (this.options.titleCSS)
				return { ...css, ...this.options.titleSize };
			else
				return css;
		}

		get timeCSS(){
			const css = { fontSize: '2.8rem', fontWeight: 500, color: '#4A4A4A', lineHeight: '1.1em' };
			if (this.options.timeCSS)
				return { ...css, ...this.options.timeCSS };
			else
				return css;
		}

		created(){
            this.getDuration();
        }

        beforeDestroy(){
        	clearInterval(this.interval);
        }

        getDuration(): void{
        	const { initialTime, tzScheduled } = this.options;
			const lt = initialTime.split(":");
			let duration = this.moment(this.serverTime);
			clearInterval(this.interval);

			duration = duration.utcOffset(tzScheduled); // timeZone
			duration = duration.subtract({ hours: lt[0], minutes: lt[1], seconds: lt[2] }); // Get hours, minutes and seconds
			this.duration = duration.format('HH:mm:ss');

			this.interval = setInterval(() => {
				duration = duration.add(1, "seconds");
				this.duration = duration.format('HH:mm:ss');
			},1000);
        }
		
		@Watch('serverTime')
		handleDuration(){
			this.getDuration();
		}
	}
