var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-format-question-radio" },
    _vm._l(_vm.questions, function(q) {
      return _c("v-question-radio", {
        key: q.id,
        attrs: {
          statusFormat: _vm.statusFormat,
          question: q,
          error: _vm.error
        },
        on: { handleAnswer: _vm.handleAnswer }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }