/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M12.41 14H1.59A1.59 1.59 0 010 12.41V1.59C0 .713.712 0 1.59 0h10.82C13.287 0 14 .712 14 1.59v10.82A1.59 1.59 0 0112.41 14z" _fill="#000" fill-rule="nonzero"/>'
  }
})
