/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'teacup': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.733 12.649a1.28 1.28 0 00-.829-.604c-.37-.085-.769-.017-1.117.096.137-.742.189-1.299.205-1.516.005-.044.007-.085.007-.125l-.001-.019v-.007c-.016-.702-.666-1.28-1.934-1.719-1.059-.367-2.524-.62-4.125-.714a.5.5 0 10-.058.998c1.512.088 2.881.323 3.856.661 1.005.348 1.25.688 1.262.792l-.002.027c-.03.139-.374.54-1.685.914-1.279.365-2.988.567-4.812.567s-3.534-.201-4.812-.567c-1.311-.375-1.655-.776-1.685-.914l-.002-.028c.021-.231.88-.939 3.57-1.304a.5.5 0 10-.135-.991c-1.226.166-2.272.43-3.025.763-.924.409-1.399.919-1.411 1.516v.003l-.001.021c0 .039.002.081.007.125.035.467.233 2.503 1.094 4.568C3.411 18.337 5.623 20 8.499 20c1.845 0 3.418-.685 4.654-2.006a.51.51 0 00.135-.04c.04-.019.993-.463 2.029-1.138 1.451-.945 2.319-1.857 2.581-2.71.157-.511.1-1.014-.166-1.456zM8.5 19c-2.468 0-4.307-1.403-5.467-4.171a14.37 14.37 0 01-.839-2.891 7.07 7.07 0 001.218.457c1.366.39 3.172.605 5.087.605s3.722-.215 5.087-.605a7.07 7.07 0 001.218-.457 14.204 14.204 0 01-.839 2.891C12.805 17.596 10.966 19 8.498 19zm8.442-5.188c-.185.604-.953 1.371-2.161 2.159-.121.079-.241.155-.359.227.17-.316.329-.652.477-1.006.263-.631.464-1.258.617-1.84.299-.172.838-.408 1.164-.333a.28.28 0 01.195.144c.12.2.142.407.067.648z"/><path pid="1" d="M9.494 11h.016a.501.501 0 00-.018-1c-.007 0-.688.001-1.117-.425-.249-.247-.376-.609-.376-1.075 0-1.44.359-2.096.74-2.791.374-.682.76-1.388.76-2.709 0-1.6-.53-2.678-.553-2.724a.5.5 0 00-.895.447c.004.009.447.91.447 2.276 0 1.065-.295 1.604-.637 2.229-.404.738-.863 1.575-.863 3.271 0 .748.229 1.352.681 1.794.694.68 1.671.706 1.814.706z"/>'
  }
})
