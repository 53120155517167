var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4",
      attrs: { id: "sign-up" }
    },
    [
      _c("div", { staticClass: "v-init-form-content d-flex flex-column" }, [
        _c("div", { staticClass: "v-paper w-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3"
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "v-btn-prev mr-2",
                  attrs: {
                    to: { name: "email-validatation" },
                    name: "institutionEmail",
                    type: "button",
                    tag: "button"
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "v-btn-prev-icon",
                    attrs: { name: "arrow-left", color: "#fff" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "v-if-title mb-0" }, [
                _vm._v(_vm._s(_vm.$t("signUp.t")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4 d-flex flex-column align-items-center" },
            [
              _c(
                "form",
                {
                  staticClass: "w-100",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.send()
                    }
                  }
                },
                [
                  _c("v-textField", {
                    ref: "name",
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.name.label"),
                      placeholder: _vm.$t("signUp.form.name.placeholder"),
                      options: _vm.inputOpc,
                      required: true
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textField", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.lastName.label"),
                      placeholder: _vm.$t("signUp.form.lastName.placeholder"),
                      options: _vm.inputOpc,
                      required: true
                    },
                    model: {
                      value: _vm.form.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "last_name", $$v)
                      },
                      expression: "form.last_name"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.gender.label"),
                      defaultOpc: _vm.$t("signUp.form.gender.placeholder"),
                      options: _vm.inputOpc,
                      opc: _vm.gender,
                      fieldVal: "val",
                      fieldTxt: "txt",
                      required: true
                    },
                    model: {
                      value: _vm.form.gender,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-datePicker", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.birthday"),
                      placeholder: "01/02/1994",
                      options: _vm.inputOpc,
                      required: true
                    },
                    model: {
                      value: _vm.form.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.campus.label"),
                      defaultOpc: _vm.$t("signUp.form.campus.placeholder"),
                      options: _vm.inputOpc,
                      opc: _vm.campuses,
                      fieldVal: "id",
                      fieldTxt: "name",
                      required: true
                    },
                    model: {
                      value: _vm.form.university_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "university_id", $$v)
                      },
                      expression: "form.university_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.career.label"),
                      defaultOpc: _vm.$t("signUp.form.career.placeholder"),
                      options: _vm.inputOpc,
                      opc: _vm.carrers,
                      disabled: _vm.form.university_id == "",
                      fieldVal: "id",
                      fieldTxt: "name",
                      required: true
                    },
                    model: {
                      value: _vm.form.career_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "career_id", $$v)
                      },
                      expression: "form.career_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.$t("signUp.form.period.label"),
                      defaultOpc: _vm.$t("signUp.form.period.placeholder"),
                      options: _vm.inputOpc,
                      opc: _vm.semesters,
                      disabled: _vm.form.university_id == "",
                      fieldVal: "id",
                      fieldTxt: "period",
                      required: true
                    },
                    model: {
                      value: _vm.form.period_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "period_id", $$v)
                      },
                      expression: "form.period_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textField", {
                    staticClass: "mb-2",
                    attrs: {
                      label: _vm.$t("signUp.form.password.label"),
                      maxlength: "50",
                      placeholder: _vm.$t("signUp.form.password.placeholder"),
                      options: _vm.inputOpc,
                      type: "password",
                      iconEye: true,
                      required: true
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "v-if-alert d-flex align-items-center mb-4",
                      attrs: { color: "gray" }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "v-if-icon-info mr-1",
                        attrs: { name: "info", color: "#9b9b9b" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("signUp.form.password.alert")))
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "v-if-checkbox d-flex align-items-center mb-3"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.terms,
                            expression: "terms"
                          }
                        ],
                        staticClass: "mr-2",
                        attrs: { type: "checkbox", required: "" },
                        domProps: {
                          checked: Array.isArray(_vm.terms)
                            ? _vm._i(_vm.terms, null) > -1
                            : _vm.terms
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.terms,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.terms = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.terms = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", {
                        staticClass: "txt mb-0",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("signUp.legalHtml"))
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    staticClass: "w-100",
                    attrs: {
                      fontSize: "1rem",
                      fontWeight: "400",
                      txt: _vm.$t("signUp.btn"),
                      type: "submit",
                      height: "40px",
                      name: "signUp",
                      spinner: _vm.sending,
                      disabled: _vm.$v.$invalid
                    }
                  })
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("p", { staticClass: "v-if-copyright mb-2" }, [
              _vm._v(_vm._s(_vm.$t("copyRight", { year: 2022 })))
            ]),
            _vm._v(" "),
            _c("svgicon", {
              staticClass: "v-if-link-logo",
              attrs: { name: "logo", color: "#a4a4a4" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-select-lang")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }