const modalVal = state => {
	if (state.showModal.indexOf(':') > 1) {
	    const vals = state.showModal.split(':');
	    return { modal: vals[0], type: vals[1]  }
    }else
    	return { modal: state.showModal }
}

export default{
	modalVal
}