<template>
	<div class="v-full-calendar w-100">
        <!-- ============= Spinner ============= -->
        <div class="bgColor position-absolute w-100 h-100 pt-5 pb-5" v-if="!calenarIsReady">
            <div class="content-spinner">
                <v-spinner />
            </div>
        </div>

        <!-- ============ Full calendar =========== -->
	    <FullCalendar class="full-calendar" ref="fullCalendar" :options="calendarOptions" />

	    <!-- ============== Modal availability ============= -->
	    <v-availability :minRangetime="minRangetime" :initTime="initTime" :endTime="endTime" :disabledDisponibility="disabledDisponibility" @closeAvailability="closeAvailability" @changeDays="updateCalendar" @changeEvents="addScheduleTodays()" @changeEventAndRemoveCustoms="changeEventAndRemoveCustoms" v-model="generalSchedule" :datas="availabilityDatas" :show="showModalAvailability" />
	</div>
</template>
<script>
	import dayGridPlugin from '@fullcalendar/daygrid';
	import interactionPlugin from '@fullcalendar/interaction';
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		props: {
            initTime: { type: String, default: '06:00', required: false },
            endTime: { type: String, default: '21:00', required: false },
            minRangetime: { type: Number, default: 30, required: false },
            disabledDisponibility: { default: false, required: false },
            value: '' // General Schedule
		},
		data(){
			return{
                calendarOptions:{
                    plugins: [ dayGridPlugin, interactionPlugin ],
                    fixedWeekCount: false,
                    events: [],
                    dayMaxEvents: 4,
                    moreLinkContent: (more) => {
                        return `+ ${more.num} ${this.$t('availability.btn.more')}`;
                    },
                    height: 'auto',
                    dateClick: this.handleDateClick,
                    datesSet: this.handleMonthChange,
                    locale: '',
                    initialView: '',
                    initialDate: '',
                    windowResizeDelay: 500,
                    headerToolbar: {
                        start: 'title prev today next',
                        center: '',
                        end: ''
                    },
                    titleFormat: (date) => {
                        let month = this.$moment(date.date.month+1, 'M').locale(this.i18n.locale).format('MMMM');
                            month = month.charAt(0).toUpperCase()+month.slice(1);
                        
                        return `${month} ${date.date.year}`;
                    },
                    buttonText: {
                        today: this.$t('today')
                    }
                },
				calenarIsReady: false,
				showModalAvailability: false,
				availabilityDatas: {},
                today: this.$moment().set({hour:0,minute:0,second:0,millisecond:0}),
                generalSchedule: new Map(),
                customSchedules: new Map(),
                customSchedulesByWeek: new Map(),
                eventsByMonth: new Map(),
			}
		},
		computed:{
			...mapState(['calendar', 'i18n', 'auth', 'menu' ])
		},
		created(){
            this.getCustomSchedules();
            this.generalSchedule = this.value;
            this.calendarOptions.initialView = this.calendar.mode;
            this.calendarOptions.initialDate = this.calendar.defaultDate;
            this.calendarOptions.locale = this.i18n.locale.split('_')[0];
		},
        mounted(){
            this.visibleRange();
        },
		methods:{
			...mapMutations(['setCalendarHistory']),
			...mapActions(['axiosRequest']),
			getCustomSchedules(){ // Get custom Schedule by specific days
                this.axiosRequest({ config: {
                   method: 'GET',
                   url: `${this.auth.api}/custom-schedules`,
                   data: { tutor_id: this.auth.user.id },
                   headers: { authorization : this.auth.token }
                }}).then( data => data.data )
                .then( async resp => {
                    await resp.map( sch => {
                        const fullDate = this.$moment(sch.custom_date).utc().format('YYYY-MM-DD'),
                              ym = this.$moment(sch.custom_date).utc().format('YYYY-MM'),
                              day = this.$moment(sch.custom_date).utc().format('e');

                         
                        if (!this.customSchedules.has(ym)){
                            const d = new Map();
                                  d.set(fullDate, { ranges: [{ from: sch.from, to: sch.to }] });
                            this.customSchedules.set(ym, d);
                        }else{
                            if (this.customSchedules.get(ym).has(fullDate))
                                this.customSchedules.get(ym).get(fullDate).ranges.push({ from: sch.from, to: sch.to });
                            else
                                this.customSchedules.get(ym).set(fullDate, { ranges: [{ from: sch.from, to: sch.to }] });
                        }

                        if (!this.customSchedulesByWeek.has(day))
                            this.customSchedulesByWeek.set(day, { dates: [ fullDate ] });
                        else{
                            if (!this.customSchedulesByWeek.get(day).dates.find( d => d === fullDate ))
                                this.customSchedulesByWeek.get(day).dates.push(fullDate);
                        }
                    });
                    this.addScheduleTodays();
                    this.calenarIsReady = true;
                } ).catch( err => {
                    console.log(err);
                    this.calenarIsReady = true;
                } )
            },
            addScheduleTodays(){ // Create events
            	this.calendarOptions.events = [];
                this.eventsByMonth.clear();

            	const nextMonth = this.$moment(this.$moment(this.$refs.fullCalendar.getApi().getDate()).format('YYYY-MM'), 'YYYY-MM').add(1, 'M'),
            	      currentMonth = this.$moment(this.$refs.fullCalendar.getApi().getDate()).set({hour:0,minute:0,second:0,millisecond:0});

            	    this.$refs.fullCalendar.getApi().addEventSource( (dt) => { // Current month

            	    	const startDate = this.$moment(dt.startStr).toDate().getTime(),
            	    	endDate = this.$moment(dt.endStr).toDate().getTime(),
            	    	oneDay = (24 * 60 * 60 * 1000);

            	    	for (var i = startDate; i <= endDate; i +=oneDay) {
            	    		const day = this.$moment(i).locale('us'),
                                  m = this.$moment(i).format('YYYY-MM'),
                                  fullDate = this.$moment(i).format('YYYY-MM-DD');
                            let ranges = [],
                                  isCustom= false,
                                  isAvailable= true;
                            
                            if (this.customSchedules.has(m)?this.customSchedules.get(m).has(fullDate):false) { // ranges custom Schedule
                                const t = this.customSchedules.get(m).get(fullDate);
                                ranges = this.customSchedules.get(m).get(fullDate).ranges;
                                if (!t.ranges[0].from)
                                    isAvailable = false;
                                isCustom = true;
                            }else{ // ranges general Schedule
                                let d = this.$moment(i).locale('es').format('dddd');
                                    d = d.charAt(0).toUpperCase() + d.slice(1).replace('é','e').replace('á','a');
                                isAvailable = this.generalSchedule.get(d).status;
                                ranges = this.generalSchedule.get(d).ranges;
                            }

            	    		if (day.isSameOrAfter(this.today) && day.isSameOrAfter(currentMonth) && day.isBefore(nextMonth)){
                                let eventsByDay = null;
                                if (!isCustom && ranges.length === 0) {
                                    eventsByDay = {
                                        ranges: [ { from: null, to: null } ], 
                                        isCustom: isCustom,
                                        available: isAvailable,
                                    }
                                }
                                else{
                                    ranges.sort( (a,b) => { // Order ranges by val 'from' to add correctly the classes
                                        const at = this.$moment(a.from, 'HH:mm');
                                        const bt = this.$moment(b.from, 'HH:mm');
        
                                        if (at.isAfter(bt))
                                            return 1;
                                        else (bt.isAfter(at))
                                            return -1;

                                        return 0;
                                    } );

            	    			    ranges.forEach( (item, i) => {
                                        // Remove seconds
                                        let f = item.from,
                                            t = item.to;
                                        if (f && t) {
                                            f = f.split(':');
                                            f = `${f[0]}:${f[1]}`;
                                            t = t.split(':');
                                            t = `${t[0]}:${t[1]}`;
                                        }
                                        
                                        // Create event
            	    				    const event = {
            	    					    title: `${this.$moment(item.from, 'HH:mm').format('LT')} - ${this.$moment(item.to, 'HH:mm').format('LT')}`,
                                            ranges: { from: f, to: t }, 
            	    					    start: this.$moment(`${day.format('MM-DD-YYYY')} ${item.from}`, 'MM-DD-YYYY HH:mm').toDate(),
            	    					    end: this.$moment(`${day.format('MM-DD-YYYY')} ${item.to}`, 'MM-DD-YYYY HH:mm').toDate(),
            	    					    backgroundColor: '#fff',
            	    					    classNames: [ !isCustom?'sch-general':'' , 'sch-time', i==0?'sch-lt':'', i==ranges.length-1 || (ranges.length >= this.calendarOptions.dayMaxEvents && i==this.calendarOptions.dayMaxEvents-1)?'sch-lb':'' ],
                                            isCustom: isCustom,
                                            display: !isAvailable?'none':'auto',
                                            available: isAvailable,
            	    					    allDay: false
            	    				    }
                                            
                                        this.calendarOptions.events.push(event);

                                        if (eventsByDay)
                                            eventsByDay.ranges.push(event.ranges);
                                        else{
                                            eventsByDay = event;
                                            eventsByDay.ranges = [ event.ranges ]
                                        }
            	    			    });
                                }
                                this.eventsByMonth.set(this.$moment(i).format('YYYY-MM-DD'), eventsByDay);
                            }
            	    	}
            	    } )
            },
            async handleMonthChange(e){ // Listen to change month or week
                const date = this.$moment(this.$refs.fullCalendar.getApi().getDate()).set({hour:0,minute:0,second:0,millisecond:0});
                this.setCalendarHistory({ ...this.calendar, defaultDate: date.toDate() });

                if (this.calenarIsReady){ // If calendar is load add events
                    await this.addScheduleTodays();

                    if (e.view.type === 'dayGridMonth' && this.today.format('YYYY MM') === date.format('YYYY MM') || e.view.type === 'dayGridWeek' && date.isSameOrBefore(this.today)) // Disabled btn prev to past months
                        this.$refs.fullCalendar.$el.querySelector('.fc-prev-button').setAttribute('disabled',''); 
                    else      
                        this.$refs.fullCalendar.$el.querySelector('.fc-prev-button').removeAttribute('disabled');
                }  
            },
            handleDateClick(e){ // date click
                const dayClick = this.$moment(e.date),
                      currentMonth = this.$moment(this.$refs.fullCalendar.getApi().getDate());

                if (dayClick.format('MM') === currentMonth.format('MM') && dayClick.isSameOrAfter(this.today) && !this.auth.user.vacation_mode){
                    this.availabilityDatas.date = this.$moment(e.date);
                    const event = this.eventsByMonth.get(this.availabilityDatas.date.format('YYYY-MM-DD'));
                    this.availabilityDatas.isAvailable = event.available;
                    
                    if (event.ranges[0].from)
                        this.availabilityDatas.ranges = event.ranges;
                    else
                        this.availabilityDatas.ranges = [];

                    this.showModalAvailability = true;
                }
            },
            getCustomSchedulesByWeek(days, schedule, clickDay){
                let removeDates = [];
                let invalidDates = 0;
                let daysNumber = [];
                days.forEach( (day) => {
                    let customSchByWeek,
                        weekN;
                    if (day == 'Domingo' && this.customSchedulesByWeek.has('0')){
                        customSchByWeek = this.customSchedulesByWeek.get('0');
                        weekN = '0';
                    }
                    else if (day == 'Lunes' && this.customSchedulesByWeek.has('1')){
                        customSchByWeek = this.customSchedulesByWeek.get('1');
                        weekN = '1';
                    }
                    else if (day == 'Martes' && this.customSchedulesByWeek.has('2')){
                        customSchByWeek = this.customSchedulesByWeek.get('2');
                        weekN = '2';
                    }
                    else if (day == 'Miercoles' && this.customSchedulesByWeek.has('3')){
                        customSchByWeek = this.customSchedulesByWeek.get('3');
                        weekN = '3';
                    }
                    else if (day == 'Jueves' && this.customSchedulesByWeek.has('4')){
                        customSchByWeek = this.customSchedulesByWeek.get('4');
                        weekN = '4';
                    }
                    else if (day == 'Viernes' && this.customSchedulesByWeek.has('5')){
                        customSchByWeek = this.customSchedulesByWeek.get('5');
                        weekN = '5';
                    }
                    else if (day == 'Sabado' && this.customSchedulesByWeek.has('6')){
                        customSchByWeek = this.customSchedulesByWeek.get('6');
                        weekN = '6';
                    }

                    if (customSchByWeek && weekN){
                        removeDates = [ ...removeDates, ...customSchByWeek.dates ];
                        daysNumber.unshift(weekN);
                        customSchByWeek.dates.forEach( item => {
                            const month = this.$moment(item, 'YYYY-MM-DD').format('YYYY-MM'),
                                  ranges = this.customSchedules.get(month).get(item).ranges,
                                  isToday = clickDay.isSame(this.$moment(item, 'YYYY-MM-DD'));

                            let isInvalid = false;   
                            if (schedule.length == ranges.length && !isToday){
                                for (var i = 0; i < schedule.length; i++) {
                                    const range = ranges.find( item => { 
                                        let { from, to } = item;
                                        if (from && to) {
                                            from = from.split(':');
                                            to = to.split(':');
                                            from = `${from[0]}:${from[1]}`;
                                            to = `${to[0]}:${to[1]}`;
                                        }

                                        if(from == schedule[i].from && to == schedule[i].to)
                                            return item;
                                    } );
                                    if (!range) {
                                        isInvalid = true;
                                        break;
                                    }
                                }
                            }else
                                isInvalid = !isToday;

                            if (isInvalid)
                                invalidDates++;
                        });
                    }
                });
                return { removeDates: removeDates, invalidDates: invalidDates, daysNumber: daysNumber };
            },
            updateCalendar(sch){
                sch.dates.map( (item,i) => {
                    const month = this.$moment(item, 'YYYY-MM-DD').format('YYYY-MM'),
                          day = this.$moment(item, 'YYYY-MM-DD').format('e');

                    if (!this.customSchedules.has(month)) {
                        const d = new Map();
                        d.set(item, { available: sch.sch.from, ranges: sch.sch });
                        this.customSchedules.set(month, d);
                    }else{
                        if (this.customSchedules.get(month).has(item)){
                            if (sch.sch.length == 0)
                                this.customSchedules.get(month).delete(item);
                            else{
                                this.customSchedules.get(month).get(item).ranges = sch.sch;                   
                                this.customSchedules.get(month).get(item).available = sch.sch.from;
                            }
                        }
                        else
                            this.customSchedules.get(month).set(item, { available: sch.sch.from,ranges: sch.sch });
                    }

                    if (!this.customSchedulesByWeek.has(day))
                        this.customSchedulesByWeek.set(day, { dates: [ item ] });
                    else{
                        if (!this.customSchedulesByWeek.get(day).dates.find( d => d === item ))
                            this.customSchedulesByWeek.get(day).dates.push(item);
                    }
                } );
                this.addScheduleTodays();
            },
            async changeEventAndRemoveCustoms(datas){
                await datas.days.forEach( day => {
                    this.customSchedulesByWeek.delete(day);
                });

                await datas.customs.forEach( date => {
                    const month = this.$moment(date, 'YYYY-MM-DD').format('YYYY-MM');
                    this.customSchedules.get(month).delete(date);
                    if (this.customSchedules.get(month).size == 0)
                        this.customSchedules.delete(month)
                });

                this.addScheduleTodays();
            },
            visibleRange(){
                let currentMonth = this.$moment(this.$moment().format("MMMM"),"MMMM"),
                    api = this.$refs.fullCalendar.getApi(),
                    endDate = currentMonth.add(7, "M").toDate();

                this.calendarOptions.validRange = { start: this.today, end: endDate };
            },
			closeAvailability(){
				this.showModalAvailability = false;
                this.availabilityDatas = {};
			}
		},
		watch: {
			'calendar.mode': function(newVal){
                this.calendarOptions.initialView = newVal;
                this.$refs.fullCalendar.getApi().changeView(newVal);
			},
			generalSchedule: function(newVal){
				this.$emit('input', newVal);
			},
            calenarIsReady: function(newVal){ // Block "prev button" when calendar load first time
                const date = this.$moment(this.$refs.fullCalendar.getApi().getDate()).set({hour:0,minute:0,second:0,millisecond:0});
                if (newVal && (this.calendar.mode === 'dayGridMonth' && this.today.format('YYYY MM') === date.format('YYYY MM') || this.calendar.mode === 'dayGridWeek' && date.isSameOrBefore(this.today)) )
                    this.$refs.fullCalendar.$el.querySelector('.fc-prev-button').setAttribute('disabled','');
                else      
                    this.$refs.fullCalendar.$el.querySelector('.fc-prev-button').removeAttribute('disabled');
            },
            'i18n.locale': function(newVal){
                this.calendarOptions.locale = newVal.split('_')[0];
                this.calendarOptions.buttonText.today = this.$t('today');
            },
            'calendar.defaultDate': function(newVal){
                this.calendarOptions.initialDate = newVal;
            },
            'menu.compact': function(newVal){
                setTimeout(()=> {
                    this.$refs.fullCalendar.getApi().updateSize();
                    if(this.$refs.fullCalendar)
                        this.$refs.fullCalendar.getApi().updateSize();
                },500);
            },
		}
	}
</script>