var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "v-room-toolbar d-flex align-items-center pl-0 pr-0 pl-sm-2 pr-sm-2 pl-lg-4 pr-lg-4",
      class: { compact: _vm.compact }
    },
    [
      _c("div", { staticClass: "col-4 pl-0 pr-0 h-100 d-flex" }, [
        _c("div", { staticClass: "h-100" }, [
          _vm.statusSessionDetails >= _vm.toolbarActions.focus
            ? _c("div", { staticClass: "btn-hover" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-session-details pl-3 pr-3 h-100",
              class: {
                active: _vm.statusSessionDetails >= _vm.toolbarActions.focus
              },
              on: {
                click: function($event) {
                  return _vm.toggleAction("session-details")
                }
              }
            },
            [
              _c("svgicon", {
                staticClass: "icon-subject d-block d-md-none",
                attrs: { name: "pencil-ruler" }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "bsd-txt d-none d-md-block" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("room.sessionDetails.btnSessionDetails")) +
                      " "
                  ),
                  _c("svgicon", {
                    staticClass: "icon-arrow",
                    attrs: {
                      name:
                        _vm.statusSessionDetails >= _vm.toolbarActions.focus
                          ? "chevron-up"
                          : "chevron-down"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("input", {
            ref: "session-details",
            staticClass: "input-hide",
            attrs: { type: "text", readonly: "" },
            on: {
              focus: function($event) {
                _vm.statusSessionDetails = _vm.toolbarActions.focus
              },
              blur: function($event) {
                return _vm.blurAction("session-details")
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "session-details" } }, [
        _vm.statusSessionDetails >= _vm.toolbarActions.focus
          ? _c(
              "div",
              {
                key: "details",
                staticClass: "v-session-details",
                on: {
                  mouseover: function($event) {
                    return _vm.hoverAction("session-details")
                  },
                  mouseleave: function($event) {
                    return _vm.leaveAction("session-details")
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pl-4 pt-3 pr-4 pb-3",
                    class:
                      _vm.statePermission == "student" ? "bg-blue" : "bg-green"
                  },
                  [
                    _c("h3", { staticClass: "v-title mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("room.sessionDetails.btnSessionDetails"))
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "v-body pl-4 pt-3 pr-4 pb-3" }, [
                  _c(
                    "div",
                    { staticClass: "sd-item" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-item",
                        attrs: { name: "user" }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "sd-item-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("room.sessionDetails.typeHtml", {
                              type: _vm.type
                            })
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sd-item" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-item",
                        attrs: { name: "pencil-ruler" }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "sd-item-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("room.sessionDetails.subjectHtml", {
                              subject: _vm.lesson.subject
                            })
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sd-item" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-item",
                        attrs: { name: "teacher-male" }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "sd-item-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("room.sessionDetails.tutorHtml", {
                              tutor: _vm.lesson.tutor
                            })
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sd-item" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-item",
                        attrs: { name: "calendar-full" }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "sd-item-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("room.sessionDetails.dateHtml", {
                              date: _vm.date
                            })
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sd-item" },
                    [
                      _c("svgicon", {
                        staticClass: "icon-item",
                        attrs: { name: "clock2" }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "sd-item-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("room.sessionDetails.timeHtml", {
                              time: _vm.time
                            })
                          )
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4 pl-0 pr-0 d-flex justify-content-center" },
        [
          _c(
            "div",
            {
              staticClass: "toolbar-actions row flex-nowrap flex-sm-wrap",
              class: { active: _vm.statusMovilActions }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "action-toolbar col-auto pl-0 pr-0 ml-2 mr-1 mr-md-2",
                  class: { disabled: !_vm.statusCam },
                  on: {
                    click: function($event) {
                      return _vm.$emit("handleCam")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "c-icon-action w-100 h-100 position-relative"
                    },
                    [
                      _vm.statusCam
                        ? _c("svgicon", {
                            staticClass: "icon-action",
                            attrs: { name: "camera" }
                          })
                        : _c("svgicon", {
                            staticClass: "icon-action i-100",
                            attrs: { name: "no-camera", original: "" }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "msg-hover" }, [
                    _vm._v(
                      _vm._s(
                        _vm.statusCam
                          ? _vm.$t("room.toolbar.cam.m")
                          : _vm.$t("room.toolbar.cam.u")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "action-toolbar col-auto pl-0 pr-0 ml-2 mr-1 mr-md-2",
                  class: { disabled: !_vm.statusMic },
                  on: {
                    click: function($event) {
                      return _vm.$emit("handleMic")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "c-icon-action w-100 h-100 position-relative"
                    },
                    [
                      _vm.statusMic
                        ? _c("svgicon", {
                            staticClass: "icon-action",
                            attrs: { name: "mic" }
                          })
                        : _c("svgicon", {
                            staticClass: "icon-action i-100",
                            attrs: { name: "no-micro", original: "" }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.statusMicAlert
                    ? _c("span", { staticClass: "msg-hover" }, [
                        _vm._v(
                          _vm._s(
                            _vm.statusMic
                              ? _vm.$t("room.toolbar.mic.m")
                              : _vm.$t("room.toolbar.mic.u")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-more-info", {
                    ref: "alertMic",
                    attrs: {
                      delayTime: 7000,
                      txtCloud: _vm.$t("room.toolbar.mic.spk"),
                      icon: null,
                      parent: "#room",
                      modality: "time",
                      status: _vm.statusMicAlert
                    },
                    on: {
                      hide: function($event) {
                        return _vm.$emit("handleVolumeAlert")
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.statePermission == "tutor" && _vm.lesson.type == "Grupal"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "action-toolbar col-auto pl-0 pr-0 ml-2 mr-1 mr-md-2",
                      on: {
                        click: function($event) {
                          return _vm.$emit("takeAssitant")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "c-icon-action w-100 h-100 position-relative"
                        },
                        [
                          _c("svgicon", {
                            staticClass: "icon-action",
                            attrs: { name: "list3" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "msg-hover" }, [
                        _vm._v(_vm._s(_vm.$t("room.toolbar.takeAttendance")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "action-toolbar close-room col-auto pl-0 pr-0 ml-2 mr-2",
                  class: { disabled: !_vm.statusMic },
                  on: {
                    click: function($event) {
                      return _vm.$emit("leave")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "c-icon-action w-100 h-100 position-relative"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "icon-action i-100",
                        attrs: { name: "end-call", original: "" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "msg-hover" }, [
                    _vm._v(_vm._s(_vm.$t("room.toolbar.exit")))
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4 h-100 pl-0 pr-0 d-flex justify-content-end" },
        [
          _c("div", { staticClass: "d-flex w-100 justify-content-end" }, [
            _vm.isntMovil
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-md-auto text-center pl-0 pr-0 pl-sm-2 pr-sm-2 d-none d-md-block"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-screen text-center w-100 w-sm-auto h-100 pl-sm-3 pr-sm-3 d-flex flex-column align-items-center justify-content-center",
                        class: { active: _vm.statusScreenShare },
                        on: {
                          click: function($event) {
                            return _vm.$emit("handleScreenShare")
                          }
                        }
                      },
                      [
                        _c("svgicon", {
                          staticClass: "v-btn-icon",
                          attrs: { name: "screen-share", original: "" }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "v-btn-txt d-none d-sm-block" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.statusScreenShare
                                  ? _vm.$t("room.toolbar.screenShare.m")
                                  : _vm.$t("room.toolbar.screenShare.u")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-auto text-center h-100 pl-0 pr-0 pl-sm-1 pr-sm-1 d-flex"
              },
              [
                _c("div", { staticClass: "w-100 h-100" }, [
                  _vm.btnShowMore >= _vm.toolbarActions.focus
                    ? _c("div", { staticClass: "btn-hover" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-more text-center w-100 w-sm-auto h-100 pl-3 pr-3 d-flex flex-column align-items-center justify-content-center",
                      class: {
                        active: _vm.btnShowMore >= _vm.toolbarActions.focus
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleAction("btn-more")
                        }
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "v-btn-icon",
                        attrs: { name: "ellipsis", color: "#fff" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "v-btn-txt" }, [
                        _vm._v(_vm._s(_vm.$t("room.toolbar.more")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "btn-more",
                    staticClass: "input-hide",
                    attrs: { type: "text", readonly: "" },
                    on: {
                      focus: function($event) {
                        _vm.btnShowMore = _vm.toolbarActions.focus
                      },
                      blur: function($event) {
                        return _vm.blurAction("btn-more")
                      }
                    }
                  })
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "session-details" } }, [
        _vm.btnShowMore == _vm.toolbarActions.focus ||
        _vm.btnShowMore == _vm.toolbarActions.hover
          ? _c(
              "nav",
              {
                key: "menu-more",
                staticClass: "toolbar-menu-more p-1",
                on: {
                  mouseover: function($event) {
                    return _vm.hoverAction("btn-more")
                  },
                  mouseleave: function($event) {
                    return _vm.leaveAction("btn-more")
                  }
                }
              },
              [
                _c("ul", { staticClass: "pl-0 mb-0 w-100" }, [
                  _vm.isntMovil
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "toolbar-btn-menu pt-1 pb-1 d-flex align-items-center d-block d-md-none",
                          on: {
                            click: function($event) {
                              _vm.btnShowMore = _vm.toolbarActions.click
                              _vm.blurAction("btn-more")
                              _vm.$emit("handleScreenShare")
                            }
                          }
                        },
                        [
                          _c("svgicon", {
                            staticClass: "toolbar-btn-icon",
                            attrs: { name: "screen-share" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "toolbar-btn-txt" }, [
                            _vm._v(
                              _vm._s(
                                _vm.statusScreenShare
                                  ? _vm.$t("room.toolbar.screenShare.m")
                                  : _vm.$t("room.toolbar.screenShare.u")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "toolbar-btn-menu pt-1 pb-1 d-flex align-items-center",
                      on: {
                        click: function($event) {
                          _vm.btnShowMore = _vm.toolbarActions.click
                          _vm.blurAction("btn-more")
                          _vm.$emit("toggleFullScreen")
                        }
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "toolbar-btn-icon",
                        attrs: {
                          name: _vm.statusFullScreen ? "contract2" : "expand2"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "toolbar-btn-txt" }, [
                        _vm._v(
                          _vm._s(
                            _vm.statusFullScreen
                              ? _vm.$t("room.toolbar.fullScreen.m")
                              : _vm.$t("room.toolbar.fullScreen.u")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "toolbar-btn-menu pt-1 pb-1 d-flex align-items-center",
                      on: {
                        click: function($event) {
                          _vm.btnShowMore = _vm.toolbarActions.click
                          _vm.blurAction("btn-more")
                          _vm.$emit("openSettings")
                        }
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "toolbar-btn-icon",
                        attrs: { name: "camera" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "toolbar-btn-txt" }, [
                        _vm._v(_vm._s(_vm.$t("room.modalSettings.t")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "toolbar-btn-menu l-top d-flex align-items-center position-relative",
                      on: {
                        click: function($event) {
                          _vm.submenu =
                            _vm.submenu == _vm.subMenus.help
                              ? _vm.subMenus.default
                              : _vm.subMenus.help
                        }
                      }
                    },
                    [
                      _c("svgicon", {
                        staticClass: "toolbar-btn-icon",
                        attrs: { name: "info" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "toolbar-btn-txt" }, [
                        _vm._v(_vm._s(_vm.$t("room.toolbar.help.t")))
                      ]),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "bound" } }, [
                        _vm.submenu == _vm.subMenus.help
                          ? _c(
                              "nav",
                              { staticClass: "toolbar-submenu-more p-1" },
                              [
                                _c("ul", { staticClass: "pl-0 mb-0 w-100" }, [
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "toolbar-btn-submenu pt-1 pb-1 d-flex align-items-center",
                                      on: {
                                        click: function($event) {
                                          return _vm.btnHelp("docs")
                                        }
                                      }
                                    },
                                    [
                                      _c("svgicon", {
                                        staticClass: "toolbar-btn-icon",
                                        attrs: { name: "register" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "toolbar-btn-txt" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("header.helpSub.docs.t")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "toolbar-btn-submenu pt-1 pb-1 d-flex align-items-center",
                                      on: {
                                        click: function($event) {
                                          return _vm.btnHelp("support")
                                        }
                                      }
                                    },
                                    [
                                      _c("svgicon", {
                                        staticClass: "toolbar-btn-icon",
                                        attrs: { name: "headset" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "toolbar-btn-txt" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("header.helpSub.online.t")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "toolbar-btn-submenu pt-1 pb-1 d-flex align-items-center",
                                      on: {
                                        click: function($event) {
                                          return _vm.btnHelp("email")
                                        }
                                      }
                                    },
                                    [
                                      _c("svgicon", {
                                        staticClass: "toolbar-btn-icon",
                                        attrs: { name: "at-sign" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "toolbar-btn-txt" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("header.helpSub.email.t")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }