/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indent-increase': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 5h-15a.5.5 0 010-1h15a.5.5 0 010 1zM17.5 8h-8a.5.5 0 010-1h8a.5.5 0 010 1zM17.5 11h-8a.5.5 0 010-1h8a.5.5 0 010 1zM17.5 14h-8a.5.5 0 010-1h8a.5.5 0 010 1zM17.5 17h-15a.5.5 0 010-1h15a.5.5 0 010 1zM2.5 14a.5.5 0 01-.5-.5v-6a.5.5 0 01.8-.4l4 3a.5.5 0 010 .8l-4 3a.5.5 0 01-.3.1zM3 8.5v4l2.667-2L3 8.5z"/>'
  }
})
