<template>
	<div id="sign-up" class="v-init-form d-flex justify-content-center align-items-center pl-0 pr-0 pt-4 pb-4">

        <div class="v-init-form-content d-flex flex-column">
            <div class="v-paper w-100 mb-4">
                <div class="v-if-head d-flex align-items-center pl-4 pr-4 pt-3 pb-3">
                    <router-link class="v-btn-prev mr-2" :to="{ name: 'email-validatation' }" name="institutionEmail" type="button" tag="button">
                        <svgicon class="v-btn-prev-icon" name="arrow-left" color="#fff" />
                    </router-link>
                    <h2 class="v-if-title mb-0">{{ $t('signUp.t') }}</h2>
                </div>

                <!-- ========== Form ========== -->
                <div class="p-4 d-flex flex-column align-items-center">
                    <form class="w-100" @submit.prevent="send()" >
                        <v-textField class="mb-3" :label="$t('signUp.form.name.label')" ref="name" :placeholder="$t('signUp.form.name.placeholder')" :options="inputOpc" v-model="form.name" :required="true"/>
					    <v-textField class="mb-3" :label="$t('signUp.form.lastName.label')" :placeholder="$t('signUp.form.lastName.placeholder')" :options="inputOpc" v-model="form.last_name" :required="true"/>
					    <v-select class="mb-3" :label="$t('signUp.form.gender.label')" :defaultOpc="$t('signUp.form.gender.placeholder')" :options="inputOpc" v-model="form.gender" :opc="gender" fieldVal="val" fieldTxt="txt" :required="true" />
					    <v-datePicker class="mb-3" :label="$t('signUp.form.birthday')" placeholder="01/02/1994" :options="inputOpc" v-model="form.birthday" :required="true" />
					    <v-select class="mb-3" :label="$t('signUp.form.campus.label')" :defaultOpc="$t('signUp.form.campus.placeholder')" :options="inputOpc" v-model="form.university_id" :opc="campuses" fieldVal="id" fieldTxt="name":required="true"  />	
					    <v-select class="mb-3" :label="$t('signUp.form.career.label')" :defaultOpc="$t('signUp.form.career.placeholder')" :options="inputOpc" v-model="form.career_id" :opc="carrers" :disabled="form.university_id==''" fieldVal="id" fieldTxt="name" :required="true" />	
					    <v-select class="mb-3" :label="$t('signUp.form.period.label')" :defaultOpc="$t('signUp.form.period.placeholder')" :options="inputOpc" v-model="form.period_id" :opc="semesters" :disabled="form.university_id==''" fieldVal="id" fieldTxt="period" :required="true"  />	
					    <v-textField class="mb-2" :label="$t('signUp.form.password.label')" maxlength="50" :placeholder="$t('signUp.form.password.placeholder')" :options="inputOpc" v-model="form.password" type="password" :iconEye="true" :required="true"/>
						<span class="v-if-alert d-flex align-items-center mb-4" color="gray"><svgicon class="v-if-icon-info mr-1" name="info" color="#9b9b9b" /> {{ $t('signUp.form.password.alert') }}</span>

                        <div class="v-if-checkbox d-flex align-items-center mb-3">
					        <input class="mr-2" type="checkbox" v-model="terms" required>
					        <label class="txt mb-0" v-html="$t('signUp.legalHtml')"></label>
					    </div>

                        <v-btn class="w-100" fontSize="1rem" fontWeight="400" :txt="$t('signUp.btn')" type="submit" height="40px" name="signUp" :spinner="sending" :disabled="$v.$invalid" />
                    </form>
                </div>
            </div>

            <!-- ========== CopyRight ========== -->
			<div class="text-center">
                <p class="v-if-copyright mb-2">{{ $t('copyRight', { year: 2022 }) }}</p>
				<svgicon class="v-if-link-logo" name="logo" color="#a4a4a4" />
			</div>
        </div>

		<!-- ========== Select language ========== -->
		<v-select-lang />
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
	export default{
		data(){
			return{
				terms: false,
				sending: false,
				form : {
					email: '',
					name: '',
					last_name: '',
					gender: '',
					birthday: '',
                    university_id: '',
                    career_id: '',
                    period_id: '',
                    password: ''	
				},
				campuses: [],
				carrers: [],
				semesters: [],
                inputOpc: {
                    styles: {
                        label: {
							fontWeight: 500,
                            '--transform-active': 'translateY(0px) scale(0.8)'
                        }
                    }
                }
			}
		},
		computed:{
            ...mapState({ institution: state => state.auth.institution,
			              user: state => state.auth.user,
                          api: state => state.auth.api }),
            gender(){
            	return [ { val: 'F', txt: this.$t('gender.f') }, 
            	         { val: 'M', txt: this.$t('gender.m') }/*,
            	         { val: 'O', txt: this.$t('gender.o') }*/ ]
            }
		},
		created(){
            this.form.email = this.user.email;
            this.form.birthday = this.$moment('2000-01-01', 'YYYY-MM-DD').toDate();
            this.axiosRequest({ config : {
            	method: 'GET',
                url: `${this.api}/institution/${this.institution.id}/campuses`,
                params: { domain: this.user.email.split('@')[1] }
            }, error401: false}).then( resp => resp.data)
            .then( data => {
                this.campuses = data;
            }).catch( error => {
                console.log(error)
            });
		},
		mounted(){
            this.$refs.name.$el.querySelector('input').focus();
		},
		methods: {
            ...mapActions(['saveUser', 'axiosRequest']),
            send(){
            	if (!this.$v.form.$invalid && !this.sending){
            		this.sending = true;
            		this.saveUser(this.form).then( permission => {
            			this.sending = false;
            			if (permission === 'confirm-email')
                            this.$router.push({ name : 'confirm-email' }); 
            		}).catch( error => {
            			this.sending = false;
					} )
            	}
            },
            getDependences(){
            	this.axiosRequest({ config : {
            		method: 'GET',
                    url: `${this.api}/campus/${this.form.university_id}/dependencies`
            	}, error401: false}).then( resp => resp.data)
                .then( data => {
                    this.carrers = data.careers;
                    this.semesters = data.semesters;
                }).catch( error => {
                    console.log(error)
                });
            }
		},
		watch: {
            'form.university_id': function(newVal){
            	if (newVal!='')
            		this.getDependences();
            	else{
            		this.form.career_id = '';
            		this.form.period_id = '';
            	}
            }
		},
	 	validations: {
	 		terms: {
	 			sameAs: sameAs( () => true )
	 		},
            form: {
            	name: {
            		required
            	},
				last_name: {
					required
				},
				gender: {
					required
				},
				birthday: {
					required
				},
				university_id: {
					required
				},
				career_id: {
					required
				},
				period_id: {
					required
				},
				password: {
					required,
                    minLength: minLength(8),
                    maxLength: maxLength(50)
				}
            }
        }
	}
</script>