
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Action } from 'vuex-class';

	@Component
	export default class lessonplace extends Vue{
		// Required { place, icon, modality }, opcional { type,  lessonId, edit }
        @Prop({ default: () => { return {} } }) readonly options!: any;

        @State( state => state.auth.permission ) userType;

        @Action('beforeShowModal') beforeShowModal;

        get t(){
        	return (this as any).$t;
        }

        get edit(){
            if (this.options.edit != undefined)
                return this.options.edit;
            else
                return true;
        }

        get icon(){
        	if (this.options.modality == 'Online'){
                return 'camera';
        	}
        	else{
        		if (this.options.place /*&& this.userType == 'student'*/ && this.options.icon)
        			return this.options.icon;
        		else
        			return 'map-marker';
            }
        }

        get place(){
        	if (this.options.modality == 'Online')
        		return this.t('lessons.vistualRoom');
        	else{
        		if (this.options.place)
        			return this.options.place
        		else{
                    if (this.userType == 'student')
        			    return this.t('noPlace.s');
                    else
                        return this.t('noPlace.t');
                }
        	}
        }
	}
