var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "schedule-lesson" } }, [
    _c(
      "div",
      { staticClass: "row justify-content-center ml-0 mr-0 mb-5" },
      [
        _c("h2", { staticClass: "main-title mb-0 text-center w-100" }, [
          _vm._v(_vm._s(_vm.$t("schedule.title")))
        ]),
        _vm._v(" "),
        _c("v-search", { ref: "search", staticClass: "mt-4 mb-3 w-100" }),
        _vm._v(" "),
        _c("h3", { staticClass: "switch-title mb-4 text-center w-100" }, [
          _vm._v(_vm._s(_vm.$t("schedule.sub")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-4 w-100" },
          [
            _c("v-switch", {
              attrs: {
                switchBtns: _vm.switchBtns,
                disabledOpc:
                  !_vm.statusI && !_vm.statusG
                    ? "both"
                    : !_vm.statusI
                    ? 1
                    : !_vm.statusG
                    ? 2
                    : null
              },
              on: { input: _vm.changeType },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "lists-select row position-relative align-items-center pl-md-3 pr-md-3 mt-3 w-100",
            class: _vm.type == "Grupal" ? "group" : ""
          },
          [
            _c(
              "div",
              {
                staticClass: "content-list col-md d-flex justify-content-center"
              },
              [
                _c("v-selected-list-items", {
                  key: `areas-${_vm.type}`,
                  attrs: {
                    title: _vm.$t("schedule.area"),
                    setListItems: _vm.places,
                    statusSpinner: _vm.spinnerAreas,
                    statusSrollEvent: _vm.places.length !== _vm.totalPlaces
                  },
                  on: {
                    changeList: _vm.getNextPlaces,
                    input: function($event) {
                      _vm.getNextSubjects("")
                      _vm.$refs.subjects.valueSearch = ""
                    }
                  },
                  model: {
                    value: _vm.place,
                    callback: function($$v) {
                      _vm.place = $$v
                    },
                    expression: "place"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md arrow text-center" },
              [
                _c("svgicon", {
                  staticClass: "icon",
                  attrs: { name: "chevron-right" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content-list col-md d-flex justify-content-center"
              },
              [
                _c("v-selected-list-items", {
                  key: `subjects-${_vm.place.id}`,
                  ref: "subjects",
                  attrs: {
                    title: _vm.$t("schedule.subject"),
                    setListItems: _vm.subjects,
                    statusSearch: true,
                    msgEmpty: _vm.msgSubject,
                    place: _vm.place.id,
                    statusSpinner: _vm.spinnerSubjects,
                    statusSrollEvent: _vm.subjects.length !== _vm.totalSubjects,
                    disabledSearch: _vm.totalSubjects == 0
                  },
                  on: {
                    changeList: _vm.getNextSubjects,
                    doSomething: _vm.goTo
                  },
                  model: {
                    value: _vm.subject,
                    callback: function($$v) {
                      _vm.subject = $$v
                    },
                    expression: "subject"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("v-circles", {
              attrs: {
                vCircle: 1,
                bgColor: _vm.type === "Individual" ? "main" : "yellow",
                size: "300px"
              }
            }),
            _vm._v(" "),
            _c("v-circles", {
              attrs: {
                vCircle: 2,
                bgColor: _vm.type === "Individual" ? "main" : "yellow",
                size: "200px"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }