<template>
	<div class="v-slide-animated" :class="{ bound: boundAnimation, hide : !showed }" v-if="status" :style="styleSteps" ref="slide">
		<slot class="v-slide-item"></slot>
	</div>
</template>
<script>
	export default{
		props: {
			show: { type: Boolean, default: false, required: false },
            boundAnimation: { type: Boolean, default: true, required: false }
        },
		data() {
			return{
				showed: false,
                closed: false,
                status: false,
                styleSteps: ''
			}
		},
		methods: {
            getHeight(){
            	const content = this.$refs.slide.firstChild;
                 
                let height = parseInt(content.offsetHeight);
                this.styleSteps = `height:${height}px`;
                setTimeout(() => {
                    this.styleSteps = '';
                },400);
            }
		},
		updated(){
			if (this.status && !this.closed) {
				if (!this.showed && this.styleSteps == ''){
				    this.getHeight();
				}    
                else if(!this.showed){
                	this.showed = true;
                }
            }
            else if(this.showed && this.closed){
                this.getHeight();
                this.showed = false;
                setTimeout(() => {
                    this.status = false;
                    this.closed = false;
                },400);
            }
        },
        created(){
            this.status = this.show;
            this.showed = this.show;
        	this.$emit('val',this.showed);
        },
        watch: {
        	show: function(newVal){
                if (newVal)
                	this.status = true;
                else{
                	this.getHeight();
                	this.closed = true;
                }
        	},
        	status : function(newVal){
        		this.$emit('val',newVal);
        	}
        }
	}
</script>