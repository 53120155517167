
    import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
    import { State, Action, Mutation } from 'vuex-class';

    enum StepsDropdown{
        blur,
        focus,
        hover,
        click
    }

    enum StepsResults{
        none,
        spinner,
        results,
        message
    }

    @Component
    export default class schStudentsT extends Vue {
        @Prop({ default: '' }) readonly value: any;
        @Prop({ default: true }) readonly disabled!: boolean;
        @Prop({ default: 0, required: true }) readonly quote!: number;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        @State( state => state.i18n.locale ) lang;
        @Action('axiosRequest') axios;
        @Mutation('setNotification') notification;

        val: any = '';
        isEmail: boolean = false;
        emailIsValid: boolean = false;
        lastResults: any = '';
        stepsDropdown: any = StepsDropdown;
        stepsResults: any = StepsResults;
        statusDropdown: StepsDropdown = StepsDropdown.blur;
        statusResults: StepsResults = StepsResults.none;
        keyPoss: number = -1;
        delay: any = '';
        resultHover: string = '';
        results: Array<any> = [];
        arrStudents: Array<object> = [];
        validExt: Array<any> = [];
        formInviteStudent: any = {
            name: '',
            lastName: ''
        }

        get refs(): any{
            return (this as any).$refs;
        }

        get t(): any{
            return (this as any).$t;
        }

        get invalidExt(): string{
            const exts = this.validExt.reduce( (str, item, index) => {
                if((index + 1) != this.validExt.length)
                   str = `<strong>${str} ${item.name}</strong>,`;
                
                return str;
            },'');

            return this.t('scheduleT.students.alertInvalidExtHtml', { exts: exts, lastExt: `<strong>${this.validExt[this.validExt.length-1].name}</strong>` });
        }

        getStudents(search: string): void{
            this.axios({ config: {
                method: 'GET',
                url: `${this.api}/students`,
                headers: { Authorization: this.token },
                params: { search: search }
            } }).then( resp => {
                if(resp.status == 200){
                    this.results = this.filterStudent(resp.data.students);

                    this.validExt = resp.data.allowedDomains;
                }

                if(this.results.length > 0)
                    this.statusResults = this.stepsResults.results;
                else{
                    this.statusResults = this.stepsResults.message;

                    const ext = search.split('@')[1];
                    if(this.isEmail && ext){
                        
                        this.emailIsValid = (resp.data.allowedDomains.find( e => e.name == ext ) != undefined);
                    }
                }
            }).catch( err => {
                console.log(err);
                this.statusResults = this.stepsResults.message;
            })
        }

        filterStudent(students): Array<object>{
           return students.reduce( (arr, item) => {
                const s = item;

                if(this.arrStudents.find( (i: any) => i.id == s.id))
                    s.disabled = true;
                else
                    s.disabled = false;

                arr = [...arr, s];

                return arr;
            }, []);
        }

        handleStudent(student: any): void{ // Click item dropdown
            if(this.arrStudents.length >= this.quote){
                this.notification({ type: "warning", msg: this.t('scheduleT.students.studentLimit', { quote: this.quote }) });
                return;
            }

            const str = this.arrStudents.find( (s: any) => s.id == student.id );

            if(!str){
                this.arrStudents = [ ...this.arrStudents, student ];
                this.results = this.filterStudent(this.results);
                (this as any).$emit('input', this.arrStudents);
                this.statusDropdown = this.stepsDropdown.click; 
                this.blurAction();
            }
		}

        handleNotFoundStudent(): void{
            if(this.arrStudents.length >= this.quote){
                this.notification({ type: "warning", msg: this.t('scheduleT.students.studentLimit', { quote: this.quote }) });
                return;
            }

            if(!this.arrStudents.find( (s: any) => s.email == this.val.trim() )){
                const { name, lastName } = this.formInviteStudent;

                this.arrStudents = [ ...this.arrStudents, { email: this.val.trim(), fullName: `${name} ${lastName}` } ];
                this.formInviteStudent = { name: '', lastName: '' };
                this.val = '';
                (this as any).$emit('input', this.arrStudents);
                this.statusDropdown = this.stepsDropdown.click; 
                this.blurAction();
            }else{
                this.notification({ type: "warning", msg: this.t('scheduleT.students.studentAdd') });
            }
        }

        removeStudent(student: any): void{
            if(student.id){
                this.arrStudents = this.arrStudents.filter( (s: any) => s.id != student.id );
                this.results = this.filterStudent(this.results);
            }
            else
                this.arrStudents = this.arrStudents.filter( (s: any) => s.email != student.email );

            (this as any).$emit('input', this.arrStudents);
        }

        toggleAction(): void{
            if (this.statusDropdown == this.stepsDropdown.blur)
            	this.focusAction();
        }
        focusAction(): void{
            if (this.statusDropdown == this.stepsDropdown.blur || this.statusDropdown == this.stepsDropdown.focus)
            	this.refs.input.focus();
        }
        blurAction(): void{
            if (this.statusDropdown != this.stepsDropdown.hover) 
            	this.statusDropdown = this.stepsDropdown.blur;
        }
        hoverAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.focus)
        		this.statusDropdown = this.stepsDropdown.hover;
        }
        leaveAction(): void{
        	if (this.statusDropdown == this.stepsDropdown.hover) {
        		this.statusDropdown = this.stepsDropdown.focus; 
        		this.focusAction();
        	}
        }

        keyFuction(key): void{ // Focus y enter sobre el dropdown
            if(key == 'ArrowUp'  || key == 'ArrowDown' || key == 'Enter'){
			    const scrollBar = this.refs.scroll,
                      dropdown = scrollBar.$el,
				      dropdowns = this.refs.dropdownItem;
				  
                if (this.keyPoss > 0 && key == 'ArrowUp') {
				    this.keyPoss--;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop > dropdowns[this.keyPoss].offsetTop )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop - 60;
			    }
                else if (this.keyPoss < dropdowns.length-1 && key == 'ArrowDown') {
				    this.keyPoss++;
                    this.resultHover = dropdowns[this.keyPoss].id;

				    if (scrollBar.ps.lastScrollTop < ((dropdowns[this.keyPoss].offsetTop + 60) - dropdown.offsetHeight) )
					    scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop + 60;
			    }
                else if (this.keyPoss > -1 && key == 'Enter'){
                    dropdowns[this.keyPoss].click();
			    }
            }
		}

        @Watch('quote')
        reset(newVal: number, oldVal: number): void{
            if(newVal < oldVal && this.quote < this.arrStudents.length){
                this.arrStudents = [];
                this.filterStudent(this.results);
            }
        }

        @Watch('val')
        search(newVal: any, oldVal: any): void{
            const newV = newVal.trim();

            clearTimeout(this.delay);
            	if (newV.length > 2){
            		this.statusResults = this.stepsResults.spinner;
            		this.delay = setTimeout( async () => {
                        this.statusDropdown = this.stepsDropdown.focus;
                        this.isEmail =  newVal.includes('@');
            			if (newV != this.lastResults){
            				await this.getStudents(newV);

                            this.lastResults = newV;
            				this.delay = '';
            		    }else{
                            if(this.results.length > 0)
                                this.statusResults = this.stepsResults.results;
                            else
                                this.statusResults = this.stepsResults.message;
                        }
            		},700);
            	}else{
                    this.blurAction();
                    this.statusDropdown = this.stepsDropdown.none; 
                }
        }
    }
