/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list5': {
    width: 21,
    height: 14,
    viewBox: '0 0 21 14',
    data: '<g transform="translate(1 1)" _stroke="#000" _fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path pid="0" stroke-width="2.304" d="M4.5.777H18M4.5 6.027H18M4.5 11.277H18"/><circle pid="1" stroke-width="1.536" cx=".75" cy=".75" r="1"/><circle pid="2" stroke-width="1.536" cx=".75" cy="6" r="1"/><circle pid="3" stroke-width="1.536" cx=".75" cy="11.25" r="1"/></g>'
  }
})
