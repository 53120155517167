<template>
	<div class="v-btn-prev col-12">
		<router-link class="pb-0 text-left" :to="to" tag="button" v-if="type==='link'"><svgicon class="icon" name="arrow-left"></svgicon>{{ txt }}</router-link>
		<button class="pb-0 text-left" @click="click" v-else><svgicon class="icon" name="arrow-left"></svgicon>{{ txt }}</button>
	</div>
</template>
<script>
	export default{
		props: {
			txt: { type: String, default: '', required: true },
			type: { type: String, default: 'link', required: false }, // link, step
			click: { type: Function, required: false }, // Use if type step,
			to: { required: false} // Use if type link
		}
	}
</script>