/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power-crossed': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.895 3.193a.501.501 0 00-.702-.088l-7.427 5.776 3.19-7.178a.499.499 0 00-.811-.556l-10 10a.499.499 0 00.354.854h3.257L.192 17.106a.501.501 0 00.614.79l7.427-5.776-3.19 7.178a.499.499 0 00.811.557l10-10a.499.499 0 00-.354-.854h-3.257l6.564-5.105a.501.501 0 00.088-.702zM4.707 11l7.242-7.242-2.462 5.539a.498.498 0 00.073.523L8.043 11H4.707zm9.586-1l-7.242 7.242 2.462-5.539a.498.498 0 00-.038-.476c-.011-.017-.023-.032-.035-.047L10.957 10h3.336z"/>'
  }
})
