/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bucket': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17 6.5v-.019c-.005-.306-.146-.753-.77-1.174-.396-.268-.949-.504-1.643-.702a13.973 13.973 0 00-1.76-.371C12.326 3.292 10.369 0 7.5 0 6.379 0 5.793.543 5.498.999c-.481.743-.611 1.893-.392 3.431-.241.054-.473.112-.693.175-.694.198-1.246.434-1.643.702-.624.421-.765.867-.77 1.174V6.5c0 .09.012.192.044.303l1.881 11.286c.068.41.369.874.714 1.104.124.083 1.312.807 4.861.807s4.737-.724 4.861-.807c.346-.23.646-.695.714-1.104l1.881-11.286c.032-.111.044-.213.044-.303zm-1.003-.022l-.014.081c-.08.165-.469.531-1.671.875-1.279.365-2.988.567-4.812.567-.866 0-1.705-.045-2.485-.132a27.022 27.022 0 01-.394-1.243 19.727 19.727 0 01-.354-1.394 21.525 21.525 0 013.234-.231c1.025 0 2.014.064 2.91.184l.042.006c.683.093 1.311.22 1.86.377 1.301.372 1.65.769 1.684.911zM12 12.5a.5.5 0 01-1 0c.002-.152.187-.717.334-1.17.054-.165.11-.336.166-.512.056.176.112.348.166.512.148.454.332 1.019.334 1.17zM6.337 1.542C6.576 1.172 6.946 1 7.5 1c1.875 0 3.367 1.942 4.084 3.088A24.187 24.187 0 009.5 4c-1.207 0-2.372.086-3.41.246-.174-1.247-.093-2.178.247-2.704zM4.688 5.567a11.2 11.2 0 01.595-.151c.099.467.225.963.378 1.489.08.276.163.546.248.806-.438-.078-.848-.17-1.22-.277-1.202-.343-1.591-.709-1.671-.875l-.014-.081c.035-.142.383-.539 1.684-.911zm9.401 12.358a.844.844 0 01-.278.433c-.061.035-1.154.642-4.311.642s-4.251-.607-4.311-.642a.85.85 0 01-.278-.433l-1.66-9.959a7.25 7.25 0 001.162.429c.556.159 1.184.288 1.863.386.402 1.106.746 1.876.768 1.924a.5.5 0 00.912-.41c-.004-.01-.249-.557-.564-1.385a24.796 24.796 0 003.565.048c-.1.613-.357 1.401-.573 2.062-.252.773-.384 1.19-.384 1.48 0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5c0-.29-.132-.707-.384-1.48-.226-.694-.498-1.527-.587-2.151a15.437 15.437 0 002.557-.474 7.384 7.384 0 001.162-.429l-1.66 9.959z"/>'
  }
})
