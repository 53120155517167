/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand4': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 18h-16C.673 18 0 17.327 0 16.5v-12C0 3.673.673 3 1.5 3h16c.827 0 1.5.673 1.5 1.5v12c0 .827-.673 1.5-1.5 1.5zM1.5 4a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-16z"/><path pid="1" d="M16.5 5h-3a.5.5 0 000 1h1.793l-2.146 2.146a.5.5 0 00.708.707l2.146-2.146V8.5a.5.5 0 001 0v-3a.5.5 0 00-.5-.5zM5.5 15H3.707l2.146-2.146a.5.5 0 00-.707-.707L3 14.293V12.5a.5.5 0 00-1 0v3a.5.5 0 00.5.5h3a.5.5 0 000-1z"/>'
  }
})
