
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State, Mutation, Action } from 'vuex-class';

	@Component
	export default class notificationList extends Vue{
        @Prop({ type: Boolean, default: false, required: false }) readonly statusSeeAll!: boolean;
        @Prop({ type: String, default: undefined, required: false }) readonly maxHeight!: string | undefined;
        @State('contentSize') contentSize;
        @State( state => state.auth.permission ) userType;
        @State( state => state.auth.user.university_id ) universityId;
        @State( state => state.auth.user.id ) userId;
        @State( state => state.notifications.wait ) wait;
        @State( state => state.notifications.lesson ) lesson;
        @State( state => state.notifications.activeLists ) activeLists;
        @State( state => state.notifications.lastest ) lastest;
        @State( state => state.notifications.lastVisible ) lastVisible;
        @State( state => state.notifications.list ) notifications;
        @State( state => state.notifications.missing ) missing;
        @State( state => state.notifications.total ) totalNof;
        @State('grid') grid;
        @State( state => state.menu.compact ) statusMenu;
        @Mutation('handleWaitNotification') handleWait;
        @Mutation('handleActiveNotifications') handleCount;
        @Mutation('handleLessonNotification') handleLessonId;
        @Mutation('handleNotificationSeen') handleSeen;
        @Mutation('clearnotifications') clearnotifications;
        @Action('getLastNotifications') getLast;
        @Action('getFisrtNotifications') getFisrt;
        @Action('getNextNotifications') getNext;
        @Action('getNewsNotifications') getNews;
        @Action('resetNotificationsCount') resetCount;

        headH: number = 0;
        options: object = { suppressScrollX: true, wheelSpeed: 0.4 };
        hover: boolean = false;
        scrollD: number = 0;
        statusSubMenu: string = 'blur';

        get refs(): any{
       	    return (this as any).$refs;
        }

        get refFirebase(): any{
       	    return (this as any).$firebase.db.ref().child(`/notifications/${this.universityId}/${this.userId}/notificationsCenter`);
        }

        get listH(): string{
       	    const { headerH, windowH, paddingView }  = this.contentSize;

            if (this.maxHeight)
                return `calc(${this.maxHeight} - ${headerH}px)`;
            else{    
                const h = Math.floor(windowH - headerH - (paddingView * 2) - this.headH);
                return `${h >= 740?740:h}px`;
            }
        }

        async created(): Promise<void>{
            if (!this.wait && this.activeLists == 0) {
                this.handleWait(true);
                await this.getLast(this.refFirebase);
       	        await this.getFisrt(this.refFirebase);
                this.getNews(this.refFirebase);
                if (this.lesson && !this.statusSeeAll) this.refs.scroll.$el.scrollTop = this.lesson.scroll;
            }

            if(this.wait) this.handleWait(false);

            this.handleCount(this.activeLists + 1);
        }

        mounted(): void{
       	    this.headH = this.refs.head.offsetHeight;
            if (this.lesson && !this.statusSeeAll && !this.wait) this.refs.scroll.$el.scrollTop = this.lesson.scroll;
            this.refs.scroll.$el.addEventListener('ps-scroll-y', this.scrollDown);
        }

        beforeDestroy(): void{
            this.refs.scroll.$el.removeEventListener('ps-scroll-y', this.scrollDown);
            if (!this.wait && this.activeLists == 1){
                this.refFirebase.off();
                this.clearnotifications();
            }

            this.handleCount(this.activeLists - 1);
        }

        async scrollDown(e): Promise<void>{
            this.scrollD =  e.target.scrollTop;
            const scroll = this.refs.scroll;
            const scrollTop = e.target.scrollTop + scroll.ps.containerHeight,
                  scrollH = scroll.$el.scrollHeight - 300;

            if (!this.wait && (this.lastVisible.time !== this.lastest.time && this.missing > 0) && scrollTop >= scrollH)
                await this.getNext(this.refFirebase);
        }

        disableScroll(): void{  
            this.hover = true;
            var x = window.scrollX;
            var y = window.scrollY;
            window.onscroll = function(){ window.scrollTo(x, y) };
        }

        enableScroll(): void{ 
            this.hover = false; 
            window.onscroll = null;
        }

        send(n): void{ // Select
            if (!n.seen) {
                this.refFirebase.child(`${n.time.split('_')[1]}`).once('value', async snap => {
                    const { seen } = snap.val();
                    if(!seen){
                        await this.refFirebase.child(`${n.time.split('_')[1]}`).update({ seen: 1 }).then( async () => {
                            await (this as any).$firebase.db.ref().child(`/total_counter/${this.universityId}/${this.userId}/notifications/${this.userType}_counter`).once('value', async snap => {
                                if(snap.val() >= 1){
                                    await (this as any).$firebase.db.ref().child(`/total_counter/${this.universityId}/${this.userId}/notifications`).update({ [`${this.userType}_counter`]: (this as any).$firebase.firebase.database.ServerValue.increment(-1) });
                                }
                            });
                            const i = this.notifications.findIndex( i => i.time == n.time );
                            this.handleSeen(i);
                        } );
                    }
                });
            }

            this.redirect({ id: n.lessonId, key: n.key, time: parseInt(n.time.split('_')[1]), scroll: this.scrollD });
        }

        resetCounter(): void{
            this.resetCount(this.refFirebase);
            this.statusSubMenu = 'click';
            this.blurAction();

            this.$parent.$emit('close', { action: () => {} }); // Component event listener
        }

        redirect(lesson: object | undefined): void{      
            this.handleLessonId(lesson);  
            this.statusSubMenu = 'click';
            this.blurAction();

            this.$parent.$emit('close', { action: () => { // Component event listener
                this.handleWait(true);
                this.$router.push({ name: `notifications-${this.userType}` });
            } });
        }

        toggleAction(): void{
            if (this.statusSubMenu == 'blur')
                this.focusAction();
            else
                this.blurAction();
        }
        focusAction(): void{
            if (this.statusSubMenu == 'blur' || this.statusSubMenu == 'focus')
                this.refs.input.focus();
        }
        blurAction(): void{
            if (this.statusSubMenu != 'hover') 
                this.statusSubMenu = 'blur';
        }
        hoverAction(): void{
            if (this.statusSubMenu == 'focus')
                this.statusSubMenu = 'hover';
        }
        leaveAction(): void{
            if (this.statusSubMenu == 'hover') {
                this.statusSubMenu = 'focus'; 
                this.focusAction();
            }
        }
	}
