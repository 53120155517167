var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-file-prevew", class: { active: _vm.show } },
    [
      _c("div", {
        staticClass: "blurColor",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "v-preview-content",
          style: {
            maxWidth: _vm.paperSize.maxWidth,
            maxHeight: _vm.paperSize.maxHeight
          }
        },
        [
          _c("div", { staticClass: "v-preview-header pl-4 pr-4" }, [
            _c(
              "div",
              {
                ref: "contentInfo",
                staticClass:
                  "d-flex align-items-center justify-content-between h-100"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "v-preview-info col",
                    style: { maxWidth: _vm.infoW }
                  },
                  [
                    _c("svgicon", {
                      staticClass: "v-info-icon",
                      attrs: { name: _vm.options.icon }
                    }),
                    _vm._v(" "),
                    _c("v-hover-txt", {
                      attrs: {
                        fontSize: "18px",
                        fontWeight: "500",
                        txt: _vm.options.name
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "v-file-size d-block" }, [
                      _vm._v(_vm._s(_vm.options.size))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "actions",
                    staticClass: "v-preview-actions d-flex align-items-center"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content-other-action pl-3 pr-3" },
                      [
                        !_vm.chatFile
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-other",
                                style: {
                                  "--hover-color":
                                    _vm.permission == "tutor"
                                      ? "#005E58"
                                      : "#2188FC"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "download",
                                      _vm.options.file
                                    )
                                  }
                                }
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "i-other",
                                  attrs: { name: "download3" }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "hover-txt" }, [
                                  _vm._v(_vm._s(_vm.$t("chat.download")))
                                ])
                              ],
                              1
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn-other cancel",
                                style: {
                                  "--hover-color":
                                    _vm.permission == "tutor"
                                      ? "#005E58"
                                      : "#2188FC"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cp-spinner position-relative"
                                  },
                                  [
                                    _c("v-spinner", {
                                      attrs: { height: "100%", width: "100%" }
                                    }),
                                    _vm._v(" "),
                                    _c("svgicon", {
                                      staticClass: "i-other",
                                      attrs: { name: "download3" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "content-btn-close text-center pt-1 pb-1 pl-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn-close",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [
                            _c("svgicon", {
                              staticClass: "i-cross",
                              attrs: { name: "cross2" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "v-preview d-flex justify-content-center align-items-center position-relative",
              class: {
                "p-4":
                  _vm.options.icon == "file-video" ||
                  _vm.options.icon == "file-audio" ||
                  _vm.options.icon == "image"
              }
            },
            [
              _vm.statusPreview == "loading"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "content-spinner d-flex justify-content-center align-items-center"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.options.icon == "pdf" ||
              _vm.options.icon == "doc" ||
              _vm.options.icon == "slide" ||
              _vm.options.icon == "txt" ||
              _vm.options.icon == "sheet"
                ? _c("iframe", {
                    key: _vm.refresh,
                    ref: "iframe",
                    staticStyle: {
                      border: "none",
                      width: "100%",
                      height: "100%"
                    },
                    attrs: {
                      src: `https://docs.google.com/viewer?url=${_vm.urlFile}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`,
                      frameborder: "0"
                    },
                    on: { load: _vm.load }
                  })
                : _vm.options.icon == "file-video" &&
                  _vm.statusPreview != "error"
                ? _c(
                    "video",
                    { attrs: { controls: "", controlsList: "nodownload" } },
                    [
                      _c("source", {
                        attrs: { src: _vm.urlFile },
                        on: { load: _vm.load, error: _vm.unload }
                      })
                    ]
                  )
                : _vm.options.icon == "file-audio" &&
                  _vm.statusPreview != "error"
                ? _c(
                    "audio",
                    { attrs: { controls: "", controlsList: "nodownload" } },
                    [
                      _c("source", {
                        attrs: { src: _vm.urlFile },
                        on: { load: _vm.load, error: _vm.unload }
                      })
                    ]
                  )
                : _vm.options.icon == "image" && _vm.statusPreview != "error"
                ? _c("img", {
                    key: _vm.refresh,
                    attrs: { src: _vm.urlFile },
                    on: { load: _vm.load, error: _vm.unload }
                  })
                : _vm.statusPreview == "error"
                ? _c("p", { staticClass: "fileNotFound" }, [
                    _vm._v(_vm._s(_vm.$t("chat.fileNotFound")))
                  ])
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }