
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class Users extends Vue{
        @Prop({ type: Array, default: [] }) readonly remoteRosters!: Array<object>
		@Prop({ type: Object, default: {} }) readonly currentRoster!: any
        @Prop({ type: String, default: null }) readonly pin!: string | null;
		@Prop({ type: Object, default: () => { return {} } }) readonly roster!:  object;

        @State( state => state.auth.permission ) permission;
	}
