/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-up': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 15a.5.5 0 00.853.354l8.646-8.646 8.646 8.646a.5.5 0 00.707-.707l-9-9a.5.5 0 00-.707 0l-9 9a.498.498 0 00-.146.354z"/>'
  }
})
