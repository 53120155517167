/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save-arrow': {
    width: 40,
    height: 25,
    viewBox: '0 0 40 25',
    data: '<path pid="0" d="M26.142 7.147c2.686 1.926 4.852 4.422 6.694 7.137 1.35 2.082 2.517 4.303 3.533 6.554-.032-2.598.188-5.246.742-7.802.189-.922 2.263.29 2.096 1.052a36.12 36.12 0 00-.6 10.43c.085.973-1.458.335-1.908-.358a1.13 1.13 0 01-.282-.102c-2.359-1.052-4.748-1.994-7.21-2.766-.505-.162-1.108-.565-1.216-1.118-.107-.552.47-.56.863-.428 2.189.72 4.296 1.561 6.413 2.452-2.22-4.934-4.672-9.979-8.974-13.466-2.182-1.764-4.76-3.138-7.382-4.19-2.5-.973-5.165-1.705-7.862-2.067-3.08-.443-6.226-.404-9.302-.015-.567.058-1.17-.345-1.46-.758C.065 1.381-.144.848.423.79 6.264.02 12.326.664 17.822 2.722c2.853 1.164 5.755 2.58 8.32 4.425z" _fill="#72BD00" fill-rule="nonzero"/>'
  }
})
