<template>
	<div id="dashboard" class="pt-4">

		<!-- ================ Banners ================== -->
        <transition name="bound" mode="out-in">
            <div class="lesson-spinner w-100 p-4 text-center mb-4" key="spinner" v-if="spinner && news.length === 0">
                <v-spinner />
            </div>
            <carousel class="mb-4 dashboard-slick" ref="slickNews" :key="`news-${updateNews}`" :perPageCustom="[[0, 1]]" :autoplay="true" :speed="2000" :autoplayTimeout="5000" :loop="true" v-else>
                <slide v-for="item in news" :key="item.id">
                    <a :href="item.link" target="_blank">
                        <div class="item d-flex align-items-center" :style="{ background: `url(${item.picture})center center / cover no-repeat` }">
                            <div class="bgColor" :style="{ background : `linear-gradient(to right, ${item.color}, ${$hexToRgba(item.color,0.9)}, ${$hexToRgba(item.color,0.7)}, ${$hexToRgba(item.color,0.5)}, ${$hexToRgba(item.color,0.3)}, ${$hexToRgba(item.color,0,1)}, transparent)` }" ></div>
                            <div class="d-flex flex-column pt-4 pl-sm-5 pl-3 w-100 content">
                                <h3 class="mb-0 w-100 title">{{ item.title }}</h3>
                                <p class="mb-0 w-100 descrip" >{{ item.subtitle }}</p>
                            </div>
                        </div>
                    </a>
                </slide>  
            </carousel>
        </transition>
		

		<!-- ================ Pending Lesson Requests ================== -->
        <section class="s-lessons mb-4" ref="lessons">
            <div class="row ml-n2 mr-n2 align-items-center justify-content-between mr-0">
                 <div class="col-auto pl-2 pr-2">
                    <h4 class="mb-0 d-title d-flex align-items-center">
                        <div class="point bg-lesson-pending mr-1"></div> 
                        {{ $t('dashboard.pending') }}
                        <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoPendingT')" />
                    </h4>
                 </div>
                 <div class="col d-none d-sm-block pl-2 pr-4 text-right position-relative">
                    <router-link class="btn-lessons position-static color-tutor-main pr-0" :to="{ name: 'lessons-tutor' }">{{ $t('lessons.btnAll') }} <svgicon class="icon fill-green-main" name="chevron-right"></svgicon></router-link>
                </div>
            </div>
            <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && lessonRequests.length === 0">
                   <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="lessonRequests.length === 0 && !spinner" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt2" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-2" v-html="$t('msgEmpty.msgPending')"></p>
                        <p class="txt2 mb-0">{{ $t('msgEmpty.will') }}</p>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="lessons" tag="div" v-else>
                    <v-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in lessonRequests"/>  
                </transition-group>
            </transition>
        </section>

        <!-- ================ Upcoming Lessons ================== -->
        <section class="s-lessons mb-4">
            <div class="row ml-n2 mr-n2 align-items-center justify-content-between mr-0">
                <div class="col-auto pl-2 pr-2">
                    <h4 class="mb-0 d-title d-flex align-items-center">
                        <div class="point bg-lesson-upcoming mr-1"></div> 
                        {{ $t('dashboard.upcoming') }}
                        <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoUpcomingT')" />
                    </h4>
                 </div>
                 <div class="col d-none d-sm-block pl-2 pr-4 text-right position-relative">
                    <router-link class="btn-lessons position-static color-tutor-main pr-0" :to="{ name: 'lessons-tutor' }">{{ $t('lessons.btnAll') }} <svgicon class="icon fill-green-main" name="chevron-right"></svgicon></router-link>
                </div>
            </div>
             <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && upcoming.length === 0">
                   <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="upcoming.length === 0 && !spinner" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt1" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-2" v-html="$t('msgEmpty.msgUpcoming')"></p>
                        <p class="txt2 mb-0">{{ $t('msgEmpty.will') }}</p>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="lessons" tag="div" v-else>
                    <v-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in upcoming"/>  
                </transition-group>
             </transition>
        </section>

        <!-- ================ Tutoring Statistics ================== -->
        <section class="s-lessons">
            <div class="row">
                <div class="col">
                    <h4 class="mb-0 d-title">
                        <!--<div class="point" color="blue-main"></div>--> 
                        {{ $t('dashboard.statistics') }}
                        <v-more-info :txtCloud="$t('moreInfo.infoStatistics')" />
                    </h4>
                 </div>
                 <!--<div class="col-6 pr-4 text-right">
                      <router-link class="btn-lessons" to="/lessons/manage">All Lessons <svgicon class="icon" name="chevron-right"></svgicon></router-link>
                 </div>-->
            </div>
            <div class="tutorin-static-info mt-3 mb-5">
                <div class="row mr-0 ml-0 align-items-center">
                    <div class="content-this-week line position-relative d-flex justify-content-start align-items-center col-xl col-sm-6 col-12 mb-3 mb-sm-0" :class="`${ !menu.compact?'col-lg-6': 'col-lg' }`">
                        <div class="this-week">
                            <svgicon class="icon" name="calendar-full" />
                            <span class="w-100 txt1">{{ $t('dashboard.this') }}</span>
                            <span class="w-100 txt2">{{ $t('dashboard.week') }}</span>
                        </div>
                    </div>
                    <div class="content-completed line position-relative d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12" :class="`${ !menu.compact?'col-lg-6': 'col-lg justify-content-lg-center' }`">
                        <div class="completed">
                            <svgicon class="icon" name="checkmark-circle" />
                            <span class="w-100 txt1 mb-2">{{ $t('dashboard.completed') }}</span>
                            <span class="w-100 txt2">{{ $t('dashboard.lessons') }}</span>
                        </div>
                        <span class="cont ml-1">{{ statistics.completedLessons || 0 }}</span>
                    </div>
                    <div class="content-hours line position-relative d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12" :class="`${ !menu.compact?'col-lg-6': 'col-lg justify-content-lg-center' }`">
                        <div class="hours">
                            <svgicon class="icon" name="timer" />
                            <span class="w-100 txt1 mb-2">{{ $t('dashboard.tutoring') }}</span>
                            <span class="w-100 txt2">{{ $t('dashboard.hours') }}</span>
                        </div>
                        <span class="cont ml-1">{{ statistics.tutoringHours || 0 }}</span>
                    </div>
                    <div class="content-canceled d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12" :class="`${ !menu.compact?'col-lg-6': 'col-lg justify-content-lg-center' }`">
                        <div class="canceled">
                            <svgicon class="icon" name="cross-circle" />
                            <span class="w-100 txt1 mb-2">{{ $t('dashboard.canceled') }}</span>
                            <span class="w-100 txt2">{{ $t('dashboard.requested') }}</span>
                        </div>
                        <span class="cont ml-1">{{ statistics.cancelledLessons || 0 }}</span>
                    </div>
                </div>
            </div>
        </section>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	export default{
		computed:{
            ...mapState(['auth','currentLesson', 'lessonChanges','menu', 'userFirebase']),
            ...mapGetters(['events', 'modalVal'])
		},
		data(){
			return{
                spinner: false,
                statusJoinedStudents: false,
                statusTutoringPlace: false,
                news: [],
                lessonRequests: [],
                upcoming: [],
                joinedStudents: [],
                statistics: {},
                updateNews: 0,
                cardW: undefined
			}
		},
        methods: {
            ...mapMutations(['handleServerTime']),
            ...mapActions(['axiosRequest']),
            getDashboard(action=''){
                this.spinner = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/dashboard/t`,
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data )
                .then( data => {
                    this.handleServerTime(data.serverTime);
                    if (action=='') {
                        this.news = data.news;
                        this.lessonRequests = data.pendingLessons;
                        this.upcoming = data.upcomingLessons;
                        this.statistics = data.statistics;
                    }
                    else if(action === 'changedPlace' || action === 'changedTopic'){
                        this.lessonRequests = data.pendingLessons;
                        this.upcoming = data.upcomingLessons;
                        this.statistics = data.statistics;
                    }
                    else if(action === 'pending')
                        this.lessonRequests = data.pendingLessons;
                    else if(action === 'canceled' || action === 'upcoming' || action === 'userGroupJoined' || action === 'userGroupLeft'){
                        this.lessonRequests = data.pendingLessons;
                        this.upcoming = data.upcomingLessons;
                    }
                    this.spinner = false;
                } )
                .catch( error => {
                    this.spinner = false;
                } );
            },
            getCardW(){
                this.cardW = this.$refs.lessons.offsetWidth;
            },
            getScrollbarWidth() {
                // Creating invisible container
                const outer = document.createElement('div');
                outer.style.visibility = 'hidden';
                outer.style.overflow = 'scroll'; // forcing scrollbar to appear
                outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
                document.body.appendChild(outer);

                // Creating inner element and placing it in the container
                const inner = document.createElement('div');
                outer.appendChild(inner);

                // Calculating difference between container's full width and the child width
                const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

                // Removing temporary elements from the DOM
                outer.parentNode.removeChild(outer);

                return scrollbarWidth;
            }
        },
        created(){
            this.getDashboard();
        },
        mounted(){
            this.cardW = this.$refs.lessons.offsetWidth - this.getScrollbarWidth();
            window.addEventListener('resize', this.getCardW);
            this.$watch('events', val => {
                const lessonLS = JSON.parse(this.$localStorage.get('lesson'));
                if (val && lessonLS.lesson){
                    const lesson = val.split('_');
                    if(lesson[1] === 'ongoing'){
                        this.$router.push({ name: 'lessons-tutor' });
                    }else{
                        this.getDashboard(lesson[1]);
                    }
                }
            });
        },
        beforeDestroy(){
            window.removeEventListener('resize', this.getCardW);
        },
        watch:{
            modalVal: function(newVal, oldVal){
                if(oldVal.modal == 'v-modal-crud' && this.currentLesson.lesson){
                    if(!this.userFirebase){
                        let lessonIndex = this.lessonRequests.findIndex( item => item.id === lesson.id );
                        if (oldVal.type === 'cancel'){
                            if (lessonIndex != -1)
                                delete this.lessonRequests[lessonIndex];
                            else{
                                lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                                if(lessonIndex != -1)
                                    delete this.upcoming[lessonIndex];
                            }
                        }
                        else if(oldVal.type === 'start'){
                            if (lessonIndex != -1)
                                this.$router.push({ name: 'lessons-tutor' });
                        }
                        else{
                            if (lessonIndex != -1){
                                this.upcoming.unshift({ ...this.lessonRequests[lessonIndex], status: 'Confirmada' });
                                delete this.lessonRequests[lessonIndex];
                            }
                        }
                    }
                }
            },
            lessonChanges: function(newVal){
                if (newVal) {
                    const { data, lesson } = newVal;
                    let lessonIndex = this.lessonRequests.findIndex( item => item.id === lesson.id );
                    if (lessonIndex != -1)
                        this.lessonRequests[lessonIndex][data] = lesson.val;
                    else{
                        lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                        if(lessonIndex != -1)
                            this.upcoming[lessonIndex][data] = lesson.val;
                    }
                }
            },
            'menu.compact': function(newVal){
                setTimeout(()=> {
                    this.getCardW();
                    if (this.$refs.slickNews)
                        this.$refs.updateNews += 1;
                },500);
            }
        }
	}
</script>