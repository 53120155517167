import Vue from 'vue';
import router from '../../router.js';

const axiosRequest = ({ state, commit, dispatch }, payload) => {
	const error401 = typeof payload.error401 !== 'undefined'?payload.error401:true; // IF value is true redirect to login

    if(payload.config.headers) // Add current lang to api header
        payload.config.headers['Accept-Language'] = state.i18n.locale || 'en_US'; 
    else
        payload.config.headers = { 'Accept-Language' : state.i18n.locale || 'en_US' };

	return new Promise((resolve, reject) => {
        axios(payload.config).then( resp => resolve(resp) ).catch( err => {
        	if (err.response) {
                const pm = state.auth.permission;
        		if (err.response.status == 401 && error401 && state.auth.token !== '' && pm !== ''){
                    commit('setContToken', 5);
        			Vue.localStorage.remove('tokenLinkWebApp');
                    Vue.localStorage.remove('tokenBrowser');
                    Vue.localStorage.remove('tokenPushNotification');
                    Vue.localStorage.remove('statusModalNotifications');
                    dispatch('endNotifications');
                    commit('setTokenNotifications', null);
                    commit('setTokenBrowser', null);
                    dispatch('stopEvents');
                    commit('logOut');
                    dispatch('signOutFirebase');
                    //if (router.history.current.name != `dashboard-${pm}`)
                        router.push({ name : 'email-validatation', query: { next: encodeURIComponent(router.history.current.fullPath) } });
                    /*else
                        router.push({ name : 'email-validatation' });*/
                }
        		else if(err.response.status == 429)
        			commit('setNotification',{ type: 'warning', msg: Vue.prototype.$t('error429') });
            }
            reject(err);
        })
	})
}

const getBrowserData = ({ commit }) => {
	const infoBrowser = navigator.userAgent,
	      versionBrowser = navigator.appVersion;

	let version = ''+parseFloat(versionBrowser),
	    name = navigator.appName,
	    engine = navigator.product || '',
	    OP_C = false,
	    offset, ix;

    if ( ( offset = infoBrowser.indexOf("OPR/")) !=-1 ) { // Opera 15+
    	OP_C = true;
        name = "Opera";
        version = infoBrowser.substring(offset+4);
    }
    else if ( ( offset = infoBrowser.indexOf("Opera")) !=-1 ) { // Older Opera
    	OP_C = true;
        name = "Opera";
        version = infoBrowser.substring(offset+6);
        if ((offset=infoBrowser.indexOf("Version"))!=-1)
        	version = infoBrowser.substring(offset+8);
    }
    else if ( (offset = infoBrowser.indexOf("MSIE")) !=-1 ) { // IE
    	name = "Internet Explorer";
    	version = infoBrowser.substring(offset+5);
    }
    else if ( (offset = infoBrowser.indexOf("Edge")) !=-1 ) { // Edge
    	name = "Edge";
    	version = infoBrowser.substring(offset+5);
    }
    else if ( (offset = infoBrowser.indexOf("Chrome")) !=-1) { // Chrome
    	OP_C = true;
        name = "Chrome";
        version = infoBrowser.substring(offset+7);
    }
    else if ( (offset = infoBrowser.indexOf("Firefox"))!=-1 ) { // Firefox
    	OP_C = true;
    	name = "Firefox";
    	version = infoBrowser.substring(offset+8);
    }
    else if ( (offset = infoBrowser.indexOf("Safari")) !=-1 ) { // Safari
    	OP_C = true;
        name = "Safari";
        version = infoBrowser.substring(offset+7);
        if ( (offset = infoBrowser.indexOf("Version") )!=-1) 
        	version = infoBrowser.substring(offset+8);
    }
    else if ( ( name = infoBrowser.lastIndexOf(' ')+1) < ( offset = infoBrowser.lastIndexOf('/')) ){ // other browser
        name = infoBrowser.substring(name, offset);
        version = infoBrowser.substring(offset+1);
        if (name.toLowerCase() == name.toUpperCase()) {
        	name = navigator.appName;
        }
    }

    // trim the fullVersion string at semicolon/space if present
    if ( (ix = version.indexOf(";") )!=-1 )
    version = version.substring(0,ix);
    if ( (ix = version.indexOf(" ")) !=-1 )
    	version = version.substring(0,ix);

    commit('setBrowser', { OP_C: OP_C, name: name, version: version, model: engine });

}

export default{
	axiosRequest,
    getBrowserData
}