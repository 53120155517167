var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 d-flex flex-column" }, [
    !_vm.userMap && !_vm.isMyMsg
      ? _c("div", { staticClass: "v-chat-user w-100" }, [
          _c("p", { staticClass: "mr-1 mb-0 d-inline" }, [
            _vm._v(_vm._s(_vm.$t("room.header.student")))
          ])
        ])
      : !_vm.isMyMsg && _vm.showAvatar
      ? _c("div", { staticClass: "v-chat-user w-100" }, [
          _c("p", { staticClass: "mr-1 mb-0 d-inline" }, [
            _vm._v(_vm._s(_vm.userMap.name))
          ]),
          _vm._v(" "),
          _vm.userMap.type == "tutor"
            ? _c("div", { staticClass: "label-t" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("room.header.tutor")))])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-chat-bubble",
        class: _vm.isMyMsg
          ? `v-chat-txt-right align-self-end bgColor-${_vm.permission}-main`
          : "v-chat-txt-left align-self-start",
        attrs: { type: _vm.type },
        on: {
          click: function($event) {
            return _vm.$emit("showPreview")
          }
        }
      },
      [
        !_vm.userMap
          ? _c(
              "div",
              { staticClass: "v-chat-img n-img" },
              [
                _c("svgicon", {
                  staticClass: "icon-users",
                  attrs: { name: "group" }
                })
              ],
              1
            )
          : _vm.showAvatar
          ? _c("div", {
              staticClass: "v-chat-img",
              style: {
                background: `url(${_vm.userMap.avatar})center center / cover no-repeat`
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "v-chat-content d-flex position-relative" }, [
          _vm.msg.file
            ? _c(
                "div",
                { staticClass: "v-icon-file col-auto pl-0 pr-0" },
                [
                  _c("svgicon", {
                    staticClass: "v-icon",
                    attrs: { name: _vm.iconFile, color: "#000" }
                  }),
                  _vm._v(" "),
                  _vm.iconPreview == "magnifying2" ||
                  (_vm.iconPreview == "download3" && !_vm.chatFile)
                    ? _c(
                        "div",
                        {
                          staticClass: "v-content-ih",
                          class: _vm.isMyMsg
                            ? `bgColor-${_vm.permission}-light`
                            : null
                        },
                        [
                          _c("svgicon", {
                            staticClass: "v-icon-hover",
                            attrs: { name: _vm.iconPreview }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto pl-0 pr-0 mb-1" }, [
            _c("span", {
              staticClass: "v-msg d-block",
              domProps: { innerHTML: _vm._s(_vm.linkify()) }
            }),
            _vm._v(" "),
            _vm.type == "file"
              ? _c("span", { staticClass: "v-file-size d-block" }, [
                  _vm._v(
                    _vm._s(
                      _vm.chatFile ? `${_vm.chatFile.progress} %` : _vm.msg.size
                    )
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.type == "file" && !_vm.chatFile
            ? _c(
                "button",
                {
                  staticClass: "btn-download",
                  class: _vm.isMyMsg ? `bgColor-${_vm.permission}-main` : null,
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.downloadFile({
                        file: _vm.msg.file,
                        name: _vm.msg.text,
                        idLesson: _vm.idLesson
                      })
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "d-icon",
                    attrs: { name: "download3" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "hover-txt" }, [
                    _vm._v(_vm._s(_vm.$t("chat.download")))
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "file" && _vm.chatFile
            ? _c(
                "button",
                {
                  staticClass: "btn-cancel-download",
                  class: _vm.isMyMsg ? `bgColor-${_vm.permission}-main` : null,
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.chatFile.source.cancel()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "cv-spinner" },
                    [
                      _c("v-spinner", {
                        attrs: { height: "100%", width: "100%" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("svgicon", {
                    staticClass: "d-icon",
                    attrs: { name: "cross2" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "hover-txt" }, [
                    _vm._v(_vm._s(_vm.$t("chat.cancelDownload")))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "v-time d-block w-100" }, [
          _vm._v(_vm._s(_vm.$moment(_vm.msg.createdAt).format("LT")))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }