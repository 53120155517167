<template>
	<div class="v-check">
		<span class="title-checkbox d-block">{{ title }}:</span>
		<div class="checkbox-group">
			<div class="checkbox-item mb-1" :style="{ pointerEvents : checked(vals[index]) && dvalue.length==1?'none':'all' }" v-for=" (check,index) in items ">
				<div class="checkbox" :class="{ active : checked(vals[index]) }">
					<input type="checkbox" :id="'v-check-'+check+'-'+index" :value="vals[index]" v-model="dvalue">
				</div>
				<label class="text-checkbox mb-0" :for="'v-check-'+check+'-'+index">{{ check }}</label>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			title: { type: String, default: '', required: true },
			items: { type: Array, default: [], required: true },
			vals: { type: Array, default: [], required: true },
			value: { type: Array }
		},
		data() {
			return{
                dvalue : []
			}
		},
		methods:{
			checked(check){
            	return this.dvalue.find(val => val==check) == check;
            }
		},
		created(){		
			this.dvalue = this.value;	
		},
		watch: {
			dvalue: function(newVal){
				this.$emit('input', newVal);
			}
		}
	}
</script>