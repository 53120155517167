/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download3': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 31.51',
    data: '<g data-name="Capa 2"><path pid="0" d="M24.35 7.29C23-2.42 9.05-2.44 7.66 7.29c-10.48.92-10.09 16 .45 16.33h6.82v4.59l-3.61-2.89A1.07 1.07 0 1010 27l5.35 4.28a1.19 1.19 0 00.67.24 1.27 1.27 0 00.67-.24L22 27a1.07 1.07 0 00-1.34-1.67l-3.61 2.89v-4.6h6.82c10.55-.34 10.95-15.41.48-16.33zm-.46 14.19h-6.82v-6a1.07 1.07 0 00-2.14 0v6H8.11a6 6 0 110-12.06c.79.08 1.59-.15 1.62-1.07C10 .07 22 .06 22.27 8.35c0 .92.84 1.15 1.62 1.07 7.96.25 7.97 11.81 0 12.06z" data-name="Capa 1"/></g>'
  }
})
