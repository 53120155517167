/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.129 7.25a.5.5 0 00-.683-.183l-4.92 2.841-3.835-2.685a.5.5 0 00-.573.819l4.096 2.868.003.002.025.016.008.005.025.014.008.004.03.013a.159.159 0 00.02.007l.012.004.022.006a.164.164 0 01.011.003l.022.005a.05.05 0 00.012.002l.021.003.015.002.018.002.027.001H9.5a.525.525 0 00.056-.003h.003a.341.341 0 00.054-.01l.005-.001a.491.491 0 00.051-.015l.008-.003.047-.02.01-.005.017-.009 5.196-3a.5.5 0 00.183-.683z"/><path pid="1" d="M16.32 17.113A9.436 9.436 0 0019 10.5c0-2.37-.862-4.608-2.438-6.355l.688-.688.646.646a.498.498 0 00.708 0 .5.5 0 000-.707l-2-2a.5.5 0 00-.707.707l.646.646-.688.688C14.108 1.861 11.87.999 9.5.999s-4.608.862-6.355 2.438l-.688-.688.646-.646a.5.5 0 00-.707-.707l-2 2a.5.5 0 00.708.707l.646-.646.688.688A9.431 9.431 0 000 10.5c0 2.489.951 4.831 2.68 6.613L.646 19.147a.5.5 0 00.708.707l2.06-2.06C5.119 19.222 7.25 20 9.501 20s4.382-.778 6.087-2.206l2.059 2.059a.498.498 0 00.708 0 .5.5 0 000-.707l-2.034-2.034zM10 18.985V18.5a.5.5 0 00-1 0v.485C4.71 18.735 1.265 15.29 1.015 11H1.5a.5.5 0 000-1h-.485C1.265 5.71 4.71 2.265 9 2.015V2.5a.5.5 0 001 0v-.485c4.29.25 7.735 3.695 7.985 7.985H17.5a.5.5 0 000 1h.485c-.25 4.29-3.695 7.735-7.985 7.985z"/>'
  }
})
