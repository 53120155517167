/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bottle2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11 8.05V3c.551 0 1-.449 1-1V1c0-.551-.449-1-1-1H8c-.551 0-1 .449-1 1v1c0 .551.449 1 1 1v5.05c-1.14.232-2 1.242-2 2.45v8c0 .827.673 1.5 1.5 1.5h4c.827 0 1.5-.673 1.5-1.5v-8c0-1.207-.86-2.217-2-2.45zM8 1h3v1H8V1zm4 17.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-8C7 9.673 7.673 9 8.5 9a.5.5 0 00.5-.5V3h1v5.5a.5.5 0 00.5.5c.827 0 1.5.673 1.5 1.5v8z"/>'
  }
})
