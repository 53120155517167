var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-notification-list",
      on: { mouseover: _vm.disableScroll, mouseleave: _vm.enableScroll }
    },
    [
      _c(
        "div",
        {
          ref: "head",
          staticClass:
            "d-flex align-items-center justify-content-between pl-4 pt-4 pr-3 pb-4",
          style: { borderBottom: "1px solid #ECECE9" }
        },
        [
          _c("h2", { staticClass: "v-nl-title mb-0" }, [
            _vm._v(_vm._s(_vm.$t("notifications.t")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "v-nl-contentSub position-relative" },
            [
              _c(
                "button",
                {
                  staticClass: "v-nl-btnMark",
                  on: {
                    click: function($event) {
                      return _vm.toggleAction()
                    }
                  }
                },
                [
                  _c("svgicon", {
                    staticClass: "v-nl-btn-icon",
                    attrs: { name: "ellipsis-v" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                ref: "input",
                attrs: { type: "text", readonly: "" },
                on: {
                  focus: function($event) {
                    _vm.statusSubMenu = "focus"
                  },
                  blur: function($event) {
                    return _vm.blurAction()
                  }
                }
              }),
              _vm._v(" "),
              _vm.statusSubMenu == "focus" ||
              _vm.statusSubMenu == "hover" ||
              _vm.statusSubMenu == "click"
                ? _c("div", { staticClass: "v-nl-hover" })
                : _vm._e(),
              _vm._v(" "),
              _c("transition", { attrs: { name: "bound" } }, [
                _vm.statusSubMenu == "focus" || _vm.statusSubMenu == "hover"
                  ? _c(
                      "ul",
                      {
                        staticClass: "v-nl-subMenu nav flex-column flex-nowrap",
                        style: {
                          [_vm.grid == "xl" ||
                          (_vm.grid == "lg" && _vm.statusMenu)
                            ? "left"
                            : "right"]: "100%"
                        },
                        on: {
                          mouseover: function($event) {
                            return _vm.hoverAction()
                          },
                          mouseleave: function($event) {
                            return _vm.leaveAction()
                          }
                        }
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "v-nl-item pt-2 pr-3 pb-2 pl-3 position-relative",
                            on: {
                              click: function($event) {
                                return _vm.resetCounter()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("notifications.settings.checkAll"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.statusSeeAll
                          ? _c(
                              "li",
                              {
                                staticClass:
                                  "v-nl-item pt-2 pr-3 pb-2 pl-3 position-relative",
                                on: {
                                  click: function($event) {
                                    return _vm.redirect(undefined)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("notifications.settings.seeAll")
                                  )
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "perfect-scrollbar",
        {
          ref: "scroll",
          staticClass: "nav-submenu nav flex-column flex-nowrap",
          style: {
            height: _vm.listH,
            "--scroll-color": _vm.userType == "tutor" ? "#72BD00" : "#00C0ff"
          },
          attrs: { tag: "div", options: _vm.options }
        },
        [
          _c(
            "transition",
            { attrs: { name: "bound", mode: "out-in" } },
            [
              _vm.wait && _vm.notifications.length == 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass:
                        "h-100 d-flex flex-column align-items-center justify-content-center pl-4 pr-4"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : _vm.notifications.length == 0 && !_vm.wait
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "h-100 d-flex flex-column align-items-center justify-content-center pl-4 pr-4"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "v-nl-alert-icon position-relative mb-4"
                        },
                        [
                          _c("svgicon", {
                            staticClass: "icon-ghost",
                            attrs: { name: "ghost-fill" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "v-nl-alert-txt text-center" }, [
                        _vm._v(_vm._s(_vm.$t("notifications.alerts.empty")))
                      ])
                    ]
                  )
                : _c(
                    "transition-group",
                    {
                      key: "notifications",
                      staticClass: "h-100 pl-0 mb-0",
                      attrs: { name: "bound", tag: "ul" }
                    },
                    [
                      _vm._l(_vm.notifications, function(n) {
                        return _c("v-notification-item", {
                          key: `${n.key}_${n.time}`,
                          class: {
                            selected: _vm.lesson
                              ? n.time ==
                                  `${_vm.userType}_${_vm.lesson.time}` &&
                                !_vm.statusSeeAll
                              : false
                          },
                          attrs: { notification: n },
                          nativeOn: {
                            click: function($event) {
                              return _vm.send(n)
                            }
                          }
                        })
                      }),
                      _vm._v(" "),
                      _vm.wait
                        ? _c(
                            "li",
                            {
                              key: "spinner",
                              staticClass: "p-4 d-flex justify-content-center",
                              attrs: { height: "40px", width: "40px" }
                            },
                            [
                              _c("v-spinner", {
                                attrs: { widdth: "40px", height: "40px" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }