const setCompact = (state,payload) => {
	state.statusHour = payload;
	state.menu.compact = payload;
}

const setStatusMenuResponsive = (state,payload) => {
	state.menu.showMenuResponsive = payload;
}

const setHideText = (state,payload) => {
	state.menu.hideText = payload;
}

export default{
	setCompact,
	setHideText,
	setStatusMenuResponsive
}