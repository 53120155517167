/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rocket': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.5 9C12.122 9 11 7.878 11 6.5S12.122 4 13.5 4 16 5.122 16 6.5 14.878 9 13.5 9zm0-4c-.827 0-1.5.673-1.5 1.5S12.673 8 13.5 8 15 7.327 15 6.5 14.327 5 13.5 5zM.5 20a.499.499 0 01-.47-.67c1.539-4.231 3.157-6.377 4.808-6.377.545 0 1.054.234 1.515.694.728.728.754 1.453.648 1.933-.334 1.508-2.464 2.985-6.33 4.391a.51.51 0 01-.171.03zm4.339-6.048c-.495 0-1.078.434-1.685 1.255-.597.807-1.197 1.957-1.788 3.427 1.335-.537 2.408-1.082 3.199-1.625 1.067-.733 1.383-1.3 1.46-1.646s-.047-.677-.379-1.009c-.27-.27-.534-.401-.808-.401z"/><path pid="1" d="M19.5 0c-2.595 0-4.907.44-6.874 1.308-1.621.716-3.009 1.72-4.126 2.986-.208.236-.401.475-.58.715-.862.057-1.744.4-2.624 1.02-.745.524-1.493 1.25-2.225 2.157-1.23 1.524-1.986 3.027-2.018 3.09a.5.5 0 00.8.578c.005-.005.483-.475 1.298-.936.672-.38 1.708-.827 2.961-.906.317.811 1.224 1.781 1.659 2.216s1.405 1.342 2.216 1.659c-.079 1.253-.526 2.289-.906 2.961-.462.816-.931 1.293-.935 1.297a.501.501 0 00.577.801c.063-.032 1.566-.788 3.09-2.018.907-.732 1.633-1.48 2.157-2.225.62-.881.962-1.762 1.02-2.624.239-.178.478-.371.715-.58 1.266-1.117 2.27-2.505 2.986-4.126.868-1.966 1.308-4.279 1.308-6.874v-.5h-.5zM3.107 9.808c.953-1.383 2.41-3.098 4.052-3.637-.649 1.137-.985 2.181-1.106 2.843a7.784 7.784 0 00-2.946.794zm7.086 7.082a7.761 7.761 0 00.793-2.944c.661-.121 1.706-.457 2.842-1.105-.54 1.639-2.254 3.096-3.636 4.049zm4.851-6.14C12.964 12.585 10.81 13 10.5 13c-.002 0-.207-.004-.759-.393-.385-.271-.834-.657-1.262-1.085s-.814-.877-1.085-1.262c-.389-.552-.393-.757-.393-.759 0-.31.415-2.464 2.25-4.544 2.196-2.488 5.557-3.849 9.744-3.95-.101 4.187-1.461 7.548-3.95 9.744z"/>'
  }
})
