var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-chat-lesson" }, [
    _c(
      "h4",
      {
        staticClass: "v-chat-lesson-head mb-0 text-center pt-2 pb-2",
        class: _vm.bgTitle
      },
      [_vm._v(_vm._s(_vm.title))]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "v-chat-lesson-info p-4 position-relative" },
      [
        _c("v-lesson-photo", {
          staticClass: "ml-auto mr-auto mb-3",
          attrs: { options: _vm.lessonPhotoOpc }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "v-cl-name text-center mb-2" }, [
          _vm._v(_vm._s(_vm.name))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "v-cl-subject text-center mb-3" }, [
          _vm._v(_vm._s(_vm.lesson.subject))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row ml-n1 mr-n1 mb-2 justify-content-center" },
          [
            _c(
              "div",
              { staticClass: "col-auto pl-1 pr-1 mb-2" },
              [
                _c("v-lesson-tag", {
                  staticClass: "mb-1",
                  attrs: { type: _vm.lesson.type, options: _vm.tagTypeOpc },
                  on: {
                    handleGroup: function($event) {
                      _vm.statusMemmersList = true
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-auto pl-1 pr-1" },
              [
                _c("v-lesson-tag", {
                  staticClass: "mb-1",
                  attrs: { type: _vm.lesson.modality }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("v-lesson-schedule", {
          staticClass: "mb-2 position-relative",
          attrs: {
            options: {
              date: _vm.lesson.scheduledDate,
              startTime: _vm.lesson.scheduledTime,
              endTime: _vm.lesson.endScheduledTime,
              spt: true
            }
          }
        }),
        _vm._v(" "),
        _c("v-lesson-place", {
          staticClass: "mb-3",
          attrs: {
            options: {
              icon: _vm.lesson.placeIcon,
              place: _vm.lesson.place,
              modality: _vm.lesson.modality,
              type: _vm.lesson.type,
              lessonId: _vm.lesson.id,
              edit:
                _vm.lesson.status == "Pendiente" ||
                _vm.lesson.status == "Confirmada"
            }
          }
        }),
        _vm._v(" "),
        _vm.statusTopic
          ? _c("v-lesson-topic", {
              staticClass: "mb-3",
              attrs: {
                options: {
                  lessonId: _vm.lesson.id,
                  topic: _vm.lesson.topic,
                  hideBtn: !(
                    _vm.lesson.status == "Pendiente" ||
                    _vm.lesson.status == "Confirmada"
                  )
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.lesson.status != "Cancelada" && _vm.lesson.status != "Finalizada"
          ? _c(
              "div",
              { staticClass: "row ml-n1 mr-n1 mt-4 justify-content-center" },
              [
                _vm.lesson.modality == "Online" && _vm.lesson.status == "Activa"
                  ? _c(
                      "div",
                      { staticClass: "col-12 pl-1 pr-1 mb-4" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: { type: "online", idLesson: _vm.lesson.id }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userType == "tutor" &&
                _vm.lesson.type == "Grupal" &&
                _vm.lesson.status == "Activa"
                  ? _c(
                      "div",
                      { staticClass: "col-6 pl-1 pr-1 mb-2" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: {
                              type: "takeAssistant",
                              idLesson: _vm.lesson.id
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userType == "tutor" && _vm.lesson.status == "Activa"
                  ? _c(
                      "div",
                      { staticClass: "col-6 pl-1 pr-1 mb-2" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: { type: "stop", idLesson: _vm.lesson.id }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lesson.status != "Activa"
                  ? _c(
                      "div",
                      { staticClass: "col-6 pl-1 pr-1 mb-2" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: { type: "cancel", idLesson: _vm.lesson.id }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lesson.status == "Pendiente" && _vm.userType == "tutor"
                  ? _c(
                      "div",
                      { staticClass: "col-6 pl-1 pr-1 mb-2" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: {
                              type: "confirm",
                              idLesson: _vm.lesson.id
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lesson.status == "Confirmada" && _vm.userType == "tutor"
                  ? _c(
                      "div",
                      { staticClass: "col-6 pl-1 pr-1 mb-2" },
                      [
                        _c("v-lesson-btn", {
                          staticClass: "w-100",
                          attrs: {
                            options: { type: "start", idLesson: _vm.lesson.id }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bound" } }, [
          _vm.lesson.type === "Grupal" && _vm.statusMemmersList
            ? _c(
                "div",
                { staticClass: "v-cl-memmers pt-2 pb-2 pl-3 pr-3" },
                [
                  _c(
                    "div",
                    { staticClass: "position-relative pr-4 pt-2 pb-2" },
                    [
                      _c("h5", { staticClass: "v-title-memmers mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("chat.memmers")))
                      ]),
                      _vm._v(" "),
                      _c("svgicon", {
                        staticClass: "v-btn-close",
                        attrs: { name: "cross2" },
                        on: {
                          click: function($event) {
                            _vm.statusMemmersList = false
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "perfect-scrollbar",
                    {
                      staticClass:
                        "v-memmers pl-0 pt-2 pb-2 mb-0 mr-n3 ml-n3 d-flex flex-column",
                      attrs: { tag: "ul" }
                    },
                    _vm._l(_vm.users, function(user, i) {
                      return user.confirmed && user.type == "student"
                        ? _c(
                            "li",
                            {
                              key: user.id,
                              staticClass: "v-cl-memmer pl-3 pr-3"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center pb-2 pt-2",
                                  style: { borderBottom: "1px solid #e8e8e8" }
                                },
                                [
                                  _c("v-lesson-photo", {
                                    attrs: {
                                      options: {
                                        photo: user.avatar,
                                        lessonType: "Individual",
                                        size: "36px"
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "v-memmer-info pl-2 pr-2",
                                      style: { width: "calc(100% - 36px)" }
                                    },
                                    [
                                      _c("v-hover-txt", {
                                        staticClass: "w-100",
                                        attrs: {
                                          txt: user.name,
                                          fontWeight: "400",
                                          positionCloud:
                                            i == 0 ? "top" : "bottom"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-hover-txt", {
                                        staticClass: "w-100",
                                        attrs: {
                                          txt: user.guest
                                            ? _vm.$t(
                                                "scheduleT.students.unconfirmed"
                                              )
                                            : user.career,
                                          positionCloud:
                                            i == 0 ? "top" : "bottom",
                                          fontSize: "11px",
                                          fontWeight: "400",
                                          colorTxt: "rgb(173, 173, 173)"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }