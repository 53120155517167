/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera3': {
    width: 14,
    height: 10,
    viewBox: '0 0 14 10',
    data: '<path pid="0" d="M8.01 0C9.136 0 9.76.689 9.783 1.885l.001.067v6.102c0 1.21-.66 1.92-1.77 1.945L7.953 10H1.83C.705 10 .025 9.311.001 8.12L0 8.054V1.952C0 .736.71.026 1.773.001L1.831 0H8.01zm5.254.942c.43 0 .717.329.735.808l.001.048v6.404c0 .505-.291.856-.736.856-.19 0-.401-.101-.585-.263l-.042-.039-2.077-1.94V3.178l2.077-1.933c.193-.185.422-.302.627-.302z" _fill="#000" fill-rule="nonzero"/>'
  }
})
