<template>
	<div class="more-info" ref="moreInfo" @mouseover="modality == 'hover'?showCloud():null" @mouseleave="modality == 'hover'?hideCloud():null" :style="{ width: sizePx, height: sizePx }"> 
		<!-- ========== Cloud ========= -->
		<div class="cloud pl-2 pt-3 pr-2 pb-3" :class="{ show: statusCloud }" :style="{ [cloudPositionX]: `-${positionVal}px`, [cloudPositionY==='top'?'bottom':'top']: `${parseInt(sizePx.replace('px','')) + 8}px`, width: widthCloud, maxWidth: maxWidthCloud, minWidth: minWidthCloud, background: auth.permission==='student'?'#2188FC':'#005E58', 'border-radius': ` 3px 3px ${paddingRightBottom} ${paddingLeftBottom}` }" v-if="show">
			<div class="arrow" :style="{ [cloudPositionX]: `${positionVal}px`, [cloudPositionY]: '100%', border: '8px solid transparent', [`border-${cloudPositionY}-Color`]: auth.permission==='student'?'#2188FC':'#005E58' }"></div>
			<p class="cloud-txt mb-0" :style="{ fontSize: fontSize, lineHeight: lineHeight }">{{ txtCloud }}</p>
			<svgicon class="icon-cross" name="close-sharp" color="#fff" v-if="modality == 'time'" @click.stop="breakCloud()" />
		</div>
		<!-- ========== Icon ========= -->
		<svgicon class="info-icon" :name="icon" :color="iconColor" v-if="icon"/>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		props:{
			txtCloud: { type: String, required: true }, // Text
			sizePx: { type: String, default: '15px', required: false }, // Width icon
			cloudPositionX: { type: String, default: 'right', required: false }, // right, 
			calcPaddingLeft: { type: String, default: 'default', required: false },
			cloudPositionY: { type: String, default: 'top', required: false }, // bottom, top
			parent: { type: String, default: '.view', required: false },
			child: { type: String, default: '', required: false },
			widthCloud: { type: String, default: '90vw', required: false }, // Cloud width
			maxWidthCloud: { type: String, default: '220px', required: false }, // Cloud max width
			minWidthCloud: { type: String, default: 'initial', required: false }, // Cloud max width
			fontSize: { type: String, default: '12px', required: false },
			lineHeight: { type: String, default: '120%', required: false },
			icon: { default: 'info', required: false }, // Icon
			iconColor: { type: String, default: '#9b9b9b', required: false }, // Color icon
			modality: { type: String, default: 'hover', required: false }, // hover, time
			delayTime: { type: Number, default: 5000, required: false },
			status: { type: Boolean, default: false, required: false }
		},
		data(){
			return{
				show: false,
				statusCloud: false,
				timer: '',
				positionVal: 0
			}
		},
		computed:{
			...mapState(['auth']),
            cloudW(){
            	const cloud = this.$refs.moreInfo.querySelector('.cloud');
            	return cloud?cloud.clientWidth:this.maxWidthCloud.includes('px')?parseInt(this.maxWidthCloud.replace('px','')):220;
            },
			paddingRightBottom(){
				return this.cloudPositionX=='right' && this.positionVal <= 3?`${this.positionVal}px`:'3px';
			},
			paddingLeftBottom(){
				return this.cloudPositionX=='left' && this.positionVal <= 3?`${this.positionVal}px`:'3px';
			}
		},
		methods:{
			showCloud(){
				this.show = true;

				const el = this.$refs.moreInfo, // Component
				      contentView = document.querySelector(this.parent),// Content view
				      contentW = contentView.clientWidth, // content width
				      contentInnerW = this.child != ''?contentView.querySelector(this.child).clientWidth:0,
				      windowW = window.innerWidth, // window width
				      paddingLeft = this.calcPaddingLeft=='default'?parseInt(getComputedStyle(contentView).paddingLeft.replace('px','')):(((contentW - contentInnerW)/2)); // Padding left content view

                let positionX = el.getBoundingClientRect();
				    positionX = positionX.x + positionX.width - (windowW - contentW); // Position x of component more info

				if ((((positionX - paddingLeft) - this.cloudW) < 0) && this.cloudPositionX === 'right') { // If position x is lower than cloud width move cloud
				    this.positionVal = this.cloudW - positionX + paddingLeft;
				}else{
				    this.positionVal = 0;
				}

				
				if (this.modality == 'hover'){
					clearTimeout(this.timer);
				    this.timer = setTimeout( () => {
                        this.statusCloud = true;
				    }, 300);
			    }else{
			    	this.statusCloud = true;
			    	this.timer = setTimeout( () => {
                        this.statusCloud = false;
                        this.$emit('hide');
				    }, this.delayTime);
			    }
			},
			breakCloud(){
				clearTimeout(this.timer);
				this.statusCloud = false; 
				this.$emit('hide')
			},
			hideCloud(){
				if (this.modality == 'hover')
				    this.timer = setTimeout( () => { // Hide cloud
					    this.statusCloud = false;
					    this.timer = setTimeout( () => {
						    this.show = false;
					    },300)
				    }, 300);
				else{
					this.show = false;
				}
			}
		},
		watch: {
			status: function(newVal){
				if (newVal)
					this.showCloud();
				else{
					clearTimeout(this.timer);
					this.hideCloud();
				}
			}
		}
	}
</script>