<template>
	<div id="manage" class="pt-4">

        <!-- ================ Pending Requests ================== -->
        <section class="pending-lessons mb-5" ref="lessons" >
            <div class="row ml-n1 mr-n1 align-items-center justify-content-between">
                <div class="col-auto pl-1 pr-1">
                    <h4 class="s-title d-flex align-items-center mb-0">
                        <div class="point bg-lesson-pending mr-1"></div> 
                        {{ $t('lessons.tPending') }}
                        <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoPendingT')" />
                    </h4>
                </div>
                <div class="col pl-1 pr-1 text-right">
                    <button class="btn-lessons" :class="{ active : pendingSlice==pending.length, 'color-tutor-main': true  }" v-on:click="pendingSlice = pendingSlice==pendingLimit?pending.length:pendingLimit" v-if="pendingLimit < pending.length">{{ $t('lessons.btnAll') }} ({{ pending.length }})<div class="icon"><svgicon class="fill-green-main" name="chevron-down"></svgicon></div></button>
                </div>
            </div>
            <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && pending.length === 0">
                    <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="!spinner && pending.length === 0" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt2" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-2" v-html="$t('msgEmpty.msgPending')"></p>
                        <p class="txt2 mb-0">{{ $t('msgEmpty.will') }}</p>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="lessons row mr-0 ml-0" v-else tag="div">
                    <v-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in pending.slice(0,pendingSlice)"/>  
                </transition-group>
            </transition>
        </section>

        <!-- ================ Upcoming Lessons ================== -->
        <section class="upcoming-lessons mb-5">
            <div class="row ml-n1 mr-n1 align-items-center justify-content-between">
                <div class="col-auto pl-1 pr-1">
                    <h4 class="s-title mb-0 d-flex align-items-center">
                        <div class="point bg-lesson-upcoming mr-1"></div> 
                        {{ $t('lessons.tUpcoming') }}
                        <v-more-info class="ml-2" :txtCloud="$t('moreInfo.infoUpcomingT')" />
                    </h4>
                </div>
                <div class="col pl-1 pr-1 text-right">
                    <button class="btn-lessons" :class="{ active : upcomingSlice==upcoming.length, [`color-${auth.permission}-main`]: true  }" v-on:click="upcomingSlice = upcomingSlice==upcomingLimit?upcoming.length:upcomingLimit" v-if="upcomingLimit < upcoming.length">{{ $t('lessons.btnAll') }} ({{ upcoming.length }})<div class="icon"><svgicon class="fill-green-main" name="chevron-down"></svgicon></div></button>
                </div>
            </div>    
            <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && upcoming.length === 0">
                    <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="!spinner && upcoming.length === 0" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt1" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-2" v-html="$t('msgEmpty.msgUpcoming')"></p>
                        <p class="txt2 mb-0">{{ $t('msgEmpty.will') }}</p>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="lessons" tag="div" v-else>
                    <v-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in upcoming.slice(0,upcomingSlice)"/>  
                </transition-group>
            </transition>           
        </section>

		<!-- ================ Ongoing Lesson ================== -->
		<section class="ongoing-lesson">
			<div class="d-flex align-items-center">
                <svgicon class="ongoing-icon" name="play-bound" original />
                <h4 class="s-title mb-0 pl-1 pr-2">{{ $t('lessons.tOngoing') }} </h4>
                <v-more-info :txtCloud="$t('moreInfo.infoOngoingS')" />
            </div>

            <transition name="bound" mode="out-in">
                <div class="lesson-spinner w-100 p-4 text-center mt-3" key="spinner" v-if="spinner && active.length === 0">
                    <v-spinner />
                </div>
                <div class="basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3" v-else-if="active.length === 0 && !spinner" key="alert">
                    <svgicon class="img pl-3 pr-3" original name="dashboardt2" />
                    <div class="pl-3 pr-3 text-center text-sm-left mt-2" :style=" { maxWidth: '300px' } ">
                        <p class="txt mb-2" v-html="$t('msgEmpty.msgOngoing')"></p>
                    </div>
                </div>
                <transition-group name="bound" key="lessons" class="row ml-0 mr-0" tag="div" v-else>
                    <v-ongoing-lesson class="col-12 mt-3" :key="lesson.id" :w="cardW" :lesson="lesson" v-for="(lesson,i) in active"/>
                </transition-group> 
            </transition>            
        </section>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
    export default{
      data(){
         return{
            spinner: false,
            upcomingSlice: 3,
            pendingSlice: 3,
            upcomingLimit: 3,
            pendingLimit: 3,
            statusAttendance: false,
            statusFeedback: false,
            active: [],
            upcoming: [],
            pending: [],
            joinedStudents: [],
            cardW: undefined
        }
    },
    computed:{
        ...mapState(['auth','currentLesson', 'lessonChanges', 'userFirebase', 'menu']),
        ...mapGetters(['events', 'modalVal'])
    },
    methods: {
        ...mapMutations(['handleServerTime']),
        ...mapActions(['axiosRequest']),
        getManageLessons(action=''){
            this.spinner = true;
            this.axiosRequest({ config : {
                method: 'GET',
                url: `${this.auth.api}/lessons/manage`,
                params: ({ user_type: 't' }),
                headers: { authorization : this.auth.token }
            }}).then( resp => resp.data)
            .then( data => {
                this.handleServerTime(data.serverTime);
                this.active.map( lesson => clearInterval(lesson.timer) );
                if (action === '' || action === 'changedPlace' || action === 'changedTopic') {
                    this.active = data.active;
                    this.upcoming = data.upcoming;
                    this.pending = data.pending;
                }
                else if(action === 'pending'){
                    this.pending = data.pending;
                }
                else if(action === 'canceled' || action === 'upcoming' || action === 'userGroupJoined' || action === 'userGroupLeft'){
                    this.pending = data.pending;
                    this.upcoming = data.upcoming;
                }
                else if(action === 'ongoing'){
                    this.active = data.active;
                    this.upcoming = data.upcoming;
                    const lesson = JSON.parse(this.$localStorage.get('lesson')).split('_')[0];
                }
                else if(action === 'pendingFeedback'){
                    this.active = data.active;
                }
                this.spinner = false;


            }).catch( error => {
                this.spinner = false;
            });
        },
        getCardW(){
            this.cardW = this.$refs.lessons.offsetWidth;
        },
        getScrollbarWidth() {
            // Creating invisible container
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll'; // forcing scrollbar to appear
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
            document.body.appendChild(outer);

            // Creating inner element and placing it in the container
            const inner = document.createElement('div');
            outer.appendChild(inner);

            // Calculating difference between container's full width and the child width
            const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

            // Removing temporary elements from the DOM
            outer.parentNode.removeChild(outer);

            return scrollbarWidth;
        }
    },
    created(){
        this.getManageLessons();
    },
    mounted(){
        this.cardW = this.$refs.lessons.offsetWidth - this.getScrollbarWidth();
        window.addEventListener('resize', this.getCardW);
        this.$watch('events', val => {
            const lessonLS = JSON.parse(this.$localStorage.get('lesson'));
            if (val && lessonLS.lesson){
                const lesson = val.split('_');
                this.getManageLessons(lesson[1]);
            }
        });
    },
    beforeDestroy(){
        window.removeEventListener('resize', this.getCardW);
    },
    watch:{
        modalVal: function(newVal, oldVal){
            if(oldVal.modal == 'v-modal-crud' && this.currentLesson.lesson){
                if(!this.userFirebase){
                    let lessonIndex = -1;
                    if (oldVal.type === 'cancel'){
                        lessonIndex = this.pending.findIndex( item => item.id === lesson.id );
                        if (lessonIndex != -1)
                            this.pending[lessonIndex].place = lesson.place;
                        else{
                            lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                            if(lessonIndex != -1)
                                delete this.upcoming[lessonIndex];
                        }
                    }else if (oldVal.type === 'confirm') {
                        lessonIndex = this.pending.findIndex( item => item.id === lesson.id );
                        if (lessonIndex != -1){
                            this.upcoming.unshift({ ...this.pending[lessonIndex], status: 'Confirmada' });
                            delete this.pending[lessonIndex];
                        }
                    }
                    else if(oldVal.type === 'start'){
                        lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                        if (lessonIndex != -1){
                            this.active.unshift({ ...this.upcoming[lessonIndex], status: 'Activa' });
                            delete this.upcoming[lessonIndex];
                        }
                    }
                    else if(oldVal.type == 'upcoming'){
                        lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                        if (this.currentLesson.lesson.type === 'Grupal' && lessonIndex != -1){
                            this.active.map( lesson => clearInterval(lesson.timer) );
                            this.active.unshift({ ...this.upcoming[lessonIndex], status: 'Activa' });
                            delete this.upcoming[lessonIndex];
                        }
                    }
                    else if(oldVal.type == 'finished'){
                        lessonIndex = this.active.findIndex( item => item.id === lesson.id );
                        if (lessonIndex != -1)
                            delete this.active[lessonIndex];
                    }
                }
            } 
        },
        lessonChanges: function(newVal){
            if (newVal) {
                const { data, lesson } = newVal;
                let lessonIndex = this.pending.findIndex( item => item.id === lesson.id );
                if (lessonIndex != -1){
                        this.pending[lessonIndex][data] = lesson.val;
                        return;
                }

                lessonIndex = this.active.findIndex( item => item.id === lesson.id );
                if(lessonIndex != -1){
                    this.active[lessonIndex][data] = lesson.val;
                    return;
                }

                lessonIndex = this.upcoming.findIndex( item => item.id === lesson.id );
                if(lessonIndex != -1){
                    this.upcoming[lessonIndex][data] = lesson.val;
                }
            }
        },
        'menu.compact': function(){
            setTimeout(() => {
                this.getCardW();
            }, 500)
        }
    }
}
</script>