var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-questions-result" }, [
    _c("div", { staticClass: "pl-3 pr-3" }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col v-qr-column",
                style: { minWidth: `${(100 / 7) * 4}%` }
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.results.visual.questions.slice(0, 4), function(
                    item
                  ) {
                    return _c(
                      "div",
                      {
                        key: item.question,
                        staticClass: "v-qr-column col pl-0 pr-0"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "v-qr-head d-flex align-items-center"
                          },
                          [
                            _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                              _vm._v(_vm._s(item.question))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "v-qr-body d-flex align-items-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "v-qr-txt pl-2 pr-2",
                                attrs: { color: "greenBold" }
                              },
                              [_vm._v(_vm._s(item.answers))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col v-qr-column" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.results.visual.questions.slice(4), function(item) {
                  return _c(
                    "div",
                    {
                      key: item.question,
                      staticClass: "v-qr-column col pl-0 pr-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "v-qr-head d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                            _vm._v(_vm._s(item.question))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "v-qr-body d-flex align-items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-qr-txt pl-2 pr-2",
                              attrs: { color: "greenBold" }
                            },
                            [_vm._v(_vm._s(item.answers))]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
            _c(
              "div",
              {
                staticClass: "v-qr-txt pl-3 pr-3",
                attrs: { color: "greenBold" }
              },
              [_vm._v(_vm._s(_vm.results.visual.total))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col v-qr-column",
                style: { minWidth: `${(100 / 7) * 4}%` }
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.results.hearing.questions.slice(0, 4), function(
                    item
                  ) {
                    return _c(
                      "div",
                      {
                        key: item.question,
                        staticClass: "v-qr-column col pl-0 pr-0"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "v-qr-head d-flex align-items-center"
                          },
                          [
                            _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                              _vm._v(_vm._s(item.question))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "v-qr-body d-flex align-items-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "v-qr-txt pl-2 pr-2",
                                attrs: { color: "greenBold" }
                              },
                              [_vm._v(_vm._s(item.answers))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col v-qr-column" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.results.hearing.questions.slice(4), function(item) {
                  return _c(
                    "div",
                    {
                      key: item.question,
                      staticClass: "v-qr-column col pl-0 pr-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "v-qr-head d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                            _vm._v(_vm._s(item.question))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "v-qr-body d-flex align-items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-qr-txt pl-2 pr-2",
                              attrs: { color: "greenBold" }
                            },
                            [_vm._v(_vm._s(item.answers))]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
            _c(
              "div",
              {
                staticClass: "v-qr-txt pl-3 pr-3",
                attrs: { color: "greenBold" }
              },
              [_vm._v(_vm._s(_vm.results.hearing.total))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col v-qr-column",
                style: { minWidth: `${(100 / 7) * 4}%` }
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(
                    _vm.results.kinesthetic.questions.slice(0, 4),
                    function(item) {
                      return _c(
                        "div",
                        {
                          key: item.question,
                          staticClass: "v-qr-column col pl-0 pr-0"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-qr-head d-flex align-items-center"
                            },
                            [
                              _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                                _vm._v(_vm._s(item.question))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "v-qr-body d-flex align-items-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "v-qr-txt pl-2 pr-2",
                                  attrs: { color: "greenBold" }
                                },
                                [_vm._v(_vm._s(item.answers))]
                              )
                            ]
                          )
                        ]
                      )
                    }
                  ),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col v-qr-column" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.results.kinesthetic.questions.slice(4), function(
                  item
                ) {
                  return _c(
                    "div",
                    {
                      key: item.question,
                      staticClass: "v-qr-column col pl-0 pr-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "v-qr-head d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
                            _vm._v(_vm._s(item.question))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "v-qr-body d-flex align-items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-qr-txt pl-2 pr-2",
                              attrs: { color: "greenBold" }
                            },
                            [_vm._v(_vm._s(item.answers))]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" }, [
          _vm._m(8),
          _vm._v(" "),
          _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
            _c(
              "div",
              {
                staticClass: "v-qr-txt pl-3 pr-3",
                attrs: { color: "greenBold" }
              },
              [_vm._v(_vm._s(_vm.results.kinesthetic.total))]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
          _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [_vm._v("VISUAL")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-none d-md-block" })
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c(
          "div",
          { staticClass: "v-qr-head align-items-center d-none d-md-flex" },
          [
            _c("div", { staticClass: "v-qr-txt pl-3 pr-3" }, [
              _vm._v("Pregunta")
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
          _c(
            "div",
            { staticClass: "v-qr-txt pl-3 pr-3", attrs: { size: "s" } },
            [_vm._v("Puntaje (1 a 5)")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
      _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
        _vm._v("Total Visual:")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
          _c("div", { staticClass: "v-qr-txt pl-3 pr-3" }, [_vm._v("AUDITIVO")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-none d-md-block" })
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c(
          "div",
          { staticClass: "v-qr-head align-items-center d-none d-md-flex" },
          [
            _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
              _vm._v("Pregunta")
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
          _c(
            "div",
            { staticClass: "v-qr-txt pl-3 pr-3", attrs: { size: "s" } },
            [_vm._v("Puntaje (1 a 5)")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
      _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
        _vm._v("Total auditivo:")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
          _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
            _vm._v("KINESTÉSICO")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-none d-md-block" })
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "v-qr-column col-md-auto col-12 pl-0 pr-0" },
      [
        _c(
          "div",
          { staticClass: "v-qr-head align-items-center d-none d-md-flex" },
          [
            _c("div", { staticClass: "v-qr-txt pl-3 pr-3" }, [
              _vm._v("Pregunta")
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "v-qr-body d-flex align-items-center" }, [
          _c(
            "div",
            { staticClass: "v-qr-txt pl-3 pr-3", attrs: { size: "s" } },
            [_vm._v("Puntaje (1 a 5)")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "v-qr-head d-flex align-items-center" }, [
      _c("div", { staticClass: "v-qr-txt pl-2 pr-2" }, [
        _vm._v("Total Kinestésico:")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }