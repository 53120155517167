import Vue from 'vue';
import { ConsoleLogger, 
         DefaultDeviceController, 
         DefaultMeetingSession, 
         DefaultBrowserBehavior, 
         Device,
         LogLevel, 
         MeetingSessionConfiguration, 
         VideoPriorityBasedPolicy, 
         VideoAdaptiveProbePolicy,
         VoiceFocusDeviceTransformer,
         VoiceFocusModelComplexity,
         VoiceFocusSpec,
         VoiceFocusTransformDevice } from 'amazon-chime-sdk-js';


/* Chime autenticate */
const authenticate = async ({ dispatch, commit }, roomId): Promise<void> =>{
    commit('handleDefaultBrowserBehaviour', new DefaultBrowserBehavior());
    const joinInfo = (await dispatch('joinMeeting', roomId)).JoinInfo;
    const configuration = new MeetingSessionConfiguration(joinInfo.Meeting, joinInfo.Attendee);
    await dispatch('initializeMeetingSession', configuration);   
}

const initializeMeetingSession = async ({ state, commit }, configuration: MeetingSessionConfiguration): Promise<void> => {
    commit('handleMeetingLogger', new ConsoleLogger('SDK', state.logLevel));
    const consoleLogger = state.meetingLogger;
    if(state.defaultBrowserBehaviour.isSafari())
        commit('handleEnableWebAudio', false);

    const deviceController: any = new DefaultDeviceController(consoleLogger, { enableWebAudio: state.enableWebAudio });
    
    configuration.enableUnifiedPlanForChromiumBasedBrowsers = state.enableUnifiedPlanForChromiumBasedBrowsers;
    if (!isNaN(state.timeoutMs))
        configuration.attendeePresenceTimeoutMs = state.timeoutMs;
    configuration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = state.enableSimulcast;

    if(state.defaultBrowserBehaviour.supportDownlinkBandwidthEstimation()){
        if (state.usePriorityBasedDownlinkPolicy) {
            commit('handlePriorityBasedDownlinkPolicy', new VideoPriorityBasedPolicy(state.meetingLogger, state.videoPriorityBasedPolicyConfig));
            configuration.videoDownlinkBandwidthPolicy = state.priorityBasedDownlinkPolicy;
        }else if (state.enableSimulcast) {
            configuration.videoDownlinkBandwidthPolicy = new VideoAdaptiveProbePolicy(state.meetingLogger);
        }
    }else{
        commit('handleUsePriorityBasedDownlinkPolicy', false)
    }

    configuration.keepLastFrameWhenPaused = true;

    commit('handleMeetingSession', new DefaultMeetingSession(configuration, consoleLogger, deviceController));
}

const joinMeeting = async ({ state, dispatch }, roomId): Promise<any> => { 
    const region: any = await dispatch('setMediaRegion');
            
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization' : state.auth.token!
        },
        body: JSON.stringify({ 
            lessonId: roomId, 
            regionId: region
        })
    };
    const response = await fetch(`${state.auth.api}/meeting/join`, requestOptions);
    const json = await response.json();

    if (json.error) {
        console.log("$ Error", json.error);
        throw new Error(`Server error joinMeeting: ${json.error}`);
    }

    return json;
}

const setMediaRegion = async ({ dispatch }): Promise<string | null> => {
    let region: string | null = "";

    try {
        const nearestMediaRegion = await dispatch('getNearestMediaRegion');
        if (nearestMediaRegion === '' || nearestMediaRegion === null) {
            region = "us-east-2";
        } else {
            region = nearestMediaRegion;
        }
    } catch (e: any) {
        console.log('Default media region selected: ' + e.message);
    }

    return region;
}

const getNearestMediaRegion = async (): Promise<string> =>{
    const nearestMediaRegionResponse = await fetch(
        `https://nearest-media-region.l.chime.aws`,
        {
            method: 'GET',
        }
    );
    const nearestMediaRegionJSON = await nearestMediaRegionResponse.json();
    const nearestMediaRegion = nearestMediaRegionJSON.region;
    return nearestMediaRegion;
}

/* Voice focus */
const initVoiceFocus = async({state, commit, dispatch}): Promise<void> => {
    const logger = new ConsoleLogger('SDK', LogLevel.DEBUG);
    if (!state.enableWebAudio) {
        logger.info('[DEMO] Web Audio not enabled. Not checking for Amazon Voice Focus support.');
        return;
    }

    try {
        commit('handleSupportsVoiceFocus', await VoiceFocusDeviceTransformer.isSupported(state.spec, { logger }));
        if (state.supportsVoiceFocus) {
            commit('handleVoiceFocusTransformer', await dispatch('getVoiceFocusDeviceTransformer', state.MAX_VOICE_FOCUS_COMPLEXITY));
            commit('handleSupportsVoiceFocus', state.voiceFocusTransformer && state.voiceFocusTransformer.isSupported());
            if (state.supportsVoiceFocus) {
                logger.info('[DEMO] Amazon Voice Focus is supported.');
                return;
            }
        }
    } catch (e: any) {
              // Fall through.
              logger.warn(`[DEMO] Does not support Amazon Voice Focus: ${e.message}`);
    }
    logger.warn('[DEMO] Does not support Amazon Voice Focus.');
    commit('handleSupportsVoiceFocus', false);
}

const getVoiceFocusDeviceTransformer = async ({state}, maxComplexity?: VoiceFocusModelComplexity): Promise<VoiceFocusDeviceTransformer> => {
    if (state.voiceFocusTransformer) {
        return state.voiceFocusTransformer;
    }

    function exceeds(configured: VoiceFocusModelComplexity): boolean {
        const max = Number.parseInt((maxComplexity as any).substring(1), 10);
        const complexity = Number.parseInt(configured.substring(1), 10);
        return complexity > max;
    }

    const logger = new ConsoleLogger('SDK', LogLevel.DEBUG);

    // Find out what it will actually execute, and cap it if needed.
    const spec: VoiceFocusSpec = { ...state.spec };
    const config = await VoiceFocusDeviceTransformer.configure(spec, { logger });

    let transformer;
    if (maxComplexity && config.supported && exceeds(config.model.variant)) {
        logger.info(`Downgrading VF to ${maxComplexity}`);
        spec.variant = maxComplexity;
        transformer = VoiceFocusDeviceTransformer.create(spec, { logger });
    } else {
        transformer = VoiceFocusDeviceTransformer.create(spec, { logger }, config);
    }

    return state.voiceFocusTransformer = await transformer;
}

const audioInputSelectionWithOptionalVoiceFocus= async({state, getters, commit, dispatch}, device: Device | any): Promise<Device | VoiceFocusTransformDevice> => {
    if (getters.isVoiceFocusEnabled) {
        if (!state.voiceFocusDevice) {
            return await dispatch('createVoiceFocusDevice', device);
        }

            // Switch out the inner if needed.
            // The reuse of the Voice Focus device is more efficient, particularly if
            // reselecting the same inner -- no need to modify the Web Audio graph.
            // Allowing the Voice Focus device to manage toggling Voice Focus on and off
            // also
        commit('handleVoiceFocusDevice', await state.voiceFocusDevice.chooseNewInnerDevice(device));
        return state.voiceFocusDevice;
    }

    return device;
}

const createVoiceFocusDevice = async({state, commit, dispatch}, inner: Device): Promise<VoiceFocusTransformDevice | Device> => {
    if (!state.supportsVoiceFocus) {
        return inner;
    }

    if (state.voiceFocusDevice) {
        // Dismantle the old one.
        commit('handleVoiceFocusDevice', await state.voiceFocusDevice.chooseNewInnerDevice(inner));
        return state.voiceFocusDevice;
    }

    try {
        const transformer = await dispatch('getVoiceFocusDeviceTransformer', state.MAX_VOICE_FOCUS_COMPLEXITY);
        const vf: VoiceFocusTransformDevice | undefined = await transformer.createTransformDevice(inner);
        if (vf) {
            commit('handleVoiceFocusDevice', vf);
            return vf;
        }
    } catch (e) {
        console.log('Issue create VF', e);
    }
    return inner;       
}

/* Browser permissions */
const setupDeviceLabelTrigger = ({ state, commit, getters }): void => {
    if (getters.audioVideo && !state.defaultBrowserBehaviour.doesNotSupportMediaDeviceLabels()) {
        getters.audioVideo.setDeviceLabelTrigger(
            async (): Promise<MediaStream> => {
                let statusVideo: boolean = true,
                    statusAudio: boolean = true;

                console.log("$ Terminado de traer los permisos 1");
                let devices: any;
                if (state.permissions.mic == state.typePermission.prompt || state.permissions.cam == state.typePermission.prompt){
                    commit('handlePermissions', { mic: state.typePermission.denied, cam: state.typePermission.denied });
                    devices = await navigator.mediaDevices.enumerateDevices();
                    commit('handleAskingForPermissions', true);
                }
                
                if(devices){
                    statusVideo = devices.find( i => i.kind == 'videoinput' );
                    statusAudio = devices.find( i => i.kind == 'audioinput' );
                }

                const stream = await navigator.mediaDevices.getUserMedia({ audio: statusAudio , video: statusVideo });
                commit('handlePermissions', { mic: state.typePermission.granted, cam: state.typePermission.granted });
                commit('handleAskingForPermissions', false);
                console.log("$", "Terminado de traer los permisos", stream);
                return stream;
            }
        );
    }
}

/* Devices */
const populateAllDeviceLists = async ({ state, commit, dispatch }): Promise<void> => {
    await dispatch('populateAudioInputList');
    await dispatch('populateVideoInputList');
    if (state.askingForPermissions)
        commit('handleAskingForPermissions', false);
    await dispatch('populateAudioOutputList');
}

const populateAudioInputList = async ({ state, commit, getters }): Promise<void> => {
    let microphones = await getters.audioVideo.listAudioInputDevices();
    if (getters.audioVideo) {
        let lastDeviceId = null,
        LastLabel = null,
        deviceId = null,
        label = null;

        if (state.currentMic) {
            lastDeviceId = state.currentMic.deviceId;
            LastLabel = state.currentMic.label;
        }

        const freshDeviceWithSameID = microphones.find((device) => device.deviceId === lastDeviceId );

        if (freshDeviceWithSameID) {
            deviceId = freshDeviceWithSameID.deviceId;
            label = freshDeviceWithSameID.label;
        }

        commit('handleDevices', { microphones: microphones });

        if(!freshDeviceWithSameID || (freshDeviceWithSameID && state.micTracksChange && lastDeviceId == deviceId && LastLabel != label)){
            commit('handleDevice', { type: 'mic', device: microphones.length == 0?null:freshDeviceWithSameID?freshDeviceWithSameID:microphones[0] });
            if (state.micTracksChange){
                commit('setNotification', { type: "success", msg: Vue.prototype.$t('room.microphone.alerts.change', { mic: freshDeviceWithSameID?label:microphones[0].label }) })
                commit('handleMicTracks', false);
            }    
        }
    }
}


const populateVideoInputList = async ({ state, commit, getters }): Promise<void> => {
    if (getters.audioVideo) {
        let cameras = await getters.audioVideo.listVideoInputDevices();
        let deviceId = null;

        if (state.currentCam) {
            deviceId = state.currentCam.deviceId;
        }

        commit('handleDevices', { cameras: cameras });

        if (!state.currentCam || (state.camTracksChange && deviceId != cameras[0].deviceId)){
            commit('handleDevice', { type: 'cam', device: cameras.length == 0?null:cameras[0] });
            if (state.camTracksChange){
                commit('setNotification', { type: "success", msg: Vue.prototype.$t('room.camera.alerts.change', { cam: cameras[0].label }) });
                commit('handleCamTracks', false);
            }
        }
    }
}

const populateAudioOutputList = async({state, commit, getters}): Promise<void> => {
    if (getters.audioVideo) {
        const supportsChoosing = state.defaultBrowserBehaviour.supportsSetSinkId();
        let speakers = supportsChoosing ? await getters.audioVideo.listAudioOutputDevices(): [];

        commit('handleDevices', { speakers: speakers });

        if (!state.currentSpk)
            commit('handleDevice', { type: 'spk', deviceId: speakers.length == 0?null:speakers[0].deviceId });
    }
}

const handleLSConfig = ({}, payload: any) => {
    const { device, data, val } = payload;
    let config: any =  Vue.prototype.$localStorage.get('vrConf');

    if(config){
        config = JSON.parse(config);
        Vue.prototype.$localStorage.set('vrConf', JSON.stringify({ ...config, [device]: { ...config[device], [data] : val } }));
    }else{
        Vue.prototype.$localStorage.set('vrConf', JSON.stringify({ [device] : { [data] : val }  }));
    }
}

export default{
	authenticate,
    initializeMeetingSession,
	joinMeeting,
	setMediaRegion,
	getNearestMediaRegion,
    initVoiceFocus,
    getVoiceFocusDeviceTransformer,
    audioInputSelectionWithOptionalVoiceFocus,
    createVoiceFocusDevice,
    setupDeviceLabelTrigger,
    populateAllDeviceLists,
    populateAudioInputList,
    populateVideoInputList,
    populateAudioOutputList,
    handleLSConfig
}