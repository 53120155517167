var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-img" }, [
    _vm.type !== "Group"
      ? _c(
          "div",
          {
            staticClass: "img",
            style: {
              background: "url(" + _vm.img + ")center center / cover no-repeat"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "content-icon one-on-one",
                style: { background: _vm.color.primary.main }
              },
              [_c("svgicon", { staticClass: "icon", attrs: { name: "user" } })],
              1
            )
          ]
        )
      : _c(
          "div",
          { staticClass: "icon" },
          [_c("svgicon", { staticClass: "icon", attrs: { name: "users2" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }