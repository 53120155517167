<template>
	<div id="change-password" class="container-fluid pb-5">
		<!-- ================ Form ================== -->
		<div class="paper row ml-md-0 mr-md-0 p-sm-4 pt-4 pb-4 justify-content-center">
			<div class="details col-md">
				<form class="d-flex paper justify-content-center flex-wrap p-4 mb-3" @submit.prevent="submit()">
					<div class="picture mb-3 text-center d-flex justify-content-center align-items-center mb-sm-3 mt-sm-3">
						<svgicon class="icon-lock" name="lock" />
					</div>
					<h2 class="title mb-5 text-center">{{ $t('changePassword.title') }}</h2>
					<v-textField class="w-100 mb-3" v-model="form.oldPassword" :label="$t('changePassword.form.cPassword')" maxlength="50" type="password" :required="true" :iconEye="true" />
					<v-textField class="w-100 mb-4" v-model="form.newPassword" :label="$t('changePassword.form.nPassword')" maxlength="50" type="password" :required="true" :iconEye="true" />
					<p class="txt-steps text-left mb-1 w-100" v-if="!this.$v.form.newPassword.minLength">{{ $t('resetPassword.step2') }}</p>
					<v-btn class="w-100 mt-3" :txt="$t('changePassword.form.btn')" type="submit" :disabled="$v.form.$invalid || sending" />
				</form>
				<v-circles :vCircle="3" size="300px"/>
				<v-circles :vCircle="4" size="200px"/>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
	import { mapActions } from 'vuex';
	export default{
		data(){
			return{
				sending: false,
				form : {
					oldPassword: '',
					newPassword: '',
				}
			}
		},
		methods:{
			...mapActions(['changePassword']),
			submit(){
				if (!this.$v.form.$invalid && !this.sending){
					this.sending = true;
					this.changePassword(this.form).then( resp => {
						this.sending = false;
						this.form.oldPassword = '';
						this.form.newPassword = '';
					}).catch( err => {
						this.sending = false;
					} )
			    }
			}
		}, 
		validations: {
            form: {
            	oldPassword: {
            		required,
                    minLength: minLength(8),
                    maxLength: maxLength(50)
            	},
            	newPassword: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(50)
            	}
            }
        }
	}
</script>