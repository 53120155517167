/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pdf': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M14.5 19h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM1.5 1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z"/><path pid="1" d="M3.664 16v-1.535h1.01c.269 0 .489-.04.659-.12.17-.08.3-.183.393-.306.092-.123.155-.257.188-.4.034-.144.05-.278.05-.4 0-.124-.016-.259-.05-.404a1.07 1.07 0 00-.188-.404 1.047 1.047 0 00-.393-.308c-.17-.082-.39-.123-.66-.123H3v4h.664zm.973-2.106h-.973V12.57h.983c.089 0 .172.01.25.028a.5.5 0 01.207.104c.06.05.108.119.144.205a.858.858 0 01.053.324c0 .127-.02.233-.061.317a.554.554 0 01-.16.202.64.64 0 01-.215.109.825.825 0 01-.228.034zM8.117 16c.29 0 .542-.052.754-.157.213-.104.387-.247.521-.428.135-.182.235-.394.3-.636.066-.243.099-.503.099-.779s-.033-.536-.099-.779a1.872 1.872 0 00-.3-.636 1.416 1.416 0 00-.52-.428A1.692 1.692 0 008.116 12H6.544v4h1.573zm-.255-.571h-.654V12.57h.654c.258 0 .47.037.635.11a.935.935 0 01.388.302c.094.129.157.28.191.454s.05.361.05.563c0 .202-.016.39-.05.563a1.122 1.122 0 01-.191.454.935.935 0 01-.388.302 1.58 1.58 0 01-.635.11zm3.183.571v-1.776h1.716v-.571h-1.716v-1.048H13V12h-2.62v4h.665zM10.5 4h-7a.5.5 0 010-1h7a.5.5 0 010 1zM12.5 6h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 8h-9a.5.5 0 010-1h9a.5.5 0 010 1zM6.8 10H3.2c-.11 0-.2-.224-.2-.5s.09-.5.2-.5h3.6c.11 0 .2.224.2.5s-.09.5-.2.5z"/></g>'
  }
})
