import { AudioVideoFacade } from 'amazon-chime-sdk-js';

const audioVideo = (state): AudioVideoFacade | null  => {
    return state.meetingSession?.audioVideo;
}

const isVoiceFocusEnabled = (state): boolean => {
    console.log('VF supported:', state.supportsVoiceFocus);
    console.log('VF enabled:', state.enableVoiceFocus);
    return state.supportsVoiceFocus && state.enableVoiceFocus;
}

export default{
    audioVideo,
    isVoiceFocusEnabled
}