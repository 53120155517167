import Vue from 'vue';

const downloadFile = ({ commit,state, dispatch }, payload) => {
    const { file, name, idLesson } = payload;
                
    if (!state.chatFiles.inProgress[file]){ // Avoid to download the file if it is downloading yet
        const chacheFile = state.chatFiles.downloading[file],
              CancelToken = axios.CancelToken,
              fileInProgress = { file: file, progress: chacheFile?100:0, source: CancelToken.source() };

        commit('addInProgressFile', fileInProgress);
        if(!chacheFile) // Download the file only if its't save in chatFiles.downloading
            dispatch('axiosRequest', { config : {
                method: 'GET',
                url: `${state.auth.aw}/${state.auth.user.university_id}/${idLesson}/${file}`,
                responseType: 'arraybuffer',
                cancelToken: fileInProgress.source.token,
                onDownloadProgress: (e) => {
                    const progress = Math.round((e.loaded * 100) / e.total);
                    commit('handleProgress', { file: file, progress: progress, source: fileInProgress.source });
                },
            }}).then( resp => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                commit('addDownloadingFile', { file: file, name: name,  blod: url });
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); 
                document.body.appendChild(link);
                link.click();
                commit('removeInProgressFile', fileInProgress);
            }).catch( err => {
                if (axios.isCancel(err)) {
                    commit('handleProgress', { file: file, progress: 100, source: fileInProgress.source });
                }else{
                    commit('setNotification',{ type: 'error', msg: Vue.prototype.$t('chat.fileNotFound') });
                }
                commit('handleProgress', { file: file, progress: 100, source: fileInProgress.source });
                commit('removeInProgressFile', fileInProgress);
            } )
        else{ // Download the file since hatFiles.downloading
            const { url, name } = chacheFile;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); 
            document.body.appendChild(link);
            link.click();
            commit('removeInProgressFile', fileInProgress);
        }
    }
}

export default{
    downloadFile
}