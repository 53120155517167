import cardImg from './card-img.vue';
import cardInfo from './card-info.vue';
import cardBasic from './card-basic.vue';

function plugin(Vue){
	Vue.component('v-card-img', cardImg);
    Vue.component('v-card-info', cardInfo);
    Vue.component('v-card-basic', cardBasic);
}

export default plugin