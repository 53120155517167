/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cash-pound': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 18H.5a.5.5 0 01-.5-.5v-10A.5.5 0 01.5 7h18a.5.5 0 01.5.5v10a.5.5 0 01-.5.5zM1 17h17V8H1v9zM17.5 6h-16a.5.5 0 010-1h16a.5.5 0 010 1zM16.5 4h-14a.5.5 0 010-1h14a.5.5 0 010 1z"/><path pid="1" d="M11.5 15H8v-2h1.5a.5.5 0 000-1H8v-.5c0-.799.763-1.5 1.634-1.5.579 0 1.182.316 1.433.75a.499.499 0 10.865-.501C11.506 9.513 10.561 9 9.633 9a2.73 2.73 0 00-1.819.717c-.526.481-.815 1.114-.815 1.783v.5h-.5a.5.5 0 000 1h.5v2.5a.5.5 0 00.5.5h4a.5.5 0 000-1z"/>'
  }
})
