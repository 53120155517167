var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-format position-relative" },
    [
      !_vm.imready
        ? _c(
            "div",
            { staticClass: "v-format-spinner" },
            [_c("v-spinner-logo", { attrs: { size: "120px" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "v-format-info" }, [
        _c("h3", { staticClass: "v-format-title text-center mb-sm-5 mb-4" }, [
          _vm._v(_vm._s(_vm.$t("formats.fm1.t")))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "v-formt-txt mb-4" }, [
          _vm._v(_vm._s(_vm.$t("formats.fm1.d")))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row h-100 mb-4" }, [
          _c(
            "div",
            {
              staticClass: "d-md-none d-block col-12 pb-3",
              class:
                ((_vm.statusMenu || !_vm.statusFormat) && _vm.grid == "lg") ||
                _vm.grid == "xl"
                  ? "d-lg-none"
                  : "d-lg-block"
            },
            [
              _vm.statusFormat && _vm.createdDate
                ? _c("p", { staticClass: "vf-txt mb-0" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("formats.fm1.date")))
                    ]),
                    _vm._v(" " + _vm._s(_vm.createdDate))
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "order-1 order-md-0 col-md-9",
              class:
                _vm.statusMenu || !_vm.statusFormat ? "col-lg-9" : "col-xl-9"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-between h-100"
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-none d-md-block col-12 mb-4 pb-2",
                        class:
                          ((_vm.statusMenu || !_vm.statusFormat) &&
                            _vm.grid == "lg") ||
                          _vm.grid == "xl"
                            ? "d-lg-block"
                            : "d-lg-none"
                      },
                      [
                        _vm.statusFormat && _vm.createdDate
                          ? _c("p", { staticClass: "vf-txt mb-0" }, [
                              _c("span", { staticClass: "bold" }, [
                                _vm._v(_vm._s(_vm.$t("formats.fm1.date")))
                              ]),
                              _vm._v(" " + _vm._s(_vm.createdDate))
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-sm-6 mb-3" },
                      [
                        _c("v-select", {
                          staticClass: "w-100",
                          attrs: {
                            label: _vm.$t("formats.fm1.form.career.label"),
                            defaultOpc: _vm.$t(
                              "formats.fm1.form.career.placeholder"
                            ),
                            opc: _vm.careers,
                            options: _vm.opcInput,
                            fieldVal: "id",
                            fieldTxt: "name",
                            required: true,
                            disabled: _vm.sending,
                            error: _vm.error && _vm.$v.form.majorId.$invalid,
                            onlyRead: _vm.statusFormat
                          },
                          model: {
                            value: _vm.form.majorId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "majorId", $$v)
                            },
                            expression: "form.majorId"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-sm-6 mb-3" },
                      [
                        _c("v-select", {
                          staticClass: "w-100",
                          attrs: {
                            label: _vm.$t("formats.fm1.form.period.label"),
                            defaultOpc: _vm.$t(
                              "formats.fm1.form.period.placeholder"
                            ),
                            opc: _vm.periods,
                            options: _vm.opcInput,
                            fieldVal: "id",
                            fieldTxt: "period",
                            required: true,
                            disabled: _vm.sending,
                            error: _vm.error && _vm.$v.form.periodId.$invalid,
                            onlyRead: _vm.statusFormat
                          },
                          model: {
                            value: _vm.form.periodId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "periodId", $$v)
                            },
                            expression: "form.periodId"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-sm-6 mb-3" },
                      [
                        _c("v-textField", {
                          staticClass: "w-100",
                          attrs: {
                            label: _vm.$t("formats.fm1.form.noControl"),
                            maxlength:
                              _vm.$v.form.studentId.$params.maxLength.max,
                            options: _vm.opcInput,
                            required: true,
                            disabled: _vm.sending,
                            error: _vm.error && _vm.$v.form.studentId.$invalid,
                            onlyRead: _vm.statusFormat
                          },
                          model: {
                            value: _vm.form.studentId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "studentId", $$v)
                            },
                            expression: "form.studentId"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "v-format-subTitle mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("formats.fm1.generalDatas")))
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "order-0 order-md-1 col-md-3 mb-3 d-flex flex-wrap flex-column align-items-center",
              class:
                _vm.statusMenu || !_vm.statusFormat ? "col-lg-3" : "col-xl-3"
            },
            [
              _vm.imready
                ? _c(
                    "v-lesson-photo",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        options: {
                          photo: _vm.userPhoto,
                          size: "130px",
                          lessonType: "Individual"
                        }
                      }
                    },
                    [
                      _vm._t("default", function() {
                        return [
                          !_vm.statusFormat
                            ? _c("input", {
                                ref: "inputPhoto",
                                staticClass: "fileinput",
                                attrs: {
                                  id: "input-file",
                                  type: "file",
                                  accept: "image/png,image/jpg,image/jpeg"
                                },
                                on: { change: _vm.uploadPhoto }
                              })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.statusFormat
                ? _c("v-btn", {
                    staticClass: "w-100",
                    attrs: {
                      fontSize: "12px",
                      fontWeight: "500",
                      max: "150px",
                      txt: _vm.$t("formats.fm1.form.btnPhoto"),
                      disabled: _vm.sending
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.actionInputPhoto.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.lastName"),
                  maxlength: _vm.$v.form.lastNameF.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.lastNameF.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.lastNameF,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "lastNameF", $$v)
                  },
                  expression: "form.lastNameF"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.motherLastName"),
                  maxlength: _vm.$v.form.lastNameM.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.lastNameM.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.lastNameM,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "lastNameM", $$v)
                  },
                  expression: "form.lastNameM"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.name"),
                  maxlength: _vm.$v.form.name.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.name.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.gender.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t("formats.fm1.form.gender.placeholder"),
                  opc: _vm.gender,
                  fieldVal: "val",
                  fieldTxt: "txt",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.gender.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.gender,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gender", $$v)
                  },
                  expression: "form.gender"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  type: "email",
                  label: _vm.$t("formats.fm1.form.email"),
                  maxlength: _vm.$v.form.email.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.email.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  type: "tel",
                  label: _vm.$t("formats.fm1.form.addressPhone"),
                  maxlength: _vm.$v.form.landlinePhone.$params.maxLength.max,
                  options: _vm.opcInput,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.landlinePhone.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.landlinePhone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "landlinePhone", $$v)
                  },
                  expression: "form.landlinePhone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  type: "tel",
                  label: _vm.$t("formats.fm1.form.phone1"),
                  maxlength: _vm.$v.form.mobilePhone1.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.mobilePhone1.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.mobilePhone1,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "mobilePhone1", $$v)
                  },
                  expression: "form.mobilePhone1"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  type: "tel",
                  label: _vm.$t("formats.fm1.form.phone2"),
                  maxlength: _vm.$v.form.mobilePhone2.$params.maxLength.max,
                  options: _vm.opcInput,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.mobilePhone2.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.mobilePhone2,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "mobilePhone2", $$v)
                  },
                  expression: "form.mobilePhone2"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2 align-items-end" }, [
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c(
                "label",
                {
                  staticClass: "vf-label mb-1",
                  style: { opacity: _vm.sending ? "0.6" : null }
                },
                [_vm._v(_vm._s(_vm.$t("formats.fm1.form.birthday")))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row ml-n1 mr-n1" }, [
                _c(
                  "div",
                  { staticClass: "col-4 pl-1 pr-1" },
                  [
                    _c("v-select", {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.birthday.day,
                        opc: _vm.days,
                        fieldVal: "val",
                        fieldTxt: "val",
                        required: true,
                        disabled: _vm.sending,
                        onlyRead: _vm.statusFormat
                      },
                      nativeOn: {
                        input: function($event) {
                          return (e => {
                            _vm.birthday = {
                              ..._vm.birthday,
                              day: parseInt(e.target.value)
                            }
                          }).apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-4 pl-1 pr-1" },
                  [
                    _c("v-select", {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.birthday.month,
                        opc: _vm.months,
                        fieldVal: "id",
                        fieldTxt: "val",
                        required: true,
                        disabled: _vm.sending,
                        onlyRead: _vm.statusFormat
                      },
                      nativeOn: {
                        input: function($event) {
                          return (e => {
                            _vm.birthday = {
                              ..._vm.birthday,
                              month: parseInt(e.target.value)
                            }
                          }).apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-4 pl-1 pr-1" },
                  [
                    _c("v-select", {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.birthday.year,
                        opc: _vm.years,
                        fieldVal: "val",
                        fieldTxt: "val",
                        required: true,
                        disabled: _vm.sending,
                        onlyRead: _vm.statusFormat
                      },
                      nativeOn: {
                        input: function($event) {
                          return (e => {
                            _vm.birthday = {
                              ..._vm.birthday,
                              year: parseInt(e.target.value)
                            }
                          }).apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  disabled: true,
                  label: _vm.$t("formats.fm1.form.age"),
                  options: _vm.opcInput
                },
                model: {
                  value: _vm.age,
                  callback: function($$v) {
                    _vm.age = $$v
                  },
                  expression: "age"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.placeOfBirth"),
                  maxlength: _vm.$v.form.birthplace.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.birthplace.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.birthplace,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "birthplace", $$v)
                  },
                  expression: "form.birthplace"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.civilStatus.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t(
                    "formats.fm1.form.civilStatus.placeholder"
                  ),
                  opc: _vm.civilStatus,
                  fieldVal: "val",
                  fieldTxt: "val",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.maritalStatus.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.maritalStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "maritalStatus", $$v)
                  },
                  expression: "form.maritalStatus"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.address"),
                  maxlength: _vm.$v.form.homeAddress.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.homeAddress.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.homeAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "homeAddress", $$v)
                  },
                  expression: "form.homeAddress"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.scholarship.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t(
                    "formats.fm1.form.scholarship.placeholder"
                  ),
                  opc: _vm.scholarship,
                  fieldVal: "val",
                  fieldTxt: "val",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.educationLevel.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.educationLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "educationLevel", $$v)
                  },
                  expression: "form.educationLevel"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.schoolName"),
                  maxlength: _vm.$v.form.schoolName.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.schoolName.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.schoolName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "schoolName", $$v)
                  },
                  expression: "form.schoolName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-3 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.average"),
                  maxlength: _vm.$v.form.schoolScore.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.schoolScore.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.schoolScore,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "schoolScore", $$v)
                  },
                  expression: "form.schoolScore"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row align-items-end mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-4 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-radio", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.onScholarship"),
                  fieldVal: "val",
                  fieldTxt: "txt",
                  opc: _vm.yesNo,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.scholarshipStatus.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.scholarshipStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "scholarshipStatus", $$v)
                  },
                  expression: "form.scholarshipStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.typeOfScholarship.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t(
                    "formats.fm1.form.typeOfScholarship.placeholder"
                  ),
                  opc: _vm.typeOfScholarship,
                  fieldVal: "val",
                  fieldTxt: "val",
                  disabled:
                    !_vm.form.scholarshipStatus ||
                    _vm.form.scholarshipStatus == "" ||
                    _vm.sending,
                  required: true,
                  error:
                    _vm.error &&
                    _vm.form.scholarshipStatus == true &&
                    _vm.scholar.scholarshipType == "",
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.scholar.scholarshipType,
                  callback: function($$v) {
                    _vm.$set(_vm.scholar, "scholarshipType", $$v)
                  },
                  expression: "scholar.scholarshipType"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.institutionName"),
                  maxlength:
                    _vm.$v.scholar.scholarshipInstitution.$params.maxLength.max,
                  options: _vm.opcInput,
                  disabled:
                    !_vm.form.scholarshipStatus ||
                    _vm.form.scholarshipStatus == "" ||
                    _vm.sending,
                  required: true,
                  error:
                    _vm.error &&
                    _vm.form.scholarshipStatus == true &&
                    _vm.scholar.scholarshipInstitution == "",
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.scholar.scholarshipInstitution,
                  callback: function($$v) {
                    _vm.$set(_vm.scholar, "scholarshipInstitution", $$v)
                  },
                  expression: "scholar.scholarshipInstitution"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row align-items-end mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-4 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-radio", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.job"),
                  fieldVal: "val",
                  fieldTxt: "txt",
                  opc: _vm.yesNo,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.workStatus.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.workStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "workStatus", $$v)
                  },
                  expression: "form.workStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-textField", {
                key: "",
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.institutionJob"),
                  maxlength: _vm.$v.work.workCompany.$params.maxLength.max,
                  options: _vm.opcInput,
                  disabled:
                    !_vm.form.workStatus ||
                    _vm.form.workStatus == "" ||
                    _vm.sending,
                  required: true,
                  error:
                    _vm.error &&
                    _vm.form.workStatus == true &&
                    _vm.work.workCompany == "",
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.work.workCompany,
                  callback: function($$v) {
                    _vm.$set(_vm.work, "workCompany", $$v)
                  },
                  expression: "work.workCompany"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-4"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.jobSchedule"),
                  maxlength: _vm.$v.work.workSchedule.$params.maxLength.max,
                  options: _vm.opcInput,
                  disabled:
                    !_vm.form.workStatus ||
                    _vm.form.workStatus == "" ||
                    _vm.sending,
                  required: true,
                  error:
                    _vm.error &&
                    _vm.form.workStatus == true &&
                    _vm.work.workSchedule == "",
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.work.workSchedule,
                  callback: function($$v) {
                    _vm.$set(_vm.work, "workSchedule", $$v)
                  },
                  expression: "work.workSchedule"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-4 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-6"
                  : "col-xl-6 col-lg-12"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.liveWith.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t("formats.fm1.form.liveWith.placeholder"),
                  opc: _vm.liveWith,
                  fieldVal: "val",
                  fieldTxt: "val",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.livingWith.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.livingWith,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "livingWith", $$v)
                  },
                  expression: "form.livingWith"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.supportPerson"),
                  maxlength: _vm.$v.form.emergencyContact.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.emergencyContact.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.emergencyContact,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "emergencyContact", $$v)
                  },
                  expression: "form.emergencyContact"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-4 col-sm-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-3"
                  : "col-xl-3 col-lg-6"
            },
            [
              _c("v-textField", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.supportPhone"),
                  maxlength: _vm.$v.form.emergencyPhone.$params.maxLength.max,
                  options: _vm.opcInput,
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.emergencyPhone.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.emergencyPhone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "emergencyPhone", $$v)
                  },
                  expression: "form.emergencyPhone"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            {
              staticClass: "col-md-6 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-5"
                  : "col-xl-5 col-lg-6"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.dadScholarship.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t(
                    "formats.fm1.form.dadScholarship.placeholder"
                  ),
                  opc: _vm.parentScholarship,
                  fieldVal: "val",
                  fieldTxt: "val",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.fatherEduLevel.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.fatherEduLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fatherEduLevel", $$v)
                  },
                  expression: "form.fatherEduLevel"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-6 offset-xl-1 mb-3",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-5 offset-lg-1"
                  : "col-xl-5 offset-xl-1 col-lg-6"
            },
            [
              _c("v-select", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("formats.fm1.form.momScholarship.label"),
                  options: _vm.opcInput,
                  defaultOpc: _vm.$t(
                    "formats.fm1.form.momScholarship.placeholder"
                  ),
                  opc: _vm.parentScholarship,
                  fieldVal: "val",
                  fieldTxt: "val",
                  required: true,
                  disabled: _vm.sending,
                  error: _vm.error && _vm.$v.form.motherEduLevel.$invalid,
                  onlyRead: _vm.statusFormat
                },
                model: {
                  value: _vm.form.motherEduLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "motherEduLevel", $$v)
                  },
                  expression: "form.motherEduLevel"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            {
              staticClass: "col-md-6",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-5"
                  : "col-xl-5 col-lg-12"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 mb-3",
                    class:
                      _vm.statusMenu || !_vm.statusFormat
                        ? "col-lg-12"
                        : "col-xl-12 col-lg-6"
                  },
                  [
                    _c("v-radio", {
                      staticClass: "w-100",
                      attrs: {
                        label: _vm.$t("formats.fm1.form.dadStatus"),
                        fieldVal: "val",
                        fieldTxt: "txt",
                        opc: _vm.dadStatusOpc,
                        disabled: _vm.sending,
                        error: _vm.error && _vm.$v.form.fatherDeceased.$invalid,
                        onlyRead: _vm.statusFormat
                      },
                      model: {
                        value: _vm.form.fatherDeceased,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fatherDeceased", $$v)
                        },
                        expression: "form.fatherDeceased"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 mb-3",
                    class:
                      _vm.statusMenu || !_vm.statusFormat
                        ? "col-lg-12"
                        : "col-xl-10 col-lg-6"
                  },
                  [
                    _c("v-textField", {
                      staticClass: "w-100",
                      attrs: {
                        label: _vm.$t("formats.fm1.form.addressJobDad"),
                        maxlength:
                          _vm.$v.form.fatherWorkplace.$params.maxLength.max,
                        options: _vm.opcInput,
                        required: _vm.form.fatherDeceased === false,
                        disabled:
                          _vm.form.fatherDeceased === true ||
                          _vm.form.fatherDeceased === "" ||
                          _vm.sending,
                        error:
                          _vm.error &&
                          _vm.form.fatherDeceased === false &&
                          _vm.form.fatherWorkplace == "",
                        onlyRead: _vm.statusFormat
                      },
                      model: {
                        value: _vm.form.fatherWorkplace,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fatherWorkplace", $$v)
                        },
                        expression: "form.fatherWorkplace"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-6",
              class:
                _vm.statusMenu || !_vm.statusFormat
                  ? "col-lg-5 offset-lg-1"
                  : "col-xl-5 offset-xl-1 col-lg-12"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 mb-3",
                    class:
                      _vm.statusMenu || !_vm.statusFormat
                        ? "col-lg-12"
                        : "col-xl-12 col-lg-6"
                  },
                  [
                    _c("v-radio", {
                      staticClass: "w-100",
                      attrs: {
                        label: _vm.$t("formats.fm1.form.momStatus"),
                        fieldVal: "val",
                        fieldTxt: "txt",
                        opc: _vm.monStatusOpc,
                        disabled: _vm.sending,
                        error: _vm.error && _vm.$v.form.motherDeceased.$invalid,
                        onlyRead: _vm.statusFormat
                      },
                      model: {
                        value: _vm.form.motherDeceased,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "motherDeceased", $$v)
                        },
                        expression: "form.motherDeceased"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 mb-3",
                    class:
                      _vm.statusMenu || !_vm.statusFormat
                        ? "col-lg-12"
                        : "col-xl-10 col-lg-6"
                  },
                  [
                    _c("v-textField", {
                      staticClass: "w-100",
                      attrs: {
                        label: _vm.$t("formats.fm1.form.addressJobMom"),
                        maxlength:
                          _vm.$v.form.motherWorkplace.$params.maxLength.max,
                        options: _vm.opcInput,
                        required: _vm.form.motherDeceased === false,
                        disabled:
                          _vm.form.motherDeceased === true ||
                          _vm.form.motherDeceased === "" ||
                          _vm.sending,
                        error:
                          _vm.error &&
                          _vm.form.motherDeceased === false &&
                          _vm.form.motherWorkplace == "",
                        onlyRead: _vm.statusFormat
                      },
                      model: {
                        value: _vm.form.motherWorkplace,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "motherWorkplace", $$v)
                        },
                        expression: "form.motherWorkplace"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        !_vm.statusFormat
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-btn", {
                  staticClass: "w-100",
                  attrs: {
                    txt: _vm.$t("formats.fm1.form.btn"),
                    height: "40px",
                    max: "450px",
                    fontSize: "1rem",
                    fontWeight: "500",
                    spinner: _vm.sending
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.submit.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("v-crop-image", {
        attrs: {
          bgIMG: _vm.imgFile,
          show: _vm.statusCropImage,
          statusBtn: _vm.statusBtn
        },
        model: {
          value: _vm.imgCrob,
          callback: function($$v) {
            _vm.imgCrob = $$v
          },
          expression: "imgCrob"
        }
      }),
      _vm._v(" "),
      _vm.imready && _vm.statusFormat && _vm.pdfData.createdDate
        ? _c("v-identification-pdf", {
            ref: "pdf",
            attrs: { data: _vm.pdfData }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "v-border-top col-12 mb-4" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }