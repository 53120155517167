var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-chat-lesson pl-sm-4 pl-3 pt-3 pr-sm-4 pr-3 pb-3",
      style: { background: "#fff" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 mb-2 d-flex justify-content-between align-items-center"
          },
          [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("div", {
                staticClass: "v-cl-color-status",
                class: _vm.statusLesson.color
              }),
              _vm._v(" "),
              _c("p", { staticClass: "v-cl-status pl-1 pr-1 mb-0" }, [
                _vm._v(_vm._s(_vm.statusLesson.t))
              ])
            ]),
            _vm._v(" "),
            _vm.lesson.status !== "Finalizada" &&
            _vm.lesson.status !== "Cancelada"
              ? _c("div", { staticClass: "pl-1 pr-1" }, [
                  _c(
                    "div",
                    { staticClass: "row ml-n1 mr-n1 justify-content-end" },
                    [
                      _vm.lesson.modality == "Online" &&
                      _vm.lesson.status == "Activa"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "online",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userType == "tutor" &&
                      _vm.lesson.type == "Grupal" &&
                      _vm.lesson.status == "Activa"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "takeAssistant",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userType == "tutor" && _vm.lesson.status == "Activa"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "stop",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.lesson.status != "Activa"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "cancel",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.lesson.status == "Pendiente" &&
                      _vm.userType == "tutor"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "confirm",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.lesson.status == "Confirmada" &&
                      _vm.userType == "tutor"
                        ? _c(
                            "div",
                            { staticClass: "col-auto pl-1 pr-1" },
                            [
                              _c("v-lesson-btn", {
                                staticClass: "w-100",
                                attrs: {
                                  options: {
                                    type: "start",
                                    btnType: "small",
                                    idLesson: _vm.lesson.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6 mb-2" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c(
              "div",
              { staticClass: "col-auto pl-0 pr-0" },
              [
                _c("v-lesson-photo", {
                  staticClass: "ml-auto mr-auto",
                  attrs: { options: _vm.lessonPhotoOpc }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col pl-2 pr-2",
                style: { maxWidth: `calc( 100% - ${_vm.lessonPhotoOpc.size} )` }
              },
              [
                _c("v-hover-txt", {
                  staticClass: "mb-2",
                  attrs: {
                    colorTxt: "#000",
                    fontSize: "16px",
                    fontWeight: "500",
                    txt: _vm.cardName
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex ml-n1 mr-n1 align-items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-auto pl-1 pr-1" },
                      [
                        _c("v-lesson-tag", {
                          attrs: {
                            type: _vm.lesson.type,
                            options: _vm.tagTypeOpc
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-auto pl-1 pr-1" },
                      [
                        _c("v-lesson-tag", {
                          attrs: { type: _vm.lesson.modality }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-sm-6 mb-2 pt-sm-2 pb-sm-2 d-flex flex-column justify-content-center align-self-start"
          },
          [
            _c(
              "div",
              { staticClass: "v-cl-subject position-relative" },
              [
                _c("svgicon", {
                  staticClass: "v-cl-icon mr-2",
                  attrs: { name: _vm.lesson.icon, color: "#000" }
                }),
                _vm._v(" "),
                _c("v-hover-txt", {
                  attrs: {
                    colorTxt: "#000",
                    fontSize: "16px",
                    fontWeight: "500",
                    txt: _vm.lesson.subject
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.statusTopic
              ? _c("v-lesson-topic", {
                  staticClass: "mt-2",
                  attrs: {
                    options: {
                      lessonId: _vm.lesson.id,
                      topic: _vm.lesson.topic,
                      hideBtn: !(
                        _vm.lesson.status == "Pendiente" ||
                        _vm.lesson.status == "Confirmada"
                      )
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 mb-2" }, [
          _c(
            "p",
            { staticClass: "v-cl-fulldate mb-0 position-relative" },
            [
              _c("svgicon", {
                staticClass: "v-cl-icon mr-2",
                attrs: { name: "calendar", color: "#000" }
              }),
              _vm._v(
                "\n\t    \t\t    " + _vm._s(_vm.fullDate) + "\n\t    \t    "
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("v-lesson-place", {
              attrs: {
                options: {
                  icon: _vm.lesson.placeIcon,
                  place: _vm.lesson.place,
                  modality: _vm.lesson.modality,
                  type: _vm.lesson.type,
                  lessonId: _vm.lesson.id,
                  edit:
                    _vm.lesson.status == "Pendiente" ||
                    _vm.lesson.status == "Confirmada"
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }