/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cash-dollar': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 18H.5a.5.5 0 01-.5-.5v-10A.5.5 0 01.5 7h18a.5.5 0 01.5.5v10a.5.5 0 01-.5.5zM1 17h17V8H1v9z"/><path pid="1" d="M11.5 12H8v-1h3.5a.5.5 0 000-1H10v-.5a.5.5 0 00-1 0v.5H7.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H11v1H7.5a.5.5 0 000 1H9v.5a.5.5 0 001 0V15h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM17.5 6h-16a.5.5 0 010-1h16a.5.5 0 010 1zM16.5 4h-14a.5.5 0 010-1h14a.5.5 0 010 1z"/>'
  }
})
