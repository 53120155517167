var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-lesson-photo position-relative",
      style: {
        height: _vm.size,
        width: _vm.size,
        background: !_vm.statusPhoto ? "#F5A623" : null
      }
    },
    [
      _vm.statusPhoto
        ? _c("div", { staticClass: "v-lesson-img" }, [
            _c("img", {
              attrs: { src: _vm.photo },
              on: {
                error: function($event) {
                  _vm.statusImgDefault = true
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.options.lessonType == "Grupal" && _vm.userType == "tutor"
        ? _c("svgicon", {
            staticClass: "group-icon",
            style: { width: _vm.iconSize, height: _vm.iconSize },
            attrs: { name: "group", color: "#fff" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.options.statusType
        ? _c(
            "div",
            {
              staticClass: "v-lp-type",
              class:
                _vm.options.lessonType == "Grupal"
                  ? "bg-lesson-group"
                  : "bg-lesson-1-on-1",
              style: { width: _vm.sizeType, height: _vm.sizeType }
            },
            [
              _c("svgicon", {
                staticClass: "v-lp-icon",
                style: { width: _vm.iconSizeType, height: _vm.iconSizeType },
                attrs: {
                  name: _vm.options.lessonType == "Grupal" ? "group" : "user2",
                  color: "#fff"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }