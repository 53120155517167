/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark-circle-fill': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M11.5 23c-3.072 0-5.96-1.196-8.132-3.368A11.42 11.42 0 010 11.502c0-3.073 1.196-5.96 3.368-8.133A11.422 11.422 0 0111.5 0c3.072 0 5.96 1.196 8.132 3.369A11.423 11.423 0 0123 11.5c0 3.073-1.196 5.96-3.368 8.131A11.425 11.425 0 0111.5 23z" _fill="#72BD00"/><path pid="1" d="M9.079 16.342a.608.608 0 01-.429-.177L5.02 12.534a.606.606 0 01.856-.856l3.203 3.203 8.045-8.045a.606.606 0 01.856.856l-8.474 8.473a.603.603 0 01-.428.177h.002z" _fill="#FFF"/></g>'
  }
})
