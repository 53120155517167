var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status
    ? _c(
        "div",
        {
          ref: "slide",
          staticClass: "v-slide-animated",
          class: { bound: _vm.boundAnimation, hide: !_vm.showed },
          style: _vm.styleSteps
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }