/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows-split': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 1h-5a.5.5 0 000 1h3.793l-8.146 8.146a.5.5 0 00-.146.354v9a.5.5 0 001 0v-8.793l8-8V6.5a.5.5 0 001 0v-5a.5.5 0 00-.5-.5zM7.854 8.146L1.708 2h3.793a.5.5 0 000-1h-5a.5.5 0 00-.5.5v5a.5.5 0 001 0V2.707l6.146 6.146a.498.498 0 00.708 0 .5.5 0 000-.707z"/>'
  }
})
