<template>
	<div class="v-modal" :class="{ active: status }" v-if="show">
		<!-- ============ Background color ============ -->
		<div class="v-blurColor" @click="!schChange?close():statusMsg=true"></div>

		<div class="v-modal-paper modal-availability d-flex flex-column justify-content-center" :style="{ paddingRight: '1.5rem' }">
            
            <div class="msg-changes d-flex align-items-center" v-if="statusMsg">
                <div class="content-cgh pt-4 pb-4">
                    <h4 class="cgh-title w-100 pl-4 pr-4 mb-5 text-center">{{ $t('availability.modals.specificScheduling.msgChanges') }}</h4>
                    <div class="w-100 d-flex align-items-end">
                        <div class="col-6 pl-5">
                            <v-btn class="w-100" :txt="$t('availability.modals.availability.btnYes')" @click.native="close()" /> 
                        </div>
                        <div class="col-6 pr-5 align-self-end">
                            <v-btn class="w-100" btn="confirm" :txt="$t('availability.modals.availability.btnNo')" @click.native="statusMsg = false" /> 
                        </div>
                    </div>
                </div>
            </div>

            <!-- =============== title =============== -->
            <div class="row w-100 ml-0 mr-0 mb-4">
            	<div class="col pl-0 pr-0">
            		<h3 class="title mb-1"> <svgicon class="icon-clock" name="clock2" />{{ $t('availability.modals.specificScheduling.t') }}</h3>
            		<h4 class="subtitle mb-0">{{ $t('availability.modals.specificScheduling.d') }}</h4>
            	</div>
            </div>

            <perfect-scrollbar ref="scroll" class="w-100 pr-3 pr-sm-5" :options="options" tag="div">

                <!-- =============== Btn days =============== -->
                <div class="row w-100 ml-0 mr-0 mb-4 pr-0">
                    <div class="col pl-1 pr-1 text-center" v-for="(d,i) in checkboxesDays ">
                        <div class="checkbox-specific-day" :class="{ active: days.find( item => item== d.value), disabled: disabledDisponibility, check: d.value == datas.day }">
                            <span class="txt-day">{{ d.day }}</span>
                            <input type="checkbox" :id="'v-check-'+i" :value="d.value" :disabled="disabledDisponibility" v-model="days">
                        </div>
                    </div>
                </div>

                <!-- ================ Schedule ranges =============== -->
            	<v-sch-by-day class="mr-5 mr-md-0" :initTime="initTime" :available="available" :endTime="endTime" :minRangetime="minRangetime" @close="!schChange?close():statusMsg=true" :setTimes="times" :disabledBtnunavailable="true" :activeBtnClear="true" :cont="cont" @addCont="cont++" @changeAvailable=" av => available=av" @getTimes="t => times=t" :disabledBtns="sending" :schedulesRangesBlock="schedulesRangesBlock" :disabledDisponibility="disabledDisponibility" v-model="schedule" @applyToAll="applyToAll"/>
            </perfect-scrollbar>

		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		props:{
			show: { type: Boolean, default: false, required: true },
            datas: { type: Object, required: true },
            subject_id: { type: Number, required: true },
			initTime: { type: String, default: '06:00', required: false },
			endTime: { type: String, default: '21:00', required: false },
            minRangetime: { type: Number, default: 30, required: false },
            schedulesRangesBlock: { type: Array, default: () => [], required: false },
            disabledDisponibility: { default: false, required: false },
            value: ''
		},
		data(){
			return{
				status: true,
                available: true,
                options: {
                    suppressScrollX: true
                },
                statusMsg: false,
                times: null,
                schedule: [],
                sending: false,
                cont: 0,
                days: []
			}
		},
		computed:{
            ...mapState(['auth', 'cutomizeSubject']),
            schChange(){ // True is something change
                let change = false;
                //if (this.schedule){
                    if (this.schedule.length != this.datas.ranges.length)
                        change = true;
                    else
                        for (var i = 0; i < this.schedule.length; i++) {
                           const sch = this.datas.ranges.find( item => item.from === this.schedule[i].from && item.to === this.schedule[i].to );
                            if (!sch){
                                change = true;
                                break;
                            }
                        }
                //}

                return change;
            },
            checkboxesDays(){
            	return [ 
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.su'), value: 'Domingo' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.mo'), value: 'Lunes' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.tu'), value: 'Martes' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.we'), value: 'Miercoles' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.th'), value: 'Jueves' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.fr'), value: 'Viernes' },
            	    { day: this.$t('availability.modals.specificScheduling.checkboxesDays.sa'), value: 'Sabado' }
            	]
            }
		},
		methods:{
            ...mapMutations(['setNotification']),
            ...mapActions(['axiosRequest', 'changeStatusSubject']),
			onKeyPress(e){
                if ('key' in e){
                	if (e.key === 'Escape' || e.key === 'Esc')
                		this.close();
                }
                else if(e.keyCode === 27)
                	this.close();
			},
			close(){
                this.status = false;
                document.removeEventListener('keydown', this.onKeyPress);
                setTimeout( () => {
                    this.$emit('closeAvailability');
                    this.schedule = [];
                    this.times = null;
                    this.cont = 0;
                    this.days = [];
                    this.statusMsg = false;
                }, 400);
			},
			async applyToAll(){
                this.$Progress.start();
                if (!this.disabledDisponibility){
                    this.sending = true;
                    let newCurrentSChedule = new Map(),
                        schedules = [],
                        schedule = [];

                    if (this.available)
                        schedule = this.schedule.reduce( (arr, item) => {
                            arr.push({ from: item.from, to: item.to });
                            return arr;
                        },[]);

                    for (var [key, value] of this.value.entries()) {
                        const day = this.days.find( d => d==key),
                              sch = this.value.get(key);
                        if (day){
                            schedules.push({ week_day: key, toDelete: schedule.length==0, hours: schedule });
                            newCurrentSChedule.set(key, { ...sch, ranges: schedule });
                        }
                        else{
                            newCurrentSChedule.set(key, sch);
                        }
                    }

                    await this.axiosRequest({ config: {
                        method: 'POST',
                        url: `${this.auth.api}/subject/${this.subject_id}/schedules`,
                        data: { days: schedules },
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data )
                    .then( data => {
                        this.$Progress.finish();
                        this.sending = false;
                        this.$emit('input', newCurrentSChedule);
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });

                        if (schedule.length > 0 && !this.cutomizeSubject.statusSchedule && this.available)
                            this.changeStatusSubject({ places: this.cutomizeSubject.statusPlaces, availability: 1 });
                        else if(schedule.length == 0 && this.cutomizeSubject.statusSchedule){ // Chech if scheduling is empty
                            let isEmpty = true;
                            for (let [key,val] of newCurrentSChedule.entries()) {
                                if (val.ranges.length > 0){
                                    isEmpty = false
                                    break;
                                }
                            }
                            if (isEmpty)
                                this.changeStatusSubject({ places: this.cutomizeSubject.statusPlaces, availability: 0 });
                        }

                        this.close();
                    } ).catch( err => {
                        this.sending = false;
                        this.$Progress.finish();
                        this.setNotification({ type: 'error', msg: err.response.data.error.message  });
                    } )
                }
			}
		},
		watch:{
			show: function(newVal){
                this.$nextTick( () => {
                    if (newVal){
                        this.status = true;
                        this.schedule = this.datas.ranges;
                        this.days.push(this.datas.day);
                        this.available = this.schedule.length==0?false:true;
                        document.addEventListener('keydown', this.onKeyPress);
                    }
                })
			}
		}
	}
</script>