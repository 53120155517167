/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip-horizontal': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.5 20h-8a.5.5 0 01-.461-.694l8-19A.5.5 0 019 .5v19a.5.5 0 01-.5.5zm-7.247-1H8V2.976L1.253 19zM19.5 20h-8a.5.5 0 01-.5-.5V.5a.5.5 0 01.961-.194l8 19A.498.498 0 0119.5 20zM12 19h6.747L12 2.976V19z"/>'
  }
})
