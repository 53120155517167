/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause-circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.5 20c-2.538 0-4.923-.988-6.718-2.782S0 13.038 0 10.501c0-2.538.988-4.923 2.782-6.718S6.962 1 9.5 1c2.538 0 4.923.988 6.718 2.783S19 7.963 19 10.501s-.988 4.923-2.782 6.717A9.438 9.438 0 019.5 20zm0-18C4.813 2 1 5.813 1 10.5S4.813 19 9.5 19c4.687 0 8.5-3.813 8.5-8.5S14.187 2 9.5 2z"/><path pid="1" d="M7.5 15h-1c-.827 0-1.5-.673-1.5-1.5v-6C5 6.673 5.673 6 6.5 6h1C8.327 6 9 6.673 9 7.5v6c0 .827-.673 1.5-1.5 1.5zm-1-8a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-1zM12.5 15h-1c-.827 0-1.5-.673-1.5-1.5v-6c0-.827.673-1.5 1.5-1.5h1c.827 0 1.5.673 1.5 1.5v6c0 .827-.673 1.5-1.5 1.5zm-1-8a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-1z"/>'
  }
})
