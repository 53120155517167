/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rank': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.5 16a.494.494 0 01-.242-.063l-8.757-4.865-8.757 4.865a.5.5 0 01-.486-.874l9-5a.5.5 0 01.486 0l9 5A.5.5 0 0118.5 16z"/>'
  }
})
