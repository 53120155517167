/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-sharp': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"/>'
  }
})
