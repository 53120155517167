
   import { Component, Vue, Watch } from 'vue-property-decorator';
   import { State, Mutation, Action } from 'vuex-class';

   @Component
   export default class TutorScheduleSession extends Vue {
        @State( state => state.i18n.locale ) lang;
        @State( state => state.auth.api ) api;
        @State( state => state.auth.token ) token;
        
        @Mutation('setNotification') notification;
        @Action('axiosRequest') axios;

        subject: any = null;
        dateTime: any = null;
        students: Array<object> = [];
        place: any = null;
        sending: boolean = false;

        get statusPlace(): boolean{
            if(this.subject?.id) 
                return this.subject.modality == 'Presencial';
            else
                return false;
        }

        get durationTime(): number{
            if(this.subject?.duration)
                return this.subject.duration;
            else
                return 30;
        }

        get type(): any{
            if(this.subject?.type)
                return this.subject.type;
            else
                return null;
        }

        get quote(): number{
            if(this.subject?.type == 'Individual')
                return 1;
            else if(this.subject?.limit)
                return this.subject.limit;
            else
                return 0;
        }

        get fullDate(): any{
            if(this.dateTime?.date && this.dateTime?.time){
                 const date = (this as any).$moment(this.dateTime.date, 'YYYY-MM-DD').locale(this.lang).format(this.$t('formatDateC'));

                 return `${date} ${this.dateTime.time}`;
            }else
                return null;
        }

        get recurrence(): number{
           return this.students.filter( (s: any) => !s.id ).length;
        }

        get enrolledStudents(): number{
            return this.students.length;
        }

        get statusForm(): boolean {
            if(this.subject && (this.dateTime?.date && this.dateTime?.date != 'Invalid date') && this.dateTime?.time && this.students.length > 0 && ((this.subject?.type == 'Individual' && this.subject?.modality != 'Online' && this.place) || (this.subject?.type == 'Grupal' && this.place) || this.subject?.modality == 'Online'))
                return true;
            else
                return false;
        }

        submit(): void{
            if(this.statusForm){
                this.sending = true;

                this.axios({ config: {
                    method: 'POST',
                    url: `${this.api}/tutors/lessons`,
                    headers: { Authorization: this.token },
                    data: {
                        date: this.dateTime.date,
                        time: (this as any).$moment(this.dateTime.time.split(' - '), 'hh:mm A').format('kk:mm'),
                        subjectId: this.subject.id,
                        type: this.subject.type,
                        mode: this.subject.modality,
                        placeId: this.place?.id?this.place?.id:0,
                        place: this.place?.name && this.subject.type == 'Grupal' && this.subject.modality != 'Online'?this.place.name:'',
                        students: this.students.reduce( (arr: Array<object>, s: any) => {
                            arr = [ ...arr, { name: s.fullName, email: s.email } ];

                            return arr;
                        }, [])
                    }
                }}).then( resp => resp.data )
                .then( data => {
                    this.notification({ type: "success", msg: this.$t('scheduleT.students.alertSuccess') });
                    this.$router.push({ name : 'lessons-tutor' })
                    
                    this.sending = false;
                }).catch( err => {
                    this.sending = false;
                    if(err.response.status == 409)
                        this.notification({ type: "warning", msg: this.$t(`scheduleT.alerts.${err.response.data.error.name}`) });
                });
            }
        }

       @Watch('subject')
       resetVars1(){
            if(this.dateTime?.time)
                this.dateTime = { date: this.dateTime.date, time: '' };
            
            if(this.place)
                this.place = null;
       }
   }
