<template>
	<div id="error" class="error-404 d-flex flex-column align-items-center justify-content-center">
		<!-- ============ Msg 404 =========== -->
		<div>
			<h2 class="txt-error mb-0" :class="{ 'color-tutor-main' : auth.permission==='tutor', 'color-student-main' : auth.permission!=='tutor' }">{{ $t('404.error') }}</h2>
		    <p class="descrip-error mb-0">{{ $t('404.msg') }}</p>
		</div>
		<!-- ============ Circles =========== -->
		<div class="circles-error mb-4 mb-5 w-100 d-flex justify-content-center">
			<div class="circle-transform">
            <div class="content-circle">
			    <div class="circle" :class="{ 'bgColor-tutor-main' : auth.permission==='tutor', 'bgColor-student-main' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle">
			    <div class="circle" :class="{ 'bgColor-tutor-light' : auth.permission==='tutor', 'bgColor-student-light' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle" >
			    <div class="circle" :class="{ 'bgColor-tutor-light' : auth.permission==='tutor', 'bgColor-student-light' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle" >
			    <div class="circle" :class="{ 'bgColor-tutor-main' : auth.permission==='tutor', 'bgColor-student-main' : auth.permission!=='tutor' }"></div>
		    </div>
		    </div>
		</div>
		<!-- ============ btn Dashboard =========== -->
		<v-btn :txt="$t('404.btnD')" min="180px" height="32px" @click.native="redirect" v-if="auth.permission!=''" />
		<!-- ============ btn confirm email =========== -->
		<v-btn :txt="$t('404.btnE')" min="180px" height="32px" @click.native="redirect" v-else/>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		computed:{
			...mapState(['auth'])
		},
		methods: {
			redirect(){
				if (this.auth.permission !== '')
                    this.$router.push({ name : 'dashboard-'+this.auth.permission });
                else
                	this.$router.push({ name : 'email-validatation' });
			}
		}
	}
</script>