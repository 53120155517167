var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-places row justify-content-center w-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3"
        },
        [
          _c(
            "div",
            { staticClass: "btn-prev-step order-0 col-auto col-md" },
            [
              _c("v-prev-link", {
                staticClass: "col-12 pt-3 pb-3 pl-0 pr-0",
                attrs: {
                  txt: _vm.$t("scheduleLesson.place.prev"),
                  type: "step",
                  click: () => _vm.prevAction()
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass:
                "title-step order-2 order-md-1 col-12 col-md-auto mb-0 text-center"
            },
            [_vm._v(_vm._s(_vm.$t("scheduleLesson.place.t")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "btn-close-step order-1 order-md-2 col-auto col-md text-right"
            },
            [
              _vm.closeAction
                ? _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeAction()
                        }
                      }
                    },
                    [_c("svgicon", { attrs: { name: "cross2" } })],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-places-content col-12" },
        [
          _c(
            "perfect-scrollbar",
            {
              staticClass: "v-places-list w-100 pl-0 mb-4",
              attrs: { tag: "ul" }
            },
            _vm._l(_vm.places, function(item, index) {
              return _c(
                "li",
                {
                  staticClass:
                    "radio-place d-flex justify-content-between align-items-center pt-2 pb-2 pl-2 pl-sm-3 pr-md-4 pr-3 w-100",
                  class: { active: _vm.value.id == item.id },
                  attrs: { color: (index + 1) % 2 == 0 ? "clear" : "dark" },
                  on: {
                    click: function($event) {
                      return _vm.handleVal(item)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center mr-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "icon-place mr-2",
                        style: "background: #92117F"
                      },
                      [
                        _c("svgicon", {
                          staticClass: "icon",
                          attrs: { name: item.icon.icon_name }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "name-place" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "val-place" })
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("v-btn", {
            staticClass: "float-right",
            attrs: {
              txt: _vm.$t("scheduleLesson.btnNext"),
              fontSize: "20px",
              type: "Next",
              min: "200px",
              disabled: !_vm.value.id
            },
            nativeOn: {
              click: function($event) {
                return _vm.nextAction()
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }