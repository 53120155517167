<template>
	<div class="v-modal" :class="{ active: status }" v-if="show">
		<!-- ============ Background color ============ -->
		<div class="v-blurColor" @click="close"></div>

		<!-- =============== Modal =============== -->
		<div class="v-modal-paper modal-vacation-mode d-flex flex-wrap justify-content-center text-center p-4 p-sm-5">
			<h3 class="title mb-3 w-100 mt-3 mt-sm-4">{{ $t('availability.modals.vacationMode.t') }}</h3>

			<div class="icon-plane mb-3">
				<svgicon class="icon" name="plane" />
			</div>

			<p class="descrip w-100 mb-4 mb-sm-5 mt-3">{{ auth.user.vacation_mode?$t('availability.modals.vacationMode.d2'):$t('availability.modals.vacationMode.d1') }}</p>

			<v-btn class="mb-4 w-100" height="40px" :txt="auth.user.vacation_mode?$t('availability.modals.vacationMode.btnAvailable'):$t('availability.modals.vacationMode.btndisabled')" :disabled="sending" @click.native="changeVacationMode" />

			<button class="btn-cancel" @click="close">{{ $t('availability.btn.cancel') }}</button>
		</div>
	</div>
</template>
<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	export default{
		props:{
			show: { type: Boolean, default: false, required: true }
		},
		data(){
			return{
				status: false,
				sending: false
			}
		},
		computed:{
			...mapState(['auth'])
		},
		methods:{
			...mapMutations(['setStatusTutor', 'setNotification']),
		    ...mapActions(['axiosRequest']),
			changeVacationMode(){ // Change status vacation mode
				this.$Progress.start();
				if (!this.sending){
					this.sending = true;
				    this.axiosRequest({ config : {
					    method: 'PUT',
					    url: `${this.auth.api}/tutor/vacations`,
					    data: { vacation_mode: !this.auth.user.vacation_mode }, 
					    headers: { authorization : this.auth.token }
				    }}).then( resp => resp.data)
				    .then( data => {
					    this.$Progress.finish();
					    this.sending = false;
					    this.setStatusTutor(!this.auth.user.vacation_mode);
					    this.setNotification({ type: data.type, msg: data.message});
                        this.close();
				    }).catch( error => {
					    this.$Progress.fail();
					    this.sending = false;
				    });
				}
			},
			onKeyPress(e){
                if ("key" in e){
                	if (e.key === 'Escape' || e.key === 'Esc')
                		this.close();
                }
                else if(e.keyCode === 27)
                	this.close();
			},
            close(){
            	this.status= false;
            	document.removeEventListener('keydown', this.onKeyPress);
            	setTimeout( () => {
                    this.$emit('closeVacationMode');
            	},400);
            }
		},
		watch:{
			show: function(newVal){
				this.$nextTick( () => {
					if (newVal && !this.status){
						this.status = true;
						document.addEventListener('keydown', this.onKeyPress);
					}
				} )
			}
		}
	}
</script>