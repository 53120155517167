
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';

    @Component
    export default class lessonInfoTop extends Vue{
        @Prop({ type: Object, required: true }) readonly lesson!: any;

        @State( state => state.auth.permission ) userType;
        @State( state => state.i18n.locale ) lang;

        get t(): any{
            return (this as any).$t;
        }

        get moment(): any{
            return (this as any).$moment;
        }

        get statusLesson(): any{
        	if (this.lesson.status == 'Pendiente')
        		return { color: 'bg-lesson-pending', t: this.t('notifications.status.pending.t') };
        	else if(this.lesson.status == 'Confirmada')
        		return { color: 'bg-lesson-upcoming', t: this.t('notifications.status.upcoming.t') };
        	else if(this.lesson.status == 'Activa')
        		return { color: 'bg-lesson-ongoing', t: this.t('notifications.status.ongoing.t') };
        	else if(this.lesson.status == 'Cancelada')
        		return { color: 'bg-lesson-canceled', t: this.t('notifications.status.canceled.t') };
        	else if(this.lesson.status == 'Finalizada')
        		return { color: 'bg-lesson-finished', t: this.t('notifications.status.finished.t') };
        	else
            	return {};
        }

        get lessonPhotoOpc(): object{
            return { photo: this.userType == 'student'?this.lesson.tutorPhoto:this.lesson.studentPhoto, lessonType: this.lesson.type, size: '60px' };
        }

        get cardName(): string{
        	if (this.userType == 'student')
        		return this.lesson.tutor;
        	else{
        		if (this.lesson.type == 'Grupal')
        			return this.t('groupLarge');
        		else
        			return this.lesson.student;
        	}
        }

        get tagTypeOpc(): object{
        	if (this.lesson.type == 'Grupal')
        		return { idLesson: this.lesson.id, lessonQuota: this.lesson.participantsTotal, lessonLimit: this.lesson.limit }
        	else
        		return {};	
        }

        get fullDate(): string{
        	const date = this.moment.utc(this.lesson.scheduledDate).locale(this.lang).format(this.t('formatDateC')),
        	      time = `${this.moment(this.lesson.scheduledTime, 'HH:mm:ss').format('LT')} - ${this.moment(this.lesson.endScheduledTime, 'HH:mm:ss').format('LT')}`
        	return `${date.charAt(0).toUpperCase()+date.slice(1)}  ${time}`;
        }

        get statusTopic(): boolean{
            if (this.userType == 'student' && this.lesson.type == 'Individual') {
                if ((this.lesson.status == 'Activa' || this.lesson.status == 'Cancelada' || this.lesson.status == 'Finalizada') && this.lesson.topic)
                    return true;
                else if(this.lesson.status == 'Pendiente' || this.lesson.status == 'Confirmada')
                    return true;
                else
                    return false;

            }else if (this.userType == 'tutor' && this.lesson.topic)
                return true;
            else
                return false;
        }
    }
