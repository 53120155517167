import header from './header.vue';
import users from './users.vue';
import toolbar from './toolbar.vue';
import video from './video.vue';
import videoOnboarding from './video-onboarding.vue';
import systemPreferences from './system-preferences.vue';
import devicesSettings from './devices-settings.vue';
import meetingEnded from './meeting-ended.vue';
import joinOnboarding from './join-onboarding.vue';
import modalPermissions from './modal-permissions.vue';
import gridRoom from './grid-room.vue';

const components = Vue => {
	Vue.component('v-room-header', header);
	Vue.component('v-room-users', users);
	Vue.component('v-room-toolbar', toolbar);
	Vue.component('v-room-video', video);
	Vue.component('v-join-onboarding', joinOnboarding);
	Vue.component('v-onboarding-video', videoOnboarding);
	Vue.component('v-system-preferences', systemPreferences);
	Vue.component('v-room-settings', devicesSettings);
	Vue.component('v-meeting-ended', meetingEnded);
	Vue.component('v-modal-permissions', modalPermissions);
	Vue.component('v-grid-room', gridRoom);
}

export default components