<template>
	<div id="account-details" class="container-fluid pb-5">
		<!-- ================ Search ================== -->
		<v-search class="mb-4" ref="search"></v-search>

		<!-- ================ Form ================== -->
		<div class="row justify-content-center">
			<div class="details col-md">
				<form class="d-flex paper justify-content-center flex-wrap p-4 mb-3" @submit.prevent="changeDatas()">
					<div class="editImg mb-3 position-relative">
						<div class="content-img h-100 w-100" @click="clickInputFile" :style="{ background : 'url('+bgIMG+')center center / cover no-repeat' }">
							<input id="input-file" type="file" accept="image/png,image/jpg,image/jpeg" @change="upload" class="fileinput">
							<div for="fileInput" slot="upload-label">
								<div class="content-icon p-right bgColor-student-main">
									<svgicon class="icon" name="camera2" />
								</div>
								<div class="img-edit bgColor-student-light" >
									<svgicon class="icon" name="pencil3"/>
								</div>
							</div>
						</div>
					</div>
					
					<!---->
					<v-textField class="w-100 mb-3" v-model="form.name" :label="$t('accountDetails.form.name')" :required="true" />
					<v-textField class="w-100 mb-3" v-model="form.last_name" :label="$t('accountDetails.form.lastName')" :required="true" />
					<v-textField class="w-100 mb-3" v-model="email" :label="$t('accountDetails.form.email')" :disabled="true" />
					<v-textField class="w-100 mb-3" v-model="campus" :label="$t('accountDetails.form.campus')" :disabled="true" />
					<v-textField class="w-100 mb-3" v-model="form.phone" :label="$t('accountDetails.form.phone')" type="number"/>
					<v-datePicker class="w-100 mb-3" v-model="form.birthday" :label="$t('accountDetails.form.birthday')" :required="true"/>
					<v-select class="w-100 mb-3" v-model="form.gender" :label="$t('accountDetails.form.gender')" :defaultOpc="$t('signUp.form.gender.placeholder')" :opc="gender" fieldVal="val" fieldTxt="txt" :required="true"/>
					<v-select class="w-100 mb-3" v-model="form.career_id" :label="$t('accountDetails.form.major')" :defaultOpc="$t('signUp.form.career.placeholder')" :opc="careers" fieldVal="id" fieldTxt="name" :required="true"/>
					<v-select class="w-100 mb-3" v-model="form.period_id" :label="$t('accountDetails.form.semester')" :defaultOpc="$t('signUp.form.period.placeholder')" :opc="semesters" fieldVal="id" fieldTxt="period" :required="true"/>
					<v-btn class="w-100 mt-3" :txt="$t('accountDetails.form.btn')" type="submit" :disabled="$v.form.$invalid || sending" />
				</form>
				<v-circles :vCircle="3" size="300px"/>
                <v-circles :vCircle="4" size="200px"/>
			</div>
		</div>

		<v-crop-image :bgIMG="imgFile" v-model="imgCrob" :show="statusCropImage" :statusBtn="statusBtn" />
	</div>
</template>
<script>
	import { required, email } from 'vuelidate/lib/validators';
	import { mapState, mapActions } from 'vuex';
	export default{
		data(){
			return{
				campus: '',
				email: '',
				sending: false,
				form : {
					name: '',
					last_name: '',
					phone: '',
					birthday: '',
					gender: '',
					career_id: '',
					period_id: ''
				},
				careers: [],
				semesters: [],
				bgIMG: '',
				imgFile: '',
				imgCrob: '',
				statusCropImage: false,
				statusBtn: false
			}
		},
		computed:{
            ...mapState(['auth']),
            gender(){
            	return [ { val: 'F', txt: this.$t('gender.f') }, 
            	         { val: 'M', txt: this.$t('gender.m') }/*,
            	         { val: 'O', txt: this.$t('gender.o') } */]
            }
		},
		created(){
			this.form.name = this.auth.user.name;
			this.form.last_name = this.auth.user.last_name;
			this.email = this.auth.user.email;
			this.form.phone = this.auth.user.phone;
			this.form.birthday = this.$moment.utc(this.auth.user.birthday).format('YYYY/MM/DD HH:mm:ss');
			this.form.gender = this.auth.user.gender;
			this.form.career_id = this.auth.user.career_id;
			this.form.period_id = this.auth.user.period_id;
			this.bgIMG = this.auth.user.photo;
			this.axiosRequest({ config : {
				method: 'GET',
                url: `${this.auth.api}/campus/${this.auth.user.university_id}/dependencies`
			}}).then( resp => resp.data )
            .then( data => {
                this.careers = data.careers;
                this.semesters = data.semesters;
                this.campus = data.campus;
            }).catch( error => {
                console.log(error)
            });
		},
		methods: {
			...mapActions(['changeUserData','changeUserPhoto', 'axiosRequest']),
			upload(e){
                this.imgFile = e.target.files[0];
                this.statusCropImage = true;
			},
			changeIMG: function(){
				this.statusBtn = true;
				var that = this;
                this.imgCrob.toBlob(function(blob) {
				    var formData = new FormData()
				    formData.append('picture', blob, `filename.jpeg`)
				    that.changeUserPhoto(formData).then( resp => {
				    	that.statusBtn = false;
				    	that.statusCropImage = false;
				    	that.imgFile = '';
				    	that.imgCrob = '';
				    	document.getElementById('input-file').value = null;
				    	that.bgIMG = that.auth.user.photo;
				    } ).catch( err => {
				    	that.statusBtn = false;
				    } );
                }, 'image/jpeg', 0.9)
			},
			clickInputFile: function(){
                document.getElementById('input-file').click();
			},
			changeDatas(){
				if (!this.$v.form.$invalid && !this.sending){
					this.sending = true;
				    this.changeUserData({ form: this.form }).then( resp => {
				    	this.sending = false;
				    } ).catch( err => {
				    	this.sending = false;
				    } );
			    }
			}
		},
		watch:{
			imgCrob: function(newVal){
				if (newVal !== '' && newVal !== false)
                    this.changeIMG();
                else if(newVal === false){
                    this.statusCropImage = false;
                	this.imgFile = '';
                	this.imgCrob = '';
                	document.getElementById('input-file').value = null;
			    }
            }
		},
		validations: {
            form: {
            	name: {
            		required
            	},
				last_name: {
					required
				},
				birthday: {
					required
				},
				gender: {
					required
				},
				career_id: {
					required
				},
				period_id: {
					required
				}
            }
        }
	}
</script>