<template>
	<div id="schedule-lesson">
		<div class="row justify-content-center ml-0 mr-0 mb-5">
            <!-- ================ Title ================== -->
		    <h2 class="main-title mb-0 text-center w-100">{{ $t('schedule.title') }}</h2>

		    <!-- ================ Search ================== -->
		    <v-search class="mt-4 mb-3 w-100" ref="search" />

            <!-- ================ Sub title ================== -->
		    <h3 class="switch-title mb-4 text-center w-100">{{ $t('schedule.sub') }}</h3>
           
            <!-- ==================== switch ===================== -->
            <div class="d-flex justify-content-center mt-2 mb-4 w-100">
            	<v-switch :switchBtns="switchBtns" v-model="type" @input="changeType" :disabledOpc="!statusI && !statusG?'both':!statusI?1:!statusG?2:null"/>
            </div>

            <div class="lists-select row position-relative align-items-center pl-md-3 pr-md-3 mt-3 w-100" :class="type=='Grupal'?'group':''">
                <!-- ==================== List Areas ===================== -->
            	<div class="content-list col-md d-flex justify-content-center">
            		<v-selected-list-items  v-model="place" 
            			                    :title="$t('schedule.area')"
                                            :setListItems="places" 
                                            @changeList="getNextPlaces"
                                            @input="getNextSubjects(''); $refs.subjects.valueSearch = ''"
                                            :statusSpinner="spinnerAreas"
                                            :statusSrollEvent="places.length !== totalPlaces"
                                            :key="`areas-${type}`" />
            	</div>

            	<div class="col-md arrow text-center">
            		<svgicon class="icon" name="chevron-right" />
            	</div>

                <!-- ==================== List Subjects ===================== -->
            	<div class="content-list col-md d-flex justify-content-center">
            		<v-selected-list-items  v-model="subject"
                                            :title="$t('schedule.subject')"
                                            :setListItems="subjects"  
                                            @changeList="getNextSubjects"
                                            @doSomething="goTo"
                                            :statusSearch="true"
                                            :msgEmpty="msgSubject"
                                            :place="place.id"
                                            :statusSpinner="spinnerSubjects"
                                            :statusSrollEvent="subjects.length !== totalSubjects"
                                            :disabledSearch="totalSubjects == 0"
                                            ref="subjects"
                                            :key="`subjects-${place.id}`" />
            	</div>

                <!-- ==================== Circles ===================== -->
            	<v-circles :vCircle="1" :bgColor="type==='Individual'?'main':'yellow'" size="300px"/>
                <v-circles :vCircle="2" :bgColor="type==='Individual'?'main':'yellow'" size="200px"/>
            </div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapActions, mapMutations } from 'vuex';

	export default{
		data(){
			return{
				limitPlaces: 10,
				skipPlaces: 0,
				totalPlaces: 0,
				places: [],
                spinnerAreas: false,
				limitSubjects: 10,
				skipSubjects: 1,
				totalSubjects: 0,
				subjects: [],
				type: '',
				place: {},
				subject: {},
                spinnerSubjects: false,
                statusI: true,
                statusG: true
			}
		},
		computed: {
            ...mapState(['auth','i18n']),
            switchBtns(){
                return [
                    { opc: 'Individual', textBtn: this.$t('lessonType.a'), icon: 'user2' },
                    { opc: 'Grupal', textBtn: this.$t('lessonType.g'), icon: 'group' }
                ]
            },
            msgSubject(){
                if (!this.place.id)
                    return this.$t('schedule.msg')
                else if(this.place.id && this.subjects.length == 0)
                    return this.$t('schedule.msgNoSubjects')
                else
                    return null
            }
		},
        created(){
            this.getPlaces().then(resp=>{
                this.statusI = resp.lessons.individual_lessons;
                this.statusG = resp.lessons.group_lessons;
                this.type = 'Individual';
                if (!this.statusI){
                    this.type = 'Grupal';
                    this.getPlaces();
                }
            });
        },
		methods: {
            ...mapMutations(['setTxtBrowser']),
            ...mapActions(['axiosRequest']),
			getPlaces(addPlaces=false){
                return new Promise( (resolve, reject) => {
                    this.spinnerAreas = true;
                    this.axiosRequest({ config : {
                        method: 'GET',
                        url: `${this.auth.api}/categories`,
                        params: { universityId: this.auth.user.university_id, limit: this.limitPlaces, skip: this.skipPlaces, status: "Activo", type: this.type },
                        headers: { authorization : this.auth.token, 'Content-Type' : 'application/json' }
                    }}).then( resp => resp.data)
                    .then( data => {
                        this.places = addPlaces?this.places.concat(data.categories):data.categories;
                        this.totalPlaces = data.total;
                        this.spinnerAreas = false;
                        resolve(data);
                    }).catch( error => {
                        console.log(error)
                    });
                })
			},
            changeType(){
                this.skipPlaces = 0;
                this.skipSubjects = 1;
                this.place = {};
                this.subjects = [];
                this.getPlaces();
                this.$refs.subjects.valueSearch = '';
            }, 
            getNextPlaces(band=true){
                if (this.totalPlaces > this.places.length) {
                    this.skipPlaces += this.limitPlaces;
                    this.getPlaces(true);
                }
            },
            getNextSubjects(band, search=''){
                if (this.totalSubjects > this.subjects.length && band) {
                    this.skipSubjects += 1;
                    this.getSubjects(this.place.id, true, search);
                }
                else if(!band){
                    this.skipSubjects = 1;
                    this.getSubjects(this.place.id, false, search);
                }
            },
			getSubjects(id, addSubjects=false, searchTxt = ''){
                this.spinnerSubjects = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/subjects/university`,
                    params: { categoryId: id, limit: this.limitSubjects, skip: this.skipSubjects, type: this.type, search: searchTxt },
                    headers: { authorization : this.auth.token, 'Content-Type' : 'application/json' }
                }}).then( resp => resp.data)
                .then( data => {
                    this.subjects = addSubjects?this.subjects.concat(data.resSubjects):data.resSubjects;
                    this.totalSubjects = data.total;
                    this.spinnerSubjects = false;
                }).catch( error => {
                    if (error.response.status === 404)
                        this.subjects = [];
                });
			},
            goTo(){
                this.setTxtBrowser(this.subject.name);
                // Convert all string to lower case and remove all accentes
                let search = this.subject.name.toLowerCase().replace(/á/g,'a').replace(/é/g,'e').replace(/í/g,'i').replace(/ó/g,'o').replace(/ú/g,'u');
                // Encoded Characters
                search = encodeURIComponent(search).replace(/%20/g, "+").replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
                this.$router.push(`/student/search/${this.type.toLowerCase()}/${search}`);
            }  
		},
        watch:{
            place: function(newVal, oldVal){
                if (newVal.id !== oldVal.id)
                    this.totalSubjects = 0;
            }
        }
	}
</script>