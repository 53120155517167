/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clip': {
    width: 10,
    height: 23,
    viewBox: '0 0 10 23',
    data: '<path pid="0" d="M4.839 22.322c-2.95 0-4.713-1.988-4.715-5.317L.142 3.523c0-.268.047-1.216.668-2.105C1.237.807 2.05.078 3.504.078h.003c1.487 0 2.295.678 2.711 1.247.598.819.663 1.772.663 2.143L6.864 17.01c0 .382 0 1.28-.617 1.898-.377.377-.898.568-1.547.568h-.005c-1.226 0-1.959-.923-1.96-2.47L2.73 3.506h1.28l.006 13.497c0 1.18.468 1.191.675 1.191h.008c.299 0 .515-.065.643-.193.242-.242.242-.75.242-.993L5.6 3.467c0-.245-.04-.872-.416-1.386-.35-.48-.915-.723-1.677-.723h-.003c-.724 0-1.277.266-1.644.792a2.564 2.564 0 00-.438 1.373l-.018 13.483c.001 1.84.598 4.036 3.435 4.037 2.774 0 3.356-2.194 3.356-4.034L8.168 5.288l1.28-.003.027 11.723c0 3.377-1.69 5.314-4.636 5.314z" _fill="#8D949E" fill-rule="nonzero"/>'
  }
})
