
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class gridRoom extends Vue{
        @Prop({ type: Boolean, default: false }) readonly statusChat!: boolean;
        @Prop({ type: Object, default: null }) readonly currentScreenShare!: any;
        @Prop({ type: Number, default: 0 }) readonly totalUsers!: number;
        @Prop({ type: Object, default: undefined }) readonly lesson!: any;
        @Prop({ type: String, default: null }) readonly pin!: string | null;
        @Prop({ type: Boolean, default: true }) readonly showCurrentVideo!:  boolean;
        @Prop({ type: Boolean, default: true }) readonly muteCam!:  boolean;
        @Prop({ type: Number, default: 0 }) readonly volume!:  number;
        @Prop({ type: Boolean, default: false }) readonly hideElement!:  boolean;
        @Prop({ type: String, default: undefined }) readonly activeUserSpeaking!:  string | undefined;
        @Prop({ type: Object, default: () => { return {} } }) readonly roster!:  object;
        @Prop({ type: Object, default: undefined}) readonly localRoster!:  object | undefined;
        @Prop({ type: Boolean, default: false }) readonly loadingRemoteUsers!:  boolean;
        @Prop({ type: Object }) readonly remoteVideos!:  any;
        @Prop({ type: Object }) readonly metrics!:  any;
        @Prop({ type: Array, default: () => { return [] } }) readonly filterRosters!:  Array<object>;
        @Prop({ type: Boolean, default: false }) readonly devMode!:  boolean;

        @State('grid') grid;
        @State( state => state.auth.user.id) userId;
        @State( state => state.auth.permission) userType;
        @State( state => state.currentCam?.deviceId ) currentCam

        screenShareHeight: string = '100%';
        contentH: number = 0;
        videoHeight: string = '100%';
        videoWidth: string = '100%';
        fRosters: Array<object> = [];

        get compactGrid(): boolean{
        	return this.statusChat && (this.grid == 'xl' || this.grid == 'lg');
        }

        get rosterScreenShare(): any{
            return this.currentScreenShare?this.currentScreenShare.roster:'';
        }

        get statusGridVideos(): boolean{
            return ((!this.currentScreenShare || (this.currentScreenShare && (this.showCurrentVideo || this.totalUsers > 1))) && this.lesson.type == 'Individual') || this.lesson.type == 'Grupal';
        }

        get colSSXL(): string{ // > 1200px Screen Share 1 on 1 (Content Width)
            let cont = this.totalUsers;
            if (this.pin)
                return 'col-xl-12'
            else
                return this.currentScreenShare && !this.statusChat && (this.showCurrentVideo || cont > 1)?'col-xl-8':'col-xl-12'
        }

        get colXl(): string { // > 1200px (user content Width)
            let cont = this.totalUsers;
            if (this.pin)
                return 'col-xl-12'
            else if (this.lesson.type == 'Grupal' || (this.lesson.type == 'Individual' && !this.currentScreenShare)) {
                if (cont == 1) return 'col-xl-12';
                if (cont == 2) return this.statusChat && this.contentH > 560?'col-xl-8':'col-xl-6';
                if (cont <= 4) return 'col-xl-6';
                else if (cont <= 9) return this.statusChat && cont < 9 ? "col-xl-6" : "col-xl-4";
                else return this.statusChat ? "col-xl-4" : "col-xl-3";
            }
            else{
                return this.statusChat ?"col-xl":"col-xl-12";
            } 
        }

        get colLg(): string{ // > 992px & < 1200px (user content Width)
            let cont = this.totalUsers;
            if (this.pin)
                return 'col-lg-12'
            else if (this.lesson.type == 'Grupal' || (this.lesson.type == 'Individual' && !this.currentScreenShare)) {
                if (cont == 1) return "col-lg-12";
                else if (cont == 2) return this.contentH > 560?(this.statusChat?'col-lg-10':'col-lg-8'):'col-lg-6';
                else if (cont <= 4) return this.statusChat && cont == 3?"col-lg-10":'col-lg-6';
                else return this.statusChat ? "col-lg-6" : "col-lg-4";
            }
            else{
                return cont == 1?"col-lg-6":"col-lg";
            }
        }
        get colMd(): string{ // > 768px & < 992px (user content Width)
            let cont = this.totalUsers;
            if(this.lesson.type == 'Individual' && this.currentScreenShare && cont > 1) return "col-md";
            else if (this.pin || cont == 1) return "col-md-12";
            else if (cont == 2) return "col-md-8";
            else return "col-md-6";
        }
        get colSm(): string{ // > 576px & < 778px (user content Width)
            let cont = this.totalUsers;
            if (this.pin || (this.lesson.type == 'Grupal' && cont == 1) || (!this.currentScreenShare && cont == 1)) return "col-sm-12"
            else if ((this.lesson.type == 'Grupal' && cont <= 3) || (!this.currentScreenShare && cont <= 2)) return "col-sm-10";
            else if(this.lesson.type == 'Individual' && this.currentScreenShare && cont > 1) return "col-sm";
            else return "col-sm-6";
        }
        get col(): string{ // < 576px (user content Width)
            let cont = this.totalUsers;
            if (this.pin || (this.lesson.type == 'Grupal' && cont <= 3) || (!this.currentScreenShare && cont <= 2)) return "col-12";
            else return "col-6";
        }

        mounted(): void {
            this.handleRosters(this.filterRosters);
        }

        getHeight(): void{
            const contentH = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight),
                  windowW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.contentH = contentH;
            let videoH = 0,
                videoW = windowW,
                rows = this.totalUsers;

            if (this.lesson.type == 'Grupal') {
                if (this.totalUsers <= 1) // 1 User
                    videoH = contentH;
                else if (contentH < 150) // height < 150px
                    videoH = 150;
                else if (windowW >= 1200){ // > 1200px (Content Width)
                    if (this.totalUsers <= 2){ //  2 Users
                        if (this.statusChat && contentH > 560) {
                            videoH = contentH/2;
                            videoW = ((windowW-350)/12)*8;
                        }else{
                            videoH = (360/640)*(windowW/2);
                            videoW = this.statusChat?(windowW-350)/2:windowW/2;
                        }
                        videoH = videoH > contentH?contentH:videoH;
                    }
                    else if (this.totalUsers <= 4){ //  3 - 4 Users
                        rows = Math.ceil(this.totalUsers/2);
                        videoH = contentH/rows;
                        videoW = this.statusChat?(windowW-350)/2:windowW/2;
                    }
                    else if( this.totalUsers <= 9 ){ // > 4 - 9 Users
                        if (this.statusChat && this.totalUsers < 9) {
                            rows = Math.ceil(this.totalUsers/2);
                            videoW = (windowW-350)/2;
                        }else{
                            rows = Math.ceil(this.totalUsers/3);
                            videoW = this.statusChat?(windowW-350)/3:windowW/3;
                        }
                        videoH = contentH/rows;
                    }
                    else{ // > 10 Users
                        if (this.statusChat) {
                            rows = Math.ceil(this.totalUsers/3);
                            videoW = (windowW-350)/3;
                        }else{
                            rows = Math.ceil(this.totalUsers/4);
                            videoW = windowW/4;
                        }
                        videoH = contentH/rows;
                    }
                }
                else if(windowW >= 992 && windowW < 1200){// > 992px & < 1200px (Content Width)
                    if (this.totalUsers == 2 ){ // 2 Users
                        if (contentH > 560) {
                            videoH = contentH/2;
                            videoW = this.statusChat?((windowW-350)/12)*10:(windowW/12)*8;
                        }else{
                            videoH = ((360/640)*(windowW/2));
                            videoW = this.statusChat?(windowW-350)/2:windowW/2;
                        }
                        videoH = videoH > contentH?contentH:videoH;
                    }
                    else if(this.totalUsers <= 4){// > 2 & < 4 Users
                        if (this.statusChat && this.totalUsers == 3) {
                            rows = this.totalUsers;
                            videoW = this.statusChat?((windowW-350)/12)* 10:windowW/2;
                        }else{
                            rows = Math.ceil(this.totalUsers/2);
                            videoW = this.statusChat?(windowW-350)/2:windowW/2;
                        }
                        videoH = contentH/rows;
                    }
                    else{ // > 5 Users
                        if (this.statusChat) {
                            rows = Math.ceil(this.totalUsers/2);
                            videoW = (windowW-350)/2;
                        }else{
                            rows = Math.ceil(this.totalUsers/3);
                            videoW = windowW/3;
                        }
                        videoH = contentH/rows;
                    }
                }
                else if(windowW >= 768 && windowW < 992){// > 768px & < 992px (Content Width)
                    if (this.totalUsers == 2) {
                        videoW = ((windowW)/12)*8;
                    }else{
                        rows = Math.ceil(this.totalUsers/2);
                        videoW = windowW/2;
                    }
                    videoH = contentH/rows;
                }
                else if(windowW >= 576 && windowW < 768){// > 776px & < 768px (Content Width)
                    if (this.totalUsers <= 3) {
                        videoW = ((windowW)/12)*10;
                    }else{
                        rows = Math.ceil(this.totalUsers/2);
                        videoW = windowW/2;
                    }
                    videoH = contentH/rows;
                }
                else{ // < 576px (Content Width)
                    if (this.totalUsers <= 3) {
                        videoW = windowW;
                    }else{
                        rows = Math.ceil(this.totalUsers/2);
                         videoW = windowW/2;
                    }
                    videoH = contentH/rows;
                }
            }else{
                let screenShareH = 0;
                if (this.pin || (this.totalUsers == 1 && !this.currentScreenShare)) {
                    videoH = contentH;
                }
                else if (windowW >= 1200) { // > 1200px (Content Width)
                    if(this.totalUsers > 1 && !this.currentScreenShare){
                        
                        if (this.statusChat && contentH > 560) {
                            videoH = contentH/this.totalUsers;
                            videoW = ((windowW-350)/12)*8;
                        }else{
                            videoH = (360/640)*(windowW/2);
                            videoW = this.statusChat?(windowW-350)/2:windowW/this.totalUsers;
                        }
                        videoH = videoH > contentH?contentH:videoH;
                    }else{
                        videoH = this.statusChat?contentH/3:((360/640)*(windowW/3));
                        videoH = (videoH * this.totalUsers) > contentH?(contentH/this.totalUsers):videoH;
                        videoW = this.statusChat?(windowW-350)/this.totalUsers:(windowW/12)*4;
                        screenShareH = this.statusChat?(contentH/3)*2-70:contentH;
                    }
                }
                else if(windowW >= 992 && windowW < 1200){ // > 992px & < 1200px (Content Width)
                    if(this.totalUsers > 1 && !this.currentScreenShare){
                        if (contentH > 560) {
                            videoH = contentH/2;
                            videoW = this.statusChat?((windowW-350)/12)*10:(windowW/12)*8;
                        }else{
                            videoH = ((360/640)*(windowW/2));
                            videoW = this.statusChat?(windowW-350)/2:windowW/2;
                        }
                        videoH = videoH > contentH?contentH:videoH;
                    }
                    else{
                        videoH = contentH/3;
                        videoW = this.statusChat?(windowW-350)/this.totalUsers:windowW/this.totalUsers;
                        screenShareH = (contentH/3)*2-70;
                    }
                }
                else { // < 992px (Content width)
                    if(this.totalUsers > 1 && !this.currentScreenShare){
                        if (contentH > 560) {
                            videoH = contentH/2;
                            videoW = (windowW/12)*8;
                        }else{
                            videoH = ((360/640)*(windowW/2));
                            videoW = this.statusChat?(windowW-350)/2:windowW/2;
                        }
                        videoH = videoH > contentH?contentH:videoH;
                    }
                    else{
                        videoH = contentH/3;
                        videoW = windowW/this.totalUsers;
                        screenShareH = (contentH/3)*2-70;
                    }
                }
                this.screenShareHeight = (this.pin == this.rosterScreenShare?`${contentH}px`:`${screenShareH>300?screenShareH:300}px`);
            }

            this.videoHeight = (!this.pin?`${videoH>150?videoH:150}px`:`${contentH}px`);
            this.videoWidth = `${videoW}px`;
        }

        openVideoInputFromSelection(deviceID, callback: any = val => val): void {
            this.$emit('openVideoInputFromSelection', deviceID, band => {
                callback(band);
            });
        }

        handleRosters(rosters: Array<any>): void{
            let r: Array<object> = rosters,
                ss: any = null;

            if(this.lesson.type == 'Individual'){
                
                rosters.forEach( item => {
                    if(item.roster.includes('#content')){
                        if(!ss)
                            ss = item;
                        else if(ss && (ss?.id == this.userId && ss?.id != item.id)){
                            ss = item;
                        }
                    }     
                });

                if(ss)
                    r = rosters.filter( i => i.roster != ss.roster );
            }

            this.fRosters = r;
            let t = r.length + 1;
            if(this.lesson.type == 'Individual' && t >= 3)
                t = 2;

            this.$emit('handleTotalUsers', t);
            this.$emit('handleScreenShare', ss);
        }

        @Watch('totalUsers')
        hangleSize(): void{
            this.getHeight();
        }

        @Watch('currentScreenShare')
        hangleSize2(): void{
            this.getHeight();
        }

        @Watch('pin')
        hangleSize3(): void{
            this.getHeight();
        }

        @Watch('statusChat')
        hangleSize4(): void{
            setTimeout( () => {
                this.getHeight();
            }, 400);
        }

        @Watch('filterRosters')
        handleScreenShare(newVal: Array<any>): void{
            this.handleRosters(newVal);            
        } 
	}
