var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-lesson-time" }, [
    _c(
      "span",
      { staticClass: "d-block w-100 lt-title mb-2", style: _vm.titleCSS },
      [_vm._v(_vm._s(_vm.$t("lessons.currentTime")))]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "time", style: _vm.timeCSS }, [
      _vm._v(_vm._s(_vm.duration))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }