
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { State } from 'vuex-class';
	import { modal } from '../../mixins/index.js';

    enum DeviceAlerts {
        prompt,
        block,
        micBlock,
        camBlock,
        empty,
        micEmpty,
        camEmpty,
        errorSystem,
        micErrorSystem,
        camErrorSystem,
        errorDevice,
        errorCam,
        errorMic,
        errorSharing
    }
    
	@Component({
		mixins: [ modal ]
	})
	export default class ModalPermissions extends Vue{
		@Prop({ default: undefined }) readonly alert!: DeviceAlerts | undefined;

		@State('defaultBrowserBehaviour') defaultBrowserBehaviour;
		@State( state => state.auth.permission ) permission;

        /*-- ========== Enums ========= */
        contentH: string = 'initial';
        private deviceAlerts = DeviceAlerts;
        
        get t(): any{
        	return(this as any).$t;
        }
        get refs(){
        	return(this as any).$refs;
        }
        get emit(): any{
        	return(this as any).$emit;
        }
        get content(): object | undefined{
            // Asking for permissions
            if (this.alert == this.deviceAlerts.prompt)
                return { t: this.t('room.permissions.prompt.t'), d: this.t('room.permissions.prompt.d') };
            // Permissions denied
            else if (this.alert == this.deviceAlerts.block)
                return { t: this.t('room.permissions.block.t'), d: this.t('room.permissions.block.d') };
            else if (this.alert == this.deviceAlerts.micBlock)
                return { t: this.t('room.microphone.alerts.denied.issue'), d: this.t('room.microphone.alerts.denied.info') };
            else if (this.alert == this.deviceAlerts.camBlock)
                return { t: this.t('room.camera.alerts.denied.issue'), d: this.t('room.camera.alerts.denied.info') };
            // No Devices
            else if (this.alert == this.deviceAlerts.empty)
                return { t: this.t('room.permissions.empty.t'), d: this.t('room.permissions.empty.d') };
            else if (this.alert == this.deviceAlerts.micEmpty)
                return { t: this.t('room.microphone.alerts.empty.issue'), d: this.t('room.microphone.alerts.empty.info') };
            else if (this.alert == this.deviceAlerts.camEmpty)
                return { t: this.t('room.camera.alerts.empty.issue'), d: this.t('room.camera.alerts.empty.info') };
            // Block by system
            else if (this.alert == this.deviceAlerts.errorSystem)
                return { t: this.t('room.permissions.errorSystem.t'), d: this.t('room.permissions.errorSystem.d') };
            else if (this.alert == this.deviceAlerts.micErrorSystem)
                return { t: this.t('room.microphone.alerts.errorSystem.issue'), d: this.t('room.microphone.alerts.errorSystem.info') };
            else if (this.alert == this.deviceAlerts.camErrorSystem)
                return { t: this.t('room.camera.alerts.errorSystem.issue'), d: this.t('room.camera.alerts.errorSystem.info') };
            // Device issue
            else if (this.alert == this.deviceAlerts.errorDevice)
                return { t: this.t('room.permissions.errorDevice.t'), d: this.t('room.permissions.errorDevice.d') };
            else if (this.alert == this.deviceAlerts.errorMic)
                return { t: this.t('room.microphone.alerts.errorDevice.issue'), d: this.t('room.microphone.alerts.errorDevice.info') };
            else if (this.alert == this.deviceAlerts.errorCam)
                return { t: this.t('room.camera.alerts.errorDevice.issue'), d: this.t('room.camera.alerts.errorDevice.info') };
            // Sharing screen issue
            else if( this.alert == this.deviceAlerts.errorSharing )
                return { t: this.t('room.sharingScreen.alert.t'), d: this.t('room.sharingScreen.alert.msg') };
            else
                return undefined;
        }
        get gifPermissions(): string{
            const browser = this.defaultBrowserBehaviour.name();
        	if (browser === 'opera')
        		return this.t('room.permissions.gifLink')+'opera.gif';
        	else if (browser === 'firefox')
        		return this.t('room.permissions.gifLink')+'firefox.gif';
        	else if (browser === 'safari')
        		return this.t('room.permissions.gifLink')+'safari.gif';
        	else
        		return this.t('room.permissions.gifLink')+'chrome.gif';
        }
        
        mounted(){
            this.getHeight();
            window.addEventListener("resize", this.getHeight);
        }
        beforeDestroy(){
            window.removeEventListener("resize", this.getHeight);
        }
        afterClose(){
        	this.emit('close');
        }

        getHeight(): void{
            this.contentH = 'initial';
            let windowH = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
                windowH = (windowH/10) * 9;
            const titleH = this.refs.title.clientHeight,
                  contentH = this.refs.content.clientHeight;

            if ((titleH + contentH) >= windowH)
                this.contentH = `${Math.ceil(windowH - titleH)}px`;
        }
	}
