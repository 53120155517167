/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'puzzle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.763 5.075a.5.5 0 00-.486-.022c-.008.004-.822.409-1.765.716-1.576.514-2.14.326-2.309.221-.061-.038-.203-.125-.203-.49 0-.315.158-.587.357-.931C14.643 4.075 15 3.46 15 2.5c0-.669-.463-1.327-1.27-1.805C12.973.247 12.004 0 11 0S9.027.247 8.27.695C7.463 1.173 7 1.831 7 2.5c0 .96.357 1.575.643 2.069.199.344.357.616.357.931 0 .268-.099.37-.195.435-.199.133-.878.383-2.886-.15-1.18-.313-2.216-.743-2.227-.747a.498.498 0 00-.678.341c-.012.05-.306 1.232-.505 2.563-.383 2.56-.064 3.321.209 3.671.195.25.473.388.781.388.679 0 1.127-.52 1.56-1.022s.843-.978 1.44-.978 1.5 1.197 1.5 3-.903 3-1.5 3-1.006-.475-1.44-.978-.882-1.022-1.56-1.022c-.337 0-.633.149-.833.418-.247.333-.532 1.02-.143 3.14.2 1.09.485 2.045.497 2.085a.5.5 0 00.479.356h14c1.378 0 2.5-1.122 2.5-2.5v-12a.5.5 0 00-.237-.425zM18 17.5c0 .827-.673 1.5-1.5 1.5H2.879a20.506 20.506 0 01-.399-1.779c-.32-1.856-.022-2.197.002-2.219l.019-.001c.22 0 .517.343.803.676.509.59 1.142 1.324 2.197 1.324.669 0 1.327-.463 1.805-1.27.448-.757.695-1.726.695-2.73s-.247-1.973-.695-2.73c-.478-.807-1.136-1.27-1.805-1.27-1.055 0-1.688.734-2.197 1.324-.282.327-.574.666-.794.675-.054-.065-.35-.538-.031-2.772.113-.792.269-1.559.378-2.053.434.161 1.084.385 1.795.574 1.796.478 3.009.484 3.708.018.292-.195.641-.573.641-1.267 0-.584-.25-1.015-.492-1.432-.261-.451-.508-.876-.508-1.568 0-.597 1.197-1.5 3-1.5s3 .903 3 1.5c0 .691-.247 1.117-.508 1.568-.242.417-.492.848-.492 1.432 0 .762.368 1.15.677 1.341.646.399 1.679.359 3.156-.125.438-.143.844-.303 1.167-.44V17.5z"/>'
  }
})
