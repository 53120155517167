var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-content-question row" }, [
    _c(
      "div",
      { staticClass: "col pl-md-4 pl-3 pt-2 pb-2 d-flex align-items-center" },
      [
        _c("p", { staticClass: "v-question mb-0" }, [
          _vm._v(_vm._s(_vm.question.question))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-auto" }, [
      _c(
        "div",
        { staticClass: "row h-100" },
        _vm._l(_vm.answers, function(item, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass:
                "v-content-question-radio col-md-auto col p-2 h-100 d-flex justify-content-center align-items-center"
            },
            [
              _c("div", {
                staticClass: "v-question-radio",
                class: {
                  active: _vm.val == item.val,
                  error: _vm.error && _vm.val == null,
                  disabled: _vm.statusFormat
                },
                on: {
                  click: function($event) {
                    return _vm.handleAnswer(item.val)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-1 d-md-none" }, [
                _vm._v(_vm._s(i + 1))
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }