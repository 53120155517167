
	import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
	import { State } from 'vuex-class';

	@Component
	export default class headerRoom extends Vue{
		/* ======== Props ======= */
        @Prop({ type: Boolean, default: false }) readonly showChat!: boolean
        @Prop({ type: Object }) readonly lesson!: any
        @Prop({ type: Map, default: new Map() }) readonly users!: Map<string ,object>
        @Prop({ type: String, default: null }) readonly duration!: any
        @Prop({ type: Object, default: null }) readonly stream!: any
        @Prop({ type: Object, default: null }) readonly currentRoster!: any
        @Prop({ type: Array, default: [] }) readonly remoteRosters!: any
        @Prop({ type: Boolean, default: false }) readonly isSharing!: any
        @Prop({ type: Object, default: null }) readonly currentUser!: any
		@Prop({ type: Object, default: () => { return {} } }) readonly roster!:  object;
        @Prop({ type: Boolean, default: false }) readonly muteVideo!: boolean
        @Prop({ type: String, default: '' }) readonly deviceId!: string
        @Prop({ type: Boolean, default: false }) readonly showCurrentVideo!: boolean
        @Prop({ type: String, default: null }) readonly pin!: string | null
        @Prop({ type: String, default: 'xl' }) readonly windowGrid!: string
        @Prop({ type: Number, default: 0 }) readonly totalUsers!: number;
		@Prop({ type: Number, default: 0 }) readonly resetLocalVideo!: number;

        /* ======== State vuex ======= */
        @State('msgs') stateMsgs
        @State(state => state.auth.permission) statePermission 
        /* ======== Datas ======== */
        tabChat: string = 'chat';
        refreshChat: number = 0;
		contSoundMsg: number = 0;
        /* ======== Computed ======= */
        get refs(){
            return (this as any).$refs;
        }
        get arrayUsers(): Array<object>{
            return Array.from( this.users.values() );
        }
        get totalMsgs(): number{
        	if (this.lesson){
        		return this.stateMsgs[this.lesson.id]?this.stateMsgs[this.lesson.id].counter:0;
        	}
        	else
        		return 0;
        }
        get chatIsReady(): boolean{
        	if (this.lesson){
        		return this.stateMsgs[this.lesson.id];
        	}
        	else
        		return false;
        }
        get lessonType(): string{
        	if (this.lesson)
        		return this.lesson.type == 'Grupal'?(this.$t('room.header.groupType') || ''):(this.$t('room.header.individualType') || '');
        	else 
        		return '';
        }
        get lessonId(): number{
        	return this.lesson?this.lesson.id:'';
        }

        @Watch('showChat')
        afterShow(newVal: Boolean){
            if (newVal) {
                if (this.tabChat == 'chat'){
                    this.refs.chat.scrollFirstTime = false;
                    this.refreshChat +=1;
                }
            }
        }
        @Watch('tabChat')
        resetChat(newVal: string){
            if (newVal == 'chat'){
                this.refs.chat.scrollFirstTime = false;
                this.refreshChat +=1;
            }
        }
        @Watch('totalMsgs')
		async msgSound(newVal: number): Promise<void>{
            if(newVal > 0 && (!this.showChat || (this.showChat && this.tabChat == 'users'))){
				const audio = new Audio('/media/msg.wav');
				audio.play();
			}
		}
	}
