/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'poop': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.913 12.637C18.627 11.993 19 11.265 19 10.5c0-1.019-.678-2.035-1.911-2.862a8.431 8.431 0 00-1.127-.63c.024-.168.037-.337.037-.508 0-1.307-.585-3.5-4.5-3.5-.684 0-1.5-.173-1.5-1 0-.453.578-.948.779-1.085A.5.5 0 0010.5 0C8.933 0 7.322.515 6.082 1.413c-.633.458-1.135.988-1.493 1.575C4.198 3.629 4 4.306 4 5c0 .288.024.574.07.855-.849.41-1.566.951-2.09 1.581C1.339 8.206 1 9.093 1 10c0 .561.129 1.133.375 1.685C.474 12.527 0 13.494 0 14.5c0 1.53 1.083 2.943 3.05 3.978C4.915 19.459 7.383 20 10 20c2.603 0 5.057-.43 6.912-1.211C18.904 17.95 20 16.782 20 15.5c0-1.041-.74-2.053-2.087-2.863zm-1.39 5.231C14.789 18.598 12.472 19 10 19c-2.458 0-4.761-.5-6.485-1.407C1.893 16.739 1 15.641 1 14.5c0-.782.423-1.435.89-1.919.108.152.226.301.353.446.796.909 1.904 1.603 3.118 1.953a.5.5 0 10.277-.96c-2.074-.599-3.639-2.327-3.639-4.02 0-1.224.88-2.399 2.329-3.155.524 1.443 1.63 2.641 3.004 3.127a.499.499 0 10.333-.942c-1.52-.537-2.667-2.269-2.667-4.029 0-1.012.592-1.998 1.668-2.777a6.583 6.583 0 012.578-1.1 1.8 1.8 0 00-.246.877c0 .967.657 2 2.5 2 1.278 0 2.219.254 2.797.756.466.405.703.992.703 1.744 0 .038-.001.075-.003.113a13.512 13.512 0 00-2.42-.607.5.5 0 00-.156.988c1.611.253 3.071.777 4.11 1.474.947.635 1.468 1.357 1.468 2.032 0 .68-.53 1.245-1.053 1.637-1.232-.55-2.755-.946-4.39-1.133a.5.5 0 00-.114.994c1.886.216 3.598.72 4.822 1.419 1.119.639 1.735 1.379 1.735 2.084 0 .842-.903 1.705-2.477 2.368z"/>'
  }
})
