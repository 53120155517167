/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume-low': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.762 12.552a.5.5 0 01-.316-.887c.352-.287.554-.711.554-1.164s-.202-.878-.554-1.164a.499.499 0 11.631-.775c.587.477.923 1.184.923 1.94s-.337 1.463-.923 1.94a.5.5 0 01-.315.112zM8.15 3.207c-.247 0-.494.104-.736.309L3.316 6.999H1.5c-.827 0-1.5.673-1.5 1.5v4c0 .827.673 1.5 1.5 1.5h1.816l4.098 3.483c.241.205.489.309.736.309a.796.796 0 00.67-.362c.119-.179.18-.408.18-.68v-12.5c0-.72-.427-1.043-.85-1.043zM1 12.5v-4a.5.5 0 01.5-.5H3v5H1.5a.5.5 0 01-.5-.5zm7 4.169l-4-3.4V7.731l4-3.4v12.338z"/>'
  }
})
