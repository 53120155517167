<template>
	<div id="subjects" class="container-fluid pb-5">
		<div class="paper row ml-sm-0 mr-sm-0 pl-md-4 pr-md-4 pt-sm-5 pb-sm-5 pt-4 pb-4 justify-content-center">
			<div class="content-subjects col-md">
				<h2 class="title-subjects mb-3"> <svgicon class="icon" name="icons"></svgicon> {{ $t('subjects.t') }} </h2>
				<p class="descrip-subjects mb-3" v-html="$t('subjects.descripHtml')"></p>
                <div class="paper p-4 mb-4">
                	<div class="d-none d-md-block">
                		<div class="d-flex flex-wrap">
                			<div class="col offset-md-4 d-flex order-1 order-sm-0 text-center">
                				<span class="title-list-subject">{{ $t('subjects.tType') }}</span>
                			</div>
                			<div class="col d-flex order-0 order-sm-1 text-center">
                				<span class="title-list-subject">{{ $t('subjects.tMode') }}</span>
                			</div>
                            <div class="col d-flex order-2 text-center">
                                <span class="title-list-subject">{{ $t('subjects.duration') }}</span>
                            </div>
                		</div>
                	</div>   
                    <perfect-scrollbar ref="scroll" >
                        <transition-group name="bound" class="subjects-list pl-3 pr-3" tag="ul">
                            <li class="subject row align-items-center pt-3 pb-3" :key="item.subject_id" v-for="(item,index) in subjects">
                                <div class="col-md-4 order-0  mb-2 mb-md-0">
                                    <div class="info-subjects d-flex justify-content-center flex-column pl-5 position-relative">
                                        <div class="color mr-2" :style="{ background: item.color }"></div>
                                        <v-hover-txt class="mb-1" fontSize="12.5px" fontWeight="500" :positionCloud="index==0?'top':'bottom'" :txt="item.name" />
                                        <v-hover-txt fontSize="10px" :txt="item.category" :positionCloud="index==0?'top':'bottom'" colorTxt="#9b9b9b" />
                                    </div>
                                </div>
                                
                                <div class="col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-2 order-sm-1">
                                    <v-lesson-tag :type="item.type" :options="{ lessonLimit: item.limit }"/>
                                </div>
                                <div class="col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-1 order-sm-2">
                                    <v-lesson-tag :type="item.modality"/>
                                </div>
                                <div class="col-sm col-auto pt-2 pt-sm-0 d-flex align-items-center order-3">
                                    <span class="duration d-block">
                                        <svgicon class="icon-mode" icon="clock" />
                                        {{ formatTime(item.duration_time) }}
                                    </span>
                                </div>
                            </li>
                            <div class="lesson-spinner w-100 p-3 text-center" key="spinner" v-if="spinner">
                                <v-spinner height="30px" width="30px" />
                            </div>
                        </transition-group>
                    </perfect-scrollbar>  	
                </div>
                <v-circles :vCircle="3" size="300px"/>
				<v-circles :vCircle="4" size="200px"/>
			</div>
		</div>
	</div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
	export default{
		data(){
			return{
                spinner: false,
				subjects: [],
                limit: 10,
                offset: 0,
                totalSubjects: 0,
                gettingSubjects: false
			}
		},
        computed:{
            ...mapState(['auth'])
        },
        methods:{
            ...mapActions(['axiosRequest']),
            getSubjects(){
                this.spinner = true;
                this.axiosRequest({ config : {
                    method: 'GET',
                    url: `${this.auth.api}/tutor/subjects`,
                    params: { limit: this.limit, offset: this.offset },
                    headers: { authorization : this.auth.token }
                }}).then( resp => resp.data )
                .then( data => {
                    this.totalSubjects = data.total;
                    this.spinner = false;
                    this.gettingSubjects = false;
                    setTimeout( () => {
                        if (this.offset == 0)
                            this.subjects = data.subjects;
                        else
                            this.subjects = [ ...this.subjects, ...data.subjects ]
                    },400);
                }).catch( error => {
                    this.spinner = false;
                });
            },
            formatTime(minutes){
                if (minutes < 60 )
                    return minutes+' min';
                else{
                    if (minutes%60===0)
                        return (minutes/60)+' hr';
                    else{
                        const hr = Math.trunc(minutes/60);
                        return `${hr} hr ${ minutes-(hr*60) } min`;
                    }

                }
            }
        },
        mounted(){
            var that = this;
            this.$refs.scroll.$el.addEventListener('ps-scroll-down', e => {
                if (((Math.round(e.target.scrollTop) + this.$refs.scroll.ps.containerHeight) >= (this.$refs.scroll.ps.contentHeight - 150)) && (this.subjects.length < this.totalSubjects) && !this.gettingSubjects) {
                    this.offset += this.limit;
                    this.gettingSubjects = true;
                    that.getSubjects();
                }
            });
        },
        created(){
            this.getSubjects();
        }
	}
</script>
