import listItems from './list-items.vue';
import dayAndTime from './day-and-time.vue';
import place from './place.vue';
import summary from './summary.vue';
import subjects from './subjects.vue';
import cardTutor from './card-tutor.vue';
import schedule from './schedule.vue';

function plugin(Vue){
	Vue.component('card-tutor', cardTutor);
	Vue.component('v-schedule-session', schedule);
	Vue.component('v-selected-list-items', listItems);
    Vue.component('v-schedule', dayAndTime);
    Vue.component('v-place', place);
    Vue.component('v-summary', summary);
    Vue.component('v-subjects', subjects);
}

export default plugin