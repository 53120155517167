var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-places-t" }, [
    _c("h5", { staticClass: "v-sch-t-title mb-3" }, [
      _vm._v(_vm._s(_vm.$t("scheduleT.places.t")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: { disabled: _vm.disabled },
        style: { border: "1px solid #9E9E9E", borderRadius: "20px" },
        attrs: { id: "search-autocomplete" }
      },
      [
        _c(
          "div",
          {
            staticClass: "position-relative search",
            on: {
              click: function($event) {
                return _vm.toggleAction()
              }
            }
          },
          [
            _vm.type != "Grupal"
              ? _c("svgicon", {
                  staticClass: "icon-arrow-down",
                  attrs: { name: "chevron-down" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.val,
                  expression: "val"
                }
              ],
              ref: "input",
              attrs: {
                type: "text",
                maxlength: "100",
                disabled: _vm.disabled,
                placeholder:
                  _vm.type != "Grupal"
                    ? _vm.$t("scheduleT.places.placeholder")
                    : _vm.$t("scheduleT.places.placeholder2")
              },
              domProps: { value: _vm.val },
              on: {
                keyup: function($event) {
                  return _vm.keyFuction($event.key)
                },
                focus: function($event) {
                  _vm.statusDropdown =
                    _vm.results.length > 0
                      ? _vm.stepsDropdown.focus
                      : _vm.stepsDropdown.blur
                },
                blur: function($event) {
                  return _vm.blurAction()
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.val = $event.target.value
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        (_vm.statusDropdown == _vm.stepsDropdown.focus ||
          _vm.statusDropdown == _vm.stepsDropdown.hover) &&
        _vm.type != "Grupal" &&
        !_vm.disabled
          ? _c(
              "perfect-scrollbar",
              {
                ref: "scroll",
                staticClass: "dropdown",
                attrs: { tag: "div" },
                on: {
                  mouseover: function($event) {
                    return _vm.hoverAction()
                  },
                  mouseleave: function($event) {
                    return _vm.leaveAction()
                  }
                }
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "bound", mode: "out-in" } },
                  [
                    _vm.statusResults === _vm.stepsResults.spinner
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            _c("v-spinner", {
                              attrs: { height: "40px", width: "40px" }
                            })
                          ],
                          1
                        )
                      : _vm.statusResults === _vm.stepsResults.message
                      ? _c(
                          "div",
                          {
                            key: "spinner",
                            staticClass: "w-100 p-3 text-center"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "msg-search mt-2 mb-2 ml-auto mr-auto text-center",
                                style: { maxWidth: "550px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "scheduleT.places.alertPlacesNotFound"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      : _vm.statusResults === _vm.stepsResults.results
                      ? _c(
                          "transition-group",
                          {
                            key: "results",
                            staticClass: "pl-0 mb-0",
                            attrs: { name: "bound", tag: "ul" }
                          },
                          _vm._l(_vm.filterResults, function(item) {
                            return _c(
                              "li",
                              {
                                key: `place-'${item.id}`,
                                ref: "dropdownItem",
                                refInFor: true,
                                staticClass:
                                  "dropdown-item position-relative d-flex align-items-center",
                                class: {
                                  hover: _vm.resultHover == `di-${item.id}`
                                },
                                attrs: { id: `di-${item.id}` },
                                on: {
                                  click: function($event) {
                                    return _vm.handlePlace(item)
                                  }
                                }
                              },
                              [
                                _c("svgicon", {
                                  staticClass: "dropdown-place-icon",
                                  attrs: { name: item.iconName }
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.name) +
                                    "\n                        "
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }