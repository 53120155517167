<template>
	<div class="confirm-modal" :class="{ active : show }" v-if="modalVal.modal=='v-modal-crud'">
        <!-- ======== Background color ======== -->
		<div class="blurColor" @click="modalVal.type != 'confirmLesson'?close():''"></div>
		<div class="v-content">
            <!-- ======== Modal title ======== -->
			<h4 class="title pt-4 pb-3 pl-5 pr-5 mb-0" :class="modalVal.type==='cancel'?'bgColor-red-dark':`bgColor-${auth.permission}-main`">
				{{ $t(`modals.${modalVal.type}.tTop`) }} <span class="d-block w-100">{{ $t(`modals.${modalVal.type}.tDown`) }}</span>
				<svgicon class="btn-close" name="cross2" @click="close()" v-if="modalVal.type != 'confirmLesson'" />
			</h4>
            <!-- ======== Modal content ======== -->
			<div class="text-center pt-3 pb-4 pl-4 pr-4">
                <svgicon class="icon mb-3" :name="svgIcon.name" :color="svgIcon.color"/>
			    <p class="alert-msg w-100 mb-4">{{ $t(`modals.${modalVal.type}.msg`) }}</p>
                <p class="alert-legend w-100 mb-4 mb-sm-5" v-if="modalVal.type === 'cancel'">{{ $t(`modals.cancel.legend${auth.permission=='tutor'?'S':'T'}`) }}</p>
			    <v-btn class="btn-accept mb-3 w-100" :txt="$t(`modals.${modalVal.type}.btnA`)" :btn="modalVal.type==='cancel'?'error':'main'" height="44px" fontSize="13px" :disabled="sending" @click.native="send()"/>
			    <button class="btn-cancel" @click="modalVal.type != 'confirmLesson'?close():cancelLesson()" v-if="$route.name !=  `room-${auth.permission}` || auth.permission == 'student'">{{ $t(`modals.${modalVal.type}.btnN`) }}</button>
                <button class="btn-cancel" @click="leaveRoom();" v-else>{{ $t(`modals.${modalVal.type}.btnLeaveRoom`) }}</button>
			</div>
		</div>
	</div>
</template>
<script>
    import { modal } from '../../mixins/index.js';
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	export default{
        mixins: [ modal ],
        data(){
            return{
                sending: false,
                openModal: 'close',
                stOpenModal: true
            }
        },
        computed:{
        	...mapState(['auth', 'currentLesson', 'userFirebase']),
            ...mapGetters(['modalVal']),
            svgIcon(){
                if (this.modalVal.type == 'cancel')
                    return { name: 'wondering', color: '#000' };
                if (this.modalVal.type == 'confirm')
                    return { name: 'happy', color: '#72BD00' };
                if (this.modalVal.type == 'start')
                    return { name: 'play-circle', color: '#72BD00' };
                if (this.modalVal.type == 'finished')
                    return { name: 'cronometro-amarillo', color: '#000' };
                if (this.modalVal.type == 'confirmLesson')
                    return { name: 'altavoz-ondas', color: '#000' };
            }
        },
        created(){
            if (this.modalVal.type == 'confirmLesson') this.eventListener = false;
        },
        methods:{
        	...mapMutations(['setNotification','setCurrentLesson','setpendingConfirmation','setcommentFeedback', 'setModal']),
        	...mapActions(['getLesson', 'axiosRequest']),
            confirmLesson(){ // confirm individual lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PATCH',
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/student/confirm`,
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data )
                    .then( data => {
                        this.sending = false;
                        this.$Progress.finish();
                        const newArray = this.auth.pendingConfirmation.filter( i => i.id+'' !== this.currentLesson.lesson.id+'');
                        this.setpendingConfirmation(newArray);
                        if (this.$route.name != 'lessons-student')
                            this.$router.push({ name: 'lessons-student' });
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });

                        if (newArray > 0){
                            this.setCurrentLesson({ lesson: { id: newArray[0].id } });
                            this.openModal = 'v-modal-crud:confirmLesson';
                        }
                        else if (this.auth.pendingFeedback.length > 0){
                            this.openModal = 'v-modal-feedback';
                        }

                        this.stOpenModal = false;
                        this.close();
                        
                    } )
                    .catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        this.close();
                    });
                }
            },
            cancelLesson(){ // Cancel individual lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PATCH',
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/reject`,
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data )
                    .then( data => {
                        this.sending = false;
                        this.$Progress.finish();
                        const newArray = this.auth.pendingConfirmation.filter( i => i.id+'' !== this.currentLesson.lesson.id+'');
                        this.setpendingConfirmation(newArray);
                        if (newArray > 0){
                            this.setCurrentLesson({ lesson: { id: newArray[0].id } });
                            this.openModal = 'v-modal-crud:confirmLesson';
                        }
                        else if (this.auth.pendingFeedback.length > 0)
                            this.openModal = 'v-modal-feedback';

                        
                        this.close();
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                    } )
                    .catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        this.close();
                    });
                }
            },
        	confirm(){ // Confirm Lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PUT',
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/confirm`,
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data )
                    .then( data => {
                        this.sending = false;
                        this.$Progress.finish();
                        this.close();
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                    } )
                    .catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        if (error.response)
                            if (error.response.status == 422){
                                this.setNotification({ type: 'warning', msg: error.response.data.error.message });
                                this.close();
                            }
                    });
                }
        	},
            cancel(){ // Cancel lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PUT', 
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/cancel`,
                        headers: { Authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then(data => {
                        this.sending = false;
                        this.$Progress.finish();
                        this.close();
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                    })
                    .catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        if (error.response)
                            if (error.response.status == 422){
                                this.setNotification({ type: 'warning', msg: error.response.data.error.message });
                                this.close();
                            }
                    })
                }
            },
        	start(){ // Start lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PATCH',
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/start`,
                        headers: { authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then( data => {
                        this.sending = false;
                        this.$Progress.finish();
                        if (data.data && this.currentLesson.lesson){
                            if (data.type == 'Success' && data.data.lesson.type == 'Grupal' && data.data.lesson.modality == 'Online'){
                                this.stOpenModal = false;
                                this.$router.push({ name: `room-${this.auth.permission}`, params: { roomId: data.data.lesson.id } });
                            }
                        }
                        this.setNotification({ type: data.type || 'warning', msg: data.message });
                        this.close();
                    }).catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        if (error.response)
                            if (error.response.status == 422){
                                this.setNotification({ type: 'warning', msg: error.response.data.error.message });
                                this.close();
                            }
                        });
                }
        	},
        	finished(){ // Stop lesson
                if (!this.sending){
                    this.sending = true;
                    this.$Progress.start();
                    this.axiosRequest({ config : {
                        method: 'PATCH', 
                        url: `${this.auth.api}/lessons/${this.currentLesson.lesson.id}/finish`,
                        headers: { Authorization : this.auth.token }
                    }}).then( resp => resp.data)
                    .then(data => {
                        this.sending = false;
                        this.$Progress.finish();
                        if (!this.userFirebase)
                            this.openModal = 'v-modal-feedback';
                        else if(data.type == 'Success')
                            this.stOpenModal = false;

                        if (data.type == 'Success')
                            this.setcommentFeedback(data.data.required);
                        this.setNotification({ type: data.type, msg: data.message, title: data.title });
                        this.close();
                    })
                    .catch( error => {
                        this.sending = false;
                        this.$Progress.fail();
                        this.close();
                    })
                }
        	},
            leaveRoom(){
                this.openModal = 'leaveRoom';
                this.close();
            },
        	send(){
                if (this.modalVal.type === 'confirmLesson')
                    this.confirmLesson();
        		else if (this.modalVal.type === 'confirm')
        			this.confirm();
                else if (this.modalVal.type === 'cancel')
                    this.cancel();
        		else if(this.modalVal.type === 'start')
        			this.start();
        		else if(this.modalVal.type === 'finished')
        			this.finished();
        	},
            async beforeClose(){

            },
            afterClose(){
                if (this.openModal == 'v-modal-feedback'){
                        this.setModal('');
                        this.getLesson(this.auth.pendingFeedback[0].id);
                }
                else if(this.stOpenModal)
                    this.setModal(this.openModal);
            }
        }
	}
</script>