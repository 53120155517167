<template>
   <div class="v-range" :class="type">
   	    <div class="row ml-0 mr-0 mb-1">
			<div class="d-flex flex-wrap align-items-center mb-2 pl-0 pr-0" :class="any?'col-8':'col'">
   	   	        <span class="title-range mr-2">{{ title }}:</span>
                <p class="range-text text-center mb-0" v-html="setRange"></p>
   	        </div>
			<div class="col pl-0 pr-0 d-flex justify-content-end align-items-center" v-if="any">
				<div class="check-any" @click="handleAny">
				    <div class="check" :class="{ active : statusAny }"></div>
				    <label class="text-any mb-0" :for="'title'">{{ $t('searchResult.filters.any') }}</label>
			    </div>
			</div>
		</div>
   	    
   	    <div class="range-slider d-flex align-items-center" :style="{ pointerEvents: statusAny?'none':'all' }">
   	   	    <range-slider class="w-100" v-model="range" tooltip="none" :interval="type==='number'?1:50" :min="minVal || startRange" :max="maxVal || endRange" :minRange="50"/>
            <svgicon class="icon-left" :class="iconStart" :name="iconStart" v-if="iconStart"></svgicon>
            <svgicon class="icon-right" :class="iconEnd" :name="iconEnd" v-if="iconEnd"></svgicon>
   	    </div>
   </div>
</template>
<script>
	export default{
		props: {
			title: { type: String, default: '', required: true },
			type: { type: String, default: 'number', required: false }, // number | time
			startRange: '',
			endRange: '',
			iconStart: { type: String, default: null, required: false },
			iconEnd: { type: String, default: null, required: false },
			any: { type: Boolean, default: false, required: false },
			value: { type: Array }
		},
		computed:{
			setRange(){
				if (this.type === 'time'){
					const min = this.setHour(this.range[0]);
					const max = this.setHour(this.range[1]);
					return `${min.hour}:${min.minutes}<span class="sg">${min.sg}</span> - ${max.hour}:${max.minutes}<span class="sg">${max.sg}</span>`;
				}
				else if (this.type === 'number')
					return `${this.range[0]} - ${this.range[1]}`;
			}
		},
		data(){
			return{
				range: [],
				minVal: null,
				maxVal: null,
			    mouseAction: false,
			    statusAny: false,
			    timer: ''
			}
		},
		created() {
			if (this.type === 'time') {
				this.minVal = parseInt(this.startRange.replace(/:/g,''));
				this.maxVal = parseInt(this.endRange.replace(/:/g,''));
				if (this.value.length<2)
					this.range = [ parseInt(this.startRange.replace(/:/g,'')), parseInt(this.endRange.replace(/:/g,'')) ];
				else
					this.range = [ parseInt(this.value[0].replace(/:/g,'')), parseInt(this.value[1].replace(/:/g,'')) ];
			}
			else if (this.type === 'number'){
				if (this.value.length<2)
					this.range = [ this.startRange, this.endRange ];
				else
					this.range = [ this.value[0], this.value[1] ];
			}
			this.emitVal();
		},
		methods:{
			setHour(time){
                let minutes = Math.round((parseInt((''+time).slice(-2)) * 60) / 100),
                      hour = (''+time).slice(0,-2),
                      sg = hour<12||hour==24?'am':'pm';
                      hour = hour<=12?hour:hour-12;
                      minutes = minutes<10?'0'+minutes:minutes;
                return { hour : hour, minutes : minutes, sg: sg }
			},
			setHour24(time){
                let minutes = Math.round((parseInt((''+time).slice(-2)) * 60) / 100),
                      hour = (''+time).slice(0,-2);
                      hour = hour<10?'0'+hour:hour;
                      minutes = minutes<10?'0'+minutes:minutes;
                return { hour : hour, minutes : minutes }
			},
			emitVal(){
				if (this.type === 'time') {
					const min = this.setHour24(this.range[0]);
					const max = this.setHour24(this.range[1]);
					this.$emit('input',[`${min.hour}:${min.minutes}`,`${max.hour}:${max.minutes}`]);
				}
				else if (this.type === 'number'){
					this.$emit('input', this.range);
				}
			},
			handleAny(){
                this.statusAny = !this.statusAny;
                if (this.statusAny)
                	this.range = [this.startRange,this.endRange];
			}
		},
		watch:{
			range: function(){
				var that = this;
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					that.emitVal();
				}, 1400);	
			}
		}
	}
</script>