/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M.646 10.146l6-6a.5.5 0 01.707.707L2.207 9.999H18.5a.5.5 0 010 1H2.207l5.146 5.146a.5.5 0 01-.708.707l-6-6a.5.5 0 010-.707z"/>'
  }
})
