var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalVal.modal == "v-modal-assistants"
    ? _c("div", { staticClass: "v-modal", class: { active: _vm.show } }, [
        _c("div", {
          staticClass: "v-blurColor",
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "v-modal-paper" }, [
          _c(
            "div",
            { staticClass: "v-modal-title", attrs: { bgColor: "green-main" } },
            [
              _c("h4", { staticClass: "v-t" }, [
                _vm._v(_vm._s(_vm.$t("modals.assistants.t")))
              ]),
              _vm._v(" "),
              _c("svgicon", {
                staticClass: "v-btn-close",
                attrs: { name: "cross2" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-4 pl-5 pr-4" },
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "list-students pl-0 pt-3 pr-4",
                  style: {
                    overflow:
                      _vm.currentLesson.students.length > 3
                        ? "hidden !important"
                        : "initial !important"
                  },
                  attrs: { tag: "ul" }
                },
                _vm._l(_vm.currentLesson.students, function(student, i) {
                  return _c(
                    "li",
                    {
                      staticClass:
                        "student d-flex flex-wrap align-items-start pt-2 pb-2 pr-4",
                      class: { active: student.assistance }
                    },
                    [
                      _c("v-img-profile", {
                        staticClass: "mr-2",
                        attrs: { img: student.photo, size: "40px" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col student-info pl-0 pr-0" }, [
                        _c(
                          "div",
                          { staticClass: "row mr-0 ml-0 align-items-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "col pl-0 pr-0 name-and-subject" },
                              [
                                _c("v-hover-txt", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    minWidthCloud: "200px",
                                    positionCloud:
                                      i == 0 &&
                                      _vm.currentLesson.students.length > 1
                                        ? "top"
                                        : "bottom",
                                    txt: student.user_name
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-hover-txt", {
                                  attrs: {
                                    fontSize: "11px",
                                    fontWeight: "400",
                                    minWidthCloud: "200px",
                                    colorTxt: "#717171",
                                    positionCloud:
                                      i == 0 &&
                                      _vm.currentLesson.students.length > 1
                                        ? "top"
                                        : "bottom",
                                    txt: student.career
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col pl-0 pr-0 rating" },
                              [
                                _c("star-rating", {
                                  staticClass: "stars justify-content-end",
                                  class: { "hide-num-rating": !student.rating },
                                  attrs: {
                                    increment: 0.1,
                                    "rounded-corners": true,
                                    "star-points": [
                                      23,
                                      2,
                                      14,
                                      17,
                                      0,
                                      19,
                                      10,
                                      34,
                                      7,
                                      50,
                                      23,
                                      43,
                                      38,
                                      50,
                                      36,
                                      34,
                                      46,
                                      19,
                                      31,
                                      17
                                    ],
                                    rating: student.rating
                                      ? parseInt(student.rating)
                                      : 0,
                                    "star-size": 10,
                                    "border-width": 0.1,
                                    "text-class": "custom-text",
                                    "inactive-color": "#FCE5BF",
                                    "active-color": "#F5A623",
                                    "read-only": ""
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-hover-txt", {
                        staticClass: "comment w-100",
                        attrs: {
                          fontWeight: "400",
                          fontSize: "11px",
                          colorTxt: "#717171",
                          lines: 3,
                          positionCloud:
                            i == 0 && _vm.currentLesson.students.length > 1
                              ? "top"
                              : "bottom",
                          lineHeight: "14px",
                          txt: student.comments ? `"${student.comments}"` : ""
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }