/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faceToFace': {
    width: 17,
    height: 15,
    viewBox: '0 0 17 15',
    data: '<path pid="0" d="M3.833 9.765c2.115 0 3.829 2.89 3.829 4.23 0 1.34-7.662 1.34-7.662 0s1.714-4.23 3.833-4.23zm9.338 0c2.118 0 3.829 2.89 3.829 4.23 0 1.34-7.662 1.34-7.662 0s1.718-4.23 3.833-4.23zM3.833 5.07c1.185 0 2.145.98 2.145 2.188 0 1.208-.96 2.187-2.145 2.187-1.186 0-2.146-.98-2.146-2.187 0-1.209.96-2.188 2.146-2.188zm9.338 0c1.185 0 2.146.98 2.146 2.188 0 1.208-.961 2.187-2.146 2.187s-2.146-.98-2.146-2.187c0-1.209.96-2.188 2.146-2.188zM11.573 0c.745 0 1.361.603 1.431 1.369l.007.145v1.24c0 .757-.537 1.4-1.248 1.5a.485.485 0 00-.4.391l-.008.088v.552l-.837-.871a.47.47 0 00-.265-.14l-.075-.006H9.56a1.94 1.94 0 00.703-1.332l.008-.182v-1.24c0-.553-.226-1.053-.583-1.403L9.563 0h2.01zm-3.14 0c.746 0 1.363.603 1.433 1.369l.006.145v1.24c0 .788-.572 1.434-1.3 1.507l-.138.007H6.829a.467.467 0 00-.284.095l-.057.05-.836.872v-.552a.48.48 0 00-.408-.479c-.666-.091-1.18-.665-1.242-1.36l-.006-.14v-1.24c0-.784.572-1.433 1.3-1.507L5.434 0h3zm-3.26 1.799a.36.36 0 00-.358.364c0 .202.16.365.357.365a.36.36 0 00.358-.365.36.36 0 00-.358-.364zm1.761 0a.36.36 0 00-.357.364c0 .202.16.365.357.365a.36.36 0 00.357-.365.36.36 0 00-.357-.364zm1.762 0a.36.36 0 00-.358.364c0 .202.16.365.358.365a.36.36 0 00.357-.365.36.36 0 00-.357-.364z" _fill="#000" fill-rule="nonzero"/>'
  }
})
