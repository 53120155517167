
    import { Vue, Component, Prop } from 'vue-property-decorator';

    enum Char{
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K'
    }
    
    @Component
    export default class formatQuestionYesNo extends Vue {
        @Prop() readonly question!: any;
        @Prop({ type: Boolean, default: false }) readonly error!: boolean;
        @Prop({ default: false }) readonly statusFormat!: boolean;

        char: any = Char;
        val: number | null = null;

        get emit(): any{
            return (this as any).$emit;
        }

        created(): void{
            if(this.statusFormat)
                this.val = this.question.answer;
        }
        
        handleAnswer(id: number, val: number){
            if(!this.statusFormat){
                this.val = id;
                this.$emit('handleAnswer', { id: this.question.id, answer: id, val: val });
            }
        }

    }
