import header from './header.vue';
import menu from './menu.vue';

// Modals
import modals from './modals';
// Fields 
import fields from './fields';
// Card
import card from './card';
// Schedule
import schedule from './schedule';
// Global
import global from './global';
// Chat
import chat from './chat';
// Lesson
import  lessons from './lessons/index.ts';
// Video room
import room from './room';
// Availability
import availability from './availability';
// Notifications
import notifications from './notifications/index.ts';
// Formats
import formats from './formats/index.ts';
// Schedule session "tutor profile
import scheduleSessionT from './schedule-tutor/index.ts'; 

//Components
function plugin (Vue) {
    Vue.component('my-header', header);
    Vue.component('my-menu', menu);
    Vue.use(lessons);
    Vue.use(card);
    Vue.use(fields);
    Vue.use(modals);
    Vue.use(schedule);
    Vue.use(global);
    Vue.use(chat);
    Vue.use(room);
    Vue.use(availability);
    Vue.use(notifications);
    Vue.use(formats);
    Vue.use(scheduleSessionT);
}

export default plugin
