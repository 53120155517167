var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-format-question-radio" }, [
    _c("div", { staticClass: "v-table-questions pl-3 pr-3" }, [
      _c(
        "div",
        { staticClass: "v-fq-title row align-items-center pt-2 pb-2" },
        [
          _c("div", { staticClass: "col-12 pl-md-4 pl-3 pr-md-4 pr-3" }, [
            _c("div", { staticClass: "v-fq-title-txt" }, [
              _vm._v(_vm._s(_vm.question.question))
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pl-3 pr-3" },
      _vm._l(_vm.question.answers, function(a, i) {
        return _c(
          "div",
          {
            key: a.id,
            staticClass: "v-content-question row",
            class: { "bg-dark": (i + 1) % 2 == 0 }
          },
          [
            _c(
              "div",
              {
                staticClass: "col pl-md-4 pt-2 pb-2 d-flex align-items-center"
              },
              [
                _c("p", { staticClass: "v-question mb-0" }, [
                  _vm._v(_vm._s(a.answer))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _c("div", { staticClass: "row h-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "v-content-question-radio col-auto pl-2 pr-2 h-100 d-flex justify-content-center align-items-center"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "v-question-radio-txt mr-md-3 mr-2" },
                      [_vm._v(_vm._s(`${_vm.char[i]})`))]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "v-question-radio",
                      class: {
                        active: _vm.val == a.id,
                        error: _vm.error && _vm.val == null,
                        disabled: _vm.statusFormat
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleAnswer(a.id, a.val)
                        }
                      }
                    })
                  ]
                )
              ])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }