<template>
	<div class="v-chat pb-3 pt-3 h-100" @dragover="showDropZone = !statusFilePreview?true:showDropZone" >
		<!--<div class="text-right p-2" :style="!btnclose?'opacity: 0; pointer-events: none;':''">
			<button class="v-btn-close-chat" @click="btnclose?$emit('closeChat'):''"><svgicon class="icon ml-1" name="cross2"></svgicon></button>
		</div>-->
        <!--<div class="v-chat-date-absolute" :class="{ hide : !statusChatDate }">{{ date }}</div>-->
        <perfect-scrollbar class="pl-3 pr-3 mb-3 h-100" :key="refreshChat" tag="div" ref="v-chat" :options="options" :style="{ '--scroll-color': auth.permission=='tutor'?'#72BD00':'#00C0ff' }">
            <ul class="v-content-chat mb-0 h-100 pt-4" :class="{ 'chat-t': auth.permission === 'tutor' }" >
                <transition-group name="bound-chat" class="w-100 tran-chat" tag="div" v-if="idLesson && usersMap.size > 0">
                    <div class="chat-spinner p-3" key="spinner" v-if="loadingMsgs">
                        <v-spinner width="40px" height="40px"  />
                    </div>
                    <li 
                    class="position-relative"
                    v-for="(item,i) in chat" 
                    :key="item._id+''">
                        <!-- ====== Message or file ====== -->
                        <v-chat-bubble :type="item.file?'file':'text'" 
                                           :idLesson="idLesson"
                                           :msg="item" 
                                           :userMap="usersMap.get(parseInt(item.uid))"
                                           :showAvatar="i==0?true:chat[i-1].uid !== item.uid" 
                                           :iconFile="getIcon(item.file)"
                                           :iconPreview="showPreview(item.file)?'magnifying2':'download3'"
                                           @showPreview="handleFilePreview(item, true)"
                                           v-if="!item.date "/>
                        <!-- ====== Date ====== -->
                        <div class="v-chat-date text-center" v-else>{{ item.date }}</div>
                    </li>
                </transition-group>
             </ul>
        </perfect-scrollbar>

        <div class="v-chat-msg text-center" v-if="!(idLesson && usersMap.size > 0)">
            <svgicon class="img mb-2" color="#F0F0F0" name="chat2" />
            <p class="msg mb-1">{{ $t('chat.selectAChat') }}</p>
        </div>

        <!-- ============ File preview ============ -->
		<v-chat-tools class="row align-items-center ml-0 mr-0" ref="chatInput" :disabled="disabled" @send="sendMsg" @loadFile="$refs.dropzone.loadFile()" v-if="idLesson && usersMap.size > 0"/>
        
        <!-- ============ Dropzone ============ -->
        <v-dropzone :show="showDropZone" ref="dropzone" @handleDropzone="band => showDropZone = band" @saveFile="saveFile" @dragleave.native="showDropZone = false" :idLesson="idLesson" v-if="!disabled"/>

        <!-- ============ File preview ============ -->
        <v-file-preview :options="optionsFilePreview" @download="downloadFile({ file: optionsFilePreview.file, name: optionsFilePreview.name, idLesson: idLesson })" @close="file => handleFilePreview(file, false)" v-if="statusFilePreview"/>
	</div>
</template>

<script>
	import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

	export default{
		props: {
			//btnclose: { type: Boolean, default: false, required: false },
			idLesson: { default: null, required: true },
			users: { type: Array, default: [], required: true },
			disabled: { type: Boolean, default: false, required: false },
			editCounter: { type: Boolean, default: true, required: false },
            refreshChat: { type: Number, default: 0, required: false }
		},
		data(){
            return{
                htmlChanged: false, // When changed the language or open a preview
            	//statusChatDate: false,
            	//timerDate: '',
            	loadingMsgs: false,
            	scrollFirstTime: false,
            	lastHeight: 0,
            	scrollDir: 'bottom',
            	scrollTop: 0,
            	options: {
            		suppressScrollX: true,
            		wheelSpeed: 0.4
            	},
                showDropZone: false,
                statusFilePreview: false,
                optionsFilePreview: {},
                acceptedFiles : {
                    'pdf': { icon: 'pdf' },       /* ===== PDF ===== */
                    'gif': { icon: 'image' },       /* ===== Start Images ===== */
                    'heic': { icon: 'image' },
                    'heif': { icon: 'image' },
                    'jpeg': { icon: 'image' },
                    'jpg': { icon: 'image' },
                    'png': { icon: 'image' },
                    'svg': { icon: 'image' },
                    'tiff': { icon: 'image' },      /* ===== End Images ===== */
                    'doc': { icon: 'doc' },       /* ===== Start Documents ===== */
                    'docm': { icon: 'doc' },
                    'docx': { icon: 'doc' },
                    'dot': { icon: 'doc' },
                    'dotm': { icon: 'doc' },
                    'dotx': { icon: 'doc' },
                    'odt': { icon: 'doc' },
                    'pages': { icon: 'doc' },     /* ===== End Documents ===== */
                    'key': { icon: 'slide' },   /* ===== Start Slides ===== */
                    'odp': { icon: 'slide' },
                    'pot': { icon: 'slide' },
                    'potm': { icon: 'slide' },
                    'potx': { icon: 'slide' },
                    'ppa': { icon: 'slide' },
                    'ppam': { icon: 'slide' },
                    'pps': { icon: 'slide' },
                    'ppsm': { icon: 'slide' },
                    'ppsx': { icon: 'slide' },
                    'ppt': { icon: 'slide' },
                    'pptm': { icon: 'slide' },
                    'pptx': { icon: 'slide' },      /* ===== End Slides ===== */
                    'csv': { icon: 'sheet' },       /* ===== Start Sheets ===== */
                    'numbers': { icon: 'sheet' },
                    'xla': { icon: 'sheet' },
                    'xlam': { icon: 'sheet' },
                    'xls': { icon: 'sheet' },
                    'xlsb': { icon: 'sheet' },
                    'xlsm': { icon: 'sheet' },
                    'xlsx': { icon: 'sheet' },
                    'xlt': { icon: 'sheet' },
                    'xltm': { icon: 'sheet' },
                    'xltx': { icon: 'sheet' },      /* ===== End Sheets ===== */
                    '7z': { icon: 'compressed' },        /* ===== Start Compressed ===== */
                    'zip': { icon: 'compressed' },  
                    'bz': { icon: 'compressed' },
                    'bz2': { icon: 'compressed' },
                    'gz': { icon: 'compressed' },
                    'gzip': { icon: 'compressed' },
                    'rar': { icon: 'compressed' },
                    'tar': { icon: 'compressed' },
                    'tgz': { icon: 'compressed' },
                    'zip': { icon: 'compressed' },       /* ===== End Compressed ===== */
                    'rtf': { icon: 'txt' },       /* ===== Start Textfiles ===== */
                    'txt': { icon: 'txt' },       /* ===== End Textfiles ===== */
                    '3g2': { icon: 'file-video' },       /* ===== Start Videos ===== */
                    '3gp': { icon: 'file-video' },
                    'avi': { icon: 'file-video' },
                    'flv': { icon: 'file-video' },
                    'm3u8': { icon: 'file-video' },
                    'mov': { icon: 'file-video' },
                    'mp4': { icon: 'file-video' },
                    'mpeg': { icon: 'file-video' },
                    'ogv': { icon: 'file-video' },
                    'ts': { icon: 'file-video' },
                    'webm': { icon: 'file-video' },
                    'wmv': { icon: 'file-video' },       /* ===== End Videos ===== */   
                    'aac': { icon: 'file-audio' },       /* ===== Start Audios ===== */
                    'm4a': { icon: 'file-audio' },  
                    'mp3': { icon: 'file-audio' },
                    'ogg': { icon: 'file-audio' },
                    'opus': { icon: 'file-audio' }       /* ===== End Audios ===== */    
                },
                filePreviews : [
                    /*PDG*/ 'pdf',
                    /*DOCS:*/ 'docx', 'dotx', 'dotm', 'docm', 'doc', 'dot',
                    /*SLIDES:*/ 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx',
                    /*SHEETS:*/ 'csv', 'xlam', 'xls', 'xlsb', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx',
                    /*TXT:*/ 'rtf', 'txt',
                    /*IMAGES:*/ 'gif', 'heic', 'jpeg', 'jpg', 'png', 'svg', 'tiff',
                    /*VIDEO:*/ 'mp4', 'webm', 'ogv',
                    /*AUDIO:*/ 'acc', 'mp3', 'ogg', 'm4a', 'aac', 'opus',

                ]
            }
		},
		computed:{
			...mapGetters(['chat']),
            ...mapState(['auth','msgs', 'i18n','firstMsg', 'lastMsgVisible', 'userFirebase', 'chatFiles', 'counters']),
            usersMap(){
                let usersMap = new Map();
                this.users.forEach( user => {
                    usersMap.set(user.id, user);
                } );
                return usersMap;
            },
            tutorId(){
                return this.users.find( item => item.type == 'tutor' ).id+'';
            },
            ref(){
            	if (this.idLesson)
            		return this.$firebase.db.ref().child(`/chat_messages/${this.auth.user.university_id}/${this.idLesson}`);
            	else{
            		return '';
            	}
            },
            currentMsg(){
            	return this.msgs[this.idLesson];
            },
            chatAndLang(){
            	return{ chat: this.chat, lang: this.i18n.locale, id: this.$route.params.idlesson }
            }
            /*linkifyTxt(){
                return this.linkify(this.txt);
            }*/
		},
		created(){
			this.$nextTick(()=>{
                if (this.idLesson && this.userFirebase){
                	this.getFirstMsg(this.ref);
                	this.getlastMsgs(this.ref);
            	    this.getNewMsgs(this.ref);
                }
            })
		},
		mounted(){
			this.$nextTick(()=>{
				if (this.currentMsg)
					if (this.currentMsg.counter > 0 && this.idLesson && this.editCounter && this.counters.chats){
                        let cont = this.counters.chats[`${this.auth.permission}_counter`] - this.currentMsg.counter;
                            cont = cont < 0?0:cont;
						this.editCont(cont);
                    }
					this.$refs['v-chat'].$el.addEventListener('ps-scroll-up', this.scrollToTop);
					//this.$refs['v-chat'].$el.addEventListener('ps-scroll-y', this.scrollEvent);
			})
		},
		updated(){		
            if (this.htmlChanged)
                this.htmlChanged = false;
			else if (this.$refs['v-chat'].$el.querySelectorAll('.tran-chat li').length > 0 && !this.loadingMsgs){
                this.scrollDown();
			}
		},
		beforeDestroy(){
            this.stopChatRef(this.ref);
            this.setStatusChat(false);
            this.$refs['v-chat'].$el.removeEventListener('ps-scroll-up', this.scrollToTop);
            //this.$refs['v-chat'].$el.removeEventListener('ps-scroll-y', this.scrollEvent);
		},
		methods:{
			...mapActions(['getFirstMsg', 'getlastMsgs', 'getChat', 'getNewMsgs','stopChatRef','changeCont','getNextMsgs', 'axiosRequest', 'downloadFile']),
			...mapMutations(['setStatusChat']),
			async sendMsg (txt) {
				if (!this.disabled && this.idLesson)
					try{
						const photo = this.auth.user.photo.split('/'),
						date = this.$moment().valueOf();
						const datas = {
							_id : date,
							createdAt : this.$firebase.firebase.database.ServerValue.TIMESTAMP,
							profile : this.auth.permission,
							text : txt,
							uid : this.auth.user.id+'',
                            tutorId: this.tutorId
						}
						const postRef = await this.ref.push();
						postRef.set(datas);
                        this.$refs.chatInput.clearMsg();    
					}
					catch (e) {
						console.log('Error200', e);
					}
            },
            async saveFile (file) {
                if (!this.disabled && this.idLesson)
                    try{
                        const photo = this.auth.user.photo.split('/'),
                        date = this.$moment().valueOf();
                        const datas = {
                            _id : date,
                            createdAt : this.$firebase.firebase.database.ServerValue.TIMESTAMP,
                            file: file.path,
                            profile : this.auth.permission,
                            text : file.originalName,
                            size: file.size,
                            uid : this.auth.user.id+'',
                            tutorId: this.tutorId
                        }
                        const postRef = await this.ref.push();
                        postRef.set(datas);       
                    }
                    catch (e) {
                        console.log('Error200', e);
                    }
            },
            scrollDown() { // Scroll to down         
            	let objDiv = this.$refs['v-chat'].$el;
            	const height = objDiv.scrollHeight;
            	if (this.scrollDir === 'top'){ 
            		objDiv.scrollTop = height - this.lastHeight + this.scrollTop;
            	}
            	else if(this.scrollDir === 'bottom' && ( (height- this.$refs['v-chat'].ps.railYHeight*2 ) < this.$refs['v-chat'].ps.lastScrollTop || !this.scrollFirstTime)){
            		this.scrollFirstTime = true;
            		objDiv.scrollTop = height;
            	}
                if (this.lastMsgVisible)
            	    if (this.firstMsg[this.lastMsgVisible.key])
            			this.scrollDir = 'bottom'    

            	this.lastHeight = height;
            },
            editCont(counter){
            	this.changeCont({ db: this.$firebase.db.ref().child(`/chat_counter/${this.auth.user.university_id}/${this.auth.user.id}/${this.idLesson}`), counter: counter });
            },
            scrollToTop(e){ // Lister scroll to top
            	if (this.lastMsgVisible){
            		this.scrollTop= e.target.scrollTop;
            		if(this.scrollTop < 400 && !this.loadingMsgs && !this.firstMsg[this.lastMsgVisible.key]){
            			this.loadingMsgs = true; 
            			this.getNextMsgs(this.ref);
            		}	
            	}
            },
            linkify(text, isFile) {
                if(!isFile){
                    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                    /*/((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;*/
                    return text.replace(urlRegex, (url) => {
                        return '<a class="v-link" href="' + url + '" target="_blank">' + url + '</a>';
                    });
                }else{
                    const ext = text.split('.').pop(),
                          name = text.substr(0, (text.length - (ext.length + 1)));
                    if (name.length >= 30)
                        return `${name.substr(0, 29)}...${ext}`;
                    else
                        return text;
                }
            },
            getIcon(file){
                if (file) {
                    const ext = file.split('.').pop().toLowerCase(),
                    icon = this.acceptedFiles[ext];
                    if (icon)
                        return icon.icon;
                    else
                        return 'file'
                }
                return undefined;
            },
            handleFilePreview(item, band){
                if (item.file) {
                    if (!band){
                        this.optionsFilePreview = {};
                        this.htmlChanged = true;
                        this.statusFilePreview = band;
                        return;
                    }

                    const icon = this.getIcon(item.text),
                    { file, size, text } = item;

                    if (this.showPreview(file)) {
                        this.optionsFilePreview = band?{
                            createdAt: item.createdAt,
                            file: file,
                            size: size,
                            name: text,
                            extension: file.split('.').pop().toLowerCase(),
                            icon: icon,
                            idLesson: this.idLesson
                        }:{};
                        this.htmlChanged = true;
                        this.statusFilePreview = band;
                    }else{
                        this.downloadFile({ file: item.file, name: item.text, idLesson: this.idLesson });
                    }
                }
            },
            showPreview(file){
                if (file) {
                    const ext = file.split('.').pop().toLowerCase();

                    return this.filePreviews.find(i => i == ext)?true:false;
                }
                return undefined;
            },
            /*scrollEvent(e){
            	const dates = document.querySelectorAll('.v-chat-date');
            	const chat = this.$refs['v-chat'];
            	for (var i = 0; i < dates.length; i++) {
            		let next = dates[i+1] ? dates[i+1].offsetTop : chat.$el.scrollHeight;
            		let current = dates[i].offsetTop-29;
            		if (chat.ps.lastScrollTop >=  current && this.$refs['v-chat'].ps.lastScrollTop <= next ){
                        clearInterval(this.timerDate);
            			this.statusChatDate = true;
                        this.date = dates[i].innerHTML;
                        const that = this;
            		    this.timerDate = setTimeout( () => {
                            that.statusChatDate = false;
            		    },4000);
            		    break;
            		}
            	}
			}*/
		},
		watch: {
            'i18n.locale': function(){
                this.htmlChanged = true;
            },
			currentMsg: function(newVal, oldVal){
				if (newVal && this.counters.chats)
					if (newVal.counter > 0 && this.idLesson && this.editCounter){
                        let cont = this.counters.chats[`${this.auth.permission}_counter`] - this.currentMsg.counter;
                            cont = cont < 0?0:cont;
						this.editCont(cont);
                    }
			},
			editCounter:  function(newVal, oldVal){
				if (newVal && this.currentMsg && this.counters.chats)
					if (this.currentMsg.counter > 0 && this.idLesson){
                        let cont = this.counters.chats[`${this.auth.permission}_counter`] - this.currentMsg.counter;
                            cont = cont < 0?0:cont;
						this.editCont(cont);
                    }
			},
			chatAndLang: {
                handler: function(newVal,oldVal) {
                	if (newVal.lang === oldVal.lang){
                		if (oldVal.chat.length > 0 && newVal.chat.length > 0){ // Direction to doing scroll
                			if (newVal.chat[newVal.chat.length-1]._id !== oldVal.chat[oldVal.chat.length-1]._id || oldVal.chat.length === newVal.chat.length)
                				this.scrollDir = 'bottom';
                			else if (this.lastMsgVisible)
                				this.scrollDir = 'top';
                		}
                		this.loadingMsgs = false;
                	}
                },
                deep: true
            },
            counters: function(newVal, oldVal){
                if (this.editCounter && this.currentMsg && newVal.chats && !oldVal.chats)
                    if (this.currentMsg.counter > 0 && this.idLesson){
                        let cont = newVal.chats[`${this.auth.permission}_counter`] - this.currentMsg.counter;
                            cont = cont < 0?0:cont;
                        this.editCont(cont);
                    }
            }
		}
	}
</script>