/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'man': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.37 15.875c-1.113-.867-2.775-1.348-4.253-1.704-.257-.062-.603-.459-.737-1.017-.071-.294-.169-1.034.446-1.561.439-.376.75-.838.964-1.339.789-.976 1.287-2.004 1.481-3.058.167-.909.111-1.863-.167-2.834-.317-1.11-1.099-2.028-2.261-2.656A6.582 6.582 0 009.539.942c-.741.03-1.404.201-1.917.494-.476.272-.815.644-.998 1.088-.819.108-1.444.578-1.782 1.349-.372.849-.39 2.039-.051 3.351.305 1.181.855 2.278 1.517 3.031.16.497.426.961.867 1.339.609.522.47 1.337.413 1.574-.133.554-.463.945-.704 1.003-1.478.356-3.139.836-4.253 1.704-1.097.854-1.63 2.04-1.63 3.625a.5.5 0 00.5.5h17a.5.5 0 00.5-.5c0-1.585-.533-2.77-1.63-3.625zM5.758 4.274C5.989 3.746 6.384 3.5 7 3.5a.5.5 0 00.49-.402c.159-.797 1.183-1.12 2.089-1.157 1.818-.074 4.035.846 4.563 2.696.284.994.295 1.94.03 2.854a6.381 6.381 0 00-.33-1.629C13.705 5.492 13.469 5 13 5c-2.568 0-4.636-.343-5.146-.854A.499.499 0 007 4.5c0 1.363-.453 2.364-.759 2.611a.5.5 0 00-.186.4c.003.125.004.253.005.386-.513-1.29-.68-2.76-.301-3.623zM2.021 19c.091-1.023.479-1.756 1.224-2.336.766-.597 1.924-1.052 3.872-1.52.66-.159 1.226-.842 1.443-1.742.242-1.006-.032-1.966-.734-2.568-.754-.646-.76-1.769-.766-2.855l-.002-.274c.387-.441.759-1.268.891-2.354.582.239 1.389.411 2.436.521.741.078 1.578.122 2.431.127.173.33.444 1.346.366 2.467-.051.73-.267 1.733-1.008 2.369-.717.615-1.004 1.57-.767 2.554.214.889.807 1.594 1.476 1.755 1.948.469 3.106.923 3.872 1.52.745.58 1.133 1.313 1.224 2.336H2.021z"/>'
  }
})
