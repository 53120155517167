/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 197,
    height: 107,
    viewBox: '0 0 197 107',
    data: '<defs><filter x="-4.6%" y="-5.4%" width="109.2%" height="115.2%" filterUnits="objectBoundingBox" id="svgicon_dashboard_b"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.787873641 0 0 0 0 0.787873641 0 0 0 0 0.787873641 0 0 0 0.5 0" in="shadowBlurOuter1"/></filter><path pid="0" d="M2 0h148a2 2 0 012 2v90H0V2a2 2 0 012-2z" id="svgicon_dashboard_a"/></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(107.5)"><ellipse pid="1" _fill="#F5F9FA" cx="33" cy="32.5" rx="33" ry="32.5"/><circle pid="2" _fill="#F5F5F5" cx="78.5" cy="25" r="11"/><circle pid="3" _fill="#F5F5F5" cx="67.5" cy="6" r="6"/></g><g transform="translate(4 8.5)"><mask id="svgicon_dashboard_c" _fill="#fff"><use xlink:href="#svgicon_dashboard_a"/></mask><use _fill="#000" filter="url(#svgicon_dashboard_b)" xlink:href="#svgicon_dashboard_a"/><use _fill="#FFF" xlink:href="#svgicon_dashboard_a"/><g mask="url(#svgicon_dashboard_c)"><g transform="translate(7 20)"><circle pid="4" _fill="#D8D8D8" cx="9" cy="9" r="9"/><rect pid="5" _fill="#F4F4F4" x="20" y="2" width="57" height="5" rx="2.5"/><rect pid="6" _fill="#F4F4F4" x="20" y="11.5" width="32" height="5" rx="2.5"/></g></g><g mask="url(#svgicon_dashboard_c)"><g transform="translate(7 43.25)"><circle pid="7" _fill="#D8D8D8" cx="9" cy="9" r="9"/><rect pid="8" _fill="#F4F4F4" x="20" y="2" width="57" height="5" rx="2.5"/><rect pid="9" _fill="#F4F4F4" x="20" y="11.5" width="32" height="5" rx="2.5"/></g></g><g mask="url(#svgicon_dashboard_c)"><g transform="translate(7 66.5)"><circle pid="10" _fill="#D8D8D8" cx="9" cy="9" r="9"/><rect pid="11" _fill="#F4F4F4" x="20" y="2" width="57" height="5" rx="2.5"/><rect pid="12" _fill="#F4F4F4" x="20" y="11.5" width="32" height="5" rx="2.5"/></g></g><path pid="13" _fill="#ACACAC" mask="url(#svgicon_dashboard_c)" d="M-2 .5h157v7H-2z"/></g></g>'
  }
})
