var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "moreInfo",
      staticClass: "more-info",
      style: { width: _vm.sizePx, height: _vm.sizePx },
      on: {
        mouseover: function($event) {
          _vm.modality == "hover" ? _vm.showCloud() : null
        },
        mouseleave: function($event) {
          _vm.modality == "hover" ? _vm.hideCloud() : null
        }
      }
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "cloud pl-2 pt-3 pr-2 pb-3",
              class: { show: _vm.statusCloud },
              style: {
                [_vm.cloudPositionX]: `-${_vm.positionVal}px`,
                [_vm.cloudPositionY === "top" ? "bottom" : "top"]: `${parseInt(
                  _vm.sizePx.replace("px", "")
                ) + 8}px`,
                width: _vm.widthCloud,
                maxWidth: _vm.maxWidthCloud,
                minWidth: _vm.minWidthCloud,
                background:
                  _vm.auth.permission === "student" ? "#2188FC" : "#005E58",
                "border-radius": ` 3px 3px ${_vm.paddingRightBottom} ${_vm.paddingLeftBottom}`
              }
            },
            [
              _c("div", {
                staticClass: "arrow",
                style: {
                  [_vm.cloudPositionX]: `${_vm.positionVal}px`,
                  [_vm.cloudPositionY]: "100%",
                  border: "8px solid transparent",
                  [`border-${_vm.cloudPositionY}-Color`]:
                    _vm.auth.permission === "student" ? "#2188FC" : "#005E58"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "cloud-txt mb-0",
                  style: { fontSize: _vm.fontSize, lineHeight: _vm.lineHeight }
                },
                [_vm._v(_vm._s(_vm.txtCloud))]
              ),
              _vm._v(" "),
              _vm.modality == "time"
                ? _c("svgicon", {
                    staticClass: "icon-cross",
                    attrs: { name: "close-sharp", color: "#fff" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.breakCloud()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.icon
        ? _c("svgicon", {
            staticClass: "info-icon",
            attrs: { name: _vm.icon, color: _vm.iconColor }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }