var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "v-format" },
    [
      !_vm.imReady
        ? _c(
            "div",
            { staticClass: "v-format-spinner" },
            [_c("v-spinner-logo", { attrs: { size: "120px" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "v-format-info" }, [
        _c("h3", { staticClass: "v-format-title text-center mb-sm-5 mb-4" }, [
          _vm._v(_vm._s(_vm.$t("formats.fm2.t")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-n3 mr-n3 mb-4" }, [
          _vm.statusFormat && _vm.imReady
            ? _c("div", { staticClass: "pl-3 pr-3" }, [
                _c("div", { staticClass: "v-fotmat-user-info" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-md-4 mb-3" }, [
                      _c("p", { staticClass: "vf-txt mb-0" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("formats.fm2.date")))
                        ]),
                        _vm._v(" " + _vm._s(_vm.createdDate))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 mb-3" }, [
                      _c("p", { staticClass: "vf-txt mb-0" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("formats.fm2.name")))
                        ]),
                        _vm._v(" " + _vm._s(_vm.user.fullName))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 text-md-center mb-3" }, [
                      _c("p", { staticClass: "vf-txt mb-0" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("formats.fm2.semester")))
                        ]),
                        _vm._v(" " + _vm._s(_vm.user.period))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 text-md-right mb-3" }, [
                      _c("p", { staticClass: "vf-txt mb-0" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("formats.fm2.noControl")))
                        ]),
                        _vm._v(" " + _vm._s(_vm.user.studentId))
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "mb-4",
          domProps: { innerHTML: _vm._s(_vm.$t("formats.fm2.dHtml")) }
        }),
        _vm._v(" "),
        _c("h4", { staticClass: "v-format-subTitle mb-4 pt-3" }, [
          _vm._v(_vm._s(_vm.$t("formats.fm2.sub1")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-format-info pt-4" },
        [
          _vm.imReady
            ? _c("v-content-questions-yes-no", {
                staticClass: "mb-4",
                attrs: {
                  statusFormat: _vm.statusFormat,
                  questions: _vm.qst1,
                  score: _vm.score ? _vm.score.organization : "",
                  error: _vm.error
                },
                model: {
                  value: _vm.qst1Val,
                  callback: function($$v) {
                    _vm.qst1Val = $$v
                  },
                  expression: "qst1Val"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "v-format-subTitle mb-4 pt-3" }, [
            _vm._v(_vm._s(_vm.$t("formats.fm2.sub2")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-format-info pt-4" },
        [
          _vm.imReady
            ? _c("v-content-questions-yes-no", {
                staticClass: "mb-4",
                attrs: {
                  statusFormat: _vm.statusFormat,
                  questions: _vm.qst2,
                  score: _vm.score ? _vm.score.technical : "",
                  error: _vm.error
                },
                model: {
                  value: _vm.qst2Val,
                  callback: function($$v) {
                    _vm.qst2Val = $$v
                  },
                  expression: "qst2Val"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "v-format-subTitle mb-4 pt-3" }, [
            _vm._v(_vm._s(_vm.$t("formats.fm2.sub3")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-format-info pt-4" },
        [
          _vm.imReady
            ? _c("v-content-questions-yes-no", {
                staticClass: "mb-4",
                attrs: {
                  statusFormat: _vm.statusFormat,
                  questions: _vm.qst3,
                  score: _vm.score ? _vm.score.motivation : "",
                  error: _vm.error
                },
                model: {
                  value: _vm.qst3Val,
                  callback: function($$v) {
                    _vm.qst3Val = $$v
                  },
                  expression: "qst3Val"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-4 pt-5 d-flex flex-column align-items-center" },
        [
          _vm.statusFormat && _vm.imReady
            ? _c(
                "div",
                {
                  staticClass:
                    "v-format-result w-100 p-4 mb-sm-5 mb-4 text-center"
                },
                [
                  _c("div", { staticClass: "v-format-result-t mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("formats.qualityResult.t")))
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.score.final))])
                ]
              )
            : _c("v-btn", {
                staticClass: "w-100",
                attrs: {
                  max: "500px",
                  fontSize: "1rem",
                  fontWeight: "500",
                  height: "40px",
                  spinner: _vm.sending,
                  txt: _vm.$t("formats.fm2.btn")
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.sendFormat.apply(null, arguments)
                  }
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _vm.statusFormat && _vm.imReady && _vm.pdfData.createdDate
        ? _c("v-study-skills-pdf", { ref: "pdf", attrs: { data: _vm.pdfData } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }