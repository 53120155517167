var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-switch row flex-nowrap",
      attrs: { isActive: _vm.disabledOpc !== "both" ? _vm.opcActive : "" }
    },
    _vm._l(_vm.switchBtns.slice(0, 2), function(item, i) {
      return _c(
        "button",
        {
          key: i,
          staticClass: "btn-switch",
          attrs: {
            disabled: i + 1 == _vm.disabledOpc || _vm.disabledOpc == "both"
          },
          on: {
            click: function($event) {
              return _vm.send(item, i)
            }
          }
        },
        [
          _c("svgicon", { staticClass: "icon", attrs: { name: item.icon } }),
          _vm._v(" "),
          _c("span", { staticClass: "btn-text" }, [
            _vm._v(_vm._s(item.textBtn) + " ")
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }