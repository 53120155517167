var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      ref: "header",
      class: {
        compact: _vm.menu.compact,
        show: _vm.menu.showMenuResponsive,
        "bgColor-tutor-main": _vm.auth.permission === "tutor",
        "bgColor-student-main": _vm.auth.permission === "student"
      },
      attrs: { id: "header-main" }
    },
    [
      _c("div", { staticClass: "container-fluid h-100" }, [
        _c("div", { staticClass: "row h-100" }, [
          _c(
            "div",
            {
              staticClass: "col h-100 align-items-center logo d-none d-lg-flex",
              class:
                _vm.auth.permission === "tutor"
                  ? "bgColor-tutor-dark"
                  : !_vm.statusFormats
                  ? "bgColor-student-dark"
                  : null
            },
            [
              _c("router-link", {
                attrs: {
                  src: _vm.auth.institution.dashboard_logo,
                  alt: _vm.auth.institution.name,
                  tag: "img",
                  to: { name: `dashboard-${_vm.auth.permission}` }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col h-100 btn-menu" }, [
            !_vm.statusFormats
              ? _c("div", { staticClass: "row h-100 align-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "content-btn-menu col h-100",
                      class: _vm.menu.compact
                        ? `bgColor-${_vm.auth.permission}-dark`
                        : ""
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-100 h-100 btn-menu-conpact d-none d-lg-block",
                          on: {
                            click: function($event) {
                              return _vm.setCompact(!_vm.menu.compact)
                            }
                          }
                        },
                        [
                          _c("svgicon", {
                            staticClass: "icon",
                            attrs: { name: "menu" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-100 h-100 btn-menu-conpact d-block d-lg-none",
                          on: {
                            click: function($event) {
                              return _vm.setStatusMenuResponsive(
                                !_vm.menu.showMenuResponsive
                              )
                            }
                          }
                        },
                        [
                          _c("svgicon", {
                            staticClass: "icon",
                            attrs: { name: "menu" }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col d-none d-md-block" }, [
                    _c("span", { staticClass: "text-dashboard" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass:
                "menu navbar navbar-expand justify-content-end align-items-center h-100 col col-md-auto pl-0 pr-0 pr-md-3 pl-md-3"
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav h-100 align-items-center" },
                [
                  !_vm.statusFormats
                    ? _c(
                        "li",
                        { staticClass: "pl-2 pr-2" },
                        [
                          _c("v-dropdown", {
                            staticClass: "btn-count",
                            class: {
                              disabled:
                                _vm.$route.name ==
                                  `chat-${_vm.auth.permission}` ||
                                _vm.$route.name ==
                                  `chat-${_vm.auth.permission}-id`
                            },
                            style: {
                              "--fill-hover":
                                _vm.auth.permission == "tutor"
                                  ? "#005E58"
                                  : "#2188FC"
                            },
                            attrs: {
                              statusSlot: false,
                              tag: "div",
                              txtHover: _vm.$t("title.chat")
                            },
                            nativeOn: {
                              click: function($event) {
                                _vm.$route.name !=
                                  `chat-${_vm.auth.permission}` &&
                                _vm.$route.name !=
                                  `chat-${_vm.auth.permission}-id`
                                  ? _vm.$router.push({
                                      name: `chat-${_vm.auth.permission}`
                                    })
                                  : () => {}
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c("svgicon", {
                                        staticClass: "icon-count",
                                        attrs: { name: "chat2" }
                                      }),
                                      _vm._v(" "),
                                      _vm.counterChats > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "counter-btn" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.counterChats))
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              594810886
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.statusFormats
                    ? _c(
                        "li",
                        { staticClass: "pl-2 pr-2" },
                        [
                          _c("v-dropdown", {
                            staticClass: "btn-count",
                            class: {
                              disabled:
                                _vm.$route.name ==
                                `notifications-${_vm.auth.permission}`,
                              active: _vm.statusNof
                            },
                            style: {
                              "--fill-hover":
                                _vm.auth.permission == "tutor"
                                  ? "#005E58"
                                  : "#2188FC"
                            },
                            attrs: {
                              statusArrow: true,
                              statusSlot:
                                _vm.$route.name !=
                                `notifications-${_vm.auth.permission}`,
                              tag: "div",
                              txtHover: _vm.$t("title.notifications")
                            },
                            on: { handleDropdown: e => (_vm.statusNof = e) },
                            nativeOn: {
                              click: function($event) {
                                _vm.grid == "xs"
                                  ? _vm.$router.push({
                                      name: `notifications-${_vm.auth.permission}`
                                    })
                                  : () => {}
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c("svgicon", {
                                        staticClass: "icon-count",
                                        attrs: { name: "bell-fill" }
                                      }),
                                      _vm._v(" "),
                                      _vm.counterNotifications > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "counter-btn" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.counterNotifications
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "dropdown",
                                  fn: function() {
                                    return [
                                      _vm.grid != "xs"
                                        ? _c("v-notification-list", {
                                            attrs: {
                                              maxHeight: "356px",
                                              statusSeeAll: true
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3601036773
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-dropdown", {
                    staticClass: "pl-md-3 pr-md-3 pl-2 pr-2 btn-help",
                    attrs: { tag: "li", submenu: _vm.help },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t("room.toolbar.help.t")) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                            _c("svgicon", {
                              staticClass: "icon",
                              attrs: { name: "chevron-down" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("v-dropdown", {
                    staticClass:
                      "pl-md-3 pr-md-3 pl-2 pr-2 h-100 d-flex align-items-center language",
                    attrs: { submenu: _vm.languages, val: "long" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.lang.long) +
                                " "
                            ),
                            _c("svgicon", {
                              staticClass: "icon",
                              attrs: { name: "chevron-down" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.lang,
                      callback: function($$v) {
                        _vm.lang = $$v
                      },
                      expression: "lang"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-dropdown", {
                    staticClass:
                      "pl-md-3 pr-md-3 pl-2 pr-2 h-100 d-flex align-items-center profile",
                    attrs: { submenu: _vm.profile },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("div", {
                              staticClass: "picture",
                              style: {
                                background:
                                  "url(" +
                                  _vm.auth.user.photo +
                                  ")center top / cover no-repeat"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.auth.user.name))
                            ]),
                            _vm._v(" "),
                            _c("svgicon", {
                              staticClass: "icon",
                              attrs: { name: "ellipsis-v" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }