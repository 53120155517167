import mutations from './mutations.js';
import actions from './actions.js';

const state = {
	chatFiles: {
		inProgress: {},  // file : { progress, cancelToken }
		downloading: {} // Limit 20 files -->  file : fileBlod
	}
}

export default{
	state,
	mutations,
	actions
}