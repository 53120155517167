var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: {
        pointerEvents:
          _vm.notifications.length + _vm.notificationsE.length > 0
            ? "initial"
            : "none"
      },
      attrs: { id: "notifications" }
    },
    [
      _c(
        "transition-group",
        { attrs: { name: "notification", tag: "div" } },
        _vm._l([..._vm.notifications, ..._vm.notificationsE], function(
          item,
          i
        ) {
          return _c(
            "div",
            {
              key: `msg-${item.id}`,
              staticClass: "notification mt-2",
              attrs: {
                type:
                  _vm.auth.permission === "tutor" &&
                  item.type.toLowerCase() === "success"
                    ? "successt"
                    : item.type.toLowerCase()
              }
            },
            [
              item.type.toLowerCase() === "success"
                ? _c("svgicon", {
                    staticClass: "n-icon",
                    attrs: { name: item.icon ? item.icon : "checkmark-circle" }
                  })
                : item.type.toLowerCase() === "warning"
                ? _c("svgicon", {
                    staticClass: "n-icon",
                    attrs: { name: item.icon ? item.icon : "wondering" }
                  })
                : _c("svgicon", {
                    staticClass: "n-icon",
                    attrs: { name: item.icon ? item.icon : "shocked" }
                  }),
              _vm._v(" "),
              item.title
                ? _c("h6", { staticClass: "title mb-0" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", {
                staticClass: "msg mb-0",
                domProps: { innerHTML: _vm._s(_vm.handleMsg(item.msg)) }
              }),
              _vm._v(" "),
              _c("svgicon", {
                staticClass: "btn-close",
                attrs: { name: "cross-circle" },
                on: {
                  click: function($event) {
                    return _vm.close(item)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }