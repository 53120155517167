var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": true,
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "letter",
            "pdf-orientation": "portrait",
            image: { type: "jpg", quality: 0.95 },
            "html-to-pdf-options": {
              margin: [10, 0],
              pagebreak: { mode: ["avoid-all", "css", "legacy"] }
            }
          }
        },
        [
          _c(
            "section",
            {
              staticClass: "format-pdf",
              attrs: { slot: "pdf-content" },
              slot: "pdf-content"
            },
            [
              _c(
                "h3",
                { staticClass: "pdf-title text-center mb-4 pl-3 pr-3" },
                [_vm._v(_vm._s(_vm.$t("formats.fm5.t")))]
              ),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "ml-n3 mr-n3 pt-2 mb-4 pl-5 pr-5" },
                [
                  _c("section", { staticClass: "pl-3 pr-3 mb-4" }, [
                    _c("section", { staticClass: "row" }, [
                      _c("section", { staticClass: "col-12 mb-4" }, [
                        _c("p", { staticClass: "pdf-txt mb-0" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm2.date")))
                          ]),
                          _vm._v(" " + _vm._s(_vm.data.createdDate))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "col-4 mb-3" }, [
                        _c("p", { staticClass: "pdf-txt mb-0" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm2.name")))
                          ]),
                          _vm._v(" " + _vm._s(_vm.data.fullName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "col-4 text-center mb-3" }, [
                        _c("p", { staticClass: "pdf-txt mb-0" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm2.semester")))
                          ]),
                          _vm._v(" " + _vm._s(_vm.data.period))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "col-4 text-right mb-3" }, [
                        _c("p", { staticClass: "pdf-txt mb-0" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("formats.fm2.noControl")))
                          ]),
                          _vm._v(" " + _vm._s(_vm.data.studentId))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "w-100 position-relative pt-2" },
                      [_c("section", { staticClass: "bottom-line" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", {
                    staticClass: "pdf-txt mb-4 pl-3 pr-3",
                    domProps: { innerHTML: _vm._s(_vm.$t("formats.fm5.dHtml")) }
                  }),
                  _vm._v(" "),
                  _c("section", { staticClass: "pl-3 pr-3 mb-4" }, [
                    _c(
                      "section",
                      { staticClass: "row justify-content-between" },
                      _vm._l(_vm.data.answers, function(item, i) {
                        return _c(
                          "section",
                          { key: i, staticClass: "pdf-txt col-auto" },
                          [_vm._v(_vm._s(item.t))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "w-100 position-relative pt-4" },
                      [_c("section", { staticClass: "bottom-line" })]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "mb-5 pl-5 pr-5" },
                [
                  _c(
                    "section",
                    { staticClass: "pdf-table-head row ml-0 mr-0" },
                    [
                      _c(
                        "section",
                        { staticClass: "col pr-0 align-self-center" },
                        [
                          _c(
                            "section",
                            { staticClass: "pdf-table-title pt-3 pb-3" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("formats.fm2.tableT")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "col-auto pl-0 pr-0 text-center" },
                        [
                          _c(
                            "section",
                            { staticClass: "pdf-table-title pl-3 pt-2 pb-1" },
                            [_vm._v(_vm._s(_vm.$t("formats.fm2.scaleT")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.data.answers, function(ans, i) {
                              return _c(
                                "section",
                                {
                                  key: i,
                                  staticClass:
                                    "pdf-table-title col-auto pl-0 pr-0",
                                  style: { width: "50px", fontSize: "18px" }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "v-fq-title-txt" },
                                    [_vm._v(_vm._s(i))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.data.q, function(a, i) {
                    return _c(
                      "section",
                      {
                        key: i,
                        staticClass: "pdf-table-body row ml-0 mr-0",
                        class: { "bg-dark": (i + 1) % 2 == 0 }
                      },
                      [
                        _c("section", { staticClass: "col pr-0" }, [
                          _c("section", { staticClass: "pdf-txt pt-2 pb-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(a.question) +
                                "\n                        "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "section",
                          { staticClass: "col-auto pl-0 pr-0 text-center" },
                          [
                            _c(
                              "section",
                              { staticClass: "d-flex h-100" },
                              _vm._l(_vm.data.answers, function(ans, i) {
                                return _c(
                                  "section",
                                  {
                                    key: i,
                                    staticClass:
                                      "pdf-table-row h-100 col-auto d-flex align-items-center pt-2 pb-2",
                                    style: { width: "50px" }
                                  },
                                  [
                                    _c("section", { staticClass: "left-line" }),
                                    _vm._v(" "),
                                    _c(
                                      "section",
                                      { staticClass: "v-fq-title-txt" },
                                      [
                                        _c("section", {
                                          staticClass: "pdf-radio",
                                          class: { active: a.answer == i + 1 }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass: "mb-4 pt-4 d-flex flex-column align-items-center"
                },
                [
                  _c(
                    "section",
                    { staticClass: "pdf-result w-100 p-4 mb-5 text-center" },
                    [
                      _c("section", { staticClass: "pdf-result-t mb-2" }, [
                        _vm._v("Tus respuestas han dado como resultado:")
                      ]),
                      _vm._v(" "),
                      _c("section", [_vm._v(_vm._s(_vm.data.score))])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }