/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scissors': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.5 12a.498.498 0 01-.5-.5.498.498 0 01.5-.5.498.498 0 01.5.5.498.498 0 01-.5.5z"/><path pid="1" d="M19.313 14.915c-.748-1.183-1.946-1.919-3.126-1.919-.488 0-.955.132-1.351.382-.632.399-1.043 1.058-1.174 1.869l-1.308-2.016a.091.091 0 01-.008-.013L3.919.228A.5.5 0 003.5 0h-.001a.502.502 0 00-.42.23l-.742 1.158c-.393.614-.452 1.572-.136 2.23l4.416 9.197C6.936 13.48 7.763 14 8.501 14l3.159-.003 2.578 3.975.067.11c.748 1.183 1.946 1.919 3.126 1.919.488 0 .955-.132 1.35-.382.69-.436 1.117-1.181 1.203-2.097.081-.867-.157-1.793-.671-2.607zM8.5 13c-.359 0-.827-.295-.982-.618L3.102 3.185c-.163-.34-.127-.94.077-1.257l.323-.505 7.508 11.574L8.499 13zm10.489 4.428c-.056.6-.32 1.078-.742 1.345a1.498 1.498 0 01-.816.227c-.773 0-1.605-.498-2.166-1.283l-.175-.27c-.355-.602-.518-1.264-.46-1.879.056-.6.32-1.078.742-1.345.238-.151.513-.227.816-.227.828 0 1.723.57 2.281 1.453.397.628.582 1.33.521 1.979z"/><path pid="2" d="M13.5 11.917a.5.5 0 01-.45-.716l3.848-8.016c.163-.34.127-.94-.077-1.257l-.323-.505L11.92 8.48a.5.5 0 11-.839-.545l5-7.708a.5.5 0 01.419-.228h.001c.17.001.328.087.42.23l.742 1.158c.393.614.452 1.572.136 2.23l-3.848 8.016a.5.5 0 01-.451.284zM7.042 15.081a.501.501 0 00-.692.147l-.013.02c-.131-.812-.542-1.47-1.174-1.869a2.518 2.518 0 00-1.351-.382c-1.18 0-2.378.735-3.126 1.919-.514.813-.753 1.739-.671 2.607.086.916.513 1.66 1.203 2.097.395.25.862.382 1.35.382 1.18 0 2.378-.735 3.126-1.919l.067-.11 1.427-2.2a.501.501 0 00-.147-.692zM2.569 19c-.303 0-.578-.076-.816-.227-.422-.267-.686-.744-.742-1.345-.061-.649.124-1.351.521-1.979.558-.883 1.453-1.453 2.281-1.453.303 0 .578.076.816.227.422.267.686.744.742 1.345.058.614-.106 1.277-.46 1.878l-.175.27c-.561.785-1.393 1.283-2.166 1.283z"/>'
  }
})
