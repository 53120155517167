var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pb-5", attrs: { id: "change-password" } },
    [
      _c(
        "div",
        {
          staticClass:
            "paper row ml-md-0 mr-md-0 p-sm-4 pt-4 pb-4 justify-content-center"
        },
        [
          _c(
            "div",
            { staticClass: "details col-md" },
            [
              _c(
                "form",
                {
                  staticClass:
                    "d-flex paper justify-content-center flex-wrap p-4 mb-3",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "picture mb-3 text-center d-flex justify-content-center align-items-center mb-sm-3 mt-sm-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "icon-lock",
                        attrs: { name: "lock" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h2", { staticClass: "title mb-5 text-center" }, [
                    _vm._v(_vm._s(_vm.$t("changePassword.title")))
                  ]),
                  _vm._v(" "),
                  _c("v-textField", {
                    staticClass: "w-100 mb-3",
                    attrs: {
                      label: _vm.$t("changePassword.form.cPassword"),
                      maxlength: "50",
                      type: "password",
                      required: true,
                      iconEye: true
                    },
                    model: {
                      value: _vm.form.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "oldPassword", $$v)
                      },
                      expression: "form.oldPassword"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textField", {
                    staticClass: "w-100 mb-4",
                    attrs: {
                      label: _vm.$t("changePassword.form.nPassword"),
                      maxlength: "50",
                      type: "password",
                      required: true,
                      iconEye: true
                    },
                    model: {
                      value: _vm.form.newPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPassword", $$v)
                      },
                      expression: "form.newPassword"
                    }
                  }),
                  _vm._v(" "),
                  !this.$v.form.newPassword.minLength
                    ? _c(
                        "p",
                        { staticClass: "txt-steps text-left mb-1 w-100" },
                        [_vm._v(_vm._s(_vm.$t("resetPassword.step2")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-btn", {
                    staticClass: "w-100 mt-3",
                    attrs: {
                      txt: _vm.$t("changePassword.form.btn"),
                      type: "submit",
                      disabled: _vm.$v.form.$invalid || _vm.sending
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-circles", { attrs: { vCircle: 3, size: "300px" } }),
              _vm._v(" "),
              _c("v-circles", { attrs: { vCircle: 4, size: "200px" } })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }