var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-field",
      class: {
        focus: _vm.isFocus,
        error: _vm.error,
        disabled: _vm.disabled,
        onlyRead: _vm.onlyRead
      },
      style: { "--focus-color": _vm.focusColor }
    },
    [
      _vm.label != ""
        ? _c(
            "label",
            {
              staticClass: "v-placeholder",
              class: { isActive: _vm.activeLabel },
              style: _vm.labelStyles
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "v-content-field position-relative" }, [
        _c(
          "select",
          {
            staticClass: "field_",
            attrs: {
              name: _vm.name,
              required: _vm.required,
              disabled: _vm.disabled,
              readonly: _vm.onlyRead
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                return _vm.handleInput($event.target.value)
              },
              focus: function($event) {
                _vm.focus = true
              },
              blur: function($event) {
                _vm.focus = false
              }
            }
          },
          [
            _vm.defaultOpc != ""
              ? _c("option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.defaultOpc))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.opc, function(item, i) {
              return _c(
                "option",
                {
                  key: i,
                  domProps: {
                    value:
                      _vm.fieldVal == ""
                        ? Object.values(item)[0]
                        : item[_vm.fieldVal],
                    selected: item[_vm.fieldVal] == _vm.value ? "selected" : ""
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.fieldTxt == ""
                        ? Object.values(item)[1]
                        : item[_vm.fieldTxt]
                    )
                  )
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        !_vm.onlyRead
          ? _c(
              "div",
              { staticClass: "content-icon" },
              [
                _c("svgicon", {
                  staticClass: "v-icon",
                  attrs: { name: "chevron-down" }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }