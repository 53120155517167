
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { State } from 'vuex-class';
    
    enum Step{
        menu,
        identificationFile,
        studySkills,
        learningInventory,
        selfEsteem,
        assertiveness
    }
    @Component
    export default class contentFormat extends Vue {
        @Prop({ default: null, required: false }) readonly studentFormats!: any;
        @Prop({ default: null, required: false }) readonly currentStudent!: any;
        @Prop({ type: Boolean, default: false, required: false }) readonly spinner!: boolean;
        @State(state => state.auth.user.name) userName;
        @State(state => state.auth.formats) formats;
        @State(state => state.auth.user.is_tutor) isTutor;

        step: any = Step;

        get emit(): any{
            return (this as any).$emit;
        }

        get statusIdentity(): any{
            const st = this.studentFormats?.forms;

            return { completed: st?st.identity:this.formats.identity,
                     disabled: st?!st.identity:false }
        }

        get statusStudySkills(): any{
            const st = this.studentFormats?.forms;

            return { completed: st?st.studySkills:this.formats.studySkills,
                     disabled: st?!st.studySkills:!this.formats.identity }
        }

        get statusLearningInventory(): any{
            const st = this.studentFormats?.forms;

            return { completed: st?st.learningStyles:this.formats.learningStyles,
                     disabled: st?!st.learningStyles:!this.formats.studySkills }
        }

        get statusSelfEsteem(): any{
            const st = this.studentFormats?.forms;

            return { completed: st?st.selfEsteem:this.formats.selfEsteem,
                     disabled: st?!st.selfEsteem:!this.formats.learningStyles }
        }

        get statusAssertiveness(): any{
            const st = this.studentFormats?.forms;

            return { completed: st?st.assertiveness:this.formats.assertiveness,
                     disabled: st?!st.assertiveness:!this.formats.selfEsteem }
        }

        handleFormat(format: Step): void{
            this.emit('handleFormat', format);
        }

        getStudentFormat(student): void{ // Click item dropdown
        this.emit('handleStudent', student);
		}
    }
