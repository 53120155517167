/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'italic': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.5 2h-8a.5.5 0 000 1h3.39l-2.8 14H4.5a.5.5 0 000 1h8a.5.5 0 000-1H9.11l2.8-14h3.59a.5.5 0 000-1z"/>'
  }
})
