var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-4", attrs: { id: "dashboard" } },
    [
      _c(
        "transition",
        { attrs: { name: "bound", mode: "out-in" } },
        [
          _vm.spinner && _vm.news.length === 0
            ? _c(
                "div",
                {
                  key: "spinner",
                  staticClass: "lesson-spinner w-100 p-4 text-center mb-4"
                },
                [_c("v-spinner")],
                1
              )
            : _c(
                "carousel",
                {
                  key: `news-${_vm.updateNews}`,
                  ref: "slickNews",
                  staticClass: "mb-4 dashboard-slick",
                  attrs: {
                    perPageCustom: [[0, 1]],
                    autoplay: true,
                    speed: 2000,
                    autoplayTimeout: 5000,
                    loop: true
                  }
                },
                _vm._l(_vm.news, function(item) {
                  return _c("slide", { key: item.id }, [
                    _c("a", { attrs: { href: item.link, target: "_blank" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "item d-flex align-items-center",
                          style: {
                            background: `url(${item.picture})center center / cover no-repeat`
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "bgColor",
                            style: {
                              background: `linear-gradient(to right, ${
                                item.color
                              }, ${_vm.$hexToRgba(
                                item.color,
                                0.9
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.7
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.5
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0.3
                              )}, ${_vm.$hexToRgba(
                                item.color,
                                0,
                                1
                              )}, transparent)`
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column pt-4 pl-sm-5 pl-3 w-100 content"
                            },
                            [
                              _c("h3", { staticClass: "mb-0 w-100 title" }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mb-0 w-100 descrip" }, [
                                _vm._v(_vm._s(item.subtitle))
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                }),
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { ref: "lessons", staticClass: "s-lessons mb-4" },
        [
          _c(
            "div",
            {
              staticClass:
                "row ml-n2 mr-n2 align-items-center justify-content-between mr-0"
            },
            [
              _c("div", { staticClass: "col-auto pl-2 pr-2" }, [
                _c(
                  "h4",
                  { staticClass: "mb-0 d-title d-flex align-items-center" },
                  [
                    _c("div", { staticClass: "point bg-lesson-pending mr-1" }),
                    _vm._v(
                      " \n                        " +
                        _vm._s(_vm.$t("dashboard.pending")) +
                        "\n                        "
                    ),
                    _c("v-more-info", {
                      staticClass: "ml-2",
                      attrs: { txtCloud: _vm.$t("moreInfo.infoPendingT") }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col d-none d-sm-block pl-2 pr-4 text-right position-relative"
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn-lessons position-static color-tutor-main pr-0",
                      attrs: { to: { name: "lessons-tutor" } }
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("lessons.btnAll")) + " "),
                      _c("svgicon", {
                        staticClass: "icon fill-green-main",
                        attrs: { name: "chevron-right" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bound", mode: "out-in" } },
            [
              _vm.spinner && _vm.lessonRequests.length === 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : _vm.lessonRequests.length === 0 && !_vm.spinner
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "img pl-3 pr-3",
                        attrs: { original: "", name: "dashboardt2" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pl-3 pr-3 text-center text-sm-left mt-2",
                          style: { maxWidth: "300px" }
                        },
                        [
                          _c("p", {
                            staticClass: "txt mb-2",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msgEmpty.msgPending"))
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "txt2 mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("msgEmpty.will")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "transition-group",
                    {
                      key: "lessons",
                      staticClass: "lessons",
                      attrs: { name: "bound", tag: "div" }
                    },
                    _vm._l(_vm.lessonRequests, function(lesson, i) {
                      return _c("v-lesson", {
                        key: lesson.id,
                        staticClass: "col-12 mt-3",
                        attrs: { w: _vm.cardW, lesson: lesson }
                      })
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "s-lessons mb-4" },
        [
          _c(
            "div",
            {
              staticClass:
                "row ml-n2 mr-n2 align-items-center justify-content-between mr-0"
            },
            [
              _c("div", { staticClass: "col-auto pl-2 pr-2" }, [
                _c(
                  "h4",
                  { staticClass: "mb-0 d-title d-flex align-items-center" },
                  [
                    _c("div", { staticClass: "point bg-lesson-upcoming mr-1" }),
                    _vm._v(
                      " \n                        " +
                        _vm._s(_vm.$t("dashboard.upcoming")) +
                        "\n                        "
                    ),
                    _c("v-more-info", {
                      staticClass: "ml-2",
                      attrs: { txtCloud: _vm.$t("moreInfo.infoUpcomingT") }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col d-none d-sm-block pl-2 pr-4 text-right position-relative"
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn-lessons position-static color-tutor-main pr-0",
                      attrs: { to: { name: "lessons-tutor" } }
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("lessons.btnAll")) + " "),
                      _c("svgicon", {
                        staticClass: "icon fill-green-main",
                        attrs: { name: "chevron-right" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "bound", mode: "out-in" } },
            [
              _vm.spinner && _vm.upcoming.length === 0
                ? _c(
                    "div",
                    {
                      key: "spinner",
                      staticClass: "lesson-spinner w-100 p-4 text-center mt-3"
                    },
                    [_c("v-spinner")],
                    1
                  )
                : _vm.upcoming.length === 0 && !_vm.spinner
                ? _c(
                    "div",
                    {
                      key: "alert",
                      staticClass:
                        "basic-paper empty-lessons d-flex flex-wrap justify-content-center align-items-center pt-5 pb-5 pl-3 pr-3 mt-3"
                    },
                    [
                      _c("svgicon", {
                        staticClass: "img pl-3 pr-3",
                        attrs: { original: "", name: "dashboardt1" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pl-3 pr-3 text-center text-sm-left mt-2",
                          style: { maxWidth: "300px" }
                        },
                        [
                          _c("p", {
                            staticClass: "txt mb-2",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msgEmpty.msgUpcoming"))
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "txt2 mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("msgEmpty.will")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "transition-group",
                    {
                      key: "lessons",
                      staticClass: "lessons",
                      attrs: { name: "bound", tag: "div" }
                    },
                    _vm._l(_vm.upcoming, function(lesson, i) {
                      return _c("v-lesson", {
                        key: lesson.id,
                        staticClass: "col-12 mt-3",
                        attrs: { w: _vm.cardW, lesson: lesson }
                      })
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "s-lessons" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "h4",
              { staticClass: "mb-0 d-title" },
              [
                _vm._v(
                  " \n                        " +
                    _vm._s(_vm.$t("dashboard.statistics")) +
                    "\n                        "
                ),
                _c("v-more-info", {
                  attrs: { txtCloud: _vm.$t("moreInfo.infoStatistics") }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tutorin-static-info mt-3 mb-5" }, [
          _c("div", { staticClass: "row mr-0 ml-0 align-items-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "content-this-week line position-relative d-flex justify-content-start align-items-center col-xl col-sm-6 col-12 mb-3 mb-sm-0",
                class: `${!_vm.menu.compact ? "col-lg-6" : "col-lg"}`
              },
              [
                _c(
                  "div",
                  { staticClass: "this-week" },
                  [
                    _c("svgicon", {
                      staticClass: "icon",
                      attrs: { name: "calendar-full" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt1" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.this")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.week")))
                    ])
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "content-completed line position-relative d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12",
                class: `${
                  !_vm.menu.compact
                    ? "col-lg-6"
                    : "col-lg justify-content-lg-center"
                }`
              },
              [
                _c(
                  "div",
                  { staticClass: "completed" },
                  [
                    _c("svgicon", {
                      staticClass: "icon",
                      attrs: { name: "checkmark-circle" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt1 mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.completed")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.lessons")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "cont ml-1" }, [
                  _vm._v(_vm._s(_vm.statistics.completedLessons || 0))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "content-hours line position-relative d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12",
                class: `${
                  !_vm.menu.compact
                    ? "col-lg-6"
                    : "col-lg justify-content-lg-center"
                }`
              },
              [
                _c(
                  "div",
                  { staticClass: "hours" },
                  [
                    _c("svgicon", {
                      staticClass: "icon",
                      attrs: { name: "timer" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt1 mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.tutoring")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.hours")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "cont ml-1" }, [
                  _vm._v(_vm._s(_vm.statistics.tutoringHours || 0))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "content-canceled d-flex justify-content-xl-center align-items-center col-xl col-sm-6 col-12",
                class: `${
                  !_vm.menu.compact
                    ? "col-lg-6"
                    : "col-lg justify-content-lg-center"
                }`
              },
              [
                _c(
                  "div",
                  { staticClass: "canceled" },
                  [
                    _c("svgicon", {
                      staticClass: "icon",
                      attrs: { name: "cross-circle" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt1 mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.canceled")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "w-100 txt2" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.requested")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "cont ml-1" }, [
                  _vm._v(_vm._s(_vm.statistics.cancelledLessons || 0))
                ])
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }