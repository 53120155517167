/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g _fill="#000" fill-rule="evenodd"><path pid="0" d="M2.024.922h1.22V.447c0-.246.209-.447.463-.447h.403c.254 0 .462.201.462.447v.475h5.856V.447c0-.246.208-.447.462-.447h.403c.254 0 .462.201.462.447v.475h1.22C14.09.922 15 1.804 15 2.882V13.04C15 14.118 14.089 15 12.975 15H2.025C.91 15 0 14.118 0 13.04V2.881C0 1.804.91.922 2.024.922zm9.73 1.166v.705a.456.456 0 01-.46.447h-.404a.456.456 0 01-.462-.447v-.705a1.05 1.05 0 00-.433.845c0 .586.491 1.062 1.096 1.062.606 0 1.096-.476 1.096-1.062 0-.345-.17-.651-.432-.845zm-7.182 0v.705a.456.456 0 01-.462.447h-.403a.456.456 0 01-.462-.447v-.705a1.05 1.05 0 00-.433.845c0 .586.491 1.062 1.097 1.062.605 0 1.096-.476 1.096-1.062 0-.345-.17-.651-.433-.845zm9.632 2.409v8.542c0 .655-.553 1.19-1.229 1.19H2.025c-.676 0-1.23-.535-1.23-1.19V4.497h13.409z"/><path pid="1" d="M10.787 10.932h1.917v1.857h-1.917zM10.787 8.434h1.917v1.857h-1.917zM7.956 10.932h1.918v1.857H7.956zM7.956 8.434h1.918v1.857H7.956zM5.126 10.932h1.917v1.857H5.126zM5.126 8.434h1.917v1.857H5.126zM2.296 10.932h1.917v1.857H2.296zM2.296 8.434h1.917v1.857H2.296zM10.787 5.937h1.917v1.857h-1.917zM7.956 5.937h1.918v1.857H7.956zM5.126 5.937h1.917v1.857H5.126zM2.296 5.937h1.917v1.857H2.296z"/></g>'
  }
})
