var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "contentTxt",
      staticClass: "v-hover position-relative",
      on: { mouseover: _vm.hover, mouseleave: _vm.removeHover }
    },
    [
      _c("transition", { attrs: { name: "bound" } }, [
        _vm.statusHover
          ? _c(
              "div",
              {
                key: "cloud",
                staticClass: "txt-cloud",
                style: {
                  [_vm.positionCloud]: _vm.positionCloudVal,
                  width: _vm.widthCloud,
                  minWidth: _vm.minWidthCloud
                }
              },
              [_c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.txt))])]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "span",
        { ref: "txt", staticClass: "txt-hover", style: _vm.ellipsisStyles },
        [_vm._v(_vm._s(_vm.txt))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }