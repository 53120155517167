/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'news': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.5 14h-6a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v4a.5.5 0 01-.5.5zM9 13h5v-3H9v3zM6.5 10h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6.5 12h-3a.5.5 0 010-1h3a.5.5 0 010 1zM6.5 14h-3a.5.5 0 010-1h3a.5.5 0 010 1z"/><path pid="1" d="M19.5 6a.5.5 0 00-.5.5v9a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v9a.5.5 0 001 0v-9c0-.827-.673-1.5-1.5-1.5h-15C.673 4 0 4.673 0 5.5v10c0 .827.673 1.5 1.5 1.5h17c.827 0 1.5-.673 1.5-1.5v-9a.5.5 0 00-.5-.5z"/><path pid="2" d="M14.5 8h-11a.5.5 0 010-1h11a.5.5 0 010 1z"/>'
  }
})
