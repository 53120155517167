/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#DE3030" cx="8" cy="8" r="8"/><path pid="1" _fill="#FFF" d="M3 7h10v2H3z"/></g>'
  }
})
