var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      style: { border: "1px solid #E8E8E8", borderRadius: "20px" },
      attrs: { id: "search-autocomplete" }
    },
    [
      _c(
        "div",
        {
          staticClass: "position-relative search",
          on: {
            click: function($event) {
              return _vm.toggleAction()
            }
          }
        },
        [
          _c("svgicon", {
            staticClass: "icon-search",
            attrs: { name: "magnifier" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            ref: "input",
            attrs: {
              type: "text",
              placeholder:
                "Buscar por nombre, no. control o correo institucional"
            },
            domProps: { value: _vm.val },
            on: {
              keyup: function($event) {
                return _vm.keyFuction($event.key)
              },
              focus: function($event) {
                _vm.statusDropdown =
                  _vm.results.length > 0
                    ? _vm.stepsDropdown.focus
                    : _vm.stepsDropdown.blur
              },
              blur: function($event) {
                return _vm.blurAction()
              },
              input: function($event) {
                if ($event.target.composing) return
                _vm.val = $event.target.value
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.statusDropdown == _vm.stepsDropdown.focus ||
      _vm.statusDropdown == _vm.stepsDropdown.hover
        ? _c(
            "perfect-scrollbar",
            {
              ref: "scroll",
              staticClass: "dropdown",
              attrs: { tag: "div" },
              on: {
                mouseover: function($event) {
                  return _vm.hoverAction()
                },
                mouseleave: function($event) {
                  return _vm.leaveAction()
                }
              }
            },
            [
              _c(
                "transition",
                { attrs: { name: "bound", mode: "out-in" } },
                [
                  _vm.statusResults === _vm.stepsResults.spinner
                    ? _c(
                        "div",
                        {
                          key: "spinner",
                          staticClass: "w-100 p-3 text-center"
                        },
                        [
                          _c("v-spinner", {
                            attrs: { height: "40px", width: "40px" }
                          })
                        ],
                        1
                      )
                    : _vm.statusResults === _vm.stepsResults.message
                    ? _c(
                        "div",
                        {
                          key: "spinner",
                          staticClass: "w-100 p-3 text-center"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "msg-search mt-2 mb-2 ml-auto mr-auto text-center",
                              style: { maxWidth: "550px" }
                            },
                            [
                              _vm._v(
                                "No se ha encontrado ningún estudiante con los parámetros ingresados. Probablemente el estudiante no se encuentre registrado en la plataforma de Linc."
                              )
                            ]
                          )
                        ]
                      )
                    : _vm.statusResults === _vm.stepsResults.results
                    ? _c(
                        "transition-group",
                        {
                          key: "results",
                          staticClass: "pl-0 mb-0",
                          attrs: { name: "bound", tag: "ul" }
                        },
                        _vm._l(_vm.results, function(item) {
                          return _c(
                            "li",
                            {
                              key: `student-'${item.id}`,
                              ref: "dropdownItem",
                              refInFor: true,
                              staticClass: "dropdown-item",
                              class: {
                                hover: _vm.resultHover == `di-${item.id}`
                              },
                              attrs: { id: `di-${item.id}` },
                              on: {
                                click: function($event) {
                                  return _vm.getStudentFormat(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex ml-0 mr-0 align-items-center"
                                },
                                [
                                  _c("div", {
                                    staticClass: "content-img",
                                    style: {
                                      background:
                                        "url(" +
                                        item.photo +
                                        ")center center / cover no-repeat"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-sm d-flex flex-wrap align-items-center subject-tutor"
                                    },
                                    [
                                      _c("p", {
                                        staticClass: "mb-0 w-100 name",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.textBold(item))
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }