<template>
	<div class="d-flex flex-wrap align-items-center pt-sm-3 pb-sm-3 pt-2 pb-2" :style="{ maxWidth: max+'px', minWidth: min+'px', paddingLeft: '10px', paddingRight: '10px' }">
		<div class="text text-left position-relative">
			<svgicon class="icon" :name="icon"></svgicon>
			<v-hover-txt class="d-block" fontSize="1rem" :txt="txt" colorTxt="#000" v-if="hover"/>
			<span class="txt1 d-block position-relative" v-else>{{ txt }}</span>
			<span class="txt2 d-block position-relative" v-if="txt2">{{ txt2 }}</span>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			txt: { type: String, default: {}, required: true },
			txt2:  { type: String, default: null, required: false },
			icon: { type: String, default: '', required: true },
			max: { required: false },
			min: { required: false },
			hover: { type: Boolean, default: false, required: false }
		}
	}
</script>