const setCalendarHistory = (state, history) => {
    state.calendar = history;
}

const setAssignedSubjectsHistory = (state, history) => {
    state.assignedSubjects = history;
}

const setCutomizeSubjectHistory = (state, history) => {
    state.cutomizeSubject = history;
}

export default{
	setCalendarHistory,
	setAssignedSubjectsHistory,
	setCutomizeSubjectHistory
}