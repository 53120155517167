<template>
	<header id="header-main" ref="header" :class="{ 'compact' : menu.compact, 'show' : menu.showMenuResponsive , 'bgColor-tutor-main' : auth.permission==='tutor', 'bgColor-student-main' : auth.permission==='student' }">
		<div class="container-fluid h-100">
			<div class="row h-100">
				<!-- ============ Institution logo ============= -->
				<div class="col h-100 align-items-center logo d-none d-lg-flex" :class="auth.permission==='tutor'?'bgColor-tutor-dark':(!statusFormats?'bgColor-student-dark':null)">
					<router-link :src="auth.institution.dashboard_logo" :alt="auth.institution.name" tag="img" :to="{ name: `dashboard-${auth.permission}`}" />
				</div>
				<!-- ============ Btn to web a movil menu ============= -->
				<div class="col h-100 btn-menu">
					<div class="row h-100 align-items-center" v-if="!statusFormats">
						<div class="content-btn-menu col h-100" :class="menu.compact?`bgColor-${auth.permission}-dark`:''">
							<button class="w-100 h-100 btn-menu-conpact d-none d-lg-block" v-on:click="setCompact(!menu.compact)">
								<svgicon class="icon" name="menu"></svgicon>
							</button>
							<button class="w-100 h-100 btn-menu-conpact d-block d-lg-none" v-on:click="setStatusMenuResponsive(!menu.showMenuResponsive)">
								<svgicon class="icon" name="menu"></svgicon>
							</button>
						</div>
						<div class="col d-none d-md-block">
							<span class="text-dashboard">{{ title }}</span>
						</div>
					</div>
				</div>
				<nav class="menu navbar navbar-expand justify-content-end align-items-center h-100 col col-md-auto pl-0 pr-0 pr-md-3 pl-md-3">
					<ul class="navbar-nav h-100 align-items-center">
						<!-- ============ Chat ============== -->
						<li class="pl-2 pr-2" v-if="!statusFormats">
							<v-dropdown class="btn-count" :class="{ disabled: $route.name == `chat-${auth.permission}` || $route.name == `chat-${auth.permission}-id` }" :style="{ '--fill-hover': auth.permission == 'tutor'?'#005E58':'#2188FC' }" :statusSlot="false" tag="div" :txtHover="$t('title.chat')" @click.native="$route.name != `chat-${auth.permission}` && $route.name != `chat-${auth.permission}-id`?$router.push({ name: `chat-${auth.permission}` }):() => {}">
								<template v-slot:content>
									<svgicon  class="icon-count" name="chat2" />
							        <div class="counter-btn" v-if="counterChats > 0"><span>{{ counterChats }}</span></div>
								</template>
							</v-dropdown>
						</li>
						<!-- ============ Notifications ============== -->
						<li class="pl-2 pr-2" v-if="!statusFormats">
							<v-dropdown class="btn-count" :class="{ disabled: $route.name == `notifications-${auth.permission}`, active: statusNof }" :style="{ '--fill-hover': auth.permission == 'tutor'?'#005E58':'#2188FC' }" :statusArrow="true" :statusSlot="$route.name != `notifications-${auth.permission}`" tag="div" :txtHover="$t('title.notifications')" @handleDropdown="e => statusNof = e" @click.native="grid == 'xs'?$router.push({ name: `notifications-${auth.permission}` }):() => {}">
								<template v-slot:content>
									<svgicon  class="icon-count" name="bell-fill" />
									<div class="counter-btn" v-if="counterNotifications > 0"><span>{{ counterNotifications }}</span></div>
								</template>
								<template v-slot:dropdown>
									<v-notification-list maxHeight="356px" :statusSeeAll="true" v-if="grid != 'xs'"/>
								</template>
							</v-dropdown>
						</li>
						<!-- ============ Dropdown Help ============= -->
						<v-dropdown class="pl-md-3 pr-md-3 pl-2 pr-2 btn-help" tag="li" :submenu="help">
							<template v-slot:content>
								{{ $t('room.toolbar.help.t') }}
								<svgicon class="icon" name="chevron-down"></svgicon>
							</template>
						</v-dropdown>
						<!-- ============ Dropdown Language ============= -->
						<v-dropdown class="pl-md-3 pr-md-3 pl-2 pr-2 h-100 d-flex align-items-center language" :submenu="languages" val="long" v-model="lang">
                	        <template v-slot:content>
                                {{ lang.long }} <svgicon class="icon" name="chevron-down"></svgicon>
                            </template>
                        </v-dropdown>
                        <!-- ============ Dropdown User menu ============= -->
						<v-dropdown class="pl-md-3 pr-md-3 pl-2 pr-2 h-100 d-flex align-items-center profile" :submenu="profile">
                	        <template v-slot:content>
                                <div class="picture" v-bind:style="{ background : 'url('+auth.user.photo+')center top / cover no-repeat' }"></div>
							    <span class="name">{{ auth.user.name }}</span>
							    <svgicon class="icon" name="ellipsis-v"></svgicon>
                            </template>
                        </v-dropdown>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>
<script>
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

	export default{
		data(){
            return{
                lang : '',
				statusNof: false
            }
		},
		computed:{
            ...mapState(['auth','title','menu','i18n','languages','statusHour', 'counters', 'userFirebase', 'grid']),
			...mapGetters(['statusFormats']),
            profile(){ 
            	if (this.auth.user.is_tutor)
            		if (this.auth.permission==='student') //Submenu profile student (tutor)
            		return [ {
				    	         txt: this.$t('header.menu.account'),
				    	         action: () => { if(this.$route.name != 'info-student') this.$router.push({ name : 'info-student' }) },
				    	         icon: 'cog2',
				             },
				             {
				    	         txt: this.$t('header.menu.tutorMode'),
				    	         icon: 'tab',
				    	         action: () => this.changeMode('tutor')
				             },
				             {
				    	         txt: this.$t('header.menu.logOut'),
				    	         icon: 'exit-left',
				    	         action: () => this.logOut()
				             } ]
				    else
				    return [ {  //Submenu profile tutor (tutor)
				    	         txt: this.$t('header.menu.profile'),
				    	         action: () => { if(this.$route.name != 'profile-tutor') this.$router.push({ name : 'profile-tutor' }) },
				    	         icon: 'user',
				             },
				             {
				    	         txt: this.$t('header.menu.account'),
				    	         action: () => { if(this.$route.name != 'info-tutor') this.$router.push({ name : 'info-tutor' }) },
				    	         icon: 'cog2',
				             },
				             {
				    	         txt: this.$t('header.menu.studentMode'),
				    	         icon: 'tab',
				    	         action: () => this.changeMode('student')
				             },
				             {
				    	         txt: this.$t('header.menu.logOut'),
				    	         icon: 'exit-left',
				    	         action: () => this.logOut()
				             } ]         
				else
					return [ { //Submenu profile student (student)
				    	         txt: this.$t('header.menu.account'),
								 disabled: this.statusFormats,
				    	         action: () => { if(this.$route.name != 'info-student') this.$router.push({ name : 'info-student' }) },
				    	         icon: 'cog2',
				             },
				             {
				    	         txt: this.$t('header.menu.logOut'),
				    	         icon: 'exit-left',
				    	         action: () => this.logOut()
				             } ]
            },
            help(){
            	return [ //Submenu btn help
            	{
            		txt: this.$t('header.helpSub.docs.t'),
            		sub: this.$t('header.helpSub.docs.descrip'),
            		icon: 'document',
            		action: () =>
            		window.open(
            			this.$t('header.helpSub.docs.link'),
            			"_blank"
            			)
            	},
            	{
            		txt: this.$t('header.helpSub.online.t'),
            		sub: this.$t('header.helpSub.online.descrip'),
            		icon: 'whatsapp',
            		action: () =>
            		window.open(
            			this.$t('header.helpSub.online.link'),
            			"_blank"
            			)
            	},
            	{
            		txt: this.$t('header.helpSub.email.t'),
            		sub: this.$t('header.helpSub.email.descrip'),
            		icon: 'envelope',
            		action: () =>
            		window.open(
            			this.$t('header.helpSub.email.link'),
            			"_blank"
            			)
            	}
            	]
            },
            counterChats(){
            	if(!this.counters)
            		return 0;
            	else if(!this.counters.chats)
            		return 0;
            	else{
            		const cont = this.counters.chats[`${this.auth.permission}_counter`];
            		return cont > 99?99:cont;
            	}
            },
            counterNotifications(){
            	if(!this.counters)
            		return 0;
            	else if(!this.counters.notifications)
            		return 0;
            	else{
            		const cont = this.counters.notifications[`${this.auth.permission}_counter`];
            		return cont > 99?99:cont;
            	}
            }
		},
		created(){
            this.lang = this.languages.find( lg => lg.short === this.i18n.locale );
            window.addEventListener("resize", this.windowSize);
		},
		mounted(){
            this.handleSize({ headerH: this.$refs.header?this.$refs.header.offsetHeight:null });
		},
		destroyed(){
            window.removeEventListener("resize", this.windowSize);
		},
		watch:{
			lang: function(newVal){
				if (newVal.short!==this.i18n.locale)
					this.setLang(newVal.short);
			}
		},
		methods: {
			...mapMutations(['setCompact','setStatusMenuResponsive','setLang','setstatusHour', 'handleSize']),
			...mapActions(['changePermission','signOutFirebase','stopEvents', 'logOut']),
			changeMode(permission){
				this.changePermission(permission);
                this.$router.push({ name: 'dashboard-'+this.auth.permission })
			},
			windowSize(){ // Event resize to change status if full hour
                const w = document.documentElement.clientWidth;
                if (this.menu.compact && w >= 992 && !this.statusHour)
                	this.setstatusHour(true);
                else if(w < 992 && this.statusHour)
                	this.setstatusHour(false);
			}
		}
	}
</script>
