/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'txt': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<g _fill="#000" fill-rule="evenodd"><path pid="0" d="M14.5 19h-13C.673 19 0 18.327 0 17.5v-16C0 .673.673 0 1.5 0h13c.827 0 1.5.673 1.5 1.5v16c0 .827-.673 1.5-1.5 1.5zM1.5 1a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-13z" fill-rule="nonzero"/><path pid="1" d="M12.375 4h-8.75C3.28 4 3 3.776 3 3.5s.28-.5.625-.5h8.75c.345 0 .625.224.625.5s-.28.5-.625.5zM12.5 6h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 8h-9a.5.5 0 010-1h9a.5.5 0 010 1zM12.5 10h-9a.5.5 0 010-1h9a.5.5 0 010 1z" fill-rule="nonzero"/><rect pid="2" x="3.05" y="11" width="6" height="1" rx=".5"/></g>'
  }
})
