<template>
	<div id="error" class="error-403 d-flex flex-column align-items-center justify-content-center">
		<!-- ============ Msg 403 =========== -->
		<h2 class="txt-error mb-0" :class="{ 'color-tutor-main' : auth.permission==='tutor', 'color-student-main' : auth.permission!=='tutor' }">{{ $t('403.error') }}</h2>
		<p class="descrip-error mb-0">{{ $t('403.msg') }}</p>
		<!-- ============ Circles =========== -->
		<div class="circles-error mb-4 mb-5 w-100 d-flex justify-content-center">
			<div class="circle-transform">
            <div class="content-circle">
			    <div class="circle" :class="{ 'bgColor-tutor-main' : auth.permission==='tutor', 'bgColor-student-main' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle">
			    <div class="circle" :class="{ 'bgColor-tutor-light' : auth.permission==='tutor', 'bgColor-student-light' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle" >
			    <div class="circle" :class="{ 'bgColor-tutor-light' : auth.permission==='tutor', 'bgColor-student-light' : auth.permission!=='tutor' }"></div>
		    </div>
		    <div class="content-circle" >
			    <div class="circle" :class="{ 'bgColor-tutor-main' : auth.permission==='tutor', 'bgColor-student-main' : auth.permission!=='tutor' }"></div>
		    </div>
		    </div>
		</div>
		<!-- ============ btn Dashboard =========== -->
		<v-btn :txt="$t('403.btn')" min="180px" height="32px" @click.native="$router.push({ name : 'dashboard-'+auth.permission })" />
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default{
		computed:{
			...mapState(['auth'])
		}
	}
</script>