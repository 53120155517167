var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row w-100" }, [
    _c(
      "div",
      {
        staticClass:
          "col-12 d-flex flex-wrap align-items-center justify-content-between pl-0 pr-0 mb-3"
      },
      [
        _c(
          "div",
          { staticClass: "btn-prev-step order-0 col-auto col-md" },
          [
            _vm.form.lesson.type != "Grupal"
              ? _c("v-prev-link", {
                  staticClass: "col-12 pt-3 pb-3 pl-0 pr-0",
                  attrs: {
                    txt: _vm.$t("scheduleLesson.place.t"),
                    type: "step",
                    click: () => _vm.prevAction()
                  }
                })
              : _c("v-prev-link", {
                  staticClass: "col-12 pt-3 pb-3 pl-0 pr-0",
                  attrs: {
                    txt: _vm.$t("scheduleLesson.place.prev"),
                    type: "step",
                    click: () => _vm.prevAction()
                  }
                })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "btn-close-step order-1 order-md-2 col-auto col-md text-right"
          },
          [
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    return _vm.closeAction()
                  }
                }
              },
              [_c("svgicon", { attrs: { name: "cross2" } })],
              1
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "v-lesson-summary w-100 d-flex flex-wrap justify-content-end"
      },
      [
        _c(
          "div",
          { staticClass: "pl-3 pr-3 w-100" },
          [
            _c(
              "div",
              { staticClass: "v-lesson-summary-info p-3 p-sm-4 w-100 mb-3" },
              [
                _c(
                  "div",
                  {
                    staticClass: "pl-sm-3 pr-sm-3",
                    style: { maxWidth: "900px" }
                  },
                  [
                    _c("h3", { staticClass: "title-step mb-4 pb-sm-2" }, [
                      _vm._v(_vm._s(_vm.$t("scheduleLesson.summary.t")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row ml-n2 mr-n2" }, [
                      _vm.tutor.last_name
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12 col-sm-auto pl-2 pr-2 mb-3"
                            },
                            [
                              _c("v-lesson-photo", {
                                staticClass: "ml-auto mr-auto",
                                attrs: {
                                  options: {
                                    photo: _vm.tutor.photo,
                                    size: "80px"
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col pl-2 pr-2" },
                        [
                          _c("div", { staticClass: "row mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-xl-4 col-lg-5 mb-2 mb-lg-0" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "v-lesson-tutor col-12 mb-1"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.tutor.name) +
                                          " " +
                                          _vm._s(_vm.tutor.last_name)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row ml-n2 mr-n2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-auto pl-2 pr-2 pb-1 pt-1"
                                          },
                                          [
                                            _c("v-lesson-tag", {
                                              attrs: {
                                                type: _vm.form.lesson.type,
                                                options: {
                                                  minWidth: "80px",
                                                  lessonLimit:
                                                    _vm.form.date.time.places,
                                                  lessonQuota:
                                                    _vm.form.date.time.occupied
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-auto pl-2 pr-2 pb-1 pt-1"
                                          },
                                          [
                                            _c("v-lesson-tag", {
                                              attrs: {
                                                type: _vm.form.lesson.modality,
                                                options: { minWidth: "80px" }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-xl-8 col-lg-7" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "v-lesson-subject mb-1 position-relative"
                                    },
                                    [
                                      _c("svgicon", {
                                        staticClass: "v-ls-icon mr-2",
                                        attrs: {
                                          name: _vm.form.lesson.icon,
                                          color: "#000"
                                        }
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t        " +
                                          _vm._s(_vm.form.lesson.name) +
                                          "\n\t\t\t\t\t\t\t\t\t\t        "
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.form.lesson.type == "Individual"
                                  ? _c(
                                      "div",
                                      { staticClass: "col-12 col-md" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row ml-n2 mr-n2 align-items-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-auto pl-2 pr-2 pt-1 pb-1"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "v-topic-tag pt-1 pr-3 pb-1 pl-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("topic.tag")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-auto d-flex align-items-center pl-2 pr-2"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "v-topic-opc"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("topic.opc")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col pl-2 pr-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.topic,
                                                      expression: "topic"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "v-topic-field w-100",
                                                  attrs: {
                                                    maxlength: "50",
                                                    placeholder: _vm.$t(
                                                      "topic.placeholder"
                                                    ),
                                                    type: "text"
                                                  },
                                                  domProps: {
                                                    value: _vm.topic
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.topic =
                                                        $event.target.value
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "v-lesson-date mb-3 position-relative"
                            },
                            [
                              _c("svgicon", {
                                staticClass: "v-ls-icon mr-2",
                                attrs: { name: "calendar", color: "#000" }
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t        " +
                                  _vm._s(`${_vm.setDate} ${_vm.setTime}`) +
                                  "\n\t\t\t\t\t\t        "
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-lesson-place", {
                            attrs: {
                              options: {
                                icon: _vm.form.place.name
                                  ? _vm.form.place.icon.icon_name
                                  : null,
                                place: _vm.form.place.name
                                  ? _vm.form.place.name
                                  : null,
                                modality: _vm.form.lesson.modality,
                                type: _vm.form.lesson.type
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("v-btn", {
              staticClass: "float-right",
              attrs: {
                txt: _vm.$t("scheduleLesson.summary.btn"),
                fontSize: "20px",
                min: "250px",
                disabled: _vm.sending
              },
              nativeOn: {
                click: function($event) {
                  return _vm.send()
                }
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }