<template>
	<div class="v-radio-button">
		<div class="row ml-0 mr-0 mb-1">
			<div class="col pl-0 pr-0">
				<span class="title-radio">{{ title }}</span>
			</div>
			<div class="pl-0 pr-0 d-flex justify-content-end">
				<div class="check-any" @click="handleInput('all')">
				    <div class="check" :class="{ active : this.dvalue.length === this.items.length }"></div>
				    <label class="text-any mb-0" :for="'title'">{{ items.length === 2 ? $t('searchResult.filters.any') : $t('searchResult.filters.all') }}</label>
			    </div>
			</div>
		</div>
		<div class="row radios-button">
			<div class="col radio-button" v-for="(radio, index) in items" :key="index">
				<button class="radio" :class="{ active : dvalue.find( item => item === vals[index] ), [bgColor[index]] : true }" @click="handleInput([vals[index]])" type="button">{{ radio }}</button>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			title: { type: String, default:'', required: true },
			items: { type: Array, required: true },
			vals: { type: Array, default: () => [], required: false },
			bgColor: { type: Array, required: false },
			value: { type: Array }
		},
		data(){
			return{
				dvalue: [],
				lastVal: []
			}
		},
		methods:{
			handleInput(val){
				if (val !== 'all') { //  IF user do click in btns
					if (this.dvalue.length === this.items.length){ // IF all btns are selecting
						this.dvalue = val;
                    }else{
                    	const isActive = this.dvalue.find( item => item === val[0] );
                    	if(isActive && this.dvalue.length > 1) // Disable btn if it's active and if there's other btn active
                    		this.dvalue = this.dvalue.filter( item => item !== val[0] )
                    	else if(!isActive) // Active btn if it's inactive
                    		this.dvalue.push(val[0]);
                    }
                    this.$emit('input', this.dvalue);
                    this.lastVal = this.dvalue;
                } else{ // IF user do click in any or all
                	if (this.dvalue.length === this.items.length) // IF all btns are selecting
                		this.dvalue = this.lastVal.filter( (last, i) => { // Return last btns selected
                            if (i+1 !== this.items.length)
                            	return last;
                		});
                	else
                		this.dvalue = this.vals;
                	this.$emit('input', this.dvalue);
                }
			}
		},
		created(){		
			if (this.value.length == 0){
				this.lastVal = [ this.vals[0] ];
				this.dvalue = this.vals;
                this.$emit('input', this.dvalue);
            }else{
            	this.lastVal = [ this.value[0] ];
            	this.dvalue = this.value;
            }
		}
	}
</script>