var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-emojis" },
    [
      _c(
        "div",
        {
          staticClass: "btn-emoji",
          on: {
            click: function($event) {
              !_vm.isMovile ? _vm.toggleEmojis() : _vm.$emit("showEmojis")
            }
          }
        },
        [
          _vm.btnEmoji == "focus" || _vm.btnEmoji == "hover"
            ? _c("div", { staticClass: "btn-emoji-hover" })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "btn-emojis",
            staticClass: "input-hide",
            attrs: { type: "text", readonly: "" },
            on: {
              focus: function($event) {
                _vm.btnEmoji = "focus"
              },
              blur: _vm.blurEmojis
            }
          }),
          _vm._v(" "),
          _c("svgicon", {
            staticClass: "emoji-icon",
            attrs: { name: "smile-keyboard", original: "" }
          }),
          _vm._v(" "),
          _vm.btnEmoji == "blur"
            ? _c("div", { staticClass: "hover-txt" }, [
                _vm._v(_vm._s(_vm.$t("chat.emoji")))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      (_vm.btnEmoji == "focus" || _vm.btnEmoji == "hover") && !_vm.disabled
        ? _c("VEmojiPicker", {
            attrs: {
              emojisByRow: 7,
              emojiSize: 24,
              i18n: _vm.i18n,
              exceptEmojis: _vm.exceptEmojis,
              emojiWithBorder: false
            },
            on: { select: e => _vm.$emit("insertEmoji", e) },
            nativeOn: {
              mouseover: function($event) {
                return _vm.hoverEmojis()
              },
              mouseleave: function($event) {
                return _vm.leaveEmojis()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }