var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-item-main-format pl-3 pl-md-4 pr-3 pr-md-4 mb-3",
      class: { disabled: _vm.disabled },
      style: { "--hover-color": _vm.userColor.main }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mf-btn-content row align-items-center pt-3 pb-3 ml-n2 mr-n2"
        },
        [
          _c("div", { staticClass: "col pl-2 pr-2" }, [
            _c("p", { staticClass: "mf-btn-txt mb-0" }, [
              _vm._v(_vm._s(_vm.txt))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-sm-auto pl-2 pr-2 mt-2 mt-sm-0 d-flex justify-content-end"
            },
            [
              !_vm.completed || _vm.userType == "tutor"
                ? _c("v-btn", {
                    attrs: {
                      txt:
                        _vm.userType == "tutor"
                          ? _vm.$t("formats.consult")
                          : _vm.$t("formats.btnToCoplete"),
                      fontSize: "1rem",
                      fontWeight: "500",
                      min: "140px",
                      disabled: _vm.disabled
                    }
                  })
                : _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("span", { staticClass: "mf-completed mr-2 mr-sm-3" }, [
                        _vm._v(_vm._s(_vm.$t("formats.completed")))
                      ]),
                      _vm._v(" "),
                      _c("svgicon", {
                        staticClass: "mf-completed-icon",
                        attrs: { name: "checkmark-circle-fill", original: "" }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }