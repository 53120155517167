/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevrons-expand-horizontal': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12.5 15.5c0-.128.049-.256.146-.354L18.292 9.5l-5.646-5.646a.5.5 0 01.707-.707l6 6a.5.5 0 010 .707l-6 6a.5.5 0 01-.853-.354zM.5 9.5c0-.128.049-.256.146-.354l6-6a.5.5 0 01.707.707L1.707 9.499l5.646 5.646a.5.5 0 01-.707.707l-6-6A.498.498 0 01.5 9.498z"/>'
  }
})
