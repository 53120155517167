/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enter-up2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 1h-16C.673 1 0 1.673 0 2.5v1C0 4.327.673 5 1.5 5h5a.5.5 0 000-1h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 000 1h5c.827 0 1.5-.673 1.5-1.5v-1c0-.827-.673-1.5-1.5-1.5z"/><path pid="1" d="M14.853 9.353a.5.5 0 01-.707 0L10 5.207V19.5a.5.5 0 01-1 0V5.207L4.854 9.353a.5.5 0 01-.707-.707l5-5a.498.498 0 01.708.001l5 5a.5.5 0 010 .707z"/>'
  }
})
