
	import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
	import { State, Getter, Mutation, Action } from 'vuex-class';
    import { DemoTileOrganizer } from "@/classes/DemoTileOrganizer";
    import {
        AudioVideoObserver,
        AudioInputDevice,
        ContentShareObserver,
        ClientMetricReport,
        DefaultActiveSpeakerPolicy,
        DefaultModality,
        DefaultVideoTile,
        VideoDownlinkObserver,
        MeetingSessionStatus,
        MeetingSessionStatusCode,
        RemovableAnalyserNode,
        TargetDisplaySize,
        VideoTileState,
        ClientVideoStreamReceivingReport,
        VoiceFocusTransformDevice,
        isAudioTransformDevice,
        VideoSource,
        VideoPreference,
        VideoPreferences
    } from "amazon-chime-sdk-js";

    enum ContentShareType {
        Nothing,
        ScreenCapture,
        VideoFile,
    };

    enum Step {
        spinner,
        promptPermissions,
        deniedPermissions,
        startRoom
    }

    @Component
    export default class Room extends Vue implements AudioVideoObserver, ContentShareObserver, VideoDownlinkObserver{
        @Prop({ type: Object }) readonly options!: any;
    	/*-- ========== Datas ========= */
        /* General Datas */
        deviceAlerts: any;
        config: any;
        roomStep: Step = Step.spinner;
        statusChat: boolean = false;
        statusFullScreen: boolean = false;
        totalUsers: number = 0;
        mouseDelay: any = '';
        hideElement: boolean = false;
        showCurrentVideo: boolean = true;
        pin: string | null = null;
        statusModalSettings: boolean = false;
        resetVideo: number = 0;
        statusEnterSound: boolean = false;
        /* Local user */
        localRoster: object | undefined = undefined;
        loadingRemoteUsers: boolean = false;
        startLocalVideo: boolean = true;
        volume: number = 0;
        volumeAlert: boolean = false;
        showVolumeAlert: boolean = true;
        volumeAlertTime: any;
        statusTestSound: boolean = false;
        /* Chime Datas */
        behaviorAfterLeave: 'spa' | 'left' | 'reload' = 'left';
        meetingEnded: boolean = false;
        attendeeIdPresenceHandler: (undefined | ((attendeeId: string, present: boolean, externalUserId: string, dropped: boolean) => void)) = undefined;
        roster: object = {};
        filterRosters: Array<object> = [];
        analyserNode: RemovableAnalyserNode | undefined;
        remoteVideos: any = {};
        contVS: number = 0;
        currentRosterScreenShare: any = null; // Current screen share for sessions 1 on 1
        showActiveSpeakerScores: boolean = false;
        tileIndexToTileId: { [id: number]: number } = {};
        tileIdToTileIndex: { [id: number]: number } = {};
        activeSpeakerLayout = true;
        activeUserSpeaking: string | null = null;
        tileOrganizer: DemoTileOrganizer = new DemoTileOrganizer();
        contentShareType: ContentShareType = ContentShareType.Nothing;
        /* Dev mode */
        metrics: object = {};
        upLinkBandWidth: number = 0;
        downlinkBandWidth: number = 0;
        private analyserNodeCallback: undefined | (() => void);

        /*-- ========== Enums ========= */
        private ContentShareType = ContentShareType;
        private Step = Step;

    	/* ========= Vuex ========= */
        /* States */
        @State( state => state.auth ) auth;
        @State( 'currentLesson' ) currentLesson
        @State( state => state.permissions.mic ) permissionMic
        @State( state => state.permissions.cam ) permissionCam
        @State('askingForPermissions') askingForPermissions
        @State( state => state.typePermission ) typePermission // Enum
        @State('meetingSession') meetingSession
        @State('devices') devices
        @State( state => state.currentMic?state.currentMic.deviceId:null ) currentMic
        @State( state => state.currentCam?state.currentCam.deviceId:null ) currentCam
        @State( 'currentSpk' ) currentSpk
        @State( 'currentVideoQuality' ) currentVideoQuality
        @State('usePriorityBasedDownlinkPolicy') usePriorityBasedDownlinkPolicy
        @State('defaultBrowserBehaviour') defaultBrowserBehaviour // Supports SetSinkId
        @State('enableVoiceFocus') enableVoiceFocus
        @State('currentAudioInputDevice') currentAudioInputDevice
        @State('voiceFocusDevice') voiceFocusDevice
        @State('priorityBasedDownlinkPolicy') priorityBasedDownlinkPolicy
        @State('grid') grid;
         /* Getters */
        @Getter('audioVideo') audioVideo
        @Getter('isVoiceFocusEnabled') isVoiceFocusEnabled;
        @Getter('modalVal') modalVal
        /* Mutation */
        @Mutation('handlePermissions') handlePermissions
        @Mutation('setModal') setModal
        @Mutation('handleDevice') handleDevice
        @Mutation('handleStatusChatVideoRoom') handleStatusChatVideoRoom
        @Mutation('handleAudioInputDevice') handleAudioInputDevice
        @Mutation('cleanRoomDatas') cleanDatas
        @Mutation('handleVoiceFocus') handleVF
        @Mutation('handleVoiceFocusDevice') handleVoiceFocusDevice
        /* Action */
        @Action('beforeShowModal') beforeShowModal
        @Action('audioInputSelectionWithOptionalVoiceFocus') audioInputSelectionWithOptionalVoiceFocus
        
    	/* ========Computed======= */
        get devMode(): boolean{
            return this.options.devMode;
        }
        get usersMap() {
            let usersMap = new Map();
            this.options.users.forEach( (user: any) => {
                usersMap.set(user.id+'', user);
            });
            return usersMap;
        }
        get lesson(): any{
            return this.options.lesson;
        }
        get duration(): string{
            return this.options.durationFormat;
        }
        get muteCam(): boolean{
            return this.options.muteCam;
        }
        get muteMic(): boolean{
            return this.options.muteMic;
        }
        get loading(): boolean{
            return this.options.loading && this.roomStep == this.Step.startRoom && this.meetingSession;
        }
        /* to avoid console issues */
        get moment(): any{
            return (this as any).$moment;
        }
        get localStorage(): any{
            return(this as any).$localStorage;
        }
        get refs(): any{
            return(this as any).$refs;
        }

    	/* ======== Vue Lifecycle Hooks ======== */
    	async created(){
            this.staticVars();
            if(this.lesson)
                this.init();
        }

        async beforeDestroy(){
            this.lesson.status = 'Finalizada';
            if(this.audioVideo) await this.endRoom();
        }

        private staticVars(): void{
            const { DeviceAlerts, config } = this.options;
            this.deviceAlerts = DeviceAlerts;
            this.config = config;
        }

        async init(): Promise<void>{
            await this.openAudioInputFromSelectionAndPreview(this.currentMic);
            this.$emit('handleVideoQuality', this.currentVideoQuality);
            await this.openVideoInputFromSelection(null);
            this.setupMuteHandler();
            this.setupCanUnmuteHandler();
            this.setupSubscribeToAttendeeIdPresenceHandler();
            if(this.usePriorityBasedDownlinkPolicy)
                this.priorityBasedDownlinkPolicy.addObserver(this);
            this.audioVideo.addObserver(this);
            this.audioVideo.addContentShareObserver(this);
            this.connectToCall();
        }

    	/*-- ========== room functions ========= */
        getHeight(): void{
            if(this.refs.gridRoom)
                this.refs.gridRoom.getHeight();
        }

        filterRemoteRosters(): void{
            console.log('filtering', this.lesson.type);
            const keys = Object.keys(this.roster);
            this.filterRosters = Object.values(this.roster).reduce( (arr,item, i) => {
                const { type } = this.lesson;
                const k = keys[i].split('#');

                if(type == 'Grupal' && item.idUser != this.auth.user.id){
                    if(!item.sharing){
                        if(arr.find( i => i.roster == `${k[0]}#content` ))
                            return arr;
                        else
                            arr = [ ...arr, { roster: keys[i], ...this.usersMap.get(item.idUser) } ];
                    }
                    else{
                        if(arr.find( i => i.roster == k[0] ))
                            arr = [ ...arr.filter( i => i.roster != k[0] ), { roster: keys[i], ...this.usersMap.get(item.idUser) } ];
                        else
                            arr = [ ...arr, { roster: keys[i], ...this.usersMap.get(item.idUser) } ];
                    }
                }
                else if(type == 'Individual' && (item.idUser != this.auth.user.id || (item.idUser == this.auth.user.id && k[1] == 'content'))){
                    arr = [ ...arr, { roster: keys[i], ...this.usersMap.get(item.idUser) } ];
                }

                return arr;
            }, []);
            this.loadingRemoteUsers = false;
        }

        toggleFullScreen (): void {
            this.refs['fullscreen'].toggle();
        }

        fullscreenChange (): void {
            this.statusFullScreen = !this.statusFullScreen;
        }

        mousemove(): void{
            this.hideElement = false;
            clearInterval(this.mouseDelay);
            this.mouseDelay = setTimeout( () => {
                this.hideElement = true;
            }, 5000);
        }

        handleVolumeAlert(): void{
            this.volumeAlert = false
            clearTimeout(this.volumeAlertTime);
            this.volumeAlertTime = setTimeout( () => {
                this.showVolumeAlert = true;
            }, 90000);
        }

        private async resetMeeting(): Promise<void>{
            location.reload();
        }

        /*-- ========== Chime functions ========= */
        async connectToCall() {
            console.log("$ connectToCall");
            try {
                if (this.muteMic) this.audioVideo.realtimeMuteLocalAudio();
                
                await this.join();
                this.mousemove();
                window.addEventListener("resize", this.getHeight);
            } catch (error) {
                console.log(error, "$ Error on join");
            }
        }

        async join(): Promise<void> { // Star sharing devices
            window.addEventListener("unhandledrejection",(event: PromiseRejectionEvent) => {
                console.log(event.reason);
            });
            this.audioVideo.start();
        }

        leave(): void{
            this.lesson.status = 'Finalizada';
            if (this.roomStep == this.Step.startRoom && !this.meetingEnded) 
                this.beforeShowModal({ lesson: this.lesson, action: 'v-modal-crud:finished' });
            else
                this.$router.push({ name: "dashboard-" + this.auth.permission });
        }

        async endRoom(): Promise<void> {
            if (this.audioVideo) this.audioVideo.stop();
            this.handleVoiceFocusDevice(null);
        }

        private async reselectAudioInputDevice(): Promise<void> {
            const current = this.currentAudioInputDevice;

            if (current instanceof VoiceFocusTransformDevice) {
                // Unwrap and rewrap if Amazon Voice Focus is selected.
                const intrinsic = current.getInnerDevice();
                const device = await this.audioInputSelectionWithOptionalVoiceFocus(intrinsic);
                return this.selectAudioInputDevice(device);
            }

            // If it's another kind of transform device, just reselect it.
            if (isAudioTransformDevice(current)) {
                return this.selectAudioInputDevice(current);
            }

            // Otherwise, apply Amazon Voice Focus if needed.
            const device = await this.audioInputSelectionWithOptionalVoiceFocus(current);
            return this.selectAudioInputDevice(device);
        }

        /* Audio input */
        async selectAudioInputDevice(device: AudioInputDevice): Promise<void> {
            this.handleAudioInputDevice(device);
            console.log('Selecting audio input', device);
            try {
                if (device != '') {
                    this.handlePermissions({ mic: this.devices.microphones.length>0?this.typePermission.granted:this.typePermission.deviceEmpty });
                    await this.audioVideo.chooseAudioInputDevice(device);
                }else{
                    await this.audioVideo.chooseAudioInputDevice(null);
                    if(this.loading)
                        this.$emit('handleDevice', 'mic');
                }
            } catch (e:any) {
                console.log(`failed to choose audio input device ${device}`, e);
                if (e.cause.name == 'PermissionDeniedError' || (e.cause.name == 'NotAllowedError' && e.cause.message != 'Permission denied by system') || e.cause.name == 'TypeError') {
                    this.handlePermissions({ mic: this.typePermission.denied });
                    if(this.loading)
                        this.$emit('handleDevice', 'mic');
                    console.log('Permission denied', e);
                }
                else if((e.cause.name == 'NotAllowedError' && e.cause.message == 'Permission denied by system') || e.cause.name == 'NotFoundError' || e.cause.name == 'DevicesNotFoundError'){
                    this.handlePermissions({ mic: this.typePermission.errorSystem });
                    if(this.loading)
                        this.$emit('handleDevice', 'mic');
                }
                else{
                    this.handlePermissions({ mic: this.typePermission.errorDevice });
                    if(this.loading)
                        this.$emit('handleDevice', 'mic');
                    console.log('Not Readable', e);
                }
            }
        }

        async openAudioInputFromSelection(deviceId: string | null): Promise<void> {
            const device = await this.audioInputSelectionWithOptionalVoiceFocus(deviceId);
            await this.selectAudioInputDevice(device);
        }

        async openAudioInputFromSelectionAndPreview(deviceId: string | null): Promise<void> {
            await this.stopAudioPreview();
            await this.openAudioInputFromSelection(deviceId);
            console.log('Starting audio preview.');
            await this.startAudioPreview();
        }

        startAudioPreview(): void {    
            this.volume = 0;

            // Recreate.
            if (this.analyserNode) {
                // Disconnect the analyser node from its inputs and outputs.
                this.analyserNode.disconnect();
                this.analyserNode.removeOriginalInputs();
                this.analyserNode = undefined;
            }

            const analyserNode = this.audioVideo.createAnalyserNodeForAudioInput();

            if (!analyserNode)
                return;

            if(!analyserNode.getByteTimeDomainData)
                return;

            this.analyserNode = analyserNode;
            const data = new Uint8Array(analyserNode.fftSize);
            let frameIndex = 0;
            this.analyserNodeCallback = () => {
                if (frameIndex === 0) {
                    analyserNode.getByteTimeDomainData(data);
                    const lowest = 0.01;
                    let max = lowest;
                    for (const f of data) {
                        max = Math.max(max, (f - 128) / 128);
                    }
                    let normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
                    this.volume = Math.min(Math.max(normalized * 100, 0), 100);
                }
                frameIndex = (frameIndex + 1) % 2;
                if (this.analyserNodeCallback) {
                    requestAnimationFrame(this.analyserNodeCallback);
                }

                if (this.muteMic && ((!this.isVoiceFocusEnabled && this.volume >= 75) || (this.isVoiceFocusEnabled && this.volume >= 60)) && this.refs.toolbar && !this.volumeAlert &&  this.showVolumeAlert && this.loading){
                    this.showVolumeAlert = false;
                    this.volumeAlertTime = setTimeout( () => {
                        this.volumeAlert = true; 
                    }, 3000);
                }
            };
            requestAnimationFrame(this.analyserNodeCallback);
        }

        async stopAudioPreview(): Promise<void> {
            console.log('Stopping audio preview.');
            if (!this.analyserNode) {
                return;
            }

            this.analyserNodeCallback = undefined;

            // Disconnect the analyser node from its inputs and outputs.
            this.analyserNode.disconnect();
            this.analyserNode.removeOriginalInputs();

            this.analyserNode = undefined;
        }

        /* Priority policy */
        updateDownlinkPreference(): void {
            if (!this.priorityBasedDownlinkPolicy || this.filterRosters.filter( (i: any) => i.id+'' != this.auth.user.id+'' ).length <= 1) {
                return;
            }

            const videoPreferences = VideoPreferences.prepare();
            let builder: Boolean = false;

            this.filterRosters.forEach( (item: any) => {
                const { roster: attendeeId, type } = item,
                    st: any = this.filterRosters.find( (i: any) => this.roster[i.roster].sharing && i.roster != attendeeId );

                if (this.roster[attendeeId].hasVideo) {
                    builder = true;

                    let priority: number = 1,
                        quality: TargetDisplaySize = TargetDisplaySize.Low;
                    
                    if(this.contVS == 1){
                        priority = 1;
                        quality = TargetDisplaySize.High;
                    }
                    else if(this.pin){
                        if(attendeeId == this.pin){
                            priority = 1;
                            quality = TargetDisplaySize.High;
                        }
                        else{
                            priority = 2;
                            quality = TargetDisplaySize.Low;
                        }
                    }
                    else if(this.lesson.type == 'Individual'){
                        if(this.currentRosterScreenShare?.roster == attendeeId){
                            priority = 1;
                            quality = TargetDisplaySize.High;
                        }
                        else{
                            priority = 2;
                            quality = TargetDisplaySize.Medium;
                        }
                    }
                    else{
                        if(this.roster[attendeeId].sharing){
                            priority = 1;
                            quality = !st?TargetDisplaySize.High:TargetDisplaySize.Medium;
                        }else if(this.activeUserSpeaking == attendeeId){
                            priority = !st?1:2;
                            quality = TargetDisplaySize.Medium;
                        }else{
                            if(!st && !this.activeUserSpeaking)
                                priority = 1;
                            else
                                priority = this.activeUserSpeaking && st && this.activeUserSpeaking != st?.roster?3:2;
                            quality = TargetDisplaySize.Low;
                        }                        
                    }
                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], priority: priority, quality: quality } };
                    videoPreferences.add(new VideoPreference(attendeeId, priority, quality));
                }else{
                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], priority: null, quality: null } };
                }
            });
            console.log('Building video preferences', builder, JSON.stringify(videoPreferences));
            if(builder)
                this.priorityBasedDownlinkPolicy.chooseRemoteVideoSources(videoPreferences.build());
        }

        /* Video */
        async openVideoInputFromSelection(deviceId: string | null, callback: any = val => val): Promise<void>{ // Change video device
            if(this.loading){                
                console.log(`Switching to: ${deviceId}`);
                if (deviceId === null) {
                    //this.audioVideo.stopVideoPreviewForVideoInput(this.refs.localVideo.$el.querySelector('video') as HTMLVideoElement);
                    this.audioVideo.stopLocalVideoTile();
                }
                try {
                    if(!(this.permissionCam == this.typePermission.denied && this.roomStep != this.Step.startRoom))
                        this.handlePermissions({ cam: this.devices.cameras.length>0?this.typePermission.granted:this.typePermission.deviceEmpty });
                    await this.audioVideo.chooseVideoInputDevice(deviceId);
                    callback(true);
                } catch (e: any) {
                    console.log(`failed to chooseVideoInputDevice ${deviceId}`, e.cause.name, e.cause.message);  
                    if(this.devices.cameras.length == 0){
                        this.handlePermissions({ cam: this.typePermission.deviceEmpty });
                        if(this.loading)
                            this.$emit('handleDevice', 'cam');
                    }
                    else if (e.cause.name == 'PermissionDeniedError' || (e.cause.name == 'NotAllowedError' && e.cause.message != 'Permission denied by system') || e.cause.name == 'TypeError') {
                        this.handlePermissions({ cam: this.typePermission.denied });
                        if(this.loading)
                            this.$emit('handleDevice', 'cam');
                        console.log('Permission denied', e);
                    }
                    else if((e.cause.name == 'NotAllowedError' && e.cause.message == 'Permission denied by system') || e.cause.name == 'NotFoundError' || e.cause.name == 'DevicesNotFoundError'){
                        this.handlePermissions({ cam: this.typePermission.errorSystem });
                        if(this.loading)
                            this.$emit('handleDevice', 'cam');
                    }
                    else{
                        this.handlePermissions({ cam: this.typePermission.errorDevice });
                        if(this.loading)
                            this.$emit('handleDevice', 'cam');
                        console.log('Not Readable', e);
                    }
                    callback(false);
                }
            }
        }

        startLocalVideoTile(): void{
            if (this.startLocalVideo){
                console.log('Staring video tile')
                this.audioVideo.startLocalVideoTile();
                this.startLocalVideo = false;
            }
        }

        /* Screen share */
        private async contentShareStart(contentShareType: ContentShareType): Promise<void> {
            switch (contentShareType) {
                case this.ContentShareType.ScreenCapture: {
                    try {
                        const sharingScreen: any = Object.keys(this.remoteVideos).filter( i => i.includes('#content') );

                        if(sharingScreen.length < 2){
                            await this.audioVideo.startContentShareFromScreenCapture();
                            if (!this.muteCam && this.lesson.type == 'Grupal'){
                                this.audioVideo.stopLocalVideoTile(); 
                            }
                            this.contentShareType = contentShareType;
                        }else{
                            this.setModal('v-modal-alert:shareScreen');
                        }
                    } catch (e: any) {
                        this.contentShareType = ContentShareType.Nothing;
                        if(e.code != 0)
                            this.setModal('v-modal-alert:browser');
                        console.log('Could not start content share', e.code, e.name, e.message);
                        return;
                    }
                    break;
                }
                case this.ContentShareType.Nothing: {
                    this.audioVideo.stopContentShare();
                    if (this.lesson.type == 'Grupal' && !this.muteCam) {
                        this.startLocalVideo = true;
                        this.startLocalVideoTile();
                    }    
                    break;
                }
            }
            this.getHeight();
        }

        /* subscribe users devices */
        setupMuteHandler(): void {
            const handler = (isMuted: boolean): void => {
                console.log(`muted = ${isMuted}`);
            };
            this.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(handler);
            const isMuted = this.audioVideo.realtimeIsLocalAudioMuted();
            handler(isMuted);
        }

        setupCanUnmuteHandler(): void {
            const handler = (canUnmute: boolean): void => {
                console.log(`canUnmute = ${canUnmute}`);
            };
            this.audioVideo.realtimeSubscribeToSetCanUnmuteLocalAudio(handler);
            handler(this.audioVideo.realtimeCanUnmuteLocalAudio());
        }

        async setupSubscribeToAttendeeIdPresenceHandler(): Promise<void> { /**Este es el evento que reconoce las conecciones */
            console.log("$ USER CONNECTED", this.roster);
            const handler = (attendeeId: string, present: boolean, externalUserId: string,  dropped: boolean): void => {
                
                const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
                console.log(`${attendeeId} present = ${present} (${externalUserId}) sharing = ${isContentAttendee}`);
                //const isSelfAttendee = new DefaultModality(attendeeId).base() === this.meetingSession!.configuration.credentials!.attendeeId;
                const attId = attendeeId.split('#');
                if (!present) {
                    if (externalUserId == this.auth.user.id+''){
                        if(attId[1] != 'content')
                            this.localRoster = undefined;
                        else if(this.lesson.type == 'Grupal')
                            this.localRoster = { roster: attId[0], ...this.usersMap.get(externalUserId) };
                    }

                    this.roster = { ...Object.values(this.roster).reduce( (arr, item) => {
                            
                        if (item.idUser == externalUserId)
                            delete arr[attendeeId]

                        return arr;
                    }, this.roster) };

                    if(this.pin == attendeeId){
                        if(this.lesson.type == 'Individual' || (this.lesson.type == 'Grupal' && !this.roster[attId[0]]))
                            this.pin = null;
                        else if(attId[1] == 'content' && this.lesson.type == 'Grupal')
                            this.pin = attId[0];
                    }

                    this.getHeight();
                    this.filterRemoteRosters();
                    console.log(`${attendeeId} dropped = ${dropped} (${externalUserId})`);
                    return;
                }

                //If someone else share content, stop the current content share
                /*if (!this.allowMaxContentShare() && !isSelfAttendee && isContentAttendee && this.isButtonOn('button-content-share')) {
                    //this.contentShareStop();
                }*/

                if (!this.roster[attendeeId] || !this.roster[attendeeId].idUser) {
                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], idUser: externalUserId, sharing: isContentAttendee  } };
                    
                    if(attId[1] != 'content' && this.loading && this.statusEnterSound){
                        const audio = new Audio('/media/vr-enter.wav');
				        audio.play();
                    }

                    if (!this.localRoster) this.loadingRemoteUsers = true;

                    if (externalUserId == this.auth.user.id+''){
                        if(this.lesson.type == 'Grupal'){
                            this.localRoster = { roster: attendeeId, ...this.usersMap.get(externalUserId) };
                        }
                        else if(this.lesson.type == 'Individual' && attId[1] != 'content')
                            this.localRoster = { roster: attendeeId, ...this.usersMap.get(externalUserId) };
                        this.roomStep = this.Step.startRoom;
                    }

                    if(this.pin == attId[0] && this.lesson.type == 'Grupal' && attId[1] == 'content')
                                this.pin = attendeeId;

                    this.filterRemoteRosters();
                    this.getHeight();
                }

                const getUserSpeaking = () => { // Edit
                    const rosters = Object.entries(this.roster);

                    if (this.totalUsers >= 2 && !this.pin) {
                        // Order by volume indicator
                        const orderRosters = rosters.sort( (a: any,b: any): any => b[1].volume - a[1].volume );

                        if (orderRosters[0][1].volume > 30)
                            this.activeUserSpeaking = orderRosters[0][0];
                    }
                    else
                        this.activeUserSpeaking = null;
                }

                this.audioVideo.realtimeSubscribeToVolumeIndicator(
                    attendeeId,
                    async (
                        attendeeId: string,
                        volume: number | null,
                        muted: boolean | null,
                        signalStrength: number | null,
                        externalUserId: string
                        ) => {
                        if (!this.roster[attendeeId]) {
                            return;
                        }
                        if (volume !== null) {
                            if(this.roster[`${attendeeId}#content`] && this.lesson.type == 'Grupal')
                                this.roster[`${attendeeId}#content`].volume = Math.round(volume * 100);
                            else
                                this.roster[attendeeId].volume = Math.round(volume * 100);
                        }
                        if (muted !== null) {
                            if(this.roster[`${attendeeId}#content`] && this.lesson.type == 'Grupal')
                                this.roster = { ...this.roster, [`${attendeeId}#content`]: { ...this.roster[`${attendeeId}#content`], muted: muted },
                                                                [attendeeId]: { ...this.roster[attendeeId], muted: muted } };
                            else{
                                const s = attendeeId.split('#');
                                if(s[1] == 'content'){
                                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], muted: this.roster[s[0]]?(this.roster[s[0]].muted || false):false } };
                                }else{
                                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], muted: muted } };
                                }                                
                            }
                                
                            if (attendeeId == this.activeUserSpeaking && muted)
                                this.activeUserSpeaking = null;
                        }
                        if (signalStrength !== null) {
                            this.roster[attendeeId].signalStrength = Math.round(
                                signalStrength * 100
                                );
                        }
                        getUserSpeaking();
                    }
                );
            };

            this.attendeeIdPresenceHandler = handler;
            this.audioVideo.realtimeSubscribeToAttendeeIdPresence(handler);

            // Hang on to this so we can unsubscribe later.
            const activeSpeakerHandler = (attendeeIds: string[]): void => {
                for (const attendeeId in this.roster) {
                    this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], active: false } };
                }
                for (const attendeeId of attendeeIds) {
                    if (this.roster[attendeeId]) {
                        this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], active: true } };
                        break; // only show the most active speaker
                    }
                }
            };

            this.audioVideo.subscribeToActiveSpeakerDetector(
                new DefaultActiveSpeakerPolicy(),
                activeSpeakerHandler,
                (scores: {[attendeeId:string]: number}) => {
                    for (const attendeeId in scores) {
                        if (this.roster[attendeeId]) {
                            this.roster = { ...this.roster, [attendeeId]: { ...this.roster[attendeeId], score: scores[attendeeId] } };
                        }
                    }
                },
                this.showActiveSpeakerScores ? 100 : 0,
                );
        }
        
        videoTileDidUpdate(tileState: VideoTileState): void { // Evento para manejar el video
            if (!tileState.boundAttendeeId) {
                return;
            }

            console.log('video tileId added:', tileState);

            if (tileState.boundVideoElement && this.lesson.status != 'Finalizada' && tileState.boundAttendeeId) {
                if ((this.remoteVideos[tileState.boundAttendeeId]?.boundAttendeeId == tileState.boundAttendeeId)){   
                    console.log(`binding video tile ${tileState.boundAttendeeId}`, tileState);
                    const tileIndex = tileState.localTile?(tileState.isContent ? this.tileOrganizer.acquireTileIndex(tileState.tileId!) : 16):this.tileOrganizer.acquireTileIndex(tileState.tileId!);
                    this.audioVideo.bindVideoElement(tileState.tileId, tileState.boundVideoElement);
                    this.tileIndexToTileId[tileIndex] = tileState.tileId!;
                    this.tileIdToTileIndex[tileState.tileId!] = tileIndex;
                }
            }

            if (tileState.boundAttendeeId) {
                console.log('Add stream video');
                this.remoteVideos = { ...this.remoteVideos, [tileState.boundAttendeeId]: tileState }
            }

            if(tileState.localTile /*&& this.statusModalSettings*/){
                if(this.remoteVideos[tileState.boundAttendeeId]?.boundVideoElement && this.remoteVideos[tileState.boundAttendeeId]?.active)
                   this.resetVideo +=1;
            }
        }

        videoTileWasRemoved(tileId: number): void { // Edit
            const tileIndex = this.tileOrganizer.releaseTileIndex(tileId);
            const video: any = Object.values(this.remoteVideos).find( (i: any) => i.tileId === tileId );
            if (video && tileId == video.tileId){
                delete this.remoteVideos[video.boundAttendeeId];
                if(!video.boundAttendeeId.includes('#content') && !this.muteCam && video.localTile)
                    this.$emit('handleCam', true);
            } 
            console.log(`video tileId removed: ${tileId} ${video.boundExternalUserId}`);
        }

        boundVideoElement(isLocal: boolean, stream: any, videoElement: HTMLVideoElement): void{ // Bind video after render video component
            if(stream){
                console.log(`Video tile updated: ${JSON.stringify(stream, null, '  ')}`, videoElement);
                console.log(`binding video tile ${stream.tileId}`);

                const tileIndex = isLocal?(stream.isContent ? this.tileOrganizer.acquireTileIndex(stream.tileId!) : 16):this.tileOrganizer.acquireTileIndex(stream.tileId!);
                this.audioVideo.bindVideoElement(stream.tileId, videoElement);
                this.tileIndexToTileId[tileIndex] = stream.tileId!;
                this.tileIdToTileIndex[stream.tileId!] = tileIndex;
                this.getHeight();
            }
        }      

        /* Events of addObserver */
        async audioVideoDidStart(): Promise<void> {
            console.log("$ session started");
            this.roomStep = this.Step.startRoom;
            this.statusEnterSound = true;
            const audio = new Audio('/media/vr-enter.wav');
			    audio.play();          
        } 
        audioVideoDidStartConnecting(reconnecting: boolean) {
            if (reconnecting) {
                console.log('Attempting to reconnect');
            }
        }
        async audioVideoDidStop(sessionStatus: MeetingSessionStatus): Promise<void> {
            console.log(`$ session stopped from ${JSON.stringify(sessionStatus)}`);

            const returnToStart = async () => {
                switch (this.behaviorAfterLeave) {
                    case 'spa':
                        this.meetingEnded = true;
                        break;
                    case 'left': {
                        this.cleanDatas();
                        this.$emit('stopDuration');
                        const audio = new Audio('/media/vr-exit.wav');
				        audio.play();
                        if(this.$route.name == `room-${this.auth.permission}`)
                            this.$router.push({ name: "dashboard-" + this.auth.permission });
                        break;
                    }
                    case 'reload': {
                        this.roomStep == this.Step.spinner;
                        let cam = this.currentCam,
                            videoQuality = this.currentVideoQuality,
                            mic = this.currentMic,
                            audioOutput = this.currentSpk,
                            vf = this.enableVoiceFocus;

                        this.cleanDatas();
                        this.handleDevice({ type: 'cam', device: { deviceId: cam } });
                        this.handleDevice({ type: 'VQ', deviceId: videoQuality });
                        this.handleDevice({ type: 'mic', device: { deviceId: mic } });
                        this.handleDevice({ type: 'spk', deviceId: audioOutput });
                        this.handleVF(vf);

                        await this.$emit('reload');
                        break;
                    }
                }
            };

            const cleanUpResources = async(): Promise<void> => {
                // Stop listening to attendee presence.
                this.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(this.attendeeIdPresenceHandler);

                // Stop watching device changes in the UI.
                this.audioVideo.removeDeviceChangeObserver(this);

                // Stop content share and local video.
                await this.audioVideo.stopLocalVideoTile();
                await this.audioVideo.stopContentShare();

                // Drop the audio output.
                await this.audioVideo.chooseAudioOutputDevice(null);
                this.audioVideo.unbindAudioElement();

                // Stop Voice Focus.
                await this.voiceFocusDevice?.stop();

                window.removeEventListener("resize", this.getHeight);
            }

            const onLeftMeeting = async () => {
                await cleanUpResources();
                await returnToStart();
            };

            if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
                console.log(`meeting ended`);
                if (this.lesson.status != 'Finalizada' && this.roomStep == this.Step.spinner)
                    this.behaviorAfterLeave = 'reload';
                else if (this.lesson.status == "Activa" && this.roomStep != this.Step.spinner) 
                    this.behaviorAfterLeave = 'spa';
                else
                    this.behaviorAfterLeave = 'left';
                    
                await onLeftMeeting();
                return;
            }

            if (sessionStatus.statusCode() === MeetingSessionStatusCode.Left) {
                console.log('left meeting');
                this.behaviorAfterLeave = 'left';
                await onLeftMeeting();
                return;
            }

            if(sessionStatus.statusCode() === MeetingSessionStatusCode.AudioJoinedFromAnotherDevice){
                console.log(`meeting ended from another device`);
                this.setModal('v-modal-alert:meetingEndedFromAnotherDevice');
                this.behaviorAfterLeave = 'left';
                await onLeftMeeting();
                return;
            }
        }
        connectionDidBecomeGood(): void {
            console.log("$ connection is good now");
        }
        connectionDidBecomePoor(): void {
            console.log("$ connection is poor");
        }
        connectionDidSuggestStopVideo(): void {
            console.log("$ suggest turning the video off");
        }
        videoNotReceivingEnoughData(receivingDataMap: ClientVideoStreamReceivingReport[]) : void{
            console.log('$ One or more video streams are not receiving expected amounts of data', JSON.stringify(receivingDataMap) );
        }
        videoReceiveBandwidthDidChange(newBandwidthKbps: number, oldBandwidthKbps: number): void {
            console.log("$ BandwidthDidChange", )
        }
        videoSendDidBecomeUnavailable(): void {
            console.log("$ sending video is not available");
        }
        estimatedDownlinkBandwidthLessThanRequired(estimatedBandwidth: number, requiredBandwidth: number ): void {
            console.log(`$ Estimated downlink bandwidth is ${estimatedBandwidth} is less than required bandwidth for video ${requiredBandwidth}`);
        }
        metricsDidReceive(clientMetricReport: ClientMetricReport): void {
            if(this.devMode){
                const metricReport = clientMetricReport.getObservableMetrics();
                this.metrics = (clientMetricReport as any).getObservableVideoMetrics();

                const { availableSendBandwidth,
                        availableOutgoingBitrate,
                        availableReceiveBandwidth,
                        availableIncomingBitrate } = metricReport;

                if (typeof availableSendBandwidth === 'number' && !isNaN(availableSendBandwidth))
                    this.upLinkBandWidth = availableSendBandwidth / 1000;
                else if (typeof availableOutgoingBitrate === 'number' && !isNaN(availableOutgoingBitrate))
                    this.upLinkBandWidth = availableOutgoingBitrate / 1000;
                else
                    this.upLinkBandWidth = 0;
    
                if (typeof availableReceiveBandwidth === 'number' && !isNaN(availableReceiveBandwidth))
                    this.downlinkBandWidth = availableReceiveBandwidth / 1000;
                else if (typeof availableIncomingBitrate === 'number' && !isNaN(availableIncomingBitrate))
                    this.downlinkBandWidth = availableIncomingBitrate / 1000;
                else
                    this.downlinkBandWidth = 0;
            }
        }

        /* Events of ContentShareObserver */ 
        contentShareDidStart(): void {
            console.log("$ content share started.");
            this.resetVideo += 1;
        }
        async contentShareDidStop(): Promise<any> {
            console.log("$ content share stopped.");
            if(this.contentShareType == this.ContentShareType.ScreenCapture){
                if (this.lesson.type == 'Grupal' && !this.muteCam) {
                    this.startLocalVideo = true;
                    this.startLocalVideoTile();
                }     
                this.contentShareType = this.ContentShareType.Nothing;
                this.resetVideo += 1;
            }
        }
        contentShareDidPause(): void {
            console.log("$ content share paused.");
        }
        contentShareDidUnpause(): void {
            console.log("$ content share unpaused.");
        }
        remoteVideoSourcesDidChange(videoSources: VideoSource[]): void { // edit
        console.log(`available remote video sources changed: ${JSON.stringify(videoSources)}`);
            for (const attendeeId in this.roster) {
                this.roster[attendeeId].hasVideo = false;
            }

            for (const source of videoSources) {
                const { attendeeId, externalUserId } = source.attendee,
                      sharing = attendeeId.split('#')[1] == 'content';

                this.roster = { ...this.roster, [attendeeId]:  { ...this.roster[attendeeId], sharing: sharing, hasVideo: true, idUser: externalUserId } };
            }
            this.contVS = videoSources.length;
            if(this.contVS > 0)
                this.filterRemoteRosters();
            this.updateDownlinkPreference();
        }
        tileWillBePausedByDownlinkPolicy(tileId: number): void {
            console.log(`Tile ${tileId} will be paused due to insufficient bandwidth`);
            const attendeeId = this.audioVideo.getVideoTile(tileId)?.state().boundAttendeeId;
            if(this.roster[attendeeId])
                this.roster[attendeeId].bandwidthConstrained = true;
        }
        tileWillBeUnpausedByDownlinkPolicy(tileId: number): void {
            console.log(`Tile ${tileId} will be resumed due to sufficient bandwidth`);
            const attendeeId = this.audioVideo.getVideoTile(tileId)?.state().boundAttendeeId;
            if(this.roster[attendeeId])
                this.roster[attendeeId].bandwidthConstrained = false;
        }

        /* Events Vue */
        @Watch('askingForPermissions')
        handleStepRoom(newVal: boolean){
            if (newVal && !this.loading)
                this.roomStep = this.Step.promptPermissions;
        }

        @Watch('enableVoiceFocus')
        async handleVoiceFocus(newVal: boolean){
            if (this.loading){
                console.log('Amazon Voice Focus toggle is now', newVal);
                await this.reselectAudioInputDevice();
                this.startAudioPreview();
            }
        }

        @Watch('muteMic')
        async handleMic(newVal: boolean): Promise<void>{
            if (this.loading && this.permissionMic == this.typePermission.granted) {
                if (!newVal){
                    this.audioVideo.realtimeUnmuteLocalAudio();
                    this.volumeAlert = false;
                    clearTimeout(this.volumeAlertTime);
                    this.showVolumeAlert = true;
                }
                else{
                    this.audioVideo.realtimeMuteLocalAudio();
                }
            }else if(this.loading && !newVal){
                await this.openAudioInputFromSelectionAndPreview(this.currentMic);
                if (this.permissionMic != this.typePermission.granted){ 
                    this.$emit('handleMic', true);
                }
            }
        }

        @Watch('currentMic')
        async micChange(newVal: string | null): Promise<void>{
            if (this.loading){
                if (newVal) 
                    await this.openAudioInputFromSelectionAndPreview(newVal);
                    //this.$emit('handleDevice', 'mic');
            }
        }

        @Watch('muteCam')
        async handleCam(newVal: boolean): Promise<void>{
            if (this.loading) {
                if (newVal){
                    console.log('Mute camera');
                    this.startLocalVideo = true;
                    this.audioVideo.stopLocalVideoTile();
                    this.resetVideo +=1;
                }
            }
        }
        
        @Watch('currentCam')
        camChange(newVal: string): void{
            if (this.loading){
                this.startLocalVideo = true;
                this.$emit('handleDevice', 'cam', false);
            }
        }

        @Watch('currentVideoQuality')
        async videoQualityChange(newVal: string): Promise<void>{
            if(this.loading){
                this.$emit('handleVideoQuality', newVal);
                if (!this.muteCam)
                    try {
                        this.startLocalVideo = false;
                        await this.refs.video?.endCloneVideo();
                        await this.refs.header?.$refs.video.endCloneVideo();
                        await this.openVideoInputFromSelection(this.currentCam);
                    } catch (err) {
                        console.log('no video input device selected');
                    }
            }
        }

        @Watch('showCurrentVideo')
        getTotalU(newVal: boolean): void{
            this.getHeight();
        }

        @Watch('pin')
        handleH(): void{
            if(this.loading){
                this.updateDownlinkPreference();
                this.getHeight();
            }
        }

        @Watch('currentRosterScreenShare')
        handlePriority(): void{
            if(this.loading)
                this.updateDownlinkPreference();;
        }

        @Watch('activeUserSpeaking')
        handlePriority2(): void{
            if(this.loading)
                this.updateDownlinkPreference();
        }

        @Watch('options.loading')
        handleView(newVal: boolean){
            if(newVal)
                this.init();
        }

        @Watch('modalVal')
        async finishSession(newVal: any, oldVal: any): Promise<void>{
            if (oldVal.modal == 'v-modal-crud' && oldVal.type == 'finished' && this.currentLesson != "" && newVal.modal != 'close' && newVal.modal != 'leaveRoom') {
                await this.endRoom();
                this.beforeShowModal({ lesson: this.currentLesson.lesson, action: 'v-modal-feedback' });
            }
            else if(oldVal.type == 'finished' && newVal.modal == 'leaveRoom'){
                this.lesson.status = 'Activa';
                await this.endRoom();
            }
            else if(oldVal.type == 'finished' && newVal.modal == 'close'){
                this.lesson.status = 'Activa';
            }
        }
    }
