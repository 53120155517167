/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border-outer': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3 10h1v1H3v-1zM5 10h1v1H5v-1zM7 10h1v1H7v-1zM9 10h1v1H9v-1zM11 10h1v1h-1v-1zM13 10h1v1h-1v-1zM15 10h1v1h-1v-1zM9 16h1v1H9v-1zM9 14h1v1H9v-1zM9 12h1v1H9v-1zM9 8h1v1H9V8zM9 6h1v1H9V6zM9 4h1v1H9V4z"/><path pid="1" d="M16.5 19h-14c-.827 0-1.5-.673-1.5-1.5v-14C1 2.673 1.673 2 2.5 2h14c.827 0 1.5.673 1.5 1.5v14c0 .827-.673 1.5-1.5 1.5zM2.5 3a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-14a.5.5 0 00-.5-.5h-14z"/>'
  }
})
