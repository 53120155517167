<template>
	<div id="search-autocomplete">
		<div class="position-relative search">
			<svgicon class="icon-search" name="magnifier"></svgicon>
			<input type="text" v-model="textInput" :placeholder="$t('search.placeholder')" @focus="focus($event.target.value)" @keyup="keyFuction($event.key)">
			<v-more-info class="position-absolute" cloudPositionY="bottom" sizePx="20px" :txtCloud="$t('search.cloud')" iconColor="#2188FC" />
		</div>
		<perfect-scrollbar class="dropdown" v-if="inputFocus" ref="scroll" tag="div">
			<transition name="bound" mode="out-in">
				<div class="w-100 p-3 text-center" key="spinner" v-if="show === 'spinner'">
                    <v-spinner :height="'40px'" :width="'40px'"/>
                </div>
                <div class="w-100 p-3 text-center" key="spinner" v-else-if="show === 'message'">
                    <p class="msg-search mt-2 mb-2">{{ $t('msgEmpty.inputsearch') }}</p>
                </div>
                <transition-group name="bound" key="results" class="pl-0 mb-0" tag="ul" v-else-if="show === 'results'">
                    <li class="dropdown-item" v-for="(item,index) in browser.searchResults" :key="'dropdown-item'+index" @click="select(item)">
                    	<div class="d-flex ml-0 mr-0 align-items-center">
                    		<div class="content-icon" :style="{ background: item.color }" v-if="item.icon_name">
                    			<svgicon class="icon" :name="item.icon_name" />
                    		</div>
                    		<div class="content-img" :style="{ 'background' : 'url('+item.photo+')center center / cover no-repeat' }" v-else></div>
                    		<div class="col col-sm d-flex flex-wrap align-items-center subject-tutor">
                    			<p class="mb-0 w-100 name" v-html="textBold(item.name)"></p>
                    		</div>
                    	</div>
                    </li>
                </transition-group>
			</transition>
		</perfect-scrollbar>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapActions } from 'vuex';

	export default{
		props:{
            defaultVal: null,
            removeTxtInput: { type: Boolean, default: true, required: false }
		},
		computed: {
			...mapState(['browser'])
		},          
		data(){
			return{
				inputFocus: false,
                textInput: '',
                lastResults: '',
                //lastChars: 0,
                noWatch: false,
                focusPressKey: -1,
                delay: '',
                show: ''
			}
		},
		methods: {
			...mapActions(['getResults']),
			...mapMutations(['setTxtBrowser', 'setResults']),
			focus: function(text){ // Función para activar o desactivar el dropdown
				this.inputFocus = text.length > 2?true:false;
				if (this.inputFocus && text != this.lastResults && ((this.browser.searchResults.length == 0 && this.delay == '') /*|| this.lastChars >= 5*/)){
					this.show = 'spinner';
					this.getResults(text).then( resp => {
						if (resp)
							this.show = 'results'
						else
							this.show = 'message';
						this.lastResults = text;
						//this.lastChars = 0;
					});
				}
			},
			closeFocus(event){ // close dropdown to click everybody
            	var ignore = '#search-autocomplete *';
                if (!event.target.matches(ignore)) {
                	this.focusPressKey = -1;
                    this.inputFocus = false;
                }
			},
			select: function(item){ // Click item dropdown
				// Convert all string to lower case and remove all accentes
				let search = item.name.toLowerCase().replace(/á/g,'a').replace(/é/g,'e').replace(/í/g,'i').replace(/ó/g,'o').replace(/ú/g,'u');
				// Encoded Characters
				search = encodeURIComponent(search).replace(/%20/g, "+").replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
				
				if(this.removeTxtInput)
				    this.textInput = '';
				if (item.icon_name){
					const type = this.$route.params.modality?`/${this.$route.params.modality}`:'';
					if (this.$route.fullPath !== `/student/search${type}/${search}`){
						this.setTxtBrowser(item.name);
				        this.$router.push(`/student/search${type}/${search}`);
					}
			    }
				else if(this.$route.fullPath !== '/student/tutor/'+item.id)
					this.$router.push('/student/tutor/'+item.id);

				this.inputFocus = false;
				this.noWatch = true;
			},
			keyFuction: function(key){ // Focus y enter sobre el dropdown
				const dropdown = document.querySelector('#search-autocomplete .dropdown'),
				      dropdowns = document.querySelectorAll('#search-autocomplete .dropdown .dropdown-item'),
				      dropdownActive = document.querySelector('#search-autocomplete .dropdown .dropdown-item.hover'),
				      scrollBar = this.$refs.scroll;
				if (this.focusPressKey > 0 && key == 'ArrowUp') {
					this.focusPressKey--;
					dropdowns[this.focusPressKey].classList.add('hover');
					if (scrollBar.ps.lastScrollTop > dropdowns[this.focusPressKey].offsetTop )
						scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop - 60;
					if(dropdownActive!=null)
					    dropdownActive.classList.remove('hover');
				}
				else if (this.focusPressKey < dropdowns.length-1 && key == 'ArrowDown') {
					this.focusPressKey++;
					dropdowns[this.focusPressKey].classList.add('hover');
					if (scrollBar.ps.lastScrollTop < ((dropdowns[this.focusPressKey].offsetTop + 60) - dropdown.offsetHeight) )
						scrollBar.$el.scrollTop = scrollBar.ps.lastScrollTop + 60;
					if(dropdownActive!=null)
					    dropdownActive.classList.remove('hover');
				}
				else if (this.focusPressKey > -1 && key == 'Enter'){
                    dropdowns[this.focusPressKey].click();
				}
			},
			textBold: function(text){ // Función para cambiar a negritas la conincidencia con el texto buscado
				let param = RegExp(this.textInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i"),
				    textNormalize = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    pos = textNormalize.search(param),
                    textLength = this.textInput.length,
                    textSub = text.substring(pos,pos+textLength);
                if (pos != -1)    
				    text = text.replace(textSub,'<span class="bold">'+textSub+'</span>');
                return text;
			}
		},
		created(){
            this.textInput = this.defaultVal?this.defaultVal:this.browser.txtBrowser;
            this.setResults({subjects: [], tutors: []})

            document.addEventListener('click',this.closeFocus);
            this.$watch( 'textInput', (newVal,oldVal) => {
            	const newV = newVal.trim(),
            	      oldV = oldVal.trim();
            	/*if (newV != this.lastResults && newV != oldV)
            	    this.lastChars += 1;*/
            	clearTimeout(this.delay);
            	if (newV.length > 2 && !this.noWatch){
            		var that = this;
            		this.show = 'spinner';
            		this.delay = setTimeout( () => {
            			if (newV != this.lastResults){
            				that.getResults(newV).then( resp => {
            					if (resp)
            						this.show = 'results'
            					else
            						this.show = 'message';
            					this.lastResults = newV;
            					this.delay = '';
            					//this.lastChars = 0;
            				});
            		    }
            		},700);
            	}
            	if (newV != oldV && (newV.length <= oldV.length+1 || newV.length - oldV.length > 1/* || this.lastChars >= 5*/) && !this.noWatch)
            		this.focus(newV);
            	else
            		this.noWatch = false;
            } );
		},
		beforeDestroy(){
            document.removeEventListener('click',this.closeFocus);
		},
		watch: {
			'browser.txtBrowser': function(newVal){
				if (this.delay == ''/* && this.lastChars == 0*/)
				    this.textInput = newVal;
			}
		}
	}
</script>